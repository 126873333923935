import { Injectable, Injector } from '@angular/core';
import { ISapCodeDto } from '@common-modules/dependencies/alc/sap-code.dto';
import { GenericCrudService } from '@common-modules/generic-crud/generic-crud.service';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable, map } from 'rxjs';
import { ISapCodeValidationDto } from './sap-code-validation.dto';

@Injectable()
export class SapCodesCrudService extends BaseService implements GenericCrudService<any, number> {
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/sap-codes`;
  }

  getAll(): Observable<ISapCodeDto[]> {
    return this.httpCacheClient.get<ISapCodeDto[]>(this._entityUrl);
  }

  create(entity: ISapCodeDto): Observable<ISapCodeDto> {
    return this.httpCacheClient.post<ISapCodeDto>(this._entityUrl, entity);
  }

  update(entity: ISapCodeDto): Observable<ISapCodeDto> {
    return this.httpCacheClient.put<ISapCodeDto>(this._entityUrl, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  validate(entity: ISapCodeDto, isCreate?: boolean): Observable<ISapCodeValidationDto> {
    return this.httpCacheClient.post<ISapCodeValidationDto>(
      `${this._entityUrl}/validate?isCreate=${isCreate ? 'true' : 'false'}`,
      entity
    );
  }

  getId(entity: ISapCodeDto): number {
    return entity.codeId;
  }
}
