import { Component, OnInit, Optional } from '@angular/core';
import { BaseLink } from '@common-modules/dependencies/navigation/base-link';
import { WlmNavigationService } from '@common-modules/dependencies/navigation/wlm-navigation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { HideNavbarsService } from '../hide-navbars/hide-navbars.service';

const COMPONENT_SELECTOR = 'wlm-nav-bar';

@UntilDestroy()
@Component({
  // tslint:disable-next-line: component-selector
  selector: COMPONENT_SELECTOR,
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  modulesLink: Array<BaseLink> = [];
  expanded = true;

  readonly hideNavBar$: Observable<boolean>;

  constructor(
    private readonly _navService: WlmNavigationService,
    @Optional() private readonly _hideNavbarsService: HideNavbarsService
  ) {
    this.hideNavBar$ = this._hideNavbarsService.hideNavBar$;
  }

  ngOnInit(): void {
    this._navService
      .getNavigationModules()
      .pipe(untilDestroyed(this))
      .subscribe((modules) => {
        this.modulesLink = modules;
      });
  }
}
