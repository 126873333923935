import { TabDetailParameterName } from '@common-modules/dependencies/navigation/tab-detail-component';
import { NeHeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { ElementTypeFilter } from '@common-modules/dependencies/wlm-filters/i-filters/element-type-filter';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';

export class ElementTypeFAdapter extends FilterAdapterSettings {
  id = FilterAdapterEnum.ElementTypeFilter;

  private elementTypeFieldName: string;
  private neheFilterSettings: NeHeFISettings;
  private tabDetailParameterName = TabDetailParameterName.hierarchyElementTypeFilterSetting;

  constructor(init: {
    dataBindingField: string;
    elementTypeFieldName: string;
    neheFilterSettings: NeHeFISettings;
    tabDetailParameterName?: TabDetailParameterName;
  }) {
    super(init.dataBindingField);
    Object.assign(this, init);
  }

  adapt(filters: FiltersPayload): IFilter {
    const neheFilter = filters.data.get(this.elementTypeFieldName);
    if (neheFilter) {
      // Convert from BasicFilter to ElementTypeFilter.
      const selectedIds = neheFilter.value ? neheFilter.value.map((item) => item.value) : [];
      const result = new ElementTypeFilter(neheFilter.fieldName, selectedIds);
      return result;
    }
    return null;
  }

  buildResults(heTypeFilter: ElementTypeFilter): FilterAdapterResult {
    if (heTypeFilter) {
      if (heTypeFilter.selectedElements.length === 0) {
        this.filtersResult.filters.delete(this.dataBindingField);
      } else {
        this.filtersResult.filters.set(this.dataBindingField, heTypeFilter);
      }

      this.neheFilterSettings.selectedIds = heTypeFilter.selectedElements;
      this.panelParams.addParameter(this.tabDetailParameterName, this.neheFilterSettings);
    }
    return new FilterAdapterResult({
      dbFilters: this.filtersResult,
      tabParams: this.panelParams,
    });
  }
}
