import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { IUnitTypeDto } from '@common-modules/shared/model/unit/unit-type.dto';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UomHierarchyBulkOptions } from '../../models/uom-hierarchy-bulk-options';

const COMPONENT_SELECTOR = 'wlm-uom-bulk-configuration';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './uom-bulk-configuration.component.html',
  styleUrls: ['./uom-bulk-configuration.component.scss'],
})
export class UomBulkConfigurationComponent implements OnInit {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  private _unitTypes: IUnitTypeDto[];
  public get unitTypes(): IUnitTypeDto[] {
    return this._unitTypes;
  }
  @Input() public set unitTypes(value: IUnitTypeDto[]) {
    if (value) {
      this._unitTypes = value;
    }

    this.form.reset();
  }

  @Output() applyAll = new EventEmitter<any>();

  get componentName(): string {
    return 'UomBulkConfigurationComponent';
  }

  hierarchyTypes = Object.values(UomHierarchyBulkOptions);

  form: UntypedFormGroup;
  hierarchyTypeFieldname = 'hierarchyType';
  unitTypeFieldName = 'unitType';

  constructor(private _formBuilder: UntypedFormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {}

  onApplyAll() {
    const formValue = this.form.value as { hierarchyType: string; unitType: number };
    this.applyAll.emit(formValue);
  }

  private createForm() {
    const controls: { [key: string]: UntypedFormControl } = {};

    controls[this.hierarchyTypeFieldname] = new UntypedFormControl(null, [Validators.required]);
    controls[this.unitTypeFieldName] = new UntypedFormControl(null, [Validators.required]);

    this.form = this._formBuilder.group(controls);
  }
}
