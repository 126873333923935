import { Component, OnInit } from '@angular/core';
import { RouteNameHelper } from '@common-modules/dependencies/navigation/route-name-helper';
import { WlmNavigationService } from '@common-modules/dependencies/navigation/wlm-navigation.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationPageParams } from '../../monitoring/notification/models/notification-page-params';
import { NotificationService } from '../../monitoring/notification/services/notification.service';
import { NotificationHubService } from '../../shared/notification/notification-hub.service';
import { RemoteEventTypes } from '../../shared/notification/remote-event-types.enum';

const COMPONENT_SELECTOR = 'wlm-top-bar-notification-unread-button';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './top-bar-notification-unread-button.component.html',
  styleUrls: ['./top-bar-notification-unread-button.component.scss'],
})
export class TopBarNotificationUnreadButtonComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmNavigation}.${COMPONENT_SELECTOR}`;
  // Null makes the mat-badge dissapear.
  activeNotificationsCount = null;
  maxActiveNotificationsCount = 99;

  constructor(
    private _notificationHubService: NotificationHubService,
    private _notificationService: NotificationService,
    private _authenticationservice: AuthenticationService,
    private _wlmNavigationService: WlmNavigationService
  ) {}

  ngOnInit(): void {
    this._authenticationservice.accessToken$.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateCounter();
    });
    this._notificationHubService
      .onEvent(RemoteEventTypes.UpdateNotificationsCount)
      .pipe(untilDestroyed(this))
      .subscribe((_) => {
        this.updateCounter();
      });
  }

  onNotificationsClick(_) {
    this.redirectToNotificationsUnread();
  }

  redirectToNotificationsUnread() {
    this._wlmNavigationService.navigateOrReload([RouteNameHelper.notifications], {
      queryParams: new NotificationPageParams({
        onlyUnread: true,
      }),
    });
  }

  private updateCounter(): void {
    this._notificationService.countUnread().subscribe((countUnread) => {
      this.activeNotificationsCount = countUnread === 0 ? null : countUnread;
    });
  }
}
