import { Component, Inject, Injector } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { DynamicFormAdditionalSettings } from '@common-modules/dynamic-forms/models/dynamic-form-additional-settings';
import { DynamicFormSourceSettings } from '@common-modules/dynamic-forms/models/dynamic-form-source-settings';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { DynamicLayoutService } from '@common-modules/dynamic-layout/services/dynamic-layout.service';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { AttributeFormGroupSettings } from '../../ne-config-attribute-form-group/attribute-form-group-settings';
import { AttributeFormGroupService } from '../../widgets/ne-config-attribute-form-group-widget/attribute-form-group.service';

const COMPONENT_SELECTOR = 'wlm-attribute-form-group-rp-widget';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './attribute-form-group-rp-widget.component.html',
  styleUrls: ['./attribute-form-group-rp-widget.component.scss'],
  providers: [DynamicLayoutService, AttributeFormGroupService],
})
export class AttributeFormGroupRpWidgetComponent extends BaseWidgetComponent {
  dynamicFormSource: DynamicFormSourceSettings;
  attributeFormGroupSettings: AttributeFormGroupSettings;
  selectedNE: Partial<INetworkElementDto>;
  formAdditionalSettings: DynamicFormAdditionalSettings;

  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private readonly _attributeFormGroupService: AttributeFormGroupService
  ) {
    super(injector, widgetSettings);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.selectedNE = parameters.parameters.get(TabDetailParameterName.networkElement);
    const selectedCategories = parameters.parameters.get(
      TabDetailParameterName.selectedFormCategories
    );

    const configuredStateService = parameters.parameters.get(
      TabDetailParameterName.configuredStateService
    );

    this._attributeFormGroupService.init({
      state: configuredStateService,
      scopeSettings: this._scopeSettings,
    });

    const dynamicLayoutKeys = parameters.parameters.get(
      TabDetailParameterName.dynamicFormLayoutKeys
    );

    this.formAdditionalSettings = this.buildFormAdditionalSettings(this.selectedNE);
    this.dynamicFormSource = parameters.parameters.get(TabDetailParameterName.dynamicFormSource);

    if (this.dynamicFormSource) {
      this.loading = true;
      this._attributeFormGroupService
        .dispatchFormGroup(this.dynamicFormSource, this.formAdditionalSettings)
        .subscribe((visibleCategories) => {
          this.attributeFormGroupSettings = {
            categories: selectedCategories ?? visibleCategories,
            dynamicLayoutKeys,
          };

          this.loading = false;
        });
    }
  }

  private buildFormAdditionalSettings(
    networkElement: Partial<INetworkElementDto>
  ): DynamicFormAdditionalSettings {
    if (!networkElement) {
      return null;
    }
    const result: DynamicFormAdditionalSettings = {
      entityId: networkElement.elementId ?? networkElement.networkElementId,
      entityName: networkElement.networkElementName,
    };
    return result;
  }

  init(): void {}

  get componentName(): string {
    return 'AttributeFormGroupRpWidgetComponent';
  }
}
