import { IFiltrableItemQueryDto } from '@common-modules/shared/model/filtrable-items/filtrable-item-query.dto';

export class SignalFiltrableItemQueryDto implements IFiltrableItemQueryDto {
  startDate: Date;
  endDate: Date;
  apiEndpoint: string;
  extraParams: any;

  constructor(startDate: Date, endDate: Date, apiEndpoint: string, signalIds: number[]) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.apiEndpoint = apiEndpoint;

    this.extraParams = {
      signalIds,
    };
  }
}
