import { Component, Input, OnInit } from '@angular/core';
import { DateRangeFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/date-range-f-adapter';
import { HierarchyElementPathFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FiltersAdapterService } from '@common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { TreeSettingsService } from '@common-modules/shared/services/tree-settings.service';
import { LeaksGridFilterConfiguration } from './leaks-grid-filter-configuration';

const COMPONENT_SELECTOR = 'alc-leaks-grid-filter';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alc-leaks-grid-filter.component.html',
  styleUrls: ['./alc-leaks-grid-filter.component.scss'],
})
export class AlcLeaksGridFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _leaksFilterConfiguration: LeaksGridFilterConfiguration;
  public get leaksFilterConfiguration(): LeaksGridFilterConfiguration {
    return this._leaksFilterConfiguration;
  }
  @Input() public set leaksFilterConfiguration(value: LeaksGridFilterConfiguration) {
    this._leaksFilterConfiguration = value;
    if (value) {
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  // Communicates hierarchy-family-filter-item with hierarchy-tree-filter-item.
  hierarchyElementFamilyId: string;

  treeSettings: ITreeSettings = null;
  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
  };
  bf1Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-1-label`,
    disableSelectAll: true,
    disableSearch: true,
  };
  bf2Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-2-label`,
    disableSelectAll: true,
  };
  dateRangeItemSettings = new BaseFilterItemSettings({
    storageLocation: 'session',
    hideInputSummaryLabel: true,
  });
  hierarchyFamilyItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
    required: true,
  });
  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
  });

  constructor(
    private treeSettingsService: TreeSettingsService,
    private adaptersService: FiltersAdapterService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  ngOnInit(): void {
    this.setFiltersPersistencyArea();
    this.buildTreeSettings();
  }

  buildKeysToComplete(): void {
    const { hierarchyElementIdFieldName } = this.leaksFilterConfiguration;
    this.keysToComplete = [hierarchyElementIdFieldName];
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const {
      startDateFieldName,
      endDateFieldName,
      dateRange,
      hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName,
    } = this.leaksFilterConfiguration;
    const adapters = [
      new DateRangeFAdapter({
        dataBindingField: 'dateRange',
        startDateFieldName,
        endDateFieldName,
        inclusive: dateRange?.inclusive,
        allowsNullDate: dateRange?.allowsNullDate,
      }),
      new HierarchyElementPathFAdapter({
        dataBindingField: 'hierarchyElementsIds',
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId: this.hierarchyElementFamilyId,
      }),
    ];
    return adapters;
  };

  setFiltersPersistencyArea() {
    this.bf1Settings.persistencyArea = this.leaksFilterConfiguration.persistencyArea;
    this.bf2Settings.persistencyArea = this.leaksFilterConfiguration.persistencyArea;
  }

  buildTreeSettings(): void {
    this.treeSettingsService
      .buildTreeSettings()
      .subscribe((settings) => (this.treeSettings = settings));
  }

  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName = this.leaksFilterConfiguration.hierarchyElementFamilyFieldName;
    const defaultId = this.leaksFilterConfiguration.hierarchyElementFamily;
    this.hierarchyElementFamilyId = this.adaptersService.getFamilyId(
      filters,
      familyFieldName,
      defaultId
    );
  }

  onTreeFilterReady() {
    if (this.mustPersistFilters) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
    }
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }
}
