import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { NotificationPopupService } from '@common-modules/dependencies/shared/notification-popup.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '../../dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from '../../dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '../../redux/models/state-widget-settings';
import { BaseWidgetComponent } from '../../shared/component/base-widget.component';
import { GridSetting } from '../../shared/constants/grid.constants';
import { GridSettingsService } from '../../shared/core/grid/grid-settings.service';
import { DataBindingFilters } from '../../shared/filters/component-filters/data-binding-filters';
import { NotificationDto } from '../../shared/model/notifications/notification.dto';
import { GenericGridComponent } from '../../wlm-grid/generic-grid/generic-grid.component';
import { NotificationFilterName } from './notification-filter-name-enum';

const COMPONENT_SELECTOR = 'wlm-notification-related';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './notification-related.component.html',
  styleUrls: ['./notification-related.component.scss'],
})
export class NotificationRelatedComponent extends BaseWidgetComponent implements OnInit {
  @ViewChild(GenericGridComponent) public genericGrid: GenericGridComponent;

  gridSettings: GridSetting;
  filters = new DataBindingFilters();
  gridName = 'Notifications';
  zonesToFilter: string[];
  additionalFilters: Map<string, string>;
  selectedNotification: NotificationDto;
  auditId: string;
  networkElementId: string;
  zone: string;

  get componentName(): string {
    return 'NotificationRelatedComponent';
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _gridSettingService: GridSettingsService,
    private readonly _notificationPopupService: NotificationPopupService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._gridSettingService.getGridSettingsByName(this.gridName).subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          gridSettings.service = 'NotificationRelatedService';
          gridSettings.selectable = false;
          gridSettings.selectByFieldName = undefined;
          gridSettings.showSelectAllColumn = false;
          this.gridSettings = gridSettings;
        }
      },
    });
    this._notificationPopupService.refreshNotifications$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.genericGrid?.reloadGrid();
      });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.zone = parameters.parameters.get(TabDetailParameterName.hierarchyElementId);
    this.networkElementId = parameters.parameters.get(TabDetailParameterName.networkElementId);
    this.selectedNotification = parameters.parameters.get(TabDetailParameterName.notification);
    this.auditId = parameters.parameters.get(TabDetailParameterName.audit);
  }

  init(): void {
    this.setDataBindingFilter();
  }

  private setDataBindingFilter() {
    if (!this.selectedNotification) return;

    const additionalFilters = new Map<string, string>();

    if (this.zone) {
      additionalFilters.set(NotificationFilterName.Elements, this.zone);
    } else if (this.networkElementId) {
      additionalFilters.set(NotificationFilterName.Elements, this.networkElementId);
    } else if (this.auditId) {
      additionalFilters.set(NotificationFilterName.Elements, this.auditId);
      additionalFilters.set(NotificationFilterName.Attribute, 'auditId');
    }

    const isNetworkElement = this.networkElementId ? 'true' : 'false';
    additionalFilters.set(NotificationFilterName.IsNetworkElement, isNetworkElement);

    // We do not need to reload here because
    // additionalFilters setter in grid directive already rebinds.
    this.additionalFilters = additionalFilters;
  }
}
