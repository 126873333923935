<span class="image-position">
  <span *ngIf="imageElements && !enableDataUrl" #columnImage>
    <mat-icon
      *ngIf="!(imageElements | imageIconIsSvg : dataItem[field])"
      [style.color]="imageElements | imageIconColor : dataItem[field]"
    >
      {{ imageElements | imageIconName : dataItem[field] }}
    </mat-icon>
    <wlm-icon
      *ngIf="imageElements | imageIconIsSvg : dataItem[field]"
      [icon]="imageElements | imageIconName : dataItem[field]"
      [size]="'lg'"
    ></wlm-icon>
  </span>
  <img *ngIf="dataUrlImage" [class.hide]="!enableDataUrl" src="{{ dataUrlImage }}" />
</span>
