import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { GridExportHelperService } from '../../services/grid-export-helper.service';

@Component({
  selector: 'wlm-boolean-column-cell',
  templateUrl: './boolean-column-cell.component.html',
  styleUrl: './boolean-column-cell.component.scss',
})
export class BooleanColumnCellComponent implements OnInit {
  @Input() useImages: boolean = true;
  @Input() dataItem;
  @Input() field;
  @Input() elements;

  @ViewChild('columnImage') columnImageElement: ElementRef;

  dataUrlImage: string;
  isExporting: boolean = false;
  setLoading: (isLoading: boolean) => void;

  constructor(
    private readonly _destroyRef: DestroyRef,
    private readonly _gridExportHelperService: GridExportHelperService,
    private readonly _spinnerService: SpinnerService,
    private readonly _cd: ChangeDetectorRef
  ) {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {
    this._gridExportHelperService.notifyExport$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((isExporting) => {
        this.isExporting = isExporting;
        this.setLoading(this.isExporting);
        this._cd.detectChanges();
      });
  }
}
