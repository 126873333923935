import { Injectable } from '@angular/core';
import { IAlarmSeverityTypeDto } from '@common-modules/dependencies/alarms/alarm-severity-type.dto';
import { PeriodValue } from '@common-modules/dependencies/alarms/period-value.dto';
import { ProfileResultDto } from '@common-modules/dependencies/alarms/profile-result.dto';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { TimeAggregationEnum } from '@common-modules/shared/model/algorithm/time-aggregation.enum';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { AlarmsService } from '@common-modules/shared/services/alarms.service';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { ChartColumnCustomization } from '@common-modules/wlm-charts/core/models/generic-chart-settings/chart-column-customization';
import { GCartesianChartSerie } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-cartesian-chart-series';
import { GChartLegend } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-legend';
import { GChartLineStyle } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-line-style';
import { GChartSerieDataPoint } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-serie-data-point';
import { GenericCartesianChartSettings } from '@common-modules/wlm-charts/core/models/generic-chart-settings/generic-cartesian-chart-settings';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ChartSerieTypeEnum } from '@common-modules/wlm-charts/core/models/chart-serie-type.enum';
import { EnvelopeType } from '../models/envelope-types.enum';

@Injectable({
  providedIn: 'root',
})
export class ProfilesChartSeriesService {
  T_SCOPE = `${AppModules.Alarms}.profile-chart-series-service`;

  minutesInHour = 60;

  private _timeAggregationForSignals = 1;
  private _timeAggregationForDaily = 1;
  private _naUnitTypeId = 50;

  private _dailyAlgorithmTranslationKey = 'daily-algorithm-category';

  constructor(
    private _dateHelper: DateHelperService,
    private _alarmService: AlarmsService,
    private _uomService: UoMService,
    private _localizationService: LocalizationHelperService,
    private _arrayHelper: ArrayHelperService
  ) {}

  mapProfilesToChartSettings(
    profiles: ProfileResultDto[]
  ): Observable<GenericCartesianChartSettings> {
    const daysCategories$ = this._localizationService.get(
      `${this.T_SCOPE}.${this._dailyAlgorithmTranslationKey}`
    );
    const severities$ = this._alarmService.getAlarmSeverityTypes();
    return combineLatest([daysCategories$, severities$]).pipe(
      switchMap(([daysCategories, severities]) => {
        const profileSeries$ = profiles.map((profile) => {
          return this._uomService
            .getByParams(
              profile.dimensionTypeId,
              profile.timeAggregationId,
              this.isHierarchyElement(profile) ? profile.hierarchyElementTypeId : null
            )
            .pipe(
              map((conversion) => {
                const profileSeries: GCartesianChartSerie[] = [];
                const profileSerie = this.getProfileSerie(profile, conversion, daysCategories);

                if (profileSerie) {
                  profileSeries.push(profileSerie);
                }

                if (profile.envelopesData) {
                  // tslint:disable-next-line: forin
                  for (const key in profile.envelopesData) {
                    const envelopeSerie = this.getEnvelopeSerie(
                      key as EnvelopeType,
                      profile,
                      profile.envelopesData[key],
                      severities,
                      conversion,
                      daysCategories
                    );

                    if (envelopeSerie) {
                      profileSeries.push(envelopeSerie);
                    }
                  }
                }

                return profileSeries;
              })
            );
        });

        const categoryAxisTitle$ = this._localizationService.get(`${this.T_SCOPE}.time`);

        return combineLatest([...profileSeries$, categoryAxisTitle$]).pipe(
          map(([profileSeries, title]) => {
            const series = profileSeries.reduce(
              (combined, current) => combined.concat(current),
              []
            );

            const columnCustomizations = [
              new ChartColumnCustomization({
                fieldName: 'pointCategory',
                titleKey: 'time',
              }),
            ];

            const chartSetttings = new GenericCartesianChartSettings({
              series,
              useCategoryAxis: true,
              categoryAxisTitle: title,
              useDataZoom: false,
              legend: new GChartLegend({
                data: series.map((x) => x.name),
                fontSize: '10',
                itemHeight: '10',
                bottom: '20',
              }),
              columnCustomizations: columnCustomizations,
            });

            return chartSetttings;
          })
        );
      })
    );
  }

  private getEnvelopeSerie(
    key: EnvelopeType,
    profile: ProfileResultDto,
    value: PeriodValue[],
    severities: IAlarmSeverityTypeDto[],
    unitConversion: UnitTypeConversionViewDto,
    daysCategories: { key: string; value: string }
  ) {
    if (!value?.length) {
      return null;
    }
    const envelopeSeverityId = this.getEnvelopeSeverityId(key, profile);

    const hasToConvert = profile.timeAggregationId === (TimeAggregationEnum.Base as number);
    const hasToTranslate = profile.timeAggregationId === (TimeAggregationEnum.Daily as number);

    let envelopeDataPoints = !value?.length
      ? []
      : this._arrayHelper.sortArrayObjectAscending(value, 'period').map((data) => {
          const dataPoint: GChartSerieDataPoint = {
            pointCategory: hasToConvert
              ? this.calculateDataPointCategory(data.period, profile)
              : hasToTranslate
              ? daysCategories[data.period]
              : data.period,
            pointValue: data.value,
          };
          return dataPoint;
        });

    envelopeDataPoints = hasToTranslate
      ? envelopeDataPoints
      : envelopeDataPoints.sort((a, b) => 0 - (a.pointCategory > b.pointCategory ? -1 : 1));

    const severityColor = severities.find(
      (x) => x.severityId === envelopeSeverityId
    )?.severityColor;

    const envelopeSerie = new GCartesianChartSerie({
      dataPoints: envelopeDataPoints,
      type: ChartSerieTypeEnum.Line,
      id: key,
      name: key,
      lineStyle: new GChartLineStyle({
        color: severityColor,
        type: 'dashed',
      }),
      itemStyle: new GChartLineStyle({
        color: severityColor,
      }),
      yAxisIndex: 0,
      xAxisIndex: 0,
      xAxisWLMDimensionTypeId: DimensionTypesEnum.NA,
      yAxisWLMDimensionTypeId: profile.dimensionTypeId,
      yAxisWLMUnitTypeIdFrom: unitConversion?.unitTypeFromId ?? this._naUnitTypeId,
      yAxisWLMUnitTypeIdTo: unitConversion?.unitTypeToId ?? this._naUnitTypeId,
      yAxisName: unitConversion?.unitTypeToDescription ?? '',
      symbol: 'none',

      // TODO: Complete
    });
    return envelopeSerie;
  }

  private getProfileSerie(
    profile: ProfileResultDto,
    unitConversion: UnitTypeConversionViewDto,
    daysCategories: { key: string; value: string }
  ) {
    if (!profile.profileData?.length) {
      return null;
    }

    const hasToConvert = profile.timeAggregationId === (TimeAggregationEnum.Base as number);
    const hasToTranslate = profile.timeAggregationId === (TimeAggregationEnum.Daily as number);

    let profileDataPoints = !profile.profileData?.length
      ? []
      : this._arrayHelper.sortArrayObjectAscending(profile.profileData, 'period').map((data) => {
          const dataPoint: GChartSerieDataPoint = {
            pointCategory:
              // Convert period only for base algorithms and signals
              hasToConvert
                ? this.calculateDataPointCategory(data.period, profile)
                : hasToTranslate
                ? daysCategories[data.period]
                : data.period,
            pointValue: data.value,
          };
          return dataPoint;
        });

    profileDataPoints = hasToTranslate
      ? profileDataPoints
      : profileDataPoints.sort((a, b) => 0 - (a.pointCategory > b.pointCategory ? -1 : 1));

    const profileSerie = new GCartesianChartSerie({
      dataPoints: profileDataPoints,
      type: ChartSerieTypeEnum.Line,
      id: profile.profileId,
      name: profile.profileName,
      yAxisIndex: 0,
      xAxisIndex: 0,
      xAxisWLMDimensionTypeId: DimensionTypesEnum.NA,
      yAxisWLMDimensionTypeId: profile.dimensionTypeId,
      yAxisWLMUnitTypeIdFrom: unitConversion?.unitTypeFromId ?? this._naUnitTypeId,
      yAxisWLMUnitTypeIdTo: unitConversion?.unitTypeToId ?? this._naUnitTypeId,
      yAxisName: unitConversion?.unitTypeToDescription ?? '',
    });
    return profileSerie;
  }

  private getEnvelopeSeverityId(key: EnvelopeType, profile: ProfileResultDto): number {
    let envelopeTypeSeverity: number;
    switch (key) {
      case EnvelopeType.Hi: {
        envelopeTypeSeverity = profile.hiSeverity;
        break;
      }
      case EnvelopeType.HiHi: {
        envelopeTypeSeverity = profile.hiHiSeverity;
        break;
      }
      case EnvelopeType.Low: {
        envelopeTypeSeverity = profile.lowSeverity;
        break;
      }
      case EnvelopeType.LowLow: {
        envelopeTypeSeverity = profile.lowLowSeverity;
        break;
      }
      default: {
        envelopeTypeSeverity = null;
        break;
      }
    }
    return envelopeTypeSeverity;
  }

  private calculateDataPointCategory(hour: number, profile: ProfileResultDto): number {
    let offset: number;
    if (profile.periodIsFixed && profile.periodEndDate) {
      offset = Math.abs(new Date(profile.periodEndDate).getTimezoneOffset()) / this.minutesInHour;
    } else {
      const endDate = this._dateHelper.getDateByDaysAgo(profile.periodDays);
      offset = Math.abs(endDate.getTimezoneOffset()) / this.minutesInHour;
    }

    const localHour = hour + offset >= 24 ? (hour + offset) % 24 : hour + offset;
    return localHour;
  }

  private isHierarchyElement(profile: ProfileResultDto): boolean {
    return typeof profile.hierarchyElementId !== 'undefined';
  }
}
