import { IFilter } from '@common-modules/shared/filters/component-filters/filter';

export class RaiseActivityTreeFilterConfiguration {
  hierarchyElementIdFieldName: string;
  hierarchyElementId: string[];
  hierarchyElementFamilyFieldName: string;
  hierarchyElementFamily: string;
  filters: IFilter[];
  persistencyArea: string;

  constructor(data?: Partial<RaiseActivityTreeFilterConfiguration>) {
    Object.assign(this, data);
  }
}
