<wlm-spinner-wrapper
  [showSpinner]="showSpinner"
  [ngClass]="{
    'custom-dialog-content-inline': styles?.contentInline
  }"
>
  <div class="popup-container">
    <div class="header-container">
      <div class="header-title">
        <h3 *ngIf="titleKey" class="title">{{ titleKey | translate : titleKeyParams }}</h3>
        <h3 *ngIf="title" class="title">{{ title }}</h3>
      </div>
      <div class="header-icons">
        <ng-content select="[popup-top-icons]"></ng-content>
      </div>
    </div>

    <hr *ngIf="titleKey || title" class="title-separator" />

    <mat-dialog-content [class]="'dialog-content ' + contentContainerClass ?? ''">
      <ng-content select="[popup-content]"></ng-content>
    </mat-dialog-content>

    <mat-dialog-actions [class]="'actions-container ' + actionsContainerClass ?? ''">
      <ng-content select="[popup-actions]"></ng-content>
      <button *ngIf="showCloseButton" mat-raised-button (click)="onClose(null)">
        {{ 'common.close' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</wlm-spinner-wrapper>
