import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SeasonDto } from '@common-modules/dependencies/shared/model/season.dto';
import { GenericCardSettings } from '@common-modules/shared-component/generic-card/generic-card-settings';
import { DragListSettings } from '@common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { SelectDragListSettings } from '@common-modules/shared/core/select-drag-list-virtual/select-drag-list-settings';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { SeasonService } from '../../service/season.service';

const COMPONENT_SELECTOR = 'wlm-season-selector';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './season-selector.component.html',
  styleUrls: ['./season-selector.component.scss'],
})
export class SeasonSelectorComponent {
  @Output() seasonSelected = new EventEmitter<any>();

  @Input() removeSelection$: Observable<void>;
  @Input() selectedSeasonId: string;

  private _seasonFieldName = 'seasonName';
  private _selectedFieldName = 'seasonId';

  private _pagesize = 50;

  settings: DragListSettings;
  cardSettings: GenericCardSettings<SeasonDto>;
  queryParams: Map<string, any> = new Map<string, any>();

  existingSeasons: SeasonDto[];
  selectedSeason: SeasonDto;
  filters = new DataBindingFilters();
  refreshList$ = new Subject<void>();
  reloadList$ = new Subject<void>();
  isLoading = false;

  constructor(private _seasonService: SeasonService) {}

  ngOnInit(): void {
    this.loadCalendars();
  }

  onSelectItem(selectedSeason: SeasonDto) {
    this.seasonSelected.emit(selectedSeason);
  }

  private loadCalendars() {
    this.isLoading = true;
    this._seasonService.getSeasons().subscribe({
      next: (seasons) => {
        this.existingSeasons = seasons;

        this.selectedSeason = this.existingSeasons.find(
          (x) => x.seasonId.toLocaleLowerCase() == this.selectedSeasonId?.toLocaleLowerCase()
        );

        this.reloadList$.next();

        this.prepareListsSettings();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  private prepareListsSettings() {
    this.settings = new SelectDragListSettings({
      isLocal: true,
      pageSize: this._pagesize,
      orderBy: [{ field: this._seasonFieldName, dir: 'asc' }],
      useQueryParams: false,
      oDataFiltersExtended: this.filters,
      displayFieldName: this._seasonFieldName,
      isReadOnly: false,
      selectedFieldName: this._selectedFieldName,
      filtrableFields: [this._seasonFieldName],
      disableSelection: false,
    });

    this.cardSettings = new GenericCardSettings<SeasonDto>({
      title1Fn: (model) => `${model.seasonName}`,
    });

    this.queryParams = new Map<string, any>();
  }
}
