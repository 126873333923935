import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { DynamicFormAdditionalSettings } from '@common-modules/dynamic-forms/models/dynamic-form-additional-settings';
import { DynamicFormSourceSettings } from '@common-modules/dynamic-forms/models/dynamic-form-source-settings';
import { SettingsEntityType } from '@common-modules/dynamic-forms/models/settings-entity-type';
import { DynamicLayoutComponent } from '@common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutItemSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-item-settings';
import { DynamicLayoutSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutService } from '@common-modules/dynamic-layout/services/dynamic-layout.service';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { StateFullSettings } from '@common-modules/redux/models/state-full-settings';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { ApplicationAttributes } from '@common-modules/shared/constants/application-constants';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { IPendingChangesChecker } from '@common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { waterLossAppNavigationPaths } from '@water-loss//water-loss-navigations';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttributeFormGroupService } from '../widgets/ne-config-attribute-form-group-widget/attribute-form-group.service';

const COMPONENT_SELECTOR = 'wlm-algorithms-configuration-page';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './algorithms-configuration-page.component.html',
  styleUrls: ['./algorithms-configuration-page.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService, AttributeFormGroupService],
})
export class AlgorithmsConfigurationPageComponent
  extends BasePageComponent
  implements OnInit, IPendingChangesChecker, OnDestroy
{
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  public T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }
  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WLMAlgorithmsCrud';
  }

  pageId: string;
  navigations: DropdownNavigationItem[] = [];
  categories: string[];
  layoutSettings: DynamicLayoutSettings;

  private _groupName: string;
  private readonly _cleanCachedUrl = 'algorithm/attributes';
  private readonly _layoutKey = 'LayoutAttributesGroup';
  private readonly _layoutArea = 'NetworkElementConfiguration';
  private readonly _widgetPage = 'algorithms-page';
  private readonly _widgetModule = 'algorithms';
  private readonly _area = 'ne-config';
  private readonly _categoriesLabelsPath = `${this.T_SCOPE}.forms`;
  private readonly _attributesLabelsPath = `${AppModules.Configuration}.attributes`;
  private readonly _entityNameKey = 'common.entities.configuration';
  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });

  constructor(
    private _localization: LocalizationHelperService,
    private _state: ReduxStateService,
    private _authenticationService: AuthenticationService,
    private _dynamicLayoutService: DynamicLayoutService,
    private _spinnerService: SpinnerService,
    private _attributeFormGroupService: AttributeFormGroupService,
    private _pendingChangesService: PendingChangesManagerService,
    private _router: Router,
    private _globalsService: GlobalsService
  ) {
    super();
    this.hasRightPanel = false;

    this.pageId = this._widgetPage;
    this._attributeFormGroupService.init({
      state: this._state,
      scopeSettings: this._scopeSettings,
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onLayoutInit();
  }

  onLayoutInit(): void {
    const fullState = this.buildFullSettings({ area: this._area });
    this._state.configure(fullState.widget);
    this._globalsService
      .getApplicationAttributesById(ApplicationAttributes.CompanyHierarchyElemName)
      .subscribe((attribute) => {
        this._groupName = attribute.attributeValue;
        this.loadAttributesForms();
      });
  }

  onClickResetLayout(): void {
    this.checkPendingChanges(this.pageId).subscribe(() => {
      this._dynamicLayoutService.resetAll();
    });
  }

  onResetLayout() {
    this.loadAttributesForms();
  }

  canLeavePage(): Observable<boolean> {
    return this.checkPendingChanges(this.pageId);
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }

  goToIntegrationPage(): void {
    this._router.navigate([waterLossAppNavigationPaths[NavKeys.Integration]]);
  }

  private setLoading(loading: boolean): void {
    this._spinnerService.setLoading(loading, this.persistencyArea);
  }

  private loadAttributesForms(): void {
    this.setLoading(true);

    const attributesSource = this.buildAttributesSource();

    const additionalSettings: DynamicFormAdditionalSettings = {
      entityId: this._groupName,
    };

    this._attributeFormGroupService
      .dispatchFormGroup(attributesSource, additionalSettings)
      .subscribe(({ visibleCategories, isCached }) => {
        this.buildDynamicLayoutSettings(visibleCategories);
        this.setLoading(false);
      });
  }

  private buildAttributesSource(): DynamicFormSourceSettings {
    const result = new DynamicFormSourceSettings({
      entityType: SettingsEntityType.Algorithms,
      elementTypeId: '',
      categoriesLabelsPath: this._categoriesLabelsPath,
      attributesLabelsPath: this._attributesLabelsPath,
      formState: {},
      entityNameKey: this._entityNameKey,
      cleanCachedUrl: this._cleanCachedUrl,
    });
    return result;
  }

  private buildFullSettings(newAction) {
    return new StateFullSettings({
      widget: new StateWidgetSettings({
        module: this._widgetModule,
        page: this._widgetPage,
        scopeInstanceKeys: {
          [StateAreas.NEConfig]: this._area,
        },
      }),
      area: newAction.area,
    });
  }

  private buildDynamicLayoutSettings(categories: string[]): void {
    this.buildTitles().subscribe((titles) => {
      const items: DynamicLayoutItemSettings[] = categories.map((categoryKey) => {
        const componentName = 'NeConfigAttributeFormWidgetComponent';
        const result = {
          componentName,
          widgetInstanceKey: `${componentName}#${categoryKey}`,
          title: titles.get(categoryKey),
          params: {
            categoryKey,
          },
          scopeInstanceKeys: {
            [StateAreas.NEConfig]: this._area,
          },
        } as DynamicLayoutItemSettings;

        return result;
      });

      this.layoutSettings = new DynamicLayoutSettings({
        layoutKey: this._layoutKey,
        layoutArea: this._layoutArea,
        currentUser: this._authenticationService.userCode,
        widgetPage: this._widgetPage,
        widgetModule: this._widgetModule,
        items,
      });
    });
  }

  private buildTitles(): Observable<Map<string, string>> {
    return this._localization.get(`${this.T_SCOPE}.forms`).pipe(
      map((ts) => {
        const titles = new Map();
        Object.entries(ts).forEach(([key, value]) => {
          titles.set(key, value);
        });
        return titles;
      })
    );
  }

  init(): void {}

  mapInitParameters(parameters: TabDetailPanelParameters) {}
}
