import { Injectable, Injector } from '@angular/core';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { ChartSerieTypeEnum } from '@common-modules/wlm-charts/core/models/chart-serie-type.enum';
import { GCartesianChartSerie } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-cartesian-chart-series';
import { GChartSerieDataPoint } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-serie-data-point';
import { GenericCartesianChartSettings } from '@common-modules/wlm-charts/core/models/generic-chart-settings/generic-cartesian-chart-settings';
import { TrendChartDataParameters } from '@common-modules/wlm-charts/core/models/trend-chart-data-parameters';
import { BaseCartesianChartService } from '@common-modules/wlm-charts/core/services/base-cartesian-chart.service';
import { asEnumerable } from 'linq-es2015';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { WaterBalanceKPIEnum } from '../../shared/model/water-balance/water-balance-kpi-types';
import { WaterBalanceTrendChartDto } from '../../shared/model/water-balance/water-balance-trend-chart.dto';
@Injectable()
export class WaterBalanceChartService extends BaseCartesianChartService {
  private mlUnitTypeId = 34;
  private mlPerDayUnitTypeId = 3;
  private readonly _naUnitTypeId = 50;

  private readonly _unitLabelsTs = 'common.unit-labels';

  private readonly _unitLabels = {
    [WaterBalanceKPIEnum.WaterLossPerMainLength]: 'Ml/Km',
    [WaterBalanceKPIEnum.RealLossPerMainLength]: 'Ml/Km',
    [WaterBalanceKPIEnum.ApparentLossPerMainLength]: 'Ml/Km',
    [WaterBalanceKPIEnum.WaterLossPerConnection]: 'Ml/Connection',
    [WaterBalanceKPIEnum.RealLossPerConnection]: 'Ml/Connection',
    [WaterBalanceKPIEnum.ApparentLossPerConnection]: 'Ml/Connection',
    [WaterBalanceKPIEnum.WaterLossPercentageDI]: '%',
    [WaterBalanceKPIEnum.RealLossPercentageDI]: '%',
    [WaterBalanceKPIEnum.ApparentLossPercentageDI]: '%',
  };

  private readonly _percentageKPIs = [
    WaterBalanceKPIEnum.WaterLossPercentageDI,
    WaterBalanceKPIEnum.RealLossPercentageDI,
    WaterBalanceKPIEnum.ApparentLossPercentageDI,
  ];
  private readonly _percentageKPIsDecimals = 2;

  constructor(
    injector: Injector,
    private dateHelperService: DateHelperService,
    private localization: LocalizationHelperService,
    private _uomService: UoMService
  ) {
    super(injector);
  }

  private get url() {
    return `${this.apiUrl}/water-balance`;
  }

  public getData(params: TrendChartDataParameters): Observable<any[]> {
    const queryParams = {
      startDate: this.dateHelperService.toApiFormatNoUTC(params.startDate),
      endDate: this.dateHelperService.toApiFormatNoUTC(params.endDate),
      dimensionTypeId: params.queryParams.dimensionTypeId,
      hierarchyElementIds: params.queryParams.hierarchyElementIds,
      kpis: params.queryParams.kpis,
      waterBalanceType: params.queryParams.waterBalanceType,
      familyId: params.queryParams.familyId,
    };
    return this.httpCacheClient.get<WaterBalanceTrendChartDto[]>(
      `${this.url}/trend-chart`,
      this.avoidCache,
      queryParams
    );
  }
  public mapDataToGenericSettings(
    data: WaterBalanceTrendChartDto[]
  ): Observable<GenericCartesianChartSettings> {
    const singleKpi = data && data.length !== 0 ? data[0].kpi : null;
    // In this chart, serie names are KPI labels.
    return forkJoin([
      this.localization.get(`${AppModules.WaterBalance}.kpis`),
      ...this.loadConversions(data),
    ]).pipe(
      map(([kpiLabels, ...conversions]) => {
        return data.map((values) => {
          const conversion: UnitTypeConversionViewDto = conversions.find(
            (x: UnitTypeConversionViewDto) =>
              x.hierarchyElementTypeId == values.hierarchyElementTypeId &&
              x.dimensionTypeId == values.dimensionTypeId &&
              x.timeAggregationId == values.timeAggregationId
          );

          return this._uomService
            .getFormatedUnit(
              conversion?.unitTypeToDescription ?? '',
              `${this._unitLabelsTs}.${values.labelKey}`,
              values.unitTypeFormat
            )
            .pipe(
              map((unitFormatedLabel) => {
                const isCategoryKpi = values.groupKey == 'HierarchyElementId';

                let dataPoints: GChartSerieDataPoint[] = [];

                for (const wbValue of values.values) {
                  const dataPoint: GChartSerieDataPoint = {
                    pointCategory: isCategoryKpi ? wbValue.key : new Date(wbValue.key),
                    pointValue: wbValue.value,
                  };
                  dataPoints.push(dataPoint);
                }

                if (isCategoryKpi) {
                  dataPoints = asEnumerable(dataPoints)
                    .OrderByDescending((x) => x.pointValue)
                    .ToArray();
                }

                const kpiLabel = this.getSerieName(values.kpi, kpiLabels);
                const decimalPositions = this.isPercentageKPI(values.kpi)
                  ? this._percentageKPIsDecimals
                  : null;

                const cartesianSerie: GCartesianChartSerie = {
                  name: kpiLabel,
                  type: isCategoryKpi ? ChartSerieTypeEnum.Bar : ChartSerieTypeEnum.Line,
                  isCategorySerie: isCategoryKpi,
                  xAxisName: isCategoryKpi ? kpiLabels['zone-category'] : undefined,
                  xAxisIndex: 0,
                  yAxisIndex: 0,
                  xAxisWLMDimensionTypeId: DimensionTypesEnum.NA,
                  yAxisWLMDimensionTypeId: values.dimensionTypeId,
                  yAxisWLMUnitCustomLabel: unitFormatedLabel, // Overrides unit label
                  yAxisWLMUnitTypeIdTo: conversion?.unitTypeToId ?? this._naUnitTypeId,
                  yAxisWLMUnitTypeIdFrom: conversion?.unitTypeFromId ?? this._naUnitTypeId,
                  dataPoints,
                  showInUtc: !isCategoryKpi,
                  decimalPositions,
                };
                return cartesianSerie;
              })
            );
        });
      }),
      switchMap((series$) => {
        return forkJoin(series$).pipe(
          map((series) => {
            //This is due that we don't currently have multiples xAxis. So we configure the chart for categories
            const categorySerie = asEnumerable(series).FirstOrDefault((x) => x.isCategorySerie);

            return new GenericCartesianChartSettings({
              series,
              useCategoryAxis: categorySerie?.isCategorySerie,
              useDataZoom: !categorySerie?.isCategorySerie,
              categoryAxisTitle: categorySerie?.xAxisName,
            });
          })
        );
      })
    );
  }

  loadConversions(series: WaterBalanceTrendChartDto[]): Observable<UnitTypeConversionViewDto>[] {
    const uoms$: Observable<UnitTypeConversionViewDto>[] = [];
    series.forEach((serie) => {
      if (serie.dimensionTypeId && serie.timeAggregationId) {
        const uom$ = this._uomService
          .getByParams(serie.dimensionTypeId, serie.timeAggregationId, serie.hierarchyElementTypeId)
          .pipe(take(1));
        uoms$.push(uom$);
      }
    });

    return uoms$;
  }

  private getSerieName(kpi: number, kpiLabels: { [key: string]: string }): string {
    // KPI labels are built like "kpi-{n}", so we use it to search the correct label.
    for (let labelKey in kpiLabels) {
      if (labelKey.startsWith(`kpi-${kpi}-`)) {
        return kpiLabels[labelKey];
      }
    }
    throw new Error('The serie does not have an associated kpi label.');
  }

  /**
   * Some KPIs must show a custom unit label (Ml/Connection) instead of a label of a registered unit type.
   * These kind of labels can be translated.
   */
  private getKPICustomUnitLabel(kpi: WaterBalanceKPIEnum): Observable<string> {
    if (!kpi || typeof this._unitLabels[kpi] === 'undefined') {
      return of(null);
    }

    const labelKey = this._unitLabels[kpi];
    const labelPath = [this._unitLabelsTs, labelKey].join('.');
    return this.localization.get(labelPath);
  }

  private getKPIUnitTypeId(dimenstionTypeId: number) {
    return dimenstionTypeId === DimensionTypesEnum.Flow
      ? this.mlPerDayUnitTypeId
      : this.mlUnitTypeId;
  }

  private isPercentageKPI = (kpi: WaterBalanceKPIEnum): boolean => {
    const found = this._percentageKPIs.find((item) => item === kpi);
    return !!found;
  };
}
