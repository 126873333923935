import { ChangeDetectorRef, inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export class IntegrableFormParams {
  op?: 'create' | 'update';
  filters?: any;
}

export abstract class IntegrableForm {
  protected _isValid$ = new ReplaySubject<boolean>(1);
  readonly isValid$ = this._isValid$.asObservable();

  private _hasChanges$ = new ReplaySubject<boolean>(1);
  readonly hasChanges$ = this._hasChanges$.asObservable();

  private _isDisabled$ = new ReplaySubject<boolean>(1);
  readonly isDisabled$ = this._isDisabled$.asObservable();

  protected readonly _cd = inject(ChangeDetectorRef);

  detectChanges(): void {
    this._cd.detectChanges();
  }

  setIsValid(isValid: boolean): void {
    this._isValid$.next(isValid);
  }

  setHasChanges(hasChanges: boolean): void {
    this._hasChanges$.next(hasChanges);
  }

  setDisabled(isDisabled): void {
    this._isDisabled$.next(isDisabled);
  }

  abstract setParams(params: IntegrableFormParams): void;

  abstract getModel(): any;

  abstract setInitialModel(model): void;
}
