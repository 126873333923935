import { Component, Input, OnInit } from '@angular/core';
import { ActivityCategoryFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/activity-category-f-adapter';
import { ActivityStatusFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/activity-status-f-adapter';
import { DateRangeFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/date-range-f-adapter';
import { HierarchyElementPathFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FiltersAdapterService } from '@common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { TreeSettingsService } from '@common-modules/shared/services/tree-settings.service';
import { ActivityGridFilterConfiguration } from './activity-grid-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-activity-grid-filter';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './activity-grid-filter.component.html',
  styleUrls: ['./activity-grid-filter.component.scss'],
})
export class ActivityGridFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _activityFilterConfiguration: ActivityGridFilterConfiguration;
  public get activityFilterConfiguration(): ActivityGridFilterConfiguration {
    return this._activityFilterConfiguration;
  }
  @Input() public set activityFilterConfiguration(v: ActivityGridFilterConfiguration) {
    this._activityFilterConfiguration = v;
    if (v) {
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  // Communicates hierarchy-family-filter-item with hierarchy-tree-filter-item.
  hierarchyElementFamilyId: string;

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  mustPersistFilters = false;

  treeSettings: ITreeSettings = null;
  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
  };
  bf1Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-1-label`,
    disableSelectAll: true,
    disableSearch: true,
  };
  bf2Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-2-label`,
    disableSelectAll: true,
  };
  bf3Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-3-label`,
    disableSelectAll: true,
  };

  dateRangeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    storageLocation: 'session',
  });

  hierarchyFamilyItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
    required: true,
  });
  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
  });
  activityStatusItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });
  activityTypesItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  // The fields that must be checked so that autoload can be performed.
  requiredFilterFields = ['HierarchyElementId'];

  constructor(
    private adaptersService: FiltersAdapterService,
    private treeSettingsService: TreeSettingsService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  ngOnInit(): void {
    this.hierarchyElementFamilyId = this.activityFilterConfiguration.hierarchyElementFamily;
    this.setFiltersPersistencyArea();
    this.buildTreeSettings();
  }

  setFiltersPersistencyArea(): void {
    this.bf1Settings.persistencyArea = this.activityFilterConfiguration?.persistencyArea;
    this.bf2Settings.persistencyArea = this.activityFilterConfiguration?.persistencyArea;
    this.bf3Settings.persistencyArea = this.activityFilterConfiguration?.persistencyArea;
  }

  buildTreeSettings(): void {
    this.treeSettingsService
      .buildTreeSettings()
      .subscribe((settings) => (this.treeSettings = settings));
  }

  /**
   * Saves the family Id so the tree filter can obtain it.
   * Only contains the activity Id filter.
   */
  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName = this.activityFilterConfiguration.hierarchyElementFamilyFieldName;
    const defaultId = this.activityFilterConfiguration.hierarchyElementFamily;
    this.hierarchyElementFamilyId = this.adaptersService.getFamilyId(
      filters,
      familyFieldName,
      defaultId
    );
  }

  onFilterText(filterText: string): void {
    this.filterText = filterText;
  }

  onTreeFilterReady() {
    if (this.mustPersistFilters) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
    }
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const { hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.activityFilterConfiguration;

    const { startDateFieldName, endDateFieldName, allowsDateNull } =
      this.activityFilterConfiguration;

    const { activityStatusFieldName } = this.activityFilterConfiguration;

    const { repairFieldName, detectionFieldName } = this.activityFilterConfiguration;

    const adapters = [
      new ActivityCategoryFAdapter({
        dataBindingField: 'activities',
        repairFieldName,
        detectionFieldName,
      }),
      new ActivityStatusFAdapter({
        dataBindingField: 'activityStatus',
        activityStatusFieldName,
      }),
      new HierarchyElementPathFAdapter({
        dataBindingField: 'hierarchyElementsIds',
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId: this.hierarchyElementFamilyId,
      }),
      new DateRangeFAdapter({
        dataBindingField: 'dateRange',
        endDateFieldName,
        startDateFieldName,
        allowsNullDate: allowsDateNull,
        inclusive: true,
      }),
    ];
    return adapters;
  };

  buildKeysToComplete(): void {
    const { hierarchyElementIdFieldName, activityStatusFieldName } =
      this.activityFilterConfiguration;
    this.keysToComplete = [hierarchyElementIdFieldName, activityStatusFieldName];
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }
}
