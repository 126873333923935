import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataUrlManagerService } from '@common-modules/shared/services/data-url-manager.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { finalize } from 'rxjs';
import { GridExportHelperService } from '../../services/grid-export-helper.service';

@Component({
  selector: 'wlm-image-column-cell',
  templateUrl: './image-column-cell.component.html',
  styleUrl: './image-column-cell.component.scss',
})
export class ImageColumnCellComponent implements OnInit {
  @Input() imageElements;
  @Input() dataItem;
  @Input() field;
  @ViewChild('columnImage') columnImageElement: ElementRef;

  dataUrlImage: string;
  enableDataUrl: boolean = false;
  setLoading: (isLoading: boolean) => void;

  private readonly _cd = inject(ChangeDetectorRef);

  constructor(
    private readonly _destroyRef: DestroyRef,
    private readonly _gridExportHelperService: GridExportHelperService,
    private readonly _dataUrlManagerService: DataUrlManagerService,
    private readonly _spinnerService: SpinnerService
  ) {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {
    this._gridExportHelperService.notifyExport$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((isExporting) => {
        this.enableDataUrl = isExporting;
        const key = this.buildKey();

        if (this.enableDataUrl && this.columnImageElement) {
          this.setLoading(true);
          this._dataUrlManagerService
            .getFromElement(key, this.columnImageElement.nativeElement)
            .pipe(finalize(() => this.setLoading(false)))
            .subscribe((dataUrl) => {
              if (this.imageElements) {
                this.dataUrlImage = dataUrl;
                this.setLoading(false);
                this._cd.detectChanges();
              }
            });
        }
      });
  }

  private buildKey = () => `${this.field}.${this.dataItem[this.field]}`;
}
