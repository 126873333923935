import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FilterItemSelectOption } from '@common-modules/common-filters/models/filter-item-select-option';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { SharedConstantsService } from '@common-modules/shared/constants/shared-constants.service';
import { SelectOption } from '@common-modules/shared/model/shared/select-option';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../../core/base-select-filter-item/base-select-filter-item.component';
import { AdaptedFilterItem } from '../../core/hooks/adapted-filter-item';

const COMPONENT_SELECTOR = 'wlm-element-target-filter-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: '../../core/default/templates/filter-item-select.template.html',
  styleUrls: ['./element-target-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => ElementTargetFilterItemComponent),
    },
  ],
})
export class ElementTargetFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, OnDestroy, AdaptedFilterItem
{
  @Input() alternativeAdapter: FilterAdapterEnum | null = null;
  @Input() defaultSelectedIds: number[] = [];
  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);
  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;
  mode = 'single';

  constructor(private sharedConstants: SharedConstantsService) {
    super();
  }

  ngOnInit(): void {
    this.data$$.next(this.getData$());
    super.onInit();
  }

  getData$(): Observable<FilterItemSelectOption[]> {
    return this.sharedConstants.getElementTargetsArray().pipe(
      map((data: SelectOption<number>[]) => {
        const results = data.map((item) => new FilterItemSelectOption(item.value, item.label));
        return results;
      })
    );
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  isValid(): boolean {
    return this.selectedElements && this.selectedElements.length !== 0;
  }

  getFieldNames(): string[] {
    return [this.fieldName];
  }

  getAdapter(): FilterAdapterEnum {
    if (this.alternativeAdapter) {
      return this.alternativeAdapter;
    }

    return FilterAdapterEnum.ElementTarget;
  }
}
