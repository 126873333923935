import { Component, Injector, inject } from '@angular/core';
import { IActivityTypeDto } from '@common-modules/dependencies/alc/activity-type.dto';
import { GenericCrudSettings } from '@common-modules/generic-crud/generic-crud-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { ValidationHelperService } from '@common-modules/shared/helpers/validation-helper.service';
import { Observable, map } from 'rxjs';
import { ActivityTypesCrudService } from '../activity-types-crud.service';
import { ActivityTypesFormComponent } from '../activity-types-form/activity-types-form.component';

const COMPONENT_SELECTOR = 'wlm-activity-types-crud';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './activity-types-crud.component.html',
  styleUrls: ['./activity-types-crud.component.scss'],
})
export class ActivityTypesCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);
  private readonly _activityTypesCrudService = inject(ActivityTypesCrudService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  private readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'ActivityTypesCrudService',
      grid: {
        gridSettingsName: 'ActivityTypesCrud',
      },
      create: {
        formComponent: ActivityTypesFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      update: {
        formComponent: ActivityTypesFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(false),
      },
      delete: {},
    });
  }

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
    (model: IActivityTypeDto): Observable<IActivityTypeDto | null> => {
      return this._activityTypesCrudService.validate(model, isCreate).pipe(
        map((validation) => {
          if (validation.isDuplicatedId) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.activity-type-id`
            );
            return null;
          }
          return model;
        })
      );
    };
}
