import { Injectable, Injector } from '@angular/core';
import { DynamicFormGroupCategories } from '@common-modules/dependencies/ne-configuration/dynamic-form-group-categories';
import { DynamicFormSettings } from '@common-modules/dynamic-forms/models/dynamic-form-settings';
import { StateSelectorBuilder } from '@common-modules/redux/models/state-selector-builder';
import { BaseStore, ReducerHandlerFn } from '@common-modules/redux/store/base.store';
import { ActionCreator } from '@ngrx/store';
import { NEConfigState } from './ne-config-state';
import { NEConfigActionTypes } from './ne-config.action-types';
import {
  NE_CONFIG_ACTION_CREATORS,
  ResetHasChangesAction,
  SelectNetworkElementConfigAction,
  SetAttributesSourceAction,
  SetBoundarySettingsAction,
  SetFormAdditionalSettingsAction,
  SetFormDefinitionsAction,
  SetFormUIValuesAction,
  SetPressureSettingsAction,
} from './ne-config.actions';
import { NEConfigSelectorTypes } from './ne-config.selector-types';

@Injectable()
export class NEConfigStoreService extends BaseStore {
  readonly serviceName = 'NEConfigStoreService';

  constructor(injector: Injector) {
    super(injector);
  }

  protected getReducer(): Map<string, ReducerHandlerFn> {
    return new Map<string, ReducerHandlerFn>([
      [
        NEConfigActionTypes.SelectNetworkElement,
        (state: NEConfigState, action: SelectNetworkElementConfigAction) => {
          state.selectedNE = action.payload;
        },
      ],
      [
        NEConfigActionTypes.SetAttributesSource,
        (state: NEConfigState, action: SetAttributesSourceAction) => {
          state.formSource = action.payload;
        },
      ],
      [
        NEConfigActionTypes.SetBoundarySettings,
        (state: NEConfigState, action: SetBoundarySettingsAction) => {
          state.boundarySettings = action.payload;
        },
      ],
      [
        NEConfigActionTypes.SetFormDefinitions,
        (state: NEConfigState, action: SetFormDefinitionsAction) => {
          state.formDefinitions = {};

          if (action.payload) {
            const { formSettings, fieldsByCategories } =
              action.payload as DynamicFormGroupCategories;

            fieldsByCategories.forEach((fieldsByCategory) => {
              state.formDefinitions[fieldsByCategory.categoryKey] = new DynamicFormSettings({
                ...formSettings,
                fieldsByCategory,
                saveOnChanges: false,
              });
            });
          }
        },
      ],
      [
        NEConfigActionTypes.SetFormUIValues,
        (state: NEConfigState, action: SetFormUIValuesAction) => {
          state.uiValues = action.payload;
        },
      ],
      [
        NEConfigActionTypes.ResetHasChanges,
        (state: NEConfigState, action: ResetHasChangesAction) => {
          state.hasChangesMap = {};
        },
      ],
      [
        NEConfigActionTypes.SetPressureSettings,
        (state: NEConfigState, action: SetPressureSettingsAction) => {
          state.pressureSettings = action.payload;
        },
      ],
      [
        NEConfigActionTypes.SetFormAdditionalSettings,
        (state: NEConfigState, action: SetFormAdditionalSettingsAction) => {
          state.formAdditionalSettings = action.payload;
        },
      ],
    ] as any);
  }

  protected getSelectors(): Map<string, StateSelectorBuilder> {
    return new Map<string, StateSelectorBuilder>([
      [
        NEConfigSelectorTypes.SelectedNetworkElement,
        {
          fn: (state: NEConfigState) => {
            return state?.selectedNE;
          },
        },
      ],
      [
        NEConfigSelectorTypes.SetAttributesSource,
        {
          fn: (state: NEConfigState) => {
            return state?.formSource;
          },
        },
      ],
      [
        NEConfigSelectorTypes.BoundarySettings,
        {
          fn: (state: NEConfigState) => {
            return state?.boundarySettings;
          },
        },
      ],
      [
        NEConfigSelectorTypes.FormDefinitions,
        {
          fn: (state: NEConfigState) => {
            return state?.formDefinitions;
          },
        },
      ],
      [
        NEConfigSelectorTypes.UIValues,
        {
          fn: (state: NEConfigState) => {
            return state?.uiValues;
          },
        },
      ],
      [
        NEConfigSelectorTypes.PressureSettings,
        {
          fn: (state: NEConfigState) => {
            return state?.pressureSettings;
          },
        },
      ],
      [
        NEConfigSelectorTypes.FormAdditionalSettings,
        {
          fn: (state: NEConfigState) => {
            return state?.formAdditionalSettings;
          },
        },
      ],
    ]);
  }

  protected getActionCreators(): ActionCreator<any>[] {
    return NE_CONFIG_ACTION_CREATORS;
  }
}
