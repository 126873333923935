import { Injector, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import { AuthGuard } from '@common-modules/shared/auth/services/auth.guard';
import { BiHomePageComponent } from './components/bi-home-page/bi-home-page.component';
import { BiPageComponent } from './components/bi-page/bi-page.component';

const routes: Routes = [
  {
    path: '',
    component: BiPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.Bi,
    },
  },
  {
    path: 'home',
    component: BiHomePageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.Bi,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BiRoutingModule {
  static injector: Injector;

  constructor(injector: Injector) {
    BiRoutingModule.injector = injector;
  }
}
