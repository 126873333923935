import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { ConstantsValues } from '@common-modules/shared/constants/constants-values';
import { SharedConstantsService } from '@common-modules/shared/constants/shared-constants.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { ValidationHelperService } from '@common-modules/shared/helpers/validation-helper.service';
import { SelectOption } from '@common-modules/shared/model/shared/select-option';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, forkJoin } from 'rxjs';

import { EnvelopeMode } from '@common-modules/dependencies/alarms/envelope-modes.enum';
import { EnvelopePersistencyFactor } from '@common-modules/dependencies/alarms/envelope-persistency-factor.enum';
import { EnvelopesConfiguration } from '@common-modules/dependencies/alarms/envelopes-configuration';
import { TimeAggregationEnum } from '@common-modules/shared/model/algorithm/time-aggregation.enum';
import { EnvelopesConfigurationForm } from '../models/envelope-configuration-form';
import { EnvPersistencyConfiguration } from '../models/envelope-persistency-configuration';

const COMPONENT_SELECTOR = 'wlm-envelopes-config';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './envelopes-config.component.html',
  styleUrls: ['./envelopes-config.component.scss'],
})
export class EnvelopesConfigComponent implements OnInit {
  @Input() set model(model: EnvelopesConfiguration) {
    if (model) {
      this.mode = model.mode;
      let data = new EnvelopesConfigurationForm({ ...model });
      data = this.calculateDefaultPersistency(data);
      this.form.reset({}, { emitEvent: false });
      this.form.patchValue(data);
      this.updateFieldSuffix();
    }
  }

  @Input() public set uoMParams(params: {
    dimensionTypeId: number;
    timeAggregation: number;
    hierarchyElementTypeId: string;
  }) {
    if (params) {
      this._uomService
        .getByParams(
          params.dimensionTypeId,
          params.timeAggregation ?? TimeAggregationEnum.Base,
          params.hierarchyElementTypeId ?? this._signalTypeId
        )
        .subscribe((unitType) => {
          if (unitType) {
            this.mainFieldUnitName = unitType.unitTypeToDescription;
            this.updateFieldSuffix();
          }
        });
    }
  }

  private _modesToRemove: EnvelopeMode[];
  public get modesToRemove(): EnvelopeMode[] {
    return this._modesToRemove;
  }
  @Input() public set modesToRemove(v: EnvelopeMode[]) {
    this._modesToRemove = v;
    this.getEnvelopeModesFiltered(v);
  }

  private _disabled = false;
  public get disabled() {
    return this._disabled;
  }
  @Input() public set disabled(value) {
    this._disabled = value;
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() clearAll$ = new Observable<void>();
  @Input() reset$ = new Observable<void>();
  @Output() valueChanges = new EventEmitter<EnvelopesConfiguration>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() isDefault = new EventEmitter<boolean>();

  private _defaultModel: EnvelopesConfiguration;
  public get defaultModel(): EnvelopesConfiguration {
    return this._defaultModel;
  }
  @Input() public set defaultModel(value: EnvelopesConfiguration) {
    this._defaultModel = this._objectHelper.clone(value);
    this.model = value;
  }
  defaultMode = EnvelopeMode.Adaptative;
  mode: EnvelopeMode;
  mainFieldSuffix = '%';
  mainFieldUnitName: string;
  envelopeNames = ['hiHi', 'hi', 'low', 'lowLow'];
  form: UntypedFormGroup;
  fieldAppearance = 'outline';
  envelopeModes = EnvelopeMode;
  modesOptions: SelectOption<number>[] = [];
  severitiesOptions: SelectOption<number>[] = [];
  persistencyOptions: SelectOption<number>[] = [];
  flatRelativeOptions: SelectOption<number>[] = [];
  secondsInAMinute = 60;
  secondsInAnHour = 60 * 60;
  secondsInADay = 60 * 60 * 24;
  defaultPersistencyFactor = EnvelopePersistencyFactor.Minutes;
  mainFieldValidators = [];

  private readonly _signalTypeId = ConstantsValues.emptyGuid;

  T_SCOPE = `${AppModules.Alarms}.${COMPONENT_SELECTOR}`;

  constructor(
    private _fb: UntypedFormBuilder,
    private _sharedConstantsService: SharedConstantsService,
    private _uomService: UoMService,
    private _objectHelper: ObjectHelperService,
    public customValidators: ValidationHelperService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.bindHooks();
    this.loadData();
    this.listenChanges();
    this.updateMainFieldValidators();

    this.emitFormValue();
  }

  resetToDefaultModel() {
    this.form.reset({}, { emitEvent: false });
    this.mode = this.defaultMode;
    this.model = this.defaultModel;
    this.onModeChange();
  }

  onModeChange(emit = true): void {
    this.updateFieldSuffix();
    this.updateMainFieldValidators();
    // Only actual values (absolute and relative) are reset.
    this.envelopeNames.forEach((envelopeName) => {
      const keysToReset = [
        this.getMainValueKey(envelopeName),
        this.getRelativeModeKey(envelopeName),
      ];
      // Disable valueChanges notifications so that the listeners are not all triggered.
      keysToReset.forEach((key) => this.form.get(key)?.reset(null, { emitEvent: false }));
    });

    if (emit) {
      this.emitFormValue();
    }
  }

  updateMainFieldValidators(): void {
    if (this.mode === EnvelopeMode.FlatAbsolute) {
      this.mainFieldValidators = this.buildValidators([this.customValidators.maxDigits(5)]);
    } else {
      this.mainFieldValidators = this.buildValidators([
        this.customValidators.onlyPositiveNumber,
        this.customValidators.maxDigits(5),
      ]);
    }
  }

  private buildValidators(validators: any[]): any[][] {
    return [[this.customValidators.onlyNumeric, ...validators]];
  }

  private loadData(): void {
    const constants = this._sharedConstantsService;
    forkJoin([
      constants.getEnvelopeModesArray(),
      constants.getSeveritiesArray(),
      constants.getEnvelopePersistencyFactorArray(),
      constants.getEnvelopeRelativeModeArray(),
    ]).subscribe(([modes, severities, persistency, relativeModes]) => {
      this.modesOptions = modes;
      this.addEmptyOption(severities);
      this.severitiesOptions = severities;
      this.addEmptyOption(persistency);
      this.persistencyOptions = persistency;
      this.addEmptyOption(relativeModes);
      this.flatRelativeOptions = relativeModes;
    });
  }

  private listenChanges(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
      this.emitFormValue();
    });

    // When a persistency temporal value changes, calculate the actual value applying the factor.
    this.subscribeToKey(this.getPersistencyFactorKey, (envelopeName, factorKey) => {
      const factorValue = this.form.get(factorKey).value;
      const temporalKey = this.getPersistencyTemporalKey(envelopeName);
      const temporalValue = this.form.get(temporalKey).value;
      this.updatePersistency(envelopeName, temporalValue, factorValue);
    });
    this.subscribeToKey(this.getPersistencyTemporalKey, (envelopeName, temporalKey) => {
      const temporalValue = this.form.get(temporalKey).value;
      const factorKey = this.getPersistencyFactorKey(envelopeName);
      const factorValue = this.form.get(factorKey).value;
      this.updatePersistency(envelopeName, temporalValue, factorValue);
    });
  }

  private bindHooks(): void {
    this.clearAll$.pipe(untilDestroyed(this)).subscribe(() => {
      this.clearAll();
    });
    this.reset$.pipe(untilDestroyed(this)).subscribe(() => {
      this.resetToDefaultModel();
    });
  }

  private clearAll(): void {
    this.form.reset({}, { emitEvent: false });
    this.mode = this.defaultMode;

    const restoreDefaults = {};
    this.envelopeNames.forEach((envelopeName) => {
      const factorKey = this.getPersistencyFactorKey(envelopeName);
      restoreDefaults[factorKey] = this.defaultPersistencyFactor;
    });
    this.form.patchValue(restoreDefaults, { emitEvent: false });

    this.emitFormValue();
  }

  private updatePersistency(
    envelopeName: string,
    temporalValue: number,
    factorValue: number
  ): void {
    const persistencyValue = this.applyPersistencyFactor(temporalValue, factorValue);
    // Update the form with the calculated persistency.
    const persistencyKey = this.getPersistencyKey(envelopeName);
    this.form.get(persistencyKey).setValue(persistencyValue);
  }

  /**
   * Subscribe to all keys of a specific type (persistency, persistencyFactor...) independently.
   */
  private subscribeToKey(
    keyMapFn: (key: string) => string,
    callback: (envelopeName: string, key: string) => void
  ): void {
    this.envelopeNames.forEach((envelopeName) => {
      const currentKey = keyMapFn(envelopeName);
      this.form
        .get(currentKey)
        ?.valueChanges.pipe(untilDestroyed(this))
        .subscribe(() => callback(envelopeName, currentKey));
    });
  }

  private createForm(): void {
    const groups = {};
    Object.assign(groups, this.createValueControls());
    Object.assign(groups, this.createSeverityControls());
    Object.assign(groups, this.createRelativeControls());
    Object.assign(groups, this.createPersistencyControls());
    // These controls are added but not emitted outside the component.
    Object.assign(groups, this.createPersistencyTemporalControls());
    Object.assign(groups, this.createPersistencyFactorControls());
    this.form = this._fb.group(groups);
  }

  private createValueControls(): { [key: string]: UntypedFormControl } {
    return this.createControlsByKey((key) => key);
  }

  private createSeverityControls(): { [key: string]: UntypedFormControl } {
    return this.createControlsByKey((key) => `${key}Severity`);
  }

  private createRelativeControls(): { [key: string]: UntypedFormControl } {
    return this.createControlsByKey((key) => `${key}Relative`);
  }

  private createPersistencyControls(): { [key: string]: UntypedFormControl } {
    return this.createControlsByKey(this.getPersistencyKey);
  }

  /**
   * Helper for persistency conversion. Is not propagated outside the form.
   */
  private createPersistencyTemporalControls(): { [key: string]: UntypedFormControl } {
    return this.createControlsByKey((key) => this.getPersistencyTemporalKey(key), null, [
      Validators.max(9999),
      Validators.min(0),
    ]);
  }

  /**
   * Helper for persistency conversion. Is not propagated outside the form.
   */
  private createPersistencyFactorControls(): { [key: string]: UntypedFormControl } {
    return this.createControlsByKey(this.getPersistencyFactorKey, this.defaultPersistencyFactor);
  }

  private getMainValueKey = (envelopeName: string) => envelopeName;
  private getRelativeModeKey = (envelopeName: string) => `${envelopeName}Relative`;
  private getSeverityKey = (envelopeName: string) => `${envelopeName}Severity`;
  private getPersistencyKey = (envelopeName: string) => `${envelopeName}Persistency`;
  private getPersistencyTemporalKey = (envelopeName: string) =>
    `${envelopeName}PersistencyTemporal`;
  private getPersistencyFactorKey = (envelopeName: string) => `${envelopeName}PersistencyFactor`;

  private createControlsByKey(
    mapKey: (key: string) => string,
    defaultValue = null,
    validators = []
  ): { [key: string]: UntypedFormControl } {
    const controls = this.envelopeNames.reduce((accum, curr) => {
      accum[mapKey(curr)] = [defaultValue, validators];
      return accum;
    }, {});
    return controls;
  }

  private updateFieldSuffix(): void {
    if (this.mode === EnvelopeMode.FlatAbsolute) {
      this.mainFieldSuffix = this.mainFieldUnitName ?? 'UOM';
    } else {
      this.mainFieldSuffix = '%';
    }
  }

  /**
   * Receives a value that can be in seconds, minutes...
   * and always converts them from the current factor to seconds.
   */
  private applyPersistencyFactor(value: number, factor: EnvelopePersistencyFactor): number {
    let factorValue = this.secondsInAMinute;

    if (factor === EnvelopePersistencyFactor.Hours) {
      factorValue = this.secondsInAnHour;
    } else if (factor === EnvelopePersistencyFactor.Days) {
      factorValue = this.secondsInADay;
    }

    if (value === null || factorValue === null) {
      return null;
    }
    return value * factorValue;
  }

  /**
   * Extract the fields that are not helpers and propagate the model outside the component.
   */
  private emitFormValue(): void {
    const model = this.parseNumbers(this.form.getRawValue());
    // Clean the model.
    this.envelopeNames.forEach((envelopeName) => {
      const factorKey = this.getPersistencyFactorKey(envelopeName);
      const temporalKey = this.getPersistencyTemporalKey(envelopeName);
      delete model[factorKey];
      delete model[temporalKey];
    });
    model.mode = this.mode;
    this.valueChanges.emit(new EnvelopesConfiguration(model));
    const isValid = this.isValid();
    this.valid.emit(isValid);
    this.checkIsDefault(new EnvelopesConfiguration(model));
  }

  /**
   * This form is valid if all the rows are either completed or empty.
   * At least one row should be filled.
   */
  private isValid(): boolean {
    if (!this.form.valid) {
      return false;
    }

    let envelopeName;
    let anyPopulated: boolean[] = [];
    for (let i = 0; i < this.envelopeNames.length; i++) {
      envelopeName = this.envelopeNames[i];
      const mainValueFilled = this.isFilledField(this.getMainValueKey(envelopeName));
      const severityFilled = this.isFilledNonZeroField(this.getSeverityKey(envelopeName));
      const persistencyFilled = this.isFilledNonZeroField(this.getPersistencyKey(envelopeName));
      const relativeFilled = this.isFilledField(this.getRelativeModeKey(envelopeName));

      anyPopulated.push(mainValueFilled);
      // Severity filled -> Value and Persistency must be filled.
      if (
        severityFilled &&
        (!persistencyFilled || !mainValueFilled || (this.isFlatRelative && !relativeFilled))
      ) {
        return false;
      }
      // Envelope value filled -> Severity and Persistency must be filled.
      if ((mainValueFilled || relativeFilled) && (!severityFilled || !persistencyFilled)) {
        return false;
      }
    }

    if (!anyPopulated.some((x) => x === true)) {
      return false;
    }

    return true;
  }

  private get isFlatRelative() {
    return this.mode === EnvelopeMode.FlatRelative;
  }

  private calculateDefaultPersistency(
    model: EnvelopesConfigurationForm
  ): EnvelopesConfigurationForm {
    const data = new EnvelopesConfigurationForm({ ...model });
    this.envelopeNames.forEach((envelopeName) => {
      const persistencyKey = this.getPersistencyKey(envelopeName);
      const persistencyFactorKey = this.getPersistencyFactorKey(envelopeName);
      const persistencyTemporalKey = this.getPersistencyTemporalKey(envelopeName);

      if (data[persistencyKey]) {
        const persistencyConf = this.getPersistencyFactorByValue(data[persistencyKey]);
        data[persistencyFactorKey] = persistencyConf.persistencyFactor;
        data[persistencyTemporalKey] = persistencyConf.persistencyValue;
      }
    });
    return data;
  }

  /**
   * Check if the form current values are the same as the default model.
   * Emit an event with the result.
   */
  private checkIsDefault(model: EnvelopesConfiguration): void {
    const noNullsModel = this._objectHelper.cloneWithoutNulls<EnvelopesConfiguration>(model);
    let defaultModel = this._objectHelper.cloneOnlyKeys<EnvelopesConfiguration>(
      this.defaultModel,
      Object.keys(noNullsModel)
    );
    defaultModel = this._objectHelper.cloneWithoutNulls<EnvelopesConfiguration>(defaultModel);

    const areSame = this._objectHelper.deepEqual(
      noNullsModel,
      new EnvelopesConfiguration(defaultModel)
    );
    this.isDefault.next(areSame);
  }

  private isFilledField = (key: string) => {
    const value = this.form.get(key).value;
    return value !== null && value !== undefined && value !== '';
  };

  private getEnvelopeModesFiltered(modesToRemove: EnvelopeMode[]) {
    this._sharedConstantsService.getEnvelopeModesArray().subscribe({
      next: (modes) => {
        if (modesToRemove?.length) {
          this.modesOptions = modes.filter((x) => !modesToRemove.includes(x.value));
          if (modesToRemove.includes(this.mode)) {
            this.mode = this.modesOptions[0].value;
            this.onModeChange();
          }
        } else {
          this.modesOptions = modes;
        }
      },
    });
  }

  private getPersistencyFactorByValue(valueInSeconds: number): EnvPersistencyConfiguration {
    if (valueInSeconds % ConstantsValues.secondsInADay === 0) {
      return new EnvPersistencyConfiguration({
        persistencyValue: valueInSeconds / ConstantsValues.secondsInADay,
        persistencyFactor: EnvelopePersistencyFactor.Days,
      });
    }

    if (valueInSeconds % ConstantsValues.secondsInAnHour === 0) {
      return new EnvPersistencyConfiguration({
        persistencyValue: valueInSeconds / ConstantsValues.secondsInAnHour,
        persistencyFactor: EnvelopePersistencyFactor.Hours,
      });
    } else {
      return new EnvPersistencyConfiguration({
        persistencyValue: valueInSeconds / ConstantsValues.secondsInAMinute,
        persistencyFactor: EnvelopePersistencyFactor.Minutes,
      });
    }
  }

  private isFilledNonZeroField = (key: string) => {
    const value = this.form.get(key).value;
    return this.isFilledField(key) && value !== 0;
  };

  private addEmptyOption(options: SelectOption<any>[]): void {
    options.unshift(new SelectOption<number>({ value: null, label: '' }));
  }

  private parseNumbers(data: EnvelopesConfiguration): EnvelopesConfiguration {
    const cloned = this._objectHelper.clone(data);
    Object.keys(cloned).forEach((key) => {
      if (typeof cloned[key] === 'string') {
        if (cloned[key] === '') {
          cloned[key] = null;
        } else {
          cloned[key] = +cloned[key];
        }
      }
    });
    return cloned;
  }
}
