import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectOption } from '@common-modules/shared/model/shared/select-option';
import { FieldType } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'wlm-custom-global-value-selector-field',
  templateUrl: './custom-global-value-selector-field.component.html',
  styleUrl: './custom-global-value-selector-field.component.scss',
  host: { class: 'custom-formly-field' },
})
export class CustomGlobalValueSelectorFieldComponent extends FieldType<any> implements OnInit {
  items: SelectOption<any>[] = [];
  private readonly _globalValueOptionId = 99;
  private readonly _destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.includeGlobalValueOption();
    this.setGlobalValueOnEnpty();
  }

  private includeGlobalValueOption(): void {
    const props = this.getProps();
    this.ensureObservable$(props.options)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((items) => {
        if (props?._globalValue$) {
          props?._globalValue$.subscribe((globalValue) => {
            if (globalValue !== null && typeof globalValue !== 'undefined') {
              this.items = [
                ...items.filter((item) => item.value != this._globalValueOptionId),
                new SelectOption<any>({
                  value: this._globalValueOptionId,
                  label: globalValue,
                }),
              ];
            }
          });
        } else {
          this.items = items;
        }
      });
  }

  private setGlobalValueOnEnpty(): void {
    if (this.formControl.value === null || typeof this.formControl.value === 'undefined') {
      this.formControl.setValue(this._globalValueOptionId);
    }

    // If the value is not configured, show that de default value is being used.
    this.formControl.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((value) => {
      if (
        value === null ||
        typeof value === 'undefined' ||
        value === String(this._globalValueOptionId)
      ) {
        this.formControl.setValue(this._globalValueOptionId);
      }
    });
  }

  private getProps() {
    return { ...(this.field.templateOptions ?? {}), ...(this.field.options ?? {}) };
  }

  get label$() {
    const label = this.field.templateOptions.label;
    return label?.subscribe ? label : of(label);
  }

  private ensureObservable$ = (obj): Observable<any> => ((obj as any)?.subscribe ? obj : of(obj));
}
