import { Component, Inject, Injector, OnInit } from '@angular/core';
import { IHierarchyElementDto } from '@common-modules/dependencies/he/hierarchy-element.dto';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { GetValueSelector } from '@common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { HierarchyStateFields } from '../../models/hierarchy-state-fields';

const COMPONENT_SELECTOR = 'wlm-hierarchy-children-configuration-widget';
export const HIERARCHY_CHILDREN_CONFIG_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-children-configurator-widget.component.html',
  styleUrls: ['./hierarchy-children-configurator-widget.component.scss'],
  providers: [ReduxStateService],
})
export class HierarchyChildrenConfiguratorWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  get componentName(): string {
    return 'HierarchyChildrenConfiguratorWidgetComponent';
  }

  phTitleKey = `${this.T_SCOPE}.must-select-title`;
  phSubtitleKey = `${this.T_SCOPE}.must-select-subtitle`;

  pageId: string;
  hierarchyElement: IHierarchyElementDto;
  hierarchyFamilyId: string;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);

    this.pageId = widgetSettings.page;
  }

  onWidgetInit(): void {
    this.listenState(HierarchyStateFields.selectedHierarchyElement).subscribe(
      (selectedHierarchyElement: IHierarchyElementDto) => {
        this.hierarchyElement = selectedHierarchyElement;
        this.hierarchyFamilyId = selectedHierarchyElement?.hierarchyFamilyId;
      }
    );
  }

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }
}
