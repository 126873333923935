import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { SchematicSave } from '@common-modules/wlm-charts/core/models/schematics/schematic';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { SchematicsService } from '../services/schematics.service';

const COMPONENT_SELECTOR = 'wlm-schematic-import';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './schematic-import.component.html',
  styleUrls: ['./schematic-import.component.scss'],
})
export class SchematicImportComponent implements OnInit {
  @Output() saved = new EventEmitter<void>();
  @Output() isValid = new EventEmitter<boolean>();

  readonly fieldAppearance = 'outline';
  readonly T_SCOPE = `${AppModules.Schematics}.${COMPONENT_SELECTOR}`;
  readonly fieldScope = `${this.T_SCOPE}.fields`;
  form: UntypedFormGroup;
  private _spinnerKey: string;
  private readonly _nodesTemplateFileName = 'Nodes-Template.xlsx';
  private readonly _relationsTemplateFileName = 'Relations-Template.xlsx';
  private readonly _templatesPath = 'assets/files';

  constructor(
    private _fb: UntypedFormBuilder,
    private _schematicsService: SchematicsService,
    private _spinnerService: SpinnerService
  ) {
    this._spinnerKey = this._spinnerService.generateKey();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    const V = Validators;
    this.form = this._fb.group({
      name: [null, [V.required]],
      nodes: [null, [V.required]],
      links: [null, [V.required]],
    });

    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.isValid.emit(this.form.valid);
    });
  }

  save(): void {
    const model: SchematicSave = this.form.getRawValue();

    this.setLoading(true);
    this._schematicsService
      .save(model)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.setLoading(false);
        })
      )
      .subscribe(() => {
        this.saved.emit();
      });
  }

  onDownloadNodesTemplate(): void {
    const url = `${this._templatesPath}/${this._nodesTemplateFileName}`;

    globalUtilsHelper.downloadFile(url, this._nodesTemplateFileName);
  }

  onDownloadLinksTemplate(): void {
    const url = `${this._templatesPath}/${this._relationsTemplateFileName}`;

    globalUtilsHelper.downloadFile(url, this._relationsTemplateFileName);
  }

  private setLoading(isLoading: boolean): void {
    this._spinnerService.setLoading(isLoading, this._spinnerKey);
  }
}
