import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
// prettier-ignore
import { isPlatformBrowser } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { InitialRedirectService } from '@common-modules/shared/auth/services/initial-redirect.service';
import { SettingsService } from '@common-modules/shared/config/settings.service';
import { IconLoaderService } from '@common-modules/shared/icon-loader.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { ExternalNavigationService } from '@common-modules/shared/navigation/external-navigation.service';
import { RightPanelSettings } from '@common-modules/shared/navigation/right-panel-settings';
import { RightPanelService } from '@common-modules/shared/navigation/right-panel.service';
import { ThemeService } from '@common-modules/shared/theme/theme.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';
import ResizeObserver from 'resize-observer-polyfill';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntegrationUserService } from '../features/integration/users/integration-user.service';
import { HideNavbarsService } from '../features/navigation/hide-navbars/hide-navbars.service';
import { NotificationHubService } from '../features/shared/notification/notification-hub.service';

@UntilDestroy()
@Component({
  selector: 'wlm-water-loss-app',
  templateUrl: './water-loss-app.component.html',
  styleUrls: ['./water-loss-app.component.scss'],
})
export class WaterLossAppComponent implements OnInit, AfterContentChecked {
  private sizeWatching: Set<any> = new Set<any>();
  configLoaded = false;
  hoverEnabled = false;
  private setLoading: (loading: boolean) => void;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: object,
    private rightPanelService: RightPanelService,
    private oauthService: OAuthService,
    private iconLoaderService: IconLoaderService,
    private cd: ChangeDetectorRef,
    private settingsService: SettingsService,
    private localization: LocalizationHelperService,
    private notificationHubService: NotificationHubService,
    private _authenticationService: AuthenticationService,
    private _spinnerService: SpinnerService,
    private _route: ActivatedRoute,
    private readonly _hideNavbarsService: HideNavbarsService,
    private readonly _initialRedirectService: InitialRedirectService,
    private readonly _integrationUserService: IntegrationUserService,
    private readonly _externalNavigationService: ExternalNavigationService,
    private readonly _themeService: ThemeService
  ) {
    this.setLoading = this._spinnerService.buildSetLoadingFn(this.componentName, {
      overlayType: 'logo',
    });

    // Load remote configuration.
    this.setLoading(true);
    this.settingsService.init();
    this._externalNavigationService.init();
    this.notificationHubService.init();
    this._themeService.enableChangeThemeByUrl();
    this.settingsService.ready$.pipe(untilDestroyed(this)).subscribe(() => {
      this.configureOAuth();
      this.localization.config();
      this.configLoaded = true;
      this._authenticationService.accessToken$.subscribe((x) => {
        this._integrationUserService.ensureIntegrationSetup(() => {
          this.setLoading(false);
          this._initialRedirectService.performInitialRedirect();
        });
      });
    });
    this.iconLoaderService.registerAllIcons();

    this.rightPanelWidthFx = 0;
    this.rightPanelCollapsed = this.rightPanelService.collapsedObservable();
    this.rightPanelHover = this.rightPanelService.HoverObservable();

    this.rightPanelHover.pipe(untilDestroyed(this)).subscribe((hover) => {
      this.hoverEnabled = hover;
      if (hover) {
        this.mainContentWidthFx = 100;
      }
    });

    this.rightPanelOverlay = this.rightPanelService.overlayObservable().pipe(
      map((value) => {
        if (value === 'over') {
          this.detectScrollbarWidth();
        }

        return value;
      })
    );

    this.rightPanelService.rightPanelInitialize.pipe(untilDestroyed(this)).subscribe((size) => {
      this.rightPanelLastWidth = size?.widthFx;
    });

    this.rightPanelVisible = this.rightPanelService.visibilityObservable().pipe(
      map((value) => {
        this.rightPanelVisibility = value;
        this.setPanelProportions();
        return value;
      })
    );

    this.resizeObserver();
  }

  private configureOAuth(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.oauthService.configure(this.settingsService.oAuthConfig);

      this._initialRedirectService.saveCurrentRoute();
      this._authenticationService.customLoadDiscoveryDocumentAndTryLogin();
    }
  }

  public rightPanelVisible: Observable<boolean>;
  public rightPanelCollapsed: Observable<boolean>;
  public rightPanelHover: Observable<boolean>;
  public rightPanelOverlay: Observable<string>;

  private rightPanelVisibility: boolean;
  private _rightPanelWidthFx: number;
  public get rightPanelWidthFx(): number {
    return this._rightPanelWidthFx;
  }
  public set rightPanelWidthFx(value: number) {
    this._rightPanelWidthFx = value;
    if (this.hoverEnabled || !this.rightPanelVisibility) {
      this._mainContentWidthFx = 100;
    } else {
      this._mainContentWidthFx = 100 - value;
    }
  }

  private _mainContentWidthFx: number;
  public get mainContentWidthFx(): number {
    return this._mainContentWidthFx;
  }
  public set mainContentWidthFx(value: number) {
    this._mainContentWidthFx = value;
    if (!this.hoverEnabled) {
      this._rightPanelWidthFx = 100 - value;
    }
  }

  private _rightPanelLastWidth;
  public get rightPanelLastWidth() {
    return this._rightPanelLastWidth;
  }
  public set rightPanelLastWidth(value) {
    this._rightPanelLastWidth = value;
    this.setPanelProportions();
  }

  public dragPosition = { x: 0, y: 0 };

  private defaultRightPanelWidthPixel = 550;

  private rightPanelProportion: number;
  scrollBarCss: string;
  private rightPanelInitialize$: Observable<RightPanelSettings>;
  private rightPanelVisibility$: Observable<boolean>;

  private setPanelProportions(): void {
    if (this.rightPanelVisibility === false) {
      this.mainContentWidthFx = 100;
    } else {
      this.rightPanelWidthFx = this.rightPanelLastWidth;
    }
  }

  private detectScrollbarWidth() {
    const item = document.getElementById('router-content');
    const scrollbarWidth = item.offsetWidth - item.clientWidth;
    this.scrollBarCss = scrollbarWidth + 1 + 'px';
  }

  dragEnded() {
    this.rightPanelLastWidth = this.rightPanelWidthFx;
  }

  dragMove(dragHandle: HTMLElement, $event: CdkDragMove<any>) {
    // TODO this isn't best practice in Angular, but works for now
    const x = dragHandle.parentNode.parentNode;
    const y = x['getBoundingClientRect']();
    const z = y['width'];

    if (this.rightPanelLastWidth == null) {
      this.rightPanelLastWidth = z;
    }

    const newWidth = this.rightPanelLastWidth + $event.distance.x * -1;

    this.rightPanelWidthFx = newWidth;

    x['style']['width'] = newWidth + 'px';
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.resetRightPanelSize();

    this.bindRightPanelSize();

    this._hideNavbarsService.init(this._route);
  }

  private bindRightPanelSize(): void {
    this.rightPanelInitialize$ = this.rightPanelService.rightPanelInitialize.asObservable();
    this.rightPanelInitialize$.pipe(untilDestroyed(this)).subscribe((descriptor) => {
      if (descriptor) {
        this.rightPanelProportion = descriptor.proportion;
        this.rightPanelWidthFx = descriptor.widthFx;
      } else {
        this.resetRightPanelSize();
      }
    });

    this.rightPanelVisibility$ = this.rightPanelService.hasRightPanelObservable();
    this.rightPanelVisibility$.pipe(untilDestroyed(this)).subscribe((visibility) => {
      this.rightPanelService.setVisible(visibility);
      if (!visibility) {
        this.rightPanelWidthFx = 0;
      }
    });

    const rightPanelSizeChanges$ = this.rightPanelService.rightPanelSizeChanges.asObservable();
    rightPanelSizeChanges$.pipe(untilDestroyed(this)).subscribe((settings) => {
      if (settings) {
        this.rightPanelWidthFx = settings.widthFx;
      }
    });
  }

  dragEnded2($event: CdkDragEnd<any>) {
    const x = $event.source.getRootElement().getBoundingClientRect().x;
    const w = window.innerWidth;

    this.rightPanelProportion = (w - x) / w;
    this.rightPanelWidthFx = this.rightPanelProportion * 100;
    this.setTransform();

    this.rightPanelService.setResized({
      proportion: this.rightPanelProportion,
      widthFx: this.rightPanelWidthFx,
    } as RightPanelSettings);

    this.rightPanelLastWidth = this.rightPanelWidthFx;
  }

  setTransform() {
    this.dragPosition = { x: 0, y: 0 };
  }

  private resetRightPanelSize() {
    this.rightPanelProportion = this.defaultRightPanelWidthPixel / window.innerWidth;
    this.rightPanelWidthFx = this.rightPanelProportion * 100;
  }

  private resizeObserver() {
    // Only run if ResizeObserver is supported.
    if ('ResizeObserver' in self) {
      // Create a single ResizeObserver instance to handle all
      // container elements. The instance is created with a callback,
      // which is invoked as soon as an element is observed as well
      // as any time that element's size changes.
      var ro = new ResizeObserver(function (entries) {
        // Default breakpoints that should apply to all observed
        // elements that don't define their own custom breakpoints.
        var defaultBreakpoints = { SM: 384, MD: 576, LG: 768, XL: 960 };

        entries.forEach(function (entry) {
          // If breakpoints are defined on the observed element,
          // use them. Otherwise use the defaults.
          var breakpoints = entry.target['dataset']?.breakpoints
            ? JSON.parse(entry.target['dataset'].breakpoints)
            : defaultBreakpoints;

          // Update the matching breakpoints on the observed element.
          Object.keys(breakpoints).forEach(function (breakpoint) {
            var minWidth = breakpoints[breakpoint];
            if (entry.contentRect.width >= minWidth) {
              entry.target.classList.add(breakpoint);
            } else {
              entry.target.classList.remove(breakpoint);
            }
          });
        });
      });

      setInterval(() => this.resizeWatch(ro), 1000);
    } else {
      console.log('No Resize');
    }
  }

  private resizeWatch(ro: ResizeObserver) {
    var elements = document.querySelectorAll('[data-observe-resizes]');
    for (var element, i = 0; (element = elements[i]); i++) {
      if (this.sizeWatching.has(element)) {
      } else {
        ro.observe(element);
        this.sizeWatching.add(element);
      }
    }
  }

  get componentName() {
    return 'AppComponent';
  }
}
