import { Injectable } from '@angular/core';
import { DefaultHistoricalValidationService } from '@common-modules/wlm-charts/core/services/default-historical-validations.service';
import { HierarchyElementPressureSignalVersionDto } from '../../shared/model/signals/hierarchy-element-pressure-signal-version.dto';
import { HierarchyElementPressureSignalDto } from '../../shared/model/signals/hierarchy-element-pressure-signal.dto';
import { PressureSignalTypesEnum } from '../../shared/model/signals/pressure-signal-types.enum';

@Injectable({
  providedIn: 'root',
})
export class PressureHistoricalValidationService extends DefaultHistoricalValidationService<HierarchyElementPressureSignalDto> {
  validateCurrentConfiguration(
    config1: HierarchyElementPressureSignalDto,
    config2: HierarchyElementPressureSignalDto
  ): boolean {
    if (config1.signalId === config2.signalId) {
      const isConfig1AZP = config1.pressureTypeId === PressureSignalTypesEnum.AZP;
      const isConfig2AZP = config2.pressureTypeId === PressureSignalTypesEnum.AZP;

      if (
        (isConfig1AZP && !isConfig2AZP && config2.offset === null) ||
        (isConfig2AZP && !isConfig1AZP && config1.offset === null) ||
        (!isConfig1AZP && !isConfig2AZP && (config1.offset === null || config2.offset === null))
      ) {
        return true;
      }
    }

    if (config1.pressureTypeId !== config2.pressureTypeId) {
      return false;
    }

    return true;
  }

  getCurrentFromHistorical(
    config: HierarchyElementPressureSignalVersionDto
  ): HierarchyElementPressureSignalDto {
    if (!config) {
      return null;
    }
    const current: HierarchyElementPressureSignalDto = {
      signalId: config.signalId,
      pointDescription: config.pointDescription,
      pointId: config.pointId,
      hierarchyElementId: config.hierarchyElementId,
      pressureTypeId: config.pressureTypeId,
      offset: config.offset,
      offsetCoordinates: config.offsetCoordinates,
      title: '',
    };
    return current;
  }
}
