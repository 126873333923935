import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { IGisElementDto } from '@common-modules/shared/model/gis/gis-element.dto';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { GisService } from '../../shared/gis/gis.service';
// prettier-ignore

import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { RightPanelService } from '@common-modules/shared/navigation/right-panel.service';
import { BaseMapComponent } from '../base-map/base-map.component';
import { MapParameters } from '../map-parameters';
import { NavigationElementType } from '../navigation-element-type';

const COMPONENT_SELECTOR = 'wlm-map-related';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-related.component.html',
  styleUrls: ['./map-related.component.scss'],
})
export class MapRelatedComponent extends BaseWidgetComponent implements OnInit {
  @ViewChild(BaseMapComponent) public baseMap: BaseMapComponent;

  mapParameters: MapParameters;
  displayMapMessage$ = new BehaviorSubject<WlmDialogSettings>(null);

  get componentName(): string {
    return 'MapRelatedComponent';
  }

  private _receivedParams: MapParameters;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _gisService: GisService,
    private readonly _rightPanelService: RightPanelService
  ) {
    super(injector, widgetSettings);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this._receivedParams = parameters.parameters.get(TabDetailParameterName.mapParameters);
    if (this._receivedParams?.navigationParam) {
      this.getGisElementByType(this._receivedParams);
    } else {
      if (this.baseMap) {
        this.baseMap.mapParameters = this._receivedParams;
      }
    }
  }

  init(): void {}

  onCoordinatesClicked(coordinates) {
    const map = new Map<any, string>();
    this._rightPanelService.rightPanelCallback.next(map.set(MapRelatedComponent, coordinates));
  }

  private getGisElementByType(params: MapParameters) {
    this.loading = true;

    switch (params.navigationParam.type) {
      case NavigationElementType.Customers:
        this.getGisElementByCustomer(params);
        break;

      case NavigationElementType.Leaks:
        this.getGisElementByLeak(params);
        break;

      case NavigationElementType.Activities:
        this.getGisElementByActivity(params);
        break;

      default:
        break;
    }
  }

  private getGisElementByCustomer(params: MapParameters) {
    this._gisService.getByCustomer(params.navigationParam.elementId).subscribe({
      next: (gisElement) => {
        if (!gisElement) {
          this.displayGisElementNotFoundMessage();
        }
        const gisElementValue = gisElement ? gisElement : null;

        this.setNavigatedElement(gisElementValue);
      },
    });
  }

  private getGisElementByLeak(params: MapParameters) {
    this._gisService.getByLeak(params.navigationParam.elementId).subscribe({
      next: (gisElement) => {
        if (!gisElement) {
          this.displayGisElementNotFoundMessage();
        }
        const gisElementValue = gisElement ? gisElement : null;

        this.setNavigatedElement(gisElementValue);
      },
    });
  }

  private getGisElementByActivity(params: MapParameters) {
    this._gisService.getByActivity(params.navigationParam.elementId).subscribe({
      next: (gisElement) => {
        if (gisElement) {
          this.setNavigatedElement(gisElement);
        } else if (params.navigationParam.hierarchyElementId) {
          this.setHierarchyElement(params.navigationParam.hierarchyElementId);
        }
      },
    });
  }

  private setNavigatedElement(gisElement: IGisElementDto) {
    this._receivedParams.navigatedElement = gisElement;
    this.mapParameters = this._receivedParams;
  }

  private setHierarchyElement(hierarchyElementId: string) {
    this._receivedParams.hierarchyElements = [hierarchyElementId];
    this.mapParameters = this._receivedParams;
  }

  private displayGisElementNotFoundMessage() {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${AppModules.Map}.common.messages.missing-gis-info`,
      icon: 'warning',
    });
    this.displayMapMessage$?.next(dialogSettings);
  }
}
