import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { CalculationMode } from '@common-modules/dependencies/alc/campaign-calculation-mode.enum';
import { IHierarchyElementPathDto } from '@common-modules/dependencies/he/hierarchy-element-path.dto';
import * as constants from '@common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { FilterAdditionalParam } from '@common-modules/dependencies/wlm-filters/filter-additional-param';
import { FilterGroupFieldSettings } from '@common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { HierarchyElementPathFilter } from '@common-modules/dependencies/wlm-filters/i-filters/hierarchy-element-path-filter';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { Observable, Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { ThematicKPI } from '../../../map/map-thematic-configuration-popup/thematic-kpi';
import { ALCCampaignBaseLine } from '../../../shared/model/alc/alc-campaign-baseline';
import { AlcCampaignsService } from '../../campaigns-page/alc-campaigns.service';

const COMPONENT_SELECTOR = 'wlm-campaigns-creation-info';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './campaigns-creation-info.component.html',
  styleUrls: ['./campaigns-creation-info.component.scss'],
})
export class CampaignsCreationInfoComponent implements OnInit {
  private _infoForm: UntypedFormGroup;
  public get infoForm(): UntypedFormGroup {
    return this._infoForm;
  }
  @Input() public set infoForm(v: UntypedFormGroup) {
    this._infoForm = v;
    if (v) {
      this.suscribeToFormChanges();
    }
  }

  @Input() campaignNameFieldName: string;
  @Input() campaignDescriptionFieldName: string;
  @Input() hierarchyElementIdFieldName: string;
  @Input() heFamilyIdFieldName: string;
  @Input() hePathFieldName: string;
  @Input() modeFieldName: string;
  @Input() reductionComponentAutomaticFieldName: string;
  @Input() reductionComponentManualFieldName: string;
  @Input() baseLineFieldName: string;
  @Input() systemInputFieldName: string;
  @Input() dimensionTypeFieldName: string;
  @Input() kpiTypeFieldName: string;
  @Input() reductionComponents: ThematicKPI[];

  private _goalCalculationDays: number;
  public get goalCalculationDays(): number {
    return this._goalCalculationDays ?? 30;
  }
  @Input() public set goalCalculationDays(v: number) {
    this._goalCalculationDays = v;
  }

  @Output() baselineLoaded = new EventEmitter<number>();
  @Output() loadingChange = new EventEmitter<boolean>();

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  hierarchyElementTypeFieldName = 'hierarchyElementTypeId';
  calculationModes: { [key: string]: CalculationMode } = {};
  selectedHe: IHierarchyElementPathDto;
  filterSettings: FilterGroupSettings;
  baselineLabel: string = '[%]';
  selectedHePathFilter: HierarchyElementPathFilter;
  disableAutoload = false;

  private _reductionComponentSubscription: Subscription;
  private _hePathSubscription: Subscription;

  private _isAutomatic: boolean = true;
  public get isAutomatic(): boolean {
    return this._isAutomatic;
  }
  public set isAutomatic(value: boolean) {
    this._isAutomatic = value;
    this.updateValidations();
  }
  currentMode: CalculationMode = CalculationMode.Automatic;

  constructor(
    private _alcCampaignsService: AlcCampaignsService,
    private _uomHelperService: UoMService
  ) {}

  ngOnInit(): void {
    this.initializeCalculationModes();
    this.setFilterSettings();
  }

  onApplyFilters(gridFiltersRef?: any): void {
    this.infoForm.controls[this.hierarchyElementIdFieldName].setValue(this.selectedHe.descendant);
    this.infoForm.controls[this.hierarchyElementFamilyFieldName].setValue(
      this.selectedHePathFilter.hierarchyFamilyId
    );

    this.infoForm.controls[this.hePathFieldName].setValue(this.selectedHe);
  }

  onCheckAutoload(): void {
    if (!this.disableAutoload) {
      this.onApplyFilters();
      this.disableAutoload = true;
    }
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const selectedHe = filtersParameters?.exportableFilter['hierarchyElementsIds'];
    this.selectedHePathFilter = selectedHe as HierarchyElementPathFilter;
  }

  onCalculationModesChange(mode) {
    this.isAutomatic = mode.value === CalculationMode.Automatic;
  }

  private updateValidations() {
    if (this.isAutomatic) {
      this.infoForm.get(this.reductionComponentManualFieldName).clearValidators();
      this.infoForm.get(this.reductionComponentManualFieldName).updateValueAndValidity();
      this.infoForm
        .get(this.reductionComponentAutomaticFieldName)
        .setValidators([Validators.required]);
      this.infoForm.get(this.reductionComponentAutomaticFieldName).updateValueAndValidity();
    } else {
      this.infoForm.get(this.reductionComponentAutomaticFieldName).clearValidators();
      this.infoForm.get(this.reductionComponentAutomaticFieldName).updateValueAndValidity();
      this.infoForm
        .get(this.reductionComponentManualFieldName)
        .setValidators([Validators.required]);
      this.infoForm.get(this.reductionComponentManualFieldName).updateValueAndValidity();
    }
  }

  private initializeCalculationModes() {
    this.calculationModes['automatic'] = CalculationMode.Automatic;
    this.calculationModes['manual'] = CalculationMode.Manual;

    const currentMode = this.infoForm.controls[this.modeFieldName].value;
    this.isAutomatic = currentMode === CalculationMode.Automatic;
    this.currentMode = currentMode;
  }

  private suscribeToFormChanges() {
    if (this._reductionComponentSubscription) {
      this._reductionComponentSubscription.unsubscribe();
    }

    if (this._hePathSubscription) {
      this._hePathSubscription.unsubscribe();
    }

    this._reductionComponentSubscription = this.infoForm.controls[
      this.reductionComponentAutomaticFieldName
    ].valueChanges.subscribe((selectedKpi: ThematicKPI) => {
      const isPristine = this.infoForm.controls[this.reductionComponentAutomaticFieldName].pristine;
      if (isPristine) {
        return;
      }

      const kpi = this.infoForm.controls[this.hePathFieldName].value;

      if (this.isAutomatic && selectedKpi && kpi) {
        this.loadBaseline(selectedKpi, kpi);
      }
    });

    this._hePathSubscription = this.infoForm.controls[this.hePathFieldName].valueChanges.subscribe(
      (hePath: IHierarchyElementPathDto) => {
        const reductionComponent =
          this.infoForm.controls[this.reductionComponentAutomaticFieldName].value;

        if (this.isAutomatic && hePath && reductionComponent) {
          this.loadBaseline(reductionComponent, hePath);
        }
      }
    );
  }

  onSelectedNodesChanged(nodes) {
    this.selectedHe = nodes[0];
  }

  private setFilterSettings() {
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      hierarchyElementId: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementIdFieldName,
      }),
      hierarchyElementFamilyId: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementFamilyFieldName,
      }),
      hierarchyElementTypes: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementTypeFieldName,
      }),
    };

    const params: { [field: string]: any } = {
      hierarchyElementIds: this.infoForm.controls[this.hierarchyElementIdFieldName].value,
      hierarchyFamilyId: this.infoForm.controls[this.hierarchyElementFamilyFieldName].value,
    };

    const additionalParams = {
      selectionMode: new FilterAdditionalParam({ value: constants.treeSelectionMode.single }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      navigationParams: params,
      persistencyArea: COMPONENT_SELECTOR,
      avoidPersistency: true,
      additionalParams,
    });
  }

  private loadBaseLine(kpi: ThematicKPI): Observable<ALCCampaignBaseLine> {
    return this._alcCampaignsService.getBaseLineValue(
      kpi,
      this.goalCalculationDays,
      this.infoForm.controls[this.hierarchyElementFamilyFieldName].value,
      this.infoForm.controls[this.hierarchyElementIdFieldName].value
    );
  }

  private loadBaseline(kpi: ThematicKPI, selectedNode: IHierarchyElementPathDto) {
    if (!kpi || !selectedNode) {
      return;
    }

    this.loadingChange.emit(true);

    this.loadBaseLine(kpi)
      .pipe(
        finalize(() => {
          this.loadingChange.emit(false);
        })
      )
      .subscribe({
        next: (baselineValue) => {
          this.infoForm.controls[this.baseLineFieldName].setValue(baselineValue.kpiValue);
          this.infoForm.controls[this.systemInputFieldName].setValue(
            baselineValue.systemInputValue
          );
          this.baselineLoaded.next(baselineValue.kpiValue);
        },
      });
  }

  private loadBaselineLabel(kpi: ThematicKPI, unitConversion: UnitTypeConversionViewDto) {
    this.loadingChange.emit(true);
    this._alcCampaignsService.getKpiUnitDescription(kpi, unitConversion).subscribe((x) => {
      this.baselineLabel = x;
      this.loadingChange.emit(false);
    });
  }

  private loadUnitConversion(
    kpi: ThematicKPI,
    selectedNode: IHierarchyElementPathDto
  ): Observable<UnitTypeConversionViewDto> {
    const heTypeId =
      this.infoForm.controls[this.hePathFieldName]?.value?.descendantHierarchyElementTypeId;

    return this._uomHelperService
      .getByParams(kpi.dimensionTypeId, kpi.timeAggregationId, heTypeId?.toLowerCase())
      .pipe(take(1));
  }
}
