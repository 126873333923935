import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuLink } from '@common-modules/dependencies/navigation/menu-link';
import { ModuleLink } from '@common-modules/dependencies/navigation/module-link';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'wlm-module-link-expandable',
  templateUrl: './module-link-expandable.component.html',
  styleUrls: ['./module-link-expandable.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class ModuleLinkExpandableComponent implements OnInit {
  @Input() moduleLink: ModuleLink;
  panelOpenState = false;

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  public navigate(menuLink: MenuLink) {
    this._router.navigateByUrl(menuLink.urlPath);
  }
}
