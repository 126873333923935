import { IEventChartQuery } from '@common-modules/shared/charts/model/events/event-chart-query';
import { Type } from 'class-transformer';
import { EventViewCategories } from './event-view-categories';

export class EventChartQueryDto implements IEventChartQuery {
  elementsIds?: string[];
  @Type(() => Date)
  startDate: Date;
  @Type(() => Date)
  endDate: Date;
  categories: EventViewCategories[];
  signalIds?: number[];

  constructor(init: Readonly<EventChartQueryDto>) {
    Object.assign(this, init);
  }
}
