// prettier-ignore
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Params } from '@angular/router';
import { DynamicLayoutParamsType } from '@common-modules/dynamic-layout/models/dynamic-layout-params-type';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { LogService } from '@common-modules/shared/wlm-log/log.service';

import { DefaultParamsFormSettings } from '@common-modules/dependencies/bi/default-params-form-settings';

const COMPONENT_SELECTOR = 'wlm-bi-default-params-form';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './bi-default-params-form.component.html',
  styleUrls: ['./bi-default-params-form.component.scss'],
})
export class BiDefaultParamsFormComponent implements OnInit, OnChanges {
  @Input() type: DynamicLayoutParamsType;
  @Input() params: Params;
  @Output() paramsChange = new EventEmitter<Params>();
  @Output() isValid = new EventEmitter<boolean>();

  settings: DefaultParamsFormSettings;

  private readonly allEditableFields = {
    TrendChartWidgetComponent: ['dimensionTypeId', 'waterBalanceType'],
    WaterBalanceKpisWidgetComponent: ['dimensionTypeId', 'waterBalanceType', 'currencyTypeId'],
  };

  constructor(private _logService: LogService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.type &&
      changes.type.currentValue &&
      changes.params &&
      changes.params.currentValue
    ) {
      // We must wait for the two inputs.
      const currentFields = this.getCurrentFields(this.type);
      const currentParams = this.getCurrentParams(this.params);

      this.settings = {
        params: currentParams,
        fields: currentFields,
        type: this.type,
      };
    } else {
      this.onIsValid(true);
    }
  }

  onParamsChanged(formParams: Params): void {
    const newParams = this.updateCurrentParams(this.params, formParams);

    this.paramsChange.emit(newParams);
  }

  updateCurrentParams(allParams: Params, formParams: Params): Params {
    const previousFormParams = this.getCurrentParams(allParams);
    const newFormParams = { ...previousFormParams, ...formParams };

    let newParams = newFormParams;

    if (this.type.widgetType == 'TrendChartWidgetComponent') {
      newParams = globalUtilsHelper.clone(allParams);
      newParams.staticQueryParams = newFormParams;
    }

    return newParams;
  }

  getCurrentParams(allParams: Params): Params {
    let formParams = allParams;
    if (this.type.widgetType == 'TrendChartWidgetComponent') {
      formParams = allParams.staticQueryParams;
    }
    return globalUtilsHelper.clone(formParams);
  }

  getCurrentFields(type: DynamicLayoutParamsType): string[] {
    const fields = this.allEditableFields[type.widgetType];
    if (!fields) {
      this._logService.error({
        msg: `The widget type ${type.widgetType} does not have any configured editable parameters`,
      });
    }
    return fields;
  }

  onIsValid(isValid: boolean): void {
    this.isValid.emit(isValid);
  }
}
