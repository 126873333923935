import { DateRangeFISettings } from '@common-modules/common-filters/models/date-range-fi-settings';

export class DateRangeAndModeSettings {
  periodIsFixed?: boolean;
  periodDays?: number;
  dateRangeSettings?: DateRangeFISettings;

  constructor(init: DateRangeAndModeSettings) {
    Object.assign(this, init);
  }
}
