import { Component, Inject, Injector } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
// prettier-ignore

import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ISystemLog } from '../../../models/system-log';

const COMPONENT_SELECTOR = 'wlm-logs-system-call-stack-widget';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './logs-system-call-stack-widget.component.html',
  styleUrls: ['./logs-system-call-stack-widget.component.scss'],
})
export class LogsSystemCallStackWidgetComponent extends BaseWidgetComponent {
  systemLog: ISystemLog;

  get componentName(): string {
    return 'LogsSystemCallStackWidgetComponent';
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  init(): void {}

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.systemLog = parameters.parameters.get(TabDetailParameterName.systemLog);
  }
}
