import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';

export class TelemetryGridFilterConfiguration {
  persistencyArea: string;
  hierarchyElementIdFieldName: string;
  hierarchyElementId: string[];
  hierarchyElementFamilyFieldName: string;
  hierarchyElementFamily: string;
  defaultFamilyId?: string;
  networkElementTypeIdFieldName: string;
  networkElementTypeIds?: number[];
  defaultNetworkElementTypeIds?: number[];
  neTypeMode: 'single' | 'multiple' = 'single';
  dimensionTypeIdFieldName: string;
  dimensionTypeId?: DimensionTypesEnum;
  defaultDimension?: DimensionTypesEnum;
  hiddenDimensionTypeIds?: DimensionTypesEnum[];
  dimensionTypeIds?: DimensionTypesEnum[];
  storageLocation?: 'local' | 'session' | 'none' = 'local';

  constructor(init: Readonly<TelemetryGridFilterConfiguration>) {
    Object.assign(this, init);
  }
}
