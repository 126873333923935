import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import { RouteNameHelper } from '@common-modules/dependencies/navigation/route-name-helper';
import { AuthGuard } from '@common-modules/shared/auth/services/auth.guard';
import { LeavePageGuard } from '@common-modules/shared/navigation/leave-page.guard';
import { AuditsPageComponent } from './audits/audits-page/audits-page.component';
import { LogsInterfacesPageComponent } from './logs-interfaces/logs-interfaces-page/logs-interfaces-page.component';
import { LogsSystemPageComponent } from './logs-system/logs-system-page/logs-system-page.component';

const routes: Routes = [
  {
    path: RouteNameHelper.logsInterfaces,
    component: LogsInterfacesPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.LogsInterfaces,
    },
  },
  {
    path: RouteNameHelper.logsSystem,
    component: LogsSystemPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.LogsSystem,
    },
  },
  {
    path: RouteNameHelper.audits,
    component: AuditsPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.Audits,
    },
  },
  { path: '**', redirectTo: RouteNameHelper.logsInterfaces },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LogsRoutingModule {}
