import { TabDetailParameterName } from '@common-modules/dependencies/navigation/tab-detail-component';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { ArrayValuesFAdapter } from './array-values-f-adapter';

export class DimensionTypeFAdapter extends ArrayValuesFAdapter {
  constructor(init: {
    dataBindingField: string;
    fieldName: string;
    tabParameter: TabDetailParameterName;
    multiple: boolean;
  }) {
    super(init);
    this.id = FilterAdapterEnum.Dimension;
  }
}
