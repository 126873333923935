import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlarmsSubscriptionService extends BaseService {
  get url() {
    return `${this.apiUrl}/alarm/notification`;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  public getSubscriptionStatus(profileId: string): Observable<boolean> {
    return this.httpCacheClient.get<boolean>(`${this.url}/${profileId}`, {
      avoid: true,
    });
  }

  public subscribe(profileIds: string[]): Observable<string> {
    return this.httpCacheClient.post(`${this.url}/subscribe`, { profileIds }, { avoid: true });
  }

  public unsubscribe(profileId: string): Observable<string> {
    const params = { profileId };

    return this.httpCacheClient.delete(
      `${this.url}/unsubscribe`,
      {
        avoid: true,
      },
      params
    );
  }
}
