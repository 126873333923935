import { WorkspaceDto } from '@common-modules/shared/model/data-viz/workspace.dto';
import { IFiltrableItemDto } from '@common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { EntityTypes } from '@common-modules/wlm-charts/core/models/entity-types';
import { ChartConfiguration } from '../../shared/charts/model/chart-configuration';

export class DataVisualizationManageWorkspaceResult {
  workspace: WorkspaceDto;
  chartConfiguration: ChartConfiguration;
  entityTypes: EntityTypes[];
  cartFiltrableItems: IFiltrableItemDto[];

  constructor(init: Partial<DataVisualizationManageWorkspaceResult>) {
    Object.assign(this, init);
  }
}
