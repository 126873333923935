import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseCustomizableChartService } from '@common-modules/shared/charts/base-customizable-chart.service';
import { IChartConfiguration } from '@common-modules/shared/charts/model/chart/chart-configuration';
import { BaseCalculateResizableChildComponent } from '@common-modules/shared/core/responsive/base-calculate-resizable-child.component';
import { ExportExcelSettings } from '@common-modules/shared/exports/models/export-excel-settings';
import { DateFormats } from '@common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { IElementSize } from '@common-modules/shared/model/element-size';
import { LogService } from '@common-modules/shared/wlm-log/log.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartConfiguration } from '@water-loss//features/shared/charts/model/chart-configuration';
import { Observable, ReplaySubject, startWith } from 'rxjs';
import { CustomizableChartComponent } from '../customizable-chart/customizable-chart.component';
import { EventsChartComponent } from '../events-chart/events-chart.component';
import { EventChartPosition } from '../events-chart/models/event-chart-position';
import { EventChartQueryDto } from '../events-chart/models/events-chart-query.dto';
import { ChartType } from '../models/chart-type.enum';
import { CustomWorkspaceChartSettings } from '../models/custom-workspace-chart-settings';
import { CustomizableChartParameters } from '../models/customizable-chart-parameters';
import { CustomizableChartSettings } from '../models/customizable-chart-settings';
import { GenericCartesianChartSettings } from '../models/generic-chart-settings/generic-cartesian-chart-settings';
import { GChartClickEvent } from '../models/generic-events/g-chart-click-event';
import { GChartDataZoomEvent } from '../models/generic-events/g-chart-data-zoom-event';
import { GChartInitEvent } from '../models/generic-events/g-chart-init-event';
import { GChartLegendSelectedEvent } from '../models/generic-events/g-chart-legend-selected-event';

const COMPONENT_SELECTOR = 'wlm-custom-workspace-chart';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './custom-workspace-chart.component.html',
  styleUrls: ['./custom-workspace-chart.component.scss'],
})
export class CustomWorkspaceChartComponent
  extends BaseCalculateResizableChildComponent
  implements OnInit
{
  dimensionToCalculate: 'height' | 'width' = 'height';
  private readonly _eventsChartSizeChanges$ = new ReplaySubject<IElementSize>(1);

  @ViewChild(EventsChartComponent) eventChart: EventsChartComponent;
  @ViewChild(CustomizableChartComponent) customizableChart: CustomizableChartComponent;

  @Output() loadingEvent = new EventEmitter<boolean>();
  @Output() chartInitEvent = new EventEmitter<GChartInitEvent>();
  @Output() chartLegendSelectedEvent = new EventEmitter<GChartLegendSelectedEvent>();
  @Output() chartLoaded = new EventEmitter<boolean>();
  @Output() chartFinished = new EventEmitter<void>();
  @Output() chartClickEvent = new EventEmitter<GChartClickEvent>();

  private _customWorkspaceSettings: CustomWorkspaceChartSettings;
  public get customWorkspaceSettings(): CustomWorkspaceChartSettings {
    return this._customWorkspaceSettings;
  }
  @Input()
  public set customWorkspaceSettings(value: CustomWorkspaceChartSettings) {
    this._customWorkspaceSettings = value;

    if (value) {
      this.loadChartConfiguration(value);
    }
  }

  private _chartConfiguration: ChartConfiguration;
  public get chartConfiguration(): ChartConfiguration {
    return this._chartConfiguration;
  }
  public set chartConfiguration(value: ChartConfiguration) {
    this._chartConfiguration = value;
    this.eventChartQuery = this.getEventChartQuery(value);

    if (value) {
      this.setChartSettings(value);
    }
  }

  eventChartQuery: EventChartQueryDto;
  chartSettings: CustomizableChartSettings;

  xAxisMin: Date;
  xAxisMax: Date;
  xAxisRange: DateRange;

  initialDateRange: DateRange;

  constructor(
    private readonly _localization: LocalizationHelperService,
    private readonly _logService: LogService,
    private readonly _customizableChartService: BaseCustomizableChartService
  ) {
    super();
  }

  ngOnInit(): void {}

  onChartLoading = (isLoading) => this.loadingEvent.emit(isLoading);
  onChartInit = (event) => this.chartInitEvent.next(event);
  onChartLegendSelected = (event) => this.chartLegendSelectedEvent.emit(event);
  onChartLoaded = (event) => this.chartLoaded.emit(event);
  onChartFinished = (event) => this.chartFinished.emit(event);
  onChartClick = (event) => this.chartClickEvent.next(event);

  onChartDataZoom(chartDataZoom: GChartDataZoomEvent): void {
    if (!this.eventChart) {
      return;
    }
    const { start, end } = chartDataZoom;
    this.eventChart.position = new EventChartPosition({
      start,
      end,
    });
  }

  onMainChartSettingsChange(settings: GenericCartesianChartSettings): void {
    if (!settings || !settings.xAxes.length) {
      return;
    }

    const min = settings.xAxes[0].min;
    const max = settings.xAxes[0].max;
    if (typeof (min as Date).getMonth === 'function') {
      this.xAxisMin = min as Date;
      this.xAxisMax = max as Date;
      this.xAxisRange = new DateRange(this.xAxisMin, this.xAxisMax);
    } else if (typeof min === 'string') {
      this.xAxisMin = this._localization.localStrToDate(min as string, DateFormats.DateTime);
      this.xAxisMax = this._localization.localStrToDate(max as string, DateFormats.DateTime);
      this.xAxisRange = new DateRange(this.xAxisMin, this.xAxisMax);
    } else {
      this._logService.error({
        msg: 'Min and max properties of the main chart are not bindable to the events chart.',
      });
    }
  }

  setDateParams(startDate: Date, endDate: Date) {
    this.customizableChart?.setDateParams(startDate, endDate);
  }

  updateChartConfiguration() {
    this._customizableChartService
      .updateChartConfiguration(this.chartConfiguration)
      .pipe(untilDestroyed(this))
      .subscribe((chartConfiguration) => {
        if (!chartConfiguration) {
          return;
        }

        this.chartConfiguration = chartConfiguration;
      });
  }

  showDataPoints() {
    this.customizableChart?.showDataPoints();
  }

  exportChart() {
    this.customizableChart?.exportChart();
  }

  getExportExcelSettings(): Observable<ExportExcelSettings> {
    return this.customizableChart?.getExportExcelSettings();
  }

  exportToPdf(): void {
    this.customizableChart?.exportToPdf();
  }

  isDataLoaded = () => this.customizableChart?.dataLoaded ?? false;

  onEventChartSizeChange(size: IElementSize): void {
    this._eventsChartSizeChanges$.next(size);
  }

  private loadChartConfiguration(settings: CustomWorkspaceChartSettings) {
    const { workspaceName, elements } = settings.dataParameters;

    this._customizableChartService
      .getChartConfiguration(workspaceName, elements)
      .pipe(untilDestroyed(this))
      .subscribe((chartConfiguration) => {
        this.chartConfiguration = chartConfiguration;
      });
  }

  private setChartSettings(chartConfiguration: IChartConfiguration) {
    const { minimum, maximum } = chartConfiguration.horizontalAxis[0];

    this.initialDateRange = new DateRange(minimum, maximum);

    this.chartSettings = new CustomizableChartSettings({
      chartType: ChartType.customizable,
      dataParameters: new CustomizableChartParameters({
        startDate: minimum,
        endDate: maximum,
        chartConfiguration: chartConfiguration,
      }),
      exportFileName: this._customWorkspaceSettings.exportFileName,
    });
  }

  private getEventChartQuery(chartConfiguration: IChartConfiguration): EventChartQueryDto {
    if (!chartConfiguration) {
      return null;
    }

    const { eventsControlSettings, eventsQuery } = chartConfiguration;

    return new EventChartQueryDto({
      ...(eventsQuery as EventChartQueryDto),
      categories: eventsControlSettings.categories,
    });
  }

  fixedSizes$(): Observable<IElementSize>[] {
    return [this._eventsChartSizeChanges$.asObservable().pipe(startWith(null))];
  }
}
