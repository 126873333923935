import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { isObservable } from 'rxjs';
import { ArrayHelperService } from '../../shared/helpers/array-helper.service';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { SelectOption } from '../../shared/model/shared/select-option';

@Component({
  selector: 'wlm-chips-selector-type',
  templateUrl: './chips-selector-type.component.html',
  styleUrl: './chips-selector-type.component.scss',
})
export class ChipsSelectorTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  allOptions: SelectOption<string>[] = [];
  selectedValues: string[] = [];
  private _previousSelectedValues: string[] = [];

  private readonly _arrayHelper = inject(ArrayHelperService);
  private readonly _destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.bindOptions();
    this.bindSelectedValues();
  }

  onValueChanged(value: string[]): void {
    this.formControl.setValue(value);
  }

  private bindOptions(): void {
    const { options } = this.field.props;

    if (isObservable(options)) {
      options.subscribe((options: SelectOption<string>[]) => {
        this.allOptions = options;
      });
    } else {
      this.allOptions = (options as SelectOption<string>[]) ?? [];
    }
  }

  private bindSelectedValues(): void {
    this.selectedValues = this.formControl.getRawValue();
    this.formControl.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((values) => {
      if (!this._arrayHelper.areSame(this._previousSelectedValues, values)) {
        this.selectedValues = values;
        this._previousSelectedValues = globalUtilsHelper.clone(values, true);
        this.formControl.markAsDirty();
      }
    });
  }
}
