import { Component, ElementRef, Inject, Injector, OnInit } from '@angular/core';
import { BiFilters } from '@common-modules/dependencies/bi/bi-filters';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { WaterBalanceParams } from '@common-modules/dependencies/water-balance/water-balance-params';
import { WaterBalanceTypesEnum } from '@common-modules/dependencies/water-balance/water-balance-types';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { WaterBalanceKpisWidgetParams } from '@common-modules/dynamic-layout/models/widget-definition-settings';
import { DefaultParamsState } from '@common-modules/dynamic-layout/state/default-params/default-params-state';
import { ResetDefaultParamsAction } from '@common-modules/dynamic-layout/state/default-params/default-params.actions';
import { AppliedDefaultParamsSelector } from '@common-modules/dynamic-layout/state/default-params/default-params.selectors';
import { AppliedExtendedFiltersSelector } from '@common-modules/dynamic-layout/state/filters/filters.selectors';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ExtendedFilters } from '@common-modules/shared/filters/component-filters/extended-filters';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { CurrencyTypesEnum } from '@common-modules/shared/model/shared/currency-types.enum';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { BaseBIDynamicWidgetComponent } from '../../../models/base-bi-dynamic-widget';
import { BiService } from '../../../services/bi.service';

const COMPONENT_SELECTOR = 'water-balance-kpis-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './water-balance-kpis-widget.component.html',
  styleUrls: ['./water-balance-kpis-widget.component.scss'],
})
export class WaterBalanceKpisWidgetComponent
  extends BaseBIDynamicWidgetComponent
  implements OnInit
{
  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.BI,
  });

  filters: BiFilters;

  biPageFilters: BiFilters;
  biDefaultFilters: BiFilters;

  waterBalanceType?: WaterBalanceTypesEnum;
  dimensionTypeId?: DimensionTypesEnum;
  kpis: number[];
  currencyTypeId: CurrencyTypesEnum;

  wbParams: WaterBalanceParams;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _dateHelperService: DateHelperService,
    private _biService: BiService,
    private readonly _element: ElementRef
  ) {
    super(injector, widgetSettings);

    this.setTitle(widgetSettings);

    this.setWBKpiParams(widgetSettings.itemSettings.params);
  }

  onWidgetInit(): void {
    this._state
      .select<ExtendedFilters>(new AppliedExtendedFiltersSelector(this._scopeSettings))
      .pipe(
        untilDestroyed(this),
        map((pageFilters) => pageFilters?.model as BiFilters),
        map((pageBiFilters: BiFilters) => {
          if (!pageBiFilters) {
            return null;
          }

          const { defaultFilters, lockFilters } = this.itemSettings;

          this.biPageFilters = globalUtilsHelper.clone(pageBiFilters, true);

          return this._biService.applyFilterLocks(defaultFilters, pageBiFilters, lockFilters);
        })
      )
      .subscribe({
        next: (biFilters: BiFilters) => {
          if (!biFilters) {
            return;
          }

          this.filters = globalUtilsHelper.clone(biFilters, true);

          this.setWBParams();
        },
      });

    this._state
      .select<DefaultParamsState>(new AppliedDefaultParamsSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (state) => {
          // TODO: the widget key check should be outside this class
          if (!state || state.widgetInstanceKey !== this.getWidgetInstanceKey()) {
            return;
          }

          this.itemSettings.defaultFilters = state.filters.model;
          this.itemSettings.lockFilters = state.lockFilters;
          this.itemSettings.params = state.params;

          this.setTitle(state);

          this.biDefaultFilters = state.filters.model as BiFilters;

          this.filters = this._biService.applyFilterLocks(
            this.biDefaultFilters,
            this.biPageFilters,
            state.lockFilters
          );

          //Uncomment this when working in WB Builder story
          this.setWBKpiParams(state.params);
          this.setWBParams();

          // This must be here because only the WB widget knows how to serialize its filters.
          this.updateItemSettings({
            params: state.params,
            defaultFilters: this.biDefaultFilters,
            lockFilters: state.lockFilters,
            widgetInstanceKey: this.getWidgetInstanceKey(),
            title: state.title,
          });

          // This is to avoid loading the same params again.
          this._state.dispatch(new ResetDefaultParamsAction(this._scopeSettings));
        },
      });
  }

  get componentName(): string {
    return COMPONENT_SELECTOR;
  }

  setWBKpiParams(params: WaterBalanceKpisWidgetParams): void {
    this.dimensionTypeId = params.dimensionTypeId;
    this.kpis = params.kpis;
    this.waterBalanceType = params.waterBalanceType;
    this.currencyTypeId = params.currencyTypeId;
  }

  setDateParams(dateRange: DateRange): void {
    this.filters.selectedDateRange = dateRange;
  }

  setElementsIds(elementIds: string[]): void {
    this.filters.selectedIds = elementIds;
    this.setWBParams();
  }

  setWBParams() {
    this.wbParams = new WaterBalanceParams({
      dimensionTypeId: this.dimensionTypeId,
      startDate: this._dateHelperService.ensureDateObject(this.filters.selectedDateRange.start),
      endDate: this._dateHelperService.ensureDateObject(this.filters.selectedDateRange.end),
      waterBalanceType: this.waterBalanceType,
      familyId: this.filters.selectedFamily,
      hierarchyElementIds: this.filters.selectedIds,
      currencyTypeId: this.currencyTypeId ?? CurrencyTypesEnum.NA,
    });
  }
}
