import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ZoneAlgorithmGridSettings } from '@common-modules/shared/constants/grid.constants';

const COMPONENT_SELECTOR = 'wlm-zone-algorithm-grid';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './zone-algorithm-grid.component.html',
  styleUrls: ['./zone-algorithm-grid.component.scss'],
})
export class ZoneAlgorithmGridComponent implements OnInit {
  @Input() gridSettings: ZoneAlgorithmGridSettings;
  @Output() selectionChange = new EventEmitter<Map<string, string[]>>();

  public rowSelection: Map<string, boolean>;
  public rowIndeterminate: Map<string, boolean>;
  public selectedValues: Map<string, string[]>;
  public selectAllValue = false;
  public selectAllIndeterminateValue = false;
  public selectionColumnWidth = 16;
  public zoneColumnWidth = 90;
  public algorithmColumnWidth = 30;
  public treelistHeight = 410;
  public expandable = true;
  public resizable = true;

  constructor() {}

  ngOnInit(): void {
    this.rowSelection = new Map<string, boolean>();
    this.rowIndeterminate = new Map<string, boolean>();
    this.gridSettings.zones.forEach((he) => {
      this.rowSelection.set(he, false);
      this.rowIndeterminate.set(he, false);
    });
  }

  public rowSelectionChanged(event: MatCheckboxChange, he: string) {
    this.gridSettings.values.get(he).forEach((_v, k) => {
      this.gridSettings.values.get(he).set(k, event.checked);
    });
    this.rowSelection.set(he, event.checked);
    this.rowIndeterminate.set(he, false);
    this.calculateSelectAllValue();
  }

  public algorithmSelectionChanged(event: MatCheckboxChange, he: string, algorithm: string) {
    this.gridSettings.values.get(he).set(algorithm, event.checked);
    const rowValue = this.calculateSelectRowValue(he);
    this.rowSelection.set(he, rowValue);

    const numberOfChecked = Array.from(this.gridSettings.values.get(he).values()).filter(
      (f) => f === true
    ).length;
    const numberOfAlgorithms = this.gridSettings.algorithms.length;
    const isIndeterminate = numberOfChecked > 0 && numberOfChecked < numberOfAlgorithms;
    this.rowIndeterminate.set(he, isIndeterminate);
    this.calculateSelectAllValue();
  }

  private calculateSelectRowValue(he: string) {
    let rowValue = true;
    this.gridSettings.values.get(he).forEach((v) => {
      rowValue = rowValue && v;
    });
    return rowValue;
  }

  public selectAllChanged(event: MatCheckboxChange) {
    this.gridSettings.values.forEach((_algorithmHe, he) => {
      this.rowSelectionChanged(event, he);
    });
  }

  private calculateSelectAllValue() {
    let allvalue = true;
    this.rowSelection.forEach((v) => {
      allvalue = allvalue && v;
    });
    this.selectAllValue = allvalue;
    this.calculateSelectAllIndeterminateValue();
    this.notifyChanges();
  }

  calculateSelectAllIndeterminateValue() {
    const rowSelectionCount = Array.from(this.rowSelection.values()).filter(
      (f) => f === true
    ).length;
    const rowIndeterminateCount = Array.from(this.rowIndeterminate.values()).filter(
      (f) => f === true
    ).length;

    const partialSelectionByRow =
      rowSelectionCount > 0 && rowSelectionCount < this.gridSettings.zones.length;
    const partialSelectionByColumn =
      rowIndeterminateCount > 0 && rowIndeterminateCount < this.gridSettings.algorithms.length;

    this.selectAllIndeterminateValue = partialSelectionByRow || partialSelectionByColumn;
  }

  private notifyChanges() {
    const values = new Map<string, string[]>();
    let selectedAlgorithms: string[];
    if (this.gridSettings.values.size > 0) {
      this.gridSettings.values.forEach((selection, he) => {
        selectedAlgorithms = [];
        selection.forEach((isSelected, algorithmShortName) => {
          if (isSelected) {
            selectedAlgorithms.push(algorithmShortName);
          }
        });
        if (selectedAlgorithms.length > 0) {
          values.set(he, selectedAlgorithms);
        }
      });
    }
    this.selectionChange.emit(values);
  }
}
