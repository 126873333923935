import { Component, Input, OnInit } from '@angular/core';
import { IGisLayerDto } from '@common-modules/shared/model/gis/gis-layer.dto';
import { Observable } from 'rxjs';
import { GisLayerService } from '../../shared/gis/gis-layer.service';

@Component({
  selector: 'wlm-feature-info',
  templateUrl: './feature-info.component.html',
  styleUrls: ['./feature-info.component.scss'],
})
export class FeatureInfoComponent implements OnInit {
  private _feature: mapboxgl.MapboxGeoJSONFeature;

  constructor(private service: GisLayerService) {}

  ngOnInit(): void {}

  @Input()
  public set feature(value: mapboxgl.MapboxGeoJSONFeature) {
    this._feature = value;
  }

  public get feature(): mapboxgl.MapboxGeoJSONFeature {
    return this._feature;
  }

  public get layer(): Observable<IGisLayerDto> {
    const layerId = +(this.feature?.properties['type'] ?? 0);

    if (!layerId) {
      return null;
    }

    return this.service.getByKey(layerId);
  }
}
