import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BiDefaultParamsButtonComponent } from '@common-modules/dynamic-layout/components/bi-default-params-button/bi-default-params-button.component';
import { BiDefaultParamsPopupComponent } from '@common-modules/dynamic-layout/components/bi-default-params-popup/bi-default-params-popup.component';
import { DynamicLayoutModule } from '@common-modules/dynamic-layout/dynamic-layout.module';
import { DynamicLayoutItemSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-item-settings';
import { MaterialSharedModule } from '@common-modules/shared/material-shared.module';
import { WidgetRegistryService } from '@common-modules/widget-registry/widget-registry';
import { WLMGridModule } from '@common-modules/wlm-grid/wlm-grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import { MapModule } from '../map/map.module';
import { NeConfigurationModule } from '../ne-configuration/ne-configuration.module';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WaterBalanceModule } from '../water-balance/water-balance.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { BiRoutingModule } from './bi-routing.module';
import { BiDefaultParamsFormComponent } from './components/bi-default-params-form/bi-default-params-form.component';
import { BiFiltersComponent } from './components/bi-filters/bi-filters.component';
import { BiHomePageComponent } from './components/bi-home-page/bi-home-page.component';
import { BiManagePopupComponent } from './components/bi-manage-popup/bi-manage-popup.component';
import { BiPageComponent } from './components/bi-page/bi-page.component';
import { BiSavePopupComponent } from './components/bi-save-popup/bi-save-popup.component';
import { BiSaveTemplateFormComponent } from './components/bi-save-template-form/bi-save-template-form.component';
import { WidgetInfoComponent } from './components/widget-info/widget-info.component';
import { WidgetSelectorItemComponent } from './components/widget-selector-item/widget-selector-item.component';
import { WidgetSelectorComponent } from './components/widget-selector/widget-selector.component';
import { WidgetsContainerComponent } from './components/widgets-container/widgets-container.component';
import { BiContainerWidgetComponent } from './components/widgets/main/bi-container-widget/bi-container-widget.component';
import { BiFiltersWidgetComponent } from './components/widgets/main/bi-filters-widget/bi-filters-widget.component';
import { WidgetSelectorWidgetComponent } from './components/widgets/main/widget-selector-widget/widget-selector-widget.component';
import { MapWidgetComponent } from './components/widgets/map-widget/map-widget.component';
import { PieChartWidgetComponent } from './components/widgets/pie-chart-widget/pie-chart-widget.component';
import { TrendChartWidgetComponent } from './components/widgets/trend-chart-widget/trend-chart-widget.component';
import { WaterBalanceKpisWidgetComponent } from './components/widgets/water-balance-kpis-widget/water-balance-kpis-widget.component';
import { WorkspaceChartWidgetComponent } from './components/widgets/workspace-chart-widget/workspace-chart-widget.component';
import { BiPersistLayoutService } from './services/bi-persist-layout.service';
import { BiService } from './services/bi.service';

@NgModule({
  declarations: [
    WidgetSelectorComponent,
    BiFiltersComponent,
    BiPageComponent,
    BiHomePageComponent,
    WorkspaceChartWidgetComponent,
    BiFiltersWidgetComponent,
    WidgetSelectorWidgetComponent,
    BiContainerWidgetComponent,
    WidgetSelectorItemComponent,
    WidgetsContainerComponent,
    BiSavePopupComponent,
    BiManagePopupComponent,
    BiSaveTemplateFormComponent,
    WaterBalanceKpisWidgetComponent,
    TrendChartWidgetComponent,
    PieChartWidgetComponent,
    MapWidgetComponent,
    BiDefaultParamsPopupComponent,
    BiDefaultParamsButtonComponent,
    BiDefaultParamsFormComponent,
    WidgetInfoComponent,
  ],
  imports: [
    CommonModule,
    WlmSharedModule,
    WlmSharedComponentModule,
    WlmChartsModule,
    BiRoutingModule,
    DynamicLayoutModule.forFeature({
      extendHeaders: [
        {
          component: BiDefaultParamsButtonComponent,
          shouldRender: (itemSettings: DynamicLayoutItemSettings) => {
            return itemSettings?.hasDefaultFilters;
          },
          flagName: 'bi-default-filters',
        },
      ],
    }),
    MaterialSharedModule,
    TranslateModule, // To import the pipe
    WLMGridModule,
    MapModule,
    // TODO: Remove after Redux refactor
    NeConfigurationModule,
    WaterBalanceModule,
    WlmFiltersModule,
  ],
  exports: [],
  providers: [
    BiService,
    BiPersistLayoutService,
    {
      provide: 'BiPersistLayoutService',
      useExisting: BiPersistLayoutService,
    },
  ],
})
export class BiModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      {
        name: 'WorkspaceChartWidgetComponent',
        widgetClass: WorkspaceChartWidgetComponent,
      },
      {
        name: 'TrendChartWidgetComponent',
        widgetClass: TrendChartWidgetComponent,
      },
      {
        name: 'PieChartWidgetComponent',
        widgetClass: PieChartWidgetComponent,
      },
      {
        name: 'MapWidgetComponent',
        widgetClass: MapWidgetComponent,
      },
      {
        name: 'WaterBalanceKpisWidgetComponent',
        widgetClass: WaterBalanceKpisWidgetComponent,
      },
      {
        name: 'BiFiltersComponent',
        widgetClass: BiFiltersComponent,
      },
      {
        name: 'WidgetSelectorWidgetComponent',
        widgetClass: WidgetSelectorWidgetComponent,
      },
      {
        name: 'BiContainerWidgetComponent',
        widgetClass: BiContainerWidgetComponent,
      },
    ]);
  }
}
