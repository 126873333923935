import { Component, Input } from '@angular/core';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';

const COMPONENT_SELECTOR = 'wlm-smart-meters-config';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './smart-meters-config.component.html',
  styleUrls: ['./smart-meters-config.component.scss'],
})
export class SmartMetersConfigComponent {
  @Input() selectedNE: INetworkElementDto;
  @Input() widgetId: string;
  @Input() pageId: string;
}
