import { Injectable, Injector } from '@angular/core';
import { GenericCrudService } from '@common-modules/generic-crud/generic-crud.service';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable, map } from 'rxjs';
import { IMaterialTypeCrudValidationDto } from './material-type-crud-validation.dto';
import { IMaterialTypeCrudDto } from './material-type-crud.dto';

@Injectable()
export class MaterialTypesCrudService
  extends BaseService
  implements GenericCrudService<IMaterialTypeCrudDto, string>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/material-types`;
  }

  getAll(): Observable<IMaterialTypeCrudDto[]> {
    return this.httpCacheClient.get<IMaterialTypeCrudDto[]>(this._entityUrl).pipe(
      map((items) =>
        items.map((item) => {
          item.materialIdsFormatted = this.buildMaterialIdsFormatted(item);
          return item;
        })
      )
    );
  }

  create(entity: IMaterialTypeCrudDto): Observable<IMaterialTypeCrudDto> {
    return this.httpCacheClient.post<IMaterialTypeCrudDto>(this._entityUrl, entity);
  }

  update(entity: IMaterialTypeCrudDto): Observable<IMaterialTypeCrudDto> {
    return this.httpCacheClient.put<IMaterialTypeCrudDto>(this._entityUrl, entity);
  }

  delete(id: string): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  validate(entity: IMaterialTypeCrudDto): Observable<IMaterialTypeCrudValidationDto> {
    return this.httpCacheClient.post<IMaterialTypeCrudValidationDto>(
      `${this._entityUrl}/validate`,
      entity
    );
  }

  getId(entity: IMaterialTypeCrudDto): string {
    return entity.materialGroup;
  }

  private buildMaterialIdsFormatted = (entity: IMaterialTypeCrudDto): string =>
    entity.materialIds.join(', ');
}
