import { Component, Inject, Injector, Input, OnInit, Optional } from '@angular/core';
import { FiltrableItemManagerService } from '@common-modules/shared/charts/filtrable-item-manager.service';
import { IExportExcelComponent } from '@common-modules/shared/exports/models/export-excel-component';
import { ExportExcelSettings } from '@common-modules/shared/exports/models/export-excel-settings';
import { IExportPdfComponent } from '@common-modules/shared/exports/models/export-pdf-component';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { IElementSize } from '@common-modules/shared/model/element-size';
import { ChartConfiguration } from '@water-loss//features/shared/charts/model/chart-configuration';
import { asEnumerable } from 'linq-es2015';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GenericChartsModuleSettings, WLM_CHART_SETTINGS } from '../../generic-charts.module';
import { BaseChartComponent } from '../base-chart/base-chart.component';
import { CustomizableChartParameters } from '../models/customizable-chart-parameters';
import { CustomizableChartSettings } from '../models/customizable-chart-settings';
import { GenericCartesianChartSettings } from '../models/generic-chart-settings/generic-cartesian-chart-settings';
import { GenericChartSettings } from '../models/generic-chart-settings/generic-chart-settings';
import { BaseCartesianChartService } from '../services/base-cartesian-chart.service';
import { GenericChartService } from '../services/generic-chart.service';
import { CustomizableChartService } from './customizable-chart.service';

const COMPONENT_SELECTOR = 'wlm-customizable-chart';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './customizable-chart.component.html',
  styleUrls: ['./customizable-chart.component.scss'],
  providers: [GenericChartService],
})
export class CustomizableChartComponent
  extends BaseChartComponent
  implements OnInit, IExportExcelComponent, IExportPdfComponent
{
  @Input() disableInnerLoading = true;
  @Input() avoidConversions = false;
  @Input() size: IElementSize;

  private _chartSettings: CustomizableChartSettings;
  public get chartSettings(): CustomizableChartSettings {
    return this._chartSettings;
  }
  @Input() public set chartSettings(v: CustomizableChartSettings) {
    this._chartSettings = v;

    if (this.chartSettings) {
      this.initializeChart();
      this.loadChart();
    }
  }

  private _dataService: BaseCartesianChartService;

  genericChartSettingsSubscription: Subscription;
  dataLoaded: boolean;

  constructor(
    _injector: Injector,
    @Optional()
    @Inject(WLM_CHART_SETTINGS)
    private _externalSettings: GenericChartsModuleSettings,
    private _filtrableItemManagerService: FiltrableItemManagerService,
    private _dateHelperService: DateHelperService
  ) {
    super(_injector);
  }

  ngOnInit(): void {}

  protected getSerieData(params: ChartConfiguration): Observable<any[]> {
    return this._dataService.getData(params);
  }

  public getGenericChartSettings(
    dataParameters?: CustomizableChartParameters
  ): Observable<GenericChartSettings> {
    return this.getSerieData(dataParameters.chartConfiguration).pipe(
      switchMap((data) => {
        return this._dataService.mapDataToGenericSettings(data);
      }),
      switchMap((settings: GenericCartesianChartSettings) => {
        if (this._externalSettings?.avoidConversion) {
          return this.genericChartService.getNoConversionCartesianChart(settings);
        } else {
          return this.genericChartService.getCustomizableChart(
            settings as GenericCartesianChartSettings
          );
        }
      })
    );
  }

  public setDateParams(startDate: Date, endDate: Date) {
    if (
      this._dateHelperService.equals(startDate, this.chartSettings.dataParameters.startDate) &&
      this._dateHelperService.equals(endDate, this.chartSettings.dataParameters.endDate)
    ) {
      return;
    }

    this.setDateRange(startDate, endDate);

    this.chartSettings.dataParameters.chartConfiguration.horizontalAxis.forEach((axis) => {
      axis.minimum = startDate;
      axis.maximum = endDate;
    });

    this.chartSettings.dataParameters.chartConfiguration.seriesConfiguration.forEach((serie) => {
      const filtrableItem = serie.serieDefinition;
      const service = this._filtrableItemManagerService.getService(
        filtrableItem.filtrableType.type
      );

      service.setDateRange(filtrableItem, startDate, endDate);
    });

    this.resetChart();
    this.loadChart();
  }

  public getParams(): CustomizableChartParameters {
    return this.chartSettings.dataParameters;
  }

  public setParams(newParams: CustomizableChartParameters) {
    this.chartSettings.dataParameters = newParams;
  }

  getExportExcelSettings(): Observable<ExportExcelSettings> {
    return this.genericChart.getExportExcelSettings();
  }

  exportToPdf(): void {
    this.genericChart.exportToPdf();
  }

  isEmpty(): boolean {
    return !this.dataLoaded;
  }

  getObjectToExport() {
    this.genericChart.getObjectToExport();
  }

  private instanceDataService() {
    this._dataService = this._injector.get(CustomizableChartService);
    this._dataService.setGenericChartService(this.genericChartService);
  }

  private initializeChart() {
    this.resetChart();
    this.instanceDataService();
  }

  private resetChart() {
    this.genericChartSettingsSubscription?.unsubscribe();
    this.genericChartSettings = null;
    this.dataLoaded = false;
  }

  private loadChart(dataParameters?: CustomizableChartParameters) {
    this.setLoading(true);

    this.genericChartSettingsSubscription = this.getGenericChartSettings(
      dataParameters ?? this.chartSettings.dataParameters
    ).subscribe({
      next: (settings: GenericCartesianChartSettings) => {
        let hasValues = false;
        if (settings) {
          this.setExportTitle(settings);
          hasValues = asEnumerable(settings.series ?? [])
            .SelectMany((x) => x?.dataPoints)
            .Any();
          this.genericChartSettings = hasValues ? settings : null;

          this.dataLoaded = true;
        }

        if (!hasValues || !settings) {
          this.chartLoaded.next(true);
        }

        this.setLoading(false);
      },
      error: (error) => {
        this.genericChartSettings = null;
        this.dataLoaded = true;
        this.chartLoaded.next(true);

        this.setLoading(false);
      },
    });
  }

  private setExportTitle(settings: GenericChartSettings) {
    if (settings) {
      settings.exportFileName = this._chartSettings?.exportFileName;
    }
  }
}
