import { Injectable, Injector } from '@angular/core';
import { StateFullSettings } from '@common-modules/redux/models/state-full-settings';
import { StateSelectorBuilder } from '@common-modules/redux/models/state-selector-builder';
import { BaseStore, ReducerHandlerFn } from '@common-modules/redux/store/base.store';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { ExtendedFilters } from '@common-modules/shared/filters/component-filters/extended-filters';
import { ActionCreator, createAction, props } from '@ngrx/store';
import { FiltersState } from './filters-state';
import { FiltersActionTypes } from './filters.action-types';
import {
  ApplyExtendedFiltersAction,
  ApplyFiltersAction,
  ResetFiltersAction,
  SetFiltersSettingsAction,
} from './filters.actions';
import { FiltersSelectorTypes } from './filters.selector-types';

@Injectable()
export class FiltersStoreService extends BaseStore {
  readonly serviceName = 'FiltersStoreService';

  constructor(injector: Injector) {
    super(injector);
  }

  protected getReducer(): Map<string, ReducerHandlerFn> {
    return new Map<string, ReducerHandlerFn>([
      [
        FiltersActionTypes.ApplyFilters,
        (state: FiltersState, action: ApplyFiltersAction) => {
          const newExtendedFilters = state.extendedFilters ?? {};
          newExtendedFilters.dataBindingFilters = action.payload;
          state.extendedFilters = newExtendedFilters;
        },
      ],
      [
        FiltersActionTypes.ApplyExtendedFilters,
        (state: FiltersState, action: ApplyExtendedFiltersAction) => {
          state.extendedFilters = action.payload;
        },
      ],
      [
        FiltersActionTypes.SetSettings,
        (state: FiltersState, action: SetFiltersSettingsAction) => {
          state.settings = action.payload;
        },
      ],
      [
        FiltersActionTypes.ResetFilters,
        (state: FiltersState, action: ResetFiltersAction) => {
          state.lastResetTime = new Date().getTime();
        },
      ],
    ]);
  }

  protected getSelectors(): Map<string, StateSelectorBuilder> {
    return new Map<string, StateSelectorBuilder>([
      [
        FiltersSelectorTypes.AppliedFilters,
        {
          fn: (state: FiltersState) => {
            return state?.extendedFilters?.dataBindingFilters;
          },
        },
      ],
      [
        FiltersSelectorTypes.AppliedExtendedFilters,
        {
          fn: (state: FiltersState) => {
            if (!state) {
              return null;
            }
            return state?.extendedFilters;
          },
        },
      ],
      [
        FiltersSelectorTypes.GetSettings,
        {
          fn: (state: FiltersState) => {
            return state?.settings;
          },
        },
      ],
      [
        FiltersSelectorTypes.ResetFilters,
        {
          fn: (state: FiltersState) => {
            return state?.lastResetTime;
          },
        },
      ],
    ]);
  }

  protected getActionCreators(): ActionCreator<any>[] {
    return [
      createAction(
        FiltersActionTypes.ApplyFilters,
        props<{ payload: DataBindingFilters; settings: StateFullSettings }>()
      ),
      createAction(
        FiltersActionTypes.ApplyExtendedFilters,
        props<{ payload: ExtendedFilters; settings: StateFullSettings }>()
      ),
      createAction(
        FiltersActionTypes.SetSettings,
        props<{ payload: any; settings: StateFullSettings }>()
      ),
      createAction(
        FiltersActionTypes.ResetFilters,
        props<{ payload: any; settings: StateFullSettings }>()
      ),
    ];
  }
}
