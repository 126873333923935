import { Component, Inject, Injector, OnInit } from '@angular/core';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { necSelectNetworkElement } from '../../helpers/select-network-element';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-lars-sworps-widget';
export const NEC_LARS_SWORPS_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-lars-sworps-widget.component.html',
  styleUrls: ['./ne-configuration-lars-sworps-widget.component.scss'],
  providers: [ReduxStateService],
})
export class NeConfigurationLarsSworpsWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  currentNE: INetworkElementDto;
  pageId: string;
  widgetId: string;

  // Not all actions/selectors must have the same action settings.
  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);

    this.pageId = widgetSettings.page;
    this.widgetId = widgetSettings.widgetInstanceKey;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onWidgetInit(): void {
    necSelectNetworkElement(this, this._state, this._scopeSettings, (selectedNE) => {
      if (!selectedNE) {
        return;
      }
      this.currentNE = selectedNE;
    });
  }

  get componentName(): string {
    return 'NeConfigurationLarsSworpsWidgetComponent';
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}
}
