import { Component, Inject, Injector, OnInit } from '@angular/core';
import { IInterfacesLoggingSelector } from '@common-modules/dependencies/logs/models/interfaces-logging-selector';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { DynamicLayoutService } from '@common-modules/dynamic-layout/services/dynamic-layout.service';
import { GetValueSelector } from '@common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LogsInterfacesStateFields } from '../../models/logs-interfaces-state-fields';
import { RefreshLogIterfaceSelectorAction } from '../refresh-log-interfaces-selector.event';

const COMPONENT_SELECTOR = 'wlm-logs-interfaces-container-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './logs-interfaces-container-widget.component.html',
  styleUrls: ['./logs-interfaces-container-widget.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class LogsInterfacesContainerWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  T_SCOPE = `${AppModules.Logs}.${COMPONENT_SELECTOR}`;

  get componentName(): string {
    return 'LogsInterfacesContainerWidgetComponent';
  }

  readonly phTitleKey = `${this.T_SCOPE}.placeholder-title`;
  readonly phSubtitleKey = `${this.T_SCOPE}.placeholder-subtitle`;

  selectedInterface: IInterfacesLoggingSelector;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  onWidgetInit(): void {
    this._state
      .select(
        new GetValueSelector({
          fieldName: LogsInterfacesStateFields.selectedInterface,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((value: IInterfacesLoggingSelector) => {
        this.selectedInterface = value;
      });
  }

  onRefreshSelector(): void {
    this._state.dispatch(new RefreshLogIterfaceSelectorAction());
  }
}
