import { Component, Input } from '@angular/core';
import { IntegrableForm, IntegrableFormParams } from '@common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { IMaterialTypeCrudDto } from '../material-type-crud.dto';

@Component({
  selector: 'wlm-material-types-form',
  templateUrl: './material-types-form.component.html',
  styleUrls: ['./material-types-form.component.scss'],
})
export class MaterialTypesFormComponent extends IntegrableForm {
  @Input() initialModel: IMaterialTypeCrudDto;
  model: IMaterialTypeCrudDto;

  onModelChange(model: IMaterialTypeCrudDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'materialGroup',
          type: 'input',
          className: 'col-12',
          props: {
            label: 'Material Group',
            required: true,
          },
          expressions: {
            'props.disabled': (data: FormlyFieldConfig) =>
              this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'materialIds',
          type: 'chips-selector',
          className: 'col-12',
          props: {
            label: 'Material Ids',
            required: true,
            maxChipLength: 40,
          },
          validators: {
            validation: [{ name: 'maxLengthInList', options: { maxLength: 40 } }],
          },
        },
      ],
    },
  ];
}
