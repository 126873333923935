import { Component, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { WidgetDefinitionSettings } from '@common-modules/dynamic-layout/models/widget-definition-settings';
import { SelectWidgetDefinitionAction } from '@common-modules/dynamic-layout/state/widget-manager/widget-manager.actions';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { Observable } from 'rxjs';
import { BiService } from '../../../../services/bi.service';

import { WidgetSelectorSettings } from '../../../widget-selector/widget-selector-settings';

const COMPONENT_SELECTOR = 'wlm-widget-selector-widget';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './widget-selector-widget.component.html',
  styleUrls: ['./widget-selector-widget.component.scss'],
  providers: [ReduxStateService],
})
export class WidgetSelectorWidgetComponent extends BaseDynamicWidgetComponent implements OnInit {
  settings: WidgetSelectorSettings;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _biService: BiService
  ) {
    super(injector, widgetSettings);
  }

  onWidgetInit(): void {
    this.getAvailableKpis().subscribe((widgetDefinitions) => {
      this.settings = new WidgetSelectorSettings({
        definitions: widgetDefinitions,
        groups: new Map(),
      });
    });
  }

  onDefinitionSelected(definition: WidgetDefinitionSettings): void {
    this.dispatchSelectedDefinition(definition);
  }

  private dispatchSelectedDefinition(definition): void {
    this._state.dispatch(new SelectWidgetDefinitionAction(definition));
  }

  private getAvailableKpis(): Observable<WidgetDefinitionSettings[]> {
    return this._biService.getAvailableKpis();
  }

  get componentName(): string {
    return 'WidgetSelectorWidgetComponent';
  }
}
