import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericCardSettings } from '@common-modules/shared-component/generic-card/generic-card-settings';
import { DragListSettings } from '@common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { SelectDragListSettings } from '@common-modules/shared/core/select-drag-list-virtual/select-drag-list-settings';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { Schematic } from '@common-modules/wlm-charts/core/models/schematics/schematic';

const COMPONENT_SELECTOR = 'wlm-schematic-selector';
type SelectedElement = any;

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './schematic-selector.component.html',
  styleUrls: ['./schematic-selector.component.scss'],
})
export class SchematicSelectorComponent implements OnInit {
  private _filters: Map<string, any>;
  get filters(): Map<string, any> {
    return this._filters;
  }
  @Input() set filters(value: Map<string, any>) {
    this._filters = value;
    this.prepareListsSettings();
  }
  @Input() widgetInstanceKey: string;
  @Input() gridName: string;
  @Input() subscriptionTag = 'default';

  @Output() selectedElementChange = new EventEmitter<SelectedElement>();

  private _nameFieldName = 'name';
  private _selectedFieldName = 'name';

  private _serviceName = 'SchematicsService';
  private _pagesize = 50;

  settings: DragListSettings;
  cardSettings: GenericCardSettings<Schematic>;
  queryParams: Map<string, any>;
  emptyFilters: DataBindingFilters;

  constructor() {}

  ngOnInit(): void {}

  onSelectItem(schematic: Schematic) {
    this.selectedElementChange.emit(schematic);
  }

  private prepareListsSettings() {
    this.settings = new SelectDragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._nameFieldName, dir: 'asc' }],
      oDataFiltersExtended: this.emptyFilters,
      useQueryParams: true,
      displayFieldName: this._nameFieldName,
      isReadOnly: false,
      scrollId: this.widgetInstanceKey,
      selectedFieldName: this._selectedFieldName,
      filtrableFields: [this._nameFieldName],
    });

    this.cardSettings = new GenericCardSettings<Schematic>({
      title1Fn: (model) => model.name,
      subtitleFn: (model) => model.description,
      useTitleCentered: true,
    });

    this.queryParams = this.filters;
  }
}
