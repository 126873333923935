import { Injectable, Injector } from '@angular/core';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { UserActivitityTrendEnum } from '@common-modules/shared/model/roles/user-activity.enum';
import { UserActivitityTrendDto } from '@common-modules/shared/model/roles/users-activity-trend.dto';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { ChartSerieTypeEnum } from '@common-modules/wlm-charts/core/models/chart-serie-type.enum';
import { GCartesianChartSerie } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-cartesian-chart-series';
import { GChartSerieDataPoint } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-serie-data-point';
import { GenericCartesianChartSettings } from '@common-modules/wlm-charts/core/models/generic-chart-settings/generic-cartesian-chart-settings';
import { BaseCartesianChartService } from '@common-modules/wlm-charts/core/services/base-cartesian-chart.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UsersActivityChartService extends BaseCartesianChartService {
  protected algorithmsList: UserActivitityTrendEnum[] = [
    UserActivitityTrendEnum.DULAC,
    UserActivitityTrendEnum.DAUC,
  ];

  protected attribute: UserActivitityTrendEnum = UserActivitityTrendEnum.LicensedUsers;

  protected NADimension = 50;

  constructor(
    injector: Injector,
    private dateHelperService: DateHelperService,
    private localization: LocalizationHelperService
  ) {
    super(injector);
  }

  protected get url() {
    return `${this.apiUrl}/roles/users/users-activity-chart`;
  }

  public getData(params: any): Observable<any[]> {
    const queryParams = {
      startDate: this.dateHelperService.toApiFormat(params.startDate),
      endDate: this.dateHelperService.toApiFormat(params.endDate),
      elementIds: params.queryParams.elementIds,
      algorithms: this.algorithmsList,
      attribute: this.attribute,
    };

    return this.httpCacheClient.get<UserActivitityTrendDto[]>(
      this.url,
      this.avoidCache,
      queryParams
    );
  }

  public mapDataToGenericSettings(
    data: UserActivitityTrendDto[]
  ): Observable<GenericCartesianChartSettings> {
    return this.localization
      .get(`${AppModules.WlmSecurity}.users-activity-trend-chart.serie-names`)
      .pipe(
        map((serieLabels: { [key: string]: string }) => {
          return data.map((values) => {
            const dataPoints: GChartSerieDataPoint[] = [];

            for (const trendValue of values.trendValues) {
              const dataPoint: GChartSerieDataPoint = {
                pointCategory: new Date(trendValue.key),
                pointValue: trendValue.value,
              };
              dataPoints.push(dataPoint);
            }

            const serieName = this.getSerieName(values.userActivity, serieLabels);

            const cartesianSerie: GCartesianChartSerie = {
              name: serieName,
              type: ChartSerieTypeEnum.Line,
              xAxisIndex: 0,
              yAxisIndex: 0,
              xAxisWLMDimensionTypeId: DimensionTypesEnum.NA,
              yAxisWLMDimensionTypeId: DimensionTypesEnum.NA,
              yAxisWLMUnitTypeIdTo: this.NADimension,
              yAxisWLMUnitTypeIdFrom: this.NADimension,
              dataPoints,
            };
            return cartesianSerie;
          });
        }),
        map(
          (series) =>
            new GenericCartesianChartSettings({
              series,
            })
        )
      );
  }

  getSerieName(userActivity: UserActivitityTrendEnum, serieLabels: { [key: string]: string }) {
    return serieLabels[UserActivitityTrendEnum[userActivity]];
  }
}
