import { Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { map, Observable } from 'rxjs';

import { NetworkElementAttributeTypeCrudDto } from '@common-modules/dependencies/ne/network-element-attribute-type-crud.dto';
import { FieldDataType } from '@common-modules/dynamic-forms/models/field-data-type';
import { TreeDropdownSettings } from '@common-modules/shared-component/tree-dropdown/tree-dropdown.component';
import { IntegrableForm, IntegrableFormParams } from '@common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { SelectOption } from '@common-modules/shared/model/shared/select-option';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { NEATypesCrudService } from '../neat-types-crud-service';

@UntilDestroy()
@Component({
  selector: 'wlm-neat-type-form',
  templateUrl: './neat-type-form.component.html',
  styleUrl: './neat-type-form.component.scss',
})
export class NeatTypeFormComponent extends IntegrableForm {
  @Input() initialModel: NetworkElementAttributeTypeCrudDto = null;
  model: NetworkElementAttributeTypeCrudDto = {} as NetworkElementAttributeTypeCrudDto;

  private readonly _neatTypesCrudService = inject(NEATypesCrudService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _globalService = inject(GlobalsService);

  onModelChange(model: NetworkElementAttributeTypeCrudDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'networkElementAttributeTypeId',
          type: 'input',
          className: 'col-6',
          props: {
            type: 'number',
            label: 'Network Element Atributte Type Id',
            required: true,
            min: 10000,
          },
          expressions: {
            'props.disabled': (data) => this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'categoryKey',
          type: 'select',
          className: 'col-6',
          props: {
            label: 'Category',
            required: true,
            options: this._neatTypesCrudService.getAllCategories().pipe(
              map((categories) =>
                categories.map((category) => ({
                  value: category,
                  label: category,
                }))
              )
            ),
          },
        },
        {
          key: 'networkElementAttributeTypeName',
          type: 'input',
          className: 'col-6',
          props: {
            type: 'text',
            label: 'Network Element Attribute Type Name',
            required: true,
          },
          expressions: {
            'props.disabled': (field) => {
              return this.formOptions.formState.op === 'update' && field.model.isCustom === false;
            },
          },
        },
        {
          key: 'order',
          type: 'input',
          className: 'col-6',
          props: {
            label: 'Order',
            required: true,
            type: 'number',
          },
        },
        {
          key: 'dataType',
          type: 'select',
          className: 'col-6',
          props: {
            required: true,
            label: 'Field Data Type',
            options: Object.keys(FieldDataType)
              .filter((key) => !isNaN(Number(FieldDataType[key])))
              .map((key) => ({ label: key, value: FieldDataType[key] })),
          },
          expressions: {
            'props.disabled': (field) => {
              return this.formOptions.formState.op === 'update';
            },
          },
          hooks: {
            onInit: (field) => {
              field.formControl.valueChanges
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((value) => {
                  const optionsArray = field.props.options as any[];
                  const selectedOption = optionsArray.find((option) => option.value === value);
                  field.model['dataTypeName'] = selectedOption.label;
                });
            },
          },
        },
        {
          key: 'dimensionTypeId',
          type: 'select',
          className: 'col-6',
          props: {
            label: 'Dimension Type',
            options: this._globalService.getDimensionTypesTranslated(),
          },
          expressions: {
            'props.disabled': (data) => {
              const result =
                this.formOptions.formState.op === 'update' || data.model.dataType !== 2;
              return result;
            },
          },
          hooks: {
            onInit: (field) => {
              (field.props.options as Observable<SelectOption<number>[]>)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((options) => {
                  field.formControl.valueChanges
                    .pipe(takeUntilDestroyed(this._destroyRef))
                    .subscribe((value) => {
                      const selectedOption = options.find((option) => option.value === value);
                      if (selectedOption) {
                        field.model['dimensionTypeLabel'] = selectedOption.label;
                      } else {
                        field.model['dimensionTypeLabel'] = null;
                      }
                    });
                });
            },
          },
        },
        {
          key: 'timeAggregationId',
          type: 'select',
          className: 'col-6',
          props: {
            label: 'Time Aggregation',
            options: this._globalService.getTimeAggregations().pipe(
              map((aggregations) =>
                aggregations.map((aggregation) => ({
                  label: aggregation.timeAggregationDescription,
                  value: aggregation.timeAggregationId,
                }))
              )
            ),
          },
          expressions: {
            'props.disabled': (data) => {
              const result =
                (this.formOptions.formState.op === 'update' && data.model.isCustom === false) ||
                data.model.dataType !== 2;
              return result;
            },
          },
          hooks: {
            onInit: (field) => {
              (field.props.options as Observable<{ label: string; value: number }[]>)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((options) => {
                  field.formControl.valueChanges
                    .pipe(takeUntilDestroyed(this._destroyRef))
                    .subscribe((value) => {
                      const selectedOption = options.find((option) => option.value === value);
                      if (selectedOption) {
                        field.model['timeAggregationLabel'] = selectedOption.label;
                      } else {
                        field.model['timeAggregationLabel'] = null;
                      }
                    });
                });
            },
          },
        },
        {
          key: 'crud',
          type: 'tree-dropdown',
          className: 'col-6',
          props: {
            label: 'CRUD',
            placeholder: 'Enter CRUD value',
            settings: new TreeDropdownSettings(),
            treeNodes: this._globalService.getDialogsTree(),
          },
          expressions: {
            'props.disabled': (field) => {
              return this.formOptions.formState.op === 'update' && field.model.isCustom === false;
            },
          },
        },
        {
          key: 'required',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Required',
            required: true,
          },
          expressions: {
            'props.disabled': (data) => this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'isCustom',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          hide: true,
          defaultValue: true,
          props: {
            label: 'Is Custom',
          },
        },
      ],
    },
  ];
}
