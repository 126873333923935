import { AlgorithmWizardStepSettings } from '@common-modules/common-filters/filters/algorithm-selection-grid-filter/algorithm-wizard-step-configuration';
import { SACalendarSelectorSettings } from './sa-calendar-selector-settings';
import { SAEnvelopesConfigurationSettings } from './sa-envelopes-configuration-settings';
import { SANameGeneratorSettings } from './sa-name-generator-settings';
import { SATargetSelectorSettings } from './sa-target-selector-settings';
import { SATelemetrySelectorSettings } from './sa-telemetry-selector-settings';
import { SATimeFrameSelectorSettings } from './sa-time-frame-selector-settings';
import { SAWizardMode } from './sa-wizard-mode.enum';

export class SAWizardSettings {
  timeFrameSettings: SATimeFrameSelectorSettings;
  pointsSelectionSettings: SATelemetrySelectorSettings;
  envelopesConfigurationSettings: SAEnvelopesConfigurationSettings;
  calendarSettings: SACalendarSelectorSettings;
  alarmNameSettings: SANameGeneratorSettings;
  targetSettings?: SATargetSelectorSettings;
  algorithmSettings?: AlgorithmWizardStepSettings;
  mode: SAWizardMode;

  constructor(init?: SAWizardSettings) {
    Object.assign(this, init);
  }
}
