import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { WLMGridModule } from '@common-modules/wlm-grid/wlm-grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { PressureManagementRoutingModule } from './pressure-management-routing.module';
import { PressureMonitoringPageComponent } from './pressure-monitoring-page/pressure-monitoring-page.component';
import { PressureMonitoringService } from './pressure-monitoring-page/pressure-monitoring.service';

@NgModule({
  declarations: [PressureMonitoringPageComponent],
  imports: [
    CommonModule,
    WlmSharedModule,
    WLMGridModule,
    TranslateModule,
    WlmFiltersModule,
    WlmSharedComponentModule,
    PressureManagementRoutingModule,
  ],
  providers: [
    PressureMonitoringService,
    { provide: 'PressureMonitoringService', useExisting: PressureMonitoringService },
  ],
})
export class PressureManagementModule {
  static injector: Injector;

  constructor(injector: Injector) {
    PressureManagementModule.injector = injector;
  }
}
