import { Injectable } from '@angular/core';
import { DefaultHistoricalValidationService } from '@common-modules/wlm-charts/core/services/default-historical-validations.service';
import { BoundarySignalVersionDto } from '../../shared/model/signals/boundary-signal-version.dto';
import { BoundarySignalDto } from '../../shared/model/signals/boundary-signal.dto';

@Injectable()
export class BoundaryHistoricalValidationService extends DefaultHistoricalValidationService<BoundarySignalDto> {
  validateCurrentConfiguration(config1: BoundarySignalDto, config2: BoundarySignalDto): boolean {
    if (config1.pointId !== config2.pointId || config1 === config2) {
      return false;
    }

    return true;
  }

  getCurrentFromHistorical(config: BoundarySignalVersionDto): BoundarySignalDto {
    if (!config) {
      return null;
    }
    const current: BoundarySignalDto = {
      signalId: config.signalId,
      pointDescription: config.pointDescription,
      pointId: config.pointId,
      hierarchyElementId: config.hierarchyElementId,
      hierarchyElementTypeId: config.hierarchyElementTypeId,
      isIn: config.isIn,
      title: '',
    };
    return current;
  }
}
