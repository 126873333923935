import { Component, DestroyRef, Inject, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { UtilsHelperService } from '@common-modules/shared/helpers/utils-helper.service';
import { JsonEditorComponent } from '@common-modules/shared/json-editor/json-editor.component';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { InterfaceRequestService } from '../services/interface-request.service';

const COMPONENT_SELECTOR = 'wlm-send-interfaces-csv-request-popup';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './send-interfaces-csv-request-popup.component.html',
  styleUrls: ['./send-interfaces-csv-request-popup.component.scss'],
})
export class SendInterfacesCsvRequestPopupComponent {
  @ViewChild(JsonEditorComponent) public jsonEditor: JsonEditorComponent;
  isSaving = false;
  hasErrors = false;

  readonly T_SCOPE = `${AppModules.Interfaces}.${COMPONENT_SELECTOR}`;
  readonly fieldAppearance = 'outline';
  readonly fieldScope = `${this.T_SCOPE}.fields`;

  private _interfaceName: string;

  private _id: string;
  form: UntypedFormGroup;
  private readonly _destroyRef = inject(DestroyRef);

  constructor(
    private _dialogRef: MatDialogRef<SendInterfacesCsvRequestPopupComponent>,
    @Inject(MAT_DIALOG_DATA) { interfaceName, interfaceUrl }: any,
    private _dialogService: DialogService,
    private _spinnerService: SpinnerService,
    private _utilsHelper: UtilsHelperService,
    private _interfaceRequestService: InterfaceRequestService,
    private _fb: UntypedFormBuilder
  ) {
    this._interfaceName = interfaceName;
    this._id = this._utilsHelper.generateGuid();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  buildForm(): void {
    const V = Validators;
    this.form = this._fb.group({
      file: [null, [V.required]],
    });

    this.form.statusChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.hasErrors = !this.form.valid;
    });
  }

  close(isCompleted: boolean = false) {
    this._dialogRef.close(isCompleted);
  }

  onJsonHasError(hasErrors) {
    this.hasErrors = hasErrors;
  }

  sendRequest() {
    this._spinnerService.setLoading(true, this._id);
    const request: { file: File } = this.form.getRawValue();

    const requestFile = new FormData();
    requestFile.append('file', request.file, request.file.name);

    this._interfaceRequestService.sendCsvRequest(this._interfaceName, requestFile).subscribe({
      next: (response) => {
        const messageKey = 'interface-request-completed';
        this._dialogService.showTranslatedMessageInSnackBar(
          new WlmDialogSettings({
            translateKey: `${this.T_SCOPE}.messages.${messageKey}`,
            params: response,
          })
        );
        this._spinnerService.setLoading(false, this._id);
        this.close(true);
      },
      error: (error) => {
        const errorMessageKey = 'interface-request-error';
        this._dialogService.showMessage(`${this.T_SCOPE}.messages.${errorMessageKey}`, 'error');
        this._spinnerService.setLoading(false, this._id);
      },
    });
  }
}
