import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

export class ElementTypeFilter implements IFilter {
  public selectedElements: any[];
  public elementFieldName: string;

  constructor(fieldName: string, selected?: any[]) {
    this.selectedElements = selected;
    this.elementFieldName = fieldName;
  }

  getFiltersValues(): Map<string, any> {
    return new Map<string, any>([[this.elementFieldName, this.selectedElements]]);
  }
  getFilters(): CompositeFilterDescriptor {
    const mainCompositeFilter: CompositeFilterDescriptor = { filters: [], logic: 'and' };

    if (this.selectedElements.length > 0) {
      const elementCompositeFilter: CompositeFilterDescriptor = { filters: [], logic: 'or' };
      this.selectedElements.forEach((selected) => {
        let elementFilter: FilterDescriptor;
        elementFilter = {
          field: this.elementFieldName,
          operator: 'eq',
          value: selected,
        };
        elementCompositeFilter.filters.push(elementFilter);
      });
      mainCompositeFilter.filters.push(elementCompositeFilter);
    }

    return mainCompositeFilter;
  }

  getSelectedFilters() {
    return new ElementTypeFilter(this.elementFieldName, this.selectedElements);
  }
}
