import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from '@angular/core';
import { IEventDto } from '@common-modules/dependencies/alarms/event.dto';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { EventService } from '@common-modules/shared/charts/event.service';
import { SettingsService } from '@common-modules/shared/config/settings.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { IElementSize } from '@common-modules/shared/model/element-size';
import { LogService } from '@common-modules/shared/wlm-log/log.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateRange } from '@progress/kendo-angular-scheduler';
import * as echarts from 'echarts';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ChartCustomLegendItem } from '../models/chart-custom-legend-item';
import { ChartSettings } from '../models/chart-settings';
import { GCartesianCustomChartSerie } from '../models/generic-chart-settings/g-cartesian-custom-chart-series';
import { GChartAxis } from '../models/generic-chart-settings/g-chart-axis';
import { GChartAxisLabel } from '../models/generic-chart-settings/g-chart-axis-label';
import { GChartAxisLine } from '../models/generic-chart-settings/g-chart-axis-line';
import { GChartAxisSplitLine } from '../models/generic-chart-settings/g-chart-axis-split-line';
import { GChartAxisTick } from '../models/generic-chart-settings/g-chart-axis-tick';
import { GChartDataZoom } from '../models/generic-chart-settings/g-chart-data-zoom';
import { GChartGrid } from '../models/generic-chart-settings/g-chart-grid';
import { GChartTooltip } from '../models/generic-chart-settings/g-chart-tooltip';
import { GenericCartesianCustomChartSettings } from '../models/generic-chart-settings/generic-cartesian-custom-chart-settings';
import { GChartClickEvent } from '../models/generic-events/g-chart-click-event';
import { GChartDataZoomEvent } from '../models/generic-events/g-chart-data-zoom-event';
import { EventChartPosition } from './models/event-chart-position';
import { EventViewAlarmCategories } from './models/event-view-alarm-categories';
import { EventViewCategories } from './models/event-view-categories';
import { EventChartQueryDto } from './models/events-chart-query.dto';

interface IProcessedEventDto extends IEventDto {
  sourceIndex: number;
}

const COMPONENT_SELECTOR = 'wlm-events-chart';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './events-chart.component.html',
  styleUrls: ['./events-chart.component.scss'],
})
export class EventsChartComponent implements OnInit {
  // Required for space calculation.
  @Input() chartHeigth = 200; // px
  // If set to true, eventWindow will be overriden and calculated depending on the available space.
  @Input() autoCalculateEventWindow = false;
  // Number of events to show at the same time.
  @Input() eventWindow = 4;
  // Allows to enable the slider dataZoom for the X axis.
  @Input() enableXDataZoom = true;
  // Position of the horizontal dataZoom.
  @Input() public set position(position: EventChartPosition) {
    this.positionHandler$.next(position);
  }
  @Output() chartClick = new EventEmitter<GChartClickEvent>();
  @Output() chartDataZoom = new EventEmitter<GChartDataZoomEvent>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() sizeChanged = new EventEmitter<IElementSize>();

  private _eventQuery: EventChartQueryDto;
  public get eventQuery(): EventChartQueryDto {
    return this._eventQuery;
  }
  @Input() public set eventQuery(v: EventChartQueryDto) {
    this._eventQuery = v;
    if (v) {
      this.loadEventChart();
    }
  }
  // Specify the lower and upper boundaries of the main X Axis.

  private _xAxisRange: DateRange;
  public get xAxisRange(): DateRange {
    return this._xAxisRange;
  }
  @Input() public set xAxisRange(v: DateRange) {
    this._xAxisRange = v;
    if (v) {
      this.setCustomSetting();
    }
  }

  private _heightElements: ElementRef<HTMLElement>[];
  get heightElements(): ElementRef<HTMLElement>[] {
    return this._heightElements;
  }
  @ViewChildren('heightElement') set heightElements(value: ElementRef<HTMLElement>[]) {
    this._heightElements = value;
    const totalSize = this.calculateTotalSize();
    totalSize.height = Math.max(totalSize.height, this._defaultHeightOnEmpty);
    this.sizeChanged.emit(totalSize);
  }

  private _loading = false;
  public get loading() {
    return this._loading;
  }
  public set loading(value) {
    this._loading = value;
    this.isLoading.emit(value);
  }

  chartSettings: ChartSettings;
  customChartSettings: GenericCartesianCustomChartSettings;
  events: IEventDto[];
  processedEvents: IProcessedEventDto[];
  processedSeries: any[][];
  chartDimensions = ['id', 'startDate', 'endDate', 'eventLabel', 'color', 'hasEnded', 'category'];
  dimensions = {
    id: 0,
    startDate: 1,
    endDate: 2,
    eventLabel: 3,
    color: 4,
    hasEnded: 5,
    category: 6,
  };
  enableShapeChange = false;
  containerHeight: number;
  // When a shape is less than this amount, convert it to a circle.
  shapeThreshold = 5; // px
  eventHeight = 25; // px
  eventSpace = 40; // px
  verticalToolingSpace = 30; // px estimation of the space required to show the x axis and the dataZoom bar.
  positionHandler$ = new ReplaySubject<EventChartPosition>();
  positionDebounceTime = 300; // ms
  translations = {
    startDate: null,
    endDate: null,
    leaksStartDate: null,
    leaksEndDate: null,
    category: null,
  };

  colorEventBurstMainLeak = '#ffa600';
  colorEventOtherLeak = '#e91e63';
  colorEventActivity = '#03a9f4';
  colorEventClosed = '#9ea5b1';
  colorEventMeterFault = '#7D33FF';

  legendItems: ChartCustomLegendItem[] = [];
  dataLoaded = false;

  // Default background with transparency used for tooltips
  private _defaultBackground = 'rgba(255,255,255,0.7)';
  T_SCOPE = `${AppModules.WlmCharts}.${COMPONENT_SELECTOR}`;
  private readonly _defaultHeightOnEmpty = 64; // px;

  constructor(
    private _eventService: EventService,
    private _dateHelperService: DateHelperService,
    private _localization: LocalizationHelperService,
    private _logService: LogService,
    private _settingsService: SettingsService
  ) {}

  ngOnInit(): void {}

  // Method that load the Event Chart

  private loadEventChart() {
    this.loading = true;
    this.calculateEventsWindow();

    forkJoin([
      this._eventService.getByCategories(this.eventQuery),
      this._localization.get(this.T_SCOPE),
    ]).subscribe(([events, translations]) => {
      this.events = events;

      this.translations = {
        startDate: translations['start-date'],
        endDate: translations['end-date'],
        leaksStartDate: translations['leaks-start-date'],
        leaksEndDate: translations['leaks-end-date'],
        category: translations['category'],
      };

      this.processedEvents = this.processEvents(this.events);
      this.processedSeries = this.buildSerie(this.processedEvents, this.events);
      this.legendItems = this.buildLegendItems(events);

      this.setCustomSetting();

      // Adjust X axis and filter events the first time.
      this.updateXAxisPosition(new EventChartPosition({ start: 0, end: 100 }));

      // Listen to position changes.
      this.positionHandler$
        .pipe(debounceTime(this.positionDebounceTime), untilDestroyed(this))
        .subscribe((position) => this.updateXAxisPosition(position));

      this.loading = false;
    });
  }

  /**
   * Depending on the space available to the chart, we will show a different amount of events,
   * to ensure that they are properly displayed.
   * Note: Window resize after load is currently not considered.
   */
  private calculateEventsWindow(): void {
    if (!this.autoCalculateEventWindow) {
      return;
    }
    const availableHeight = this.chartHeigth - this.verticalToolingSpace;
    if (availableHeight < 50 && !this._settingsService.log) {
      throw new Error('There is not enough space to render an events chart.');
    }
    this.eventWindow = Math.floor(availableHeight / this.eventSpace);
  }

  /**
   * Process the events to prepare them for the chart.
   */
  private processEvents(events: IEventDto[]): IProcessedEventDto[] {
    // Format dates.
    let formattedEvents = events.map((event, index) => {
      const formatted: IProcessedEventDto = {
        ...event,
        startDate: this._dateHelperService.fromApiFormat(event.startDate as any),
        endDate: event.endDate
          ? this._dateHelperService.fromApiFormat(event.endDate as any)
          : this._dateHelperService.ensureDateObject(this.eventQuery.endDate),
        // We need to keep track of wich processed event corresponds to each source event after sorting.
        sourceIndex: index,
      };
      return formatted;
    });
    // Sort by startDate.
    formattedEvents = formattedEvents.sort(
      (a, b) => +new Date(b.startDate) - +new Date(a.startDate)
    );
    return formattedEvents;
  }

  /**
   * Converts the events to the format accepted by the chart.
   */
  private buildSerie(formattedEvents: IProcessedEventDto[], sourceEvents: IEventDto[]): any[][] {
    const result: any[][] = [];
    formattedEvents.forEach((event, index) => {
      const processed: any[] = [
        index,
        event.startDate,
        event.endDate,
        this.formatEventLabel(event),
        this.getEventColor(sourceEvents[event.sourceIndex]),
        // Save if the event was actually finished or not (endDate defaults to now).
        Boolean(sourceEvents[event.sourceIndex].endDate),
        event.category,
      ];
      result.push(processed);
    });
    return result;
  }

  // Left for reference about aligning all bars to the top.
  private fillSeriesWindow(series: any[][]): any[][] {
    if (series.length < this.eventWindow) {
      const mocksToAdd = this.eventWindow - series.length;
      const dimensionAmount = Object.keys(this.dimensions).length;
      const mockSeries = [];
      for (let i = 1; i <= mocksToAdd; i++) {
        const mockSerie = [];
        // Create array with all dimensions set to null.
        for (let dim = 0; dim < dimensionAmount; dim++) {
          mockSerie.push(null);
        }
        mockSerie[0] = -i;
        mockSeries.push(mockSerie);
      }
      return series.concat(mockSeries);
    }
  }

  private getChartSettings = (): GenericCartesianCustomChartSettings => {
    const defaultYAxis = new GChartAxis({
      type: 'category',
      axisTick: new GChartAxisTick({ show: false }),
      splitLine: new GChartAxisSplitLine({ show: false }),
      axisLine: new GChartAxisLine({ show: true }),
      axisLabel: new GChartAxisLabel({ show: false }),
    });

    const settings = new GenericCartesianCustomChartSettings({
      type: 'custom',
      animation: false,
      grid: new GChartGrid({
        y2: '5',
      }),
      dataZoom: [
        this.enableXDataZoom
          ? new GChartDataZoom({
              id: 'sliderX',
              type: 'slider',
              xAxisIndex: 0,
              filterMode: 'weakFilter',
              // Make it disappear
              height: 0,
              moveHandleSize: {
                borderWidth: 0,
              },
              showDetail: false,
            })
          : null,
        new GChartDataZoom({
          id: 'sliderY',
          brushSelect: false,
          endValue: this.processedEvents.length - this.eventWindow,
          handleSize: 0,
          moveHandleSize: 20,
          showDetail: false,
          startValue: this.processedEvents.length - 1,
          top: this.verticalToolingSpace,
          type: 'slider',
          yAxisIndex: 0,
          zoomLock: true,
          filterMode: 'filter',
        }),
      ].filter(Boolean), // Remove the first dataZoom if the ternary returns null
      xAxes: [
        new GChartAxis({
          type: 'time',
          position: 'top',
          boundaryGap: false,
          min: this.xAxisRange.start,
          max: this.xAxisRange.end,
        }),
        new GChartAxis({
          type: 'time',
          position: 'bottom',
          boundaryGap: false,
          splitLine: new GChartAxisSplitLine({ show: false }),
          min: this.xAxisRange.start,
          max: this.xAxisRange.end,
          axisLabel: new GChartAxisLabel({
            show: false,
          }),
          axisTick: new GChartAxisTick({ show: false }),
        }),
      ],
      yAxes: [defaultYAxis, defaultYAxis],
      series: [
        new GCartesianCustomChartSerie({
          id: 'events',
          type: 'custom',
          renderItem: this.renderEventItem,
          dimensions: this.chartDimensions,
          labelLayout: { hideOverlap: true },
          encode: {
            x: [this.dimensions.startDate, this.dimensions.endDate],
            y: this.dimensions.id,
          },
          data: this.processedSeries,
        }),
      ],
      tooltip: new GChartTooltip({
        trigger: 'item',
        borderColor: '#fff',
        formatter: this.renderTooltip,
        backgroundColor: this._defaultBackground,
      }),
    });
    return settings;
  };

  private formatDate = (date: Date): string => {
    return this._localization.formatDate(date);
  };

  private setCustomSetting() {
    if (!this.processedEvents?.length) {
      return;
    }
    this.dataLoaded = true;
    this.customChartSettings = this.getChartSettings();
  }

  private renderEventItem = (params, api) => {
    const categoryIndex = api.value(this.dimensions.id);
    const dateStartNumber = api.value(this.dimensions.startDate);
    const dateEndNumber = api.value(this.dimensions.endDate);
    const color = api.value(this.dimensions.color);
    const startCoords = api.coord([dateStartNumber, categoryIndex]);
    const endCoords = api.coord([dateEndNumber, categoryIndex]);
    // We use new Date because dateStart and dateEnd are numeric.
    const dateStart = new Date(dateStartNumber);
    const dateEnd = new Date(dateEndNumber);
    let eventLength = endCoords[0] - startCoords[0];
    eventLength = Math.max(eventLength, this.shapeThreshold);
    // Get the heigth corresponds to length 1 on y axis.
    let x = startCoords[0];
    const y = endCoords[1];

    if (this.isLimitStartEvent(dateEnd) || this.isLimitEndEvent(dateStart)) {
      eventLength = this.shapeThreshold;
      if (this.isLimitEndEvent(dateStart)) {
        // If limit at the end of the chart, the event must expand backwards.
        x = x - eventLength;
      } else {
        // If at limit at the start, we must ensure we start at the beginning of the x axis (positive side).
        if (this._xAxisRange && this._xAxisRange.start) {
          const chartStartCoords = api.coord([this._xAxisRange.start.getTime(), categoryIndex]);
          x = chartStartCoords[0];
        }
      }
    }

    const eventLabel = api.value(this.dimensions.eventLabel) + '';
    const eventLabelWidth = echarts.format.getTextRect(eventLabel).width;
    const text = eventLength > eventLabelWidth + 40 && x + eventLength >= 80 ? eventLabel : '';

    let shapeType = 'rect';
    let shape = this.clipRectByRect(params, {
      x,
      y,
      width: eventLength,
      height: this.eventHeight,
    });

    const rectText = this.clipRectByRect(params, {
      x,
      y,
      width: eventLength,
      height: this.eventHeight,
    });

    if (this.enableShapeChange && shape && shape.width < this.shapeThreshold) {
      shape = {
        cx: shape.x,
        cy: shape.y,
        r: shape.height / 8,
      } as any;
      shapeType = 'circle';
    }

    return {
      type: 'group',
      children: [
        {
          type: shapeType,
          ignore: !shape,
          shape,
          style: {
            fill: color,
          },
        },
        {
          type: 'rect',
          ignore: !rectText,
          shape: rectText,
          style: {
            fill: 'transparent',
            stroke: 'transparent',
            text,
            textFill: '#fff',
          },
        },
      ],
    };
  };

  /**
   * A limit event ends at the axis startDate or starts at the axis endDate
   */
  private isLimitStartEvent(endDate: Date): boolean {
    return this._dateHelperService.equals(endDate, this._xAxisRange.start);
  }
  private isLimitEndEvent(startDate: Date): boolean {
    return this._dateHelperService.equals(startDate, this._xAxisRange.end);
  }

  private formatEventLabel = (event: IEventDto) => {
    const category = this.setEventCategoryLabel(event);

    if (event) {
      if (event.description) {
        return `${category} - ${event.description}`;
      }

      return category;
    }

    return null;
  };

  private getEventColor = (event: IEventDto) => {
    let color;
    if (this.isBurstMainLeak(event)) {
      color = this.colorEventBurstMainLeak;
    } else if (this.isOtherLeak(event)) {
      color = this.colorEventOtherLeak;
    } else if (this.isActivity(event)) {
      color = this.colorEventActivity;
    } else if (this.isAlarm(event)) {
      if (this.eventIsClosed(event)) {
        color = this.colorEventClosed;
      } else {
        color = this.alarmEventColor(event);
      }
    } else if (this.isMeterFault(event)) {
      color = this.colorEventMeterFault;
    }
    if (!color) {
      this._logService.error({ msg: 'The event of the event chart has no color' });
    }
    return color;
  };

  private renderTooltip = (params) => {
    const { data } = params;
    const dim = this.dimensions;
    let startDateLabel;
    let endDateLabel;
    if (data[dim.category] === EventViewCategories.BurstMainLeak) {
      startDateLabel = this.translations.leaksStartDate;
      endDateLabel = this.translations.leaksEndDate;
    } else {
      startDateLabel = this.translations.startDate;
      endDateLabel = this.translations.endDate;
    }
    const content = [
      `<b>${data[dim.eventLabel]}</b>`,
      this.renderTooltipField(
        startDateLabel,
        this.formatDate(data[dim.startDate]),
        data[dim.color]
      ),
      data[dim.hasEnded]
        ? this.renderTooltipField(endDateLabel, this.formatDate(data[dim.endDate]), data[dim.color])
        : null,
    ].filter(Boolean);
    const result = content.join('<br/>');
    return result;
  };

  private renderTooltipField = (label: string, value: any, color: string = '#4d5ee0'): string => {
    return `<div style="height: 10px;
                                    width: 10px;
                                    background-color: ${color};
                                    border-radius: 50%;
                                    margin-right: 3px;
                                    display: inline-block;">
        </div>
        <b>${label}</b>: ${value}`;
  };

  private updateXAxisPosition = (position: EventChartPosition): void => {
    const newSettings = { ...this.customChartSettings };
    const series = newSettings.series;
    const dataZooms = newSettings.dataZoom;

    if (!series || !series.length || !dataZooms || !dataZooms.length) {
      return;
    }

    if (this.xAxisRange) {
      const minMs = this.xAxisRange.start.getTime();
      const maxMs = this.xAxisRange.end.getTime();
      const totalTimeLapse = maxMs - minMs;
      const lapseStartMs = (position.start / 100) * totalTimeLapse;
      const lapseEndMs = (position.end / 100) * totalTimeLapse;
      const startDate = new Date(minMs + lapseStartMs);
      const endDate = new Date(minMs + lapseEndMs);

      const isOutsideRange = (serie) =>
        serie[this.dimensions.endDate] < startDate || serie[this.dimensions.startDate] > endDate;
      const currentSeries = this.processedSeries.filter((serie) => !isOutsideRange(serie));

      if (!currentSeries.length) {
        this.dataLoaded = false;
        return;
      }

      newSettings.series[0].data = currentSeries;
    }

    newSettings.dataZoom.forEach((dz) => {
      if (dz.id === 'sliderX') {
        dz.start = position.start;
        dz.end = position.end;
      }
    });
    this.dataLoaded = true;
    this.customChartSettings = newSettings;
  };

  private setEventCategoryLabel(event: IEventDto) {
    const category = event.category;

    return this.translations.category[category] ?? category;
  }

  private buildLegendItems(events: IEventDto[]): ChartCustomLegendItem[] {
    if (!events.length) {
      return [];
    }
    const firstBurstMainLeak = events.find(this.isBurstMainLeak);
    const firstOtherLeak = events.find(this.isOtherLeak);
    const firstActivity = events.find(this.isActivity);
    const firstCriticalAlarm = events.find(this.isNonAckCriticalAlarm);
    const firstHighAlarm = events.find(this.isNonAckHighAlarm);
    const firstMediumAlarm = events.find(this.isNonAckMediumAlarm);
    const firstLowAlarm = events.find(this.isNonAckLowAlarm);
    const firstAckAlarm = events.find(this.isAckAlarm);
    const firstMeterFault = events.find(this.isMeterFault);

    const legendScope = `${this.T_SCOPE}.legend`;
    const items = [
      firstBurstMainLeak
        ? new ChartCustomLegendItem({
            labelKey: `${legendScope}.burst-main-leaks`,
            color: this.colorEventBurstMainLeak,
            icon: 'burst-main-leaks',
            isSvgIcon: true,
          })
        : null,
      firstOtherLeak
        ? new ChartCustomLegendItem({
            labelKey: `${legendScope}.other-leaks`,
            color: this.colorEventOtherLeak,
            icon: 'pipe-leak',
            isSvgIcon: true,
          })
        : null,
      firstActivity
        ? new ChartCustomLegendItem({
            labelKey: `${legendScope}.activities`,
            color: this.colorEventActivity,
            icon: 'baseline-handyman',
            isSvgIcon: true,
          })
        : null,
      firstCriticalAlarm
        ? new ChartCustomLegendItem({
            labelKey: `${legendScope}.non-ack-critical-alarms`,
            color: this.alarmEventColor(firstCriticalAlarm),
            icon: 'warning',
            isSvgIcon: true,
          })
        : null,
      firstHighAlarm
        ? new ChartCustomLegendItem({
            labelKey: `${legendScope}.non-ack-high-alarms`,
            color: this.alarmEventColor(firstHighAlarm),
            icon: 'warning',
            isSvgIcon: true,
          })
        : null,
      firstMediumAlarm
        ? new ChartCustomLegendItem({
            labelKey: `${legendScope}.non-ack-medium-alarms`,
            color: this.alarmEventColor(firstMediumAlarm),
            icon: 'warning',
            isSvgIcon: true,
          })
        : null,
      firstLowAlarm
        ? new ChartCustomLegendItem({
            labelKey: `${legendScope}.non-ack-low-alarms`,
            color: this.alarmEventColor(firstLowAlarm),
            icon: 'warning',
            isSvgIcon: true,
          })
        : null,
      firstAckAlarm
        ? new ChartCustomLegendItem({
            labelKey: `${legendScope}.ack-alarms`,
            color: this.colorEventClosed,
            icon: 'warning',
            isSvgIcon: true,
          })
        : null,
      firstMeterFault
        ? new ChartCustomLegendItem({
            labelKey: `${legendScope}.meter-fault`,
            color: this.colorEventMeterFault,
            icon: 'build',
            isSvgIcon: false,
          })
        : null,
    ].filter(Boolean);
    return items;
  }

  private eventIsClosed = (event: IEventDto): boolean => {
    if (this.isAlarm(event)) {
      return this.isAckAlarm(event);
    } else {
      return Boolean(event.endDate);
    }
  };

  onChartClick = (event: GChartClickEvent) => this.chartClick.emit(event);
  onChartDataZoom = (event: GChartDataZoomEvent) => this.chartDataZoom.emit(event);

  private clipRectByRect = (params, rect) => {
    return echarts.graphic.clipRectByRect(rect, {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height,
    });
  };

  private alarmEventColor = (event: IEventDto): string => {
    return event.aditionalInformation4;
  };

  private isActivity = (event: IEventDto): boolean =>
    event.category === EventViewCategories.Activity;

  private isBurstMainLeak = (event: IEventDto): boolean =>
    event.category === EventViewCategories.BurstMainLeak;

  private isOtherLeak = (event: IEventDto): boolean =>
    event.category === EventViewCategories.OtherLeak;

  private isAlarm = (event: IEventDto): boolean => event.category === EventViewCategories.Alarm;

  private isMeterFault = (event: IEventDto): boolean =>
    event.category === EventViewCategories.MeterFault;

  private isNonAckCriticalAlarm = (event: IEventDto): boolean =>
    !this.isAckAlarm(event) && event.aditionalInformation2 === EventViewAlarmCategories.Critical;

  private isNonAckHighAlarm = (event: IEventDto): boolean =>
    !this.isAckAlarm(event) && event.aditionalInformation2 === EventViewAlarmCategories.High;

  private isNonAckMediumAlarm = (event: IEventDto): boolean =>
    !this.isAckAlarm(event) && event.aditionalInformation2 === EventViewAlarmCategories.Medium;

  private isNonAckLowAlarm = (event: IEventDto): boolean =>
    !this.isAckAlarm(event) && event.aditionalInformation2 === EventViewAlarmCategories.Low;

  /**
   * If additionalInformation5 is true, then the alarm is NOT ACK.
   */
  private isAckAlarm = (event: IEventDto): boolean => {
    if (!this.isAlarm(event)) {
      return false;
    }
    const value = event.aditionalInformation5;
    const isNotAck = value ? Boolean(Number(value)) : false;
    return !isNotAck;
  };

  private calculateTotalSize(): IElementSize {
    const sizes = this.heightElements.map((element) =>
      globalUtilsHelper.getElementSize(element.nativeElement)
    );

    const size = sizes.reduce(
      (accum: IElementSize, current: IElementSize) => {
        return {
          height: accum.height + current.height,
          width: current.width,
        } as IElementSize;
      },
      { width: 0, height: 0 } as IElementSize
    );

    return size;
  }
}
