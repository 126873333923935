import { HierarchyFamilyFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/hierarchy-family-fi-settings';
import { HierarchyTreeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/hierarchy-tree-fi-settings';
import { NeHeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';

export class HierarchyElementsSelectionFilterConfiguration {
  persistencyArea: string;
  familySettings: HierarchyFamilyFISettings;
  heTypeSettings: NeHeFISettings;
  treeSettings: HierarchyTreeFISettings;
  hideFilterButtons?: boolean;
  heDisplayableLevels?: string[];
  cssClass?: string;

  public constructor(init?: Partial<HierarchyElementsSelectionFilterConfiguration>) {
    Object.assign(this, init);
  }
}
