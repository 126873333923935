import { LargeUserSignalDto } from '@common-modules/dependencies/shared/model/large-user-signal.dto';

export class LargeUserAssignedDto {
  hierarchyElementId: string;
  hierarchyElementName: string;
  customerId: string;
  propertyId: string;
  organizationName: string;
  largeUserId: number;
  signals: LargeUserSignalDto[];

  constructor(init: Readonly<LargeUserAssignedDto>) {
    Object.assign(this, init);
  }
}
