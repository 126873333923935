import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { WorkOrderService } from '@common-modules/dependencies/alc/services/work-order.service';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { finalize } from 'rxjs/operators';
// prettier-ignore

const COMPONENT_SELECTOR = 'wlm-notifications';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './activity-notification-detail.component.html',
  styleUrls: ['./activity-notification-detail.component.scss'],
})
export class ActivityNotificationDetailComponent extends BaseWidgetComponent implements OnInit {
  @Input() notificationLongText: string;

  private _externalId: string;
  public get externalId(): string {
    return this._externalId;
  }
  @Input() public set externalId(v: string) {
    this._externalId = v;
    this.loadNotificationLongText(v);
  }

  get componentName() {
    return 'ActivityNotificationDetailComponent';
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _workOrderService: WorkOrderService
  ) {
    super(injector, widgetSettings);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.externalId =
      parameters.parameters.get(TabDetailParameterName.workOrderId) ??
      parameters.parameters.get(TabDetailParameterName.orderNumber);
  }

  init(): void {
    this.loadNotificationLongText(this.externalId);
  }

  loadNotificationLongText(externalId: string) {
    if (!externalId) {
      return;
    }

    this.loading = true;
    this._workOrderService
      .getByExternalId(externalId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (value) => {
          this.notificationLongText = value?.sapnotificationLongText;
        },
      });
  }
}
