import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TreelistComponent } from '../shared/core/treelist/treelist.component';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { HideNavbarsService } from './hide-navbars/hide-navbars.service';
import { HomeConfigurationPopupComponent } from './home-configuration-popup/home-configuration-popup.component';
import { MenuLinkComponent } from './menu-link/menu-link.component';
import { ModuleLinkExpandableComponent } from './module-link-expandable/module-link-expandable.component';
import { ModuleLinkMenuComponent } from './module-link-menu/module-link-menu.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NotificationConfigurationPopupComponent } from './notification-configuration-page/notification-configuration-popup.component';
import { RightPanelComponent } from './right-panel/right-panel.component';
import { TopBarAccountCardComponent } from './top-bar-account-card/top-bar-account-card.component';
import { TopBarNotificationCreateButtonComponent } from './top-bar-notification-create-button/top-bar-notification-create-button.component';
import { TopBarNotificationUnreadButtonComponent } from './top-bar-notification-unread-button/top-bar-notification-unread-button.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { VersionInfoItemComponent } from './version-info-item/version-info-item.component';
import { VersionInfoPopupComponent } from './version-info-popup/version-info-popup.component';

@NgModule({
  declarations: [
    ModuleLinkExpandableComponent,
    NavBarComponent,
    ModuleLinkMenuComponent,
    TopBarComponent,
    TopBarAccountCardComponent,
    RightPanelComponent,
    VersionInfoPopupComponent,
    TopBarNotificationCreateButtonComponent,
    TopBarNotificationUnreadButtonComponent,
    MenuLinkComponent,
    HomeConfigurationPopupComponent,
    NotificationConfigurationPopupComponent,
    VersionInfoItemComponent,
  ],
  imports: [CommonModule, WlmSharedModule, HttpClientModule, TranslateModule, TreelistComponent],
  exports: [NavBarComponent, TopBarComponent, RightPanelComponent],
  providers: [HideNavbarsService],
})
export class WlmNavigationModule {}
