import { Injectable, Injector } from '@angular/core';
import { PipeChartDto } from '@common-modules/dependencies/shared/model/pipes/pipe-chart.dto';
import { ISummaryDistancePerMaterialDto } from '@common-modules/dependencies/shared/model/pipes/summaryDistancePerMaterial.dto';
import { BaseService } from '@common-modules/shared/base.service';
import { asEnumerable } from 'linq-es2015';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PipeChartService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public getSummaryBy(
    zoneId: string,
    columnName: string,
    columnToOrder: string
  ): Observable<PipeChartDto> {
    const result = this.getCached<ISummaryDistancePerMaterialDto[]>(`pipes/summary/${zoneId}`).pipe(
      mergeMap((pipeRows) =>
        asEnumerable(pipeRows)
          .Where((pipe) => pipe[columnName] !== undefined && pipe[columnToOrder])
          .GroupBy((pipe) => pipe[columnName])
          .Select(
            (pipe) =>
              new PipeChartDto(
                zoneId,
                pipe.key,
                asEnumerable(pipe).Sum((p) => p.distance),
                asEnumerable(pipe).FirstOrDefault()[columnToOrder]
              )
          )
          .OrderBy((pipe) => (Number(pipe.order) ? +pipe.order : pipe.order))
          .ThenBy((pipe) => pipe.order)
          .ToArray()
      )
    );
    return result;
  }
}
class InternalGroupDto {
  elementid: string;
  category: string;
  constructor(elementid: string, category: string) {
    this.elementid = elementid;
    this.category = category;
  }
}
