import { Injectable, Injector } from '@angular/core';
import { PipeChartDto } from '@common-modules/dependencies/shared/model/pipes/pipe-chart.dto';
import { ISummaryDistancePerMaterialDto } from '@common-modules/dependencies/shared/model/pipes/summaryDistancePerMaterial.dto';
import { asEnumerable } from 'linq-es2015';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GChartSerieDataSection } from '../../wlm-charts/core/models/generic-chart-settings/g-chart-serie-data-section';
import { GPieChartSerie } from '../../wlm-charts/core/models/generic-chart-settings/g-pie-chart-serie';
import { GenericPieChartSettings } from '../../wlm-charts/core/models/generic-chart-settings/generic-pie-chart-settings';
import { PieChartDataParameters } from '../../wlm-charts/core/models/pie-chart-data-parameters';
import { BasePieChartService } from '../../wlm-charts/core/services/base-pie-chart.service';

import { DimensionTypesEnum } from '../model/shared/dimension-types';

@Injectable()
export class PipePieChartService extends BasePieChartService {
  constructor(injector: Injector) {
    super(injector);
  }

  protected get url() {
    return `${this.apiUrl}/pipes/summary`;
  }

  public getData(params: PieChartDataParameters): Observable<any[]> {
    const { zoneId, columnName, columnToOrder } = params.queryParams;
    const result = this.httpCacheClient
      .get<ISummaryDistancePerMaterialDto[]>(`${this.url}/${zoneId}`, {
        avoid: true,
      })
      .pipe(
        map((pipeRows) =>
          asEnumerable(pipeRows)
            .Where((pipe) => pipe[columnName] !== undefined && pipe[columnToOrder])
            .GroupBy((pipe) => pipe[columnName])
            .Select(
              (pipe) =>
                new PipeChartDto(
                  zoneId,
                  pipe.key,
                  asEnumerable(pipe).Sum((p) => p.distance),
                  asEnumerable(pipe).FirstOrDefault()[columnToOrder]
                )
            )
            .OrderBy((pipe) => (Number(pipe.order) ? +pipe.order : pipe.order))
            .ThenBy((pipe) => pipe.order)
            .ToArray()
        )
      );
    return result;
  }

  public mapDataToGenericSettings(data: PipeChartDto[]): Observable<GenericPieChartSettings> {
    const dataSegments = [];
    data.forEach((item) => {
      dataSegments.push(new GChartSerieDataSection({ value: item.value, name: item.category }));
    });

    // TODO: review values
    const pieSerie: GenericPieChartSettings = {
      series: [
        new GPieChartSerie({
          dataPoints: dataSegments,
          type: 'pie',
          name: '',
          radius: '55%',
          center: ['50%', '50%'],
          yAxisWLMDimensionTypeId: DimensionTypesEnum.Length,
          yAxisWLMUnitTypeIdFrom: 28,
          yAxisWLMUnitTypeIdTo: 29,
          decimalPositions: 2,
        }),
      ],
      type: 'pie',
    };
    return of(pieSerie);
  }
}
