import { Component, ElementRef, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { ApplyFiltersAction } from '@common-modules/dynamic-layout/state/filters/filters.actions';
import { SetValueAction } from '@common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from '@common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { IElementSize } from '@common-modules/shared/model/element-size';
import { SizeCalculatorService } from '@common-modules/shared/services/size-calculator.service';
import { EditableChartDateRange } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-date-range';
import { EditableChartRangeUpdate } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-range-update';
import { EditableChartSelection } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-selection';
import { EditableChartValueRange } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-value-range';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { DataValidationStateFields } from '../../state/data-validation-state-fields';

const COMPONENT_SELECTOR = 'wlm-data-validation-chart-widget';
export const DATA_VALIDATION_CHART_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-validation-chart-widget.component.html',
  styleUrls: ['./data-validation-chart-widget.component.scss'],
})
export class DataValidationChartWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  T_SCOPE = `${AppModules.DataValidation}.${COMPONENT_SELECTOR}`;

  widgetInstanceKey: string;
  pageId: string;

  selectedSignalId: number;

  phTitleKey = `${this.T_SCOPE}.must-select-element-title`;
  phSubtitleKey = `${this.T_SCOPE}.must-select-element-subtitle`;

  get componentName(): string {
    return 'DataValidationChartWidgetComponent';
  }

  valueRanges: EditableChartValueRange[];
  dateRanges: EditableChartDateRange[];
  validationToApply: EditableChartSelection;
  chartSize$: Observable<IElementSize>;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private readonly _sizeCalculatorService: SizeCalculatorService,
    private readonly _element: ElementRef
  ) {
    super(injector, widgetSettings);

    this.widgetInstanceKey = widgetSettings.widgetInstanceKey;
    this.pageId = widgetSettings.page;
  }

  onWidgetInit(): void {
    this.chartSize$ = this._sizeCalculatorService.listenElementSize$(this._element.nativeElement);

    this.listenState(DataValidationStateFields.selectedPoint).subscribe((point: any) => {
      if (point?.signalId) {
        this.selectedSignalId = point.signalId;
        return;
      }

      this.selectedSignalId = null;
    });

    this.listenState(DataValidationStateFields.selectedValueRange).subscribe(
      (valueRanges: EditableChartRangeUpdate) => {
        //update changes only from external components
        if (valueRanges?.sender !== this.componentName) {
          this.valueRanges = valueRanges?.range as EditableChartValueRange[];
        }
      }
    );

    this.listenState(DataValidationStateFields.selectedDateRange).subscribe(
      (dateRanges: EditableChartRangeUpdate) => {
        //update changes only from external components
        if (dateRanges?.sender !== this.componentName) {
          this.dateRanges = dateRanges?.range as EditableChartDateRange[];
        }
      }
    );

    this.listenState(DataValidationStateFields.selectedValidationToApply).subscribe(
      (validationToApply: any) => {
        //listen validation function to apply from cart

        this.validationToApply = validationToApply;
      }
    );
  }

  onChartFiltersChange(filters: DataBindingFilters) {
    this._state.dispatch(new ApplyFiltersAction(filters));
  }

  onValueRangesChanged(ranges) {
    const rangeInfo = new EditableChartRangeUpdate({
      range: ranges,
      sender: this.componentName,
    });

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataValidationStateFields.selectedValueRange,
        value: rangeInfo,
      })
    );
  }

  onDateRangesChanged(ranges) {
    const rangeInfo = new EditableChartRangeUpdate({
      range: ranges,
      sender: this.componentName,
    });

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataValidationStateFields.selectedDateRange,
        value: rangeInfo,
      })
    );
  }

  onToggleValueRangesCart(toggle) {
    this._state.dispatch(
      new SetValueAction({
        fieldName: DataValidationStateFields.toggleValueRangeCart,
        value: toggle,
      })
    );
  }

  onToggleDateRangesCart(toggle) {
    this._state.dispatch(
      new SetValueAction({
        fieldName: DataValidationStateFields.toggleDateRangeCart,
        value: toggle,
      })
    );
  }

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }
}
