<wlm-ne-configuration-historical-switcher
  [selectedNE]="selectedNE"
  class="switcher"
  [widgetId]="widgetId"
  [pageId]="pageId"
>
  <ng-template #currentTemplate>
    <wlm-smart-meters-current-config
      #controlledComponent
      class="item-container"
      [selectedNE]="selectedNE"
      [widgetId]="widgetId"
      [pageId]="pageId"
    ></wlm-smart-meters-current-config>
  </ng-template>
  <ng-template #historicalTemplate let-isReadOnly="isReadOnly">
    <wlm-smart-meters-historical-config
      #controlledComponent
      class="item-container"
      [selectedNE]="selectedNE"
      [widgetId]="widgetId"
      [pageId]="pageId"
      [isReadOnly]="isReadOnly"
    ></wlm-smart-meters-historical-config>
  </ng-template>
</wlm-ne-configuration-historical-switcher>
