// Map API license modules names to module keys, so they can be translated.

import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';

export class WaterLossLicenseModule {
  apiName: string;
  navKey?: NavKeys; // Used to infer translation.
}

// Maps the names of the module licences in the back with the nav keys, which hold translation names.
export const waterLossLicenseModules: WaterLossLicenseModule[] = [
  {
    apiName: 'ALC',
    navKey: NavKeys.AlcModule,
  },
  {
    apiName: 'RepairManagement',
  },
  {
    apiName: 'Pressure',
    navKey: NavKeys.PressureManagementModule,
  },
  {
    apiName: 'LeakageCalculation',
    navKey: NavKeys.LeakageCalculationModule,
  },
  {
    apiName: 'SmartAlarms',
    navKey: NavKeys.AlarmsConfiguration,
  },
];
