import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedConstantsService } from '@common-modules/shared/constants/shared-constants.service';
import { UtilsHelperService } from '@common-modules/shared/helpers/utils-helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BaseFilterCellComponent,
  FilterService,
  PopupCloseEvent,
  SinglePopupService,
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

const closest = (node: any, predicate: any): any => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};

const COMPONENT_SELECTOR = 'wlm-uom-column-filter';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './uom-column-filter.component.html',
  styleUrls: ['./uom-column-filter.component.scss'],
})
export class UomColumnFilterComponent extends BaseFilterCellComponent implements OnInit {
  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Input() public conversionFactor: number;
  @Output() public valueChange = new EventEmitter<number[]>();

  public filterOperators: Array<{ text: string; value: string }>;

  public selectedOperator: string;

  private _selectedValue: number;
  public get selectedValue(): number {
    return this._selectedValue;
  }
  public set selectedValue(value: number) {
    this._selectedValue = value;
    this.onValueChange(value);
  }

  public get numTextBoxDisabled(): boolean {
    return this.selectedOperator === 'isnull' || this.selectedOperator === 'isnotnull';
  }

  public popupSettings: any = {
    popupClass: 'uom-column-filter',
  };

  constructor(
    private element: ElementRef,
    popupService: SinglePopupService,
    filterService: FilterService,
    sharedConstantsService: SharedConstantsService,
    private _utilsHelper: UtilsHelperService
  ) {
    super(filterService);

    // Get translated filter operators
    sharedConstantsService
      .mapToArrayObservable(sharedConstantsService.getFilterOperatorsMapping(), 'value', 'text')
      .subscribe((array) => {
        this.filterOperators = array;
      });

    // Handle the service onClose event and prevent the menu from closing when the datepickers are still active.
    popupService.onClose.pipe(untilDestroyed(this)).subscribe((e: PopupCloseEvent) => {
      if (
        document.activeElement &&
        closest(
          document.activeElement,
          (node) =>
            node === this.element.nativeElement ||
            String(node.className).indexOf('uom-column-filter') >= 0
        )
      ) {
        e.preventDefault();
      }
    });
  }

  public onValueChange(value: any): void {
    const isUnaryOperator =
      this.selectedOperator === 'isnull' || this.selectedOperator === 'isnotnull';

    let convertBackValue = undefined;

    if (isUnaryOperator) {
      convertBackValue = null;
    } else {
      if (value !== null) {
        convertBackValue = +this._utilsHelper.uomDivide(
          String(value),
          String(this.conversionFactor),
          null,
          12
        );
      }
    }

    this.applyFilter(
      convertBackValue === null && !this.numTextBoxDisabled // value of the default item
        ? this.removeFilter(this.field) // remove the filter
        : this.updateFilter({
            // add a filter for the field with the value
            field: this.field,
            operator: this.selectedOperator,
            value: convertBackValue,
          })
    ); // update the root filter
  }

  ngOnInit(): void {
    const filterApplied = this.currentFilter?.filters[0] as FilterDescriptor;
    this.selectedOperator =
      filterApplied !== undefined ? (filterApplied.operator as string) : 'gte';

    const isUnaryOperator =
      this.selectedOperator === 'isnull' || this.selectedOperator === 'isnotnull';

    this.selectedValue =
      filterApplied !== undefined && !isUnaryOperator
        ? filterApplied.value * this.conversionFactor
        : null;
  }

  public operatorChange(operator) {
    if (operator?.value === 'isnull' || operator?.value === 'isnotnull') {
      this.selectedValue = null;
    } else {
      this.onValueChange(this.selectedValue);
    }
  }

  preventEvent(event: PointerEvent): void {
    event.stopPropagation();
  }
}
