import { Component, OnInit } from '@angular/core';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { SettingsService } from '@common-modules/shared/config/settings.service';
import { RightPanelService } from '@common-modules/shared/navigation/right-panel.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { HideNavbarsService } from '../hide-navbars/hide-navbars.service';

const COMPONENT_SELECTOR = 'wlm-top-bar';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  disabledRightPanelButtons: boolean;

  public userCard: boolean;

  companyLogo: string;

  T_SCOPE = `${AppModules.WlmNavigation}.${COMPONENT_SELECTOR}`;

  public get rightPanelVisibility(): boolean {
    return this._rightPanelService.visibility;
  }

  public get rightPanelHover(): boolean {
    return this._rightPanelService.hover;
  }

  readonly hideTopBar$: Observable<boolean>;

  constructor(
    private _rightPanelService: RightPanelService,
    private _settingsService: SettingsService,
    private readonly _hideNavbarsService: HideNavbarsService
  ) {
    this.hideTopBar$ = this._hideNavbarsService.hideTopBar$;
  }

  toggleVisibility = () => this._rightPanelService.toggleVisibility();
  toggleHover = () => this._rightPanelService.toggleHover();

  ngOnInit(): void {
    this.userCard = false;
    this.companyLogo = this._settingsService.companyLogo;

    this._rightPanelService
      .hasRightPanelObservable()
      .pipe(untilDestroyed(this))
      .subscribe((hasRightPanel) => (this.disabledRightPanelButtons = !hasRightPanel));
  }
}
