import { Injectable, Injector } from '@angular/core';
import { AdditionalHttpOpts } from '@common-modules/cache/http-cache/additional-http-options';
import { HttpCacheClient } from '@common-modules/cache/http-cache/http-cache.client';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { DateFormats } from '@common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { HistoricalChartDataParameters } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-chart-data-parameters';
import { HistoricalChartResultSettings } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-chart-result-settings';
import { HistoricalEvent } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-event';
import {
  BaseHistoricalChartService,
  Translations,
} from '@common-modules/wlm-charts/core/services/base-historical-chart.service';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BoundaryHistoricalGroups } from '../../shared/model/signals/boundary-historical-groups';
import { BoundarySignalVersionOperationDto } from '../../shared/model/signals/boundary-signal-version-operation.dto';
import { BoundarySignalVersionSaveDto } from '../../shared/model/signals/boundary-signal-version-save.dto';
import { BoundarySignalVersionDto } from '../../shared/model/signals/boundary-signal-version.dto';
import { BoundarySignalDto } from '../../shared/model/signals/boundary-signal.dto';
import { SaveBoundarySignalVersionResponseDto } from '../../shared/model/signals/save-boundary-signal-version-response.dto';

@Injectable()
export class BoundaryHistoricalService extends BaseHistoricalChartService {
  protected get url() {
    return `${this.apiUrl}/boundary/historical`;
  }

  private readonly _seriesInUtc = false;

  constructor(
    injector: Injector,
    private _localizationService: LocalizationHelperService,
    private _http: HttpCacheClient,
    private _objectHelper: ObjectHelperService
  ) {
    super(injector);
  }

  saveHistoricalConfiguration(
    operations: BoundarySignalVersionOperationDto[],
    currentConfigurations: BoundarySignalVersionDto[],
    originalConfigurations: BoundarySignalVersionDto[],
    element: INetworkElementDto
  ): Observable<SaveBoundarySignalVersionResponseDto> {
    const signals = operations
      .filter((x) => x.originalWasCurrent)
      .map((x) => this.getCurrentFromHistorical(x.originalConfiguration));
    const trackedDates = this.getDaysToRecalculate(currentConfigurations, originalConfigurations);
    const trackedDatesNoUtc = trackedDates.map((date) =>
      this.dateHelperService.toApiFormatNoUTC(date)
    );

    operations.forEach((config) => {
      config.configuration.hierarchyElementId = element.hierarchyElementId;
      config.configuration.hierarchyElementTypeId = element.hierarchyElementTypeId;
      config.originalConfiguration.hierarchyElementId = element.hierarchyElementId;
    });

    const saveCmd = new BoundarySignalVersionSaveDto({
      boundarySignalVersionOperations: operations,
      minDate: this.dateHelperService.toApiFormatNoUTC(this.minDate),
      boundarySignals: signals,
      hierarchyElementId: element.hierarchyElementId,
      hierarchyElementName: element.hierarchyElementName ?? element.networkElementName,
      trackedSignals: this.arrayHelperService.onlyUnique(
        operations.map((x) => x.configuration.signalId)
      ),
      trackedDates: trackedDatesNoUtc,
    });

    const operationsPath = 'boundarySignalVersionOperations[]';
    const requestDatesNoUtc = [
      'minDate',
      `${operationsPath}.configuration.validFrom`,
      `${operationsPath}.configuration.validTo`,
      `${operationsPath}.originalConfiguration.validFrom`,
      `${operationsPath}.originalConfiguration.validTo`,
      'trackedDates[]',
    ];
    const additionalHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });

    return this.httpCacheClient.post<SaveBoundarySignalVersionResponseDto>(
      `${this.url}/save`,
      saveCmd,
      this.avoidCache,
      additionalHttpOptions
    );
  }

  cloneConfiguration(configuration: BoundarySignalVersionDto): BoundarySignalVersionDto {
    const cloned = new BoundarySignalVersionDto({ ...configuration });
    return cloned;
  }

  getData(params: HistoricalChartDataParameters): Observable<BoundarySignalVersionDto[]> {
    this.setDatesThreshold(params);

    const queryParams = {
      hierarchyElementId: params.queryParams['hierarchyElementId'],
      minDate: this.dateHelperService.toApiFormatNoUTC(params.startDate),
    };

    const requestDatesNoUtc = ['startDate', 'endDate'];

    const additionalHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: false,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });

    return this.httpCacheClient
      .get<BoundarySignalVersionDto[]>(
        this.url,
        this.avoidCache,
        queryParams,
        additionalHttpOptions
      )
      .pipe(
        map((x) =>
          x.map((y) => {
            return new BoundarySignalVersionDto({
              ...y,
              validFrom: this.preprocessEventDate(y.validFrom.toString()),
              validTo: this.preprocessEventDate(y.validTo.toString()),
            });
          })
        )
      );
  }

  private preprocessEventDate(dateStr: string): Date {
    const isUTC = false;
    const date = this.dateHelperService.fromApiFormat(dateStr, isUTC);
    return date;
  }

  mapDataToGenericSettings(
    data: BoundarySignalVersionDto[]
  ): Observable<HistoricalChartResultSettings> {
    const events$ = data.map((versionDto) => {
      const event = this.getEvent(versionDto);
      return event;
    });

    if (events$.length === 0) {
      const settings = new HistoricalChartResultSettings({
        events: [],
        showInUTC: this._seriesInUtc,
      });
      return of(settings);
    }

    return forkJoin(events$).pipe(
      map((events) => {
        const sortedEvents = this._objectHelper.sortObjectArray(events, 'group', 'ascending');
        const settings = new HistoricalChartResultSettings({
          events: sortedEvents,
          showInUTC: this._seriesInUtc,
        });
        return settings;
      })
    );
  }

  getEvent(versionDto: BoundarySignalVersionDto): Observable<HistoricalEvent> {
    return this._localizationService.get(this.getTranslationKey()).pipe(
      map((ts) => {
        const additionalParams = {
          ...versionDto,
        };

        const endDate = this.dateHelperService.fromApiFormat(versionDto.validTo.toString());
        const startDate = this.dateHelperService.fromApiFormat(versionDto.validFrom.toString());
        const visibleEndDate = endDate > this.maxDate ? this.maxDate : new Date(endDate);
        const visibleStartDate = startDate < this.minDate ? this.minDate : new Date(startDate);

        const id = versionDto.id;
        const group = this.getGroup(versionDto);
        const label = this.getLabel(versionDto, ts);
        const tooltip = this.getTooltip(versionDto, ts);

        const event = new HistoricalEvent({
          additionalParams,
          endDate,
          startDate,
          visibleEndDate,
          visibleStartDate,
          id,
          group,
          label,
          originalEndDate: new Date(endDate),
          originalStartDate: new Date(startDate),
          tooltip,
        });

        return event;
      })
    );
  }

  saveConfiguration(data: BoundarySignalVersionSaveDto): Observable<void> {
    return this._http.post(`${this.url}/save`, data);
  }

  protected getGroup(configuration: BoundarySignalVersionDto): string {
    return configuration.isIn ? BoundaryHistoricalGroups.IsIn : BoundaryHistoricalGroups.IsOut;
  }

  protected getLabel(config: BoundarySignalVersionDto, ts: Translations): string {
    const inOrOutValue = config.isIn ? ts.isIn : ts.isOut;

    return `${config.pointDescription} ${inOrOutValue}`;
  }

  protected getTooltip(config: BoundarySignalVersionDto, ts: Translations): string {
    const inOrOutValue = config.isIn ? ts.isIn : ts.isOut;

    let startDate;
    if (this.dateHelperService.isCurrentStartDate(config.validFrom)) {
      startDate = (ts.current as any).start;
    } else {
      startDate = this._localizationService.formatDate(config.validFrom, DateFormats.Date);
    }

    let endDate;
    if (this.dateHelperService.isCurrentEndDate(config.validTo)) {
      endDate = (ts.current as any).end;
    } else {
      endDate = this._localizationService.formatDate(config.validTo, DateFormats.Date);
    }

    let tooltip = [
      this.getTooltipRow(ts.pointId, config.pointId),
      this.getTooltipRow(ts.pointDescription, config.pointDescription),
      this.getTooltipRow(ts.configurationType, inOrOutValue),
      this.getTooltipRow(ts.startDate, startDate),
      this.getTooltipRow(ts.endDate, endDate),
    ];

    return tooltip.join('<br>');
  }

  private getTooltipRow = (header: string, value: string): string =>
    `<strong>${header}:</strong> ${value}`;

  protected getTranslationKey(): string {
    return `${this.T_SCOPE}.boundary`;
  }

  getCurrentFromHistorical(config: BoundarySignalVersionDto): BoundarySignalDto {
    if (!config) {
      return null;
    }
    const current: BoundarySignalDto = {
      signalId: config.signalId,
      isIn: config.isIn,
      pointDescription: config.pointDescription,
      pointId: config.pointId,
      hierarchyElementId: config.hierarchyElementId,
      hierarchyElementTypeId: config.hierarchyElementTypeId,
      title: '',
    };
    return current;
  }

  configurationsAreEqual<BoundarySignalDto>(
    config1: BoundarySignalDto,
    config2: BoundarySignalDto
  ) {
    return this._objectHelper.deepEqual(config1, config2);
  }

  recalculateDatesGroupBy(configuration: BoundarySignalVersionDto): string {
    if (configuration.pointId === undefined) {
      throw new Error('Could not get pointId. Maybe the configuration is not a Boundary one?');
    }
    const { pointId, isIn } = configuration;
    return [pointId, isIn].join('#');
  }
}
