import { IHierarchyElementDto } from '@common-modules/dependencies/he/hierarchy-element.dto';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';

export class SaAlgorithmElementDto {
  algorithmShortName: string;
  hierarchyElement?: IHierarchyElementDto;
  networkElement?: INetworkElementDto;
  algorithmId: string;
  dimenstionTypeId: number;
  timeAggregationId: number;

  public set targetName(value: string) {}
  public get targetName(): string {
    return this.hierarchyElement
      ? this.hierarchyElement.hierarchyElementId
      : this.networkElement.networkElementName;
  }

  constructor(init?: SaAlgorithmElementDto) {
    Object.assign(this, init);
  }
}
