import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ButtonColumnService } from '@common-modules/shared/core/grid/button-column.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { WlmColumnComponent } from '../../wlm-column/wlm-column.component';

const COMPONENT_SELECTOR = 'wlm-button-column-helper';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './button-column-helper.component.html',
  styleUrls: ['./button-column-helper.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => ButtonColumnHelperComponent),
    },
  ],
})
export class ButtonColumnHelperComponent extends WlmColumnComponent implements OnInit {
  @Input() title: string;
  @Input() iconName: string;

  private _dataItem: any;
  public get dataItem(): any {
    return this._dataItem;
  }
  @Input()
  public set dataItem(value: any) {
    this._dataItem = value;

    if (value) {
      this.setVisibility();
    }
  }
  @Input() visibilityMethod: string;
  @Input() clickMethod: string;

  visibility: boolean;

  constructor(private _buttonColumnService: ButtonColumnService) {
    super();
  }

  ngOnInit(): void {}

  onClick() {
    if (!this.clickMethod) {
      return;
    }

    this._buttonColumnService.applyFn(this.clickMethod, this.dataItem);
  }

  private setVisibility() {
    if (!this.visibilityMethod) {
      return;
    }

    this._buttonColumnService
      .applyFn(this.visibilityMethod, this.dataItem)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (isVisible) => {
          this.visibility = isVisible;
        },
      });
  }
}
