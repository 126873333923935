import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FilterItemSelectOption } from '@common-modules/common-filters/models/filter-item-select-option';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivityStatusFilter } from '../../../../dependencies/wlm-filters/i-filters/activity-status-filter';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../../core/base-select-filter-item/base-select-filter-item.component';

const COMPONENT_SELECTOR = 'wlm-activity-only-open-filter-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './activity-only-open-filter-item.component.html',
  styleUrls: ['./activity-only-open-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => ActivityOnlyOpenFilterItemComponent),
    },
  ],
})
export class ActivityOnlyOpenFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, OnDestroy
{
  @Input() fieldName = 'ilpmsStatus';
  @Input() filterText: string;
  @Input() filters: IFilter[];
  @Input() defaultSelectedIds: any[];

  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);

  private defaultSettings = new BaseFilterItemSettings({
    required: true,
  });
  set settings(value: BaseFilterItemSettings) {
    if (value) {
      const settings = Object.assign(this.defaultSettings, value);
      super.settings = Object.assign(super.settings, settings);
    }
  }
  get settings(): BaseFilterItemSettings {
    return super.settings;
  }
  mode = 'multiple';
  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;

  constructor(private localizationService: LocalizationHelperService) {
    super();
  }

  ngOnInit(): void {
    this.data$$.next(this.getData$());
    this.setDefaultValue();
    super.onInit();
  }

  getData$(): Observable<FilterItemSelectOption[]> {
    return this.localizationService.get(this.T_SCOPE).pipe(
      map((ts) => {
        const items = [
          new FilterItemSelectOption(1, ts['label-open']),
          new FilterItemSelectOption(0, ts['label-closed']),
        ];

        return items;
      })
    );
  }

  setDefaultValue() {
    if (this.filters?.length) {
      const activityStatusFilter = this.filters.filter(
        (x) => Object.getPrototypeOf(x) === ActivityStatusFilter.prototype
      );
      if (activityStatusFilter.length) {
        const filter = activityStatusFilter[0] as ActivityStatusFilter;
        this.selectedIds = [];
        if (filter?.status[0]) {
          this.selectedIds.push(1);
        }
        if (filter?.status[1]) {
          this.selectedIds.push(0);
        }
      }
    }
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  // To be valid, at least one item must be selected.
  isValid(): boolean {
    return this.isValidSelect;
  }

  getStateFormatted(): Observable<string> {
    const selectedValues = this.selectedElements
      ? this.selectedElements.map((x) => x.label).join(', ')
      : null;

    return this.localizationService.get(this.inputSummaryKey).pipe(
      map((label) => {
        const summaryText =
          selectedValues.length === 0
            ? ''
            : this.settings?.hideInputSummaryLabel
            ? selectedValues
            : `${label}${selectedValues}`;
        return summaryText;
      })
    );
  }

  getFieldNames(): string[] {
    return [this.fieldName];
  }

  getAdapter(): FilterAdapterEnum {
    return FilterAdapterEnum.ActivityStatus;
  }
}
