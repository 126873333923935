import { Injectable, Injector } from '@angular/core';
import { GetCacheOpts } from '@common-modules/cache/http-cache/http-cache.client';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { Observable } from 'rxjs';
import { SaveBoundarySignalsCmdResponse } from '../../shared/model/signals/boundary-configuration-save-response.dto';
import { BoundaryConfigurationDto } from '../../shared/model/signals/boundary-configuration.dto';
import { BoundarySignalDto } from '../../shared/model/signals/boundary-signal.dto';

const endpointUrl = '/api/boundary';
@Injectable({
  providedIn: 'root',
})
export class NeConfigurationBoundaryService extends GridODataService<BoundarySignalDto> {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/available`);
  }

  protected mapResponse(
    response: PagedResultDto<BoundarySignalDto>
  ): PagedResultDto<BoundarySignalDto> {
    return response;
  }

  getBoundarySignalsByZone(hierarchyElementId: string): Observable<BoundarySignalDto[]> {
    return this.httpCacheClient.get(`${this.baseUrl}${endpointUrl}/${hierarchyElementId}`, {
      avoid: true,
    });
  }

  saveBoundaryConfiguration(
    boundaryConfiguration: BoundaryConfigurationDto
  ): Observable<SaveBoundarySignalsCmdResponse> {
    return this.httpCacheClient.post(`${this.baseUrl}${endpointUrl}/save`, boundaryConfiguration);
    //.pipe(map(() => true));
  }
}
