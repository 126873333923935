<div class="upper-spacer"></div>
<div class="filters-background">
  <wlm-ne-type-filter
    *ngIf="filterSettings"
    [settings]="filterSettings"
    (filtersChanged)="setDataBindingFilters($event)"
    (apply)="applyFilters()"
    (clearAll)="onClearAll()"
  ></wlm-ne-type-filter>
</div>
<wlm-generic-crud [hidden]="!filtersSetted" class="generic-crud" [settings]="settings">
  <ng-template
    genericCrudAdditionalButtons
    let-isContainerCollapsed="isContainerCollapsed"
    let-type="ultimate"
  >
    <button
      wlmCollapsibleButton
      [tooltip]="T_SCOPE + '.link-delete-custom'"
      [showLabel]="isContainerCollapsed"
      (click)="onDeleteCustomConfirm()"
    >
      <wlm-icon icon="reset-layout"></wlm-icon>
    </button> </ng-template
></wlm-generic-crud>
