import { TabDetailParameterName } from '@common-modules/dependencies/navigation/tab-detail-component';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { DateRange } from '@common-modules/shared/model/date/date-range';

export class DateRangeFAdapter extends FilterAdapterSettings {
  id = FilterAdapterEnum.DateRange;

  // Helps to handle when a date range has the same field name in both fields.
  private dateRangeConfig = {
    startHelperKey: 'StartDate',
    endHelperKey: 'EndDate',
    divider: '#',
  };

  startDateFieldName: string;
  endDateFieldName: string;
  inclusive?: boolean;
  allowsNullDate?: boolean;

  constructor(init: {
    dataBindingField: string;
    startDateFieldName: string;
    endDateFieldName: string;
    inclusive?: boolean;
    allowsNullDate?: boolean;
  }) {
    super(init.dataBindingField);
    Object.assign(this, init);
  }

  adapt(filters: FiltersPayload) {
    const sameKeys = this.startDateFieldName === this.endDateFieldName;
    const { divider, startHelperKey, endHelperKey } = this.dateRangeConfig;
    const startKey = this.startDateFieldName + (sameKeys ? `${divider}${startHelperKey}` : '');
    const endKey = this.endDateFieldName + (sameKeys ? `${divider}${endHelperKey}` : '');

    const startDateFilter = filters.data.get(startKey);
    const endDateFilter = filters.data.get(endKey);

    if (startDateFilter && endDateFilter) {
      const dateRange = new DateRange(
        startDateFilter?.value,
        endDateFilter?.value,
        this.startDateFieldName,
        this.endDateFieldName,
        this.allowsNullDate,
        this.inclusive
      );
      return dateRange;
    }
  }

  buildResults(dateRange: DateRange): FilterAdapterResult {
    if (dateRange) {
      this.filtersResult.filters.set(this.dataBindingField, dateRange);
      this.panelParams.addParameter(TabDetailParameterName.startDate, dateRange.start);
      this.panelParams.addParameter(TabDetailParameterName.endDate, dateRange.end);
    }
    return new FilterAdapterResult({
      dbFilters: this.filtersResult,
      tabParams: this.panelParams,
    });
  }
}
