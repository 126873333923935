import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { PendingChanges } from '@common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from '@common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { ChartType } from '@common-modules/wlm-charts/core/models/chart-type.enum';
import { NodeFormDefinition } from '@common-modules/wlm-charts/core/models/schematics/node-form-definition';
import {
  Schematic,
  SchematicBasic,
  SchematicNode,
  SchematicNodeProcessed,
} from '@common-modules/wlm-charts/core/models/schematics/schematic';
import { SchematicChartDataParameters } from '@common-modules/wlm-charts/core/models/schematics/schematic-chart-data-parameters';
import { SchematicChartSettings } from '@common-modules/wlm-charts/core/models/schematics/schematic-chart-settings';
import { SchematicOperationsEnum } from '@common-modules/wlm-charts/core/models/schematics/schematic-operations.enum';
import { SpinnerWrapperSettings } from '@common-modules/wlm-spinner/models/spinner-wrapper-settings';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { Observable, of, Subject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { ISchematicChartComponent } from '../schematic-chart/schematic-chart-component.interface';
import { SchematicChartComponent } from '../schematic-chart/schematic-chart.component';
import { SchematicsService } from '../services/schematics.service';

const COMPONENT_SELECTOR = 'wlm-schematics-container';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './schematics-container.component.html',
  styleUrls: ['./schematics-container.component.scss'],
})
export class SchematicsContainerComponent implements OnInit, IPendingChangesEmitter {
  @ViewChild(SchematicChartComponent) schematicChart: ISchematicChartComponent;

  @Input() pageId: string;

  private _schematic: SchematicBasic;
  get schematic(): SchematicBasic {
    return this._schematic;
  }
  @Input() set schematic(value: SchematicBasic) {
    this._schematic = value;
    if (this.schematic) {
      this.buildChartSettings(this.schematic.name);
      this.nodeFormVisible = false;
      this.loadedSchematic = false;
    }

    this.schematicChart?.resetDragChanges();
  }

  private _editMode: boolean;
  get editMode() {
    return this._editMode;
  }
  @Input() set editMode(value) {
    this._editMode = value;
    if (this.schematicChart && this._previousEditMode !== this._editMode) {
      if (this._editMode) {
        this.currentOperation = SchematicOperationsEnum.None;
      }

      this.schematicChart.setEditMode(this._editMode);
      this._previousEditMode = this._editMode;
    }
  }
  @Output() toggleEditor = new EventEmitter<boolean>();
  @Output() chartFinished = new EventEmitter<void>();
  @Output() externalLoading = new EventEmitter<boolean>();

  private _hasChanges = false;
  public get hasChanges() {
    return this._hasChanges;
  }
  public set hasChanges(value) {
    if (this.hasChanges !== value) {
      this.setPendingChanges(this.pageId, this.getPendingChanges(value));
    }
    this._hasChanges = value;
  }

  chartSettings: SchematicChartSettings;
  chartElementHaschanged = false;
  isAllSynced = true;
  spinnerWrapperSettings: SpinnerWrapperSettings = {
    isOpaque: true,
  };

  //Node form elements
  nodeForm: UntypedFormGroup;
  nodeFormVisible = false;
  gisIdFieldName = 'gisId';
  nameFieldName = 'name';
  latitudeFieldName = 'latitude';
  longitudeFieldName = 'longitude';
  networkElementTypeIdFieldName = 'networkElement';

  nodeFormDefinition: NodeFormDefinition;
  selectedNodeGisId: number = null;
  selectedName: string;
  private _selectedLatitude: number;
  get selectedLatitude(): number {
    return this._selectedLatitude;
  }
  set selectedLatitude(value: number) {
    this._selectedLatitude = value;

    if (this.nodeForm) {
      this.nodeForm.controls[this.latitudeFieldName].setValue(value);
    }
  }

  private _selectedLongitude: number;
  get selectedLongitude(): number {
    return this._selectedLongitude;
  }
  set selectedLongitude(value: number) {
    this._selectedLongitude = value;

    if (this.nodeForm) {
      this.nodeForm.controls[this.longitudeFieldName].setValue(value);
    }
  }
  selectedNetworkElementTypeId: string;
  currentOperation: SchematicOperationsEnum;
  operationMode = SchematicOperationsEnum;
  updatedModel: Schematic;
  toggleMapOpacity$ = new Subject<void>();
  loadedSchematic = false;

  readonly T_SCOPE = `${AppModules.Schematics}.${COMPONENT_SELECTOR}`;

  get getNodeFormTitleKey(): string {
    return this.currentOperation === SchematicOperationsEnum.CreateNode
      ? `${this.T_SCOPE}.create-node-title`
      : `${this.T_SCOPE}.edit-node-title`;
  }

  get componentName() {
    return 'SchematicsContainerComponent';
  }

  private _previousEditMode = false;
  private _updatedDataPointsHash: Map<string, SchematicNodeProcessed>;
  private _schematicModel: Schematic;

  private setLoading: (loading: boolean) => void;
  private _mapOpacityEnabled = false;

  constructor(
    private _schematicsService: SchematicsService,
    private _spinnerService: SpinnerService,
    private _dialogsService: DialogService,
    private _cd: ChangeDetectorRef,
    private _formBuilder: UntypedFormBuilder,
    private _objectHelperService: ObjectHelperService,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {}

  buildChartSettings(chartName: string): void {
    this.chartSettings = new SchematicChartSettings({
      chartType: ChartType.schematic,
      dataParameters: new SchematicChartDataParameters({
        queryParams: { name: chartName },
        dataService: 'SchematicChartService',
        startDate: null,
        endDate: null,
      }),
    });
  }

  private refreshChartSettings(chartName: string): void {
    this.chartSettings = null;
    this._cd.detectChanges();
    this.buildChartSettings(chartName);
  }

  /**
   * This model does not integrate anty changes, they are meant to be integrated here for better performance.
   */
  onLoadedSchematic(schematicModel: Schematic): void {
    this.loadedSchematic = true;
    this._schematicModel = schematicModel;
    this.updatedModel = this._objectHelperService.clone(this._schematicModel, true);
    this.checkModelChanges();
    this.checkGlobalChanges();
  }

  onModelChange(schematicModel: Schematic): void {
    this.updatedModel = schematicModel;
    this.checkModelChanges();
    this.checkGlobalChanges();
    this.currentOperation = SchematicOperationsEnum.None;
  }

  private checkModelChanges() {
    const differences = this._objectHelperService.deepDiff(this._schematicModel, this.updatedModel);
    this.chartElementHaschanged = Object.keys(differences).length !== 0;
  }

  //*****************************/
  //    Operation handlers
  //*****************************/
  onDisableMap() {
    this.toggleMapOpacity$.next();
    this._mapOpacityEnabled = !this._mapOpacityEnabled;
  }

  onCreateNode() {
    this.setOperationMode(SchematicOperationsEnum.CreateNode);
    this.initializeNodeDefinition();
    this.initializeCreateNodeForm();
    this.selectedLatitude = null;
    this.selectedLongitude = null;

    this.nodeFormVisible = true;
  }

  onEditNode() {
    this.setOperationMode(SchematicOperationsEnum.EditNode);
  }

  onNotifyNodeInfo(selectedNode: SchematicNode): void {
    if (this.currentOperation === SchematicOperationsEnum.EditNode) {
      this.initializeCreateNodeForm();
      this.initializeNodeDefinition();
      this.populateNodeForm(selectedNode);

      this.selectedNodeGisId = +selectedNode.id;
      this.selectedName = selectedNode.name;
      this.selectedLatitude = selectedNode.x;
      this.selectedLongitude = selectedNode.y;
      this.selectedNetworkElementTypeId = selectedNode.category;

      this.nodeFormVisible = true;
    }
  }

  onDeleteNode() {
    this.setOperationMode(SchematicOperationsEnum.DeleteNode);
  }

  onCreateRelation() {
    this.setOperationMode(SchematicOperationsEnum.CreateRelation);
  }

  onDeleteRelation() {
    this.setOperationMode(SchematicOperationsEnum.DeleteRelation);
  }

  onDrag(): void {
    if (this.currentOperation === SchematicOperationsEnum.DragDrop) {
      return;
    }

    this.setOperationMode(SchematicOperationsEnum.DragDrop);
    this.schematicChart.enableDragMode();
  }

  onLastClickedPositionChange(position: number[]) {
    if (this.currentOperation !== SchematicOperationsEnum.None) {
      this.selectedLatitude = position[1];
      this.selectedLongitude = position[0];
    }
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: hasChanges,
      saveFn: () => this.save(),
    };
  }

  private setOperationMode(mode: SchematicOperationsEnum) {
    this.currentOperation = mode;
    this.schematicChart?.setSchematicOperation(this.currentOperation);
  }

  private initializeNodeDefinition() {
    this.nodeFormDefinition = new NodeFormDefinition({
      gisIdFieldName: this.gisIdFieldName,
      nameFieldName: this.nameFieldName,
      latitudeFieldName: this.latitudeFieldName,
      longitudeFieldName: this.longitudeFieldName,
      networkElementFieldName: this.networkElementTypeIdFieldName,
      titleKey: this.getNodeFormTitleKey,
    });
  }

  private initializeCreateNodeForm() {
    const nodeFormControls: { [key: string]: UntypedFormControl } = {};

    nodeFormControls[this.gisIdFieldName] = new UntypedFormControl(this.selectedNodeGisId, [
      Validators.required,
    ]);

    nodeFormControls[this.nameFieldName] = new UntypedFormControl(this.selectedName, [
      Validators.required,
    ]);

    nodeFormControls[this.latitudeFieldName] = new UntypedFormControl(this.selectedLatitude, [
      Validators.required,
    ]);

    nodeFormControls[this.longitudeFieldName] = new UntypedFormControl(this.selectedLongitude, [
      Validators.required,
    ]);

    nodeFormControls[this.networkElementTypeIdFieldName] = new UntypedFormControl(
      this.selectedNetworkElementTypeId,
      [Validators.required]
    );

    this.nodeForm = this._formBuilder.group(nodeFormControls);
  }

  //Save handler from node form control
  onSaveNode(node) {
    this.schematicChart.saveNode(node);
    this.restoreNodeForm();
  }

  private restoreNodeForm() {
    this.clearNodeForm();
    this.nodeFormVisible = false;
    this.currentOperation = SchematicOperationsEnum.None;
  }

  private clearNodeForm() {
    this.selectedNodeGisId = null;
    this.selectedName = null;
    this.selectedLatitude = null;
    this.selectedLongitude = null;
    this.selectedNetworkElementTypeId = null;
  }

  onSave() {
    this.save().subscribe((_) => {});
  }

  save(): Observable<boolean> {
    // By now, the schematic model should always be available.
    if (!this._schematicModel) {
      return of(true);
    }

    this.nodeFormVisible = false;
    this.updatedModel.hideMap = this._mapOpacityEnabled;

    this.updatedModel.nodes.forEach((node) => {
      if (this._updatedDataPointsHash?.has(node.id)) {
        const updatedNode = this._updatedDataPointsHash.get(node.id);
        node.x = updatedNode.value[1];
        node.y = updatedNode.value[0];
      }
    });

    this.loadedSchematic = false;
    this.setLoading(true);

    return this._schematicsService.update(this.updatedModel).pipe(
      finalize(() => this.setLoading(false)),
      tap(() => {
        this._dialogsService.showEntityActionSnackBar('update', 'schematic.singular');
        this.setOperationMode(SchematicOperationsEnum.None);
        this.toggleEditor.emit(false);
        this.refreshChartSettings(this.schematic.name);
      }),
      map(() => true)
    );
  }

  //Reset handler: undo all changes
  onReset(): void {
    if (this.schematicChart) {
      if (this._updatedDataPointsHash) {
        this.schematicChart.resetDragChanges();
      }

      this.refreshChartSettings(this.schematic.name);
      this.chartElementHaschanged = false;
      this.setOperationMode(SchematicOperationsEnum.None);
      this.checkGlobalChanges();
    }
  }

  private populateNodeForm(selectedNode: SchematicNode) {
    if (this.nodeForm) {
      this.nodeForm.controls[this.gisIdFieldName].setValue(selectedNode.id);
      this.nodeForm.controls[this.nameFieldName].setValue(selectedNode.name);
      this.nodeForm.controls[this.latitudeFieldName].setValue(selectedNode.x);
      this.nodeForm.controls[this.longitudeFieldName].setValue(selectedNode.y);
      this.nodeForm.controls[this.networkElementTypeIdFieldName].setValue(+selectedNode.category);
    }
  }

  //Notify graph changes on drop operation
  onUpdatedDataPoints(hash: Map<string, any>): void {
    this._updatedDataPointsHash = hash;
    this.checkGlobalChanges();
  }

  private checkGlobalChanges() {
    const datapoinsValidation = this._updatedDataPointsHash
      ? this._updatedDataPointsHash?.size !== 0
      : false;

    this.hasChanges = datapoinsValidation || this.chartElementHaschanged;
  }

  onOptionSelected(event: any): void {
    this.schematicChart.zoomToNode(event);
  }

  onChartFinished(event): void {
    this.chartFinished.emit(event);
  }

  onExternalLoading(isLoading: boolean): void {
    this.setLoading(isLoading);
    this.externalLoading.emit(isLoading);
  }

  onIsAllSynced(isAllSynced): void {
    this.isAllSynced = isAllSynced;
  }

  onCloseEditor(): void {
    this.toggleEditor.emit(false);

    this.onReset();
    this.restoreNodeForm();
    this.nodeFormVisible = false;
  }

  onCancelForm() {
    this.checkGlobalChanges();
    this.restoreNodeForm();
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
