import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DynamicSettingsSave } from '@common-modules/dynamic-layout/models/dynamic-settings-save';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DynamicSettingsService } from '@common-modules/shared/config/dynamic-settings.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationConfigurationService } from '../../configuration/notification-configuration.service';
import { TreelistChange } from '../../shared/core/treelist/treelist-change';
import { TreelistData } from '../../shared/core/treelist/treelist-data';
import { TreelistSettings } from '../../shared/core/treelist/treelist-settings';

const COMPONENT_SELECTOR = 'wlm-notification-configuration-popup';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './notification-configuration-popup.component.html',
  styleUrls: ['./notification-configuration-popup.component.scss'],
})
export class NotificationConfigurationPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;

  public get titleTranslationKey(): string {
    return 'NotificationConfigurationPopupComponent';
  }

  public get pageCrud(): string {
    return 'NotificationConfigurationPopupComponent';
  }

  treeListSettings: TreelistSettings;
  private _config: Map<string, Map<string, boolean>>;

  private _saveErrorMsg = 'common.messages.saved-error';
  private _settingArea = 'NotificationSetting';
  private _settingKey = 'NotificationValues';

  constructor(
    private _nfConfigService: NotificationConfigurationService,
    private _spinnerService: SpinnerService,
    private _dialogRef: MatDialogRef<NotificationConfigurationPopupComponent>,
    private _dynamicSettingsService: DynamicSettingsService,
    private _dialogService: DialogService,
    private _objectHelper: ObjectHelperService
  ) {}

  ngOnInit(): void {
    this.loadConfiguration();
  }

  private loadConfiguration() {
    this.setSpinner(true);
    this._nfConfigService
      .getUserNotificationConfiguration()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (config) => {
          this._config = config;
          this.treeListSettings = this.buildTreeListSettings(config);
          this.setSpinner(false);
        },
        error: (error) => {
          this.setSpinner(false);
        },
      });
  }

  private buildTreeListSettings(
    configuration: Map<string, Map<string, boolean>>
  ): TreelistSettings {
    const columnKeys = new Set<string>();
    const data: TreelistData[] = [];

    configuration.forEach((rowValue, rowKey) => {
      const treeListEntry = new TreelistData({
        columnsData: new Map<string, boolean>(rowValue),
        rowKey,
      });
      data.push(treeListEntry);

      rowValue.forEach((value, columnKey) => {
        columnKeys.add(columnKey);
      });
    });

    //TODO: Delete this when the notification email template would be created
    const disabledNodes = new Map<string, string[]>([['General', ['Email']]]);

    const treeSetting = new TreelistSettings({ columnKeys, data, disabledNodes });

    return treeSetting;
  }

  onValueChanged(change: TreelistChange) {
    this._config.get(change.rowKey)?.set(change.columnKey, change.value);
  }

  saveConfiguration() {
    const configKeyObject = this._objectHelper.mapToPlainObjectRecursive(this._config);
    const configSerialized = JSON.stringify(configKeyObject);

    const settings = new DynamicSettingsSave({
      settingArea: this._settingArea,
      settingKey: this._settingKey,
      settingValue: configSerialized,
      saveAsDefault: false,
    });
    this.setSpinner(true);

    this._dynamicSettingsService.saveDynamicSettings(settings).subscribe({
      next: () => {
        this._dialogService.showEntityActionSnackBar('save', 'configuration');
        this._dialogRef.close();
        this.setSpinner(false);
      },
      error: (error) => {
        this._dialogService.showTranslatedMessageInSnackBar(
          new WlmDialogSettings({ translateKey: this._saveErrorMsg, icon: 'error' })
        );
        this.setSpinner(false);
      },
    });
  }

  close() {
    this._dialogRef.close();
  }

  private setSpinner(isLoading: boolean) {
    this._spinnerService.setLoading(isLoading, this.pageCrud);
  }
}
