import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { IHierarchyElementTypes } from '@common-modules/dependencies/he/hierarchy.constants';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { TimeAggregationEnum } from '@common-modules/shared/model/algorithm/time-aggregation.enum';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { asEnumerable } from 'linq-es2015';
import { combineLatest } from 'rxjs';
import { ICustomerGroupDto } from '../../shared/model/customer/customer-group.dto';
import { CustomerGroupService } from '../services/customer-group.service';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

const COMPONENT_SELECTOR = 'wlm-customer-group';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './customer-group.component.html',
  styleUrls: ['./customer-group.component.scss'],
})
export class CustomerGroupComponent extends BaseWidgetComponent implements OnInit {
  @Input() elementId: string;

  public customerGroup: ICustomerGroupDto[] = [];
  public errorMessage: Error;
  public totalProperties = 0;
  public averageConsumption = 0;
  public totalMetered = 0;
  public totalUnmetered = 0;
  public totalConsumptionMetered = 0;
  public totalConsumptionUnmetered = 0;
  public totalCount: Tile[] = [];
  public partialCount: Tile[] = [];
  public categories: string[] = [];
  public categoriesGroups: Map<string, number> = new Map();
  public categoriesMetered: Map<string, number> = new Map();
  public categoriesUnmetered: Map<string, number> = new Map();
  public consumptionGroups: Map<string, number> = new Map();
  public consumptionMetered: Map<string, number> = new Map();
  public consumptionUnmetered: Map<string, number> = new Map();

  private readonly _fixedDecimals = 3;

  consumptionDimension = DimensionTypesEnum.Flow;
  comsumptionTimeAggregation = TimeAggregationEnum.Daily;
  unitConversion: UnitTypeConversionViewDto;

  T_SCOPE = `${AppModules.Customer}.${COMPONENT_SELECTOR}`;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    public readonly dialogService: DialogService,
    public readonly customerGrouService: CustomerGroupService,
    private readonly localization: LocalizationHelperService,
    private readonly _uomService: UoMService
  ) {
    super(injector, widgetSettings);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.elementId = parameters.parameters.get(TabDetailParameterName.hierarchyElementId);
  }

  init(): void {
    if (this.elementId) {
      this.loadCustomerGroup();
    }
  }

  loadCustomerGroup() {
    this.loading = true;
    const uom$ = this._uomService.getByParams(
      this.consumptionDimension,
      this.comsumptionTimeAggregation,
      IHierarchyElementTypes.Customer
    );
    const customer$ = this.customerGrouService.getCustomerGroup(this.elementId);

    combineLatest([uom$, customer$]).subscribe({
      next: ([uom, customerGroup]) => {
        this.resetArrays();

        this.unitConversion = uom;
        this.customerGroup = customerGroup;
        this.calcTotalProperties();
        this.groupByMeasurementType();
        this.listCategories();
        this.groupByCategoryAndMeasurement();
        this.displayCustomerInfo();
        this.loading = false;
      },
      error: (error) => {
        this.dialogService.showErrorMessage(error);
        this.loading = false;
      },
    });
  }

  calcTotalProperties() {
    this.totalProperties = asEnumerable(this.customerGroup).Sum((x) => x.customersNumber);
    this.averageConsumption = +(
      asEnumerable(this.customerGroup).Sum((x) => x.consumption) * this.getUnitConversionFactor()
    ).toFixed(5);
  }

  groupByMeasurementType() {
    const totalMeteredFiltered = asEnumerable(this.customerGroup).Where((x) => x.isMeasured);
    this.totalMetered = totalMeteredFiltered.Sum((x) => x.customersNumber);

    this.totalConsumptionMetered = +(
      totalMeteredFiltered.Sum((x) => x.consumption) * this.getUnitConversionFactor()
    ).toFixed(5);

    const totalUnmeteredFiltered = asEnumerable(this.customerGroup).Where(
      (x) => x.isMeasured === false
    );

    this.totalUnmetered = totalUnmeteredFiltered.Sum((x) => x.customersNumber);

    this.totalConsumptionUnmetered = +(
      totalUnmeteredFiltered.Sum((x) => x.consumption) * this.getUnitConversionFactor()
    ).toFixed(5);
  }

  listCategories() {
    this.categories = asEnumerable(this.customerGroup)
      .Select((x) => x.customerClassSuperTypeName)
      .Distinct()
      .ToArray();
  }

  groupByCategoryAndMeasurement() {
    this.categories.forEach((category) => {
      const customersGroupedFiltered = asEnumerable(this.customerGroup).Where(
        (y) => y.customerClassSuperTypeName === category
      );
      const customersGrouped = customersGroupedFiltered.Sum((y) => y.customersNumber);
      this.categoriesGroups.set(category, customersGrouped);

      const customersConsumptonGrouped =
        customersGroupedFiltered.Sum((y) => y.consumption) * this.getUnitConversionFactor();
      this.consumptionGroups.set(
        category,
        +customersConsumptonGrouped.toFixed(this._fixedDecimals)
      );

      const categoriesMeteredFiltered = asEnumerable(this.customerGroup).Where(
        (y) => y.customerClassSuperTypeName === category && y.isMeasured === true
      );
      const categoriesMetered = categoriesMeteredFiltered.Sum((y) => y.customersNumber);
      this.categoriesMetered.set(category, categoriesMetered);

      this.consumptionMetered.set(
        category,
        +(
          categoriesMeteredFiltered.Sum((y) => y.consumption) * this.getUnitConversionFactor()
        ).toFixed(this._fixedDecimals)
      );

      const categoriesUnmeteredFiltered = asEnumerable(this.customerGroup).Where(
        (y) => y.customerClassSuperTypeName === category && y.isMeasured === false
      );
      const categoriesUnmetered = categoriesUnmeteredFiltered.Sum((y) => y.customersNumber);
      this.categoriesUnmetered.set(category, categoriesUnmetered);

      const categoriesConsumptionUnmetered =
        categoriesUnmeteredFiltered.Sum((y) => y.consumption) * this.getUnitConversionFactor();
      this.consumptionUnmetered.set(
        category,
        +categoriesConsumptionUnmetered.toFixed(this._fixedDecimals)
      );
    });
  }

  private resetArrays() {
    this.totalCount = [];
    this.partialCount = [];
    this.categories = [];
  }

  displayCustomerInfo() {
    this.localization.get(this.T_SCOPE).subscribe((ts) => {
      this.partialCount.push({
        text: `${ts['number-of-properties']} ${this.totalProperties} \r\n${
          ts['average-consumption']
        } ${this.getNumberFormatted(
          this.averageConsumption
        )} ${this.getUnitConversionDescription()}`,
        cols: 1,
        rows: this.categories.length * 2,
        color: 'rgba(77, 168, 229, 0.75)',
      });
      this.categories.forEach((category) => {
        this.partialCount.push({
          text: `${category}: ${this.categoriesGroups.get(category)} \r\n${
            ts['average-consumption']
          } ${this.getNumberFormatted(
            this.consumptionGroups.get(category)
          )} ${this.getUnitConversionDescription()}`,
          cols: 1,
          rows: 2,
          color: 'rgba(230, 167, 0, 0.75)',
        });
        this.partialCount.push({
          text: `${ts.metered} ${this.categoriesMetered.get(category) ?? '0'} \r\n${
            ts['average-consumption']
          } ${this.getNumberFormatted(
            this.consumptionMetered.get(category)
          )} ${this.getUnitConversionDescription()}`,
          cols: 1,
          rows: 1,
          color: 'rgba(23, 157, 113, 0.75)',
        });
        this.partialCount.push({
          text: `${ts.unmetered} ${this.categoriesUnmetered.get(category) ?? '0'} \r\n${
            ts['average-consumption']
          } ${this.getNumberFormatted(
            this.consumptionUnmetered.get(category)
          )} ${this.getUnitConversionDescription()}`,
          cols: 1,
          rows: 1,
          color: 'rgba(204, 46, 41, 0.7)',
        });
      });
      this.totalCount.push({
        text: `${ts['number-of-properties']} ${this.totalProperties} \r\n${
          ts['average-consumption']
        } ${this.getNumberFormatted(
          this.averageConsumption
        )} ${this.getUnitConversionDescription()}`,
        cols: 1,
        rows: this.categories.length * 2,
        color: 'rgba(77, 168, 229, 0.75)',
      });
      this.totalCount.push({
        text: `${ts.metered} ${this.totalMetered} \r\n${
          ts['average-consumption']
        } ${this.getNumberFormatted(
          this.totalConsumptionMetered
        )} ${this.getUnitConversionDescription()}`,
        cols: 2,
        rows: this.categories.length,
        color: 'rgba(23, 157, 113, 0.75)',
      });
      this.totalCount.push({
        text: `${ts.unmetered} ${this.totalUnmetered} \r\n${
          ts['average-consumption']
        } ${this.getNumberFormatted(
          this.totalConsumptionUnmetered
        )} ${this.getUnitConversionDescription()}`,
        cols: 2,
        rows: this.categories.length,
        color: 'rgba(204, 46, 41, 0.7)',
      });
    });
  }

  get componentName() {
    return 'CustomerGroupComponent';
  }

  getNumberFormatted(value: number) {
    return this.localization.formatNumber(value);
  }

  getUnitConversionFactor() {
    return this.unitConversion ? this.unitConversion.conversionFactor : 1;
  }

  getUnitConversionDescription() {
    return this.unitConversion ? this.unitConversion.unitTypeToDescription : 'm3/s';
  }
}
