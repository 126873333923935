import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { CanSaveHistoricalForm } from '@water-loss//features/shared/historical/can-save-historical-form';
import { HistoricalFormSettings } from '@water-loss//features/shared/historical/historical-form-settings';
import { HierarchyElementPressureSignalVersionDto } from '@water-loss//features/shared/model/signals/hierarchy-element-pressure-signal-version.dto';
import { PressureSignalTypesEnum } from '@water-loss//features/shared/model/signals/pressure-signal-types.enum';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-pressure-historical-form';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-pressure-historical-form.component.html',
  styleUrls: ['./ne-configuration-pressure-historical-form.component.scss'],
})
export class NeConfigurationPressureHistoricalFormComponent {
  private _formModel: HierarchyElementPressureSignalVersionDto;
  get formModel(): HierarchyElementPressureSignalVersionDto {
    return this._formModel;
  }
  @Input() set formModel(value: HierarchyElementPressureSignalVersionDto) {
    this._formModel = value;
    if (value) {
      const pressureTypeValue = this.pressureTypeMap[value.pressureTypeId] || 'Unknown';
      this.pressureType.setValue(pressureTypeValue);

      if (value.offset !== null && value.offset !== undefined) {
        this.offset.setValue(value.offset);
      } else {
        this.offset.reset();
      }
    }
  }
  @Input() settings: HistoricalFormSettings;

  @Output() formChanges = new EventEmitter<HierarchyElementPressureSignalVersionDto>();
  @Output() canSave = new EventEmitter<CanSaveHistoricalForm>();
  @Output() canReset = new EventEmitter<boolean>();

  readonly pressureType = new FormControl({
    value: null,
    disabled: true,
  });

  readonly pressureTypeMap = {
    [PressureSignalTypesEnum.AZP]: 'AZP',
    [PressureSignalTypesEnum.CPP]: 'CPP',
    [PressureSignalTypesEnum.MPP]: 'MPP',
  };

  readonly offset = new FormControl({
    value: null,
    disabled: true,
  });

  readonly T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  onFormChange(data: HierarchyElementPressureSignalVersionDto): void {
    this.formChanges.emit(data);
  }

  onCanSave(data: CanSaveHistoricalForm): void {
    this.canSave.emit(data);
  }

  onCanReset(canReset: boolean): void {
    this.canReset.emit(canReset);
  }
}
