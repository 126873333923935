import { Injectable, Injector } from '@angular/core';
import { AdditionalHttpOpts } from '@common-modules/cache/http-cache/additional-http-options';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable } from 'rxjs';
import { CommentCreateDto } from '../../monitoring/notification/models/comment-create.dto';

import { CommentDto } from '../models/comment.dto';

@Injectable({ providedIn: 'root' })
export class CommentsService extends BaseService {
  protected get url() {
    return `${this.apiUrl}/comment`;
  }

  protected get discussionUrl() {
    return `${this.apiUrl}/discussion`;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  getByDiscussion(discussionId: string): Observable<CommentDto[]> {
    const additionalOpts = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: true,
    });

    return this.httpCacheClient.getWithOpts({
      url: `${this.discussionUrl}/${discussionId}`,
      additionalOpts,
    });
  }

  create(comment: CommentCreateDto): Observable<CommentDto> {
    return this.httpCacheClient.post(this.url, comment, { avoid: true });
  }
}
