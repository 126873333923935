import { IMVDto } from '@common-modules/shared/model/mv/mv-dto';

export class ApplyMvEstimationResponse {
  measuredValues: IMVDto[] = [];
  isCompleted: boolean = true;

  constructor(init?: Partial<ApplyMvEstimationResponse>) {
    Object.assign(this, init);
  }
}
