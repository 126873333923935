import { EnvelopesConfiguration } from '@common-modules/dependencies/alarms/envelopes-configuration';

export class SAEnvelopesConfigurationSettings {
  defaultEnvConf: EnvelopesConfiguration;
  gridName: string;

  constructor(init?: SAEnvelopesConfigurationSettings) {
    Object.assign(this, init);
  }
}
