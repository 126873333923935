import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { MaterialSharedModule } from '@common-modules/shared/material-shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { isObservable } from 'rxjs';
import { TreelistChange } from './treelist-change';
import { TreelistData } from './treelist-data';
import { TreelistSettings } from './treelist-settings';

const COMPONENT_SELECTOR = 'wlm-treelist';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './treelist.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialSharedModule,
    TranslateModule,
    TreeListModule,
  ],
  styleUrls: ['./treelist.component.scss'],
})
export class TreelistComponent implements OnInit {
  readonly T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;
  fullSize: boolean = true;

  private _treeListSettings: TreelistSettings;
  get treeListSettings(): TreelistSettings {
    return this._treeListSettings;
  }
  @Input() set treeListSettings(value: TreelistSettings) {
    this._treeListSettings = value;
    this.bindData();
  }

  @Output() valueChanged = new EventEmitter<TreelistChange>();

  data: TreelistData[];

  constructor() {}

  ngOnInit(): void {}

  columnValueChanged(event: MatCheckboxChange, dataItem: TreelistData, columnKey: string) {
    const change = new TreelistChange({ columnKey, rowKey: dataItem.rowKey, value: event.checked });
    this.valueChanged.emit(change);
  }

  checkIfDisabled(dataItem, columnKey): boolean {
    if (!this.treeListSettings.disabledNodes.has(dataItem.rowKey)) {
      return false;
    }

    const isDisabledColumn = this.treeListSettings.disabledNodes
      .get(dataItem.rowKey)
      .includes(columnKey);

    return isDisabledColumn;
  }

  private bindData(): void {
    const dataSource = this.treeListSettings?.data;
    if (dataSource) {
      if (isObservable(dataSource)) {
        dataSource.subscribe((data) => {
          this.data = data;
        });
      } else {
        this.data = dataSource;
      }
    }
  }
}
