<span *ngIf="!useImages || isExporting" class="column-cell">
  {{ elements | enumField : dataItem[field] }}
</span>
<span *ngIf="useImages && !isExporting" class="image-position">
  <mat-icon *ngIf="dataItem[field]" class="true-definition">done</mat-icon>
  <mat-icon *ngIf="dataItem[field] === false" class="false-definition">clear</mat-icon>
  <div *ngIf="dataItem[field] === null || dataItem[field] === undefined">
    {{ dataItem[field] | gridCellEmpty }}
  </div>
</span>
