import { ProfileConfigurationDto } from '@common-modules/dependencies/alarms/profile-configuration.dto';

export class SaveProfileDto {
  profileConfigurations: ProfileConfigurationDto[];
  subscribe: boolean;

  constructor(init?: Partial<SaveProfileDto>) {
    Object.assign(this, init);
  }
}
