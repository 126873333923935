import { Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { INetworkElementSubTypeDto } from '@common-modules/dependencies/ne/network-element-subtype.dto';
import { INetworkElementTypeDto } from '@common-modules/dependencies/ne/network-element-type.dto';
import { IntegrableForm, IntegrableFormParams } from '@common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BehaviorSubject, map, Observable, of, startWith } from 'rxjs';
import { NETypesCrudService } from '../../ne-types/ne-types-crud.service';

@UntilDestroy()
@Component({
  selector: 'wlm-ne-subtype-form',
  templateUrl: './ne-subtype-form.component.html',
  styleUrl: './ne-subtype-form.component.scss',
})
export class NeSubTypeFormComponent extends IntegrableForm {
  @Input() initialModel: INetworkElementSubTypeDto = null;
  model: INetworkElementSubTypeDto;

  private readonly _neTypesCrudService = inject(NETypesCrudService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _globalService = inject(GlobalsService);
  private neSelectedOption = new BehaviorSubject<INetworkElementTypeDto>(null);

  private _operatorList = of([
    { key: '==', value: '==' },
    { key: '!=', value: '!=' },
  ]);

  onModelChange(model: INetworkElementSubTypeDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'networkElementSubTypeId',
          type: 'input',
          className: 'col-6',
          props: {
            type: 'number',
            label: 'Network Element SubType Id',
            required: true,
          },
          expressions: {
            'props.disabled': (data) => this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'networkElementSubTypeName',
          type: 'input',
          className: 'col-6',
          props: {
            type: 'text',
            label: 'Network Element SubType Name',
            required: true,
          },
        },
        {
          key: 'networkElementTypeId',
          type: 'select',
          className: 'col-4',
          props: {
            label: 'Network Element Type',
            valueProp: 'networkElementTypeId',
            labelProp: 'networkElementTypeName',
            required: true,
            options: this._neTypesCrudService
              .getAll()
              .pipe(
                map((options) =>
                  options
                    .filter((option) => option.isZone === false)
                    .sort((a, b) =>
                      a.networkElementTypeName.localeCompare(b.networkElementTypeName)
                    )
                )
              ),
          },
          hooks: {
            onInit: (field) => {
              (field.props.options as Observable<any[]>)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((options) => {
                  field.formControl.valueChanges
                    .pipe(takeUntilDestroyed(this._destroyRef))
                    .subscribe((value) => {
                      const selectedOption = options.find(
                        (option) => option[field.props.valueProp] === value
                      );
                      field.model[field.props.labelProp] = selectedOption[field.props.labelProp];
                      this.neSelectedOption.next(selectedOption);
                    });
                });
            },
          },
        },
        {
          key: 'isNetworkElement',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Network Element',
            required: true,
            disabled: true,
          },
          hooks: {
            onInit: (field) => {
              this.neSelectedOption
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((selectedOption) => {
                  if (selectedOption && selectedOption.isNetworkElement !== undefined) {
                    field.formControl.setValue(selectedOption.isNetworkElement);
                  }
                });
            },
          },
        },
        {
          key: 'isGisElement',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is GIS Element',
            required: true,
            disabled: true,
          },
          hooks: {
            onInit: (field) => {
              this.neSelectedOption
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((selectedOption) => {
                  if (selectedOption && selectedOption.isGisElement !== undefined) {
                    field.formControl.setValue(selectedOption.isGisElement);
                  }
                });
            },
          },
        },
      ],
    },
    {
      wrappers: ['section'],
      props: { label: 'Interface Expression' },
      fieldGroupClassName: 'row',

      fieldGroup: [
        {
          key: 'networkElementAttributeId',
          type: 'select',
          className: 'col-5 formly-field-extra-padding',
          props: {
            label: 'Network Element Attribute Type',
            valueProp: 'networkElementAttributeTypeId',
            labelProp: 'networkElementAttributeTypeName',
            options: this._globalService
              .getNetworkElementAttributesType()
              .pipe(
                map((options) =>
                  options.sort((a, b) =>
                    a.networkElementAttributeTypeName.localeCompare(
                      b.networkElementAttributeTypeName
                    )
                  )
                )
              ),
          },
          expressions: {
            'props.disabled': 'model.isComplexExpression',
            'props.description':
              'model.isComplexExpression ? "Complex expressions can be modified in the Log/Interfaces page" : null',
          },
          hooks: {
            onInit: (field) => {
              (field.props.options as Observable<any[]>)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((options) => {
                  field.formControl.valueChanges
                    .pipe(takeUntilDestroyed(this._destroyRef))
                    .subscribe((value) => {
                      const selectedOption = options.find(
                        (option) => option[field.props.valueProp] === value
                      );
                      field.model[field.props.labelProp] = selectedOption[field.props.labelProp];
                    });
                });
            },
          },
        },
        {
          key: 'expressionOperator',
          type: 'select',
          className: 'col-2',
          props: {
            label: 'Operator',
            valueProp: 'key',
            labelProp: 'value',

            options: this._operatorList,
          },
          expressions: {
            'props.disabled': 'model.isComplexExpression',
          },
          hooks: {
            onInit: (field) => {
              (field.props.options as Observable<any[]>)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((options) => {
                  field.formControl.valueChanges
                    .pipe(takeUntilDestroyed(this._destroyRef))
                    .subscribe((value) => {
                      const selectedOption = options.find(
                        (option) => option[field.props.valueProp] === value
                      );
                      field.model[field.props.labelProp] = selectedOption[field.props.labelProp];
                    });
                });
            },
          },
        },
        {
          key: 'networkElementAttributeValue',
          type: 'input',
          className: 'col-5',
          props: {
            type: 'text',
            label: 'Network Element Attribute Type Value',
          },
          expressions: {
            'props.disabled': 'model.isComplexExpression',
          },
        },
      ],
    },
    {
      wrappers: ['section'],
      props: { label: 'Map Attributes' },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'gisLayerId',
          type: 'input',
          className: 'col-5',
          props: {
            type: 'number',
            label: 'GIS Layer Id',
            disabled: true,
          },
          hooks: {
            onInit: (field) =>
              this.syncWithField(field, 'networkElementSubTypeId', (field) => field.parent.parent),
          },
        },
        {
          key: 'description',
          type: 'input',
          className: 'col-5',
          props: {
            label: 'GIS Layer Description',
            disabled: true,
          },
          hooks: {
            onInit: (field) =>
              this.syncWithField(
                field,
                'networkElementSubTypeName',
                (field) => field.parent.parent
              ),
          },
        },
        {
          key: 'gisOrder',
          type: 'input',
          className: 'col-2',
          props: {
            type: 'number',
            label: 'GIS Order',
          },
        },
        {
          key: 'iconPath',
          type: 'svg-editor',
          className: 'col-12',
          props: {
            label: 'Icon',
            previewLabel: 'Icon Preview',
            previewPlaceholder: 'Introduce valid SVG content',
            disabledValidation: true,
          },
          validators: {
            validation: [{ name: 'notSvg' }],
          },
        },
      ],
    },
  ];

  private syncWithField(
    field: FormlyFieldConfig,
    sourceFieldName: string,
    getSourceFieldParent?: (field: FormlyFieldConfig) => FormlyFieldConfig
  ): void {
    const sourceForm = getSourceFieldParent ? getSourceFieldParent(field) : field.parent;
    const sourceFormControl = sourceForm.get(sourceFieldName).formControl;

    sourceFormControl.valueChanges
      .pipe(untilDestroyed(this), startWith(sourceFormControl.value))
      .subscribe((value) => {
        field.formControl.setValue(value);
      });
  }
}
