import { Component, Input, OnInit } from '@angular/core';
import { FilterConditionalOverride } from '@common-modules/common-filters/models/filter-conditional-override';
import { IHierarchyElementTypeDto } from '@common-modules/dependencies/he/hierarchy-element-type.dto';
import { ElementTypeFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/element-type-f-adapter';
import { HierarchyElementPathFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FiltersAdapterService } from '@common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { FiltersPayloadStatus } from '@common-modules/dependencies/wlm-filters/filters-payload-status.enum';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { HierarchyElementsSelectionFilterConfiguration } from '@common-modules/shared-component/combined-grids/hierarchy-elements-selection-grid/hierarchy-elements-selection-filter-configuration';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { IGridSettings } from '@common-modules/shared/constants/grid.constants';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { TreeSettingsService } from '@common-modules/shared/services/tree-settings.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';

const COMPONENT_SELECTOR = 'wlm-hierarchy-elements-selection-filter';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-elements-selection-filter.component.html',
  styleUrls: ['./hierarchy-elements-selection-filter.component.scss'],
})
export class HierarchyElementsSelectionFilterComponent
  extends BasePageFiltersComponent
  implements OnInit
{
  @Input() public set config(value: HierarchyElementsSelectionFilterConfiguration) {
    this._config = value;
    this.selectedElementTypes = value.heTypeSettings.selectedIds ?? [];
    this.defaultElementTypes = value.heTypeSettings.defaultValue ?? [];
    this.isNEFilter = value.heTypeSettings.isNEFilter;
    this.elementTypeFieldName = value.heTypeSettings.fieldName;

    // set old values
    this.oldFamilyId = this.objectHelperService.clone(
      value.familySettings?.selectedId ?? value.familySettings?.defaultValue ?? []
    );

    this.oldHElementTypes = this.objectHelperService.clone(
      value.heTypeSettings?.selectedIds ?? value.heTypeSettings?.defaultValue ?? []
    );

    // Update filter item settings with the specified in the config.
    Object.assign(this.hierarchyFilterItemSettings, value.heTypeSettings);
    Object.assign(this.familyFilterItemSettings, value.familySettings);
    Object.assign(this.hierarchyTreeItemSettings, value.treeSettings);

    this.buildFiltersOverrides();
    this.buildKeysToComplete();

    this.adaptersReady = true;
  }
  public get config(): HierarchyElementsSelectionFilterConfiguration {
    return this._config;
  }
  private _config: HierarchyElementsSelectionFilterConfiguration;
  @Input() hfFieldName = 'hierarchyFamilyId';
  @Input() gridSettings: IGridSettings;

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableSelectAll: true,
    disableApplyFilters: false,
  };
  bf1Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-1-label`,
  };
  bf2Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-2-label`,
  };

  hierarchyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    storageLocation: this.config?.heTypeSettings?.storageLocation,
  });

  familyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
    storageLocation: this.config?.familySettings?.storageLocation,
  });

  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
    storageLocation: this.config?.treeSettings?.storageLocation,
  });

  selectedElementTypes: string[] | number[] = [];
  defaultElementTypes: string[] | number[] = [];
  isNEFilter: boolean;
  elementTypeFieldName: string;
  rebuildTreeFilter$ = new Subject<void>();
  treeSettings: ITreeSettings = null;
  hfSelectedId: string;
  treeFamilyId: string;
  loaded = false;
  useInitialTreeValues = true;
  treeFilterReadyToDisplay = false;
  filtersOverrides: FilterConditionalOverride[];
  oldFamilyId: string;
  oldHElementTypes: string[] | number[];
  private _hierarchyElementTypesLevelList: IHierarchyElementTypeDto[];
  private _disabledhierarchyElementTypes: string[];

  constructor(
    private adapterService: FiltersAdapterService,
    private treeSettingsService: TreeSettingsService,
    private arrayHelper: ArrayHelperService,
    private objectHelperService: ObjectHelperService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  ngOnInit(): void {
    this.setFiltersPersistencyArea();
    this.initHierarchyFamily();
    this.loaded = true;
  }

  protected setFiltersPersistencyArea() {
    this.bf1Settings.persistencyArea = this.config.persistencyArea;
    this.bf2Settings.persistencyArea = this.config.persistencyArea;
  }

  initHierarchyFamily(): void {
    if (this.config.familySettings.selectedId) {
      this.hfSelectedId = this.config.familySettings.selectedId;
    }
  }

  private rebuildTree(selectedElementTypes: string[]): void {
    this.treeFamilyId = this.hfSelectedId;
    this.calculateDisabledNodes(selectedElementTypes);
    this.buildTreeSettings();
    this.treeFilterReadyToDisplay = true;
    this.rebuildTreeFilter$.next();
    // this.updateTreeFilter(this.getDefaultTreeMapFilter());
  }

  private buildDefaultTreeFilter(): BasicFilter {
    let defaultValue: string[] = [];
    if (this.useInitialTreeValues) {
      defaultValue = this.config?.treeSettings.selectedIds ?? [];

      this.useInitialTreeValues = false;
    }
    this.config.treeSettings.selectedIds = defaultValue;

    const result = new BasicFilter(this.config.treeSettings.fieldName, defaultValue);

    return result;
  }

  calculateDisabledNodes(selectedElements: any[]) {
    if (selectedElements?.length > 0) {
      const index = this._hierarchyElementTypesLevelList
        .map((x) => x.hierarchyElementTypeId)
        .indexOf(selectedElements[0]);
      this._disabledhierarchyElementTypes = this._hierarchyElementTypesLevelList
        .map((x) => x.hierarchyElementTypeName)
        .slice(index + 1);
    }
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const { familySettings, treeSettings, heTypeSettings } = this.config;
    const adapters = [
      new ElementTypeFAdapter({
        dataBindingField: 'hierarchyElementTypeFilter',
        elementTypeFieldName: heTypeSettings.fieldName,
        neheFilterSettings: heTypeSettings,
      }),
      new HierarchyElementPathFAdapter({
        dataBindingField: 'hierarchyElementsFilter',
        hierarchyElementFamilyFieldName: familySettings.fieldName,
        hierarchyElementIdFieldName: treeSettings.fieldName,
        familyId: this.treeFamilyId,
      }),
    ];
    return adapters;
  };

  buildFiltersOverrides(): void {
    const elementTypeFieldName = this.config.heTypeSettings.fieldName;
    const treeFieldName = this.config.treeSettings.fieldName;

    this.filtersOverrides = [
      new FilterConditionalOverride({
        onChangedFieldName: elementTypeFieldName,
        targetFieldName: treeFieldName,
        targetIsFromPersistencyFn: (payload: FiltersPayload): boolean => {
          const currentHEIds = payload.data.get(treeFieldName)?.value?.map((item) => item.value);
          const persistedHEIds = this.config?.treeSettings.selectedIds ?? [];
          const areSame = this.arrayHelper.areSame<string>(currentHEIds, persistedHEIds);
          return areSame;
        },
        overridePayloadFn: (
          payload: FiltersPayload,
          eventType: 'apply' | 'filter'
        ): FiltersPayload => {
          // If the event is filter, the elementTypeFilter is initializing and
          // the treeFilter does not have a persistency value, it must be initialized.
          if (
            eventType === 'apply' ||
            (eventType === 'filter' &&
              payload.status.get(elementTypeFieldName) === FiltersPayloadStatus.Initial)
          ) {
            const elementTypeFilter = payload.data.get(elementTypeFieldName) as BasicFilter;
            const selectedElements = elementTypeFilter.value.map((item) => item.value);
            // The tree reflects the changes specified by the elementType filter.
            if (eventType === 'apply') {
              this.rebuildTree(selectedElements);
              // Everytime the elementType changes, the tree filter is reset inside the payload.
              const defaultTreeFilter = this.buildDefaultTreeFilter();
              const newPayload = payload.clone();
              newPayload.addField(treeFieldName, defaultTreeFilter);
              return newPayload;
            }
          }
          return payload;
        },
      }),
    ];
  }

  buildKeysToComplete(): void {
    this.keysToComplete = [this.hfFieldName, this.config.heTypeSettings.fieldName];
  }

  /**
   * Only used to save the selected family id.
   * Will allways contain an array of one element, as it is single select.
   */
  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName = this.config.familySettings.fieldName;
    const defaultId = this.config.familySettings.selectedId;
    const newFamilyId = this.adapterService.getFamilyId(filters, familyFieldName, defaultId);
    if (!this.hfSelectedId || this.hfSelectedId !== newFamilyId) {
      this.hfSelectedId = newFamilyId;
    }

    // Assign the selected family if to the tree at least the first time, so the tree can be loaded.
    // If the tree is not loaded, the container cannot coordinate its events.
    if (!this.treeFamilyId) {
      this.rebuildTree([]);
    }
  }

  buildTreeSettings(): void {
    this.treeSettingsService
      .buildTreeSettings({
        unselectableTypes: this._disabledhierarchyElementTypes,
      })
      .subscribe((settings) => (this.treeSettings = settings));
  }

  onhierarchyLevelsListChanged(hierarchyLevelList: IHierarchyElementTypeDto[]) {
    this._hierarchyElementTypesLevelList = hierarchyLevelList;
  }

  // This is necessary to persist the tree values
  onTreeFilterReady() {
    if (this.mustPersistFilters) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
    }
  }

  additionalAutoloadCheck(results: FilterAdapterResult): boolean {
    const familyFieldName = this.config.familySettings.fieldName;
    const familyChanged = results.changedFields.get(familyFieldName);
    const familyStatus = results.payload.status.get(familyFieldName);
    // Avoid autoload if the family changed.
    const avoid = familyStatus !== FiltersPayloadStatus.Initial && familyChanged;
    return !avoid;
  }

  onApplyFamilyHEFilter($event) {
    if (
      this.oldFamilyId !== this.hfSelectedId ||
      !this.objectHelperService.deepEqual(this.oldHElementTypes, this.selectedElementTypes)
    ) {
      this.oldFamilyId = this.objectHelperService.clone(this.hfSelectedId);
      this.oldHElementTypes = this.objectHelperService.clone(this.selectedElementTypes);
      this.rebuildTree([]);
    }
  }
}
