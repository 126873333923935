import { Injectable } from '@angular/core';
import { ApplicationAttributes } from '@common-modules/shared/constants/application-constants';
import { UserNamePipe } from '@common-modules/shared/pipes/user-name.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { asEnumerable } from 'linq-es2015';
import { Observable, forkJoin, isObservable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { GridColumnTypes } from '../../wlm-grid/grid-column-types-enum';
import { IGridSettings } from '../constants/grid.constants';
import { ColumnEnumsService } from '../core/grid/column-enums.service';
import { TitleMethodsService } from '../core/grid/title-methods.service';
import { DateFormats } from '../localization/date-formats.enum';
import { LocalizationHelperService } from '../localization/localization-helper.service';
import { IAlgorithmDto } from '../model/algorithm/algorithm.dto';
import { GridColumnSetting } from '../model/grid/grid-column-setting';
import { SimpleColumn } from '../model/shared/simple-column';
import { UnitTypeConversionViewDto } from '../model/uom/unit-type-conversion-view.dto';
import { GlobalsService } from '../services/globals.service';
import { UoMService } from '../uom/uom.service';
import { GridHelperService } from './grid-helper.service';

@UntilDestroy()
@Injectable()
export class GridColumnHelperService {
  defaultBooleanMapMethodName = 'getBooleanColumnDefaultMapping';
  private _enumerableColumnMappings: Map<string, Map<any, string>>;
  private _units: UnitTypeConversionViewDto[];
  private _algorithms: IAlgorithmDto[];
  private _rowData: any;
  private _columns: GridColumnSetting[];
  private _defaultPositions = 3;
  private _removeHierarchyColumns: boolean;
  private _currencySymbol: string;

  processedRows: Observable<SimpleColumn[]>[];

  // Decission taken due to issue #23385
  private readonly _avoidRemovingHierarchyColumns = true;

  constructor(
    private _localizationService: LocalizationHelperService,
    private _columnEnumService: ColumnEnumsService,
    private _globalService: GlobalsService,
    private _uomService: UoMService,
    private _userNamePipe: UserNamePipe,
    private _gridHelperService: GridHelperService,
    private _titleMethodsService: TitleMethodsService
  ) {
    this.setCurrencySymbol();
  }

  setCurrencySymbol = () => {
    this._globalService
      .getApplicationAttributesById(ApplicationAttributes.Currency)
      .subscribe((attribute) => {
        this._currencySymbol = attribute.attributeValue;
      });
  };

  public getColumnTranslatedTitle(gridColumnSetting: GridColumnSetting): Observable<string> {
    const currentLocale = this._localizationService.currentLocale;

    let translatedTitle = gridColumnSetting?.localeTitle
      ? gridColumnSetting?.localeTitle[currentLocale]
      : null;

    translatedTitle = translatedTitle ?? gridColumnSetting.title;

    if (!gridColumnSetting?.columnTitleSettings) {
      return of(translatedTitle);
    } else {
      const { hasToBeCalculated, hasToBeInterpolated, titleMethod } =
        gridColumnSetting?.columnTitleSettings;

      if (hasToBeCalculated && titleMethod) {
        return this._titleMethodsService.getProcessedTitleValue(titleMethod).pipe(
          switchMap((processedTitle) => {
            if (!processedTitle) {
              return of(translatedTitle);
            }

            if (hasToBeInterpolated) {
              var res = this.interpolateString(translatedTitle, processedTitle);

              return of(res);
            }

            return of(processedTitle[0]);
          })
        );
      } else {
        return of(translatedTitle);
      }
    }
  }

  private interpolateString(
    inputString: string,
    interpolationValues: { [key: string]: string }
  ): string {
    let resultString = inputString;

    for (const key in interpolationValues) {
      if (interpolationValues.hasOwnProperty(key)) {
        var expression = '{' + key + '}';
        resultString = resultString.replace(expression, interpolationValues[key]);
      }
    }

    return resultString;
  }

  public getColumnEnumerableValue(columnValue: any, enumValues: Map<any, string>): string {
    const value = enumValues.get(columnValue);
    if (value) {
      return value;
    } else if (enumValues.has(columnValue?.toString())) {
      return enumValues.get(columnValue?.toString());
    } else {
      return columnValue;
    }
  }

  getColumnImageValue(columnValue: any, enumValues: Map<any, string>): string {
    const value = enumValues.get(columnValue);
    if (value) {
      return value['text'];
    }

    return columnValue;
  }

  public getDateValue(
    columnValue: any,
    columnFormat: string,
    valueDefinedAsUtc = true
  ): string | Date {
    return this._localizationService.getLocalizedDateFromApi(
      columnValue,
      columnFormat,
      valueDefinedAsUtc
    );
  }

  public processRowDataValuesBulk(
    allRowsData: any[],
    columns: GridColumnSetting[],
    units: UnitTypeConversionViewDto[],
    algorithms: IAlgorithmDto[],
    enumerableColumnMappings: Map<string, Map<any, string>>
  ) {
    this._units = units;
    this._algorithms = algorithms;
    this._columns = columns;
    this._enumerableColumnMappings = enumerableColumnMappings;

    this.processedRows = [];

    this._removeHierarchyColumns = true;
    allRowsData.forEach((row) => {
      this._rowData = row;
      if (this._removeHierarchyColumns) {
        this._columns = this.clearHierarchyColumns(columns);
        this._removeHierarchyColumns = false;
      }

      this.processedRows.push(this.processColumns(this._columns));
    });

    return this.processedRows;
  }

  // Given a grid rowdata, processees the columns and return the values
  // just like they are displayed in the grid (uom values converted, etc..)
  public processRowDataValues(
    rowData: any,
    columns: GridColumnSetting[]
  ): Observable<SimpleColumn[]> {
    this._rowData = rowData;
    const columnEnums$ = this.getExportableEnums(columns);
    const units$ = this._uomService.getAllDefaultUoM();
    const algorithms$ = this._globalService.getAlgorithms();
    this._columns = this.clearHierarchyColumns(columns);

    // The pipe(take(1)) is to force the observable to finish
    return forkJoin([
      units$.pipe(take(1)),
      algorithms$.pipe(take(1)),
      columnEnums$.pipe(take(1)),
    ]).pipe(
      untilDestroyed(this),
      switchMap(([units, algorithms, columnEnums]) => {
        this._units = units;
        this._algorithms = algorithms;
        this._enumerableColumnMappings = columnEnums;
        return this.processColumns(this._columns);
      })
    );
  }

  public getExportableEnums(columns: GridColumnSetting[]) {
    let enumColumnsKeys = asEnumerable(columns)
      .Where((x) => x.enumerableKey?.length > 0)
      .Select((x) => x.enumerableKey)
      .Distinct()
      .ToArray();

    const imageColumnsKeys = asEnumerable(columns)
      .Where((x) => x.getImageMethodName?.length > 0)
      .Select((x) => x.getImageMethodName)
      .Distinct()
      .ToArray();
    enumColumnsKeys = enumColumnsKeys.concat(imageColumnsKeys);

    // Added to get the mapping for default boolean columns
    enumColumnsKeys.push('getBooleanColumnDefaultMapping');

    const columnEnums$ = this._columnEnumService.getEnums(enumColumnsKeys);
    return columnEnums$;
  }

  clearHierarchyColumns(columns: GridColumnSetting[]) {
    if (this._avoidRemovingHierarchyColumns) {
      return [...columns];
    }

    const anyHierachyColumn = columns.some((f) => f.type === GridColumnTypes.Hierarchy);
    if (anyHierachyColumn) {
      const selectedFamily = this._rowData['hierarchyFamilyId'];
      columns = this._gridHelperService.getVisibleColumnsByFamily(selectedFamily, columns);
      columns = columns.filter(
        (f) =>
          f.type !== GridColumnTypes.Hierarchy ||
          (f.type === GridColumnTypes.Hierarchy && f.uniqueName.includes(selectedFamily))
      );
    }

    return columns;
  }

  getItemId = (column: GridColumnSetting) => column.uniqueName ?? column.field;

  getColumnSettingsByField = (gridSettings: IGridSettings, field: string) =>
    gridSettings?.gridColumnSettings?.find((c) => c.field === field);

  private processColumns(columns: GridColumnSetting[]) {
    const columnsProcesed: Observable<SimpleColumn>[] = [];

    columns.forEach((column) => {
      switch (column.type) {
        case GridColumnTypes.Uom:
        case GridColumnTypes.Maxmin:
          columnsProcesed.push(this.getUoMColumn(column));
          break;
        case GridColumnTypes.Custom:
        case GridColumnTypes.Enumerable:
        case GridColumnTypes.Image:
        case GridColumnTypes.AlarmSeverity:
          columnsProcesed.push(this.getSimpleColumn(column, this.getEnumColumnValue));
          break;
        case GridColumnTypes.Boolean:
          columnsProcesed.push(this.getSimpleColumn(column, this.getBooleanColumnValue));
          break;
        case GridColumnTypes.Date:
          columnsProcesed.push(this.getSimpleColumn(column, this.getDateColumnValue));
          break;
        case GridColumnTypes.User:
          columnsProcesed.push(this.getSimpleColumn(column, this.getUserColumnValue));
          break;
        default:
          columnsProcesed.push(this.getSimpleColumn(column, this.getColumnValue));
          break;
      }
    });

    return forkJoin(columnsProcesed);
  }

  private getSimpleColumn(
    column: GridColumnSetting,
    valueFunc: (column: GridColumnSetting) => any
  ): Observable<SimpleColumn> {
    const value = valueFunc(column);
    const value$: Observable<any> = isObservable(value) ? (value as any) : of(value);
    return forkJoin({
      resolvedValue: value$,
      resolvedColumnTitle: this.getColumnTitle(column),
    }).pipe(
      map(({ resolvedValue, resolvedColumnTitle }) => {
        const simpleColumn = new SimpleColumn({
          columnId: column.field,
          columnTitle: resolvedColumnTitle,
          value: resolvedValue,
        });
        return simpleColumn;
      })
    );
  }

  private getEnumColumnValue = (column: GridColumnSetting): string => {
    const value = this.getColumnValue(column);
    const methodName = column?.enumerableKey ?? column?.getImageMethodName;
    const enumerableKeys = this._enumerableColumnMappings.get(methodName);

    if (!enumerableKeys) {
      return value;
    }

    let result = this.getColumnEnumerableValue(value, enumerableKeys);
    if (column.type === GridColumnTypes.Image) {
      result = this.getColumnImageValue(value, enumerableKeys);
    }

    return result;
  };

  private getBooleanColumnValue = (column: GridColumnSetting): string => {
    const value = this.getColumnValue(column);
    const enumerableMethod = column?.enumerableKey ?? this.defaultBooleanMapMethodName;
    const enumerableKeys = this._enumerableColumnMappings.get(enumerableMethod);

    if (!enumerableKeys) {
      return value;
    }

    return this.getColumnEnumerableValue(value, enumerableKeys);
  };

  private getDateColumnValue = (column: GridColumnSetting): string | Date => {
    const value = this.getColumnValue(column);
    const format = column.format ? column.format : DateFormats.Date;
    return this.getDateValue(value, format);
  };

  private getUserColumnValue = (column: GridColumnSetting): Observable<string> => {
    const value = this.getColumnValue(column);
    return this._userNamePipe.transform(value);
  };

  private getColumnTitle(column: GridColumnSetting): Observable<string> {
    return this.getColumnTranslatedTitle(column).pipe(
      map((translatedTitle) => {
        if (column.type === GridColumnTypes.Currency) {
          const unitFormated = this.getUnitFormated(column);
          translatedTitle = `${translatedTitle}${unitFormated}`;
        }

        return translatedTitle;
      })
    );
  }

  private getColumnValue = (column: GridColumnSetting): any => {
    let value = this.getColumnValueByField(column.field);
    const hasValue = value !== null && value !== undefined;
    if (hasValue && column?.format && column.type === GridColumnTypes.Numeric) {
      const positions = column.format.substring(1, column.format.length);
      value = value?.toFixed(positions);
    }

    return value;
  };

  private getColumnValueByField = (field: string) => {
    if (!field) {
      return '';
    }

    return this._rowData[field];
  };

  private getUoMColumn = (columnSetting: GridColumnSetting): Observable<SimpleColumn> => {
    let timeAggregationId = columnSetting?.timeAggregationId;
    let dimensionTypeId = columnSetting?.dimensionTypeId;

    if (columnSetting.algorithmShortName) {
      const algorithm = asEnumerable(this._algorithms).FirstOrDefault(
        (x) => columnSetting.algorithmShortName === x.algorithmShortName
      );

      dimensionTypeId = algorithm?.dimensionTypeId;
      timeAggregationId = algorithm?.timeAggregationId;
    }

    const heTypeId = this.getColumnValueByField(columnSetting?.uomHierarchyElementTypeIdField);

    const uom = this.getUoMByHETypeAndTimeAggregationAndDimensionType(
      heTypeId,
      dimensionTypeId,
      timeAggregationId
    );

    const simpleColumn = new SimpleColumn({
      columnId: columnSetting.field,
      columnTitle: this.getUomColumnTitle(columnSetting, uom),
      value: this.getUomColumnValue(columnSetting, uom),
      valueAddon: uom ? this.getUnitFormated(columnSetting, uom?.unitTypeToDescription) : null,
    });

    return of(simpleColumn);
  };

  private getUoMByHETypeAndTimeAggregationAndDimensionType(
    hierarchyElementTypeId: string,
    dimensionTypeId: number,
    timeAggregationId: number
  ) {
    return asEnumerable(this._units).FirstOrDefault(
      (x) =>
        x.hierarchyElementTypeId === hierarchyElementTypeId &&
        x.dimensionTypeId === dimensionTypeId &&
        x.timeAggregationId === timeAggregationId
    );
  }

  private getUomColumnTitle(column: GridColumnSetting, uom: UnitTypeConversionViewDto) {
    let unitName = '';
    if (uom) {
      unitName = this.getUnitFormated(column, uom?.unitTypeToDescription);
    }
    return `${this.getColumnTitle(column)}${unitName}`;
  }

  getUnitFormated(column: GridColumnSetting, unitTypeToDescription: string = null): string {
    const format = column?.unitFormat ?? `[{0}]`;
    let unitFormated = ` ${format}`;

    if (unitTypeToDescription) {
      unitFormated = unitFormated.replace('{0}', unitTypeToDescription);
    }

    const isCurrencyColumn = column.type === GridColumnTypes.Currency;
    if (column.isCurrencyFormat || isCurrencyColumn) {
      unitFormated = unitFormated.replace('{1}', this._currencySymbol);
    }
    return unitFormated;
  }

  private getUomColumnValue(column: GridColumnSetting, uom: UnitTypeConversionViewDto) {
    const value = this.getColumnValueByField(column.field);
    if (value === undefined || value === null) {
      return null;
    }

    const conversionFactor = uom ? uom.conversionFactor : 1;

    const valueConverted = value * conversionFactor;
    if (column?.uomDecimalPositions || column.type === GridColumnTypes.Maxmin) {
      const positions = column?.uomDecimalPositions ?? this._defaultPositions;
      return valueConverted.toFixed(positions);
    }

    return valueConverted;
  }
}
