import { GenericSelector } from '@common-modules/redux/models/generic-selector';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { DefaultParamsSelectorTypes } from './default-params.selector-types';

export abstract class DefaultParamsSelector extends GenericSelector {
  area = StateAreas.DefaultParams;
}

export class AppliedDefaultParamsSelector extends DefaultParamsSelector {
  type = DefaultParamsSelectorTypes.GetAppliedDefaultParams;
}
