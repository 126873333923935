// prettier-ignore
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IGisLayer } from '@common-modules/dependencies/map/gis-layer';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { UntilDestroy } from '@ngneat/until-destroy';

const COMPONENT_SELECTOR = 'wlm-map-filter-item-select';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-filter-item-select.component.html',
  styleUrls: ['./map-filter-item-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapFilterItemSelectComponent implements OnInit {
  @Input() title: string;
  @Input() layers: IGisLayer[];
  @Input() showIcon: boolean;
  @Input() selectedLayers: { [id: string]: boolean };
  @Input() displayed: boolean;

  @Output() select = new EventEmitter<[IGisLayer, boolean]>();

  private readonly _pointViewBox = '0 0 64 64';
  private readonly _transformClass = 'translate(7, 7) scale(1.55)';
  private readonly _pointStrikeWidth = 8;
  private readonly _leakLayersPrefix = '777';
  private readonly _activityLayersPrefix = '888';

  T_SCOPE = `${AppModules.Map}.${COMPONENT_SELECTOR}`;
  iconSize = '20';
  labelPosition = 'before';

  constructor() {}

  ngOnInit(): void {}

  onSelect(event: MatCheckboxChange, layer: IGisLayer, parentLayer?: IGisLayer): void {
    this.select.emit([layer, event.checked]);

    if (parentLayer) {
      const allSelected = this.hasAllSublayersSelected(parentLayer);

      if (allSelected) {
        this.select.emit([parentLayer, true]);
      }
    }
  }

  hasAllSublayersSelected(layer: IGisLayer): boolean {
    if (!layer.sublayers?.length) {
      return false;
    }

    return layer.sublayers
      .map((sub) => sub.gisLayerId)
      .every((id) => this.selectedLayers[id] === true);
  }

  hasAnySublayerSelected(layer: IGisLayer): boolean {
    const anySelected = layer.sublayers
      .map((sub) => sub.gisLayerId)
      .some((id) => this.selectedLayers[id] === true);

    return anySelected && !this.hasAllSublayersSelected(layer);
  }

  getViewBox(layer: IGisLayer): string {
    if (!this.isPointLayer(layer.gisLayerId)) {
      return '';
    }

    return this._pointViewBox;
  }

  getTransformClass(layer: IGisLayer): string {
    if (!this.isPointLayer(layer.gisLayerId)) {
      return '';
    }

    return this._transformClass;
  }

  getStrokeWidth(layer: IGisLayer): number {
    if (!this.isPointLayer(layer.gisLayerId) || !layer.iconStrokeColor) {
      return 0;
    }

    return layer.iconColor !== layer.iconStrokeColor ? this._pointStrikeWidth : 0;
  }

  private isPointLayer(gisLayerId: number) {
    const layerId = gisLayerId.toString();

    return (
      layerId.startsWith(this._leakLayersPrefix) || layerId.startsWith(this._activityLayersPrefix)
    );
  }
}
