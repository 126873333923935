import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { OpenActivitiesFilter } from './open-activities-filter';

const COMPONENT_SELECTOR = 'wlm-activity-open-filter';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './activity-open-filter.component.html',
  styleUrls: ['./activity-open-filter.component.scss'],
})
export class ActivityOpenFilterComponent implements OnInit {
  @Input() openActivityFieldName: string;
  @Input() filters: IFilter[];
  @Output() openActivityChanged = new EventEmitter<OpenActivitiesFilter>();

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  isOpenActivitiesActive: boolean;
  constructor() {}

  ngOnInit(): void {
    this.setDefaultValue();
    this.emitActivitiesOpenFilterValue(this.isOpenActivitiesActive);
  }

  openActivitiesValuesChanged(e: MatSlideToggleChange) {
    this.emitActivitiesOpenFilterValue(e.checked);
  }

  emitActivitiesOpenFilterValue(isOpen: boolean) {
    const openActivitiesFilter = new OpenActivitiesFilter(isOpen, this.openActivityFieldName);
    this.openActivityChanged.emit(openActivitiesFilter);
  }

  setDefaultValue() {
    if (this.filters?.length) {
      const openFilter = this.filters.filter(
        (x) => Object.getPrototypeOf(x) === OpenActivitiesFilter.prototype
      );
      if (openFilter.length) {
        this.isOpenActivitiesActive = (openFilter[0] as OpenActivitiesFilter).openActivitiesOnly;
      } else {
        this.isOpenActivitiesActive = false;
      }
    }
  }
}
