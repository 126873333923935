import { Component, Input, OnInit } from '@angular/core';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LargeUserAssignedCardSettings } from './large-user-assigned-card-settings';

const COMPONENT_SELECTOR = 'wlm-large-user-assigned-card';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './large-user-assigned-card.component.html',
  styleUrls: ['./large-user-assigned-card.component.scss'],
})
export class LargeUserAssignedCardComponent implements OnInit {
  private _item: any;
  public get item(): any {
    return this._item;
  }
  @Input() public set item(value: any) {
    this._item = value;
  }

  private _settings: LargeUserAssignedCardSettings;
  public get settings(): LargeUserAssignedCardSettings {
    return this._settings;
  }

  @Input() public set settings(value: LargeUserAssignedCardSettings) {
    this._settings = value;
  }

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  constructor() {}

  ngOnInit(): void {}

  delete() {
    this.settings.deleteCallback(this.item);
  }

  edit() {
    this.settings.editCallback(this.item);
  }
}
