import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { WLMGridModule } from '@common-modules/wlm-grid/wlm-grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { DvAlgorithmNavigationPopupComponent } from './dv-algorithm-navigation-popup/dv-algorithm-navigation-popup.component';
import { ZoneAlgorithmGridComponent } from './zone-algorithm-grid/zone-algorithm-grid.component';

@NgModule({
  declarations: [DvAlgorithmNavigationPopupComponent, ZoneAlgorithmGridComponent],
  imports: [CommonModule, TranslateModule, WlmSharedModule, WLMGridModule],
  exports: [DvAlgorithmNavigationPopupComponent],
})
export class AlgorithmsModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AlgorithmsModule.injector = injector;
  }
}
