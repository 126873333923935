import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';

export abstract class BaseBIDynamicWidgetComponent extends BaseDynamicWidgetComponent {
  innerFilters: any;
  lockFilters: boolean;
  paramsTitle: string;
  baseTitle: string;

  setTitle(obj: { title?: string; baseTitle?: string }): void {
    if (obj) {
      this.paramsTitle = obj.title;
      this.baseTitle = obj.baseTitle;
    }
  }
}
