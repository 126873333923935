import { Injectable, Injector } from '@angular/core';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ALCActivityDto } from '../../shared/model/alc/alc-activity.dto';
import { ICloseActivities } from '../../shared/model/alc/close-activities.data';

const url = '/api/alc/activity';

@Injectable()
export class ActivitiesGridService extends GridODataService<ALCActivityDto> {
  constructor(injector: Injector) {
    super(injector, url);
  }

  protected mapResponse(response: PagedResultDto<ALCActivityDto>): PagedResultDto<ALCActivityDto> {
    return response;
  }

  public closeActivities(closeActivities: ICloseActivities): Observable<boolean> {
    return this.httpCacheClient
      .post(`${this.baseUrl}${url}/close`, closeActivities)
      .pipe(map(() => true));
  }
}
