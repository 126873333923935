import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { DynamicGridSettings } from '@common-modules/shared/model/grid/dynamic-grid-settings';
import { UserDto } from '@common-modules/shared/model/roles/user.dto';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { finalize } from 'rxjs/operators';

import { BiTemplateGridDto } from '../../models/bi-template-grid.dto';
import { BiTemplateDto } from '../../models/bi-template.dto';
import { BiService } from '../../services/bi.service';

const COMPONENT_SELECTOR = 'wlm-bi-manage-popup';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './bi-manage-popup.component.html',
  styleUrls: ['./bi-manage-popup.component.scss'],
})
export class BiManagePopupComponent implements OnInit {
  isLoading = true;
  hasSaved = false;
  dynamicGridSettings: DynamicGridSettings;
  gridFiltersForBinding = new DataBindingFilters();
  grid: GenericGridComponent;
  selectedItem: BiTemplateGridDto;
  isTemplateOwner: boolean;
  readonly T_SCOPE = `${AppModules.BI}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;

  user: UserDto;
  currentDashboardId?: number;
  refreshCurrentDashboard = false;

  private readonly _gridSettingsName = 'BiManageTemplate';

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private _dialogRef: MatDialogRef<BiManagePopupComponent>,
    private _biService: BiService,
    private _dialogsService: DialogService,
    private _authenticationService: AuthenticationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _globals: GlobalsService
  ) {
    this.currentDashboardId = +data.currentDashboardId;
    this.dynamicGridSettings = this.buildGridSettings();
  }

  ngOnInit(): void {
    this._globals.getCurrentUser().subscribe((user) => {
      this.user = user;
    });
  }

  onLoadSelected(): void {}

  onClose(data): void {
    this._dialogRef.close({
      selectedLayout: data,
      refreshCurrentDashboard: this.refreshCurrentDashboard,
    });
  }

  onGridLoaded(grid: GenericGridComponent): void {
    this.grid = grid;
    this.isLoading = false;
    this._changeDetectorRef.detectChanges();
  }

  onSelectedItem(item: BiTemplateGridDto): void {
    this.selectedItem = item;
    if (this.selectedItem) {
      this.isTemplateOwner = this.selectedItem.owner === this.user?.userCode;
    } else {
      this.isTemplateOwner = false;
    }
  }

  onToggleSystemTemplate(): void {
    this.updateTemplate(() => ({
      isSystemTemplate: !this.selectedItem.isSystemTemplate,
    }));
  }

  onToggleSystemDefault(): void {
    this.updateSystemTemplate(() => ({
      isSystemDefault: !this.selectedItem.isSystemDefault,
    }));
  }

  onToggleDashboardDefault(): void {
    this.updateTemplate(() => ({
      isDashboardDefault: !this.selectedItem.isDashboardDefault,
    }));
  }

  onLoadTemplate(): void {
    if (!this.selectedItem) {
      return;
    }
    this.isLoading = true;
    this._biService
      .getTemplate(this.selectedItem.bidashboardTemplateId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (layout) => {
          this.onClose(layout);
        },
        error: (error) => {
          this.showDialogResult('messages.loading-error', 'error');
        },
      });
  }

  onDeleteAnyTemplate(): void {
    if (!this.selectedItem) {
      return;
    }
    this.isLoading = true;

    this._biService
      .deleteTemplate(this.selectedItem.bidashboardTemplateId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          if (this.currentDashboardId === this.selectedItem.bidashboardTemplateId) {
            this.refreshCurrentDashboard = true;
          }

          this._dialogsService.showEntityActionSnackBar('delete', 'dashboard');
          this.grid.reloadGrid();
          this.selectedItem = null;
        },
        error: (error) => {
          this.showDialogResult('messages.delete-error', 'error');
        },
      });
  }

  onDeleteMyTemplates(): void {
    if (!this.isTemplateOwner) {
      return;
    }
    this.onDeleteAnyTemplate();
  }

  private updateTemplate(getDataToUpdate: () => Partial<BiTemplateDto>): void {
    if (!this.selectedItem) {
      return;
    }
    const data = getDataToUpdate();
    this.isLoading = true;
    this._biService
      .updateTemplate(this.selectedItem.bidashboardTemplateId, data)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.showDialogResult('messages.save-success', 'success');
          this.grid.reloadGrid();
          this.hasSaved = true;
        },
        error: (error) => {
          this.showDialogResult('messages.save-error', 'error');
        },
      });
  }

  private updateSystemTemplate(getDataToUpdate: () => Partial<BiTemplateDto>): void {
    if (!this.selectedItem) {
      return;
    }
    const data = getDataToUpdate();
    this.isLoading = true;
    this._biService
      .updateSystemTemplate(this.selectedItem.bidashboardTemplateId, data)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.showDialogResult('messages.save-success', 'success');
          this.grid.reloadGrid();
          this.hasSaved = true;
        },
        error: (error) => {
          this.showDialogResult('messages.save-error', 'error');
        },
      });
  }

  private buildGridSettings(): DynamicGridSettings {
    const settings = new DynamicGridSettings({
      gridSettingName: this._gridSettingsName,
      type: 'generic',
      disableAutoLoad: false,
      usePersistence: false,
    });
    return settings;
  }

  private showDialogResult(messageKey: string, icon: 'success' | 'error') {
    const errorMessageKey = `${this.T_SCOPE}.${messageKey}`;
    let dialogSettings = new WlmDialogSettings({ translateKey: errorMessageKey, icon });

    this._dialogsService.showTranslatedMessageInSnackBar(dialogSettings);
  }
}
