import { Injectable, Injector } from '@angular/core';
import { ALCPrioritisationView } from '@common-modules/dependencies/alc/prioritisation-view.dto';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { IDynamicSummaryApi } from '@common-modules/shared/model/dynamic-summary/dynamic-summary-api';
import { SummaryLatestCalculationDayDto } from '@common-modules/shared/model/dynamic-summary/summary-latest-calculation-day.dto';
import {
  DynamicPageResultDto,
  PagedResultDto,
} from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { Observable, map } from 'rxjs';

@Injectable()
export class PrioritisationGridService
  extends GridODataService<ALCPrioritisationView>
  implements IDynamicSummaryApi
{
  constructor(injector: Injector, private readonly _dateHelperService: DateHelperService) {
    super(injector, '/api/summary/dynamic/ALC');
  }

  protected mapResponse(
    dynamicResponse: DynamicPageResultDto<ALCPrioritisationView>
  ): PagedResultDto<ALCPrioritisationView> {
    const responseItems = dynamicResponse.items.map((x) => x.properties);
    const pageResponse: PagedResultDto<ALCPrioritisationView> = {
      totalCount: dynamicResponse.totalCount,
      items: responseItems,
    };
    return pageResponse;
  }

  getLatestCalculationDay(): Observable<Date> {
    return this.httpCacheClient
      .get<SummaryLatestCalculationDayDto>(`${this.baseUrl}${this.api}/latestcalculationday`, {
        avoid: true,
      })
      .pipe(map((result) => this._dateHelperService.fromApiFormat(result.latestCalculationDay)));
  }
}
