import { TimeAggregationEnum } from '@common-modules/shared/model/algorithm/time-aggregation.enum';

function showInUtc(algorithmShortName: string, timeAggregationId: number) {
  const algorithmsToConvert = [
    'MNF',
    'DAZNP',
    'DALCMNF',
    'MNFByB',
    'DAZNPALC',
    'MNFNLU',
    'DMNFNMIFDMA',
  ];

  return (
    timeAggregationId !== TimeAggregationEnum.Base &&
    timeAggregationId !== TimeAggregationEnum.Hourly &&
    !algorithmsToConvert.includes(algorithmShortName)
  );
}

function showAlwaysInTooltip(timeAggregationId: number) {
  return timeAggregationId === TimeAggregationEnum.Daily;
}

export const chartUtilsHelper = {
  showInUtc: showInUtc,
  showAlwaysInTooltip,
};
