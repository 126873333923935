import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { DynamicLayoutComponent } from '@common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutSettingsLoadOptions } from '@common-modules/dynamic-layout/models/dynamic-layout-settings-load-options';
import { DynamicSettings } from '@common-modules/dynamic-layout/models/dynamic-settings';
import { DynamicSettingsSave } from '@common-modules/dynamic-layout/models/dynamic-settings-save';
import { LayoutNodeTypes } from '@common-modules/dynamic-layout/models/layout-node-types';
import { DynamicLayoutService } from '@common-modules/dynamic-layout/services/dynamic-layout.service';
import {
  ResetLayoutAction,
  ResetWidgetManagerStateAction,
  SetLayoutKeysAction,
  SetLayoutToLoadAction,
} from '@common-modules/dynamic-layout/state/widget-manager/widget-manager.actions';
import {
  CurrentLayoutIdentitySelector,
  CurrentLayoutSelector,
} from '@common-modules/dynamic-layout/state/widget-manager/widget-manager.selectors';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { CustomerPopupDimensions } from '@common-modules/shared/constants/dimensions.constants';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { CreateDashboardResultDto } from '../../models/create-template-result.dto';
import { BiService } from '../../services/bi.service';
import { BiManagePopupComponent } from '../bi-manage-popup/bi-manage-popup.component';
import { BiSavePopupComponent } from '../bi-save-popup/bi-save-popup.component';

const COMPONENT_SELECTOR = 'wlm-bi-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './bi-page.component.html',
  styleUrls: ['./bi-page.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class BiPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  T_SCOPE = `${AppModules.BI}.wlm-bi`;

  pageSettings: DynamicLayoutSettings;
  pageSettingsKeys = {
    layoutKey: 'LayoutMain',
    layoutArea: 'BI',
    widgetPage: 'bi',
    widgetModule: 'bi',
  };
  sharedScopeInstance = {
    [StateAreas.Filters]: 'shared',
    [StateAreas.WidgetManager]: 'shared',
  };
  currentTemplateLayout: DynamicLayoutSettings;
  currentDashboardTitle: string;
  currentDashboardId: string = null;

  private _stateWidgetSettings = new StateWidgetSettings({
    module: this.pageSettingsKeys.widgetModule,
    page: this.pageSettingsKeys.widgetPage,
    scopeInstanceKeys: this.sharedScopeInstance,
  });

  private readonly _widgetContainerSettingsKeys = new DynamicSettings({
    settingKey: 'BIWidgetsContainer',
    settingArea: 'BI',
  });

  private readonly _filtersInstanceKey = 'BiFiltersComponent#b14bb17c-38c2-480a-b9d3-cb163679c984';
  private readonly _selectorInstanceKey =
    'WidgetSelectorWidgetComponent#41074c80-fa38-4576-a369-b5440d90b16d';
  private readonly _widgetContainerInstanceKey =
    'BiContainerWidgetComponent#1d45731d-2d8b-46cf-ace1-cfdba83919dc';

  constructor(
    private _authenticationService: AuthenticationService,
    private _localization: LocalizationHelperService,
    private _matDialog: MatDialog,
    private _state: ReduxStateService,
    private _biService: BiService,
    private _dialogsService: DialogService,
    private _objectHelperService: ObjectHelperService,
    private _spinnerService: SpinnerService,
    private _dynamicLayoutService: DynamicLayoutService,
    private readonly _cd: ChangeDetectorRef
  ) {
    super();
    this._state.configure(this._stateWidgetSettings);
    this.hasRightPanel = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildPage();

    // Starts the first flow.
    this._state.dispatch(new SetLayoutKeysAction(this._widgetContainerSettingsKeys));

    this._state
      .select<DynamicLayoutSettings>(new CurrentLayoutSelector())
      .pipe(untilDestroyed(this))
      .subscribe((layout) => {
        this.currentTemplateLayout = this._objectHelperService.serializedClone(layout);
        this._cd.detectChanges();
      });

    this._state
      .select<DynamicLayoutSettings>(new CurrentLayoutIdentitySelector())
      .pipe(untilDestroyed(this))
      .subscribe((identity) => {
        this.currentDashboardId = identity?.layoutId ?? null;
        this.currentDashboardTitle = identity?.title;
        this._cd.detectChanges();
      });
  }

  private buildPage(): void {
    forkJoin([this._localization.get(`${this.T_SCOPE}.widgets`)]).subscribe(([titles]) => {
      this.pageSettings = new DynamicLayoutSettings({
        ...this.pageSettingsKeys,
        currentUser: this._authenticationService.userCode,
        structure: [],
        defaultStructure: [
          {
            type: LayoutNodeTypes.Column,
            content: [
              {
                type: LayoutNodeTypes.Stack,
                height: 15,
                content: [
                  {
                    type: LayoutNodeTypes.Component,
                    widgetInstanceKey: this._filtersInstanceKey,
                  },
                ],
              },
              {
                type: LayoutNodeTypes.Row,
                content: [
                  {
                    type: LayoutNodeTypes.Stack,
                    width: 25,
                    content: [
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: this._selectorInstanceKey,
                      },
                    ],
                  },
                  {
                    type: LayoutNodeTypes.Stack,
                    width: 75,
                    content: [
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: this._widgetContainerInstanceKey,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        items: [
          {
            componentName: 'BiFiltersComponent',
            widgetInstanceKey: this._filtersInstanceKey,
            scopeInstanceKeys: {
              [StateAreas.Filters]: 'shared',
            },
            title: titles['bi-filters-widget'],
          },
          {
            componentName: 'WidgetSelectorWidgetComponent',
            widgetInstanceKey: this._selectorInstanceKey,
            scopeInstanceKeys: {
              [StateAreas.Filters]: 'shared',
              [StateAreas.WidgetManager]: 'shared',
            },
            title: titles['widget-selector-widget'],
          },
          {
            componentName: 'BiContainerWidgetComponent',
            widgetInstanceKey: this._widgetContainerInstanceKey,
            scopeInstanceKeys: {
              [StateAreas.WidgetManager]: 'shared',
            },
            title: titles['bi-container-widget'],
          },
        ],
      });
    });
  }

  /**
   * Resets the dashboard to an empty one.
   */
  onNewEmptyTemplate(): void {
    this._biService.getEmptyDashboard(this._widgetContainerSettingsKeys).subscribe((dashboard) => {
      const settingsWithOptions = new DynamicLayoutSettingsLoadOptions({
        settings: dashboard,
      });
      this._state.dispatch(new SetLayoutToLoadAction(null));
      this._state.dispatch(new SetLayoutToLoadAction(settingsWithOptions));
      this.currentDashboardId = null;
      this.currentDashboardTitle = null;
    });
  }

  onCreateTemplate(): void {
    const popup = this._matDialog.open(BiSavePopupComponent, {
      data: new DynamicSettingsSave({
        ...this._widgetContainerSettingsKeys,
        settingValue: this.currentTemplateLayout,
      }),
    });

    popup.afterClosed().subscribe((data: CreateDashboardResultDto) => {
      if (data) {
        this.currentDashboardId = data.dashboardId ?? null;
        this.currentDashboardTitle = data.dashboardTitle;
        this.currentTemplateLayout.title = this.currentDashboardTitle;
        this.currentTemplateLayout.layoutId = this.currentDashboardId;

        const settingsWithOptions = new DynamicLayoutSettingsLoadOptions({
          settings: this.currentTemplateLayout,
        });
        this._state.dispatch(new SetLayoutToLoadAction(null));
        this._state.dispatch(new SetLayoutToLoadAction(settingsWithOptions));
      }
    });
  }

  onSaveTemplateDefinition(): void {
    this.setSpinner(true);
    this._biService
      .updateTemplateDefinition(this.currentDashboardId, this.currentTemplateLayout)
      .pipe(untilDestroyed(this))
      .pipe(finalize(() => this.setSpinner(false)))
      .subscribe({
        next: (data) => {
          this.showDialogResult('messages.save-success', 'success');
        },
        error: (error) => {
          this.showDialogResult('messages.save-error', 'error');
        },
      });
  }

  onManageTemplate(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '70%';
    dialogConfig.height = CustomerPopupDimensions.Height;
    dialogConfig.data = { currentDashboardId: this.currentDashboardId };

    this._matDialog
      .open(BiManagePopupComponent, dialogConfig)
      .afterClosed()
      .subscribe((data) => {
        if (data.selectedLayout) {
          const settingsWithOptions = new DynamicLayoutSettingsLoadOptions({
            settings: data.selectedLayout,
          });
          this._state.dispatch(new SetLayoutToLoadAction(null));
          this._state.dispatch(new SetLayoutToLoadAction(settingsWithOptions));
        }

        //In case the current dashboard has been deleted, we create a new empty template
        if (data.refreshCurrentDashboard) {
          this.onNewEmptyTemplate();
        }
      });
  }

  // In this specific page, the reset main layout does not intend to reset the inner BI Container layout.
  onClickResetMainLayout(): void {
    this._state.dispatch(new ResetWidgetManagerStateAction());

    const settings = new DynamicSettings({
      settingKey: this.pageSettingsKeys.layoutKey,
      settingArea: this.pageSettingsKeys.layoutArea,
    });

    this._dynamicLayoutService.resetOne(settings);
    // Trigger first flow to enable widget container
    this._state.dispatch(new SetLayoutKeysAction(null));
    this._state.dispatch(new SetLayoutKeysAction(this._widgetContainerSettingsKeys));
  }

  onClickResetDahsboardLayout(): void {
    this._dynamicLayoutService.resetOne(this._widgetContainerSettingsKeys);
    this._state.dispatch(new ResetLayoutAction(this._widgetContainerSettingsKeys));
  }

  get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  get persistencyArea(): string {
    return this.pageCrud;
  }

  get pageCrud(): string {
    return 'BiPageComponent';
  }

  get navigations(): DropdownNavigationItem[] {
    return [];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  private showDialogResult(messageKey: string, icon: 'success' | 'error') {
    const errorMessageKey = `${this.T_SCOPE}.${messageKey}`;
    let dialogSettings = new WlmDialogSettings({ translateKey: errorMessageKey, icon });

    this._dialogsService.showTranslatedMessageInSnackBar(dialogSettings);
  }

  private setSpinner(isLoading: boolean) {
    this._spinnerService.setLoading(isLoading, this.pageCrud);
  }

  ngOnDestroy(): void {
    this._state.dispatch(new ResetWidgetManagerStateAction());
  }
}
