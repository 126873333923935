import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { ColumnTitlePipe } from '@common-modules/wlm-grid/pipes/column-title.pipe';
import { AppModules } from '../../app-modules.enum';
import { BaseComponent } from '../../component/base.component';
import { GridSetting } from '../../constants/grid.constants';
import { DialogService } from '../../dialogs/dialogs.service';
import { ArrayHelperService } from '../../helpers/array-helper.service';
import { GridColumnHelperService } from '../../helpers/grid-column-helper.service';
import { GridHelperService } from '../../helpers/grid-helper.service';
import { ObjectHelperService } from '../../helpers/object-helper.service';
import { LocalizationHelperService } from '../../localization/localization-helper.service';
import { WlmDialogSettings } from '../../model/dialog/wlm-dialog-setting';
import { AddGridSettingsCmd } from '../../model/grid/AddGridSettingsCmd';
import { GridColumnSetting } from '../../model/grid/grid-column-setting';
import { GridSettingSaveStateDto } from '../../model/grid/grid-setting-save-state.dto';
import { DragListItemsSettings } from '../drag-list/models/drag-list-items-settings';
import { GridSettingsService } from '../grid/grid-settings.service';
import { IManageColumnData } from './manage-column-data';
import { ManageColumnResult } from './manage-column-result';

const COMPONENT_SELECTOR = 'wlm-manage-column';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './manage-column.component.html',
  styleUrls: ['./manage-column.component.scss'],
})
export class ManageColumnComponent extends BaseComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  private _excludedColumns: GridColumnSetting[];
  private _originalVisibleColumns: GridColumnSetting[];
  private _originalPagesize: number;

  private readonly excludedColumnTypes = ['export-only', 'internal', 'button'];

  private _gridSettings: GridSetting;
  public get gridSettings(): GridSetting {
    return this._gridSettings;
  }
  @Input() public set gridSettings(gridSetting: GridSetting) {
    this._gridSettings = gridSetting;
    if (gridSetting) {
      this.populateColumnsList();
    }
  }

  @Output() public loadGridSettings: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get currentLocaleCss(): string {
    return `lang-${this._localizationHelper.currentLocale}`;
  }

  visibleColumns: GridColumnSetting[];
  hiddenColumns: GridColumnSetting[];
  selectedHiddenColumn: GridColumnSetting;
  selectedVisibleColumn: GridColumnSetting;

  hiddenColumnsFiltered: GridColumnSetting[];
  visibleColumnsFiltered: GridColumnSetting[];
  pageSize: number;
  pagesizes: number[];

  gridName: string;
  settingsHasChanged = false;
  gridSettingSaveState: GridSettingSaveStateDto;
  dragListHiddenItemsSettings: DragListItemsSettings;
  dragListVisibleItemsSettings: DragListItemsSettings;
  columnTitlePipe: ColumnTitlePipe;

  constructor(
    private dialogRef: MatDialogRef<ManageColumnComponent>,
    @Inject(MAT_DIALOG_DATA) { gridSettings, gridName }: IManageColumnData,
    private _gridHelperService: GridHelperService,
    private _gridSettingsService: GridSettingsService,
    private _localizationHelper: LocalizationHelperService,
    private _dialogService: DialogService,
    private _gridColumnHelperService: GridColumnHelperService,
    private _arrayHelperService: ArrayHelperService,
    private _objectHelper: ObjectHelperService
  ) {
    super();

    this.gridSettings = gridSettings;
    this.gridName = gridName;
    this.pagesizes =
      typeof gridSettings.pageable !== 'boolean'
        ? (gridSettings.pageable.pageSizes as number[])
        : [this.gridSettings.pageSize];
    this.columnTitlePipe = new ColumnTitlePipe(_gridColumnHelperService);

    this.dragListHiddenItemsSettings = this.getDragListItemsSettings(false);
    this.dragListVisibleItemsSettings = this.getDragListItemsSettings(true);
  }

  ngOnInit(): void {
    this.setLoadButtonsState();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  populateZoneColumns() {
    if (
      this.gridSettings?.showZoneColumns &&
      !this.gridSettings?.gridColumnSettings?.filter((x) => x.type === 'hierarchy').length
    ) {
      this._gridHelperService.getHierarchyColumns().subscribe((col) => {
        this.hiddenColumns = this.hiddenColumns.concat(col);
      });
    }
  }

  populateColumnsList() {
    this.visibleColumns = this._objectHelper.clone(
      this.gridSettings?.gridColumnSettings?.filter(
        (x) => x.visible && !this.excludedColumnTypes.includes(x.type)
      )
    );

    this._originalVisibleColumns = this._objectHelper.clone(
      this.gridSettings?.gridColumnSettings?.filter(
        (x) => x.visible && !this.excludedColumnTypes.includes(x.type)
      )
    );

    this.hiddenColumns = this._objectHelper.clone(
      this.gridSettings?.gridColumnSettings?.filter(
        (x) => !x.visible && !this.excludedColumnTypes.includes(x.type)
      )
    );

    this._excludedColumns = this._objectHelper.clone(
      this.gridSettings?.gridColumnSettings?.filter((x) =>
        this.excludedColumnTypes.includes(x.type)
      )
    );

    this.pageSize = this.gridSettings?.pageSize;
    this._originalPagesize = this.gridSettings?.pageSize;

    this.populateZoneColumns();
  }

  setColumnToVisible() {
    this.visibleColumns.push(this.selectedHiddenColumn);
    this.hiddenColumns.forEach((item, index) => {
      if (item === this.selectedHiddenColumn) {
        this.hiddenColumns.splice(index, 1);
      }
    });

    this.visibleColumns = this._objectHelper.clone(this.visibleColumns);

    this.selectedHiddenColumn = undefined;
    this.compareVisibleColumns();
  }

  setAllColumnsToVisible() {
    this.visibleColumns.push(...this.hiddenColumnsFiltered);
    this.visibleColumns = this._objectHelper.clone(this.visibleColumns);

    this.hiddenColumns = this.hiddenColumns.filter((x) => {
      return this.hiddenColumnsFiltered.findIndex((t) => t.title === x.title) === -1;
    });

    this.compareVisibleColumns();
  }

  setColumnToHidden() {
    // Set as unlocked
    this.selectedVisibleColumn.locked = false;

    this.hiddenColumns.push(this.selectedVisibleColumn);
    this.visibleColumns.forEach((item, index) => {
      if (item === this.selectedVisibleColumn) {
        this.visibleColumns.splice(index, 1);
      }
    });

    this.hiddenColumns = this._objectHelper.clone(this.hiddenColumns);

    this.selectedVisibleColumn = undefined;
    this.compareVisibleColumns();
  }

  setAllColumnsToHidden() {
    // Set columns as unlocked
    this.visibleColumnsFiltered.forEach((column) => (column.locked = false));

    this.hiddenColumns.push(...this.visibleColumnsFiltered);
    this.hiddenColumns = this._objectHelper.clone(this.hiddenColumns);

    this.visibleColumns = this.visibleColumns.filter((x) => {
      return this.visibleColumnsFiltered.findIndex((t) => t.title === x.title) === -1;
    });

    this.compareVisibleColumns();
  }

  saveUserGridSettings() {
    this.saveGridSettings(false);
  }

  saveGlobalGridSettings() {
    this.saveGridSettings(true);
  }

  loadUserGridSettings() {
    this.loadGridSettings.emit(false);
  }

  loadGlobalGridSettings() {
    this.loadGridSettings.emit(true);
  }

  generateSerielizedNewColumnGridSettings(): string {
    const visibleColumns = this.visibleColumns;
    const hiddenColumns = this.hiddenColumns;

    visibleColumns.forEach((x) => (x.visible = true));
    hiddenColumns.forEach((x) => (x.visible = false));

    const newGridSettings = this.gridSettings;
    newGridSettings.gridColumnSettings = visibleColumns
      .concat(hiddenColumns)
      .concat(this._excludedColumns);
    newGridSettings.pageSize = this.pageSize;

    const serializedSettings = JSON.stringify(newGridSettings);

    return serializedSettings;
  }

  closeAndReload(isDefaultGrid: boolean) {
    this.dialogRef.close(new ManageColumnResult(true, isDefaultGrid));
  }

  close() {
    this.dialogRef.close(false);
  }

  onClickLock(item: GridColumnSetting) {
    const newLockValue = !item.locked;

    const itemIndex = this.visibleColumns.findIndex((vc) => vc === item);
    if (itemIndex < 0) {
      return;
    }

    this.visibleColumns.forEach((column, index) => {
      // If a column is locked, lock the previous columns too
      // When unlocking, unlock from the selected to the last column
      if ((newLockValue && itemIndex >= index) || (!newLockValue && itemIndex <= index)) {
        column.locked = newLockValue;
      }
    });

    this.visibleColumns = this._objectHelper.clone(this.visibleColumns);

    this.compareVisibleColumns();
  }

  onDropVisibleList(item: GridColumnSetting) {
    const index = this.visibleColumns.findIndex((c) => c === item);
    const [nextItem, previousItem] = [
      this.visibleColumns[index + 1],
      this.visibleColumns[index - 1],
    ];

    // At least one non-locked column is mandatory,
    // When a column is dropped, check previous and next columns
    // to see if there is a locked or unlocked zone
    this.visibleColumns[index].locked =
      item === this.visibleColumns[this.visibleColumns.length - 1]
        ? false
        : item === this.visibleColumns[0] && nextItem?.locked
        ? true
        : nextItem?.locked !== item.locked && previousItem?.locked !== item.locked
        ? nextItem.locked
        : item.locked;

    this.visibleColumns = this._objectHelper.clone(this.visibleColumns);
  }

  onDropHiddenList(item: GridColumnSetting) {
    const index = this.hiddenColumns.findIndex((c) => c === item);

    // Set as unlocked
    if (index >= 0) {
      this.hiddenColumns[index].locked = false;
    }

    this.hiddenColumns = this._objectHelper.clone(this.hiddenColumns);
    this.visibleColumns = this._objectHelper.clone(this.visibleColumns);
  }

  isValidConfiguration() {
    if (this.visibleColumns.length > 0) {
      // At least one non-locked column is mandatory
      // (the non-locked columns are desplayed after the locked ones)
      return this.visibleColumns[this.visibleColumns.length - 1]?.locked !== true;
    }

    return false;
  }

  private saveGridSettings(isDefaultSetting: boolean) {
    const settings = this.generateSerielizedNewColumnGridSettings();

    this._gridSettingsService
      .saveGridSettings(new AddGridSettingsCmd(this.gridName, settings, isDefaultSetting))
      .subscribe({
        next: (response) => {
          const messageKey = isDefaultSetting ? 'system-template-saved' : 'user-template-saved';
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.messages.${messageKey}` })
          );
          this.closeAndReload(isDefaultSetting);
        },
        error: (error) => {
          this._dialogService.showErrorMessage(error);
        },
      });
  }

  public compareVisibleColumns() {
    if (this.visibleColumns.length !== this._originalVisibleColumns.length) {
      this.settingsHasChanged = true;
      return;
    }

    for (let i = 0, l = this.visibleColumns.length; i < l; i++) {
      if (
        this.visibleColumns[i]?.field !== this._originalVisibleColumns[i]?.field ||
        this.visibleColumns[i]?.locked !== this._originalVisibleColumns[i]?.locked
      ) {
        this.settingsHasChanged = true;
        return;
      }
    }

    if (this._originalPagesize !== this.gridSettings.pageSize) {
      this.settingsHasChanged = true;
      return;
    }

    this.settingsHasChanged = false;
  }

  private setLoadButtonsState() {
    this._gridSettingsService.getGridSettingsSaveState(this.gridName).subscribe((saveState) => {
      this.gridSettingSaveState = saveState;
    });
  }

  private getDragListItemsSettings(showButton: boolean) {
    return {
      showSeparator: true,
      getSeparatorCssClassFn: (model: GridColumnSetting, list: GridColumnSetting[]) => {
        let cssClassName = '';

        const itemIndex = list?.findIndex((item) => item === model);
        const separatorIndex = this._arrayHelperService.findLastIndex(
          list,
          (model) => model.locked
        );

        if (itemIndex > -1 && itemIndex === separatorIndex) {
          cssClassName = 'item-separator';
        }

        return cssClassName;
      },
      showButton: showButton,
      getIconFn: (model: GridColumnSetting) => {
        return model?.locked ? 'lock' : 'lock_open';
      },
      getIconCssClassFn: (model: GridColumnSetting) => {
        return model?.locked ? null : 'secondary-icon';
      },
      getItemIdFn: this._gridColumnHelperService.getItemId,
    };
  }

  onHiddenColumnsChanged($event) {
    this.hiddenColumnsFiltered = $event;
  }

  onVisibleColumnsChanged($event) {
    this.visibleColumnsFiltered = $event;
  }
}
