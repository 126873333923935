import { Injectable, Injector } from '@angular/core';
import { AdditionalHttpOpts } from '@common-modules/cache/http-cache/additional-http-options';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { NotificationCloseDto } from '@common-modules/shared/model/notifications/notification-close.dto';
import { NotificationFollowDto } from '@common-modules/shared/model/notifications/notification-follow.dto';
import { NotificationTypes } from '@common-modules/shared/model/notifications/notification-types.enum';
import { NotificationUserReadDto } from '@common-modules/shared/model/notifications/notification-user-read.dto';
import { NotificationDto } from '@common-modules/shared/model/notifications/notification.dto';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotificationDiscussionLinkDto } from '../models/notification-discussion-link.dto';

@Injectable({ providedIn: 'root' })
export class NotificationService extends GridODataService<NotificationDto> {
  protected get url() {
    return `${this.apiUrl}/notification`;
  }

  constructor(injector: Injector, private _objectHelper: ObjectHelperService) {
    super(injector, '/api/notification');
  }

  protected mapResponse(
    response: PagedResultDto<NotificationDto>
  ): PagedResultDto<NotificationDto> {
    return response;
  }

  follow(notificationsToFollow: NotificationFollowDto): Observable<void> {
    return this.httpCacheClient.put(`${this.url}/follow`, notificationsToFollow, {
      avoid: true,
    });
  }

  unfollow(notificationsToUnFollow: NotificationFollowDto): Observable<void> {
    return this.httpCacheClient.put(`${this.url}/unfollow`, notificationsToUnFollow, {
      avoid: true,
    });
  }

  readUnread(notificationsToUpdate: NotificationUserReadDto): Observable<null> {
    return this.httpCacheClient.post(`${this.url}/read`, notificationsToUpdate, {
      avoid: true,
    });
  }

  close(notificationsToClose: NotificationCloseDto): Observable<null> {
    return this.httpCacheClient.patch(`${this.url}/close`, notificationsToClose, {
      avoid: true,
    });
  }

  getById(notificationId: string): Observable<NotificationDto> {
    const addHttpOptions = new AdditionalHttpOpts({
      mapResponseDates: true,
    });
    return this.httpCacheClient.get(
      `${this.url}/${notificationId}`,
      { avoid: true },
      addHttpOptions
    );
  }

  create(notificationData: NotificationDto): Observable<NotificationDto> {
    const requestDatesNoUtc = ['targetDate'];

    const notification = this._objectHelper.clone(notificationData);
    notification.notificationId = null;
    notification.notificationType = NotificationTypes.Manual;
    const addHttpOptions = new AdditionalHttpOpts({
      mapResponseDates: true,
      mapRequestDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });
    return this.httpCacheClient.post(this.url, notification, { avoid: true }, addHttpOptions);
  }

  update(notification: NotificationDto): Observable<NotificationDto> {
    const requestDatesNoUtc = ['targetDate'];

    const addHttpOptions = new AdditionalHttpOpts({
      mapResponseDates: true,
      mapRequestDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });
    return this.httpCacheClient.put(
      `${this.url}/${notification.notificationId}`,
      notification,
      { avoid: true },
      addHttpOptions
    );
  }

  countUnread(): Observable<number> {
    return this.httpCacheClient
      .get(`${this.url}/counter`, { avoid: true })
      .pipe(map((data: { counter: number }) => data.counter));
  }

  linkComment(link: NotificationDiscussionLinkDto): Observable<void> {
    return this.httpCacheClient.post(`${this.url}/link`, link, {
      avoid: true,
    });
  }
}
