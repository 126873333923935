import { HierarchyElementPressureConfigDto } from './hierarchy-element-pressure-config.dto';
import { HierarchyElementPressureSignalVersionOperationDto } from './hierarchy-element-signal-version-operation.dto';

export class HierarchyElementPressureSignalVersionSaveDto {
  hierarchyElementId: string;
  hierarchyElementName: string;
  minDate: string;
  pressureSignalVersionOperations: HierarchyElementPressureSignalVersionOperationDto[];
  pressureSignals: HierarchyElementPressureConfigDto[];
  trackedDates: string[];
  trackedSignals: number[];

  constructor(init: HierarchyElementPressureSignalVersionSaveDto) {
    Object.assign(this, init);
  }
}
