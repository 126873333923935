import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { OAuthService } from 'angular-oauth2-oidc';

const COMPONENT_SELECTOR = 'wlm-home';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './home.component.html',
})
export class HomeComponent extends BasePageComponent implements OnInit, OnDestroy, AfterViewInit {
  public T_SCOPE = `${AppModules.Home}.${COMPONENT_SELECTOR}`;
  @ViewChild('sv') private scrollView;
  private interval;
  public paused = false;
  public items: any[] = [
    {
      url: 'assets/images/carousel/1280px-Torrepiezometricaaerea2.jpg',
      title: 'Torre piezo metrica',
      attribution: 'Elena Becchetti / CC BY-SA',
    },
    { url: 'assets/images/carousel/big-blue-pipe-close-up.jpg', title: 'Mega pipe' },
  ];
  public width = '100%';
  public height = '400px';

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  public get persistencyArea(): string {
    throw new Error('Method not implemented.');
  }
  mapInitParameters(parameters: TabDetailPanelParameters) {}
  public get pageCrud(): string {
    return 'HomePageCrud';
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
  get hasLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  constructor(private oAuthService: OAuthService) {
    super();
  }
  public ngAfterViewInit() {
    this.rightPanelService.setVisible(false);
    this.interval = setInterval(() => {
      if (!this.paused) {
        this.scrollView.next();
      }
    }, 5000);
  }

  public ngOnDestroy() {
    clearInterval(this.interval);
    super.ngOnDestroy();
  }

  init(): void {}
}
