import { SetValueAction } from '@common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from '@common-modules/dynamic-layout/state/generic/generic.selectors';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';

const eventName = 'RefreshLogIterfaceSelector';

export class RefreshLogIterfaceSelectorAction extends SetValueAction {
  constructor(value?) {
    super({
      fieldName: eventName,
      value: value ?? globalUtilsHelper.generateGuid(),
    });
  }
}

export class RefreshLogIterfaceSelectorSelector extends GetValueSelector {
  constructor() {
    super({
      fieldName: eventName,
    });
  }
}
