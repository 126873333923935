import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCacheService } from '@common-modules/cache/http-cache/http-cache.service';
import { ACKAlarmsDto } from '@common-modules/dependencies/alarms/ack-alarms.dto';
import { AlarmCommentTypesDto } from '@common-modules/dependencies/alarms/alarm-comment-type.dto';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { AlarmsService } from '@common-modules/shared/services/alarms.service';

import { ActiveAlarmsGridService } from '../active-alarms.service';

const COMPONENT_SELECTOR = 'wlm-ack-alarm-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ack-alarm-popup.component.html',
  styleUrls: ['./ack-alarm-popup.component.scss'],
})
export class AckAlarmPopupComponent implements OnInit {
  selectedAlarmIds: string[];
  form: UntypedFormGroup;
  isSaving = false;
  alarmCommentTypes: AlarmCommentTypesDto[];
  ackCommentId: number;

  T_SCOPE = `${AppModules.Alarms}.${COMPONENT_SELECTOR}`;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AckAlarmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) alarmIds: string[],
    private authenticationService: AuthenticationService,
    private activeAlarmsGridService: ActiveAlarmsGridService,
    private dialogService: DialogService,
    private cacheService: HttpCacheService,
    private alarmsService: AlarmsService
  ) {
    this.selectedAlarmIds = alarmIds;
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      ackCommentControl: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.loadACKComments();
  }

  loadACKComments() {
    this.alarmsService.getAlarmCommentTypes().subscribe({
      next: (comments) => {
        this.alarmCommentTypes = comments;
      },
    });
  }

  save() {
    if (this.form.valid) {
      this.isSaving = true;
      const ackAlarms: ACKAlarmsDto = {
        alarmIds: this.selectedAlarmIds,
        commentId: this.ackCommentId,
        ackTime: new Date(Date.now()),
        userCode: this.authenticationService?.userCode?.split('@')[0],
        isACK: true,
      };
      this.activeAlarmsGridService.setAckAlarms(ackAlarms).subscribe({
        next: (value) => {
          if (!value) {
            this.dialogService.showTranslatedMessage(
              new WlmDialogSettings({
                translateKey: `${this.T_SCOPE}.ack-save-error`,
                icon: 'error',
              })
            );
          }
          this.cacheService.clearContainsInUrl('alarm').then(() => {
            this.isSaving = false;
            this.dialogRef.close(value);
          });
        },
        error: () => {
          this.dialogService.showTranslatedMessage(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.ack-save-error`,
              icon: 'error',
            })
          );
          this.isSaving = false;
        },
      });
    }
  }

  close(event) {
    this.dialogRef.close();
  }
}
