import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHierarchyElementDto } from '@common-modules/dependencies/he/hierarchy-element.dto';
import { GenericCardSettings } from '@common-modules/shared-component/generic-card/generic-card-settings';
import { SettingsService } from '@common-modules/shared/config/settings.service';
import { DragListSettings } from '@common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { SelectDragListSettings } from '@common-modules/shared/core/select-drag-list-virtual/select-drag-list-settings';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'wlm-hierarchy-selector',
  templateUrl: './hierarchy-selector.component.html',
  styleUrls: ['./hierarchy-selector.component.scss'],
})
export class HierarchySelectorComponent implements OnInit {
  private _filters: Map<string, any>;
  get filters(): Map<string, any> {
    return this._filters;
  }
  @Input() set filters(value: Map<string, any>) {
    this._filters = value;

    this.prepareListsSettings();
  }

  @Input() widgetInstanceKey: string;
  @Input() gridName: string;
  @Input() subscriptionTag = 'default';

  @Output() selectedElementChange = new EventEmitter<IHierarchyElementDto>();

  private _hierarchyElementFieldName = 'hierarchyElementId';
  private _hierarchyElementNameFieldName = 'hierarchyElementName';
  private _selectedFieldName = 'hierarchyElementId';
  private _serviceName = 'HierarchySelectionService';
  private _pagesize = 50;

  private _hierarchyCardSettings: { titleField: string; subtitleField: string };

  settings: DragListSettings;
  cardSettings: GenericCardSettings<IHierarchyElementDto>;
  queryParams: Map<string, any>;

  emptyFilters: DataBindingFilters;

  constructor(private _settingService: SettingsService) {
    this._hierarchyCardSettings = this._settingService.hierarchyCardSettings;
  }

  ngOnInit(): void {}

  onSelectItem(selectedItem: IHierarchyElementDto) {
    this.selectedElementChange.emit(selectedItem);
  }

  private prepareListsSettings() {
    this.settings = new SelectDragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._hierarchyElementFieldName, dir: 'asc' }],
      oDataFiltersExtended: this.emptyFilters,
      useQueryParams: true,
      displayFieldName: this._hierarchyElementFieldName,
      isReadOnly: false,
      scrollId: 'hierarchySelector',
      selectedFieldName: this._selectedFieldName,
      filtrableFields: [this._hierarchyElementFieldName, this._hierarchyElementNameFieldName],
    });

    this.cardSettings = new GenericCardSettings<IHierarchyElementDto>({
      title1Fn: (model) =>
        this._hierarchyCardSettings?.titleField == 'id'
          ? model.hierarchyElementId
          : model.hierarchyElementName,
      subtitleFn: (model) =>
        this._hierarchyCardSettings?.subtitleField == 'id'
          ? model.hierarchyElementId
          : model.hierarchyElementName,
    });

    this.queryParams = this.filters;
  }
}
