import { Injectable, Injector } from '@angular/core';
import { AlcAttributesDto } from '@common-modules/dependencies/alc/alc-attributes.dto';
import { IReasonsForEligibleChangeDto } from '@common-modules/dependencies/alc/eligibility-reasons.dto';
import { FiltrableGisLayer } from '@common-modules/dependencies/map/filtrable-gis-layer';
import { IApplicationAttributeDto } from '@common-modules/dependencies/shared/model/application-attribute.dto';
import { IBillingClassDto } from '@water-loss//features/shared/model/customer/billing-class.dto';
import { ICustomerClassTypeDto } from '@water-loss//features/shared/model/customer/customer-class-type.dto';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GetCacheOpts } from '../../cache/http-cache/http-cache.client';
import { IActivityTypeDto } from '../../dependencies/alc/activity-type.dto';
import { IReasonsForCriticalityConfigDto } from '../../dependencies/alc/criticality-reason.dto';
import { ISapCodeGroupDto } from '../../dependencies/alc/sap-code-group.dto';
import { ISapCodeDto } from '../../dependencies/alc/sap-code.dto';
import { SourceSystemDto } from '../../dependencies/alc/source-system.dto';
import { IHierarchyElementAttributeDto } from '../../dependencies/he/hierarchy-element-attribute.dto';
import { HierarchyElementFiltersDataDto } from '../../dependencies/he/hierarchy-element-filters-data.dto';
import { IHierarchyElementPathDto } from '../../dependencies/he/hierarchy-element-path.dto';
import { IHierarchyElementTypeDto } from '../../dependencies/he/hierarchy-element-type.dto';
import { IHierarchyFamilyDto } from '../../dependencies/he/hierarchy-family.dto';
import { IHierarchyRelationLevelDto } from '../../dependencies/he/hierarchy-relation-level.dto';
import { IGisLayerYear } from '../../dependencies/map/gis-layer-year';
import { NetworkElementAttributeTypeDto } from '../../dependencies/ne/network-element-attribute-type.dto';
import { INetworkElementAttributeDto } from '../../dependencies/ne/network-element-attribute.dto';
import { INetworkElementSubTypeDto } from '../../dependencies/ne/network-element-subtype.dto';
import { INetworkElementTypeDto } from '../../dependencies/ne/network-element-type.dto';
import { INetworkElementDto } from '../../dependencies/ne/network-element.dto';
import { CalendarDto } from '../../dependencies/shared/model/calendar.dto';
import { SeasonDto } from '../../dependencies/shared/model/season.dto';
import { SettingsEntitySubtype } from '../../dynamic-forms/models/settings-entity-subtype';
import { BaseService } from '../base.service';
import { LocalizationHelperService } from '../localization/localization-helper.service';
import { AlgorithmAttributesDto } from '../model/algorithm/algorithm-attributes.dto';
import { IAlgorithmFamilyDto } from '../model/algorithm/algorithm-family.dto';
import { IAlgorithmHierarchyViewDto } from '../model/algorithm/algorithm-hierarchy-view.dto';
import { IAlgorithmDto } from '../model/algorithm/algorithm.dto';
import { EntityTypeDto } from '../model/algorithm/entity-type.dto';
import { TimeAggregationDto } from '../model/algorithm/time-aggregation.dto';
import { IGisLayerDto } from '../model/gis/gis-layer.dto';
import { PagedResultDto } from '../model/paged-result.dto';
import { UserDto } from '../model/roles/user.dto';
import { IDialogPathDto } from '../model/security/dialog-path.dto';
import { IDialogDto } from '../model/security/dialog.dto';
import { SelectOption } from '../model/shared/select-option';
import { TreeFlatElementDto } from '../model/shared/tree-flat-element.dto';
import { IDimensionTypeDto } from '../model/unit/dimension-type.dto';
import { IUnitTypeDto } from '../model/unit/unit-type.dto';

const APP_ATTR_DEFAULT_FAMILY = 21;

@Injectable({ providedIn: 'root' })
export class GlobalsService extends BaseService {
  expiration: GetCacheOpts = { expiration: 'default', reload: true };

  constructor(injector: Injector, private _localization: LocalizationHelperService) {
    super(injector);
  }

  protected get<T>(urlPath: string, params = null) {
    return this.httpCacheClient.get<T>(`${this.apiUrl}/${urlPath}`, this.expiration, params);
  }

  private post<T>(urlPath: string, body: string) {
    return this.httpCacheClient.post<T>(`${this.apiUrl}/${urlPath}`, body, this.expiration);
  }

  getCurrentUser(): Observable<UserDto> {
    return this.httpCacheClient.get<UserDto>(`${this.apiUrl}/roles/users/me`, {
      avoid: true,
    });
  }

  getAlgorithms(): Observable<IAlgorithmDto[]> {
    const algorithms$ = this.get<IAlgorithmDto[]>('alg/algorithm/all');
    return algorithms$;
  }

  getAlgorithmHierarchies(): Observable<IAlgorithmHierarchyViewDto[]> {
    const algorithmHierarchies$ = this.get<IAlgorithmHierarchyViewDto[]>('alg/algorithm/hierarchy');
    return algorithmHierarchies$;
  }

  // To get a single algorithm, use AlgorithmsService instead.

  getAlgorithmFamilies(): Observable<IAlgorithmFamilyDto> {
    return this.get<IAlgorithmFamilyDto>('alg/family/all');
  }

  getHierarchyElementAttributes(): Observable<IHierarchyElementAttributeDto[]> {
    return this.get<IHierarchyElementAttributeDto[]>('he/attribute/all');
  }

  getHierarchyElementAttributesById(id: string): Observable<IHierarchyElementAttributeDto[]> {
    const url = `he/attribute/${id}`;

    return this.get<IHierarchyElementAttributeDto[]>(url);
  }

  getHierarchyElementAttributesByIdFiltered(
    id: string,
    attributeIds?: number[]
  ): Observable<IHierarchyElementAttributeDto[]> {
    const url = `he/attribute?`;
    const filterData = this.getHEAttributesFilter(id, attributeIds);
    const urlWithFilter = url + filterData;

    return this.get<PagedResultDto<IHierarchyElementAttributeDto>>(urlWithFilter).pipe(
      map((x) => x.items)
    );
  }

  getNetworkElementAttributesByType(
    attributeIds?: number[]
  ): Observable<PagedResultDto<INetworkElementAttributeDto>> {
    return this.get<PagedResultDto<INetworkElementAttributeDto>>('ne/attribute', {
      attributeTypeIds: attributeIds,
    });
  }

  getNetworkElementAttributesType(): Observable<NetworkElementAttributeTypeDto[]> {
    return this.get<NetworkElementAttributeTypeDto[]>('ne/attribute/types');
  }

  getHEAttributesFilter(id: string, attributeIds?: number[]): string {
    const hasId = id != null && id != undefined && id != '';

    let queryFilter = hasId ? `$filter=(hierarchyElementId eq '${id}'` : `$filter=(`;

    let attributesFilter = '';
    if (attributeIds && attributeIds.length) {
      attributeIds.forEach((x) => {
        if (attributesFilter !== '') {
          attributesFilter = attributesFilter + ' or ';
        }
        attributesFilter = attributesFilter + 'hierarchyElementAttributeTypeId eq ' + x;
      });
    }
    queryFilter +=
      attributesFilter === ''
        ? ')'
        : hasId
        ? ` and (${attributesFilter}))`
        : `${attributesFilter})`;

    return queryFilter;
  }

  getHierarchyElementTypes(familyId?: string): Observable<IHierarchyElementTypeDto[]> {
    let urlPath = 'he/type/all';
    if (familyId) {
      urlPath = `${urlPath}?familyId=${familyId}`;
    }
    return this.httpCacheClient.get<IHierarchyElementTypeDto[]>(
      `${this.apiUrl}/${urlPath}`,
      { avoid: false },
      { showSpinner: true }
    );
  }

  getHierarchyFamilies(): Observable<IHierarchyFamilyDto[]> {
    return this.get<IHierarchyFamilyDto[]>('he/family/all');
  }

  getHierarchyElementPaths(): Observable<IHierarchyElementPathDto[]> {
    return this.get<IHierarchyElementPathDto[]>('he/tree/all');
  }

  getHierarchyRelationLevels(): Observable<IHierarchyRelationLevelDto[]> {
    return this.get<IHierarchyRelationLevelDto[]>('he/levels/all');
  }

  getHeFiltersDataById(hierarchyElementId: string): Observable<HierarchyElementFiltersDataDto> {
    const url = `he/filters-data/${hierarchyElementId}`;

    return this.get<HierarchyElementFiltersDataDto>(url);
  }

  getUnitTypes(): Observable<IUnitTypeDto[]> {
    return this.get<IUnitTypeDto[]>('unit/type/all');
  }

  getDimensionTypes(): Observable<IDimensionTypeDto[]> {
    return this.get<IDimensionTypeDto[]>('unit/dimension/all');
  }

  getDimensionTypesTranslated = (
    includeExclude?: 'include' | 'exclude',
    typeIds?: number[]
  ): Observable<SelectOption<number>[]> => {
    return this.getDimensionTypes().pipe(
      map((items) => {
        let filteredItems = items;
        if (includeExclude === 'include') {
          filteredItems = items.filter((item) => typeIds.includes(item.dimensionTypeId));
        }
        if (includeExclude === 'exclude') {
          filteredItems = items.filter((item) => !typeIds.includes(item.dimensionTypeId));
        }
        return filteredItems;
      }),
      switchMap((items) => {
        return this._localization.get('common.dimension-types').pipe(
          map((ts) => {
            return items.map(
              (item: IDimensionTypeDto) =>
                new SelectOption<number>({
                  label: ts[item.dimensionTypeDescription.toLowerCase()],
                  value: item.dimensionTypeId,
                })
            );
          })
        );
      })
    );
  };

  getDialogs(): Observable<IDialogDto[]> {
    return this.get<IDialogDto[]>('security/dialog/all');
  }

  getDialogPaths(): Observable<IDialogPathDto[]> {
    return this.get<IDialogPathDto[]>('security/dialog-path/all');
  }

  getGisLayers(): Observable<IGisLayerDto[]> {
    return this.get<IGisLayerDto[]>('gis/layer');
  }

  getFiltrableGisLayers(): Observable<FiltrableGisLayer> {
    return this.httpCacheClient.get<FiltrableGisLayer>(`${this.apiUrl}/gis/layer/filtrable`, {
      avoid: false,
    });
  }

  getFiltrableGisHeLayers(): Observable<FiltrableGisLayer> {
    return this.httpCacheClient.get<FiltrableGisLayer>(`${this.apiUrl}/gis/layer/filtrable/he`, {
      avoid: false,
    });
  }

  getFiltrableGisLeakYears(): Observable<IGisLayerYear[]> {
    return this.httpCacheClient.get<IGisLayerYear[]>(
      `${this.apiUrl}/gis/layer/filtrable/years`,
      { avoid: false },
      { showSpinner: true }
    );
  }

  getActivityTypes(): Observable<IActivityTypeDto[]> {
    return this.get<IActivityTypeDto[]>('alc/activity/type/all');
  }

  getSapCodes(): Observable<ISapCodeDto[]> {
    return this.get<ISapCodeDto[]>('alc/sap/code/all');
  }

  getSapCodeGroups(): Observable<ISapCodeGroupDto[]> {
    return this.get<ISapCodeGroupDto[]>('alc/sap/code-group/all');
  }

  getNetworkElementTypes(
    includeOnlyConfigurable: boolean = false
  ): Observable<INetworkElementTypeDto[]> {
    return this.get<INetworkElementTypeDto[]>('ne/type/all', {
      includeOnlyConfigurable,
    });
  }

  getNetworkElementSubTypes(): Observable<INetworkElementSubTypeDto[]> {
    return this.get<INetworkElementSubTypeDto[]>('ne/subtype/all');
  }

  getTransmissionNetworkElementTypes(): Observable<INetworkElementTypeDto[]> {
    return this.get<INetworkElementTypeDto[]>('tn/ne-type');
  }

  getApplicationAttributes(): Observable<IApplicationAttributeDto[]> {
    return this.get<IApplicationAttributeDto[]>('application/attribute/all');
  }

  getApplicationAttributesById(attributeId: number): Observable<IApplicationAttributeDto> {
    return this.get<IApplicationAttributeDto>(`application/attribute/${attributeId}`);
  }

  getAllReasons(): Observable<IReasonsForEligibleChangeDto[]> {
    return this.get(`alc/eligibility/reasons/all`);
  }

  getAllCriticalityReasons(): Observable<IReasonsForCriticalityConfigDto[]> {
    return this.get(`alc/criticality/reasons/all`);
  }

  getSourceSystems(): Observable<SourceSystemDto[]> {
    return this.get(`telemetry/source-system/all`);
  }

  getTimeAggregations(): Observable<TimeAggregationDto[]> {
    return this.get(`alg/aggregation/all`);
  }

  getEntityTypes(): Observable<EntityTypeDto[]> {
    return this.get(`alg/entity-type/all`);
  }

  /**
   * Get the default value for the hierarchy family id. If the supplied value is valid, use it instead.
   */
  getDefaultHierarchyFamilyId(defaultValue?: string): Observable<string> {
    if (defaultValue) {
      return of(defaultValue);
    }
    return this.getApplicationAttributesById(APP_ATTR_DEFAULT_FAMILY).pipe(
      map((data: IApplicationAttributeDto) => data.attributeValue.toLowerCase())
    );
  }

  getCalendars(): Observable<CalendarDto[]> {
    return this.get(`calendar`);
  }

  getSeasons(): Observable<SeasonDto[]> {
    return this.get(`season`);
  }

  getAlgorithmAttributes(ids: number[] = []): Observable<AlgorithmAttributesDto[]> {
    return this.get('algorithm/attributes', { algorithmAttributeIds: ids }).pipe(
      map((data: any) => data.algorithmAttributes)
    );
  }

  getAlcAttributes(): Observable<AlcAttributesDto[]> {
    return this.get('alc/attributes').pipe(map((data: any) => data.activeLeakageControlAttributes));
  }

  getCustomerClassTypes(): Observable<ICustomerClassTypeDto[]> {
    return this.get('customer/class-type');
  }

  getBillingClasses(): Observable<IBillingClassDto[]> {
    return this.get('customer/billing-class');
  }

  getDialogsTree(): Observable<TreeFlatElementDto<number>[]> {
    return this.get<TreeFlatElementDto<number>[]>('security/dialog/tree');
  }

  buildEntitySubtypesFromNetworkElement(
    networkElement: INetworkElementDto
  ): SettingsEntitySubtype[] {
    const subtypes = [];
    if (networkElement.isMerging) {
      subtypes.push(SettingsEntitySubtype.MergedZone);
    }
    return subtypes;
  }
}
