import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { WLMGridModule } from '@common-modules/wlm-grid/wlm-grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import { MonitoringModule } from '../monitoring/monitoring.module';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { FlagReasonPopupComponent } from './leakage-reporting-components/flag-reason-popup/flag-reason-popup.component';
import { LeakageReportingFilterComponent } from './leakage-reporting-components/leakage-reporting-filter/leakage-reporting-filter.component';
import { UnflagReasonPopupComponent } from './leakage-reporting-components/unflag-reason-popup/unflag-reason-popup.component';
import { LeakageReportingPageComponent } from './leakage-reporting-page/leakage-reporting-page.component';
import { LeakageReportingRoutingModule } from './leakage-reporting-routing.module';
import { LeakageReportingService } from './leakage-reporting.service';

@NgModule({
  declarations: [
    LeakageReportingPageComponent,
    LeakageReportingFilterComponent,
    FlagReasonPopupComponent,
    UnflagReasonPopupComponent,
  ],
  imports: [
    CommonModule,
    WlmSharedModule,
    WLMGridModule,
    WlmChartsModule,
    TranslateModule,
    WlmFiltersModule,
    WlmSharedComponentModule,
    LeakageReportingRoutingModule,
    MonitoringModule,
  ],
  providers: [
    LeakageReportingService,
    { provide: 'LeakageReportingService', useExisting: LeakageReportingService },
  ],
})
export class LeakageReportingModule {
  static injector: Injector;

  constructor(injector: Injector) {
    LeakageReportingModule.injector = injector;
  }
}
