import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { SettingsService } from '@common-modules/shared/config/settings.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService: OAuthService;
  constructor(private injector: Injector, private _settingsService: SettingsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const auth = this.getAuthService();
    let isApiUrl = request.url.startsWith(this._settingsService.apis.default.url + '/api');

    if (this._settingsService.useMultitenant) {
      const tenant = this._settingsService.currentTenant;
      const urlWithTenant = this.addTenantToUrl(
        this._settingsService.apis.default.url + '/api',
        tenant
      );
      isApiUrl = request.url.startsWith(urlWithTenant);
    }

    if (isApiUrl && auth.hasValidAccessToken()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${auth.getAccessToken()}`,
        },
      });
    }

    return next.handle(request);
  }
  getAuthService(): OAuthService {
    if (typeof this.authService === 'undefined') {
      this.authService = this.injector.get(OAuthService);
    }
    return this.authService;
  }

  //TODO: move method in helper, this method is called in this interceptor and tenant.interceptor.ts
  private addTenantToUrl(url: string, tenant: string) {
    const urlParts = url.split('://');

    if (tenant && urlParts.length >= 2) {
      const [protocol, ...restOfUrl] = urlParts;
      const urlWithTenant = [protocol, '://', tenant, '.', ...restOfUrl].join('');
      return urlWithTenant;
    }
    return url;
  }
}
