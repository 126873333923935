import { WlmElementExtended } from '@common-modules/shared/charts/model/elements/element-extended';
import { IAlgorithmFiltrableType } from '@common-modules/shared/model/filtrable-items/types/algorithm-filtrable-type';
import { FiltrableItemTypeEnum } from '@common-modules/shared/model/filtrable-items/types/filtrable-item-type-enum';
import { EntityTypes } from '@common-modules/wlm-charts/core/models/entity-types';

export class AlgorithmFiltrableType implements IAlgorithmFiltrableType {
  key: string;
  title: string;
  algorithmShortName: string;
  algorithmDescription: string;
  dimensionTypeId: number;
  timeAggregationId: number;
  icon: string;
  type: FiltrableItemTypeEnum = FiltrableItemTypeEnum.Algorithm;
  entityType: EntityTypes;
  element: WlmElementExtended;

  constructor(
    title: string,
    algorithmShortName: string,
    algorithmDescription: string,
    dimensionTypeId: number,
    timeAggregationId: number,
    icon: string,
    elementId: string,
    elementTypeId: string,
    name: string,
    typeName: string,
    entityType: EntityTypes,
    alternativeId: string
  ) {
    this.key = `${algorithmShortName}-${elementId}`;

    this.title = title;
    this.algorithmShortName = algorithmShortName;
    this.algorithmDescription = algorithmDescription;
    this.dimensionTypeId = dimensionTypeId;
    this.timeAggregationId = timeAggregationId;
    this.icon = icon;
    this.entityType = entityType;

    this.element = new WlmElementExtended(elementId, elementTypeId, name, typeName, alternativeId);
  }
}
