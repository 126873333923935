import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WaterBalanceParams } from '@common-modules/dependencies/water-balance/water-balance-params';
import { WaterBalanceTypesEnum } from '@common-modules/dependencies/water-balance/water-balance-types';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { WBDimensionTypes } from '@common-modules/shared/constants/dimensions.constants';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { CurrencyTypesEnum } from '@common-modules/shared/model/shared/currency-types.enum';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { WaterBalanceChartConfiguration } from '../../shared/model/water-balance/water-balance-chart-configuration';

import { WaterBalanceChartComponent } from '../water-balance-chart/water-balance-chart.component';
import { WaterBalanceFiltersConfiguration } from '../water-balance-filters/water-balance-filters-configuration';

const COMPONENT_SELECTOR = 'wlm-water-balance-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './water-balance-page.component.html',
  styleUrls: ['./water-balance-page.component.scss'],
})
export class WaterBalancePageComponent extends BasePageComponent implements OnInit, OnDestroy {
  // Filters main config.

  filtersConfig: WaterBalanceFiltersConfiguration;
  filters: Map<string, BasicFilter> = new Map<string, BasicFilter>();
  wbParams: WaterBalanceParams;

  idWaterBalanceWidget = 'water-balance';
  nameWaterBalanceImage = 'Water Balance';

  clearAll$ = new Subject<void>();
  persistFilters$ = new Subject<void>();

  // Filter item values.
  selectedHierarchyElements: string[] = [];
  selectedHierarchyFamilyId: string;

  // filters fieldNames

  autoLoad = false;
  T_SCOPE = `${AppModules.WaterBalance}.${COMPONENT_SELECTOR}`;
  filterDetailsParameters: TabDetailPanelParameters;
  defaultMonthSpan = 1;
  rightPanelDetailsTitle: string;

  constructor(private _globalsService: GlobalsService, private _route: ActivatedRoute) {
    super();
  }

  // Filters fieldNames
  hierarchyElementFamilyFieldName = () => {
    return 'HierarchyFamilyId';
  };
  hierarchyElementIdFieldName = () => {
    return 'HierarchyElementId';
  };
  startDateFieldName = () => {
    return 'StartDate';
  };
  endDateFieldName = () => {
    return 'EndDate';
  };
  waterBalanceTypeFieldName = () => {
    return 'WaterBalanceType';
  };
  waterBalanceDimensionFieldName = () => {
    return 'Dimension';
  };

  ngOnInit(): void {
    super.ngOnInit();
    this._route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.readNavigationParameters(params);
      this.init();
      this.initializeRightPanelComponents();
    });
  }

  readNavigationParameters(params: Params) {
    const queryParams = params;

    if (queryParams.hierarchyElementIds) {
      this.useGridPersistence = false;
      const hierarchyElementIds =
        typeof queryParams.hierarchyElementIds === 'string'
          ? [queryParams.hierarchyElementIds]
          : queryParams.hierarchyElementIds;
      // this.setFilteredZones(hierarchyElementIds);
      this.selectedHierarchyElements = hierarchyElementIds;
    }

    this.selectedHierarchyFamilyId = queryParams.hierarchyFamilyId;
  }

  private initializeRightPanelComponents() {
    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      const panelSettings = new TabDetailPanelSettings();
      panelSettings.addComponent(WaterBalanceChartComponent, ts.chart);
      this.rightPanelDetailsTitle = ts.details;
      this.rightPanelService.setTabSettings(panelSettings);
    });
  }

  init(): void {
    this.loadState();
  }

  /**
   * Load all default values for the filters before loading them.
   */
  loadState() {
    const filters = [];
    const waterBalanceFilters = this.getPersisted(
      TabDetailParameterName.basicFilters,
      new Map<string, BasicFilter>()
    );

    const persistedStartDate = this.loadPersistedDate(
      this.startDateFieldName(),
      this.getDefaultStartDate(),
      false,
      true
    );

    const hasEndDatePersisted =
      this.getPersisted(this.endDateFieldName(), null, null, false) != null;

    let persistedEndDate = this.loadPersistedDate(
      this.endDateFieldName(),
      this.getDefaultEndDate(),
      true,
      true
    );

    if (!hasEndDatePersisted) {
      persistedEndDate = this.dateHelperService.addDays(persistedEndDate, 1);
    }

    const persistedFamilyId = this.getPersistedFamily();

    this.selectedHierarchyElements = this.getPersistedHierarchyElements();

    const persistedDimensionTypeId = this.getPersistedSingleValueFilter(
      this.waterBalanceDimensionFieldName(),
      DimensionTypesEnum.Volume
    );

    const persistedWaterBalanceType = this.getPersistedSingleValueFilter(
      this.waterBalanceTypeFieldName(),
      WaterBalanceTypesEnum.BottomUp
    );

    this._globalsService.getDefaultHierarchyFamilyId(persistedFamilyId).subscribe((familyId) => {
      this.selectedHierarchyFamilyId = familyId;
      this.loadPage(
        waterBalanceFilters,
        persistedStartDate,
        persistedEndDate,
        persistedDimensionTypeId,
        persistedWaterBalanceType,
        filters
      );
    });
  }

  getPersistedHierarchyElements(): string[] {
    if (this.selectedHierarchyElements.length > 0) {
      return this.selectedHierarchyElements;
    }

    const persistedHE = this.getPersisted(
      this.hierarchyElementIdFieldName(),
      new BasicFilter(this.hierarchyElementIdFieldName(), [])
    );

    return (persistedHE as BasicFilter).value;
  }

  getPersistedSingleValueFilter(fieldName: string, defaultValue: any) {
    const persistedBasicFilter = this.getPersisted(fieldName, undefined);

    return persistedBasicFilter
      ? (persistedBasicFilter as BasicFilter)?.value[0]?.value
      : defaultValue;
  }

  getPersistedFamily() {
    if (this.selectedHierarchyFamilyId) {
      return this.selectedHierarchyFamilyId;
    }

    const persistedFamilyIdBasicFilter = this.getPersisted(
      this.hierarchyElementFamilyFieldName(),
      new BasicFilter(this.hierarchyElementFamilyFieldName(), this.selectedHierarchyFamilyId)
    );

    return (persistedFamilyIdBasicFilter as BasicFilter)?.value?.shift()?.value;
  }

  /**
   * Loads the page by instantiating the filters with the default config.
   */
  private loadPage(
    waterBalanceFilters: Map<string, BasicFilter>,
    startDate: Date,
    endDate: Date,
    dimensionTypeId: DimensionTypesEnum,
    waterBalanceTypeId: WaterBalanceTypesEnum,
    filters: IFilter[]
  ) {
    const config: WaterBalanceFiltersConfiguration = {
      persistencyArea: this.persistencyArea,
      // Hierarchy tree config
      hierarchyElementFamilyFieldName: this.hierarchyElementFamilyFieldName(),
      hierarchyElementIdFieldName: this.hierarchyElementIdFieldName(),
      hierarchyElementFamily: this.selectedHierarchyFamilyId,
      hierarchyElementId: this.selectedHierarchyElements,
      // Date range config
      startDateFieldName: this.startDateFieldName(),
      endDateFieldName: this.endDateFieldName(),
      dateRange: new DateRange(startDate, endDate),
      defaultDateRange: new DateRange(this.getDefaultStartDate(), this.getDefaultEndDate()),
      // WB Type config
      waterBalanceTypeFieldName: this.waterBalanceTypeFieldName(),
      waterBalanceType: waterBalanceTypeId,
      defaultWaterBalanceType: WaterBalanceTypesEnum.BottomUp,
      // WB Dimension config
      waterBalanceDimensionFieldName: this.waterBalanceDimensionFieldName(),
      waterBalanceDimension: dimensionTypeId,
      defaultWaterBalanceDimension: DimensionTypesEnum.Volume,
      wbDimensionTypeIds: WBDimensionTypes,

      filters,
    };

    this.filtersConfig = config;
    this.wbParams = this.getDefaultParams(config, true);

    if (waterBalanceFilters) {
      this.autoLoad = true;
    }
  }

  /** We set the filters here instead of in onApplyFilters so the persistence can access them. */
  onFilter(filters: Map<string, BasicFilter>): void {
    this.filters = filters;
  }

  onApplyFilters(): void {
    const def = this.getDefaultParams(this.filtersConfig);
    this.wbParams = new WaterBalanceParams({
      dimensionTypeId:
        this.filters.get(this.filtersConfig.waterBalanceDimensionFieldName)?.value[0]?.value ??
        def.dimensionTypeId,
      startDate: this.filters.get(this.filtersConfig.startDateFieldName)?.value ?? def.startDate,
      endDate: this.filters.get(this.filtersConfig.endDateFieldName)?.value ?? def.endDate,
      waterBalanceType:
        this.filters.get(this.filtersConfig.waterBalanceTypeFieldName)?.value[0]?.value ??
        def.waterBalanceType,
      familyId:
        this.filters.get(this.filtersConfig.hierarchyElementFamilyFieldName)?.value[0]?.value ??
        def.familyId,
      hierarchyElementIds:
        this.filters.get(this.filtersConfig.hierarchyElementIdFieldName)?.value ??
        def.hierarchyElementIds,
    });
  }

  /**
   * Gets the default params by using the config.
   */
  getDefaultParams(
    config: WaterBalanceFiltersConfiguration,
    isFromPageLoad: boolean = false
  ): WaterBalanceParams {
    const params = new WaterBalanceParams({
      dimensionTypeId: config.waterBalanceDimension,
      startDate: config.dateRange?.start,
      endDate: isFromPageLoad
        ? this.dateHelperService.addDays(config.dateRange?.end, 1)
        : config.dateRange?.end,
      waterBalanceType: config.waterBalanceType,
      familyId: config.hierarchyElementFamily,
      hierarchyElementIds: config.hierarchyElementId,
    });
    return params;
  }

  filtersParametersChanged(parameters: TabDetailPanelParameters) {
    this.filterDetailsParameters = parameters;
  }

  onSectionSelect(kpis: number[]) {
    const parameters = new TabDetailPanelParameters();

    if (kpis.length) {
      const waterBalanceChartConfiguration = new WaterBalanceChartConfiguration({
        dimensionTypeId: this.wbParams.dimensionTypeId,
        startDate: this.wbParams.startDate,
        endDate: this.wbParams.endDate,
        waterBalanceType: this.wbParams.waterBalanceType,
        familyId: this.wbParams.familyId,
        hierarchyElementIds: this.wbParams.hierarchyElementIds,
        currenctyTypeId: CurrencyTypesEnum.NA,
        kpis,
      });

      parameters.addParameter(
        TabDetailParameterName.waterBalanceChart,
        waterBalanceChartConfiguration
      );
      parameters.addParameter(TabDetailParameterName.elementName, this.rightPanelDetailsTitle);
    }

    this.rightPanelService.setTabParameters(parameters);
  }

  onClickClearFilters() {
    this.clearAll$.next();
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WaterBalanceCrud'; // TODO: check that this is the correct crud.
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  getDefaultStartDate(): Date {
    const startDate = this.getDefaultEndDate();
    startDate.setMonth(startDate.getMonth() - this.defaultMonthSpan);
    return startDate;
  }

  getDefaultEndDate(): Date {
    const endDate = new Date();
    return endDate;
  }

  ngOnDestroy(): void {
    this.persist(TabDetailParameterName.basicFilters, this.filters);
    super.ngOnDestroy();
  }

  onCheckAutoload() {
    this.persistFilters$.next();
  }
}
