import { Component, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { GetValueSelector } from '@common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { CalendarStateFields } from '../../models/calendar-state-fields';

const COMPONENT_SELECTOR = 'wlm-calendar-configuration-widget';
export const CALENDAR_CONFIGURATION_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './calendar-configuration-widget.component.html',
  styleUrls: ['./calendar-configuration-widget.component.scss'],
  providers: [ReduxStateService],
})
export class CalendarConfigurationWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  calendarId: string;

  get componentName(): string {
    return 'CalendarConfigurationWidgetComponent';
  }

  phTitleKey = `${this.T_SCOPE}.calendar-empty-title`;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  onWidgetInit(): void {
    this.listenState(CalendarStateFields.selectedCalendar).subscribe((calendarSelected: any) => {
      this.calendarId = calendarSelected?.calendarId;
    });
  }

  ngOnDestroy() {}

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }
}
