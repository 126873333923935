// prettier-ignore
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DynamicLayoutComponentApi } from '@common-modules/dynamic-layout/dynamic-layout/dynamic-layout-component-api';
import { DynamicLayoutComponent } from '@common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutIdentity } from '@common-modules/dynamic-layout/models/dynamic-layout-identity';
import { DynamicLayoutSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutSettingsLoadOptions } from '@common-modules/dynamic-layout/models/dynamic-layout-settings-load-options';
import { DynamicSettings } from '@common-modules/dynamic-layout/models/dynamic-settings';
import { WidgetDefinitionSettings } from '@common-modules/dynamic-layout/models/widget-definition-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { BiService } from '../../services/bi.service';

const COMPONENT_SELECTOR = 'wlm-widgets-container';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './widgets-container.component.html',
  styleUrls: ['./widgets-container.component.scss'],
})
export class WidgetsContainerComponent implements OnInit {
  @ViewChild(DynamicLayoutComponent) set queryDynamicLayoutComponent(
    dynamicLayoutComponent: DynamicLayoutComponentApi
  ) {
    this._dynamicLayoutComponent = dynamicLayoutComponent;
    if (this._dynamicLayoutComponent) {
      this._dynamicLayoutComponentReady$.next();
      this.layoutComponentLoaded.emit(this._dynamicLayoutComponent);
    }
  }

  private _settingsKeys: DynamicSettings;
  get settingsKeys(): DynamicSettings {
    return this._settingsKeys;
  }
  /**
   * First flow. When loading, the page sends the settings keys and loads
   * the previously configured dashboard for the current user.
   * In BI, disablePersistencyLoad is always set to true.
   */
  @Input() set settingsKeys(value: DynamicSettings) {
    this._settingsKeys = value;
    if (this.settingsKeys) {
      this._biService.getConfiguredDashboard(this.settingsKeys).subscribe((settings) => {
        settings.layoutArea = value.settingArea;
        settings.layoutKey = value.settingKey;
        settings.showCloseWidgetIcon = true;

        this.settingsToLoad = new DynamicLayoutSettingsLoadOptions({
          settings,
        });
      });
    }
  }

  private _settingsToLoad: DynamicLayoutSettingsLoadOptions;
  get settingsToLoad(): DynamicLayoutSettingsLoadOptions {
    return this._settingsToLoad;
  }
  /**
   * Enables two flows. The first is invoked from @Input() settingKeys, and the second is when
   * an external component (ie a popup) declares a specific layout to be used.
   * In BI, disablePersistencyLoad is always set to true.
   */
  @Input() set settingsToLoad(value: DynamicLayoutSettingsLoadOptions) {
    this._settingsToLoad = this._objectHelperService.serializedClone(value);
    if (this.settingsToLoad) {
      if (!this.settingsToLoad.loadOptions) {
        this.settingsToLoad.loadOptions = {};
      }
      this.settingsToLoad.loadOptions.disablePersistencyLoad = true;
    }
    this.syncedSettings = this._objectHelperService.serializedClone(this.settingsToLoad?.settings);
  }
  @Input() instanceKey: string;

  private _newDefinition: WidgetDefinitionSettings;
  get newDefinition(): WidgetDefinitionSettings {
    return this._newDefinition;
  }
  @Input() set newDefinition(value: WidgetDefinitionSettings) {
    this._newDefinition = value;
    if (this.newDefinition) {
      if (!this._dynamicLayoutComponentReadySubs.closed) {
        this._dynamicLayoutComponentReadySubs.unsubscribe();
      }
      this._dynamicLayoutComponentReadySubs = this._dynamicLayoutComponentReady$
        .pipe(take(1))
        .subscribe(() => {
          this._dynamicLayoutComponent.addWidgetDefinition(this.newDefinition);
        });
    }
  }

  @Output() settingsChanged = new EventEmitter<DynamicLayoutSettings>();
  @Output() layoutComponentLoaded = new EventEmitter<DynamicLayoutComponentApi>();
  @Output() currentLayoutIdentity = new EventEmitter<DynamicLayoutIdentity>();

  syncedSettings: DynamicLayoutSettings;
  readonly allowEmptyRoot = true;

  private _dynamicLayoutComponent: DynamicLayoutComponentApi;
  private _dynamicLayoutComponentReady$ = new ReplaySubject<void>(1);
  private _dynamicLayoutComponentReadySubs = new Subscription();
  readonly T_SCOPE = `${AppModules.BI}.${COMPONENT_SELECTOR}`;
  private _newWidgetInstanceKey: string;

  constructor(
    public injector: Injector,
    private readonly _objectHelperService: ObjectHelperService,
    private readonly _spinnerService: SpinnerService,
    private readonly _biService: BiService
  ) {}

  ngOnInit(): void {}

  onLayoutLoading(isLoading: boolean): void {
    if (this._newWidgetInstanceKey) {
      this.setLoading(isLoading, this._newWidgetInstanceKey);
    }
  }

  onLayoutSettingsChanged(changedSettings: DynamicLayoutSettings): void {
    this.syncedSettings = changedSettings;
    this.settingsChanged.emit(this.syncedSettings);
  }

  onLayoutIdentityChanged(identity: DynamicLayoutIdentity): void {
    this.currentLayoutIdentity.emit(identity);
  }

  resetToDefault(): void {
    this._dynamicLayoutComponent.resetToDefault();
  }

  onResize(event: any) {}

  private setLoading(isLoading: boolean, newWidgetInstanceKey: string): void {
    const loadingKey = [this.instanceKey, newWidgetInstanceKey].join('#');
    this._spinnerService.setLoading(isLoading, loadingKey);
  }
}
