import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IHierarchyElementPathDto } from '@common-modules/dependencies/he/hierarchy-element-path.dto';
import { treeSelectionMode } from '@common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { HierarchyElementPathFilter } from '@common-modules/dependencies/wlm-filters/i-filters/hierarchy-element-path-filter';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';

const COMPONENT_SELECTOR = 'wlm-he-tree-family-form-element';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './he-tree-family-form-element.component.html',
  styleUrls: ['./he-tree-family-form-element.component.scss'],
})
export class HeTreeFamilyFormElementComponent {
  @Input() filterSettings: FilterGroupSettings;
  @Input() heCtrl: FormControl;
  @Input() familyCtrl: FormControl;
  @Input() fieldLabelKey: string;
  @Input() disabled: boolean;

  disableAutoload = false;

  selectedHierarchyElements: string[] | string;
  selectedHierarchyFamilyId: string;

  onApplyFilters(_?): void {
    this.heCtrl.setValue(this.selectedHierarchyElements);
    this.familyCtrl.setValue(this.selectedHierarchyFamilyId);
  }

  onCheckAutoload(): void {
    if (!this.disableAutoload) {
      this.onApplyFilters();
      this.disableAutoload = true;
    }
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const selectedHe = filtersParameters?.exportableFilter['hierarchyElementsIds'];
    const selectedHePathFilter = selectedHe as HierarchyElementPathFilter;
    this.selectedHierarchyFamilyId = selectedHePathFilter.hierarchyFamilyId;
  }

  onSelectedNodesChanged(nodes: IHierarchyElementPathDto[]): void {
    if (this.isSingleSelection()) {
      if (nodes.length) {
        this.selectedHierarchyElements = nodes[0].descendant;
      } else {
        this.selectedHierarchyElements = null;
      }
    } else {
      this.selectedHierarchyElements = nodes.map((node) => node.descendant);
    }
  }

  isSingleSelection = () =>
    this.filterSettings.additionalParams?.selectionMode?.value === treeSelectionMode.single;
}
