import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LinksCrudService {
  private networkElementTypeIdSource = new ReplaySubject<number>();
  networkElementTypeId$ = this.networkElementTypeIdSource.asObservable();

  setNetworkElementTypeId(id: number) {
    this.networkElementTypeIdSource.next(id);
  }
}
