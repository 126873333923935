import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LargeUserSignalDto } from '@common-modules/dependencies/shared/model/large-user-signal.dto';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DragListCardSettings } from '@common-modules/shared/core/drag-list-card/drag-list-card-settings';
import { DragListCustomSettings } from '@common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { DragListSettings } from '@common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { Subject } from 'rxjs';
import { NeSignalSelectionPopupConfiguration } from '../ne-signal-selection-popup-configuration';
import { NeSignalSelectionPopupResult } from '../ne-signal-selection-popup-result';

const COMPONENT_SELECTOR = 'wlm-ne-signal-selection-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-signal-selection-popup.component.html',
  styleUrls: ['./ne-signal-selection-popup.component.scss'],
})
export class NeSignalSelectionPopupComponent implements OnInit {
  private _titleFieldName = 'title';
  private _pointIdFieldName = 'pointId';
  private _pointDescriptionFieldName = 'pointDescription';
  private _pagesize = 50;

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  get componentName(): string {
    return 'NeSignalSelectionPopupComponent';
  }

  signalsPopupSettings: NeSignalSelectionPopupConfiguration;
  settings: DragListSettings;
  settingsCustom: DragListCustomSettings;
  cardSettings: DragListCardSettings;
  queryParams: Map<string, any>;
  configuredSignals: LargeUserSignalDto[] = [];
  excludedSignals: LargeUserSignalDto[] = [];
  refreshList$ = new Subject<void>();
  isLoading = false;
  isFirstLoad = true;

  constructor(
    private dialogRef: MatDialogRef<NeSignalSelectionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) popupConfiguration: NeSignalSelectionPopupConfiguration,
    private _arrayHelperService: ArrayHelperService
  ) {
    this.signalsPopupSettings = popupConfiguration;
    const sortedCurrentConfiguration = this._arrayHelperService.sortArrayObjectCaseInsensitive(
      [...popupConfiguration.currentConfiguration],
      this._pointDescriptionFieldName
    );

    this.configuredSignals = sortedCurrentConfiguration;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.prepareListsSettings();
    this.updateQueryParams();

    this.excludedSignals = [...this.signalsPopupSettings.excludedConfiguration];
  }

  saveConfiguration() {
    const sortedConfiguredSignals = this._arrayHelperService.sortArrayObjectCaseInsensitive(
      this.configuredSignals,
      this._pointDescriptionFieldName
    );

    const result: NeSignalSelectionPopupResult = {
      hasToSave: true,
      newConfiguration: sortedConfiguredSignals,
    };

    this.dialogRef.close(result);
  }

  close() {
    const result: NeSignalSelectionPopupResult = {
      hasToSave: false,
      newConfiguration: null,
    };

    this.dialogRef.close(result);
  }

  prepareListsSettings() {
    this.settings = new DragListSettings({
      dataService: this.signalsPopupSettings.oDataService,
      pageSize: this._pagesize,
      orderBy: [{ field: this._pointDescriptionFieldName, dir: 'asc' }],
      useQueryParams: true,
      displayFieldName: this._titleFieldName,
      oDataFilters: this.signalsPopupSettings.oDataFilters,
      useGlobalSpinner: false,
    });

    this.settingsCustom = new DragListCustomSettings({
      hideFilter: true,
      emptyLegendKey: `${this.T_SCOPE}.messages.drag-list-empty-message`,
    });

    this.cardSettings = new DragListCardSettings({
      fields: [this._pointIdFieldName, this._pointDescriptionFieldName],
      fieldLabels: {
        pointDescription: this._pointDescriptionFieldName,
        pointId: this._pointIdFieldName,
      },
      iconName: 'card-handler',
      isSvg: true,
    });
  }

  updateQueryParams() {
    const newParams = new Map<string, any>();

    if (
      this.signalsPopupSettings.queryFieldNames?.length &&
      this.signalsPopupSettings.selectedItem
    ) {
      this.signalsPopupSettings.queryFieldNames.forEach((fieldName) => {
        newParams.set(fieldName, this.signalsPopupSettings.selectedItem[fieldName]);
      });
    }

    this.queryParams = newParams;
  }

  onSignalsChanged($event) {
    if (this.isFirstLoad) {
      this.isFirstLoad = false;
      return;
    }

    this.refreshList$.next();
  }

  onDroppedElement($event) {
    const newExcludedSignals = [...this.excludedSignals];
    newExcludedSignals.push($event);
    this.excludedSignals = [...newExcludedSignals];
  }

  onfilterChange() {
    this.isLoading = true;
  }
  onAvailableSignalsLoaded() {
    this.isLoading = false;
  }
}
