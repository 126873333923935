import { INeHeFilterSettings } from '@common-modules/common-filters/filters/ne-type-filter/nehe-filter-settings';
import { ElementTargetFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/element-target-fi-settings';
import { HierarchyTreeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/hierarchy-tree-fi-settings';

export class NEConfigFilterConfiguration {
  // settings
  persistencyArea: string;
  pagePersistencyArea: string;
  heSettings: INeHeFilterSettings;
  neSettings: INeHeFilterSettings;
  targetSettings: ElementTargetFISettings;
  treeSettings: HierarchyTreeFISettings;

  // default values
  defaultFamilyId: string;
  defaultHETypeIds: any[];
  defaultNETypeIds: any[];

  // field names
  hierarchyElementFamilyFieldName: string;
  hierarchyElementIdFieldName: string;

  // persisted values
  initialFamilyId: string;
  initialHierarchyElementId: string[];

  constructor(init: NEConfigFilterConfiguration) {
    Object.assign(this, init);
  }
}
