import { Component, Input, OnInit } from '@angular/core';
import { PressureSettings } from '@common-modules/dependencies/ne-configuration/pressure-settings';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-pressure';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-pressure.component.html',
  styleUrls: ['./ne-configuration-pressure.component.scss'],
})
export class NeConfigurationPressureComponent implements OnInit {
  @Input() selectedNE: INetworkElementDto;
  @Input() widgetId: string;
  @Input() pageId: string;
  @Input() pressureSettings: PressureSettings;
  pressureSettingsOtp: PressureSettings;

  ngOnInit() {
    this.pressureSettingsOtp = this.pressureSettings;
  }
}
