import { Component, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { IPendingChangesChecker } from '@common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { SelectDimensionAction } from '../../state/uom.action';
import { UoMScopes } from '../uom-scopes';

const COMPONENT_SELECTOR = 'wlm-uom-selection-widget';
export const UOM_SELECTION_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './uom-selection-widget.component.html',
  styleUrls: ['./uom-selection-widget.component.scss'],
  providers: [ReduxStateService],
})
export class UomSelectionWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit, IPendingChangesChecker
{
  widgetInstanceKey: string;
  pageId: string;

  // Not all actions/selectors must have the same action settings.
  private _scopeSettings = new StateScopeSettings({
    scope: UoMScopes.Main,
  });

  get componentName(): string {
    return 'UomSelectionWidgetComponent';
  }

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);

    this.widgetInstanceKey = widgetSettings.widgetInstanceKey;
    this.pageId = widgetSettings.page;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onWidgetInit(): void {}

  onSelectedChange(dimensionId: number) {
    this.checkPendingChanges(this.pageId).subscribe((_) =>
      this._state.dispatch(new SelectDimensionAction(dimensionId, this._scopeSettings))
    );
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(untilDestroyed(this));
  }
}
