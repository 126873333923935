import { Pipe, PipeTransform } from '@angular/core';
import { SharedConstantsService } from '@common-modules/shared/constants/shared-constants.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * IMPORTANT: As this pipe now uses translations, it must be async.
 * This means that it must be used like " myValue | async | alarmSeverityName "
 */

// Helper type.
type Option = { value: number; name: string };

@Pipe({ name: 'alarmSeverityName' })
export class AlarmSeverityNamePipe implements PipeTransform {
  constructor(private sharedConstantsService: SharedConstantsService) {}

  transform(value: number): Observable<string> {
    return this.sharedConstantsService.getSeveritiesMapping().pipe(
      switchMap((mapping) => {
        const found: Option = this.mapToArray(mapping).find((option) => option.value === value);
        return of(found ? found.name : '');
      })
    );
  }

  /**
   * Convert a map to an array of options.
   */
  private mapToArray(mapping: Map<number, string>): Option[] {
    return Array.from(mapping.keys()).map((key) => ({
      value: key,
      name: mapping.get(key),
    }));
  }
}
