import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import { AppPermissionsSettings } from '@common-modules/shared/auth/services/app-permissions-settings';

const waterLossRoutePermissions = new Map<NavKeys, [string, string][]>([
  [NavKeys.Home, []],
  [NavKeys.LeakageCalculationModule, [['LeakageCalculationCrud', 'r']]],
  [NavKeys.DistributionNetwork, [['WLMDistributionNetworkCrud', 'r']]],
  [NavKeys.TransmissionNetwork, [['WLMTransmissionNetworkCrud', 'r']]],
  [NavKeys.PressureManagementModule, [['WLMPressureManagementCrud', 'r']]],
  [NavKeys.PressureMonitoring, [['WLMPressureMonitoringCrud', 'r']]],
  [NavKeys.LeakageCalculationModule, [['LeakageCalculationCrud', 'r']]],
  [NavKeys.DataVisualization, [['WLMDataVisualizationCrud', 'r']]],
  [NavKeys.DataValidation, [['WLMDataValidationCrud', 'r']]],
  [NavKeys.CustomerDetails, [['WLMCustomerDetailsCrud', 'r']]],

  [NavKeys.AlcModule, [['ActiveLeakageControlCrud', 'r']]],
  [NavKeys.Prioritisation, [['PrioritisationCrud', 'r']]],
  [
    NavKeys.ActivityRaising,
    [
      ['ActivityRaisingCrud', 'r'],
      ['ActivityRaisingCrud', 'u'],
    ],
  ],
  [NavKeys.Activities, [['ActivitiesCrud', 'r']]],
  [NavKeys.Leaks, [['LeaksCrud', 'r']]],
  [NavKeys.Campaigns, [['WLMCampaignCrud', 'r']]],

  [NavKeys.WaterBalanceModule, [['WaterBalanceCrud', 'r']]],
  [NavKeys.WaterBalance, [['WaterBalanceCrud', 'r']]],

  [NavKeys.BiModule, [['WLMBusinessIntelligenceCrud', 'r']]],
  [NavKeys.Bi, [['WLMBusinessIntelligenceCrud', 'r']]],
  [NavKeys.BIHome, [['WLMBusinessIntelligenceCrud', 'r']]],

  [NavKeys.DataAccessModule, [['DataAccessCrud', 'r']]],
  [NavKeys.Map, [['WLMMapCrud', 'r']]],
  [NavKeys.DataValidation, [['WLMDataValidationCrud', 'r']]],
  [NavKeys.DataVisualization, [['WLMDataVisualizationCrud', 'r']]],
  [NavKeys.LeakageReporting, [['WLMLeakageReportingCrud', 'r']]],
  [NavKeys.CustomerDetails, [['WLMCustomerDetailsCrud', 'r']]],
  [NavKeys.Schematics, [['WLMSchematicsCrud', 'r']]],

  [NavKeys.MonitoringModule, [['MonitoringCrud', 'r']]],
  [NavKeys.Notifications, [['WLMNotificationsCrud', 'r']]],

  [NavKeys.ConfigurationModule, [['ConfigurationCrud', 'r']]],
  [NavKeys.TelemetryPoints, [['WLMTelemetryPointsCrud', 'r']]],
  [NavKeys.AlarmsConfiguration, [['WLMAlarmsCrud', 'r']]],
  [NavKeys.AlarmsCreationTelemetry, [['WLMAlarmsCrud', 'c']]],
  [NavKeys.AlarmsCreationAlgorithms, [['WLMAlarmsCrud', 'c']]],
  [NavKeys.NetworkElementConfig, [['WLMNetworkCrud', 'r']]],
  [NavKeys.NetworkElementDetails, [['WLMNetworkCrud', 'r']]],
  [NavKeys.Algorithms, [['WLMAlgorithmsCrud', 'r']]],
  [NavKeys.Hierarchy, [['WLMHierarchyCrud', 'r']]],
  [NavKeys.UoM, [['WLMUnitsOfMeasurementCrud', 'r']]],
  [NavKeys.Calendar, [['WLMCalendarsCrud', 'r']]],
  [NavKeys.MergedZones, [['WLMMergedZonesCrud', 'r']]],

  [NavKeys.AdministrationModule, [['WLMAdministrationCrud', 'r']]],
  [NavKeys.Users, [['WLMAdministrationCrud', 'r']]],
  [NavKeys.Groups, [['WLMAdministrationCrud', 'r']]],

  [NavKeys.LogsModule, [['WLMLogCrud', 'r']]],
  [NavKeys.LogsInterfaces, [['WLMInterfacesCrud', 'r']]],
  [NavKeys.LogsSystem, [['WLMSystemCrud', 'r']]],
  [NavKeys.Audits, [['WLMAuditsCrud', 'r']]],
]);

const waterLossPermissionsHierarchy = new Map<NavKeys, NavKeys>([
  [NavKeys.DistributionNetwork, NavKeys.LeakageCalculationModule],
  [NavKeys.TransmissionNetwork, NavKeys.LeakageCalculationModule],
  [NavKeys.PressureMonitoring, NavKeys.PressureManagementModule],
  [NavKeys.Prioritisation, NavKeys.AlcModule],
  [NavKeys.ActivityRaising, NavKeys.AlcModule],
  [NavKeys.Activities, NavKeys.AlcModule],
  [NavKeys.Leaks, NavKeys.AlcModule],
  [NavKeys.Campaigns, NavKeys.AlcModule],
  [NavKeys.WaterBalance, NavKeys.WaterBalanceModule],
  [NavKeys.Bi, NavKeys.BiModule],
  [NavKeys.Map, NavKeys.DataAccessModule],
  [NavKeys.DataValidation, NavKeys.DataAccessModule],
  [NavKeys.DataVisualization, NavKeys.DataAccessModule],
  [NavKeys.LeakageReporting, NavKeys.DataAccessModule],
  [NavKeys.CustomerDetails, NavKeys.DataAccessModule],
  [NavKeys.Schematics, NavKeys.DataAccessModule],
  [NavKeys.Notifications, NavKeys.MonitoringModule],
  [NavKeys.TelemetryPoints, NavKeys.ConfigurationModule],
  [NavKeys.AlarmsConfiguration, NavKeys.ConfigurationModule],
  [NavKeys.NetworkElementConfig, NavKeys.ConfigurationModule],
  [NavKeys.Algorithms, NavKeys.ConfigurationModule],
  [NavKeys.Hierarchy, NavKeys.ConfigurationModule],
  [NavKeys.UoM, NavKeys.ConfigurationModule],
  [NavKeys.Calendar, NavKeys.ConfigurationModule],
  [NavKeys.MergedZones, NavKeys.ConfigurationModule],
  [NavKeys.AlarmsCreationTelemetry, NavKeys.ConfigurationModule],
  [NavKeys.AlarmsCreationAlgorithms, NavKeys.ConfigurationModule],
  [NavKeys.NetworkElementDetails, NavKeys.ConfigurationModule],
  [NavKeys.Algorithms, NavKeys.ConfigurationModule],
  [NavKeys.Users, NavKeys.AdministrationModule],
  [NavKeys.Groups, NavKeys.AdministrationModule],
  [NavKeys.LogsInterfaces, NavKeys.LogsModule],
  [NavKeys.LogsSystem, NavKeys.LogsModule],
  [NavKeys.Audits, NavKeys.LogsModule],
]);

export const waterLossAppPermissions: AppPermissionsSettings = {
  routePermissions: waterLossRoutePermissions,
  permissionsHierarchy: waterLossPermissionsHierarchy,
};
