import { Injectable, Injector } from '@angular/core';
import { NetworkElementAttributeTypeCrudDto } from '@common-modules/dependencies/ne/network-element-attribute-type-crud.dto';
import { NetworkElementAttributeLinkDto } from '@common-modules/dependencies/ne/network-element-link-dto';
import { map, Observable, of } from 'rxjs';

import { GenericCrudService } from '@common-modules/generic-crud/generic-crud.service';
import { BaseService } from '@common-modules/shared/base.service';
import { LinksCrudService } from './links-crud-service';

@Injectable()
export class NetNeatLinkCrudService extends BaseService implements GenericCrudService<any, number> {
  private _entityUrl: string;
  private networkElementTypeId: number;

  constructor(injector: Injector, private readonly _linksCrudService: LinksCrudService) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/network-element-attribute-types`;
  }

  getAll(data: { networkElementTypeId: number }): Observable<NetworkElementAttributeTypeCrudDto[]> {
    if (!data?.networkElementTypeId) {
      return of([]);
    }
    this.networkElementTypeId = data.networkElementTypeId;
    return this.httpCacheClient.get(`${this._entityUrl}/link/${data.networkElementTypeId}`);
  }

  create(entity: NetworkElementAttributeLinkDto): Observable<NetworkElementAttributeLinkDto> {
    const { networkElementTypeId, ...requestBody } = entity;
    return this.httpCacheClient.post<NetworkElementAttributeLinkDto>(
      `${this._entityUrl}/link/${networkElementTypeId}`,
      [requestBody]
    );
  }

  update(entity: NetworkElementAttributeLinkDto): Observable<NetworkElementAttributeLinkDto> {
    const { networkElementTypeId, ...requestBody } = entity;
    return this.httpCacheClient.post<NetworkElementAttributeLinkDto>(
      `${this._entityUrl}/link/${networkElementTypeId}`,
      [requestBody]
    );
  }

  delete(id: number): Observable<boolean> {
    this._linksCrudService.networkElementTypeId$.subscribe((id) => {
      this.networkElementTypeId = id;
    });
    return this.httpCacheClient
      .delete(`${this._entityUrl}/link/${this.networkElementTypeId}/${id}`)
      .pipe(map(() => true));
  }

  deleteCustomConfiguration(networkElementTypeId: number): Observable<boolean> {
    return this.httpCacheClient
      .delete(`${this._entityUrl}/link/${networkElementTypeId}`)
      .pipe(map(() => true));
  }

  getId(entity: NetworkElementAttributeTypeCrudDto): number {
    return entity.networkElementAttributeTypeId;
  }

  validate(entity: NetworkElementAttributeTypeCrudDto): Observable<boolean> {
    return this.getAll({ networkElementTypeId: this.networkElementTypeId }).pipe(
      map((links) => {
        const duplicated = links.find(
          (link) =>
            link.gisOrder === entity.gisOrder &&
            link.networkElementAttributeTypeId !== entity.networkElementAttributeTypeId
        );
        return !duplicated;
      })
    );
  }
}
