import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SignalInfoDto } from '@common-modules/dependencies/shared/model/signal-info.dto';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { UtilsHelperService } from '@common-modules/shared/helpers/utils-helper.service';

import { CanSaveHistoricalForm } from '../../../shared/historical/can-save-historical-form';
import { HistoricalFormSettings } from '../../../shared/historical/historical-form-settings';
import { LargeUserSignalVersionDto } from '../../../shared/model/signals/large-user-signal-version.dto';
import { LargeUserSignalSelectionPopupResponse } from './large-user-signal-selection-popup-response';
import { LargeUserSignalSelectionPopupSettings } from './large-user-signal-selection-popup-settings';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-large-user-historical-creation-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-large-user-historical-creation-popup.component.html',
  styleUrl: './ne-configuration-large-user-historical-creation-popup.component.scss',
})
export class NeConfigurationLargeUserHistoricalCreationPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  isLoading = false;
  canSave = true;

  popupSettings: LargeUserSignalSelectionPopupSettings;
  formSettings: HistoricalFormSettings = {};
  formModel: LargeUserSignalVersionDto;
  initialFormModel: LargeUserSignalVersionDto;

  configuredSignals: SignalInfoDto[] = [];

  constructor(
    private dialogRef: MatDialogRef<NeConfigurationLargeUserHistoricalCreationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) popupSettings: LargeUserSignalSelectionPopupSettings,
    private _utilsService: UtilsHelperService
  ) {
    this.popupSettings = popupSettings;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.buildFormSettings();
  }

  close() {
    this.dialogRef.close();
  }

  saveConfiguration() {
    const configurations: LargeUserSignalVersionDto[] = [];
    this.configuredSignals.forEach((signal) => {
      const configuration = new LargeUserSignalVersionDto({
        signalId: signal.signalId,
        pointId: signal.pointId,
        pointDescription: signal.pointDescription,
        validFrom: this.formModel.validFrom,
        validTo: this.formModel.validTo,
        hierarchyElementId: this.formModel.hierarchyElementId,
        hierarchyElementName: this.formModel.hierarchyElementName,
        customerId: this.formModel.customerId,
        largeUserId: this.formModel.largeUserId,
        propertyId: this.formModel.propertyId,
        id: this._utilsService.generateGuid(),
      });

      configurations.push(configuration);
    });

    const popupResponse = new LargeUserSignalSelectionPopupResponse({ configurations });
    this.dialogRef.close(popupResponse);
  }

  onLoadingChanged(isLoading) {
    this.isLoading = isLoading;
  }

  onSignalsChange(signals: SignalInfoDto[]) {
    this.configuredSignals = signals;
  }

  onCanSaveChanged(data: CanSaveHistoricalForm) {
    this.canSave = data.isValid;
  }

  onIsValidChanged(isValid: boolean) {
    this.canSave = isValid;
  }

  onEdit(editedModel: LargeUserSignalVersionDto): void {
    this.formModel = new LargeUserSignalVersionDto(editedModel);
  }

  private buildFormSettings(): void {
    this.formSettings = new HistoricalFormSettings({
      minDate: this.popupSettings.minDate,
      maxDate: this.popupSettings.maxDate,
      editOnlyDates: false,
      emitAtFormInitialization: true,
    });

    this.initialFormModel = new LargeUserSignalVersionDto({
      validFrom: this.popupSettings.minDate,
      validTo: this.popupSettings.maxDate,
      propertyId: null,
      customerId: null,
      largeUserId: null,
      id: null,
      signalId: null,
      pointId: null,
      pointDescription: null,
      hierarchyElementId: null,
      hierarchyElementName: null,
    });
  }
}
