import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LeaksDto } from '@common-modules/dependencies/alc/leaks.dto';
import { HierarchyElementFiltersDataDto } from '@common-modules/dependencies/he/hierarchy-element-filters-data.dto';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WNavigateSettings } from '@common-modules/dependencies/navigation/w-navigate-by';
import { ActivityCategoryTypesFilter } from '@common-modules/dependencies/wlm-filters/i-filters/activity-category-types-filter';
import { ActivityStatusFilter } from '@common-modules/dependencies/wlm-filters/i-filters/activity-status-filter';
import { HierarchyElementPathFilter } from '@common-modules/dependencies/wlm-filters/i-filters/hierarchy-element-path-filter';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthorizeService } from '@common-modules/shared/auth/services/authorize.service';
import { WlmElementExtended } from '@common-modules/shared/charts/model/elements/element-extended';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { GridBtnsEvent } from '@common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from '@common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { NavMenuBuilderHelperService } from '@common-modules/shared/helpers/navmenu-builder-helper.service';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { ChartType } from '@common-modules/wlm-charts/core/models/chart-type.enum';
import { CustomWorkspaceChartDataParameters } from '@common-modules/wlm-charts/core/models/custom-workspace-chart-data-parameters';
import { CustomWorkspaceChartSettings } from '@common-modules/wlm-charts/core/models/custom-workspace-chart-settings';
import { EntityTypes } from '@common-modules/wlm-charts/core/models/entity-types';
import { PeriodTypesEnum } from '@common-modules/wlm-charts/core/models/period-types.enum';
import { TimeSelectorChartSettings } from '@common-modules/wlm-charts/core/models/time-selector-settings';
import { TimeSelectorChartWidgetComponent } from '@common-modules/wlm-charts/core/time-selector-chart-widget/time-selector-chart-widget.component';
import { GridPersistedElements } from '@common-modules/wlm-grid/generic-grid/generic-grid-constants';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataVisualizationNavigationService } from '../../data-visualization-shared/services/data-visualization-navigation.service';
import { MapHelperService } from '../../map/map-helper.service';
import { MapParameters } from '../../map/map-parameters';
import { MapRelatedComponent } from '../../map/map-related/map-related.component';
import { NavigationElement } from '../../map/navigation-element';
import { NavigationElementType } from '../../map/navigation-element-type';
import { NavItemsConfiguration } from '../../shared/model/navigation/navitem-configuration';
import { NavMenuConfiguration } from '../../shared/model/navigation/navmenu-configuration';
import { ActivitiesGridFilterValidations } from '../activities-grid/activities-grid-filter-validations';
import { AlcLeaksActivitiesPanelComponent } from './alc-leaks-activities-panel/alc-leaks-activities-panel.component';
import { LeaksGridFilterConfiguration } from './alc-leaks-grid-filter/leaks-grid-filter-configuration';
import { AlcLeaksParentActivityComponent } from './alc-leaks-parent-activity/alc-leaks-parent-activity.component';

const COMPONENT_SELECTOR = 'wlm-alc-leaks-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alc-leaks-page.component.html',
  styleUrls: ['./alc-leaks-page.component.scss'],
})
export class AlcLeaksPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild(GenericGridComponent) public leaksGrid: GenericGridComponent;

  private readonly _settingArea = 'MapSetting';
  private readonly _settingKey = `${this.persistencyArea}_MapFilter`;
  private readonly _mapParametersLayers = [
    1, 777001, 777002, 777003, 777011, 777012, 777101, 777102, 777104, 777111, 777112,
  ];
  private readonly _commonLeakYears = [0, -1];

  private readonly _chartWorkspaceName = 'LeaksDetailsChart';

  gridFilters: DataBindingFilters;
  private filterDetailsParameters: TabDetailPanelParameters;
  private selectedLeak: LeaksDto;
  private _selectedLeaks: LeaksDto[];

  private _isNavigatingFromAnotherPage: boolean;
  gridSettings: GridSetting;

  selectedHierarchyElementsFromNavigation: string[];
  selectedHierarchyFamilyIdFromNavigation: string;
  startDateFromNavigation: Date;
  endDateFromNavigation: Date;
  orderNumberFromNavigation: string;
  private _hasBeenSelected = false;
  gridSettingsReady$ = new ReplaySubject<GridSetting>();
  autoloadPerformed = false;
  offsetStartDate = 3; //3 months

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  public get autoLoad(): boolean {
    return this._isNavigatingFromAnotherPage;
  }
  public set autoLoad(v: boolean) {
    this._isNavigatingFromAnotherPage = v;
  }

  public get selectedLeaks(): LeaksDto[] {
    return this._selectedLeaks;
  }

  public set selectedLeaks(value: LeaksDto[]) {
    this._selectedLeaks = value;
    this.updateNavigationParams();
  }

  navigations: DropdownNavigationItem[] = [];
  gridFiltersForBinding: DataBindingFilters;

  canNavigateToLeak = false;
  selectedUrlParams: TabDetailPanelParameters;
  selectedHierarchyElements: string[];
  selectedHierarchyFamilyId: string;
  leaksGridFilterConfiguration: LeaksGridFilterConfiguration;
  configReady = false;
  defaultHierarchyElementId = '';

  // Filters fieldNames
  hierarchyElementIdFieldName = 'HierarchyElementId';
  hierarchyElementFamilyFieldName = 'HierarchyFamilyId';
  dateFieldName = 'CreationDate';
  // tslint:disable-next-line: max-line-length
  startDateKey = `${this.dateFieldName}#StartDate`; // <-- This formatting is necessary due to daterange uses the same fieldname in this page
  endDateKey = `${this.dateFieldName}#EndDate`;

  //Activity filters fieldNames
  startDateFieldName = 'StartDate';
  endDateFieldName = 'EndDate';
  repairFieldName = 'IsRepairActivity';
  detectionFieldName = 'IsDetectionActivity';
  statusFieldName = 'ilpmsStatus';
  orderNumberFieldName = 'saporderNumber';

  hasDateFromNavigation = false;

  gridName = 'Leaks';
  canLoad: boolean;
  clearAll$ = new Subject<void>();
  persistFilters$ = new Subject<void>();
  useInclusiveEndDate = true;

  constructor(
    private _route: ActivatedRoute,
    private _gridService: GridSettingsService,
    private _authService: AuthorizeService,
    private _globalsService: GlobalsService,
    private _dateHelperService: DateHelperService,
    private _navMenuBuilderHelperService: NavMenuBuilderHelperService,
    private _mapHelperService: MapHelperService,
    private readonly _dataVisualizationNavigationService: DataVisualizationNavigationService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.readNavigationParameters(params)
        .pipe(untilDestroyed(this))
        .subscribe((filterData) => {
          if (filterData?.hierarchyFamilyId) {
            this.selectedHierarchyFamilyId = filterData.hierarchyFamilyId;
          }

          this.autoLoad = this.selectedHierarchyElements?.length > 0;

          this.init();

          const activityPermission$ = this._authService.canAccess('ActivitiesCrud', 'r');
          const localization$ = this.localization.get(`${this.T_SCOPE}.tab-settings`);
          combineLatest([activityPermission$, localization$]).subscribe({
            next: ([activityPermission, ts]) => {
              const panelSettings = new TabDetailPanelSettings();
              if (activityPermission) {
                panelSettings.addComponent(AlcLeaksActivitiesPanelComponent, ts.activities);
                panelSettings.addComponent(AlcLeaksParentActivityComponent, ts['parent-activity']);
              }

              panelSettings.addComponent(MapRelatedComponent, ts.map);
              panelSettings.addComponent(TimeSelectorChartWidgetComponent, ts.trends);
              this.rightPanelService.setTabSettings(panelSettings);
            },
          });
        });
    });
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    // 4 filters right now (date, hierarchy, activity type, open activities)
    const filterValidation = new ActivitiesGridFilterValidations();

    if (
      filtersParameters &&
      filterValidation.validateFilters(['hierarchyElementsIds'], filtersParameters)
    ) {
      this.gridFilters = filtersParameters;
      this.canLoad = true;
    } else {
      this.canLoad = false;
    }
  }

  loadGrid() {
    if (this.canLoad) {
      const newGridFilters = new DataBindingFilters();
      newGridFilters.filters = this.gridFilters.filters;
      this.gridFiltersForBinding = newGridFilters;
      this.persistFilters$.next();
    }
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }
  public get pageCrud(): string {
    return 'LeaksPageCrud';
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    throw new Error('Method not implemented.');
  }

  init(): void {
    this.loadState();
    this.updateNavigationParams();
    this.initGrid();
  }

  initGrid() {
    if (this.gridName) {
      const persistedSettings = this.getPersisted(
        GridPersistedElements.Settings,
        undefined
      ) as GridSetting;

      if (persistedSettings) {
        this.setGridSettings(persistedSettings);

        if (this.orderNumberFromNavigation) {
          this.filterByNavigatedOrderNumber();
        }
      } else {
        this._gridService.getGridSettingsByName(this.gridName).subscribe({
          next: (gridSettings) => {
            if (gridSettings) {
              this.setGridSettings(gridSettings);
            }

            if (this.orderNumberFromNavigation) {
              this.filterByNavigatedOrderNumber();
            }
          },
        });
      }
    }
  }

  getFilterOrderNumber(): FilterDescriptor[] {
    const filter = {
      field: this.orderNumberFieldName,
      operator: 'eq',
      value: this.orderNumberFromNavigation,
    };
    return [filter];
  }

  onManageTemplatesAndWorkspaces = (navigateSettings: WNavigateSettings): void => {
    const elements = this.selectedLeaks.map(
      (x) =>
        new WlmElementExtended(
          x.hierarchyElementId,
          x.hierarchyElementTypeId,
          x.hierarchyElementName,
          null,
          null,
          EntityTypes.hierarchyElement
        )
    );

    this._dataVisualizationNavigationService.openManageTemplatePopupAndNavigate(
      navigateSettings,
      elements,
      this._chartWorkspaceName
    );
  };

  loadState() {
    const filters = [];

    if (!this.selectedHierarchyElements) {
      this.selectedHierarchyElements = (
        this.getPersisted(this.hierarchyElementIdFieldName, [
          { value: this.defaultHierarchyElementId, label: '' },
        ]) as BasicFilter
      ).value;
    }

    if (!this.selectedHierarchyFamilyId) {
      const persistedFamilyId = this.getPersisted(this.hierarchyElementFamilyFieldName, undefined);
      if (persistedFamilyId) {
        this.selectedHierarchyFamilyId = (persistedFamilyId as BasicFilter)?.value?.shift()?.value;
      }
    }

    this._globalsService
      .getDefaultHierarchyFamilyId(this.selectedHierarchyFamilyId)
      .subscribe((familyId) => {
        if (!this.selectedHierarchyFamilyId) {
          this.selectedHierarchyFamilyId = familyId;
        }

        const startDate = this.hasDateFromNavigation
          ? this.startDateFromNavigation
          : this.loadPersistedDate(this.startDateKey, this.startDateFromNavigation, false, true);

        const hasEndDatePersisted = this.getPersisted(this.endDateKey, null, null, false) != null;

        let endDate = this.hasDateFromNavigation
          ? this.endDateFromNavigation
          : this.loadPersistedDate(this.endDateKey, this.endDateFromNavigation, true, true);

        if (!hasEndDatePersisted) {
          endDate = this.dateHelperService.addDays(endDate, 1);
        }

        const gridFiltersForBinding = this.getPersisted(
          TabDetailParameterName.dataBindingFilter,
          undefined
        ) as DataBindingFilters;

        let dataBindingCasted = new DataBindingFilters();
        if (gridFiltersForBinding?.exportableFilter) {
          dataBindingCasted.exportableFilter = gridFiltersForBinding?.exportableFilter;
          dataBindingCasted.reloadFromSerialization();
        } else {
          dataBindingCasted = null;
        }

        this.loadPage(dataBindingCasted, startDate, endDate, filters);
      });
  }

  private loadPage(
    gridFiltersForBinding: DataBindingFilters,
    startDate: any,
    endDate: any,
    filters: IFilter[]
  ) {
    const defaultDateRange = this._dateHelperService.createDefaultDateRange();
    const configuration = new LeaksGridFilterConfiguration(
      this.persistencyArea,
      this.hierarchyElementIdFieldName,
      this.hierarchyElementFamilyFieldName,
      this.dateFieldName,
      this.dateFieldName, // <-- the range filter is configured tu use the same date field for start and end
      this.selectedHierarchyElements,
      this.selectedHierarchyFamilyId,
      new DateRange(startDate, endDate, null, null, null, this.useInclusiveEndDate),
      defaultDateRange,
      filters
    );

    this.leaksGridFilterConfiguration = configuration;
    if (gridFiltersForBinding) {
      this.autoLoad = true;
    }
    this.configReady = true;
  }

  updateNavigationParams() {
    this.navigations = [];

    const navItemsConfiguration = this.getNavItemsConfigurations();
    const navMenuConfiguration = new NavMenuConfiguration({
      selectedElements: this.selectedLeaks,
      items: navItemsConfiguration,
    });
    this._navMenuBuilderHelperService
      .buildMenu(navMenuConfiguration)
      .subscribe((menu) => (this.navigations = menu));
  }

  setFiltersDetailsParameters(value: TabDetailPanelParameters) {
    this.filterDetailsParameters = value;
    if (this.autoLoad && this.canLoad) {
      this.loadGrid();
      this.autoLoad = false;
    }
  }

  getSelectedLeak(leak: LeaksDto) {
    if (leak != null) {
      this._hasBeenSelected = true;
    }
    this.SendParameters(leak);
    this.canNavigateToLeak = true;
  }

  private SendParameters(selectedElement: LeaksDto) {
    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      this.selectedLeak = selectedElement;
      const parameters = this.filterDetailsParameters ?? new TabDetailPanelParameters();
      parameters.addParameter(
        TabDetailParameterName.workOrderExternalId,
        selectedElement?.saporderNumber
      );
      parameters.addParameter(
        TabDetailParameterName.elementName,
        selectedElement
          ? ts['order-number-title'] + selectedElement?.saporderNumber ??
              ts['zone-title'] + selectedElement?.hierarchyElementName
          : null
      );
      parameters.addParameter(
        TabDetailParameterName.hierarchyElementId,
        selectedElement?.hierarchyElementId
      );

      const startDate = this.filterDetailsParameters?.parameters.get(
        TabDetailParameterName.startDate
      );
      const endDate = this.filterDetailsParameters?.parameters.get(TabDetailParameterName.endDate);

      parameters.addParameter(
        TabDetailParameterName.dataBindingFilter,
        this.getActivityFilterParameters(
          selectedElement?.hierarchyElementId,
          selectedElement?.hierarchyFamilyId,
          new Date(new Date().setMonth(new Date().getMonth() - 3)),
          new Date()
        )
      );

      let timeSelectorChartSetting = null;

      if (selectedElement?.hierarchyElementId) {
        const wlmElement = this.getWlmElement(selectedElement);

        timeSelectorChartSetting = new TimeSelectorChartSettings({
          chartSetting: new CustomWorkspaceChartSettings({
            chartType: ChartType.customizable,
            dataParameters: new CustomWorkspaceChartDataParameters({
              startDate: new Date(),
              endDate: new Date(),
              workspaceName: this._chartWorkspaceName,
              elements: [wlmElement],
            }),
            exportFileName: `${this.titleService.getTitle()} ${
              selectedElement?.hierarchyElementId
            }`,
          }),
          includeDefaultPeriods: true,
          defaultSelectedPeriodType: PeriodTypesEnum.customFromDateRange,
        });
      }

      parameters.addParameter(
        TabDetailParameterName.timeSelectorChartSetting,
        timeSelectorChartSetting
      );

      const mapParameters = this.initializeMapParameters();
      if (this._hasBeenSelected) {
        mapParameters.navigationParam = selectedElement?.leakageId
          ? new NavigationElement({
              elementId: selectedElement?.leakageId,
              type: NavigationElementType.Leaks,
            })
          : null;
        mapParameters.visibleLayersIds = [
          ...new Set([...mapParameters.visibleLayersIds, ...this._mapParametersLayers]),
        ];
        mapParameters.leakYears = [
          ...new Set([...mapParameters.leakYears, ...this._commonLeakYears]),
        ];
      }
      parameters.addParameter(TabDetailParameterName.mapParameters, mapParameters);

      parameters.addParameter(TabDetailParameterName.activityId, selectedElement?.activityId);

      this.selectedUrlParams = parameters;
      this.rightPanelService.setTabParameters(parameters);
    });
  }

  private getActivityFilterParameters(
    hierarchyElementId: string,
    hierarchyFamilyId: string,
    startDate: Date,
    endDate: Date
  ): DataBindingFilters {
    if (!hierarchyElementId) {
      return null;
    }
    const activityGridFiltersForBinding = new DataBindingFilters();

    const dateRange = new DateRange(
      startDate,
      endDate,
      this.startDateFieldName,
      this.endDateFieldName,
      true
    );

    activityGridFiltersForBinding.filters.set('dateRange', dateRange);

    const activityCategory = new ActivityCategoryTypesFilter(
      this.repairFieldName,
      this.detectionFieldName
    );
    activityCategory.includeDetection = true;
    activityCategory.includeRepair = true;

    activityGridFiltersForBinding.filters.set('category', activityCategory);

    const heIds = new HierarchyElementPathFilter(
      hierarchyFamilyId,
      [hierarchyElementId],
      this.hierarchyElementFamilyFieldName,
      this.hierarchyElementIdFieldName
    );

    const activityStatus = new ActivityStatusFilter([true, true], this.statusFieldName);
    activityGridFiltersForBinding.filters.set('status', activityStatus);

    activityGridFiltersForBinding.filters.set('hierarchyElementsIds', heIds);

    return activityGridFiltersForBinding;
  }

  readNavigationParameters(params: Params): Observable<HierarchyElementFiltersDataDto> {
    const queryParams = this.getQueryParams(params);

    this.useGridPersistence = queryParams.hierarchyElementIds === undefined;
    if (queryParams?.hierarchyElementIds) {
      this.selectedHierarchyElements = Array.isArray(queryParams.hierarchyElementIds)
        ? queryParams.hierarchyElementIds
        : [queryParams.hierarchyElementIds];
    }
    this.selectedHierarchyFamilyId = queryParams.hierarchyFamilyId;

    this.startDateFromNavigation =
      queryParams?.startDate ?? this._dateHelperService.getDefaultStartDate(this.offsetStartDate);
    this.endDateFromNavigation =
      queryParams?.endDate ?? this._dateHelperService.getDefaultEndDate();

    this.hasDateFromNavigation = queryParams.startDate || queryParams.endDate;

    if (this.isNavigationFromMap(params)) {
      this.setMapNavigationParams(queryParams);

      return this._globalsService.getDefaultHierarchyFamilyId().pipe(
        map((familyId) => {
          return { hierarchyFamilyId: familyId } as HierarchyElementFiltersDataDto;
        })
      );
    }

    if (
      this.selectedHierarchyElements?.[0] &&
      !queryParams?.hierarchyFamilyId &&
      !this.isNavigationFromMap(params)
    ) {
      return this._globalsService.getHeFiltersDataById(this.selectedHierarchyElements[0]);
    }

    return of(null);
  }
  private setMapNavigationParams(queryParams: Params) {
    this.orderNumberFromNavigation = queryParams.orderNumber;
    this.startDateFromNavigation = new Date(queryParams.creationDate);
    this.endDateFromNavigation = new Date(queryParams.creationDate);
    this.hasDateFromNavigation = true;
    this.selectedHierarchyElements = [];
    this.useGridPersistence = false;
  }

  isNavigationFromMap(params: Params): boolean {
    return params.orderNumber;
  }

  /**
   * Captures all the click events of all the buttons and associate them to the correct callbacks.
   */
  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  /**
   * When all filters are available, perform autoload.
   */
  onCheckAutoload(): void {
    this.gridSettingsReady$.pipe(untilDestroyed(this)).subscribe((settings) => {
      if (!settings.disableAutoLoad) {
        this.loadGrid();
      }
    });
  }

  canLeavePage(): Observable<boolean> {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    this._mapHelperService.persistMapSettings(
      persistedMapsettings,
      this._settingArea,
      this._settingKey
    );

    return of(true);
  }

  private initializeMapParameters() {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    const mapParameters = MapParameters.getparameter({
      visibleLayersIds: persistedMapsettings ? persistedMapsettings?.visibleLayersIds : null,
      leakYears: persistedMapsettings ? persistedMapsettings?.leakYears : null,
      visibleThematicsIds: persistedMapsettings ? persistedMapsettings?.visibleThematicsIds : null,
      showFilters: true,
      settingArea: this._settingArea,
      settingKey: this._settingKey,
      loadFromPersistency: persistedMapsettings === null,
    });

    return mapParameters;
  }

  private getNavItemsConfigurations(): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.Map,
        validationType: 'custom',
        customValidation: this.selectedLeaks?.length !== 1,
        customtooltip: this._navMenuBuilderHelperService.getNavDisabledTooltipMessageByMaxZones(),
        paramType: 'custom',
        customParamsKey: ['leakageId'],
      },
      {
        key: NavKeys.DataVisualization,
        validationType: 'max-zones',
        paramType: 'he-family',
        customNavMethod: this.onManageTemplatesAndWorkspaces,
      },
    ];
  }

  private setGridSettings(gridSettings: GridSetting) {
    this.gridSettings = gridSettings;

    this.gridSettingsReady$.next(this.gridSettings);
    this.gridSettingsReady$.complete();
  }

  private filterByNavigatedOrderNumber() {
    this.leaksGrid.clearFilters();
    this.leaksGrid.applyColumnFilter(this.getFilterOrderNumber());
  }

  private getWlmElement(selectedElement: LeaksDto): WlmElementExtended {
    const { hierarchyElementId, hierarchyElementTypeId, hierarchyElementName } = selectedElement;

    return new WlmElementExtended(
      hierarchyElementId,
      hierarchyElementTypeId,
      hierarchyElementName,
      null,
      null
    );
  }

  ngOnDestroy() {
    this.persist(TabDetailParameterName.dataBindingFilter, this.gridFiltersForBinding);
    this.persist(TabDetailParameterName.hierarchyElementId, '');
    this.persist(TabDetailParameterName.elementName, null);

    super.ngOnDestroy();
  }
}
