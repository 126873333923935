import { Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { FieldType } from '@ngx-formly/material';
import {
  LNUModeFieldNames,
  LNUModeFieldNamesMapping,
} from '@water-loss//features/shared/model/ne/lnu-mode-field-names';
import { LNUMode } from '@water-loss//features/shared/model/ne/lnu-mode.enum';
import { map, Observable } from 'rxjs';
import { FieldDefinition } from '../../models/field-definition';
import { FieldsByCategory } from '../../models/fields-by-category';
import { BaseFormLabelOverwriteService } from '../../services/base-form-label-overwrite.service';

class LNUIdentifier {
  HierarchyCustomerAttributeTypeId: number;
  CustomerTypeId: string;
  Field: LNUModeFieldNames;
}

@Component({
  selector: 'wlm-custom-global-value-display-field',
  templateUrl: './custom-global-value-display-field.component.html',
  styleUrl: './custom-global-value-display-field.component.scss',
  host: { class: 'custom-formly-field' },
})
export class CustomGlobalValueDisplayFieldComponent extends FieldType<any> implements OnInit {
  private readonly _lnuCategoryKey = 'LNU';
  readonly innerCtrl = new FormControl();

  constructor(
    @Optional()
    @Inject(BaseFormLabelOverwriteService)
    private readonly _labelOverwriteService: BaseFormLabelOverwriteService,
    private _elementRef: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.buildGlobalValue();
  }

  private buildGlobalValue(): void {
    const props = this.getProps();
    const sourceField: FieldDefinition = props._source;

    // We need the raw version of the global value, not the formatted one.
    this._labelOverwriteService.getGlobalValue$(sourceField).subscribe((globalMode: LNUMode) => {
      if (Number(globalMode) === LNUMode.SmartMeter) {
        this.handleSmartMeter();
        return;
      }

      // From the configured globalValue, we get the actual value that should display in the field.
      const identifierObject: LNUIdentifier = JSON.parse(sourceField.globalDataSource.identifier);

      // The identifier must include the mode of the customer class type selector
      // to know exactly which value to show.
      const globalFieldName = LNUModeFieldNamesMapping.get(Number(globalMode));
      if (globalFieldName) {
        identifierObject.Field = globalFieldName;
      }

      // To convert to the correct UoM, we must find the field definition corresponding to that default value.
      // If we dont want to do a query to get the Algorithm's fields, at least show the same unit as the equivalent field in NEC.
      this.getLNUFields().subscribe((fields) => {
        const actualIdentifier = globalUtilsHelper.normalizeId(identifierObject);
        let fakeField = fields.find((f) => {
          const currentIdentifier = globalUtilsHelper.normalizeId(f.source.identifier);
          return currentIdentifier === actualIdentifier;
        });
        fakeField = globalUtilsHelper.clone(fakeField);

        if (fakeField) {
          // Build a fake field with the correct uom,
          // but with the global settings in order to produce the desired value.
          const actualGlobalDataSource = globalUtilsHelper.clone(sourceField.globalDataSource);
          actualGlobalDataSource.identifier = actualIdentifier;

          fakeField.globalDataSource = actualGlobalDataSource;

          // With the completed identifier, get the value that is actually displayed.
          this._labelOverwriteService.apply(fakeField, '').subscribe((actualGlobalValue) => {
            this.setGlobalValue(actualGlobalValue);
          });
        }
      });
    });
  }

  private getLNUFields(): Observable<FieldDefinition[]> {
    return this._labelOverwriteService.getFieldsByCategory$().pipe(
      map((fieldsByCategory: FieldsByCategory[]) => {
        const item = fieldsByCategory.find((item) => item.categoryKey === this._lnuCategoryKey);
        return Object.values(item.fields);
      })
    );
  }

  private handleSmartMeter(): void {
    const formFieldElement = this._elementRef.nativeElement.closest('.mat-mdc-form-field');
    if (formFieldElement) {
      formFieldElement.style.display = 'none';
    }
  }

  private setGlobalValue(globalValue): void {
    this.formControl.setValue(globalValue && globalValue !== '' ? globalValue : 'GLOBAL VALUE');
  }

  private getProps() {
    return { ...(this.field.templateOptions ?? {}), ...(this.field.options ?? {}) };
  }
}
