import { Component, Inject, Injector, ViewChild } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { WLMDialogResult } from '@common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { GroupDto } from '@common-modules/shared/model/roles/group.dto';
import { GroupUsersCmdDto } from '@common-modules/shared/model/roles/groupUsersCmd.dto';
import { UserDto } from '@common-modules/shared/model/roles/user.dto';
import { UsersGridODataService } from '@common-modules/shared/services/users/users.service';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
// prettier-ignore

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { UserGroupsPopupComponent } from '../user-groups-popup/user-groups-popup.component';

const COMPONENT_SELECTOR = 'wlm-user-group-grid';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './user-group-grid.component.html',
  styleUrls: ['./user-group-grid.component.scss'],
})
export class UserGroupGridComponent extends BaseWidgetComponent {
  @ViewChild(GenericGridComponent) public groupsGrid: GenericGridComponent;
  gridSettings: GridSetting;
  additionalFilters: Map<string, string>;
  gridFilters: DataBindingFilters;
  user: UserDto;
  usersGroupGridName = 'UserGroups';
  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;
  removeSelection$ = new ReplaySubject<void>();

  selectedGroups: GroupDto[];

  selectedGroup: GroupDto;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    readonly gridService: GridSettingsService,
    private readonly _authService: AuthenticationService,
    private readonly _userService: UsersGridODataService,
    private readonly _dialogService: DialogService,
    private readonly _groupsPopup: MatDialog
  ) {
    super(injector, widgetSettings);
    gridService.getGridSettingsByName(this.usersGroupGridName).subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.removeSelection$.next();
    this.user = parameters.parameters.get(TabDetailParameterName.user);
  }
  init(): void {
    this.loadGrid();
  }

  private loadGrid() {
    if (this.user) {
      const additionalFilters = new Map<string, string>();
      additionalFilters.set('userCode', this.user.userCode);

      this.additionalFilters = additionalFilters;
      this.gridFilters = new DataBindingFilters();
      this.groupsGrid?.reloadGrid();
    }
  }

  getSelectedGroup(group: GroupDto) {
    this.selectedGroup = group;
  }

  addGroupsToUser(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.height = '700px';
    dialogConfig.data = {
      userCode: this.user.userCode,
    };
    const popup = this._groupsPopup.open(UserGroupsPopupComponent, dialogConfig);

    popup
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.loadGrid();
        }
      });
  }

  removeGroup(): void {
    const isPlural = this.selectedGroups.length > 1;
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages${
        isPlural ? '.remove-groups-warning' : '.remove-group-warning'
      }`,
    });
    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.removeGroupAction(isPlural);
        }
      });
  }

  private removeGroupAction(isPlural: boolean) {
    const groupUsersToRemoveDto = new GroupUsersCmdDto(
      this.selectedGroups.map((x) => x.groupId),
      [this.user.userCode],
      this._authService.userCode
    );
    this.groupsGrid.grid.loading = true;

    this._userService.removeUsersFromGroups(groupUsersToRemoveDto).subscribe({
      next: (response) => {
        this.groupsGrid.grid.loading = false;
        if (response.errors.length) {
          this._dialogService.showTranslatedMessage(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages${
                isPlural ? '.remove-groups-error' : '.remove-group-error'
              }`,
            })
          );
        } else {
          this.removeSelection$.next();
          this.init();
          this.selectedGroups = [];
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages${
                isPlural ? '.remove-groups-success' : '.remove-group-success'
              }`,
            })
          );
        }
      },
    });
  }

  get componentName() {
    return 'UserGroupGridComponent';
  }
}
