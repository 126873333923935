import { Component, Input, OnInit } from '@angular/core';
import { networkElementTypesDVWizardSet } from '@common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { TabDetailParameterName } from '@common-modules/dependencies/navigation/tab-detail-component';
import { DimensionTypeFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/dimension-type-f-adapter';
import { ElementTypeFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/element-type-f-adapter';
import { HierarchyElementPathFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { NeHeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FiltersAdapterService } from '@common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { TreeSettingsService } from '@common-modules/shared/services/tree-settings.service';
import { ReplaySubject } from 'rxjs';
// prettier-ignore

import { TelemetryGridFilterConfiguration } from './telemetry-filter-configuration';
const COMPONENT_SELECTOR = 'wlm-telemetry-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './telemetry-filter.component.html',
  styleUrls: ['./telemetry-filter.component.scss'],
})
export class TelemetryFilterComponent extends BasePageFiltersComponent implements OnInit {
  @Input() storageLocation: 'local' | 'session' | 'none';

  private _telemetryFilterConfiguration: TelemetryGridFilterConfiguration;
  public get telemetryFilterConfiguration(): TelemetryGridFilterConfiguration {
    return this._telemetryFilterConfiguration;
  }
  @Input() public set telemetryFilterConfiguration(v: TelemetryGridFilterConfiguration) {
    this._telemetryFilterConfiguration = v;
    if (v) {
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  treeSettings: ITreeSettings = null;
  filterFields = ['hierarchyElementsIds'];
  gridSettingsReady$ = new ReplaySubject<GridSetting>();
  mustPersistFilters = false;
  isFirstLoad = true;

  neTypesToFilter = networkElementTypesDVWizardSet;

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
  };

  bfSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-label`,
    disableSelectAll: true,
  };

  bfNeSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-2-label`,
    disableSelectAll: true,
  };

  bfDimensionSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-3-label`,
    disableSelectAll: true,
  };

  hierarchyFamilyItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
    required: true,
  });

  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
  });

  neTypeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    storageLocation: 'local',
    required: false,
  });

  dimensionTypeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    storageLocation: 'local',
    required: false,
  });

  // Communicates hierarchy-family-filter-item with hierarchy-tree-filter-item.
  hierarchyElementFamilyId: string;

  constructor(
    private adaptersService: FiltersAdapterService,
    private treeSettingsService: TreeSettingsService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  ngOnInit(): void {
    this.buildTreeSettings();
    this.setFiltersPersistencyArea();
  }

  buildTreeSettings(): void {
    this.treeSettingsService
      .buildTreeSettings()
      .subscribe((settings) => (this.treeSettings = settings));
  }

  onFilterText(filterText: string): void {
    this.filterText = filterText;
  }

  /**
   * Saves the family Id so the tree filter can obtain it.
   * Only contains the activity Id filter.
   */
  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName = this.telemetryFilterConfiguration.hierarchyElementFamilyFieldName;
    const defaultId = this.telemetryFilterConfiguration.hierarchyElementFamily;
    this.hierarchyElementFamilyId = this.adaptersService.getFamilyId(
      filters,
      familyFieldName,
      defaultId
    );
  }

  onTreeFilterReady() {
    if (this.mustPersistFilters && this.isFirstLoad) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
      this.isFirstLoad = false;
    }
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const { hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.telemetryFilterConfiguration;

    const { networkElementTypeIdFieldName, networkElementTypeIds } =
      this.telemetryFilterConfiguration;

    const { dimensionTypeIdFieldName } = this.telemetryFilterConfiguration;

    const adapters = [
      new HierarchyElementPathFAdapter({
        dataBindingField: 'hierarchyElementsIds',
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId: this.hierarchyElementFamilyId,
      }),
      new ElementTypeFAdapter({
        dataBindingField: 'networkElementType',
        elementTypeFieldName: networkElementTypeIdFieldName,
        neheFilterSettings: new NeHeFISettings({
          isNEFilter: true,
          fieldName: networkElementTypeIdFieldName,
          selectedIds: networkElementTypeIds,
        }),
      }),
      new DimensionTypeFAdapter({
        dataBindingField: 'dimension',
        fieldName: dimensionTypeIdFieldName,
        tabParameter: TabDetailParameterName.dimensionType,
        multiple: false,
      }),
    ];
    return adapters;
  };

  protected setFiltersPersistencyArea(): void {
    this.bfSettings.persistencyArea = this.telemetryFilterConfiguration?.persistencyArea;
    this.bfNeSettings.persistencyArea = this.telemetryFilterConfiguration?.persistencyArea;
    this.bfDimensionSettings.persistencyArea = this.telemetryFilterConfiguration?.persistencyArea;
    this.hierarchyFamilyItemSettings.storageLocation = this.storageLocation;
    this.hierarchyTreeItemSettings.storageLocation = this.storageLocation;
    this.neTypeItemSettings.storageLocation = this.storageLocation;
    this.dimensionTypeItemSettings.storageLocation = this.storageLocation;
  }

  buildKeysToComplete(): void {
    const { hierarchyElementIdFieldName } = this.telemetryFilterConfiguration;
    this.keysToComplete = [hierarchyElementIdFieldName];
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }
}
