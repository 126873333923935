<kendo-grid-column [field]="field" [width]="width" [locked]="locked">
  <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
    <ng-container>
      <wlm-column-header-template [columnTitle]="column"></wlm-column-header-template>
    </ng-container>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <wlm-boolean-column-cell
      [dataItem]="dataItem"
      [elements]="elements"
      [field]="field"
    ></wlm-boolean-column-cell
  ></ng-template>

  <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService"
  >
    <wlm-boolean-column-filter
      [field]="column.field"
      [filterService]="filterService"
      [filter]="filter"
      [elements]="elements"
    ></wlm-boolean-column-filter>
  </ng-template>
</kendo-grid-column>
