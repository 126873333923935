import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { GetCacheOpts } from '@common-modules/cache/http-cache/http-cache.client';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { ActivityOperationService } from '../../activity-operation.service';

@UntilDestroy()
@Directive({
  selector: '[wlm-operations-binding-directive]',
})
export class OperationsBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {
  private columns: string[] = [];
  private cache: GetCacheOpts = { minutes: 3 };

  constructor(private activityOperationService: ActivityOperationService, grid: GridComponent) {
    super(grid);
  }

  @Input() filters: DataBindingFilters;
  @Input() count: boolean;
  @Input() columnKey: string;

  public ngOnInit(): void {
    this.activityOperationService.pipe(untilDestroyed(this)).subscribe((result) => {
      if (result == null) {
        return;
      }

      this.grid.loading = false;
      this.grid.data = { data: result.items, total: result.totalCount };
      this.notifyDataChange();
    });
    super.ngOnInit();
    this.rebind();
  }

  public rebind(): void {
    this.grid.loading = true;
    this.activityOperationService.query(
      this.columns,
      this.state,
      this.filters,
      this.count,
      null,
      this.cache
    );
  }
  public getBindingState(): State {
    return this.state;
  }

  public clearFilters() {
    const filter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [],
    };

    this.state.filter = filter;
    this.grid.filter = filter;
    this.rebind();
  }
}
