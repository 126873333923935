import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MVService } from '@common-modules/dependencies/mv/mv.service';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { GetValueSelector } from '@common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { BaseFiltrableItemService } from '@common-modules/shared/charts/base-filtrable-item.service';
import { FiltrableItemManagerService } from '@common-modules/shared/charts/filtrable-item-manager.service';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { FiltrableItemTypeEnum } from '@common-modules/shared/model/filtrable-items/types/filtrable-item-type-enum';
import { IMVDto } from '@common-modules/shared/model/mv/mv-dto';
import { MVQueryDto } from '@common-modules/shared/model/mv/mv-query.dto';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { plainToClass } from 'class-transformer';
import { asEnumerable } from 'linq-es2015';
import { Observable, forkJoin } from 'rxjs';
import { ChartConfiguration } from '../../../shared/charts/model/chart-configuration';
import { SignalFiltrableItemQueryDto } from '../../../shared/model/filtrable-items/signal-filtrable-item-query.dto';
import { SignalFiltrableItemDto } from '../../../shared/model/filtrable-items/signal-filtrable-item.dto';
import { DataVisualizationCartSelection } from '../../models/data-visualization-cart-selection';
import { DataVisualizationStateFields } from '../../models/data-visualization-state-fields';

const COMPONENT_SELECTOR = 'wlm-data-visualization-point-values-widget';
export const DV_POINT_VALUES_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-point-values-widget.component.html',
  styleUrls: ['./data-visualization-point-values-widget.component.scss'],
  providers: [ReduxStateService],
})
export class DataVisualizationPointValuesWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  get componentName(): string {
    return 'DataVisualizationPointValuesWidgetComponent';
  }

  gridSettings: GridSetting;
  gridData: any[];
  isLoading = false;

  readonly gridSettingsName = 'MeasuredValuesGrid';

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _filtrableItemManagerService: FiltrableItemManagerService,
    private _gridSettingsService: GridSettingsService,
    private readonly _mvService: MVService
  ) {
    super(injector, widgetSettings);
    const algorithms = this.setSignalFiltrableItems(widgetSettings.itemSettings?.params);
    this.loadGrid(algorithms);
  }

  onWidgetInit(): void {
    this.setGridSettings();

    this.listenState(DataVisualizationStateFields.plotCartSelection).subscribe(
      (cartSelection: DataVisualizationCartSelection) => {
        if (cartSelection) {
          this.isLoading = true;

          const signalsFiltrableItems =
            this.getSignalFiltrableItemsFromCartSelection(cartSelection);

          this.onCartPlot(signalsFiltrableItems);
          this.loadGrid(signalsFiltrableItems);
        }
      }
    );

    this.listenState(DataVisualizationStateFields.plotChartConfiguration).subscribe(
      (chartConfiguration: ChartConfiguration) => {
        if (chartConfiguration) {
          this.isLoading = true;

          const signalsFiltrableItems =
            this.getSignalFiltrableItemsFromChartConfiguration(chartConfiguration);

          this.onCartPlot(signalsFiltrableItems);
          this.loadGrid(signalsFiltrableItems);
        }
      }
    );

    this.listenState(DataVisualizationStateFields.clearAll).subscribe((value) => {
      if (value) {
        this.loadGrid([]);
      }
    });
  }

  private loadGrid(signalFiltrableItems: SignalFiltrableItemDto[]) {
    if (!signalFiltrableItems?.length) {
      this.gridData = [];
      this.isLoading = false;
      return;
    }
    const service = this._filtrableItemManagerService.getService(FiltrableItemTypeEnum.Signal);

    const queries$: Observable<IMVDto[]>[] = [];

    signalFiltrableItems.forEach((signalFiltrableItem) => {
      const query$ = this.getSignalShort(service, signalFiltrableItem);
      queries$.push(query$);
    });

    forkJoin(queries$).subscribe({
      next: (values) => {
        const gridData = asEnumerable(values)
          .SelectMany((x) => x)
          .ToArray();

        this.gridData = gridData;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  private getSignalFiltrableItemsFromChartConfiguration(
    chartConfiguration: ChartConfiguration
  ): SignalFiltrableItemDto[] {
    return chartConfiguration.seriesConfiguration
      .map((s) => s.serieDefinition)
      .filter((sd) => sd.filtrableType.type == FiltrableItemTypeEnum.Signal)
      .map((sd) => sd as SignalFiltrableItemDto);
  }

  private getSignalFiltrableItemsFromCartSelection(
    cartSelection: DataVisualizationCartSelection
  ): SignalFiltrableItemDto[] {
    return cartSelection.visibleItems
      .filter((x) => x.filtrableType.type == FiltrableItemTypeEnum.Signal)
      .map((x) => x as SignalFiltrableItemDto);
  }

  private getSignalShort(
    service: BaseFiltrableItemService,
    signalFiltrableItem: SignalFiltrableItemDto
  ): Observable<IMVDto[]> {
    const query = service.getQuery(signalFiltrableItem) as SignalFiltrableItemQueryDto;

    const query$ = this._mvService.getShortConverted(
      new MVQueryDto(query.startDate, query.endDate, query.extraParams.signalIds)
    );
    return query$;
  }

  private setGridSettings() {
    this._gridSettingsService
      .getGridSettingsByName(this.gridSettingsName)
      .subscribe((gridSettings) => {
        this.gridSettings = gridSettings;
      });
  }

  private onCartPlot(signalFiltrableItems: SignalFiltrableItemDto[]) {
    this.updateItemSettings({
      title: this._settings.title,
      params: signalFiltrableItems,
      widgetInstanceKey: this.getWidgetInstanceKey(),
    });
  }

  private setSignalFiltrableItems(params: any[]) {
    if (!params) {
      return;
    }

    const signalFiltrableItems = plainToClass(SignalFiltrableItemDto, params);
    return signalFiltrableItems;
  }

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }
}
