import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivityCategoryType } from '@common-modules/dependencies/wlm-filters/i-filters/activity-category-type';
import { ActivityCategoryTypesEnum } from '@common-modules/dependencies/wlm-filters/i-filters/activity-category-types';
import { ActivityCategoryTypesFilter } from '@common-modules/dependencies/wlm-filters/i-filters/activity-category-types-filter';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivityCategoryTypesService } from './activity-category-types.service';

const COMPONENT_SELECTOR = 'wlm-activity-type-filter';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './activity-category-type-filter.component.html',
  styleUrls: ['./activity-category-type-filter.component.scss'],
})
export class ActivityTypeFilterComponent implements OnInit {
  activityTypesForm = new UntypedFormControl();
  activityTypes: ActivityCategoryType[];
  selectedActivities: ActivityCategoryTypesEnum[] = [];

  @Input() repairFieldName: string;
  @Input() detectionFieldName: string;
  @Output() activityTypesSelectedChanged = new EventEmitter<ActivityCategoryTypesFilter>();
  @Input() filters: IFilter[];

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  constructor(private _activityCategoryTypesService: ActivityCategoryTypesService) {}

  ngOnInit(): void {
    this._activityCategoryTypesService.getActivityCategoryTypes().subscribe({
      next: (activities) => {
        this.activityTypes = activities;
        this.getDefaultValue();
      },
    });

    this.activityTypesForm.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (value) => {
        const activityTypesSelected = new ActivityCategoryTypesFilter(
          this.repairFieldName,
          this.detectionFieldName
        );

        value?.forEach((activityType) => {
          // tslint:disable-next-line: triple-equals
          if (activityType === ActivityCategoryTypesEnum.IsRepair) {
            activityTypesSelected.includeRepair = true;
          } else if (activityType === ActivityCategoryTypesEnum.IsDetection) {
            activityTypesSelected.includeDetection = true;
          }
        });

        this.activityTypesSelectedChanged.emit(activityTypesSelected);
      },
    });
  }
  getDefaultValue() {
    if (this.filters?.length) {
      const activityTypeFilter = this.filters.filter(
        (x) => Object.getPrototypeOf(x) === ActivityCategoryTypesFilter.prototype
      );
      if (activityTypeFilter.length) {
        const includeRepair = (activityTypeFilter[0] as ActivityCategoryTypesFilter).includeRepair;
        const includeDetection = (activityTypeFilter[0] as ActivityCategoryTypesFilter)
          .includeDetection;

        if (includeRepair) {
          this.selectedActivities.push(ActivityCategoryTypesEnum.IsRepair);
        }
        if (includeDetection) {
          this.selectedActivities.push(ActivityCategoryTypesEnum.IsDetection);
        }
      }
    }
  }
}
