import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AlarmSeriesDto } from '@common-modules/dependencies/alarms/alarm-serie.dto';
import { SignalsConfiguredQueryDto } from '@common-modules/dependencies/signals/models/signals-configured-query.dto';
import { SignalsService } from '@common-modules/dependencies/signals/signals.service';
import { WlmElementExtended } from '@common-modules/shared/charts/model/elements/element-extended';
import { DataVisualizationAddAlgorithmPopupDimensions } from '@common-modules/shared/constants/dimensions.constants';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { AlgorithmDto, IAlgorithmDto } from '@common-modules/shared/model/algorithm/algorithm.dto';
import { WorkspaceDefinitionDto } from '@common-modules/shared/model/data-viz/workspace-definition';
import { WorkspaceDto } from '@common-modules/shared/model/data-viz/workspace.dto';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { IFiltrableItemDto } from '@common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { FiltrableItemTypeEnum } from '@common-modules/shared/model/filtrable-items/types/filtrable-item-type-enum';
import { DateSelectModeEnum } from '@common-modules/shared/model/shared/date-select-mode.enum';
import { SignalTelemetryNullableViewDto } from '@common-modules/shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { AlgorithmsService } from '@common-modules/shared/services/algorithms.service';
import { EntityTypes } from '@common-modules/wlm-charts/core/models/entity-types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { plainToClass, plainToInstance } from 'class-transformer';
import { asEnumerable } from 'linq-es2015';
import { Observable, forkJoin, map, of, switchMap, take } from 'rxjs';
import { DataVisualizationHeAlgorithmPopupComponent } from '../../data-visualization/components/data-visualization-he-algorithm-popup/data-visualization-he-algorithm-popup.component';
import { DataVisualizationNeAlgorithmPopupComponent } from '../../data-visualization/components/data-visualization-ne-algorithm-popup/data-visualization-ne-algorithm-popup.component';
import { ChartConfiguration } from '../charts/model/chart-configuration';
import { AlgorithmFiltrableItemDto } from '../model/filtrable-items/algorithm-filtrable-item.dto';
import { AlgorithmFiltrableItemService } from '../model/filtrable-items/services/algorithm-filtrable-item.service';
import { SignalFiltrableItemService } from '../model/filtrable-items/services/signal-filtrable-item.service';
import { SignalFiltrableItemDto } from '../model/filtrable-items/signal-filtrable-item.dto';
import { AlgorithmFiltrableType } from '../model/filtrable-items/types/algorithm-filtrable-type';
import { FiltrableItemMapperService } from './filtrable-item-mapper.service';

@UntilDestroy()
@Injectable()
export class WorkspacesHelperService {
  constructor(
    private _dateHelperService: DateHelperService,
    private _dialogService: DialogService,
    private _signalService: SignalsService,
    private _filtrableMapperService: FiltrableItemMapperService,
    private readonly _algorithmFiltrableService: AlgorithmFiltrableItemService,
    private readonly _signalFiltrableService: SignalFiltrableItemService,
    private readonly _algorithmsService: AlgorithmsService
  ) {}

  getTemplateDefinition(chartConfiguration: ChartConfiguration): any {
    const chartSeries = chartConfiguration.seriesConfiguration
      .filter(
        (serie) => serie.serieDefinition.filtrableType.type === FiltrableItemTypeEnum.Algorithm
      )
      .map((serie) => {
        delete serie.serieDefinition.filtrableType.key;
        delete serie.serieDefinition.filtrableType.title;
        delete serie.serieDefinition.filtrableType?.['element'];

        return serie;
      });

    // Templates will only store one filtrable item per algorithm
    chartConfiguration.seriesConfiguration = asEnumerable(chartSeries)
      .Distinct((serie) => serie.serieDefinition.filtrableType['algorithmShortName'])
      .ToArray();

    return {
      chartConfigurations: [chartConfiguration],
    };
  }

  getFiltrableItemsFromAlarm(alarms: AlarmSeriesDto): Observable<IFiltrableItemDto[]> {
    const signals = alarms.signalIds;
    const signalsFiltrableItems$ = signals?.length
      ? this._signalService.getSignalsByIds(signals).pipe(
          map((signals) => {
            return signals.map((signal) => {
              return this._filtrableMapperService.getSignalFiltrableItemDto(signal);
            });
          })
        )
      : of([]);

    const algorithmsFiltrableItems$ = Object.keys(alarms.algorithms).map((algorithmShortName) => {
      return this._algorithmsService.getAlgorithm(algorithmShortName).pipe(
        take(1),
        switchMap((algorithm) => {
          const elements = alarms.algorithms[algorithmShortName].map((elementId) => {
            const wlmElement = new WlmElementExtended(
              elementId,
              null,
              null,
              null,
              null,
              algorithm.entityTypeId
            );
            return this._filtrableMapperService.setAlgorithmFiltrableItemElement(
              algorithm,
              wlmElement
            );
          });

          return forkJoin(elements).pipe(
            switchMap((algorithms) => {
              return algorithms;
            })
          );
        })
      );
    });

    return forkJoin([signalsFiltrableItems$, ...algorithmsFiltrableItems$]).pipe(
      map(([signals, ...algorithms]) => {
        const startDate = this._dateHelperService.fromApiFormat(alarms.startDate);
        const endDate = this._dateHelperService.fromApiFormat(alarms.endDate);
        return this.setFiltrableItemDate(
          [...signals, ...algorithms],
          new DateRange(
            this._dateHelperService.truncateDate(startDate),
            this._dateHelperService.truncateDate(endDate)
          )
        );
      })
    );
  }

  getFiltrableItemsFromTemplateAndElements(
    workspace: WorkspaceDto,
    chartConfiguration: ChartConfiguration,
    elements: WlmElementExtended[]
  ): Observable<IFiltrableItemDto[]> {
    const dateRange = this.getConfiguredDateRange(workspace);
    const algorithmFiltrableItems = chartConfiguration.seriesConfiguration.map((x) =>
      plainToInstance(AlgorithmFiltrableItemDto, x.serieDefinition)
    );

    const algorithmFiltrableItemsToLoad$: Observable<AlgorithmFiltrableItemDto>[] = [];

    elements.forEach((element) => {
      algorithmFiltrableItems
        .filter((x) => x.filtrableType.entityType == element.entityType)
        .forEach((algorithmFiltrable) => {
          const algorithm = {
            algorithmShortName: algorithmFiltrable.filtrableType.algorithmShortName,
            algorithmDescription: algorithmFiltrable.filtrableType.algorithmDescription,
            timeAggregationId: algorithmFiltrable.filtrableType.timeAggregationId,
            dimensionTypeId: algorithmFiltrable.filtrableType.dimensionTypeId,
            entityTypeId: algorithmFiltrable.filtrableType.entityType,
          };
          const algorithmFiltrable$ = this._filtrableMapperService.setAlgorithmFiltrableItemElement(
            algorithm as IAlgorithmDto,
            element
          );
          algorithmFiltrableItemsToLoad$.push(algorithmFiltrable$);
        });
    });

    if (!algorithmFiltrableItemsToLoad$?.length) {
      return of([]);
    }

    return forkJoin(algorithmFiltrableItemsToLoad$).pipe(
      switchMap((algorithmFiltrableItemsToLoad) => {
        return this.getIncludeSignals(algorithmFiltrableItemsToLoad, workspace).pipe(
          map((signals) => {
            const filtrableItems: IFiltrableItemDto[] = [
              ...algorithmFiltrableItemsToLoad,
              ...signals,
            ];
            const modifiedItems = this.setFiltrableItemDate(filtrableItems, dateRange);
            return modifiedItems;
          })
        );
      })
    );
  }

  getWorkspaceDefinition(definition: string): WorkspaceDefinitionDto {
    const definitionParsed = JSON.parse(definition);
    const workspaceDefinition = plainToClass(WorkspaceDefinitionDto, definitionParsed);

    return workspaceDefinition;
  }

  getIncludeSignals(
    algorithmFiltrableItems: AlgorithmFiltrableItemDto[],
    workspace: WorkspaceDto
  ): Observable<SignalFiltrableItemDto[]> {
    if (
      !workspace.includeBoundariesSignals &&
      !workspace.includeLargeUsersSignals &&
      !workspace.includePressureSignals &&
      !workspace.includeInletOutletSignals &&
      !workspace.includeLevelSignals &&
      !workspace.includeLarsSworpsSignals &&
      !workspace.includeSmartMetersSignals
    ) {
      return of([]);
    }

    const elementIds = algorithmFiltrableItems.map((x) => x.filtrableType.element.elementId);

    const signalQuery = this.getSignalConfigurationQuery(
      elementIds,
      workspace.includeBoundariesSignals,
      workspace.includeLargeUsersSignals,
      workspace.includePressureSignals,
      workspace.includeInletOutletSignals,
      workspace.includeLevelSignals,
      workspace.includeLarsSworpsSignals,
      workspace.includeSmartMetersSignals
    );

    return this._signalService.getSignalsConfigurationForElement(signalQuery).pipe(
      map((signals) => {
        const uniqueSignals = asEnumerable(signals)
          .Distinct((s) => s.signalId)
          .ToArray();

        return uniqueSignals.map((signal) => {
          const signalNullable = new SignalTelemetryNullableViewDto({
            signalId: signal.signalId,
            pointId: signal.pointId,
            pointDescription: signal.pointDescription,
            isConfigured: true,
            dimensionTypeId: signal.dimensionTypeId,
            signalDescription: signal.signalDescription,
            unitTypeId: signal.unitTypeId.toString(),
            hierarchyElementId: signal.hierarchyElementId,
          });

          const signalFiltrableItem =
            this._filtrableMapperService.getSignalFiltrableItemDto(signalNullable);
          return signalFiltrableItem;
        });
      })
    );
  }

  getChartConfiguration(workspaceDefinition: WorkspaceDefinitionDto): ChartConfiguration[] {
    const chartConfigurations = plainToInstance(
      ChartConfiguration,
      workspaceDefinition.chartConfigurations
    );

    return chartConfigurations;
  }

  getConfiguredDateRange(workspace: WorkspaceDto) {
    if (workspace.timeWindowMode !== DateSelectModeEnum.Rolling) {
      return this.getDateRangeFromWorkspace(workspace);
    }

    const { timeAggregationRolling, rollingOffset, rollingWindowWidth } = workspace;

    const rollingDate = this._dateHelperService.getDateRangeFromRollingParams(
      timeAggregationRolling,
      rollingOffset,
      rollingWindowWidth
    );

    // Inclusive end
    return new DateRange(rollingDate.start, rollingDate.end);
  }

  getCartFiltrableitems(workspaceDefinition: WorkspaceDefinitionDto): IFiltrableItemDto[] {
    const cartItems = workspaceDefinition.cartFiltrableItems;
    return cartItems;
  }

  getTemplateEntityTypes(chartConfiguration: ChartConfiguration): EntityTypes[] {
    return asEnumerable(chartConfiguration.seriesConfiguration)
      .Select((x) => x.serieDefinition)
      .Select((x) => x.filtrableType)
      .Where((x) => x.type == FiltrableItemTypeEnum.Algorithm)
      .Select((x) => (x as AlgorithmFiltrableType).entityType)
      .Distinct()
      .ToArray();
  }

  getDateRangeFromWorkspace(workspace: WorkspaceDto) {
    if (!workspace) {
      return null;
    }

    const { startDate, endDate } = workspace;
    const start = this._dateHelperService.fromApiFormat(startDate);
    const end = this._dateHelperService.fromApiFormat(endDate);

    if (!start || !end) {
      return null;
    }

    return new DateRange(start, end);
  }

  getDateRangeFromChartConfiguration(chartConfiguration: ChartConfiguration) {
    if (!chartConfiguration) {
      return null;
    }

    const serieDefinition = chartConfiguration.seriesConfiguration[0]?.serieDefinition;
    if (!serieDefinition) {
      return null;
    }

    const { startDate, endDate } = serieDefinition;

    if (!startDate || !endDate) {
      return null;
    }

    return new DateRange(startDate, endDate);
  }

  getAlgorithmElementsSelectionPopup(algorithm: IAlgorithmDto): MatDialogRef<any> {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      algorithm,
    };
    dialogConfig.height = DataVisualizationAddAlgorithmPopupDimensions.Height;
    dialogConfig.width = DataVisualizationAddAlgorithmPopupDimensions.Width;

    let popupRef = null;

    switch (algorithm.entityTypeId) {
      case EntityTypes.hierarchyElement:
        popupRef = this._dialogService.openComponent(
          DataVisualizationHeAlgorithmPopupComponent,
          dialogConfig
        );
        break;

      case EntityTypes.networkElement:
      case EntityTypes.larsAndSworpsSite:
        popupRef = this._dialogService.openComponent(
          DataVisualizationNeAlgorithmPopupComponent,
          dialogConfig
        );
        break;

      default:
        break;
    }

    return popupRef;
  }

  getAlgorithmsByEntityTypes(
    chartConfiguration: ChartConfiguration,
    entityTypes: number[]
  ): Map<number, IAlgorithmDto[]> {
    const algorithmSerieDefinitions = asEnumerable(chartConfiguration.seriesConfiguration)
      .Select((x) => x.serieDefinition)
      .Where((x) => x.filtrableType.type == FiltrableItemTypeEnum.Algorithm)
      .ToArray();
    const algorithmsByEntityTypes = new Map<number, IAlgorithmDto[]>();

    entityTypes.forEach((entityType) => {
      const algorithmsByEntityType = algorithmSerieDefinitions
        .filter((x) => (x as AlgorithmFiltrableItemDto).filtrableType.entityType == entityType)
        .map((x) => {
          const algorithmFiltrableItem = x as AlgorithmFiltrableItemDto;
          const algorithm = new AlgorithmDto({
            algorithmDescription: algorithmFiltrableItem.filtrableType.algorithmDescription,
            algorithmShortName: algorithmFiltrableItem.filtrableType.algorithmShortName,
            dimensionTypeId: algorithmFiltrableItem.filtrableType.dimensionTypeId,
            entityTypeId: algorithmFiltrableItem.filtrableType.entityType,
            timeAggregationId: algorithmFiltrableItem.filtrableType.timeAggregationId,
          });

          return algorithm;
        });
      algorithmsByEntityTypes.set(entityType, [...algorithmsByEntityType]);
    });

    return algorithmsByEntityTypes;
  }

  setFiltrableItemDate(filtrableItems: IFiltrableItemDto[], dateRange: DateRange) {
    const configuredFiltrableItems = [];

    filtrableItems?.forEach((filtrableItem) => {
      const type = filtrableItem.filtrableType.type;

      let clonedFiltrableItem;

      if (type === FiltrableItemTypeEnum.Algorithm) {
        clonedFiltrableItem = plainToInstance(AlgorithmFiltrableItemDto, filtrableItem);
        this._algorithmFiltrableService.setDateRange(
          clonedFiltrableItem,
          dateRange.start,
          dateRange.end
        );
      } else if (type === FiltrableItemTypeEnum.Signal) {
        clonedFiltrableItem = plainToInstance(SignalFiltrableItemDto, filtrableItem);
        this._signalFiltrableService.setDateRange(
          clonedFiltrableItem,
          dateRange.start,
          dateRange.end
        );
      }

      if (clonedFiltrableItem) {
        configuredFiltrableItems.push(clonedFiltrableItem);
      }
    });

    return configuredFiltrableItems;
  }

  private getSignalConfigurationQuery(
    elementsIds: string[],
    includeBoundaries: boolean,
    includeLargeUsers: boolean,
    includePressure: boolean,
    includeInletOutlet: boolean,
    includeLevel: boolean,
    includeLarsSworps: boolean,
    includeSmartMeters: boolean
  ): SignalsConfiguredQueryDto {
    return new SignalsConfiguredQueryDto(
      elementsIds,
      includeBoundaries,
      includeLargeUsers,
      includePressure,
      includeInletOutlet,
      includeLevel,
      includeLarsSworps,
      includeSmartMeters
    );
  }
}
