import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { IGridSettings } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { BaseGridComponent } from '@common-modules/wlm-grid/base-grid/base-grid.component';

const COMPONENT_SELECTOR = 'wlm-grid-selector';

type SelectedElement = any;

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './grid-selector.component.html',
  styleUrls: ['./grid-selector.component.scss'],
})
export class GridSelectorComponent implements OnInit, OnChanges {
  @ViewChild('grid') gridComponent: BaseGridComponent;

  @Input() gridType: 'generic' | 'local' = 'local';
  @Input() gridName: string;
  @Input() gridData: any[];

  private _selectedElements: any[] = [];
  get selectedElements(): any[] {
    return this._selectedElements;
  }
  @Input() set selectedElements(value: any[]) {
    this._selectedElements = value;
    this.selectedManyChange.emit(this.selectedElements);
  }
  @Input() subscriptionTag = 'default';
  @Input() filters: Map<string, any>;
  @Input() widgetInstanceKey: string;

  @Output() selectedElementChange = new EventEmitter<SelectedElement>();
  @Output() selectedManyChange = new EventEmitter<SelectedElement[]>();

  gridSettings: IGridSettings;
  emptyFilters: DataBindingFilters;

  constructor(
    private _gridSettingService: GridSettingsService,
    private _dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gridName?.currentValue) {
      this.initializeGrid();
    }

    if (changes.initialSettings) {
      this.applyGridSettings();
    }
  }

  private initializeGrid() {
    if (this.gridName && !this.gridSettings) {
      this.setGridSettings();
    }
    this.emptyFilters = new DataBindingFilters();
    this.gridComponent?.clearFilters();
    this.gridComponent?.reloadGrid();
  }

  private setGridSettings() {
    this._gridSettingService.getGridSettingsByName(this.gridName).subscribe({
      next: (settings) => {
        this.gridSettings = this.postProcessSettings(settings);
      },
      error: (error) => {
        this._dialogService.showErrorMessage(error);
      },
    });
  }

  private applyGridSettings(): void {
    this.gridSettings = this.postProcessSettings(this.gridSettings);
  }

  private postProcessSettings(gridSettings: IGridSettings): IGridSettings {
    gridSettings.selectable = {
      enabled: true,
      checkboxOnly: true,
    };
    gridSettings.showSelectAllColumn = true;

    return gridSettings;
  }

  setSelectedElement(selectedElement: SelectedElement): void {
    this.selectedElementChange.emit(selectedElement);
  }

  setSelectedMany(selectedElements: SelectedElement[]): void {
    this.selectedManyChange.emit(selectedElements);
  }

  get componentName(): string {
    return 'GridSelectorComponent';
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}
}
