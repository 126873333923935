import { HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpCacheService } from '@common-modules/cache/http-cache/http-cache.service';
import { SourceSystemDto } from '@common-modules/dependencies/alc/source-system.dto';
import { SignalsService } from '@common-modules/dependencies/signals/signals.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { ISignalTelemetryNullableViewDto } from '@common-modules/shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { SignalTelemetryDto } from '@common-modules/shared/model/telemetry/signal-telemetry.dto';
import { IDimensionTypeDto } from '@common-modules/shared/model/unit/dimension-type.dto';
import { IUnitTypeDto } from '@common-modules/shared/model/unit/unit-type.dto';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';

const COMPONENT_SELECTOR = 'wlm-point-creation-popup';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './point-creation-popup.component.html',
  styleUrls: ['./point-creation-popup.component.scss'],
})
export class PointCreationPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  isLoading = false;
  titleKey: string;

  sourceSystems: SourceSystemDto[];
  dimensionTypes: IDimensionTypeDto[];
  unitTypes: IUnitTypeDto[];
  unitTypesByDimension: IUnitTypeDto[] = [];

  point: ISignalTelemetryNullableViewDto;
  isEditMode: boolean = false;

  form: UntypedFormGroup;

  // Form fields
  dimensionTypeIdFieldName = 'dimensionTypeId';
  unitTypeIdFieldName = 'unitTypeId';
  sourceSystemIdFieldName = 'sourceSystemId';
  pointIdFieldName = 'pointId';
  pointDescriptionFieldName = 'pointDescription';
  isPointOfInterestFieldName = 'isPointOfInterest';

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ISignalTelemetryNullableViewDto,
    private _formBuilder: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<PointCreationPopupComponent>,
    private _dialogsService: DialogService,
    private _cacheService: HttpCacheService,
    private _globalService: GlobalsService,
    private _signalService: SignalsService
  ) {
    this.point = data;
    this.isEditMode = data !== null;
    this.titleKey = this.isEditMode ? `${this.T_SCOPE}.title.edit` : `${this.T_SCOPE}.title.add`;
  }

  ngOnInit(): void {
    this.setLoading(true);

    this.createForm();

    const sourceSystems$ = this._globalService.getSourceSystems();
    const dimensionTypes$ = this._globalService.getDimensionTypes();
    const unitTypes$ = this._globalService.getUnitTypes();

    combineLatest([sourceSystems$, dimensionTypes$, unitTypes$])
      .pipe(untilDestroyed(this))
      .subscribe(([sourceSystems, dimensionTypes, unitTypes]) => {
        this.sourceSystems = sourceSystems;
        this.dimensionTypes = dimensionTypes;
        this.unitTypes = unitTypes;

        if (this.point?.dimensionTypeId >= 0) {
          this.unitTypesByDimension = unitTypes.filter(
            (ut) => ut.dimensionTypeId === this.point.dimensionTypeId
          );
        }

        this.setLoading(false);
      });
  }

  onSelectDimension(dimensionType: any) {
    this.unitTypesByDimension = this.unitTypes?.filter(
      (ut) => ut.dimensionTypeId === dimensionType
    );

    this.form.get(this.unitTypeIdFieldName).setValue(null);
  }

  save() {
    if (this.form?.valid) {
      this.setLoading(true);

      const signalTelemtryDto = this.getSignalTelemetryDto();

      let save$: Observable<boolean>;
      if (this.isEditMode) {
        save$ = this._signalService.editSignalTelemetry(signalTelemtryDto);
      } else {
        save$ = this._signalService.createSignalTelemetry(signalTelemtryDto);
      }

      save$
        .pipe(
          finalize(() => this.setLoading(false)),
          untilDestroyed(this)
        )
        .subscribe({
          next: () => {
            this._cacheService.clearContainsInUrl('telemetry/signal').then(() => {
              const dialogSettings = this.setDialogSettingsConfiguration(
                'messages.save-success',
                'success'
              );
              this._dialogsService.showTranslatedMessageInSnackBar(dialogSettings);

              this._dialogRef.close(true);
            });
          },
          error: (error) => {
            if (error?.status === HttpStatusCode.Conflict) {
              return this.setConflictValidation();
            }

            const dialogSettings = this.setDialogSettingsConfiguration(
              'messages.save-error',
              'error'
            );
            this._dialogsService.showTranslatedMessageInSnackBar(dialogSettings);
          },
        });
    }
  }

  cancel(): void {
    this._dialogRef.close();
  }

  private createForm() {
    const formControls: { [key: string]: UntypedFormControl } = {};

    formControls[this.dimensionTypeIdFieldName] = new UntypedFormControl(
      this.point?.dimensionTypeId,
      [Validators.required]
    );

    formControls[this.unitTypeIdFieldName] = new UntypedFormControl(this.point?.unitTypeId, [
      Validators.required,
    ]);

    formControls[this.sourceSystemIdFieldName] = new UntypedFormControl(
      this.point?.sourceSystemId,
      [Validators.required]
    );

    formControls[this.pointIdFieldName] = new UntypedFormControl(
      { value: this.point?.pointId, disabled: this.isEditMode },
      [Validators.required]
    );

    formControls[this.pointDescriptionFieldName] = new UntypedFormControl(
      this.point?.pointDescription,
      [Validators.required]
    );

    formControls[this.isPointOfInterestFieldName] = new UntypedFormControl(
      this.point?.isPointOfInterest ?? false,
      [Validators.required]
    );

    this.form = this._formBuilder.group(formControls);

    this.form.controls[this.pointIdFieldName].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.resetValidation(this.sourceSystemIdFieldName));

    this.form.controls[this.sourceSystemIdFieldName].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.resetValidation(this.pointIdFieldName));
  }

  private getSignalTelemetryDto(): SignalTelemetryDto {
    return new SignalTelemetryDto({
      signalId: this.point?.signalId,
      unitTypeId: this.form.get(this.unitTypeIdFieldName).value,
      sourceSystemId: this.form.get(this.sourceSystemIdFieldName).value,
      pointId: this.form.get(this.pointIdFieldName).value,
      pointDescription: this.form.get(this.pointDescriptionFieldName).value,
      isPointOfInterest: this.form.get(this.isPointOfInterestFieldName).value,
    });
  }

  private setConflictValidation() {
    this.form.get(this.pointIdFieldName).setErrors({ notUnique: true });
    this.form.get(this.sourceSystemIdFieldName).setErrors({ notUnique: true });
  }

  private setDialogSettingsConfiguration(
    messageKey: string,
    messageType: 'success' | 'error'
  ): WlmDialogSettings {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.${messageKey}`,
      icon: messageType,
    });
    return dialogSettings;
  }

  private resetValidation(controlName: string) {
    this.form.get(controlName).setErrors(null);
  }

  private setLoading(loading: boolean) {
    this.isLoading = loading;
  }
}
