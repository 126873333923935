import {
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BiFilters } from '@common-modules/dependencies/bi/bi-filters';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { TrendChartWidgetParams } from '@common-modules/dynamic-layout/models/widget-definition-settings';
import { DefaultParamsState } from '@common-modules/dynamic-layout/state/default-params/default-params-state';
import { ResetDefaultParamsAction } from '@common-modules/dynamic-layout/state/default-params/default-params.actions';
import { AppliedDefaultParamsSelector } from '@common-modules/dynamic-layout/state/default-params/default-params.selectors';
import { AppliedExtendedFiltersSelector } from '@common-modules/dynamic-layout/state/filters/filters.selectors';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ExtendedFilters } from '@common-modules/shared/filters/component-filters/extended-filters';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { IElementSize } from '@common-modules/shared/model/element-size';
import { SizeCalculatorService } from '@common-modules/shared/services/size-calculator.service';
import { EventViewCategories } from '@common-modules/wlm-charts/core/events-chart/models/event-view-categories';
import { EventChartQueryDto } from '@common-modules/wlm-charts/core/events-chart/models/events-chart-query.dto';
import { ChartType } from '@common-modules/wlm-charts/core/models/chart-type.enum';
import { DynamicChartSettings } from '@common-modules/wlm-charts/core/models/dynamic-chart-settings';
import { TrendChartDataParameters } from '@common-modules/wlm-charts/core/models/trend-chart-data-parameters';
import { TrendChartSettings } from '@common-modules/wlm-charts/core/models/trend-chart-settings';
import { DynamicChartComponent } from '@common-modules/wlm-charts/dynamic-chart/dynamic-chart.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseBIDynamicWidgetComponent } from '../../../models/base-bi-dynamic-widget';
import { BiService } from '../../../services/bi.service';

const COMPONENT_SELECTOR = 'wlm-trend-chart-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './trend-chart-widget.component.html',
  styleUrls: ['./trend-chart-widget.component.scss'],
})
export class TrendChartWidgetComponent extends BaseBIDynamicWidgetComponent implements OnInit {
  @ViewChild(DynamicChartComponent) dynamicChartComponent;

  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.BI,
  });

  chartDefinition: string;
  chartSettings: DynamicChartSettings;

  //From KPI definition
  dataService: string;
  eventCategories: EventViewCategories[];
  staticQueryParams: any;
  queryParamFieldsMap: { [key: string]: string };

  biPageFilters: BiFilters;
  biDefaultFilters: BiFilters;
  filters: BiFilters;

  private _previousFilters: BiFilters;

  private _setDateParams$ = new ReplaySubject<DateRange>();
  readonly setDateParams$ = this._setDateParams$.asObservable();

  private readonly _fixedSizeElements$ = new ReplaySubject<IElementSize>(1);
  readonly fixedSizeElements$ = this._fixedSizeElements$.asObservable();

  private _containerSize$: Observable<IElementSize>;
  get containerSize$(): Observable<IElementSize> {
    return this._containerSize$;
  }
  set containerSize$(value: Observable<IElementSize>) {
    this._containerSize$ = value;
    this.tryInitResize();
  }

  private _fixedSizes$: Observable<IElementSize>[] = [];

  private readonly _calculatedSize$ = new ReplaySubject<IElementSize>(1);
  readonly calculatedSize$ = this._calculatedSize$.asObservable();
  private _resizeSubscription: Subscription;

  private readonly _sizeCalculatorService = inject(SizeCalculatorService);
  protected readonly _destroyRef = inject(DestroyRef);

  private tryInitResize(): void {
    if (this.containerSize$ && this._fixedSizes$?.length) {
      const size$ = this._sizeCalculatorService.listenCalculateRemainingSize$({
        containerSize$: this.containerSize$,
        fixedSizes$: this._fixedSizes$,
        dimensionToCalculate: 'height',
        debugName: COMPONENT_SELECTOR,
      });

      this._resizeSubscription?.unsubscribe();
      this._resizeSubscription = size$
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe((size) => {
          this._calculatedSize$.next(size);
        });
    }
  }

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private readonly _objectHelperService: ObjectHelperService,
    private readonly _biService: BiService,
    private readonly _dateHelperService: DateHelperService,
    private readonly _element: ElementRef
  ) {
    super(injector, widgetSettings);

    this.setTitle(widgetSettings);

    this.setChartParamams(widgetSettings.itemSettings.params);
  }

  getAvailableHeight(): void {}

  onWidgetInit(): void {
    this.containerSize$ = this._sizeCalculatorService.listenElementSize$(
      this._element.nativeElement
    );

    this._state
      .select<ExtendedFilters>(new AppliedExtendedFiltersSelector(this._scopeSettings))
      .pipe(
        untilDestroyed(this),
        map((pageFilters) => pageFilters?.model as BiFilters),
        map((pageBiFilters: BiFilters) => {
          if (!pageBiFilters) {
            return null;
          }

          const { defaultFilters, lockFilters } = this.itemSettings;

          this.biPageFilters = globalUtilsHelper.clone(pageBiFilters, true);

          return this._biService.applyFilterLocks(defaultFilters, pageBiFilters, lockFilters);
        })
      )
      .subscribe({
        next: (biFilters: BiFilters) => {
          if (!biFilters) {
            return;
          }

          this.filters = biFilters;

          if (!this._previousFilters) {
            // Initial case.
            this.setDynamicChartSettings();
          } else {
            const filtersDiff: Partial<BiFilters> = this._objectHelperService.deepDiff(
              this.filters,
              this._previousFilters
            );
            if (typeof filtersDiff.selectedIds !== 'undefined') {
              this.setDynamicChartSettings();
            } else if (typeof filtersDiff.selectedDateRange !== 'undefined') {
              this.setDateParams(this.filters.selectedDateRange);
            }
          }

          this._previousFilters = this.filters;
          this._cd.detectChanges();
        },
      });

    this._state
      .select<DefaultParamsState>(new AppliedDefaultParamsSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (state) => {
          if (!state || state.widgetInstanceKey !== this.getWidgetInstanceKey()) {
            return;
          }

          this.itemSettings.defaultFilters = state.filters.model;
          this.itemSettings.lockFilters = state.lockFilters;

          this.setTitle(state);

          this.biDefaultFilters = state.filters.model as BiFilters;

          this.filters = this._biService.applyFilterLocks(
            this.biDefaultFilters,
            this.biPageFilters,
            state.lockFilters
          );

          const filtersDiff: Partial<BiFilters> = this._objectHelperService.deepDiff(
            this.filters,
            this._previousFilters
          );

          const newParams = state.params?.staticQueryParams;

          if (
            typeof filtersDiff.selectedIds !== 'undefined' ||
            (newParams && this.staticQueryParams !== newParams)
          ) {
            this.setStaticQueryParams(this.staticQueryParams, newParams);
            this.itemSettings.params.staticQueryParams = this.staticQueryParams;
            this.setDynamicChartSettings();
          } else if (typeof filtersDiff.selectedDateRange !== 'undefined') {
            this.setDateParams(this.filters.selectedDateRange);
          }
          this._previousFilters = this.filters;

          // This must be here because only the WB widget knows how to serialize its filters.
          this.updateItemSettings({
            params: this.itemSettings.params,
            defaultFilters: this.biDefaultFilters,
            lockFilters: state.lockFilters,
            widgetInstanceKey: this.getWidgetInstanceKey(),
            title: state.title,
          });

          // This is to avoid loading the same params again.
          this._state.dispatch(new ResetDefaultParamsAction(this._scopeSettings));
          this._cd.detectChanges();
        },
      });
  }

  get componentName(): string {
    return COMPONENT_SELECTOR;
  }

  setStaticQueryParams(oldParams, newParams) {
    if (!newParams) {
      return;
    }
    Object.keys(newParams)?.forEach((paramKey) => {
      oldParams[paramKey] = newParams[paramKey];
    });
  }

  setDynamicChartSettings() {
    const queryParams = this.getQueryParams();

    const startDate = this._dateHelperService.ensureDateObject(
      this.filters.selectedDateRange.start
    );
    const endDate = this._dateHelperService.ensureDateObject(this.filters.selectedDateRange.end);

    const dataParameters = new TrendChartDataParameters({
      endDate: endDate,
      startDate: startDate,
      queryParams,
      dataService: this.dataService,
    });

    const chartSettings = new TrendChartSettings({
      dataParameters,
      chartType: ChartType.trend,
    });

    const eventChartSettings = this.eventCategories?.length
      ? new EventChartQueryDto({
          categories: this.eventCategories,
          endDate: endDate,
          startDate: startDate,
          elementsIds: this.filters.selectedIds,
        })
      : null;

    const dynamicChartSettings = new DynamicChartSettings({
      chartSettings,
      eventChartSettings: eventChartSettings,
    });

    this.chartSettings = dynamicChartSettings;
  }

  setChartParamams(params: TrendChartWidgetParams): void {
    this.dataService = params.dataService;
    this.eventCategories = params.eventChartCategories;
    this.staticQueryParams = params.staticQueryParams;
    this.queryParamFieldsMap = params.queryParamFieldsMap;
  }

  setDateParams(dateRange: DateRange): void {
    const newDateRange = new DateRange(
      this._dateHelperService.ensureDateObject(dateRange.start),
      this._dateHelperService.ensureDateObject(dateRange.end)
    );
    this.filters.selectedDateRange = newDateRange;
    this._setDateParams$.next(newDateRange);
  }

  setElementsIds(elementIds: string[]): void {
    this.filters.selectedIds = elementIds;
    this.setDynamicChartSettings();
  }

  private getQueryParams() {
    const queryParams: { [key: string]: any } = { ...this.staticQueryParams };
    const fieldNames = this._biService.filters.fieldNames;

    const heIdParamName = this.queryParamFieldsMap[fieldNames.hierarchyElementId];
    const heFamilyIdParamName = this.queryParamFieldsMap[fieldNames.hierarchyFamilyId];
    const startDateParamName = this.queryParamFieldsMap[fieldNames.startDate];
    const endDateParamName = this.queryParamFieldsMap[fieldNames.endDate];

    queryParams[heIdParamName] = this.filters.selectedIds;
    queryParams[heFamilyIdParamName] = this.filters.selectedFamily;
    queryParams[startDateParamName] = this.filters.selectedDateRange.start;
    queryParams[endDateParamName] = this.filters.selectedDateRange.end;

    return queryParams;
  }

  onWidgetInfoSizeChanged(size: IElementSize): void {
    this._fixedSizes$ = [of(size)];
    this.tryInitResize();
  }
}
