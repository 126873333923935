import { Component, Inject, Injector, Input, OnInit, ViewChild } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
// prettier-ignore

const COMPONENT_SELECTOR = 'wlm-alc-leaks-activities-panel';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alc-leaks-activities-panel.component.html',
  styleUrls: ['./alc-leaks-activities-panel.component.scss'],
})
export class AlcLeaksActivitiesPanelComponent extends BaseWidgetComponent implements OnInit {
  @ViewChild(GenericGridComponent) public genericGrid: GenericGridComponent;
  private _gridFilters: DataBindingFilters;
  gridSettings: GridSetting;
  public get gridFilters(): DataBindingFilters {
    return this._gridFilters;
  }
  @Input()
  public set gridFilters(v: DataBindingFilters) {
    this._gridFilters = v;
    this.init();
  }

  isRightPanelGrid = true;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _gridService: GridSettingsService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._gridService.getGridSettingsByName('ActivitiesRightPanel').subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          gridSettings.showZoneColumns = false;
        }
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.gridFilters = parameters.parameters.get(TabDetailParameterName.dataBindingFilter);
  }
  init(): void {}

  get componentName() {
    return 'AlcLeaksActivitiesPanelComponent';
  }
}
