import { Observable } from 'rxjs';
import { FieldDefinition } from '../models/field-definition';
import { FieldsByCategory } from '../models/fields-by-category';

export abstract class BaseFormLabelOverwriteService {
  /**
   * Returns the formatted, uom-converted global value an inserted into translations.
   */
  abstract apply(
    field: FieldDefinition,
    currentLabel: string,
    translationKey?: string
  ): Observable<string>;

  /**
   * Returns the unprocessed, raw global value.
   */
  abstract getGlobalValue$(field: FieldDefinition): Observable<any>;

  abstract getFieldsByCategory$(): Observable<FieldsByCategory[]>;
}
