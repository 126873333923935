import { Component, OnDestroy, OnInit } from '@angular/core';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { NotificationRelatedComponent } from '@common-modules/shared-component/notification-related/notification-related.component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthorizeService } from '@common-modules/shared/auth/services/authorize.service';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { IGridSettings } from '@common-modules/shared/constants/grid.constants';
import { LabelValueListItem } from '@common-modules/shared/core/label-value-list/label-value-list-item';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsDisable } from '@common-modules/shared/grid-buttons/models/grid-btns-disable';
import { DateFormats } from '@common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { DynamicGridSettings } from '@common-modules/shared/model/grid/dynamic-grid-settings';
import { MapperFunctions } from '@common-modules/shared/services/generic-mappers/mapper-functions';
import { ToReadableDateMapperParams } from '@common-modules/shared/services/generic-mappers/mapper-params/to-readable-date-mapper-params';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';

import { LogsSystemCallStackWidgetComponent } from '../components/widgets/logs-system-call-stack-widget/logs-system-call-stack-widget.component';
import { ISystemLog } from '../models/system-log';

const COMPONENT_SELECTOR = 'wlm-logs-system-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './logs-system-page.component.html',
  styleUrls: ['./logs-system-page.component.scss'],
})
export class LogsSystemPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  readonly T_SCOPE = `${AppModules.Logs}.${COMPONENT_SELECTOR}`;

  private readonly _rightPanelAttributes: LabelValueListItem[] = [
    {
      label: `${this.T_SCOPE}.fields.auditId`,
      propertyFn: (model: ISystemLog) => model.auditId,
    },
    {
      label: `${this.T_SCOPE}.fields.timestamp`,
      propertyFn: (model: ISystemLog) => model.auditTimestamp,
      mappers: [MapperFunctions.ToReadableDate],
      mappersParams: {
        [MapperFunctions.ToReadableDate]: {
          format: DateFormats.DateTime,
          toUtc: false,
        } as ToReadableDateMapperParams,
      },
    },
    {
      label: `${this.T_SCOPE}.fields.category`,
      propertyFn: (model: ISystemLog) => model.category,
    },
    {
      label: `${this.T_SCOPE}.fields.priority`,
      propertyFn: (model: ISystemLog) => model.priority,
    },
  ];

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WLMSystemCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  get componentName(): string {
    return 'LogsSystemPageComponent';
  }

  grid: GenericGridComponent;
  dynamicGridSettings: DynamicGridSettings;
  additionalFilters: Map<string, any>;
  emptyFilters: DataBindingFilters = new DataBindingFilters();
  gridSettings: IGridSettings;
  gridName = 'LogsSystem';

  reloadGrid$ = new Subject<void>();
  gridSettingsChanged$ = new ReplaySubject<IGridSettings>();
  removeSelection$ = new Subject<void>();
  removeSelectionPersisted$ = new Subject<void>();
  gridBtnsDisable$: Subject<GridBtnsDisable>;
  gridSettingsReady$ = new ReplaySubject<IGridSettings>();

  selectedLog: ISystemLog;

  constructor(
    private _localizationHelperService: LocalizationHelperService,
    private _authService: AuthorizeService
  ) {
    super();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  ngOnInit(): void {
    super.ngOnInit();

    this.setRightPanel();
    this.initGridSettings();
  }

  getSelectedElement(selectedLog: ISystemLog) {
    this.selectedLog = selectedLog;

    this.sendParameters(selectedLog);
  }

  getGenericGridInstance(grid: GenericGridComponent) {
    this.grid = grid;
  }

  getGridSettings(gridSettings: IGridSettings) {
    this.gridSettings = gridSettings;
    this.gridSettingsReady$.next(gridSettings);
  }

  onGridSettingsChanged(settings: IGridSettings) {
    this.gridSettingsChanged$.next(settings);
  }

  private sendParameters(selectedLog: ISystemLog) {
    const parameters = new TabDetailPanelParameters();

    if (selectedLog) {
      const formattedDate = this._localizationHelperService.getLocalizedDateFromApi(
        selectedLog.auditTimestamp,
        DateFormats.DateTime
      );

      parameters.addParameter(
        TabDetailParameterName.elementName,
        `${formattedDate} - ${selectedLog.process}`
      );
    }

    parameters.addParameter(TabDetailParameterName.topAttributes, this._rightPanelAttributes);
    parameters.addParameter(TabDetailParameterName.topValues, selectedLog);

    parameters.addParameter(TabDetailParameterName.systemLog, selectedLog);

    parameters.addParameter(TabDetailParameterName.audit, selectedLog?.auditId);
    parameters.addParameter(TabDetailParameterName.notification, selectedLog);

    this.rightPanelService.setTabParameters(parameters);
  }

  private initGridSettings() {
    this.dynamicGridSettings = new DynamicGridSettings({
      gridSettingName: this.gridName,
      persistencyArea: this.persistencyArea,
      usePersistence: true,
      type: 'generic',
      disableAutoLoad: false,
    });
  }

  private setRightPanel(): void {
    const notificationPermission$ = this._authService.canAccess('WLMNotificationsCrud', 'r');
    const localization$ = this.localization.get(`${this.T_SCOPE}.tab-settings`);

    combineLatest([notificationPermission$, localization$]).subscribe(
      ([notificationPermission, ts]) => {
        const panelSettings = new TabDetailPanelSettings();

        panelSettings.addComponent(LogsSystemCallStackWidgetComponent, ts['log-system-call-stack']);

        if (notificationPermission) {
          panelSettings.addComponent(NotificationRelatedComponent, ts.notifications);
        }

        this.rightPanelService.setTabSettings(panelSettings);
      }
    );
  }
}
