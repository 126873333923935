import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpCacheService } from '@common-modules/cache/http-cache/http-cache.service';
import { IHierarchyElementDto } from '@common-modules/dependencies/he/hierarchy-element.dto';
import { CrudConstants } from '@common-modules/dependencies/shared/crud-constants';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthorizeService } from '@common-modules/shared/auth/services/authorize.service';
import { DragListCardSettings } from '@common-modules/shared/core/drag-list-card/drag-list-card-settings';
import { DragListCustomSettings } from '@common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { DragListSettings } from '@common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { PendingChanges } from '@common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from '@common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, catchError, finalize, map, of, tap } from 'rxjs';
import { MergedZonesDto } from '../../models/merged-zones.dto';
import { MergedZonesService } from '../../services/merged-zones.service';

const COMPONENT_SELECTOR = 'wlm-merged-zones-configurator';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './merged-zones-configurator.component.html',
  styleUrls: ['./merged-zones-configurator.component.scss'],
})
export class MergedZonesConfiguratorComponent implements OnInit, IPendingChangesEmitter {
  @Input() pageId: string;

  private _mergedZone: MergedZonesDto;
  public get mergedZone(): MergedZonesDto {
    return this._mergedZone;
  }
  @Input()
  public set mergedZone(value: MergedZonesDto) {
    this._mergedZone = value;
    if (value) {
      this.updateQueryParams();
      this.loadMergedZoneConfiguration();
    }
  }

  @Input() heFamilyId: string;

  @Output() onMergedSaved = new EventEmitter<void>();

  private readonly _hierarchyElementTypeIdFieldName = 'hierarchyElementTypeId';
  private readonly _hierarchyElementIdFieldName = 'hierarchyElementId';

  private readonly _titleFieldName = 'title';
  private readonly _hierarchyElementNameFieldName = 'hierarchyElementName';
  private readonly _serviceName = 'MergedZonesHeAvailableService';
  private readonly _pagesize = 20;

  readonly T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  private _configurationHasChanged = false;
  public get configurationHasChanged() {
    return this._configurationHasChanged;
  }
  public set configurationHasChanged(value) {
    this._configurationHasChanged = value;
    this.setPendingChanges(this.pageId, this.getPendingChanges(value));
  }

  private _originalMergedZoneElements: IHierarchyElementDto[] = [];

  settings: DragListSettings;
  settingsCustom: DragListCustomSettings;
  cardSettings: DragListCardSettings;
  reloadList$ = new Subject<void>();
  refreshList$ = new Subject<void>();
  queryParams: Map<string, any>;
  mergedZoneElements: IHierarchyElementDto[] = [];
  excludedElements: IHierarchyElementDto[] = [];
  isReadOnly = true;

  get componentName() {
    return 'MergedZonesConfiguratorComponent';
  }

  get mergedZoneHierarchyElement(): IHierarchyElementDto {
    return this.mergedZone?.hierarchyElementsMergedZones?.map(
      (mz) => mz.hierarchyElementParent
    )?.[0];
  }

  constructor(
    private readonly _spinnerService: SpinnerService,
    private readonly _authorizeService: AuthorizeService,
    private readonly _mergedZonesService: MergedZonesService,
    private readonly _arrayHelperService: ArrayHelperService,
    private readonly _dialogService: DialogService,
    private readonly _cacheService: HttpCacheService,
    private readonly _pendingChangesService: PendingChangesManagerService
  ) {}

  ngOnInit(): void {
    this.checkReadOnlyAccess();
    this.prepareListsSettings();
  }

  onDragListChange() {
    if (this.listHasChanged(this.mergedZoneElements, this._originalMergedZoneElements)) {
      this.configurationHasChanged = true;
      return;
    }

    this.configurationHasChanged = false;
  }

  onDroppedElement($event: IHierarchyElementDto) {
    const newExcludedElements = [...this.excludedElements, $event];

    this.excludedElements = [...newExcludedElements];
  }

  checkReadOnlyAccess() {
    this._authorizeService.canAccess(CrudConstants.MergedZones, 'u').subscribe((canAccess) => {
      this.isReadOnly = !canAccess;
    });
  }

  onSave() {
    this.save().subscribe(() => {});
  }

  save() {
    this.setLoading(true);

    return this._mergedZonesService
      .saveMergedZoneConfiguration(this.mergedZone.hierarchyElementId, this.mergedZoneElements)
      .pipe(
        tap((result) => {
          let dialogSettings = new WlmDialogSettings({ translateKey: '' });

          if (!result) {
            dialogSettings.translateKey = `${this.T_SCOPE}.messages.save-error`;
            dialogSettings.icon = 'error';
            this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
            return;
          }

          dialogSettings.translateKey = `${this.T_SCOPE}.messages.save-success`;
          dialogSettings.icon = 'success';
          this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);

          const clearhierarchy$ = this._cacheService.clearContainsInUrl('available-he');
          Promise.all([clearhierarchy$]).then(() => {
            this.updateOriginalLists();
            this.reloadList$.next();
            this.onMergedSaved.next();
          });
        }),
        catchError((error) => {
          this.displayErrorMessage(`${this.T_SCOPE}.messages.save-error`);

          return of(null);
        }),
        finalize(() => this.setLoading(false)),
        map((_) => true)
      );
  }

  discard() {
    this.mergedZoneElements = [...this._originalMergedZoneElements];
    this.excludedElements = [];

    this.reloadList$.next();
    this.configurationHasChanged = false;
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  updateQueryParams() {
    const newParams = new Map<string, any>();

    newParams.set(this._hierarchyElementTypeIdFieldName, this.mergedZone.hierarchyElementTypeId);

    this.queryParams = newParams;
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private updateOriginalLists() {
    this._originalMergedZoneElements = [...this.mergedZoneElements];
    this.configurationHasChanged = false;
  }

  private loadMergedZoneConfiguration() {
    this.setLoading(true);

    this._mergedZonesService
      .getMergedZoneElements(this.mergedZone.hierarchyElementId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (hierarchyElements) => {
          if (hierarchyElements) {
            const sortedElements = this._arrayHelperService.sortArrayObjectCaseInsensitive(
              hierarchyElements,
              this._hierarchyElementNameFieldName
            );

            this.mergedZoneElements = [...sortedElements];
            this._originalMergedZoneElements = [...sortedElements];

            this.configurationHasChanged = false;
          }

          this.setLoading(false);
        },
        error: (err) => {
          this.displayErrorMessage(`${this.T_SCOPE}.messages.load-error`);
          this.setLoading(false);
        },
      });
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: hasChanges,
      saveFn: () => this.save(),
    };
  }

  private prepareListsSettings() {
    this.settings = new DragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._hierarchyElementIdFieldName, dir: 'asc' }],
      useQueryParams: true,
      displayFieldName: this._titleFieldName,
      isReadOnly: this.isReadOnly,
      scrollId: this.componentName,
    });

    this.settingsCustom = new DragListCustomSettings({
      hideFilter: true,
      emptyLegendKey: this.T_SCOPE + '.messages.drag-list-empty-message',
      isReadOnly: this.isReadOnly,
    });

    this.cardSettings = new DragListCardSettings({
      fields: [this._hierarchyElementIdFieldName, this._hierarchyElementNameFieldName],
      fieldLabels: {
        hierarchyElementId: this._hierarchyElementIdFieldName,
        hierarchyElementName: this._hierarchyElementNameFieldName,
      },
      iconName: 'card-handler',
      isSvg: true,
      isReadOnly: this.isReadOnly,
    });
  }

  private listHasChanged(current: IHierarchyElementDto[], original: IHierarchyElementDto[]) {
    const configurationHasChanged = !this._arrayHelperService.areSame(current, original);
    return configurationHasChanged;
  }

  private displayErrorMessage(messageKey: string) {
    let dialogSettings = new WlmDialogSettings({ translateKey: '' });
    dialogSettings.translateKey = messageKey;
    dialogSettings.icon = 'error';
    this._dialogService.showTranslatedMessage(dialogSettings);
  }

  private setLoading(loading: boolean) {
    this._spinnerService.setLoading(loading, this.componentName);
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
