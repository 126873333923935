import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { ChartCustomization } from '@common-modules/shared/charts/model/chart/chart-customization';
import { PlotableSerie } from '@common-modules/shared/charts/model/series/plotable-serie';
import { SerieCustomization } from '@common-modules/shared/charts/model/series/serie-customization';
import { EventsControlSettings } from '@common-modules/shared/model/data-viz/events-control-settings.dto';
import { EventViewCategories } from '@common-modules/wlm-charts/core/events-chart/models/event-view-categories';
import { EventViewCategory } from '@common-modules/wlm-charts/core/events-chart/models/event-view-category';
import { ChartSerieType } from '@common-modules/wlm-charts/core/models/chart-serie-type';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ChartConfigurationService } from '../../../shared/charts/chart-configuration.service';
import { ChartConfiguration } from '../../../shared/charts/model/chart-configuration';

const COMPONENT_SELECTOR = 'wlm-data-visualization-chart-config-popup';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-chart-config-popup.component.html',
  styleUrls: ['./data-visualization-chart-config-popup.component.scss'],
})
export class DataVisualizationChartConfigPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;

  isFormReady: boolean = false;
  form: UntypedFormGroup;
  chartConfiguration: ChartConfiguration;
  serieTypes: ChartSerieType[];
  eventCategories: EventViewCategory[];

  showAllEventsCheck: boolean = false;

  // Form fields
  chartNameFieldName = 'chartName';
  serieNameFieldName = 'serieName';
  serieColorFieldName = 'serieColor';
  serieTypeFieldName = 'serieType';
  categoriesFieldName = 'eventCategories';

  constructor(
    @Inject(MAT_DIALOG_DATA) { chartConfiguration }: any,
    private _dialogRef: MatDialogRef<DataVisualizationChartConfigPopupComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _chartConfigurationService: ChartConfigurationService
  ) {
    this.chartConfiguration = chartConfiguration;
  }

  ngOnInit(): void {
    this.getSerieTypes();
    this.getEventCategories();
    this.createForm();
  }

  cancel(): void {
    this._dialogRef.close();
  }

  apply(): void {
    const chartConfiguration = this.generateChartConfiguration();

    this._dialogRef.close(chartConfiguration);
  }

  getFormColor(fieldName) {
    return this.form.get(fieldName).value;
  }

  setColor(color: string, fieldName: string) {
    this.form.get(fieldName).setValue(color);
    this.form.markAsDirty();
  }

  onShowAllEventsChange(event: MatSlideToggleChange) {
    let categories = [];

    if (event.checked) {
      categories = this.eventCategories.map((e) => e.value);
    }

    this.form.get(this.categoriesFieldName).setValue(categories);
    this.form.markAsDirty();
  }

  isCategoryChecked(eventCategory: EventViewCategory) {
    return this.form?.get(this.categoriesFieldName)?.value?.some((c) => c === eventCategory.value);
  }

  onShowCategoryChange(event: MatSlideToggleChange, eventCategory: EventViewCategory) {
    let categories = (this.form.get(this.categoriesFieldName).value ?? []) as EventViewCategories[];

    if (event.checked) {
      categories.push(eventCategory.value);
    } else {
      categories = categories.filter((c) => c !== eventCategory.value);
    }

    this.form.get(this.categoriesFieldName).setValue(categories);
    this.form.markAsDirty();
  }

  private createForm() {
    const { customization, seriesConfiguration, eventsControlSettings } = this.chartConfiguration;

    const formControls: { [key: string]: UntypedFormControl } = {};

    formControls[this.chartNameFieldName] = new UntypedFormControl(customization?.name);

    seriesConfiguration.forEach((serie, index) => {
      const serieCustomization = serie.serieCustomization;

      formControls[`${this.serieNameFieldName}${index}`] = new UntypedFormControl(
        serieCustomization.name,
        [Validators.required]
      );

      formControls[`${this.serieColorFieldName}${index}`] = new UntypedFormControl(
        serieCustomization.hexColor,
        [Validators.required]
      );

      formControls[`${this.serieTypeFieldName}${index}`] = new UntypedFormControl(
        serieCustomization.type,
        [Validators.required]
      );
    });

    formControls[this.categoriesFieldName] = new UntypedFormControl(
      eventsControlSettings.categories
    );

    this.form = this._formBuilder.group(formControls);

    this.showAllEventsCheck =
      eventsControlSettings.categories.length === this.eventCategories.length;

    this.isFormReady = true;
  }

  private generateChartConfiguration(): ChartConfiguration {
    const customization = new ChartCustomization({
      ...this.chartConfiguration.customization,
      name: this.form.get(this.chartNameFieldName).value,
    });

    const seriesConfiguration = this.chartConfiguration.seriesConfiguration.map((serie, index) => {
      const serieCustomization = new SerieCustomization({
        ...serie.serieCustomization,
        name: this.form.get(`${this.serieNameFieldName}${index}`).value,
        hexColor: this.form.get(`${this.serieColorFieldName}${index}`).value,
        type: this.form.get(`${this.serieTypeFieldName}${index}`).value,
      });

      return new PlotableSerie(serieCustomization, serie.serieDefinition);
    });

    const eventsControlSettings = new EventsControlSettings({
      ...this.chartConfiguration.eventsControlSettings,
      categories: this.form.get(this.categoriesFieldName).value,
    });

    return new ChartConfiguration({
      ...this.chartConfiguration,
      eventsControlSettings,
      customization,
      seriesConfiguration,
    });
  }

  private getSerieTypes() {
    this.serieTypes = this._chartConfigurationService.getChartSerieTypes();
  }

  private getEventCategories() {
    this.eventCategories = this._chartConfigurationService.getEventCategories();
  }
}
