import { Component, Injector, inject } from '@angular/core';
import { GenericCrudSettings } from '@common-modules/generic-crud/generic-crud-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { Observable, map } from 'rxjs';
import { IMaterialTypeCrudDto } from '../material-type-crud.dto';
import { MaterialTypesCrudService } from '../material-types-crud.service';
import { MaterialTypesFormComponent } from '../material-types-form/material-types-form.component';

const COMPONENT_SELECTOR = 'wlm-material-types-crud';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './material-types-crud.component.html',
  styleUrls: ['./material-types-crud.component.scss'],
})
export class MaterialTypesCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);
  private readonly _materialTypesCrudService = inject(MaterialTypesCrudService);
  private readonly _dialogService = inject(DialogService);
  private readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'MaterialTypesCrudService',
      grid: {
        gridSettingsName: 'MaterialTypesCrud',
      },
      create: {
        formComponent: MaterialTypesFormComponent,
        beforeSaveHook: this.beforeSaveHook,
      },
      update: {
        formComponent: MaterialTypesFormComponent,
        beforeSaveHook: this.beforeSaveHook,
      },
      delete: {},
    });
  }

  private beforeSaveHook = (
    model: IMaterialTypeCrudDto
  ): Observable<IMaterialTypeCrudDto | null> => {
    return this._materialTypesCrudService.validate(model).pipe(
      map((validation) => {
        if (validation.duplicatedMaterialIds.length) {
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              icon: 'error',
              translateKey: `${this.T_SCOPE}.duplicated-material-ids`,
              params: {
                duplicatedMaterialIds: validation.duplicatedMaterialIds.join(', '),
              },
            })
          );
          return null;
        }
        return model;
      })
    );
  };
}
