import { Injectable, Injector } from '@angular/core';
import { IEventDto } from '@common-modules/dependencies/alarms/event.dto';
import { Observable, of } from 'rxjs';
import { GetCacheOpts } from '../../cache/http-cache/http-cache.client';
import { EventChartQueryDto } from '../../wlm-charts/core/events-chart/models/events-chart-query.dto';
import { BaseService } from '../base.service';
import { SettingsService } from '../config/settings.service';
import { DateHelperService } from '../helpers/date-helper.service';

@Injectable()
export class EventService extends BaseService {
  expiration: GetCacheOpts = { avoid: true };

  constructor(
    injector: Injector,
    private _dateHelper: DateHelperService,
    private _settingsService: SettingsService
  ) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/event/historical`;
  }

  /**
   * Get all events, and allow to filter also by category.
   */
  getByCategories(query: EventChartQueryDto): Observable<IEventDto[]> {
    if (!query?.categories?.length) {
      return of([]);
    }

    const queryParams = {
      startDate: this._dateHelper.toApiFormat(query.startDate),
      endDate: this._dateHelper.toApiFormat(query.endDate),
      elementsIds: query.elementsIds,
      categories: query.categories,
      signalIds: query.signalIds,
    };

    const events = this.httpCacheClient.post<IEventDto[]>(`${this.url}/categories`, queryParams, {
      avoid: true,
    });
    return events;
  }
}
