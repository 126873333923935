import { Component, Input, OnInit } from '@angular/core';
import { ImageElement } from '@common-modules/shared/model/grid/image-element';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'wlm-image-column-filter',
  templateUrl: './image-column-filter.component.html',
  styleUrls: ['./image-column-filter.component.scss'],
})
export class ImageColumnFilterComponent extends BaseFilterCellComponent implements OnInit {
  @Input() field: string;
  @Input() filterService: FilterService;
  @Input() filter: CompositeFilterDescriptor;
  @Input() elements: Map<any, ImageElement>;

  selectedValue: any[];
  internalElements: any[];
  optionWidth: string;
  valuesSelected: any[] = [];
  private elseValue = -99999;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  ngOnInit(): void {
    const initialElements = Array.from(this.elements.keys()).map((m) => {
      if (!this.elements.get(m).isElse) {
        return {
          value: m,
          checked: false,
          text: this.elements.get(m),
          isSvg: this.elements.get(m).isSvg,
        };
      }
    });
    this.internalElements = initialElements.filter((f) => f !== undefined);

    let elseIncluded = false;
    this.elements.forEach((v, k) => {
      if (v.isElse && !elseIncluded) {
        this.internalElements.push({ value: this.elseValue, checked: false, text: v });
        elseIncluded = true;
      }
    });

    let valuesFiltered = [];

    if (this.filter.filters.some((f) => (f as FilterDescriptor).operator === 'ne')) {
      this.internalElements.find((f) => f.value === this.elseValue).checked = true;
    } else {
      valuesFiltered = this.filter?.filters?.map((m) => (m as FilterDescriptor)?.value);

      this.internalElements.forEach((f) => {
        if (valuesFiltered.includes(f.value)) {
          f.checked = true;
        }
      });
    }

    this.valuesSelected = this.internalElements
      .filter((f) => valuesFiltered.includes(f.value))
      .map((m) => m.value);
  }

  valueChange(selection: any): void {
    const checked = selection.target.checked;
    const currentValue = this.internalElements.find(
      (f) => f.value.toString() === selection.target.value
    ).value;

    if (checked) {
      this.valuesSelected.push(currentValue);
    } else {
      const index = this.valuesSelected.indexOf(currentValue, 0);
      if (index > -1) {
        this.valuesSelected.splice(index, 1);
      }
    }

    const currentValues: any = [];
    if (this.valuesSelected?.length === 0) {
      this.applyFilter(this.removeFilter(this.field));
    } else {
      const filter: CompositeFilterDescriptor = {
        logic: this.valuesSelected?.length > 1 ? 'or' : 'and',
        filters: [],
      };

      if (this.valuesSelected.includes(this.elseValue)) {
        currentValues.push(this.elseValue);
        this.internalElements
          .filter((f) => f.value !== this.elseValue)
          .forEach((e) => {
            filter.filters.push({
              field: this.field,
              operator: 'ne',
              value: e.value,
            });
          });
      } else {
        this.valuesSelected.forEach((item) => {
          currentValues.push(item);
          filter.filters.push({
            field: this.field,
            operator: 'eq',
            value: item,
          });
        });
      }

      this.applyFilter(this.removeFilter(this.field));
      this.applyFilter(filter);
    }
  }
}
