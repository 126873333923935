import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  IterableDiffers,
  OnInit,
  Output,
  PipeTransform,
} from '@angular/core';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { ColumnTitlePipe } from '@common-modules/wlm-grid/pipes/column-title.pipe';
import { AppModules } from '../../app-modules.enum';
import { BaseComponent } from '../../component/base.component';
import { globalUtilsHelper } from '../../helpers/global-utils-helper';
import { GridColumnHelperService } from '../../helpers/grid-column-helper.service';
import { DragListItemsSettings } from './models/drag-list-items-settings';

const COMPONENT_SELECTOR = 'wlm-drag-list';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './drag-list.component.html',
  styleUrls: ['./drag-list.component.scss'],
})
export class DragListComponent extends BaseComponent implements OnInit {
  private _objectsToOrder: any[];
  public get objectsToOrder(): any[] {
    return this._objectsToOrder;
  }
  @Input() public set objectsToOrder(v: any[]) {
    if (v?.length && this.itemsSettings) {
      this.setItemProperties(v);
    }

    this._objectsToOrder = v;
    this.objectsFiltered = globalUtilsHelper.clone(v, true);

    this.objectsToOrderChanged.emit(this.objectsFiltered);
  }
  @Output() objectsToOrderChanged = new EventEmitter<any[]>();

  private _selectedObject: any;
  public get selectedObject(): any {
    return this._selectedObject;
  }
  @Input() public set selectedObject(v: any) {
    this._selectedObject = v;
  }
  @Output() selectedObjectChange = new EventEmitter<any>();

  @Input() hideFilter = false;
  @Input() titlePipe: PipeTransform;
  @Input() itemsSettings: DragListItemsSettings;

  @Output() droppedElement = new EventEmitter<any>();
  @Output() somethingChanged = new EventEmitter<void>();
  @Output() clickButton = new EventEmitter<any>();

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;
  filterValue: string;
  objectsFiltered: any[];
  iterableDiffer: any;

  constructor(iterableDiffers: IterableDiffers, _gridColumnHelper: GridColumnHelperService) {
    super();
    this.iterableDiffer = iterableDiffers.find([]).create(null);
    this.titlePipe = new ColumnTitlePipe(_gridColumnHelper);
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.objectsToOrder);
    if (changes) {
      this.filterList(null);
    }
  }

  ngOnInit(): void {}

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  drop(event: CdkDragDrop<any[]>) {
    const { previousIndex, currentIndex, previousContainer, container } = event;
    const item = event.item.data;
    // If objectsFiltered is only used to render the previous element list,
    // then previousIndex is not actually related to the data collection.
    const getItemIdFn = this.itemsSettings.getItemIdFn;
    const realPreviousIndex = event.previousContainer.data.findIndex(
      (currentItem) => getItemIdFn(item) === getItemIdFn(currentItem)
    );

    if (previousContainer === container) {
      if (previousIndex === currentIndex) {
        return;
      }

      moveItemInArray(container.data, previousIndex, currentIndex);
    } else {
      transferArrayItem(previousContainer.data, container.data, realPreviousIndex, currentIndex);
    }

    this.droppedElement.emit(item);
    this.somethingChanged.emit();
  }

  getItemStyle(item: any): string {
    const baseStyle = item === this.selectedObject ? 'item-selected-box' : 'item-box';
    const separatorStyle = this.itemsSettings?.showSeparator ? item.separatorClass : '';

    return `${baseStyle} ${separatorStyle}`;
  }

  selectItem(item: any) {
    this.selectedObject = item === this.selectedObject ? undefined : item;
    this.selectedObjectChange.emit(this.selectedObject);
  }

  onClickItemButton($event: PointerEvent, item: any) {
    $event.stopPropagation();
    this.clickButton.emit(item);
  }

  filterList($event) {
    if (this.filterValue === '' || this.filterValue == undefined) {
      this.objectsFiltered = this.objectsToOrder;
      this.objectsToOrderChanged.emit(this.objectsFiltered);
      return;
    }

    const filterTolower = this.filterValue?.toLowerCase();
    this.objectsFiltered = this.objectsToOrder.filter((f) =>
      f.title?.toLowerCase().includes(filterTolower)
    );
    this.objectsToOrderChanged.emit(this.objectsFiltered);
  }

  private setItemProperties(items: any[]) {
    items.forEach((item) => {
      if (typeof this.itemsSettings.getSeparatorCssClassFn !== 'undefined') {
        const separatorCssClass = this.itemsSettings.getSeparatorCssClassFn(item, items);

        item.separatorClass = separatorCssClass;
      }

      if (typeof this.itemsSettings.getIconFn !== 'undefined') {
        item.iconName = this.itemsSettings.getIconFn(item);
      }

      if (typeof this.itemsSettings.getIconCssClassFn !== 'undefined') {
        item.iconClass = this.itemsSettings.getIconCssClassFn(item);
      }
    });
  }
}
