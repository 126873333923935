import { TimeAggregationEnum } from '@common-modules/shared/model/algorithm/time-aggregation.enum';
import { FiltrableItemTypeEnum } from '@common-modules/shared/model/filtrable-items/types/filtrable-item-type-enum';
import { ISignalFiltrableType } from '@common-modules/shared/model/filtrable-items/types/signal-filtrable-type';

export class SignalFiltrableType implements ISignalFiltrableType {
  key: string;
  title: string;
  signalId: number;
  pointId: string;
  pointDescription: string;
  dimensionTypeId: number;
  timeAggregationId: number = TimeAggregationEnum.Base;
  isConfigured: boolean;
  icon: string;
  type: FiltrableItemTypeEnum = FiltrableItemTypeEnum.Signal;

  constructor(
    title: string,
    signalId: number,
    pointId: string,
    pointDescription: string,
    dimensionTypeId: number,
    isConfigured: boolean,
    icon: string
  ) {
    this.key = `${signalId}-${pointId}`;

    this.title = title;
    this.signalId = signalId;
    this.pointId = pointId;
    this.pointDescription = pointDescription;
    this.dimensionTypeId = dimensionTypeId;
    this.isConfigured = isConfigured;
    this.icon = icon;
  }
}
