import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { NavItemsConfiguration } from '@water-loss//features/shared/model/navigation/navitem-configuration';
import { NavMenuConfiguration } from '@water-loss//features/shared/model/navigation/navmenu-configuration';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownNavigationItem } from '../../dependencies/navigation/dropdown-navigation-item';
import { AppModules } from '../app-modules.enum';
import { ApplicationAttributes } from '../constants/application-constants';
import { LocalizationHelperService } from '../localization/localization-helper.service';

@Injectable({
  providedIn: 'root',
})
export class NavMenuBuilderHelperService {
  constructor(private _localization: LocalizationHelperService) {
    this.initialize();
  }

  maxZonesSelectedTs$: Observable<string>;
  maxZonesMessage: string;

  private initialize(): void {
    const maxZones = ApplicationAttributes.MaxItemsForNavigation;

    this.maxZonesSelectedTs$ = this._localization.get(
      `${AppModules.WlmShared}.wlm-nav-dropdown.max-zones-selected`,
      { maxZones }
    );
  }

  buildMenu(navMenu: NavMenuConfiguration): Observable<DropdownNavigationItem[]> {
    return forkJoin([this.maxZonesSelectedTs$]).pipe(
      map(([maxZonesMessage]) => {
        this.maxZonesMessage = maxZonesMessage;

        const navItems: DropdownNavigationItem[] = [];
        navMenu.items.forEach((itemConf) => {
          const item = new DropdownNavigationItem({
            key: itemConf.key,
            disabled: this.getDisabledValue(itemConf, navMenu?.selectedElements),
            params: this.getParams(
              itemConf.paramType,
              navMenu?.selectedElements,
              itemConf.customParamsKey
            ),
            disabledKeyTooltip: this.getDisabledTooltip(itemConf, navMenu?.selectedElements),
            customNavMethod: itemConf.customNavMethod ? itemConf.customNavMethod : null,
            titleKey: itemConf.titleKey,
          });
          navItems.push(item);
        });
        return navItems;
      })
    );
  }

  getNavDisabledTooltipMessageByMaxZones(maxZones: number = 1): string {
    let tooltipMessage = '';

    this._localization
      .get(`${AppModules.WlmShared}.wlm-nav-dropdown.max-zones-selected`, { maxZones })
      .subscribe((message) => {
        tooltipMessage = message;
        return tooltipMessage;
      });

    return tooltipMessage;
  }

  private getParams(
    paramType: string,
    selectedElements: any[],
    customParams: string[] = undefined
  ): Params {
    if (!selectedElements?.length) {
      return;
    }

    let params: Params = {};
    const defaultParams = {
      hierarchyElementIds: selectedElements?.map((x) => x?.hierarchyElementId),
      hierarchyFamilyId: selectedElements[0]?.hierarchyFamilyId,
    };
    const hasElements = selectedElements?.length > 0;

    switch (paramType) {
      case 'he-family':
        params = hasElements ? defaultParams : null;
        break;

      case 'he-family-hetype':
        params = hasElements
          ? {
              ...defaultParams,
              hierarchyElementTypeId: selectedElements[0]?.hierarchyElementTypeId,
            }
          : null;
        break;

      case 'custom':
        params = customParams ? this.getCustomParams(customParams, selectedElements[0]) : null;
        break;

      case 'none':
        params = null;
        break;

      default:
        break;
    }
    return params;
  }

  private getCustomParams(customParamsKey: string[], selectedElement: any): Params {
    if (!customParamsKey || customParamsKey.length === 0) {
      return null;
    }

    let params = {};

    customParamsKey.forEach((key) => {
      params[key] = selectedElement[key];
    });

    return params as Params;
  }

  private getDisabledTooltip(
    navItemsConfiguration: NavItemsConfiguration,
    selectedElements: any[]
  ): string {
    let tooltip;
    switch (navItemsConfiguration.validationType) {
      case 'max-zones':
        tooltip = this.numberOfElementsExceeded(selectedElements) ? this.maxZonesMessage : '';
        break;

      case 'custom':
        tooltip = navItemsConfiguration.customtooltip;
        break;

      default:
        break;
    }
    return tooltip;
  }

  private getDisabledValue(
    navItemsConfiguration: NavItemsConfiguration,
    selectedElements: any[]
  ): boolean {
    let disabledValue = false;
    switch (navItemsConfiguration.validationType) {
      case 'max-zones':
        disabledValue =
          !selectedElements?.length || this.numberOfElementsExceeded(selectedElements);
        break;

      case 'custom':
        disabledValue = navItemsConfiguration.customValidation;
        break;

      case 'none':
        disabledValue = false;
        break;

      default:
        break;
    }
    return disabledValue;
  }

  private numberOfElementsExceeded(
    selectedElements: any[],
    maxZones: number = ApplicationAttributes.MaxItemsForNavigation
  ): boolean {
    return selectedElements?.length > maxZones;
  }
}
