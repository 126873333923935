import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import { RouteNameHelper } from '@common-modules/dependencies/navigation/route-name-helper';
import { AuthGuard } from '@common-modules/shared/auth/services/auth.guard';
import { LeavePageGuard } from '@common-modules/shared/navigation/leave-page.guard';
import { HistoricalAlarmsGridComponent } from '@common-modules/wlm-grid/historical-alarms/historical-alarms-grid.component';
import { RaiseActivityPageComponent } from './features/alc/raise-activity-page/raise-activity-page.component';
import { SAWizardPageComponent } from './features/configuration/sa-wizard-page/sa-wizard-page.component';

const featurePath = './features';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', canLoad: [AuthGuard] },
  {
    path: 'alarms/activation/:elementId',
    component: HistoricalAlarmsGridComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'alarms/activation/:elementId/:startDate',
    component: HistoricalAlarmsGridComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'alarms/activation/:elementId/:startDate/:endDate',
    component: HistoricalAlarmsGridComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'alc',
    loadChildren: () => import(`${featurePath}/alc/alc.module`).then((m) => m.ALCModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.AlcModule,
    },
  },
  {
    path: 'map',
    loadChildren: () => import(`${featurePath}/map/map.module`).then((m) => m.MapModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.DataAccessModule,
    },
  },
  {
    path: 'customer',
    loadChildren: () =>
      import(`${featurePath}/customer/customer.module`).then((m) => m.CustomerModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.DataAccessModule,
    },
  },
  {
    path: 'home',
    loadChildren: () => import(`${featurePath}/home/home.module`).then((m) => m.HomeModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'dn',
    loadChildren: () =>
      import(`${featurePath}/dn/distribution-network.module`).then(
        (m) => m.DistributionNetworkModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.LeakageCalculationModule,
    },
  },
  {
    path: 'tn',
    loadChildren: () =>
      import(`${featurePath}/tn/transmission-network.module`).then(
        (m) => m.TransmissionNetworkModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.LeakageCalculationModule,
    },
  },
  {
    path: 'pressure-management',
    loadChildren: () =>
      import(`${featurePath}/pressure-management/pressure-management.module`).then(
        (m) => m.PressureManagementModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.PressureManagementModule,
    },
  },
  {
    path: 'roles',
    loadChildren: () =>
      import(`@common-modules/wlm-security/wlm-security.module`).then((m) => m.WlmSecurityModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.AdministrationModule,
    },
  },
  {
    path: 'water-balance',
    loadChildren: () =>
      import(`${featurePath}/water-balance/water-balance.module`).then((m) => m.WaterBalanceModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.WaterBalanceModule,
    },
  },
  {
    path: 'alc/activity',
    component: RaiseActivityPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: `${RouteNameHelper.configurationModule}/hierarchy`,
    loadChildren: () =>
      import(`${featurePath}/hierarchy/hierarchy.module`).then((m) => m.HierarchyModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.ConfigurationModule,
    },
  },
  {
    path: `${RouteNameHelper.configurationModule}/${RouteNameHelper.mergedZones}`,
    loadChildren: () =>
      import(`${featurePath}/merged-zones/merged-zones.module`).then((m) => m.MergedZonesModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.ConfigurationModule,
    },
  },
  {
    path: RouteNameHelper.configurationModule,
    loadChildren: () =>
      import(`${featurePath}/configuration/configuration.module`).then(
        (m) => m.ConfigurationModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.ConfigurationModule,
    },
  },
  {
    path: 'dv/leakage-reporting',
    loadChildren: () =>
      import(`${featurePath}/leakage-reporting/leakage-reporting.module`).then(
        (m) => m.LeakageReportingModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.DataAccessModule,
    },
  },
  {
    path: RouteNameHelper.dataValidation,
    loadChildren: () =>
      import(`${featurePath}/data-validation/data-validation.module`).then(
        (m) => m.DataValidationModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.DataAccessModule,
    },
  },
  {
    path: 'dv',
    loadChildren: () =>
      import(`${featurePath}/data-visualization/data-visualization.module`).then(
        (m) => m.DataVisualizationModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.DataAccessModule,
    },
  },
  {
    path: 'dv-deprecated',
    loadChildren: () =>
      import(`${featurePath}/dv/data-visualization.module`).then(
        (m) => m.DataVisualizationDeprecatedModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.DataAccessModule,
    },
  },
  {
    path: 'alarm-creation',
    component: SAWizardPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.AlarmsCreation,
    },
  },
  {
    path: RouteNameHelper.notifications,
    loadChildren: () =>
      import(`${featurePath}/monitoring/monitoring.module`).then((m) => m.MonitoringModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.MonitoringModule,
    },
  },
  {
    path: RouteNameHelper.bi,
    loadChildren: () => import(`${featurePath}/bi/bi.module`).then((m) => m.BiModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.BiModule,
    },
  },
  {
    path: 'schematics',
    loadChildren: () =>
      import(`${featurePath}/schematics/schematics.module`).then((m) => m.SchematicsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      // navKey: NavKeys.Schematics,
    },
  },
  {
    path: 'logs',
    loadChildren: () => import(`${featurePath}/logs/logs.module`).then((m) => m.LogsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.LogsModule,
    },
  },
  {
    path: 'theme-test',
    loadChildren: () =>
      import(`${featurePath}/theme-test/theme-test.module`).then((m) => m.ThemeTestModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'integration',
    loadChildren: () =>
      import(`${featurePath}/integration/integration.module`).then((m) => m.IntegrationModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      superUser: true,
    },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [LeavePageGuard],
})
export class WaterLossRoutingModule {}
