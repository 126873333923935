import { INeHeFilterSettings } from '@common-modules/common-filters/filters/ne-type-filter/nehe-filter-settings';
import { DateRange } from '@common-modules/shared/model/date/date-range';

export class LeakageReportingFilterConfiguration {
  // settings
  persistencyArea: string;
  heSettings: INeHeFilterSettings;

  // default values
  defaultFamilyId: string;
  defaultHETypeIds: any[];
  dateRange: DateRange;
  defaultDateRange?: DateRange;

  // field names
  hierarchyElementFamilyFieldName: string;
  hierarchyElementIdFieldName: string;
  startDateFieldName: string;
  endDateFieldName: string;

  // persisted values
  initialFamilyId: string;
  initialHierarchyElementId: string[];
  initialHeTypeIds: string[];

  constructor(init: Readonly<LeakageReportingFilterConfiguration>) {
    Object.assign(this, init);
  }
}
