import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from '../../app-modules.enum';
import { BaseComponent } from '../../component/base.component';
import { GridSetting } from '../../constants/grid.constants';
import { DialogService } from '../../dialogs/dialogs.service';
import { JsonEditorComponent } from '../../json-editor/json-editor.component';
import { LocalizationHelperService } from '../../localization/localization-helper.service';
import { WlmDialogSettings } from '../../model/dialog/wlm-dialog-setting';
import { AddGridSettingsCmd } from '../../model/grid/AddGridSettingsCmd';
import { GridColumnSetting } from '../../model/grid/grid-column-setting';
import { GridSettingsService } from '../grid/grid-settings.service';
import { EditGridSettingsData } from './edit-grid-settings-data';

const COMPONENT_SELECTOR = 'wlm-edit-grid-settings-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './edit-grid-settings-popup.component.html',
  styleUrls: ['./edit-grid-settings-popup.component.scss'],
})
export class EditGridSettingsPopupComponent extends BaseComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  @ViewChild(JsonEditorComponent) public jsonEditor: JsonEditorComponent;

  private _excludedColumns: GridColumnSetting[];
  private _originalVisibleColumns: GridColumnSetting[];
  private _originalPagesize: number;

  private readonly excludedColumnTypes = ['export-only', 'internal', 'button'];

  private _gridSettings: GridSetting;
  public get gridSettings(): GridSetting {
    return this._gridSettings;
  }
  public set gridSettings(gridSetting: GridSetting) {
    this._gridSettings = gridSetting;
  }

  public get currentLocaleCss(): string {
    return `lang-${this._localizationHelper.currentLocale}`;
  }

  gridName: string;
  isSaving = false;
  hasErrors = false;

  constructor(
    private dialogRef: MatDialogRef<EditGridSettingsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) { gridSettings, gridName }: EditGridSettingsData,
    private _gridSettingsService: GridSettingsService,
    private _localizationHelper: LocalizationHelperService,
    private _dialogService: DialogService
  ) {
    super();

    this.gridSettings = gridSettings;
    this.gridName = gridName;
  }

  ngOnInit(): void {
    this.loadGridSettings();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  close() {
    this.dialogRef.close(false);
  }

  onJsonHasError(hasErrors) {
    this.hasErrors = hasErrors;
  }

  save() {
    let settings = this.jsonEditor.stringifyJson;
    if (typeof settings !== 'string') {
      settings = JSON.stringify(settings);
    }
    this._gridSettingsService
      .saveGridSettings(new AddGridSettingsCmd(this.gridName, settings, true))
      .subscribe({
        next: (response) => {
          const messageKey = 'system-template-saved';
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.messages.${messageKey}` })
          );
          this.close();
        },
        error: (error) => {
          this._dialogService.showErrorMessage(error);
        },
      });
  }

  private loadGridSettings() {
    this._gridSettingsService.getGridSettingsByName(this.gridName, true).subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }
}
