import { Component, EventEmitter, Inject, Injector, Input, Output, ViewChild } from '@angular/core';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { GenericCardSettings } from '@common-modules/shared-component/generic-card/generic-card-settings';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { SettingsService } from '@common-modules/shared/config/settings.service';
import { DragListSettings } from '@common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { SelectDragListSettings } from '@common-modules/shared/core/select-drag-list-virtual/select-drag-list-settings';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-selection';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-selection.component.html',
  styleUrls: ['./ne-configuration-selection.component.scss'],
})
export class NeConfigurationSelectionComponent extends BaseWidgetComponent {
  @ViewChild(GenericGridComponent) public neGrid: GenericGridComponent;

  private _filters: Map<string, any>;
  public get filters(): Map<string, any> {
    return this._filters;
  }
  @Input() public set filters(value: Map<string, any>) {
    this._filters = this._objectHelper.clone(value);

    this.prepareListsSettings();
  }
  @Input() widgetInstanceKey: string;

  @Output() selectedNEChange = new EventEmitter<INetworkElementDto>();

  emptyFilters: DataBindingFilters;

  private _networkElementNameFieldName = 'networkElementName';
  private _networkElementDescriptionFieldName = 'networkElementDescription';
  private _selectedFieldName = 'networkElementName';

  private _serviceName = 'NeConfigurationSelectionService';
  private _pagesize = 50;

  private _hierarchyCardSettings: { titleField: string; subtitleField: string };

  settings: DragListSettings;
  cardSettings: GenericCardSettings<INetworkElementDto>;
  queryParams: Map<string, any>;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _objectHelper: ObjectHelperService,
    private readonly _settingService: SettingsService
  ) {
    super(injector, widgetSettings);

    this._hierarchyCardSettings = this._settingService.hierarchyCardSettings;
  }

  get componentName(): string {
    throw 'NeConfigurationSelectionComponent';
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  onSelectItem(selectedNE: INetworkElementDto) {
    this.selectedNEChange.emit(selectedNE);
  }

  private prepareListsSettings() {
    this.settings = new SelectDragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._networkElementNameFieldName, dir: 'asc' }],
      oDataFiltersExtended: this.emptyFilters,
      useQueryParams: true,
      displayFieldName: this._networkElementNameFieldName,
      isReadOnly: false,
      scrollId: this.widgetInstanceKey,
      selectedFieldName: this._selectedFieldName,
      filtrableFields: [
        this._networkElementNameFieldName,
        this._networkElementDescriptionFieldName,
      ],
    });

    this.cardSettings = new GenericCardSettings<INetworkElementDto>({
      title1Fn: (model) => this.getHierarchyElementTitle(model),
      subtitleFn: (model) => this.getHierarchyElementSubtitle(model),
    });

    this.queryParams = this.filters;
  }

  private getHierarchyElementTitle(ne: INetworkElementDto): string {
    if (ne.isNetworkElement) {
      return ne.networkElementName;
    }

    return this._hierarchyCardSettings?.titleField == 'id'
      ? ne.networkElementName
      : ne.networkElementDescription;
  }

  private getHierarchyElementSubtitle(ne: INetworkElementDto): string {
    if (ne.isNetworkElement) {
      return ne.networkElementDescription;
    }

    return this._hierarchyCardSettings?.subtitleField == 'id'
      ? ne.networkElementName
      : ne.networkElementDescription;
  }
}
