import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { AlarmSeriesDto } from '@common-modules/dependencies/alarms/alarm-serie.dto';
import { NavigationMode } from '@common-modules/dependencies/navigation/navigation-mode.enum';
import { WNavigateSettings } from '@common-modules/dependencies/navigation/w-navigate-by';
import { WlmNavigationService } from '@common-modules/dependencies/navigation/wlm-navigation.service';
import { WlmElementExtended } from '@common-modules/shared/charts/model/elements/element-extended';
import { DataVisualizationManageTemplatePopupDimensions } from '@common-modules/shared/constants/dimensions.constants';
import { NavigationsConstants } from '@common-modules/shared/constants/navigation-constants';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { LocalStorageService } from '@common-modules/shared/local-storage.service';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { IFiltrableItemDto } from '@common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DataVisualizationManageWorkspacePopupComponent } from '../../data-visualization/components/data-visualization-manage-workspace-popup/data-visualization-manage-workspace-popup.component';
import { DataVisualizationManageWorkspaceResult } from '../../data-visualization/models/data-visualization-manage-workspace-result';
import { DataVisualizationWorkspaceNavigation } from '../../data-visualization/models/data-visualization-workspace-navigation';
import { WorkspacesHelperService } from '../../shared/services/workspaces-helper.service';

@UntilDestroy()
@Injectable()
export class DataVisualizationNavigationService {
  constructor(
    private readonly _dialogService: DialogService,
    private readonly _workspaceHelperService: WorkspacesHelperService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _spinnerService: SpinnerService,
    private readonly _navigateService: WlmNavigationService
  ) {}

  openManageTemplatePopupAndNavigate(
    navigateSettings: WNavigateSettings,
    elements: WlmElementExtended[],
    workspaceName?: string
  ) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.height = DataVisualizationManageTemplatePopupDimensions.Height;
    dialogConfig.width = DataVisualizationManageTemplatePopupDimensions.Width;

    dialogConfig.data = {
      selectedWorkspace: workspaceName,
      navigationMode: true,
    };

    const popupRef = this._dialogService.openComponent(
      DataVisualizationManageWorkspacePopupComponent,
      dialogConfig
    );

    popupRef?.afterClosed().subscribe((result: DataVisualizationManageWorkspaceResult) => {
      if (!result) {
        return;
      }

      this.setLoading(true);

      this._workspaceHelperService
        .getFiltrableItemsFromTemplateAndElements(
          result.workspace,
          result.chartConfiguration,
          elements
        )
        .subscribe({
          next: (items) => {
            this.setLoading(false);
            this.navigateToDV(navigateSettings, items, result);
          },
          error: () => {
            this.setLoading(false);
          },
        });
    });
  }

  navigateByAlarms(navigateSettings: WNavigateSettings, alarmsSeries: AlarmSeriesDto) {
    this.setLoading(true);

    this._workspaceHelperService.getFiltrableItemsFromAlarm(alarmsSeries).subscribe({
      next: (items) => {
        this.setLoading(false);
        this.navigateToDV(navigateSettings, items, null);
      },
      error: () => {
        this.setLoading(false);
      },
    });
  }

  private navigateToDV(
    navigateSettings: WNavigateSettings,
    items: IFiltrableItemDto[],
    result?: DataVisualizationManageWorkspaceResult
  ) {
    if (!items?.length) {
      //TODO: Add message when the navigation cannot be done. Specify if the problem was an incorrect template
      // or ther kind of error.
      return;
    }

    const firstItem = items[0];
    const dateRange = new DateRange(firstItem.startDate, firstItem.endDate);
    this._localStorageService.addOrUpdate(
      NavigationsConstants.NavigationModeKey,
      NavigationMode.Backend,
      true
    );

    const dvData = new DataVisualizationWorkspaceNavigation({
      workspace: result,
      filtrableItems: items,
      dateRange,
    });

    this._localStorageService.addOrUpdate(NavigationsConstants.QueryParamskey, dvData, true);
    this._navigateService.navigate({
      ...navigateSettings,
      urlPath: 'dv/data-visualization',
    });
  }

  private setLoading(isLoading: boolean) {
    this._spinnerService.setLoading(isLoading, DataVisualizationNavigationService.toString());
  }
}
