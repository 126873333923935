import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';

export class ElementNetworkElementsConfigurationDto {
  parent: INetworkElementDto;
  childrens: INetworkElementDto[];

  constructor(init: Readonly<ElementNetworkElementsConfigurationDto>) {
    Object.assign(this, init);
  }
}
