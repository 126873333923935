import { Injectable, Injector } from '@angular/core';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { MergedZonesDto } from '../models/merged-zones.dto';

const API_BASE = '/api/merged-zones';

@Injectable({
  providedIn: 'root',
})
export class MergedZonesSelectionService extends GridODataService<MergedZonesDto> {
  constructor(injector: Injector) {
    super(injector, API_BASE);
  }

  protected mapResponse(response: PagedResultDto<MergedZonesDto>): PagedResultDto<MergedZonesDto> {
    return response;
  }
}
