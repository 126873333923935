import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicLayoutModule } from '@common-modules/dynamic-layout/dynamic-layout.module';
import { FiltersStoreService } from '@common-modules/dynamic-layout/state/filters/filters-store.service';
import { GenericStoreService } from '@common-modules/dynamic-layout/state/generic/generic-store.service';
import { ReduxStateModule } from '@common-modules/redux/redux-state.module';
import { MaterialSharedModule } from '@common-modules/shared/material-shared.module';
import { LogsInterfacesService } from '@common-modules/shared/services/logs-interfaces.service';
import { WidgetRegistryService } from '@common-modules/widget-registry/widget-registry';
import { WLMGridModule } from '@common-modules/wlm-grid/wlm-grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { InterfacesModule } from '../interfaces/interfaces.module';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { AuditsPageComponent } from './audits/audits-page/audits-page.component';
import { AuditAttributesComponent } from './audits/components/audit-attributes/audit-attributes.component';
import { AuditAttributesWidgetComponent } from './audits/components/widgets/audit-attributes-widget/audit-attributes-widget.component';
import { AuditsService } from './audits/services/audits.service';
import { LogsInterfacesContainerComponent } from './logs-interfaces/logs-interfaces-container/logs-interfaces-container.component';
import { LogsInterfacesPageComponent } from './logs-interfaces/logs-interfaces-page/logs-interfaces-page.component';
import { LogsInterfacesSelectorComponent } from './logs-interfaces/logs-interfaces-selector/logs-interfaces-selector.component';
import { LogsInterfacesContainerWidgetComponent } from './logs-interfaces/widgets/logs-interfaces-container-widget/logs-interfaces-container-widget.component';
import { LogsInterfacesSelectorWidgetComponent } from './logs-interfaces/widgets/logs-interfaces-selector-widget/logs-interfaces-selector-widget.component';
import { LogsRoutingModule } from './logs-routing.module';
import { LogsSystemMessageComponent } from './logs-system/components/logs-system-message/logs-system-message.component';
import { LogsSystemCallStackWidgetComponent } from './logs-system/components/widgets/logs-system-call-stack-widget/logs-system-call-stack-widget.component';
import { LogsSystemPageComponent } from './logs-system/logs-system-page/logs-system-page.component';
import { LogsSystemService } from './logs-system/services/logs-system.service';

@NgModule({
  declarations: [
    LogsInterfacesPageComponent,
    LogsInterfacesSelectorComponent,
    LogsInterfacesContainerComponent,
    LogsInterfacesContainerWidgetComponent,
    LogsInterfacesSelectorWidgetComponent,
    LogsSystemPageComponent,
    LogsSystemCallStackWidgetComponent,
    LogsSystemMessageComponent,
    AuditsPageComponent,
    AuditAttributesWidgetComponent,
    AuditAttributesComponent,
  ],
  imports: [
    CommonModule,
    MaterialSharedModule,
    DynamicLayoutModule,
    WlmSharedModule,
    WlmSharedComponentModule,
    LogsRoutingModule,
    ReduxStateModule.forFeature({
      storeServices: [FiltersStoreService, GenericStoreService],
    }),
    TranslateModule,
    WLMGridModule,
    InterfacesModule,
  ],
  providers: [
    LogsInterfacesService,
    { provide: 'LogsInterfacesService', useExisting: LogsInterfacesService },
    LogsSystemService,
    { provide: 'LogsSystemService', useExisting: LogsSystemService },
    AuditsService,
    { provide: 'AuditsService', useExisting: AuditsService },
  ],
})
export class LogsModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      {
        name: 'LogsInterfacesSelectorWidgetComponent',
        widgetClass: LogsInterfacesSelectorWidgetComponent,
      },
      {
        name: 'LogsInterfacesContainerWidgetComponent',
        widgetClass: LogsInterfacesContainerWidgetComponent,
      },
      {
        name: 'LogsSystemCallStackWidgetComponent',
        widgetClass: LogsSystemCallStackWidgetComponent,
      },
      {
        name: 'AuditAttributesWidgetComponent',
        widgetClass: AuditAttributesWidgetComponent,
      },
    ]);
  }
}
