import { Component, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { FiltersAdapterService } from '@common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { FamilyFAdapter } from '../../../dependencies/wlm-filters/adapters/family-f-adapter';

import { FamilyLevelFilterConfiguration } from './family-level-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-family-level-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './family-level-filter.component.html',
  styleUrls: ['./family-level-filter.component.scss'],
})
export class FamilyLevelFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _filterConfiguration: FamilyLevelFilterConfiguration;
  public get filterConfiguration(): FamilyLevelFilterConfiguration {
    return this._filterConfiguration;
  }
  public set filterConfiguration(value: FamilyLevelFilterConfiguration) {
    this._filterConfiguration = value;
    if (value) {
      this.setFiltersPersistencyArea();
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  private _filterGroupSettings: FilterGroupSettings;
  public get settings(): FilterGroupSettings {
    return this._filterGroupSettings;
  }
  @Input() public set settings(v: FilterGroupSettings) {
    this._filterGroupSettings = v;

    if (v) {
      if (v.navigationParams) {
        this.loadNavigationParams(v.navigationParams);
      }

      this.loadState();
    }
  }

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;
  filterAdapterType = FilterAdapterEnum.Family;

  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableSelectAll: true,
    disableApplyFilters: false,
  };

  baseFamilyFilterSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-1-label`,
    disableSelectAll: true,
  };
  hierarchyFamilyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    id: this.filterAdapterType,
  });

  selectedFamilyId: any;

  constructor(
    private _globalsService: GlobalsService,
    private _adaptersService: FiltersAdapterService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  ngOnInit(): void {}

  buildKeysToComplete(): void {
    this.keysToComplete = [this.filterConfiguration.hierarchyFamilyFieldName];
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const { hierarchyFamilyFieldName, heFamilyFilterSettings } = this.filterConfiguration;

    const adapters = [
      new FamilyFAdapter({
        dataBindingField: hierarchyFamilyFieldName,
        heFamilyFieldName: hierarchyFamilyFieldName,
        heFamilyFilterSettings,
      }),
    ];

    return adapters;
  };

  loadState() {
    this.persistencyArea = this.settings.persistencyArea;

    if (!this.selectedFamilyId) {
      const persistedFamilyId = this.getPersisted(
        this.settings.fields.hierarchyFamilyId.fieldName,
        undefined
      );

      if (persistedFamilyId) {
        this.selectedFamilyId = (persistedFamilyId as BasicFilter)?.value?.shift()?.value;
      }
    }

    this._globalsService
      .getDefaultHierarchyFamilyId(this.selectedFamilyId)
      .subscribe((familyId) => {
        if (!this.selectedFamilyId) {
          this.selectedFamilyId = familyId;
        }

        this.filterConfiguration = new FamilyLevelFilterConfiguration(
          this.settings.persistencyArea,
          this.settings.fields.hierarchyFamilyId.fieldName,
          familyId,
          this.selectedFamilyId
        );
      });
  }

  onFamilyIdSelect(filters: FiltersPayload): void {
    this.selectedFamilyId = this._adaptersService.getFamilyId(
      filters,
      this.filterConfiguration.hierarchyFamilyFieldName,
      this.filterConfiguration.heFamilyFilterSettings.selectedId
    );
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }

  protected setFiltersPersistencyArea(): void {
    this.baseFamilyFilterSettings.persistencyArea = this.filterConfiguration.persistencyArea;
  }

  private loadNavigationParams(queryParams: Params) {
    this.selectedFamilyId = queryParams.hierarchyFamilyId;
  }
}
