import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { BiFilters } from '@common-modules/dependencies/bi/bi-filters';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { FilterAdditionalParam } from '@common-modules/dependencies/wlm-filters/filter-additional-param';
import { FilterGroupFieldSettings } from '@common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { ApplyExtendedFiltersAction } from '@common-modules/dynamic-layout/state/filters/filters.actions';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { ExtendedFilters } from '@common-modules/shared/filters/component-filters/extended-filters';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { ActivitiesGridFilterValidations } from '../../../alc/activities-grid/activities-grid-filter-validations';
import { BiService } from '../../services/bi.service';

const COMPONENT_SELECTOR = 'wlm-bi-filters';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './bi-filters.component.html',
  styleUrls: ['./bi-filters.component.scss'],
})
export class BiFiltersComponent extends BaseDynamicWidgetComponent implements OnInit {
  canLoad: boolean;
  filterDetailsParameters: TabDetailPanelParameters;
  autoLoad: boolean;
  persistFilters$ = new Subject<void>();
  filterSettings: FilterGroupSettings;

  offsetStartDate = 3;

  clearAll$ = new Subject<void>();

  private filters: DataBindingFilters;

  get componentName(): string {
    return 'BiFiltersComponent';
  }

  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.BI,
  });

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _biService: BiService
  ) {
    super(injector, widgetSettings);

    this.setPersistencyArea(widgetSettings.widgetInstanceKey);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const filterValidation = new ActivitiesGridFilterValidations();

    if (
      filtersParameters &&
      filterValidation.validateFilters(['hierarchyElementsIds'], filtersParameters)
    ) {
      this.filters = filtersParameters;
      this.canLoad = true;
    } else {
      this.canLoad = false;
    }
  }

  applyFilters() {
    if (this.canLoad) {
      const extendedFilters = this.buildExtendedFilters();
      this._state.dispatch(new ApplyExtendedFiltersAction(extendedFilters, this._scopeSettings));
      this.persistFilters$.next();
    }
  }

  private buildExtendedFilters(): ExtendedFilters {
    if (!this.filters) {
      return null;
    }

    const biFilters: Partial<BiFilters> = {};

    const dateRange: any = this.filters.filters.get('dateRange');
    if (dateRange) {
      biFilters.selectedDateRange = dateRange;
    }

    const heFilter: any = this.filters.filters.get('hierarchyElementsIds');
    if (heFilter) {
      biFilters.selectedFamily = heFilter.hierarchyFamilyId;
      biFilters.selectedIds = heFilter.elementIds;
      biFilters.selectedElements = heFilter.elementsWithNames;
    }

    const extendedFilters = new ExtendedFilters({
      dataBindingFilters: this.filters,
      model: biFilters as BiFilters,
    });
    return extendedFilters;
  }

  setFiltersDetailsParameters(value: TabDetailPanelParameters) {
    this.filterDetailsParameters = value;
    if (this.autoLoad && this.canLoad) {
      this.applyFilters();
      this.autoLoad = false;
    }
  }

  onCheckAutoload(): void {
    this.applyFilters();
  }

  onWidgetInit(): void {
    this.setFilterSettings(null);
  }

  private setPersistencyArea(widgetId: string) {
    this.persistencyArea = this.componentName + widgetId;
  }

  private setFilterSettings(params: Params) {
    const fieldNames = this._biService.filters.fieldNames;
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      hierarchyElementId: new FilterGroupFieldSettings({
        fieldName: fieldNames.hierarchyElementId,
      }),
      hierarchyFamilyId: new FilterGroupFieldSettings({
        fieldName: fieldNames.hierarchyFamilyId,
      }),
      startDate: new FilterGroupFieldSettings({
        fieldName: fieldNames.startDate,
      }),
      endDate: new FilterGroupFieldSettings({
        fieldName: fieldNames.endDate,
      }),
    };

    const additionalParams = {
      offsetStartDate: new FilterAdditionalParam({ value: this.offsetStartDate }),
      sendRootLevel: new FilterAdditionalParam({ value: true }),
      inclusiveEndDateParam: new FilterAdditionalParam({ value: false }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      additionalParams,
      navigationParams: params,
      persistencyArea: this.persistencyArea,
    });
  }
}
