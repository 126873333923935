import { IAlgorithmFiltrableItemDto } from '@common-modules/shared/model/filtrable-items/algorithm-filtrable-item.dto';
import { Type } from 'class-transformer';
import { AlgorithmFiltrableType } from './types/algorithm-filtrable-type';

export class AlgorithmFiltrableItemDto implements IAlgorithmFiltrableItemDto {
  @Type(() => Date)
  startDate: Date;
  @Type(() => Date)
  endDate: Date;
  filtrableType: AlgorithmFiltrableType;

  constructor(init: Partial<AlgorithmFiltrableItemDto>) {
    Object.assign(this, init);
  }
}
