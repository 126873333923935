import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { DynamicLayoutComponent } from '../../dynamic-layout/dynamic-layout.component';
import { DynamicLayoutSettings } from '../../models/dynamic-layout-settings';
import { DynamicLayoutService } from '../../services/dynamic-layout.service';

import { LayoutTemplatesService } from '../../services/layout-templates.service';
import { ApplyFiltersAction } from '../../state/filters/filters.actions';
import { SetValueAction } from '../../state/generic/generic.actions';
import { GetValueSelector } from '../../state/generic/generic.selectors';
import { SimpleDynamicLayoutSettingsItem } from '../simple-dynamic-layout-settings-item';
import { SelectorDynamicLayoutSettings } from './selector-dynamic-layout-settings';

const COMPONENT_SELECTOR = 'wlm-selector-dynamic-layout';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './selector-dynamic-layout.component.html',
  styleUrls: ['./selector-dynamic-layout.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class SelectorDynamicLayoutComponent implements OnInit {
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  private _settings: SelectorDynamicLayoutSettings;
  get settings(): SelectorDynamicLayoutSettings {
    return this._settings;
  }
  @Input() set settings(value: SelectorDynamicLayoutSettings) {
    this._settings = value;
    this.configure();
  }

  @Output() stateLoaded = new EventEmitter<any>();
  @Output() selected = new EventEmitter<any>();
  @Output() manySelected = new EventEmitter<any>();

  // Default areas that will be injected to all the components.
  private readonly _standardMDInstanceKeys = {
    [StateAreas.Filters]: 'shared',
    [StateAreas.Generic]: 'shared',
  };

  private _dynamicLayoutSettings$ = new ReplaySubject<DynamicLayoutSettings>(1);
  dynamicLayoutSettings: DynamicLayoutSettings;
  private _stateWidgetSettings: StateWidgetSettings;

  constructor(
    private _authenticationService: AuthenticationService,
    private _localization: LocalizationHelperService,
    private _state: ReduxStateService,
    private _dynamicLayoutService: DynamicLayoutService,
    private _layoutTemplatesService: LayoutTemplatesService
  ) {}

  ngOnInit(): void {}

  dispatchFilters(filters: DataBindingFilters): void {
    this._state.dispatch(new ApplyFiltersAction(filters));
  }

  refreshSelector(): void {
    this.dispatchFilters(null);
    this.dispatchFilters(new DataBindingFilters());
  }

  private configure(): void {
    this.buildStateWidgetSettings(this.settings).subscribe((stateWidgetSettings) => {
      this._stateWidgetSettings = stateWidgetSettings;
      this._state.configure(this._stateWidgetSettings);
      this.stateLoaded.emit(this._state);
      this.configureLayout();
      this.listenSelectors();
    });
  }

  private configureLayout(): void {
    this.buildDynamicLayoutSettings(this.settings).subscribe((dynamicLayoutSettings) => {
      this.dynamicLayoutSettings = dynamicLayoutSettings;
      this._dynamicLayoutSettings$.next(this.dynamicLayoutSettings);
      this.onLayoutInit();
    });
  }

  private listenSelectors(): void {
    const selectedField = this.settings.stateFields?.selected;
    if (selectedField) {
      this.selectField(selectedField).subscribe((selected) => {
        this.selected.emit(selected);
      });
    }

    const selectedManyField = this.settings.stateFields?.selectedMany;
    if (selectedManyField) {
      this.selectField(selectedManyField).subscribe((selected) => {
        this.manySelected.emit(selected);
      });
    }
  }

  private selectField<T>(fieldName: string): Observable<T> {
    return this._state
      .select<T>(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }

  private buildDynamicLayoutSettings(
    settings: SelectorDynamicLayoutSettings
  ): Observable<DynamicLayoutSettings> {
    return this.buildTitles(settings).pipe(
      map((titles) => {
        const { filterWidget, selectorWidget, receptorWidget } = settings.items;

        const instanceKeys = {
          filterWidget: this.getIdOrDefault(filterWidget),
          selectorWidgets: [this.getIdOrDefault(selectorWidget)],
          receptorWidgets: [this.getIdOrDefault(receptorWidget)],
        };

        const defaultStructure = this._layoutTemplatesService.buildMasterDetailLayout(instanceKeys);
        const items = [
          {
            componentName: selectorWidget.widgetName,
            widgetInstanceKey: instanceKeys.selectorWidgets[0],
            scopeInstanceKeys: this._standardMDInstanceKeys,
            title: titles.get('selectorWidget'),
          },
          {
            componentName: receptorWidget.widgetName,
            widgetInstanceKey: instanceKeys.receptorWidgets[0],
            scopeInstanceKeys: this._standardMDInstanceKeys,
            title: titles.get('receptorWidget'),
          },
        ];

        if (filterWidget) {
          items.push({
            componentName: filterWidget.widgetName,
            widgetInstanceKey: instanceKeys.filterWidget,
            scopeInstanceKeys: this._standardMDInstanceKeys,
            title: titles.get('filterWidget'),
          });
        }

        const dynamicLayoutSettings = new DynamicLayoutSettings({
          ...settings,
          widgetPage: this.getWidgetPage(settings),
          widgetModule: this.getWidgetModule(settings),
          currentUser: this._authenticationService.userCode,
          structure: [],
          defaultStructure,
          items,
          minItemHeight: 70,
          minItemWidth: 200,
          showCloseIcon: false,
        });

        return dynamicLayoutSettings;
      })
    );
  }

  private getWidgetPage = (settings: SelectorDynamicLayoutSettings): string =>
    settings.widgetPage ?? settings.layoutKey;

  private getWidgetModule = (settings: SelectorDynamicLayoutSettings): string =>
    settings.widgetModule ?? settings.layoutArea;

  private getIdOrDefault(item: SimpleDynamicLayoutSettingsItem): string {
    if (item) {
      return item.id ?? item.widgetName;
    }
    return null;
  }

  private buildStateWidgetSettings(
    settings: SelectorDynamicLayoutSettings
  ): Observable<StateWidgetSettings> {
    const stateWidgetSettings = new StateWidgetSettings({
      module: this.getWidgetModule(settings),
      page: this.getWidgetPage(settings),
      scopeInstanceKeys: this._standardMDInstanceKeys,
    });

    return of(stateWidgetSettings);
  }

  onLayoutInit(): void {}

  onResetLayout(): void {
    this._dynamicLayoutService.resetAll();
  }

  private buildTitles(settings: SelectorDynamicLayoutSettings): Observable<Map<string, string>> {
    const translateKeys = [
      settings.items.selectorWidget.titleKey,
      settings.items.receptorWidget.titleKey,
    ];

    if (settings.items.filterWidget) {
      translateKeys.push(settings.items.filterWidget.titleKey);
    }

    const obs$: any = {
      selectorWidget: this._localization.get(settings.items.selectorWidget.titleKey),
      receptorWidget: this._localization.get(settings.items.receptorWidget.titleKey),
    };

    if (settings.items.filterWidget) {
      obs$.filterWidget = this._localization.get(settings.items.filterWidget.titleKey);
    }

    return forkJoin(obs$).pipe(
      map((translations: any) => {
        const titles = new Map([
          ['selectorWidget', translations.selectorWidget],
          ['receptorWidget', translations.receptorWidget],
        ]);

        if (settings.items.filterWidget) {
          titles.set('filterWidget', translations['filterWidget']);
        }
        return titles;
      })
    );
  }

  private resetConfiguredActions(): void {
    if (this.settings.stateFields) {
      const allFields = Object.values(this.settings.stateFields);
      allFields.forEach((fieldName) => {
        this._state.dispatch(
          new SetValueAction({
            fieldName,
            value: null,
          })
        );
      });
    }
  }

  ngOnDestroy() {
    this.dispatchFilters(null);
    this.resetConfiguredActions();
  }
}
