import { Injectable, Injector } from '@angular/core';
import { GetCacheOpts } from '@common-modules/cache/http-cache/http-cache.client';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { AvailableSignalDto } from '../model/signals/available-signal.dto';

const endpointUrl = '/api/telemetry/signal/available';

@Injectable()
export class AvailableSignalsService extends GridODataService<AvailableSignalDto> {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector, endpointUrl);
  }

  protected mapResponse(
    response: PagedResultDto<AvailableSignalDto>
  ): PagedResultDto<AvailableSignalDto> {
    return response;
  }
}
