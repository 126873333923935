import { Component, Input, OnInit } from '@angular/core';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { CustomerPremisesViewDto } from '../../shared/model/customer/customer-premises-view.dto';

const COMPONENT_SELECTOR = 'wlm-customer-detail-single';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './customer-detail-single.component.html',
  styleUrls: ['./customer-detail-single.component.scss'],
})
export class CustomerDetailSingleComponent implements OnInit {
  @Input() customer: CustomerPremisesViewDto;
  readonly T_SCOPE = `${AppModules.Customer}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {}
}
