import { FormArray, FormGroup } from '@angular/forms';
import * as cloneDeep from 'clone-deep';
import * as DOMPurify from 'dompurify';
import deepEqual from 'fast-deep-equal/es6';
import { saveAs } from 'file-saver';
import isSvg from 'is-svg';
import * as $ from 'jquery';
import { Observable } from 'rxjs';
import { v4 as uuidv4, validate as validateGuid } from 'uuid';
import { IElementSize } from '../model/element-size';

/**
 * Encapsulates some useful methods that are sometimes used outside dependency injection, so they cannot go inside a service.
 */

function serializedClone<T>(obj): T {
  const cloned = JSON.parse(JSON.stringify(obj));
  return cloned;
}

function clone<T>(obj: T, useInstanceClone = false): T {
  if (useInstanceClone) {
    // Fallback that specifies how to clone instance of classes.
    const instanceClone = globalUtilsHelper.serializedClone;
    return cloneDeep(obj, instanceClone);
  }
  return cloneDeep(obj);
}

function generateGuid(): string {
  return uuidv4();
}

function isNullUndefined(value): boolean {
  return value === null || typeof value === 'undefined';
}

/**
 * This method only works if it receives an array of objects.
 * For an array of arrays, use groupArraysByFn.
 */
function groupByFn(array: any[], fn: (item) => any): { [key: string]: any[] } {
  return array.reduce((accum, item) => {
    (accum[fn(item)] = accum[fn(item)] || []).push(item);
    return accum;
  }, {});
}

function groupByFnHashInternal<TItem, TGroup>(
  array: TItem[],
  fn: (item: TItem) => any,
  addNewGroup: (newItem: TItem) => TGroup,
  addToGroup: (accum: TGroup, newitem: TItem) => TGroup
): Map<any, TGroup> {
  const hash = new Map<string | number, TGroup>();
  array.forEach((item) => {
    const key = fn(item);
    if (hash.has(key)) {
      hash.set(key, addToGroup(hash.get(key), item));
    } else {
      hash.set(key, addNewGroup(item));
    }
  });
  return hash;
}

function groupByFnHash<T>(array: T[], fn: (item) => any): Map<any, T[]> {
  const result = groupByFnHashInternal<T, T[]>(
    array,
    fn,
    (item) => [item],
    (group, item) => group.concat(item)
  );
  return result;
}

function groupByFnHashSingle<T>(array: T[], fn: (item) => any): Map<any, T> {
  const result = groupByFnHashInternal<T, T>(
    array,
    fn,
    (item) => item,
    (group, item) => item
  );
  return result;
}

function exportURL(url, name: string) {
  saveAs(url, name);
}

function resizeImage(url, width, fileName, callback) {
  var sourceImage = new Image();

  sourceImage.onload = function () {
    var canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d'),
      oc = document.createElement('canvas'),
      octx = oc.getContext('2d');

    canvas.width = width; // destination canvas size
    canvas.height = (canvas.width * sourceImage.height) / sourceImage.width;

    var cur = {
      width: Math.floor(sourceImage.width * 0.5),
      height: Math.floor(sourceImage.height * 0.5),
    };

    oc.width = cur.width;
    oc.height = cur.height;

    octx.drawImage(sourceImage, 0, 0, cur.width, cur.height);

    while (cur.width * 0.5 > width) {
      cur = {
        width: Math.floor(cur.width * 0.5),
        height: Math.floor(cur.height * 0.5),
      };
      octx.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
    }

    ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);

    // Convert the canvas to a data URL in PNG format
    callback(canvas.toDataURL(), fileName);
  };

  sourceImage.src = url;
}

function getElementSize(element: HTMLElement): IElementSize {
  if (!element) {
    return null;
  }
  const size: IElementSize = {
    width: element.offsetWidth,
    height: element.offsetHeight,
  };

  return size;
}

function downloadFile(url, fileName: string) {
  let link = document.createElement('a');
  link.setAttribute('type', 'hidden');
  link.href = `${url}`;
  link.download = fileName;

  document.body.appendChild(link);

  link.click();
  link.remove();
}

function downloadBlobFile(blob: Blob, fileName: string): void {
  const url = window.URL.createObjectURL(blob);
  downloadFile(url, fileName);
}

function fileToBase64(file: File): Observable<string> {
  return new Observable((observer) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64result = (reader.result as string).split(',')[1];

      observer.next(base64result);
      observer.complete();
    };
    reader.onerror = (error) => {
      observer.error(error);
    };
  });
}

function camelcase(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
}

function removeFilePath(title: string): string {
  if (!title) {
    return title;
  }

  if (title.includes(`\\`)) {
    const splitted = title.split(`\\`);
    return splitted[splitted.length - 1];
  }

  return title;
}

/**
 * Convert numeric formats to the format that angular decimal pipe uses.
 */
function translateNumericFormat(format: string): string {
  if (!format) {
    return null;
  }
  let currentFormat = format;
  const matches = format.match(/^(0+).(0+)$/);
  if (matches && matches.length === 3) {
    const digits = matches[1].length;
    const decimals = matches[2].length;
    currentFormat = `${digits}.${decimals}-${decimals}`;
  }
  return currentFormat;
}

function getFirstUrlSegment(url: string): string | null {
  const urlParts = url.split('://');
  if (urlParts.length >= 2) {
    const restOfParts = urlParts[1].split('.');
    return restOfParts[0];
  }
  return null;
}

function mapToPlainObject(map: Map<any, any>): { [key: string | number]: any } {
  const obj = Array.from(map.entries()).reduce(
    (main, [key, value]) => ({ ...main, [key]: value }),
    {}
  );
  return obj;
}

function mapToPlainObjectRecursive(map: Map<any, any>): any {
  const obj = Array.from(map.entries()).reduce(
    (main, [key, value]) => ({
      ...main,
      [key]: value instanceof Map ? this.mapToPlainObject(value) : value,
    }),
    {}
  );
  return obj;
}

function queryStringToJson(queryString) {
  if (queryString.indexOf('?') > -1) {
    queryString = queryString.split('?')[1];
  }
  const pairs = queryString.split('&');
  const result = {};
  pairs.forEach(function (pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  return result;
}

function jsonToQueryString(object): string {
  return $.param(object);
}

function setUrlFragments(fragments: string): void {
  window.location.hash = fragments;
}

function prettifySvg(xml: string, tab = '\t', nl = '\n'): string {
  if (!xml) {
    return xml;
  }
  let formatted = '',
    indent = '';
  const nodes = xml.slice(1, -1).split(/>\s*</);
  if (nodes[0][0] == '?') formatted += '<' + nodes.shift() + '>' + nl;
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (node[0] == '/') indent = indent.slice(tab.length); // decrease indent
    formatted += indent + '<' + node + '>' + nl;
    if (node[0] != '/' && node[node.length - 1] != '/' && node.indexOf('</') == -1) indent += tab; // increase indent
  }
  return formatted;
}

/**
 * Handle the case in which numbers are returned with comma instead of dot.
 */
function commaNumericStringToNumber(str: string): number | null {
  if (!str) {
    return null;
  }

  const commaMatches = str.match(/,/);
  const dotMatches = str.match(/\./);
  let formatted = str;
  if (dotMatches?.length > 1) {
    formatted = formatted.replace(/\./, '');
  }
  if (commaMatches?.length > 1 || (commaMatches?.length === 1 && dotMatches?.length === 1)) {
    formatted = formatted.replace(/,/, '');
  }
  if (commaMatches?.length === 1) {
    formatted = str.replace(/,/, '.');
  }
  return +formatted;
}

function isSvgText(svgText: string): boolean {
  return isSvg(svgText);
}

function purifyHtml(dirty: string): string {
  const clean = DOMPurify.sanitize(dirty, { USE_PROFILES: { html: true } });
  return clean;
}

function isValidJson(data: string): boolean {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
}

function isObject(obj): boolean {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
}

function markAllAsTouched(group: FormGroup | FormArray): void {
  Object.keys(group.controls).forEach((key: string) => {
    const abstractControl = group.controls[key];

    if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
      markAllAsTouched(abstractControl);
    } else {
      abstractControl.markAsTouched();
    }
  });
}

function normalizeId(id): string {
  let strId;
  if (globalUtilsHelper.isObject(id)) {
    strId = JSON.stringify(id);
  } else {
    try {
      const parsed = JSON.parse(id);
      strId = JSON.stringify(parsed);
    } catch (error) {
      strId = String(id);
    }

    strId = String(id);
  }

  return strId?.toLowerCase();
}

/**
 * Include all the ancestors of the nodes. Only include each node once.
 */
function getAncestors<T>(
  selectedNodes: T[],
  allNodes: T[],
  getKey: (node: T) => string,
  getParentKey: (node: T) => string
): T[] {
  let results = [...selectedNodes];
  selectedNodes.forEach((selectedNode) => {
    results = results.concat(getNodeAncestors(selectedNode, allNodes, getKey, getParentKey));
  });
  const resultsUnique: T[] = [];
  const included: Map<string, boolean> = new Map();
  results.forEach((node) => {
    const nodeKey = getKey(node);
    if (!included.has(nodeKey)) {
      resultsUnique.push(node);
      included.set(nodeKey, true);
    }
  });
  return resultsUnique;
}

/**
 * Get all the ancestors nodes of a node.
 */
function getNodeAncestors<T>(
  node: T,
  allNodes: T[],
  getKey: (node: T) => string,
  getParentKey: (node: T) => string
): T[] {
  const ancestors = [];
  const parentNode = allNodes.find((item) => getKey(item) === getParentKey(node));
  if (parentNode) {
    getNodeAncestorsRecursive(parentNode, ancestors, allNodes, getKey, getParentKey);
  }
  return ancestors;
}

/**
 * Get all the ancestors nodes of a node. Helper method.
 */
function getNodeAncestorsRecursive<T>(
  node: T,
  ancestors: T[],
  allNodes: T[],
  getKey: (node: T) => string,
  getParentKey: (node: T) => string
): void {
  ancestors.unshift(node);
  const parentNode = allNodes.find((item) => getKey(item) === getParentKey(node));
  if (parentNode) {
    getNodeAncestorsRecursive(parentNode, ancestors, allNodes, getKey, getParentKey);
  }
}

export const globalUtilsHelper = {
  serializedClone,
  clone,
  generateGuid,
  validateGuid,
  isNullUndefined,
  exportURL,
  resizeImage,
  groupByFn,
  groupByFnHash,
  groupByFnHashSingle,
  getElementSize,
  downloadFile,
  fileToBase64,
  camelcase,
  deepEqual,
  downloadBlobFile,
  removeFilePath,
  translateNumericFormat,
  getFirstUrlSegment,
  jsonToQueryString,
  setUrlFragments,
  mapToPlainObject,
  mapToPlainObjectRecursive,
  queryStringToJson,
  prettifySvg,
  isSvgText,
  commaNumericStringToNumber,
  purifyHtml,
  isValidJson,
  isObject,
  markAllAsTouched,
  normalizeId,
  getAncestors,
};
