import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { WidgetRegistryService } from '@common-modules/widget-registry/widget-registry';
import { WLMGridModule } from '@common-modules/wlm-grid/wlm-grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { BaseMapComponent } from './base-map/base-map.component';
import { FeatureInfoComponent } from './feature-info/feature-info.component';
import { GisLayerNamePipe } from './map-filter/map-filter-item-select.component/gis-layer-name.pipe';
import { MapFilterItemSelectComponent } from './map-filter/map-filter-item-select.component/map-filter-item-select.component';
import { MapFilterComponent } from './map-filter/map-filter.component';
import { MapHelperService } from './map-helper.service';
import { MapPageComponent } from './map-page/map-page.component';
import { MapRelatedComponent } from './map-related/map-related.component';
import { MapRoutingModule } from './map-routing.module';
import { MapSearchComponent } from './map-search/map-search.component';
import { MapTabPanelComponent } from './map-tab-panel/map-tab-panel.component';
import { MapThematicConfigurationPopupComponent } from './map-thematic-configuration-popup/map-thematic-configuration-popup.component';
import { MapThematicConfigurationComponent } from './map-thematic-configuration/map-thematic-configuration.component';
import { MapThematicLegendComponent } from './map-thematic-legend/map-thematic-legend.component';
import { MapThematicComponent } from './map-thematic/map-thematic.component';
import { MapTooltipItemComponent } from './map-tooltip/map-tooltip-item/map-tooltip-item.component';
import { MapTooltipComponent } from './map-tooltip/map-tooltip.component';
import { MapZoomConfigurationPopupComponent } from './map-zoom-configuration-popup/map-zoom-configuration-popup.component';
import { MapZoomConfigurationComponent } from './map-zoom-configuration/map-zoom-configuration.component';
import { MiniMapComponent } from './mini-map/mini-map.component';

const fieldAppearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [
    BaseMapComponent,
    MiniMapComponent,
    FeatureInfoComponent,
    MapPageComponent,
    MapFilterComponent,
    MapRelatedComponent,
    MapFilterItemSelectComponent,
    MapTooltipComponent,
    MapTooltipItemComponent,
    MapThematicComponent,
    MapSearchComponent,
    MapThematicConfigurationPopupComponent,
    MapThematicConfigurationComponent,
    MapThematicLegendComponent,
    MapTabPanelComponent,
    MapZoomConfigurationPopupComponent,
    MapZoomConfigurationComponent,
    GisLayerNamePipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MapRoutingModule,
    WlmSharedModule,
    WLMGridModule,
    WlmSharedComponentModule,
    MatTabsModule,
  ],
  exports: [
    BaseMapComponent,
    MiniMapComponent,
    MapFilterComponent,
    MapThematicComponent,
    MapSearchComponent,
  ],
  providers: [
    MapHelperService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: fieldAppearance,
    },
  ],
})
export class MapModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      {
        name: 'MapRelatedComponent',
        widgetClass: MapRelatedComponent,
      },
    ]);
  }
}
