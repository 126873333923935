import { Component, Input } from '@angular/core';
import { NoBoundarySettings } from '@common-modules/dependencies/ne-configuration/no-boundary-settings';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { IHasChanges } from '../../shared/model/has-changes.interface';

@Component({
  selector: 'wlm-ne-configuration-boundary-no-boundary',
  templateUrl: './ne-configuration-boundary-no-boundary.component.html',
  styleUrl: './ne-configuration-boundary-no-boundary.component.scss',
})
export class NeConfigurationBoundaryNoBoundaryComponent implements IHasChanges {
  @Input() selectedNE: INetworkElementDto;
  @Input() widgetId: string;
  @Input() pageId: string;
  @Input() noBoundarySettings: NoBoundarySettings;
  @Input() isHierarchyElement: boolean;

  private _hasChanges: boolean = false;

  itHasChanges(): boolean {
    return this._hasChanges;
  }

  onHasChanges(hasChanges: boolean): void {
    this._hasChanges = hasChanges;
  }
}
