import { TabDetailParameterName } from '@common-modules/dependencies/navigation/tab-detail-component';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { HierarchyElementPathFilter } from '@common-modules/dependencies/wlm-filters/i-filters/hierarchy-element-path-filter';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';

export class HierarchyElementPathFAdapter extends FilterAdapterSettings {
  id = FilterAdapterEnum.HierarchyElementPathFilter;

  hierarchyElementFamilyFieldName: string;
  hierarchyElementIdFieldName: string;
  familyId: any;
  familyFilterNotIncluded = false;

  constructor(init: {
    dataBindingField: string;
    hierarchyElementFamilyFieldName: string;
    hierarchyElementIdFieldName: string;
    familyId: any;
    familyFilterNotIncluded?: boolean;
  }) {
    super(init.dataBindingField);
    Object.assign(this, init);
  }

  adapt(filters: FiltersPayload): IFilter {
    const heFamilyFilter = filters.data.get(this.hierarchyElementFamilyFieldName);
    const heElementFilter = filters.data.get(this.hierarchyElementIdFieldName);
    if (
      heElementFilter &&
      (this.familyFilterNotIncluded || (!this.familyFilterNotIncluded && heFamilyFilter))
    ) {
      const result = new HierarchyElementPathFilter(
        this.familyId,
        [...heElementFilter.value],
        heFamilyFilter?.fieldName ?? this.hierarchyElementFamilyFieldName,
        heElementFilter.fieldName,
        heElementFilter.additionalFields?.elementsWithNames
      );
      return result;
    }
    return null;
  }

  buildResults(heIds: HierarchyElementPathFilter): FilterAdapterResult {
    if (heIds) {
      this.filtersResult.filters.set(this.dataBindingField, heIds);
      this.panelParams.addParameter(
        TabDetailParameterName.hierarchyElementIdsTree,
        heIds.elementIds
      );
      this.panelParams.addParameter(
        TabDetailParameterName.hierarchyFamilyIdTree,
        heIds.hierarchyFamilyId
      );
    }
    return new FilterAdapterResult({
      dbFilters: this.filtersResult,
      tabParams: this.panelParams,
    });
  }
}
