import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { CreateCalendarExceptionDto } from '../../models/create-calendar-exception.dto';
import { CalendarExceptionTypesEnum, DaysOfWeekEnum } from '../../models/exception-list.enum';
import { CalendarService } from '../../service/calendar.service';

const COMPONENT_SELECTOR = 'wlm-calendar-exception-creator';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './calendar-exception-creator.component.html',
  styleUrls: ['./calendar-exception-creator.component.scss'],
})
export class CalendarExceptionCreatorComponent implements OnInit {
  @Input() calendarId: string;
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() exceptionCreated = new EventEmitter<void>();

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  form: FormGroup;

  exceptionTypeFieldName = 'exceptionType';
  daysFieldName = 'daysOfTheWeek';
  dayFromFieldName = 'dayOfTheMonthFrom';
  dayToFieldName = 'dayOfTheMonthTo';
  yearlyFromFieldName = 'dayOfTheYearFrom';
  yearlyToFieldName = 'dayOfTheYearTo';
  dateRangeFieldName = 'fixedDateRange';

  exceptionTypes: { [key: string]: CalendarExceptionTypesEnum } = {};
  daysOfWeek: { [key: string]: DaysOfWeekEnum } = {};
  daysOfMonth: number[] = [];
  initialDateRange: DateRange;
  resetEndDate$ = new ReplaySubject<void>();
  resetStartDate$ = new ReplaySubject<void>();
  titleKeyFrom = `${this.T_SCOPE}.labels.start-date`;
  titleKeyTo = `${this.T_SCOPE}.labels.end-date`;
  yearlyRangeError: string;

  get dailyExceptionSelected() {
    return (
      this.form?.get(this.exceptionTypeFieldName)?.value === CalendarExceptionTypesEnum.DaysOfWeek
    );
  }

  get monthlyExceptionSelected() {
    return this.form?.get(this.exceptionTypeFieldName)?.value === CalendarExceptionTypesEnum.Month;
  }

  get yearlyExceptionSelected() {
    return this.form?.get(this.exceptionTypeFieldName)?.value === CalendarExceptionTypesEnum.Yearly;
  }

  get fixedExceptionSelected() {
    return this.form?.get(this.exceptionTypeFieldName)?.value === CalendarExceptionTypesEnum.Single;
  }

  get anySelected() {
    return (
      this.dailyExceptionSelected ||
      this.monthlyExceptionSelected ||
      this.yearlyExceptionSelected ||
      this.fixedExceptionSelected
    );
  }

  get hasErrors() {
    return this.form.errors;
  }

  private readonly _offsetStartDate = 1;

  constructor(
    private _formBuilder: FormBuilder,
    private _dateHelperService: DateHelperService,
    private _calendarService: CalendarService,
    private _dialogService: DialogService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.initExceptionTypes();
    this.initDaysOfWeek();
    this.initDaysList();
    this.initDefaultDateRange();
  }

  createForm() {
    const formControls: { [key: string]: FormControl } = {};

    formControls[this.exceptionTypeFieldName] = new FormControl(null);
    formControls[this.daysFieldName] = new FormControl(null, Validators.required);
    formControls[this.dayFromFieldName] = new FormControl(null, Validators.required);
    formControls[this.dayToFieldName] = new FormControl(null, Validators.required);
    formControls[this.yearlyFromFieldName] = new FormControl(null);
    formControls[this.yearlyToFieldName] = new FormControl(null);
    formControls[this.dateRangeFieldName] = new FormControl(null);

    this.form = this._formBuilder.group(formControls);

    this.form.addValidators([
      this.validateMonthlyRange,
      this.validateYearyRange,
      this.validateDayOfTheWeek,
    ]);
  }

  getExceptionControl() {
    const ctrl = new FormControl(null);
    ctrl.valueChanges.subscribe((value) => {
      this.form.updateValueAndValidity();
    });

    return ctrl;
  }

  validateDayOfTheWeek = (form: FormGroup) => {
    if (!this.dailyExceptionSelected) {
      return null;
    }

    const ctrl = form.get(this.daysFieldName);
    return ctrl.valid ? null : { requiredDayOfTheWeek: true };
  };

  validateMonthlyRange = (form: FormGroup) => {
    if (!this.monthlyExceptionSelected) {
      return null;
    }

    const ctrlFrom = form.get(this.dayFromFieldName);
    const ctrlTo = form.get(this.dayToFieldName);
    ctrlFrom.setErrors(null);
    ctrlTo.setErrors(null);

    const from = ctrlFrom?.value;
    const to = ctrlTo?.value;

    if (!from || !to) {
      return { requiredDaysOfTheMonth: true };
    }

    if (from > to) {
      const error = { invalidDayOfTheMonthRange: true };
      ctrlFrom.setErrors(error);
      ctrlTo.setErrors(error);

      return error;
    }

    return null;
  };

  validateYearyRange = (form: FormGroup) => {
    if (!this.yearlyExceptionSelected) {
      return null;
    }

    const ctrlFrom = form.get(this.yearlyFromFieldName);
    const ctrlTo = form.get(this.yearlyToFieldName);
    ctrlFrom.setErrors(null);
    ctrlTo.setErrors(null);
    this.yearlyRangeError = null;

    const from = ctrlFrom?.value;
    const to = ctrlTo?.value;

    if (!from || !to) {
      return { requiredDaysOfTheYear: true };
    }

    const fromDay = from.getDate();
    const fromMonth = from.getMonth();
    const toDay = to.getDate();
    const toMonth = to.getMonth();

    if (fromDay > toDay || fromMonth > toMonth) {
      const error = { invalidDayOfTheYearRange: true };
      ctrlFrom.setErrors(error);
      ctrlTo.setErrors(error);

      return error;
    }

    return null;
  };

  fixedExceptionError(error) {
    const fixedError = error ? { invalidFixedException: true } : null;
    this.form.setErrors(fixedError);
  }

  onDateRangeChanged(dateRange: DateRange) {
    this.form.get(this.dateRangeFieldName).setValue(dateRange);
  }

  saveException() {
    this.isLoading.emit(true);
    const newException = this.buildNewException();

    this._calendarService
      .createCalendarException(newException)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.clearFields();
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages.create-exception-success`,
              icon: 'success',
            })
          );
          this.exceptionCreated.emit();
          this.isLoading.emit(false);
        },
        error: (error) => {
          this.isLoading.emit(false);

          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages.create-exception-error`,
              icon: 'error',
            })
          );
        },
      });
  }

  private clearFields() {
    this.form.get(this.exceptionTypeFieldName).setValue(null);
    this.form.get(this.exceptionTypeFieldName).setErrors(null);
    this.form.get(this.daysFieldName).setValue(null);
    this.form.get(this.dayFromFieldName).setValue(null);
    this.form.get(this.dayToFieldName).setValue(null);
    this.form.get(this.yearlyFromFieldName).setValue(null);
    this.form.get(this.yearlyToFieldName).setValue(null);
    this.form.get(this.dateRangeFieldName).setValue(null);
  }

  private buildNewException() {
    const newException = new CreateCalendarExceptionDto({
      calendarId: this.calendarId,
    });

    if (this.dailyExceptionSelected) {
      this.populateDailyExceptionData(newException);
    }

    if (this.fixedExceptionSelected) {
      this.populateFixedExceptionData(newException);
    }

    if (this.monthlyExceptionSelected) {
      this.populateMonthlyExceptionData(newException);
    }

    if (this.yearlyExceptionSelected) {
      this.populateYearlyExceptionData(newException);
    }

    return newException;
  }

  private populateFixedExceptionData(newException: CreateCalendarExceptionDto) {
    newException.type = CalendarExceptionTypesEnum.Single;
    const dateRange: DateRange = this.form.get(this.dateRangeFieldName).value;

    this.populateDayAndMonth(newException, dateRange.start, dateRange.end);
    newException.startYear = dateRange.start.getFullYear();
    newException.endYear = dateRange.end.getFullYear();
  }

  private populateYearlyExceptionData(newException: CreateCalendarExceptionDto) {
    newException.type = CalendarExceptionTypesEnum.Yearly;
    const dateFrom = this.form.get(this.yearlyFromFieldName).value;
    const dateTo = this.form.get(this.yearlyToFieldName).value;

    this.populateDayAndMonth(newException, dateFrom, dateTo);
  }

  private populateDayAndMonth(
    newException: CreateCalendarExceptionDto,
    dateFrom: any,
    dateTo: any
  ) {
    newException.startDay = dateFrom.getDate();
    newException.startMonth = dateFrom.getMonth() + 1;

    newException.endDay = dateTo.getDate();
    newException.endMonth = dateTo.getMonth() + 1;
  }

  private populateMonthlyExceptionData(newException: CreateCalendarExceptionDto) {
    newException.type = CalendarExceptionTypesEnum.Month;
    newException.startDay = this.form.get(this.dayFromFieldName).value;
    newException.endDay = this.form.get(this.dayToFieldName).value;
  }

  private populateDailyExceptionData(newException: CreateCalendarExceptionDto) {
    newException.type = CalendarExceptionTypesEnum.DaysOfWeek;
    newException.dayOfWeek = this.form.get(this.daysFieldName).value;
  }

  private initExceptionTypes() {
    this.exceptionTypes['daily'] = CalendarExceptionTypesEnum.DaysOfWeek;
    this.exceptionTypes['monthly'] = CalendarExceptionTypesEnum.Month;
    this.exceptionTypes['yearly'] = CalendarExceptionTypesEnum.Yearly;
    this.exceptionTypes['fixed'] = CalendarExceptionTypesEnum.Single;
  }

  private initDaysOfWeek() {
    this.daysOfWeek[0] = DaysOfWeekEnum.Sunday;
    this.daysOfWeek[1] = DaysOfWeekEnum.Monday;
    this.daysOfWeek[2] = DaysOfWeekEnum.Tuesday;
    this.daysOfWeek[3] = DaysOfWeekEnum.Wednesday;
    this.daysOfWeek[4] = DaysOfWeekEnum.Thursday;
    this.daysOfWeek[5] = DaysOfWeekEnum.Friday;
    this.daysOfWeek[6] = DaysOfWeekEnum.Saturday;
  }

  private initDaysList() {
    for (let index = 1; index < 32; index++) {
      this.daysOfMonth.push(index);
    }
  }

  private initDefaultDateRange() {
    this.initialDateRange = this._dateHelperService.createDefaultDateRange(this._offsetStartDate);
  }
}
