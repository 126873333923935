import { Directive } from '@angular/core';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SelectorDynamicLayoutSettings } from '../selector-dynamic-layout/selector-dynamic-layout-settings';

@Directive()
export abstract class BaseSelectorDynamicPage extends BasePageComponent {
  settings: SelectorDynamicLayoutSettings;

  abstract getSettings(): Observable<SelectorDynamicLayoutSettings>;

  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    this.getSettings()
      .pipe(take(1))
      .subscribe((settings) => {
        this.loading = false;
        this.settings = settings;
      });
  }
}
