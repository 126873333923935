import { Component, Inject, Injector, OnInit } from '@angular/core';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { NoBoundarySettings } from '@common-modules/dependencies/ne-configuration/no-boundary-settings';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { BoundarySettingsSelector } from '@common-modules/dynamic-layout/state/ne-config/ne-config.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { applyOnActiveWidget } from '@common-modules/redux/operators/apply-on-active-widget.operator';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { necSelectNetworkElement } from '../../helpers/select-network-element';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-boundary-widget';
export const NEC_BOUNDARY_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-boundary-widget.component.html',
  styleUrls: ['./ne-configuration-boundary-widget.component.scss'],
  providers: [ReduxStateService],
})
export class NeConfigurationBoundaryWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  currentNE: INetworkElementDto;
  widgetId: string;
  pageId: string;
  boundarySettings: NoBoundarySettings;
  isHierarchyElement: boolean;

  // Not all actions/selectors must have the same action settings.
  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);

    this.widgetId = widgetSettings.widgetInstanceKey;
    this.pageId = widgetSettings.page;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onWidgetInit(): void {
    necSelectNetworkElement(
      this,
      this._state,
      this._scopeSettings,
      (selectedNE: INetworkElementDto) => this.setNewNetworkElement(selectedNE)
    );

    this._state
      .select<NoBoundarySettings>(new BoundarySettingsSelector(this._scopeSettings))
      .pipe(
        untilDestroyed(this),
        applyOnActiveWidget(this, (boundarySettings: NoBoundarySettings) => {
          this.boundarySettings = boundarySettings;
        })
      )
      .subscribe();
  }

  get componentName(): string {
    return 'NeConfigurationBoundaryWidgetComponent';
  }

  private setNewNetworkElement(selectedNE: INetworkElementDto) {
    if (!selectedNE) {
      return;
    }
    this.currentNE = selectedNE;
    this.isHierarchyElement = this.currentNE.isZone;
  }
}
