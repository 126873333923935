import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, ReplaySubject, forkJoin, from, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';
import { AppModules } from '../app-modules.enum';
import { EligibilityPopupDimensions } from '../constants/eligibility.constants';
import { ObjectHelperService } from '../helpers/object-helper.service';
import { LocalizationHelperService } from '../localization/localization-helper.service';
import { WLMDialogResult } from '../model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from '../model/dialog/wlm-dialog-setting';

@Injectable({ providedIn: 'root' })
export class DialogService {
  private readonly T_SCOPE_TOASTS = `${AppModules.WlmShared}.toasts`;

  private _isLoaded = false;
  private _isAvailable$ = new ReplaySubject<void>();

  private _toastTitles: Map<string, string> = new Map<string, string>();

  constructor(
    private localization: LocalizationHelperService,
    private snackBar: MatSnackBar,
    private _matDialog: MatDialog,
    private _objectHelperService: ObjectHelperService
  ) {}

  /**
   * Receives WlmDialogSettings model with the message key, gets the translation and displays it in a simple message popup.
   */
  showTranslatedMessage(settings: WlmDialogSettings): void {
    this.localization
      .get(settings.translateKey, settings.params)

      .subscribe((message) => {
        this.showMessage(message, settings.icon);
      });
  }

  /**
   * Receives a WlmDialogSettings model with the message key, gets the translation and displays it in a snackbar.
   */
  showTranslatedMessageInSnackBar(settings: WlmDialogSettings): void {
    this.loadSnackBarMessage(settings.translateKey, settings.params).subscribe((message) => {
      const type = settings.icon ?? 'success';

      const toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: settings.timer ?? 3000,
        timerProgressBar: false,
        didOpen: (toastWindow) => {
          toastWindow.addEventListener('mouseenter', Swal.stopTimer);
          toastWindow.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

      toast.fire({
        iconHtml: `<i class="swal2-toast-icon swal2-custom-icon-${type}"></i>`,
        title: this._toastTitles.get(type),
        text: message,
        customClass: {
          title: 'swal2-toast-title',
          container: 'swal2-toast-container',
          htmlContainer: 'swal2-toast-text',
          icon: 'swal2-toast-icon',
          popup: ['swal2-toast-popup', `swal2-toast-popup-${type}`],
        },
        showClass: {
          icon: '', // disable icon animation
        },
      });
    });
  }

  getDialogSettingsConfiguration(
    messageKey: string,
    messageType: 'success' | 'error',
    scope: string
  ): WlmDialogSettings {
    const errorMessageKey = `${scope}.${messageKey}`;
    const dialogSettings = new WlmDialogSettings({
      translateKey: errorMessageKey,
      icon: messageType,
    });
    return dialogSettings;
  }

  showEntityActionSnackBar(
    actionKey: 'save' | 'delete' | 'create' | 'update',
    entityKey: string
  ): void {
    this.localization.get(`common.entities.${entityKey}`).subscribe((entityName) => {
      this.showTranslatedMessageInSnackBar(
        new WlmDialogSettings({
          translateKey: `common.messages.${actionKey}`,
          params: {
            entityName,
          },
          icon: 'success',
        })
      );
    });
  }

  showDeleteConfirmationSnackBar(
    entitiesAmount: number,
    entityKey: string
  ): Observable<WLMDialogResult> {
    if (entitiesAmount < 1) {
      return of(null);
    }

    const entityFullKey = `common.entities.${entityKey}.${
      entitiesAmount === 1 ? 'singular' : 'plural'
    }`;

    const messageFullKey = `common.messages.${
      entitiesAmount === 1 ? 'delete-confirm' : 'delete-confirm-many'
    }`;

    return this.localization.get(entityFullKey).pipe(
      take(1),
      switchMap((entityName) => {
        return this.showTranslatedDialogMessage(
          new WlmDialogSettings({
            translateKey: messageFullKey,
            params: {
              entityName,
            },
            icon: 'warning',
          })
        );
      })
    );
  }

  /**
   * Receives a WlmDialogSettings model with the message key, gets the translation and displays it in a dialog with confirmation.
   */
  showTranslatedDialogMessage(settings: WlmDialogSettings): Observable<WLMDialogResult> {
    const title$ = this.localization.get(settings.translateKey, settings.params);
    const confirm$ = this.localization.get(settings?.confirmButtonTextKey ?? 'common.accept');
    const deny$ = this.localization.get(settings?.denyButtonTextKey ?? 'common.cancel');

    const iconHtml =
      settings.icon === 'error' || settings.icon === 'warning'
        ? `<i class="swal2-custom-icon swal2-custom-icon-${settings.icon}"></i>`
        : null;

    return forkJoin([title$, confirm$, deny$]).pipe(
      switchMap(([message, confirmText, denyText]) => {
        return from(
          Swal.fire({
            title: message,
            iconHtml,
            showDenyButton: true,
            confirmButtonText: confirmText,
            denyButtonText: denyText,
            customClass: {
              title: 'sweet-dialog-title',
              popup: 'sweet-dialog-popup',
              denyButton: 'sweet-dialog-buttons',
              confirmButton: 'sweet-dialog-buttons',
            },
            focusDeny: true,
            heightAuto: false,
            html: settings?.html,
          })
        );
      }),
      map((payload: SweetAlertResult) => {
        return new WLMDialogResult({ result: payload.isConfirmed });
      })
    );
  }

  showErrorMessage = (error: Error) => this.fireMessage(error.message, 'error');

  /**
   * Public usage is deprecated. Use showTranslatedMessage instead.
   */
  showMessage(
    message: string,
    icon?: 'success' | 'error' | 'warning' | 'info' | 'question',
    customClass: { [key: string]: string } = {}
  ) {
    this.fireMessage(message, icon, customClass);
  }

  private fireMessage(
    message: string,
    icon?: SweetAlertIcon,
    customClass: { [key: string]: string } = {}
  ) {
    const type = icon ?? 'success';
    this.localization.get('common.accept').subscribe((tsText) => {
      Swal.fire({
        title: message,
        heightAuto: false,
        iconHtml: `<i class="swal2-custom-icon swal2-custom-icon-${type}"></i>`,
        confirmButtonText: tsText,
        customClass: {
          container: 'swal2-custom-container',
          title: 'sweet-message-title',
          confirmButton: 'sweet-dialog-buttons',
          icon: 'swal2-custom-icon',
          ...customClass,
        },
      });
    });
  }

  /**
   * Public usage is deprecated. Use showTranslatedMessageInSnackBar instead.
   */
  showSnackMessage(message: string, duration: number = 4000) {
    this.snackBar.open(message, null, { duration, horizontalPosition: 'center' });
  }

  openComponent<T>(
    component: ComponentType<T>,
    inputDialogConfig: MatDialogConfig,
    params?: {
      autoHeight?: boolean;
      avoidCloneConfig?: boolean;
    }
  ): MatDialogRef<T, any> {
    const dialogConfig = this._objectHelperService.clone(inputDialogConfig, true);
    dialogConfig.width = inputDialogConfig?.width ?? EligibilityPopupDimensions.Width;
    if (!params?.autoHeight) {
      dialogConfig.height = inputDialogConfig?.height ?? EligibilityPopupDimensions.Height;
    }

    return this.openComponentDefault<T>(component, dialogConfig);
  }

  openComponentDefault<T>(
    component: ComponentType<T>,
    dialogConfig: MatDialogConfig
  ): MatDialogRef<T, any> {
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    return this._matDialog.open(component, dialogConfig);
  }

  private loadSnackBarMessage(translateKey: string, params?: any) {
    return this.localization.get(translateKey, params).pipe(
      take(1),
      switchMap((message) => {
        if (!this._isLoaded) {
          this._isLoaded = true;
          this.loadToastTitles();
        }

        return this._isAvailable$.asObservable().pipe(
          take(1),
          map(() => message)
        );
      })
    );
  }

  private loadToastTitles() {
    this.localization.get(`${this.T_SCOPE_TOASTS}.titles`).subscribe((titles) => {
      Object.entries(titles).forEach(([key, value]) => {
        this._toastTitles.set(key, value as string);
        this._isAvailable$.next();
      });
    });
  }
}
