import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';

export class FilterContainerIndexedPayload {
  payload: Map<string, BasicFilter>;
  index: number;

  constructor(init: FilterContainerIndexedPayload) {
    Object.assign(this, init);
  }
}
