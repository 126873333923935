import { Injectable, Injector } from '@angular/core';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';

const API_BASE = '/api/ne/selection';
@Injectable()
export class NeConfigurationSelectionService extends GridODataService<INetworkElementDto> {
  constructor(injector: Injector) {
    super(injector, API_BASE);
  }

  protected mapResponse(
    response: PagedResultDto<INetworkElementDto>
  ): PagedResultDto<INetworkElementDto> {
    return response;
  }
}
