import { Injectable, Injector } from '@angular/core';
import { IHierarchyElementDto } from '@common-modules/dependencies/he/hierarchy-element.dto';
import { BaseService } from '@common-modules/shared/base.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { Observable } from 'rxjs';
import { MergedZonesDto } from '../models/merged-zones.dto';

@Injectable({
  providedIn: 'root',
})
export class MergedZonesService extends BaseService {
  protected get url() {
    return `${this.apiUrl}/merged-zones`;
  }

  constructor(injector: Injector, private readonly _objectHelperService: ObjectHelperService) {
    super(injector);
  }

  getMergedZoneElements(hierarchyElementId: string): Observable<IHierarchyElementDto[]> {
    const params = { hierarchyElementId };
    return this.httpCacheClient.get(`${this.url}/elements`, { avoid: true }, params);
  }

  saveMergedZoneConfiguration(
    hierarchyElementParentId: string,
    mergedZoneElements: IHierarchyElementDto[]
  ): Observable<boolean> {
    const endpointUrl = `${this.url}/save/elements/${hierarchyElementParentId}`;

    return this.httpCacheClient.post<boolean>(endpointUrl, mergedZoneElements);
  }

  getFiltersMap(dataBindingFilters: DataBindingFilters): Map<string, any> {
    const iFilters = Array.from(dataBindingFilters.filters.values()).map((x) =>
      x.getFiltersValues()
    );

    const filtersToApply = this._objectHelperService.joinMaps(iFilters);

    return this._objectHelperService.clone(filtersToApply);
  }

  getFieldValuesFromFilter(dataBindingFilters: DataBindingFilters, fieldName: string) {
    const filtersMap = this.getFiltersMap(dataBindingFilters);

    return filtersMap.get(fieldName);
  }

  create(mergedZones: MergedZonesDto): Observable<MergedZonesDto> {
    return this.httpCacheClient.post(this.url, mergedZones);
  }

  update(mergedZones: MergedZonesDto): Observable<MergedZonesDto> {
    return this.httpCacheClient.post(`${this.url}/${mergedZones.hierarchyElementId}`, mergedZones);
  }

  delete(mergedZones: MergedZonesDto): Observable<boolean> {
    return this.httpCacheClient.delete(
      this.url,
      {
        avoid: true,
      },
      null,
      null,
      mergedZones
    );
  }
}
