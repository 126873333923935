import { Component, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { SetValueAction } from '@common-modules/dynamic-layout/state/generic/generic.actions';
import { BaseSelectorDynamicWidgetComponent } from '@common-modules/redux/components/base-selector-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { IAlgorithmDto } from '@common-modules/shared/model/algorithm/algorithm.dto';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { switchMap } from 'rxjs/operators';
import { AlgorithmFiltrableItemDto } from '../../../shared/model/filtrable-items/algorithm-filtrable-item.dto';
import { DataVisualizationStateFields } from '../../models/data-visualization-state-fields';
import { DvAlgorithmsSelectorSettings } from '../../models/dv-algorithms-selector-settings';
import { DataVisualizationService } from '../../services/data-visualization.service';

const COMPONENT_SELECTOR = 'wlm-data-visualization-algorithms-selector-widget';
export const DV_ALGORITHMS_SELECTOR_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-algorithms-selector-widget.component.html',
  styleUrls: ['./data-visualization-algorithms-selector-widget.component.scss'],
  providers: [ReduxStateService],
})
export class DataVisualizationAlgorithmsSelectorWidgetComponent
  extends BaseSelectorDynamicWidgetComponent
  implements OnInit
{
  settings: DvAlgorithmsSelectorSettings;
  algorithms: IAlgorithmDto[];

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    pendingChangesService: PendingChangesManagerService,
    private _dataVisualizationService: DataVisualizationService
  ) {
    super(injector, widgetSettings, pendingChangesService);
  }

  onWidgetInit(): void {
    this.getWidgetSelectorSettings();
  }

  get selectedFieldName(): string {
    return DataVisualizationStateFields.selectedAlgorithms;
  }

  get componentName(): string {
    return 'DataVisualizationAlgorithmsSelectorWidgetComponent';
  }

  onAlgorithmsSelected(algorithmsSelected: AlgorithmFiltrableItemDto[]): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: this.selectedFieldName,
        value: algorithmsSelected,
      })
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: this.selectedFieldName,
        value: null,
      })
    );
  }

  private getWidgetSelectorSettings() {
    this._dataVisualizationService
      .getAlgorithmsByFamily()
      .pipe(
        switchMap((data: Map<string, IAlgorithmDto[]>) => {
          this.algorithms = Array.from(data.values()).reduce((acc, value) => acc.concat(value), []);

          return this._dataVisualizationService.getAlgorithmSelectorSettings(data);
        })
      )
      .subscribe((items) => {
        this.settings = new DvAlgorithmsSelectorSettings({
          algorithms: this.algorithms,
          items: items,
          groups: new Map(),
        });
      });
  }
}
