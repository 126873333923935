import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

export class OpenActivitiesFilter implements IFilter {
  openActivitiesOnly: boolean;
  openActivitiesFieldName: string;

  constructor(openActivitiesOnly: boolean, openActivitiesFieldName: string) {
    this.openActivitiesOnly = openActivitiesOnly;
    this.openActivitiesFieldName = openActivitiesFieldName;
  }
  getFiltersValues(): Map<string, any> {
    return new Map<string, any>([[this.openActivitiesFieldName, this.openActivitiesOnly]]);
  }
  getSelectedFilters(): boolean {
    return this.openActivitiesOnly;
  }
  getFilters(): CompositeFilterDescriptor {
    if (!this.openActivitiesOnly) {
      return null;
    }

    const filterOpenActivities: FilterDescriptor = {
      field: this.openActivitiesFieldName,
      operator: 'eq',
      value: this.openActivitiesOnly,
    };

    return {
      filters: [filterOpenActivities],
      logic: 'and',
    };
  }
}
