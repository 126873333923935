import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { TimeSelectorService } from '@common-modules/wlm-charts/core/services/time-selector.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { PeriodTypesEnum } from '../../models/period-types.enum';
import { TimeSelectorPeriod } from '../../models/time-selector-period';
import { TimeSelectorChartSettings } from '../../models/time-selector-settings';

const COMPONENT_SELECTOR = 'wlm-time-selector';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss'],
})
export class TimeSelectorComponent implements OnInit, AfterViewInit {
  T_SCOPE = `${AppModules.WlmCharts}.${COMPONENT_SELECTOR}`;

  periods: TimeSelectorPeriod[] = [];

  private _isLoading: boolean;
  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() public set isLoading(v: boolean) {
    this._isLoading = v;
  }

  private _selectedPeriod: TimeSelectorPeriod;
  public get selectedPeriod(): TimeSelectorPeriod {
    return this._selectedPeriod;
  }
  public set selectedPeriod(v: TimeSelectorPeriod) {
    this._selectedPeriod = v;

    if (v) {
      this.timeSelectionChanged.emit(v);
    }
  }

  private _timeSelectorSettings: TimeSelectorChartSettings;
  public get timeSelectorSettings(): TimeSelectorChartSettings {
    return this._timeSelectorSettings;
  }
  @Input() public set timeSelectorSettings(v: TimeSelectorChartSettings) {
    this._timeSelectorSettings = v;
    this.addCustomPeriods();
  }

  @Input() addTimeSelectorPeriod$: Observable<TimeSelectorPeriod>;

  @Output() timeSelectionChanged = new EventEmitter<TimeSelectorPeriod>();

  constructor(private _timeSelectorService: TimeSelectorService) {}

  ngAfterViewInit(): void {
    this.selectDefaultPeriod();
  }

  ngOnInit(): void {
    this.addPeriods();
    this.bindAddTimeSelectorPeriod();
  }

  private bindAddTimeSelectorPeriod() {
    this.addTimeSelectorPeriod$?.pipe(untilDestroyed(this)).subscribe((period) => {
      const isDuplicated = this.periods.some(
        (p) =>
          p.startDate.getTime() === period.startDate.getTime() &&
          p.endDate.getTime() === period.endDate.getTime() &&
          p.periodType === period.periodType
      );

      if (isDuplicated) {
        return;
      }

      this.resetCustomPeriods();
      this.periods.push(period);
      this.selectDefaultPeriod();
    });
  }

  private addCustomPeriods() {
    this.resetCustomPeriods();
    if (this.timeSelectorSettings?.customPeriods?.length) {
      this.periods.push(...this.timeSelectorSettings.customPeriods);
    }
  }

  private resetCustomPeriods() {
    this.periods = this.periods.filter(
      (x) =>
        x.periodType !== PeriodTypesEnum.customFromNavigation &&
        x.periodType !== PeriodTypesEnum.customFromDateRange
    );
  }

  private addPeriods() {
    this.addDefaultPeriods();

    // Uncomment to show last two fiscal years
    // this.addFiscalYearPeriods();
  }

  private selectDefaultPeriod() {
    if (this.timeSelectorSettings.defaultSelectedPeriodType) {
      this.selectedPeriod = this.periods.find(
        (x) => x.periodType === this.timeSelectorSettings.defaultSelectedPeriodType
      );
    } else {
      this.selectedPeriod = this.periods.find((x) => x.periodType === PeriodTypesEnum.week);
    }
  }

  private addDefaultPeriods() {
    if (this.timeSelectorSettings?.includeDefaultPeriods) {
      this.periods.push(...this._timeSelectorService.getDefaultTimeSelectorPeriod());
    }
  }
}
