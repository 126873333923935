<wlm-generic-grid
  *ngIf="gridSettings && filters && gridType === 'generic'"
  class="grid selector-grid"
  #grid
  [subscriptionTag]="widgetInstanceKey"
  [gridSettings]="gridSettings"
  [additionalFilters]="filters"
  [gridFiltersForBinding]="emptyFilters"
  [subscriptionTag]="subscriptionTag"
  [showTooltipOnRows]="false"
  (selectedItemChanged)="setSelectedElement($event)"
  [(selectedItems)]="selectedElements"
></wlm-generic-grid>

<wlm-local-grid
  *ngIf="gridSettings && gridType === 'local'"
  class="wtr-fx-flex-grow generic-grid"
  #grid
  [gridSettings]="gridSettings"
  [gridData]="gridData"
  (selectedItemChanged)="setSelectedElement($event)"
  (selectedItemsChange)="setSelectedMany($event)"
  [(selectedItems)]="selectedElements"
  [showSelectedList]="true"
  [showSelectedItems]="true"
></wlm-local-grid>
