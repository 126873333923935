import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import { NotificationAttributeDto } from './notification-attribute.dto';

export class NotificationDetailsDto {
  attributes: NotificationAttributeDto[];
  zoneId?: string;

  // TODO: Check if needed for future features
  fromPage?: string;
  uri?: string;
  fromNavKey?: NavKeys;

  constructor(init?: Partial<NotificationDetailsDto>) {
    Object.assign(this, init);
  }
}
