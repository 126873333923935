import { CampaignStatus } from '@common-modules/dependencies/alc/campaign-status.enum';

export class ALCCampaignStatus {
  value: CampaignStatus;
  class: string;
  labelKey: string;

  constructor(init: Partial<ALCCampaignStatus>) {
    Object.assign(this, init);
  }
}
