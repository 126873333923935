import { Component, Injector, inject } from '@angular/core';
import { ISapCodeDto } from '@common-modules/dependencies/alc/sap-code.dto';
import { GenericCrudSettings } from '@common-modules/generic-crud/generic-crud-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { ValidationHelperService } from '@common-modules/shared/helpers/validation-helper.service';
import { Observable, map } from 'rxjs';
import { SapCodesCrudService } from '../sap-codes-crud.service';
import { SapCodesFormComponent } from '../sap-codes-form/sap-codes-form.component';

const COMPONENT_SELECTOR = 'wlm-sap-codes-crud';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './sap-codes-crud.component.html',
  styleUrls: ['./sap-codes-crud.component.scss'],
})
export class SapCodesCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);
  private readonly _service = inject(SapCodesCrudService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  private readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'SapCodesCrudService',
      grid: {
        gridSettingsName: 'SapCodesCrud',
      },
      create: {
        formComponent: SapCodesFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      update: {
        formComponent: SapCodesFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(false),
      },
      delete: {},
    });
  }

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
    (model: ISapCodeDto): Observable<ISapCodeDto | null> => {
      return this._service.validate(model, isCreate).pipe(
        map((validation) => {
          if (validation.isDuplicatedId) {
            this._validationHelperService.showNotUniqueError(`${this.T_SCOPE}.properties.code-id`);
            return null;
          } else if (validation.isDuplicatedValue) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.code-value`
            );
            return null;
          }
          return model;
        })
      );
    };
}
