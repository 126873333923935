import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DynamicLayoutBuilderService } from '@common-modules/dynamic-layout/layouts/dynamic-layout-builder-service';
import { SimpleDynamicLayoutSettings } from '@common-modules/dynamic-layout/layouts/simple-dynamic-layout-settings';
import { SimpleDynamicLayoutSettingsItem } from '@common-modules/dynamic-layout/layouts/simple-dynamic-layout-settings-item';
import { DynamicLayoutSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutStructure } from '@common-modules/dynamic-layout/models/dynamic-layout-structure';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { GridBtnsEvent } from '@common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from '@common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { LogService } from '@common-modules/shared/wlm-log/log.service';
import { LocalGridComponent } from '@common-modules/wlm-grid/local-grid/local-grid.component';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { Observable, ReplaySubject, Subject, finalize, map } from 'rxjs';
import { IntegrationGisLayersPopupComponent } from './integration-gis-layers-popup/integration-gis-layers-popup.component';
import { IntegrationGisLayersService } from './integration-gis-layers.service';
import { INTEGRATION_GIS_LAYER_GRID_WIDGET_INSTANCE } from './widgets/integration-gis-layers-grid-widget/integration-gis-layers-grid-widget.component';
import { INTEGRATION_GIS_LAYER_MAP_WIDGET_INSTANCE } from './widgets/integration-gis-layers-map-widget/integration-gis-layers-map-widget.component';
import { INTEGRATION_GIS_LAYER_MAPPING_WIDGET_INSTANCE } from './widgets/integration-gis-layers-mapping-widget/integration-gis-layers-mapping-widget.component';

const COMPONENT_SELECTOR = 'wlm-integration-gis-layers';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './integration-gis-layers.component.html',
  styleUrls: ['./integration-gis-layers.component.scss'],
})
export class IntegrationGisLayersComponent {
  @ViewChild(LocalGridComponent) grid: LocalGridComponent;

  @Output() entitiesChanged = new EventEmitter<any[]>();

  readonly clearAll$ = new Subject<void>();
  readonly isMappingsFormValid$: Observable<boolean>;

  readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;
  private readonly _dialogService = inject(DialogService);
  private readonly _log = inject(LogService);
  private readonly _spinnerService = inject(SpinnerService);
  private readonly _dynamicLayoutBuilderService = inject(DynamicLayoutBuilderService);
  private readonly _integrationGisLayersService = inject(IntegrationGisLayersService);

  private _settingsReady$ = new ReplaySubject<void>(1);
  private _gridReady$ = new ReplaySubject<void>(1);

  private setLoading: (loading: boolean) => void;

  private readonly _simpleSettings: SimpleDynamicLayoutSettings = {
    layoutKey: 'GisLayers',
    layoutArea: 'Integration',
    scopeInstanceKeys: {
      [StateAreas.Generic]: 'shared',
    },
  };

  private readonly _gridWidget: SimpleDynamicLayoutSettingsItem = {
    widgetName: 'IntegrationGisLayersGridWidgetComponent',
    titleKey: `${this.T_SCOPE}.widgets.grid`,
    id: INTEGRATION_GIS_LAYER_GRID_WIDGET_INSTANCE,
  };

  private readonly _mapWidget: SimpleDynamicLayoutSettingsItem = {
    widgetName: 'IntegrationGisLayersMapWidgetComponent',
    titleKey: `${this.T_SCOPE}.widgets.map`,
    id: INTEGRATION_GIS_LAYER_MAP_WIDGET_INSTANCE,
  };

  private readonly _mappingWidget: SimpleDynamicLayoutSettingsItem = {
    widgetName: 'IntegrationGisLayersMappingWidgetComponent',
    titleKey: `${this.T_SCOPE}.widgets.mapping`,
    id: INTEGRATION_GIS_LAYER_MAPPING_WIDGET_INSTANCE,
  };

  private readonly _defaultWidgets = [this._gridWidget, this._mapWidget, this._mappingWidget];
  private readonly _defaultStructure: DynamicLayoutStructure[] = [];
  dynamicLayoutSettings: DynamicLayoutSettings;

  constructor() {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
    this.isMappingsFormValid$ = this._integrationGisLayersService.isMappingsFormValid$;
  }

  ngOnInit(): void {
    this._settingsReady$.next();
    this.initialLoad();
  }

  onGridReady(): void {
    this._gridReady$.next();
    this._gridReady$.complete();
  }

  onOpenUpload(event): void {
    this.onOpenPopup();
  }

  private initialLoad() {
    this.buildDynamicLayoutSettings().subscribe((settings) => {
      this.dynamicLayoutSettings = settings;
    });
  }

  private buildDynamicLayoutSettings(): Observable<DynamicLayoutSettings> {
    const simpleSettings = globalUtilsHelper.clone(this._simpleSettings, true);
    simpleSettings.items = this._defaultWidgets;

    return this._dynamicLayoutBuilderService.build(simpleSettings).pipe(
      map((dynamicLayoutSettings) => {
        dynamicLayoutSettings.showResetIcon = true;
        dynamicLayoutSettings.defaultStructure = this._defaultStructure;
        return dynamicLayoutSettings;
      })
    );
  }

  private onOpenPopup(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    const dialogRef = this._dialogService.openComponentDefault(
      IntegrationGisLayersPopupComponent,
      dialogConfig
    );
  }

  discardChanges(): void {
    this._integrationGisLayersService.resetAll();
  }

  onClickGridBtns(events: GridBtnsEvent): void {
    switch (events.btn) {
      case GridBtnsOptions.ClearGridFilters:
        this.onClearGridFilters();
        return;
    }

    this._log.error({ msg: 'The clicked grid button does not have a handler yet' });
  }

  onClearGridFilters(): void {
    this.grid.clearFilters();
  }

  onSendRequest(): void {
    this.wrapInSpinner(this._integrationGisLayersService.sendRequest()).subscribe({
      next: () => this.showSuccess(),
      error: this._dialogService.showErrorMessage,
    });
  }

  private wrapInSpinner(query$: Observable<any>): Observable<any> {
    this.setLoading(true);
    return query$.pipe(finalize(() => this.setLoading(false)));
  }

  private showSuccess(): void {
    this._dialogService.showTranslatedMessageInSnackBar(
      new WlmDialogSettings({
        translateKey: 'common.messages.success',
        icon: 'success',
      })
    );
  }
}
