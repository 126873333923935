import { GenericSelector } from '@common-modules/redux/models/generic-selector';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { AlgorithmsConfigSelectorTypes } from './algorithms-config.selector-types';

export abstract class AlgorithmConfigSelector extends GenericSelector {
  area = StateAreas.Algorithms;
}

export class AlgorithmsFormDefinitionsSelector extends AlgorithmConfigSelector {
  type = AlgorithmsConfigSelectorTypes.FormDefinitions;
}

export class AlgorithmsFormUIValuesSelector extends AlgorithmConfigSelector {
  type = AlgorithmsConfigSelectorTypes.UIValues;
}
