import { Injectable, Injector } from '@angular/core';
import { GenericCrudService } from '@common-modules/generic-crud/generic-crud.service';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable, map } from 'rxjs';
import { IBillingClassDto } from '../../shared/model/customer/billing-class.dto';
import { IBillingClassValidationDto } from './billing-class-validation.dto';

@Injectable()
export class BillingClassesCrudService
  extends BaseService
  implements GenericCrudService<IBillingClassDto, number>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/billing-classes`;
  }

  getAll(): Observable<IBillingClassDto[]> {
    return this.httpCacheClient.get<IBillingClassDto[]>(this._entityUrl);
  }

  create(entity: IBillingClassDto): Observable<IBillingClassDto> {
    return this.httpCacheClient.post<IBillingClassDto>(this._entityUrl, entity);
  }

  update(entity: IBillingClassDto): Observable<IBillingClassDto> {
    return this.httpCacheClient.put<IBillingClassDto>(this._entityUrl, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  getId(entity: IBillingClassDto): number {
    return entity.billingClassId;
  }

  validate(entity: IBillingClassDto, isCreate?: boolean): Observable<IBillingClassValidationDto> {
    return this.httpCacheClient.post<IBillingClassValidationDto>(
      `${this._entityUrl}/validate?isCreate=${isCreate ? 'true' : 'false'}`,
      entity
    );
  }
}
