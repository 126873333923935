import { Injectable } from '@angular/core';
import { AlarmDto } from '@common-modules/dependencies/alarms/alarm.dto';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { DateFormats } from '@common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { ChartTooltipNativeParams } from '@common-modules/wlm-charts/core/models/chart-tooltip-native-params';
import { GCartesianChartSerieTooltip } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-cartesian-chart-serie-tooltip';
import { GCartesianChartSerie } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-cartesian-chart-series';
import { GChartTooltip } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-tooltip';
import { GenericChartUnselectedSeries } from '@common-modules/wlm-charts/core/models/generic-chart-unselected-series';
import { GenericChartService } from '@common-modules/wlm-charts/core/services/generic-chart.service';
import { IAlarmsChartInstanceScope } from './alarm-chart-instance-scope';

export class AlarmChartTooltipLabels {
  thresholdLabel: string;
  alarmLabel: string;
  thresholdTitle: string;
  startDateLabel: string;
  endDateLabel: string;
}

export class AlarmChartTooltipSettings {
  alarms: AlarmDto[];
  severityColors: { [key: string]: string };
  mainSeriesNames: string[];
  tooltipLabels: AlarmChartTooltipLabels;
  ackAlarmBackgroundColor: string;
  getScope: () => IAlarmsChartInstanceScope;
  getSerieName: (serie: any) => string;
  getHiddenSerieNames: () => GenericChartUnselectedSeries;
}

@Injectable()
export class AlarmChartTooltipService {
  constructor(
    private readonly _genericChartService: GenericChartService,
    private readonly _dateHelper: DateHelperService,
    private readonly _localization: LocalizationHelperService
  ) {}

  buildTooltip(settings: AlarmChartTooltipSettings): GChartTooltip {
    const defaultTooltip = this._genericChartService.buildDefaultTooltip();

    const tooltip = new GChartTooltip({
      ...defaultTooltip,
      formatter: (params) => this.formatTooltip(params, settings),
    });

    return tooltip;
  }

  private formatTooltip(params: ChartTooltipNativeParams[], settings: AlarmChartTooltipSettings) {
    if (!params?.length) {
      return;
    }

    const hiddenSerieNamesData = settings.getHiddenSerieNames();
    const hiddenSeries = hiddenSerieNamesData?.series ?? [];

    const alarmsFiltered = settings.alarms.filter((alarm) => {
      if (alarm.signalId) {
        return !hiddenSeries.find((serie) => serie.additionalParams.signalId === alarm.signalId);
      } else if (alarm.algorithmShortName) {
        return !hiddenSeries.find(
          (serie) => serie.additionalParams.algorithmShortName === alarm.algorithmShortName
        );
      }

      return true;
    });

    // These will include thresholds when they are loaded.
    const series = settings.getScope().allChartSeriesUnprocessed;

    // If the date needs to be shown in UTC, it will already be converted when assigning the series.
    // The dates will have the timezone set to local, but the date itself (day, hours) has been translated to UTC.
    const currentDate = this._dateHelper.toApiFormat(params[0].value[0]);
    let tooltipText = '';

    // Separate params from main and threshold series.
    const mainSerieParams: ChartTooltipNativeParams[] = [];
    const thresholdSerieParams: ChartTooltipNativeParams[] = [];
    params.forEach((x) => {
      const isMainSerie = settings.mainSeriesNames.find((name) => name === x.seriesName);
      isMainSerie ? mainSerieParams.push(x) : thresholdSerieParams.push(x);
    });

    // Render main series tooltips.

    tooltipText += this.addMainSeriesToTooltip(
      mainSerieParams,
      currentDate,
      series,
      params,
      settings.mainSeriesNames,
      settings.getSerieName,
      hiddenSerieNamesData?.names ?? []
    );

    // Render threshold series tooltips.

    tooltipText += this.addThresholdSeriesToTooltip(
      thresholdSerieParams,
      currentDate,
      series,
      settings.tooltipLabels,
      settings.mainSeriesNames,
      settings.getSerieName
    );

    // Render Alarms.

    tooltipText += this.addAlarmsToTooltip(
      alarmsFiltered,
      currentDate,
      settings.severityColors,
      settings.tooltipLabels,
      settings.ackAlarmBackgroundColor
    );

    return tooltipText;
  }

  private addMainSeriesToTooltip(
    mainSerieParams: ChartTooltipNativeParams[],
    currentDate: string,
    series: GCartesianChartSerie[],
    tooltipSeriesParams: ChartTooltipNativeParams[],
    mainSeriesNames: string[],
    getSerieName: (serie: any) => string,
    hiddenSerieNames: string[]
  ): string {
    const tooltipsByDate = new Map<string, GCartesianChartSerieTooltip[]>();

    const mainSerieTooltips = mainSerieParams.map((serieParams) =>
      this.buildTooltipFromSerie(serieParams.seriesName, serieParams, series)
    );
    // Add them to the main tooltips map.
    if (mainSerieTooltips.length) {
      tooltipsByDate.set(currentDate, mainSerieTooltips);
    }

    // Build daily series tooltips.
    // Avoid repeating series with the ones that we already going to show (main & threshold series).
    // Only consider series that are not already shown in the tooltip.
    const seriesNamesInTooltip = mainSerieParams.map((x) => x.seriesName);
    const seriesNotInTooltip = series.filter((serie) => {
      const serieName = getSerieName(serie);

      return (
        this.isMainSerie(serieName, mainSeriesNames) &&
        !hiddenSerieNames.find((hiddenSerieName) => hiddenSerieName === serieName) &&
        !seriesNamesInTooltip.find((serieName) => serieName === getSerieName(serie))
      );
    });

    // Update tooltipsByDate with the any missing daily series.
    this._genericChartService.buildClosestPointDailySeries(
      seriesNotInTooltip,
      this._dateHelper.fromApiFormat(currentDate),
      tooltipsByDate,
      null,
      tooltipSeriesParams
    );

    // Both main and daily are in the map. The method already inserts the date as the title.
    const tooltipText = this._genericChartService.parseTooltipsByDate(tooltipsByDate);
    return tooltipText;
  }

  private addThresholdSeriesToTooltip(
    thresholdInTooltipParams: ChartTooltipNativeParams[],
    currentDate: string,
    series: GCartesianChartSerie[],
    tooltipLabels: AlarmChartTooltipLabels,
    mainSeriesNames: string[],
    getSerieName: (serie) => string
  ): string {
    const tooltipsByDate = new Map<string, GCartesianChartSerieTooltip[]>();

    // thresholdInTooltipParams contains which theshold series are provided from native tooltip.
    // We also calculate which ones are not but must be always shown.
    const seriesNamesInTooltip = thresholdInTooltipParams.map((x) => x.seriesName);
    const thresholdsNotInTooltip = series.filter((serie) => {
      const serieName = getSerieName(serie);
      return (
        this.isThresholdSerie(serieName, mainSeriesNames) &&
        serie.showAlwaysInTooltip && // Differentiate thresholds that do not need to always show.
        !seriesNamesInTooltip.find((serieNameInTooltip) => serieNameInTooltip === serieName)
      );
    });

    const thresholdTooltips = thresholdInTooltipParams.map((thresholdParams) => {
      const currentSerie = series[thresholdParams.seriesIndex];
      const serieName = this.getThresholdSerieLabel(currentSerie);
      return this.buildTooltipFromSerie(serieName, thresholdParams, series);
    });

    if (thresholdTooltips.length) {
      tooltipsByDate.set(currentDate, thresholdTooltips);
    }

    this._genericChartService.buildClosestPointDailySeries(
      thresholdsNotInTooltip,
      this._dateHelper.fromApiFormat(currentDate),
      tooltipsByDate,
      null,
      thresholdInTooltipParams,
      this.getThresholdSerieLabel
    );

    let tooltipText = '';

    if (tooltipsByDate.size !== 0) {
      tooltipText += this._genericChartService.tooltipTemplateTitle(tooltipLabels.thresholdTitle);
      tooltipText += this._genericChartService.parseTooltipsByDate(tooltipsByDate);
    }

    return tooltipText;
  }

  private addAlarmsToTooltip(
    alarmsFiltered: AlarmDto[],
    currentDate: string,
    severityColors: {
      [key: string]: string;
    },
    tooltipLabels: AlarmChartTooltipLabels,
    ackAlarmBackgroundColor: string
  ): string {
    let tooltipText = '';
    let associatedAlarms: AlarmDto[] = [];
    associatedAlarms = alarmsFiltered;

    let currentAlarms: AlarmDto[] = [];
    associatedAlarms.forEach((alarm) => {
      // An alarm is included if it fits inside the date range and belongs to the selected signals.
      const isIncluded =
        alarm.alarmStartDateTime <= currentDate &&
        (!alarm.alarmEndDateTime || currentDate <= alarm.alarmEndDateTime);

      if (isIncluded) {
        // If must include, check it a similar alarm has not been included yet.
        const sameAlarm = currentAlarms.find((selected) =>
          this.areSameAlarmsTooltip(selected, alarm)
        );
        if (!sameAlarm) {
          currentAlarms.push(alarm);
        }
      }
    });

    if (currentAlarms.length) {
      // Check which are the higher criticality alarms.
      const maxPriority = Math.max(
        ...currentAlarms.map((alarm) => (alarm.isAcknowledge ? 0 : alarm.alarmSeverity))
      );
      // Only render the alarms that have the higher criticality (or the Acks, if all are Acks).
      currentAlarms = currentAlarms.filter((alarm) =>
        maxPriority === 0
          ? alarm.isAcknowledge
          : !alarm.isAcknowledge && maxPriority === alarm.alarmSeverity
      );
    }

    if (currentAlarms.length) {
      const { tooltipTemplateTitle, tooltipTemplateRow, tooltipTemplateRowDetail } =
        this._genericChartService;

      tooltipText += tooltipTemplateTitle(tooltipLabels.alarmLabel);

      currentAlarms.forEach((alarm) => {
        const alarmColor = alarm.isAcknowledge
          ? ackAlarmBackgroundColor
          : severityColors[alarm.alarmSeverity];
        tooltipText += tooltipTemplateRow(alarm.alarmTypeDescription, null, null, alarmColor);

        tooltipText += tooltipTemplateRowDetail(
          tooltipLabels.startDateLabel,
          this.formatDate(this._dateHelper.fromApiFormat(alarm.alarmStartDateTime))
        );
        if (alarm.alarmEndDateTime) {
          tooltipText += tooltipTemplateRowDetail(
            tooltipLabels.endDateLabel,
            this.formatDate(this._dateHelper.fromApiFormat(alarm.alarmEndDateTime))
          );
        }
      });
    }

    return tooltipText;
  }

  /**
   * Render a serie row in the tooltip, whether it is main or not.
   */
  private buildTooltipFromSerie(
    serieName: string,
    serieParams: ChartTooltipNativeParams,
    series: GCartesianChartSerie[]
  ): GCartesianChartSerieTooltip {
    const serie = series[serieParams.seriesIndex];
    const dataPointValue = serieParams.value[1];
    const unitName = serie.yAxisName;
    const color = serie.itemStyle?.color ?? serie.lineStyle?.color ?? serieParams.color;

    const tooltip = new GCartesianChartSerieTooltip({
      name: serieName,
      color,
      dataPointValue,
      unit: unitName,
    });
    return tooltip;
  }

  private getThresholdSerieLabel(serie): string {
    const additional = serie.additionalParams;
    const serieName = additional ? additional['envelopeDescription'] : serie.name;
    return serieName;
  }

  /**
   * If two alarms are the same by following this criteria, only one will be shown in the tooltip.
   */
  private areSameAlarmsTooltip(a1: AlarmDto, a2: AlarmDto): boolean {
    return (
      a1.isAcknowledge === a2.isAcknowledge &&
      a1.alarmSeverity === a2.alarmSeverity &&
      a1.alarmTypeDescription === a2.alarmTypeDescription
    );
  }

  private isMainSerie = (serieName: string, mainSeriesNames: string[]) =>
    mainSeriesNames.find((name) => name === serieName);

  private isThresholdSerie = (serieName: string, mainSeriesNames: string[]) =>
    !this.isMainSerie(serieName, mainSeriesNames);

  private formatDate = (date: Date) => this._localization.formatDate(date, DateFormats.DateTime);
}
