import { NgModule } from '@angular/core';
import {
  GenericChartsModule,
  GenericChartsModuleSettings,
} from '@common-modules/wlm-charts/generic-charts.module';
import { WlmCustomizableChartService } from '../shared/charts/wlm-customizable-chart.service';

@NgModule({
  declarations: [],
  imports: [
    GenericChartsModule.forFeature(
      new GenericChartsModuleSettings({ baseCustomizableChartService: WlmCustomizableChartService })
    ),
  ],
  exports: [GenericChartsModule],
  providers: [WlmCustomizableChartService],
})
export class WlmChartsModule {}
