import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { WLMDialogResult } from '@common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { GroupDto } from '@common-modules/shared/model/roles/group.dto';
import { GroupUsersCmdDto } from '@common-modules/shared/model/roles/groupUsersCmd.dto';
import { UserDto } from '@common-modules/shared/model/roles/user.dto';
import { UsersGridODataService } from '@common-modules/shared/services/users/users.service';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { GroupUsersPopupComponent } from '../group-users-popup/group-users-popup.component';

const COMPONENT_SELECTOR = 'wlm-groups-users';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './groups-users.component.html',
  styleUrls: ['./groups-users.component.scss'],
})
export class GroupsUsersComponent extends BaseWidgetComponent implements OnInit {
  @ViewChild(GenericGridComponent) public usersGrid: GenericGridComponent;
  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;

  userGridSettingsName = 'GroupUsers';

  gridFiltersForBinding: DataBindingFilters;
  group: GroupDto;
  additionalFilters: Map<string, string>;
  selectedUser: UserDto;
  removeSelection$ = new ReplaySubject<void>();

  private _selectedUsers: UserDto[];
  public get selectedUsers(): UserDto[] {
    return this._selectedUsers;
  }
  public set selectedUsers(v: UserDto[]) {
    this._selectedUsers = v;
  }

  private _userGridSetting: GridSetting;
  public get userGridSetting(): GridSetting {
    return this._userGridSetting;
  }
  public set userGridSetting(v: GridSetting) {
    this._userGridSetting = v;
  }

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    readonly gridSettingsService: GridSettingsService,
    private readonly _userService: UsersGridODataService,
    private readonly _usersPopup: MatDialog,
    private readonly _authService: AuthenticationService,
    private readonly _dialogService: DialogService
  ) {
    super(injector, widgetSettings);
    gridSettingsService.getGridSettingsByName(this.userGridSettingsName).subscribe({
      next: (gridSettings) => {
        this.userGridSetting = gridSettings;
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.removeSelection$.next();
    this.group = parameters.parameters.get(TabDetailParameterName.Group);
  }
  init(): void {
    if (this.group?.groupId) {
      const additionalFilters = new Map<string, string>();
      additionalFilters.set('exclude', 'false');
      additionalFilters.set('groupId', this.group?.groupId.toString());
      this.additionalFilters = additionalFilters;

      const dataBinding = new DataBindingFilters();
      this.gridFiltersForBinding = null;
      this.gridFiltersForBinding = dataBinding;
      this.usersGrid?.reloadGrid();
    }
  }

  getSelectedUser(user: UserDto): void {
    this.selectedUser = user;
  }

  addUserToGroup(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.height = '700px';
    dialogConfig.data = {
      groupId: this.group.groupId,
      groupName: this.group.groupName,
    };
    const popup = this._usersPopup.open(GroupUsersPopupComponent, dialogConfig);

    popup
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.init();
        }
      });
  }

  removeUser(): void {
    const groupUsersToRemoveDto = new GroupUsersCmdDto(
      [this.group.groupId],
      [],
      this._authService.userCode
    );

    groupUsersToRemoveDto.users.push(...this.selectedUsers.map((x) => x.userCode));
    const isPlural = this.selectedUsers.length > 1;

    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages${
        isPlural ? '.remove-users-warning' : '.remove-user-warning'
      }`,
    });

    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.usersGrid.grid.loading = true;
          this._userService.removeUsersFromGroups(groupUsersToRemoveDto).subscribe({
            next: (response) => {
              this.usersGrid.grid.loading = false;
              if (response.errors.length) {
                this._dialogService.showTranslatedMessage(
                  new WlmDialogSettings({
                    translateKey: `${this.T_SCOPE}.messages${
                      isPlural ? '.remove-users-error' : '.remove-user-error'
                    }`,
                    icon: 'error',
                  })
                );
              } else {
                this.removeSelection$.next();
                this.init();
                this._dialogService.showTranslatedMessageInSnackBar(
                  new WlmDialogSettings({
                    translateKey: `${this.T_SCOPE}.messages${
                      isPlural ? '.remove-users-success' : '.remove-user-success'
                    }`,
                  })
                );
              }
            },
          });
        }
      });
  }

  get componentName() {
    return 'GroupsUsersComponent';
  }
}
