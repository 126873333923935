import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { FilterAdditionalParam } from '@common-modules/dependencies/wlm-filters/filter-additional-param';
import { FilterGroupFieldSettings } from '@common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BaseCalculateResizableChildComponent } from '@common-modules/shared/core/responsive/base-calculate-resizable-child.component';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { EstimatedEditedEnum } from '@common-modules/shared/model/algorithm/estimated-edited.enum';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { IElementSize } from '@common-modules/shared/model/element-size';
import { MVChartPointDto } from '@common-modules/shared/model/mv/mv-chart-point.dto';
import { MVQueryDto } from '@common-modules/shared/model/mv/mv-query.dto';
import { PendingChanges } from '@common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesChecker } from '@common-modules/shared/pending-changes/models/pending-changes-checker';
import { IPendingChangesEmitter } from '@common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { EditableChartComponent } from '@common-modules/wlm-charts/core/editable-chart/editable-chart.component';
import { ChartType } from '@common-modules/wlm-charts/core/models/chart-type.enum';
import { EditableChartDataParameters } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-data-parameters';
import { EditableChartDateRange } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-date-range';
import { EditableChartPointDto } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-point.dto';
import { EditableChartSelection } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-selection';
import { EditableChartSettings } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-settings';
import { EditableChartValueRange } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-value-range';
import { BrushMode } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-brush';
import { GChartBrushArea } from '@common-modules/wlm-charts/core/models/generic-events/g-chart-brush-end-event';
import { SpinnerWrapperSettings } from '@common-modules/wlm-spinner/models/spinner-wrapper-settings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscriber, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataValidationChartService } from '../services/data-validation-chart.service';

const COMPONENT_SELECTOR = 'wlm-data-validation-chart';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-validation-chart.component.html',
  styleUrls: ['./data-validation-chart.component.scss'],
})
export class DataValidationChartComponent
  extends BaseCalculateResizableChildComponent
  implements OnInit, IPendingChangesEmitter, IPendingChangesChecker
{
  @ViewChild(EditableChartComponent) editableChart: EditableChartComponent;

  @Input() pageId: string;

  private _signalId;
  public get signalId() {
    return this._signalId;
  }
  @Input()
  public set signalId(value) {
    this._signalId = value;
    if (value) {
      const dateRange = this._filters?.filters.get('dateRange');
      this.setChartSettings(dateRange);

      this.selectedChartPoint = null;
      this.resetForm();
    }

    this.isHorizontalBrushModeOn = this.isVerticalBrushModeOn = false;
  }

  //Listen the range changes from external components
  private _valueRanges: EditableChartValueRange[];
  get valueRanges(): EditableChartValueRange[] {
    return this._valueRanges;
  }
  @Input() set valueRanges(value: EditableChartValueRange[]) {
    this._valueRanges = value;
    this.updateRangeArea(value, BrushMode.Vertical);
  }

  private _dateRanges: EditableChartDateRange[];
  get dateRanges(): EditableChartDateRange[] {
    return this._dateRanges;
  }
  @Input() set dateRanges(value: EditableChartDateRange[]) {
    this._dateRanges = value;
    this.updateRangeArea(value, BrushMode.Horizontal);
  }

  private _validationToApply: EditableChartSelection;
  get validationToApply(): EditableChartSelection {
    return this._validationToApply;
  }
  @Input() set validationToApply(value: EditableChartSelection) {
    this._validationToApply = value;
    if (value) {
      this._applyValidationFunction$.next({
        ...value,
        element: this.signalId,
      });
    }
  }

  @Output() chartFiltersChange = new EventEmitter<DataBindingFilters>();
  @Output() valueRangesChange = new EventEmitter<EditableChartValueRange[]>();
  @Output() dateRangesChange = new EventEmitter<EditableChartDateRange[]>();
  @Output() toggleValueRangesCart = new EventEmitter<boolean>();
  @Output() toggleDateRangesCart = new EventEmitter<boolean>();

  private _filters: DataBindingFilters;

  private _activateBrush$ = new Subject<BrushMode>();
  readonly activateBrush$ = this._activateBrush$.asObservable();

  private _updateRange$ = new Subject<GChartBrushArea[]>();
  readonly updateRange$ = this._updateRange$.asObservable();

  private _updatePoints$ = new Subject<EditableChartPointDto[]>();
  readonly updatePoints$ = this._updatePoints$.asObservable();

  private _discardAll$ = new Subject<void>();
  readonly discardAll$ = this._discardAll$.asObservable();

  private _saveAll$ = new Subject<number>();
  readonly saveAll$ = this._saveAll$.asObservable();

  private _applyValidationFunction$ = new Subject<EditableChartSelection>();
  readonly applyValidationFunction$ = this._applyValidationFunction$.asObservable();

  private _hasChanges: boolean = false;
  public get hasChanges(): boolean {
    return this._hasChanges;
  }
  public set hasChanges(value: boolean) {
    this._hasChanges = value;
    this.setPendingChanges(this.pageId, this.getPendingChanges());
  }

  T_SCOPE = `${AppModules.DataValidation}.${COMPONENT_SELECTOR}`;

  saveSubscriber$: Subscriber<boolean>;

  isLoading = false;
  spinnerWrapperSettings: SpinnerWrapperSettings = {
    isOpaque: true,
  };

  filterSettings: FilterGroupSettings;
  chartSettings: EditableChartSettings;

  mainChartLoading = false;
  offsetStartDate = 1;
  maxDaysFilterRange = 93;

  canLoad: boolean;
  autoLoad: boolean;
  persistFilters$ = new Subject<void>();
  clearAll$ = new Subject<void>();

  form: UntypedFormGroup;
  selectedChartPoint: MVChartPointDto;
  selectedChartPointDate: Date;
  chartPointFieldName = 'chartPoint';
  chartPointLabel$: Observable<string>;

  selectedBrushMode: BrushMode | null;
  isVerticalBrushModeOn: boolean;
  isHorizontalBrushModeOn: boolean;

  dimensionToCalculate: 'height' | 'width' = 'height';

  // Fields
  startDateFieldName = 'startDate';
  endDateFieldName = 'endDate';

  get componentName(): string {
    return 'DataValidationChartComponent';
  }

  get currentChartDimension(): number {
    return this.editableChart ? this.editableChart?.getCurrentSerie()?.yAxisWLMDimensionTypeId : 0;
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _dateHelperService: DateHelperService,
    private _dataValidationChartService: DataValidationChartService,
    private _dialogService: DialogService,
    private _translateService: TranslateService,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setFilterSettings();
    this.createPointForm();
  }

  setMainChartLoading(isLoading: boolean) {
    this.mainChartLoading = isLoading;
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    this.canLoad = filtersParameters.exportableFilter !== undefined;
    this._filters = filtersParameters;
  }

  setFiltersDetailsParameters(value: TabDetailPanelParameters) {
    if (this.autoLoad && this.canLoad) {
      this.applyFilters();
      this.autoLoad = false;
    }
  }

  applyFilters() {
    if (this.canLoad) {
      this.checkPendingChanges(this.pageId).subscribe((_) => {
        const dateRange = this._filters.filters.get('dateRange');
        this.setChartSettings(dateRange);

        this.chartFiltersChange.emit(this._filters);
      });
    }
  }

  onCheckAutoload(): void {
    this.applyFilters();
  }

  onApplyForm() {
    this.getUpdatedChartPoint()
      .pipe(untilDestroyed(this))
      .subscribe((point) => {
        this.form.get(this.chartPointFieldName).markAsPristine();
        this._updatePoints$.next([point]);
      });
  }

  onChartLoading(isLoading): void {
    this.isLoading = isLoading;
  }

  onSelectPoint(point: MVChartPointDto) {
    this.selectedChartPoint = point;
    if (!point) {
      this.resetForm();
      return;
    }

    this.selectedChartPointDate = this._dateHelperService.fromApiFormat(
      this.selectedChartPoint.measureTimestamp
    );

    this.updateForm();
  }

  //Informs the range selected in the chart to the external components
  onSelectRange(rangeInfo: GChartBrushArea[]): void {
    if (rangeInfo.length) {
      const type = rangeInfo[0].brushType;
      const range = rangeInfo.map((m) => m.range);
      const isValueRangeSelection = type === BrushMode.Vertical;

      if (isValueRangeSelection) {
        this.valueRangesChange.emit(this.getValueSelection(range));
      } else {
        this.dateRangesChange.emit(this.getDateSelection(range));
      }
    }
  }

  getDateSelection(range: [number, number][]): EditableChartDateRange[] {
    const formatedRanges = [];
    range?.forEach((item) => {
      formatedRanges.push(
        new EditableChartDateRange({
          startDate: this._dateHelperService.resetSecondsAndMilliseconds(new Date(item[0])),
          endDate: this._dateHelperService.resetSecondsAndMilliseconds(new Date(item[1])),
        })
      );
    });

    return formatedRanges;
  }

  getValueSelection(range: [number, number][]): EditableChartValueRange[] {
    const formatedRanges = [];
    range?.forEach((item) => {
      formatedRanges.push(new EditableChartValueRange({ startValue: item[0], endValue: item[1] }));
    });

    return formatedRanges;
  }

  onHasChanges(hasChanges: boolean): void {
    this.hasChanges = hasChanges;
  }

  activeVerticalBrush(): void {
    this.activeBrushMode(BrushMode.Vertical);
    this.toggleValueRangesCart.emit(this.isVerticalBrushModeOn);
  }

  activeHorizontalBrush(): void {
    this.activeBrushMode(BrushMode.Horizontal);
    this.toggleDateRangesCart.emit(this.isHorizontalBrushModeOn);
  }

  onSave() {
    this.save().subscribe(() => {});
  }

  save(): Observable<boolean> {
    return new Observable<boolean>((o) => {
      this.saveSubscriber$ = o;

      this._saveAll$.next(this.signalId);
    });
  }

  discardChanges(): void {
    this.selectedBrushMode = null;
    this.isHorizontalBrushModeOn = this.isVerticalBrushModeOn = false;

    this._discardAll$.next();
  }

  onSaveCompleted(saveResult: boolean) {
    if (saveResult) {
      this._dialogService.showTranslatedMessageInSnackBar(
        new WlmDialogSettings({
          translateKey: `${this.T_SCOPE}.messages.save-success`,
          icon: 'success',
        })
      );
    }

    if (this.saveSubscriber$) {
      this.saveSubscriber$.next(true);
      this.saveSubscriber$.complete();
    }
  }

  onChartLoaded(loaded: boolean) {
    if (loaded) {
      this.loadUomLabel();
    }
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  checkPendingChanges(key: string): Observable<unknown> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(untilDestroyed(this));
  }

  showDataPoints() {
    this.editableChart?.showDataPoints();
  }

  private updateRangeArea(ranges: any[], areaType: BrushMode.Vertical | BrushMode.Horizontal) {
    if (areaType === BrushMode.Vertical) {
      this.convertFromValueRanges(ranges, areaType);
    } else {
      this.convertFromDateRanges(ranges, areaType);
    }
  }

  private convertFromDateRanges(
    ranges: EditableChartDateRange[],
    areaType: BrushMode.Vertical | BrushMode.Horizontal
  ) {
    const rangeTransformed = this.getDateRangeTransformed(ranges, areaType);

    this._updateRange$.next(rangeTransformed);
  }

  private convertFromValueRanges(
    ranges: EditableChartValueRange[],
    areaType: BrushMode.Vertical | BrushMode.Horizontal
  ) {
    const rangeTransformed = this.getValueRangeTransformed(ranges, areaType);

    this._updateRange$.next(rangeTransformed);
  }

  private getValueRangeTransformed(
    ranges: EditableChartValueRange[],
    brushType: string
  ): GChartBrushArea[] {
    const rangeTransformed = [];
    ranges?.forEach((range) => {
      rangeTransformed.push({ brushType, range: [range.startValue, range.endValue] });
    });

    return rangeTransformed;
  }

  private getDateRangeTransformed(
    ranges: EditableChartDateRange[],
    brushType: string
  ): GChartBrushArea[] {
    const rangeTransformed = [];
    ranges?.forEach((range) => {
      rangeTransformed.push({
        brushType,
        range: [range.startDate.getTime(), range.endDate.getTime()],
      });
    });

    return rangeTransformed;
  }

  private setFilterSettings() {
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      startDate: new FilterGroupFieldSettings({
        fieldName: this.startDateFieldName,
      }),
      endDate: new FilterGroupFieldSettings({
        fieldName: this.endDateFieldName,
      }),
    };

    const additionalParams = {
      offsetStartDate: new FilterAdditionalParam({ value: this.offsetStartDate }),
      inclusiveEndDateParam: new FilterAdditionalParam({ value: false }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      additionalParams,
      navigationParams: null,
      persistencyArea: COMPONENT_SELECTOR,
      avoidPersistency: false,
    });
  }

  private setChartSettings(dateFilter?: IFilter) {
    if (!this._signalId || !dateFilter) {
      return;
    }
    const dates = dateFilter.getFiltersValues();

    const startDate = dates.get(this.startDateFieldName);
    const endDate = dates.get(this.endDateFieldName);

    const queryParams: MVQueryDto = new MVQueryDto(startDate, endDate, [this.signalId]);

    this.chartSettings = new EditableChartSettings({
      chartType: ChartType.editable,
      dataParameters: new EditableChartDataParameters({
        startDate,
        endDate,
        queryParams: queryParams,
        dataService: 'DataValidationChartService',
      }),
    });

    this.selectedChartPoint = null;
    this.resetForm();
  }

  private createPointForm() {
    const formControls: { [key: string]: UntypedFormControl } = {};

    formControls[this.chartPointFieldName] = new UntypedFormControl(
      {
        value: this.selectedChartPoint?.value,
        disabled: isNaN(this.selectedChartPoint?.value),
      },
      [Validators.required]
    );

    this.form = this._formBuilder.group(formControls);
  }

  private updateForm() {
    const { value, dimensionTypeId } = this.selectedChartPoint;

    this._dataValidationChartService
      .convertValueToUoM(value, dimensionTypeId)
      .subscribe((value) => {
        this.form.get(this.chartPointFieldName).setValue(value);

        if (!isNaN(value)) {
          this.form.get(this.chartPointFieldName).enable();
          this.form.get(this.chartPointFieldName).markAsPristine();
        }
      });
  }

  private resetForm() {
    this.form?.reset();
    this.form?.get(this.chartPointFieldName).disable();

    this.restoreBrushButtons();
  }

  private restoreBrushButtons() {
    this.isHorizontalBrushModeOn = this.isVerticalBrushModeOn = false;
    this._activateBrush$.next(null);
    this.selectedBrushMode = undefined;

    this.toggleValueRangesCart.emit(this.isVerticalBrushModeOn);
    this.toggleDateRangesCart.emit(this.isHorizontalBrushModeOn);
  }

  private getUpdatedChartPoint(): Observable<MVChartPointDto> {
    const newValue = this.form.get(this.chartPointFieldName).value;

    return this._dataValidationChartService
      .convertValueFromUoM(newValue, this.selectedChartPoint.dimensionTypeId)
      .pipe(
        untilDestroyed(this),
        map((value) => {
          const point = { ...this.selectedChartPoint };

          point.value = value;
          point.estimatedEdited = EstimatedEditedEnum.Edited;
          point.validity = 0;
          point.validationFunctionId = null;
          point.estimationFunctionId = null;

          return point;
        })
      );
  }

  private getPendingChanges(): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: this.hasChanges,
      saveFn: () => this.save(),
    };
  }

  private activeBrushMode(mode: BrushMode) {
    if (this.selectedBrushMode === mode) {
      mode = null;
    }

    this.setBrushButtons(mode);

    this.selectedBrushMode = mode;
    this._activateBrush$.next(mode);
  }

  private setBrushButtons(mode: BrushMode) {
    if (!mode) {
      this.isHorizontalBrushModeOn = this.isVerticalBrushModeOn = false;
      return;
    }

    this.isHorizontalBrushModeOn = mode === BrushMode.Horizontal;
    this.isVerticalBrushModeOn = mode === BrushMode.Vertical;
  }

  private loadUomLabel() {
    const label$ = this._translateService.get(`${this.T_SCOPE}.fields.chart-point`);
    const unit$ = this._dataValidationChartService.getChartUnitLabel();

    this.chartPointLabel$ = combineLatest([label$, unit$]).pipe(
      untilDestroyed(this),
      map(([label, unit]) => (unit ? `${label} [${unit}]` : label))
    );
  }

  fixedSizes$(): Observable<IElementSize>[] {
    return [];
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
