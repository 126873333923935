import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HistoricalChartEditedEvent } from '@common-modules/dependencies/ne-configuration/historical-chart-edited-event';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { SignalSelectionPopupDimensions } from '@common-modules/shared/constants/dimensions.constants';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { PendingChanges } from '@common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from '@common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { HistoricalChartDataParameters } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-chart-data-parameters';
import { HistoricalChartSettings } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-chart-settings';
import { HistoricalVersionDto } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-version.dto';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, Subscriber } from 'rxjs';
import { SignalSelectionListSettings } from '../../features/ne-configuration/ne-signal-selection-lists/signal-selection-list-settings';
import { CanSaveHistoricalForm } from '../../features/shared/historical/can-save-historical-form';
import { HistoricalFormSettings } from '../../features/shared/historical/historical-form-settings';
import { IHasChanges } from '../../features/shared/model/has-changes.interface';
import { SaveSmartMeterSignalVersionResponseDto } from '../models/save-smart-meter-signal-version-response-dto';
import { SmartMeterSelectionPopupSettings } from '../models/smart-meter-selection-popup-settings';
import { SmartMeterSignalSelectionPopupResponse } from '../models/smart-meter-signal-selection-popup-response';
import { SmartMeterSignalVersionDto } from '../models/smart-meter-signal-version-dto';
import { SmartMetersHistoricalMessagesService } from '../services/smart-meters-historical-messages.service';
import { SmartMeterHistoricalCreationPopupComponent } from '../smart-meter-historical-creation-popup/smart-meter-historical-creation-popup.component';
import { SmartMetersModule } from '../smart-meters.module';

const COMPONENT_SELECTOR = 'wlm-smart-meters-historical-config';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './smart-meters-historical-config.component.html',
  styleUrl: './smart-meters-historical-config.component.scss',
})
export class SmartMetersHistoricalConfigComponent
  implements OnInit, IPendingChangesEmitter, IHasChanges
{
  private _selectedNE: INetworkElementDto;
  get selectedNE(): INetworkElementDto {
    return this._selectedNE;
  }
  @Input() set selectedNE(value: INetworkElementDto) {
    this._selectedNE = value;
    this.initByNetworkElement();
  }

  @Input() pageId: string;
  @Input() widgetId: string;

  private _isReadOnly = true;
  get isReadOnly() {
    return this._isReadOnly;
  }
  @Input() set isReadOnly(value) {
    this._isReadOnly = value;
  }

  private _hasChanges = false;
  public get hasChanges() {
    return this._hasChanges;
  }
  public set hasChanges(value) {
    this._hasChanges = value;
    this.setPendingChanges(this.pageId, this.getPendingChanges());
  }

  private _hasErrors = false;
  public get hasErrors() {
    return this._hasErrors;
  }
  public set hasErrors(value) {
    this._hasErrors = value;
    this.setPendingChanges(this.pageId, this.getPendingChanges());
  }

  saveSubscriber$: Subscriber<boolean>;

  formSettings: HistoricalFormSettings = null;
  chartSettings: HistoricalChartSettings;
  selectedConfiguration: SmartMeterSignalVersionDto;
  currentFormModel: SmartMeterSignalVersionDto;
  minDate: Date;
  maxDate: Date;
  isFormValid: boolean;
  canResetForm: boolean;

  T_SCOPE = `${AppModules.SmartMeters}.${COMPONENT_SELECTOR}`;

  get componentName(): string {
    return 'SmartMeterHistoricalComponent';
  }

  private _updateChartNewEvents$ = new Subject<HistoricalVersionDto[]>();
  readonly updateChartNewEvents$ = this._updateChartNewEvents$.asObservable();
  private _updateChartEditedEvents$ = new Subject<HistoricalChartEditedEvent>();
  readonly updateChartEditedEvents$ = this._updateChartEditedEvents$.asObservable();
  private _updateChartDeletedEvents$ = new Subject<HistoricalVersionDto>();
  readonly updateChartDeletedEvents$ = this._updateChartDeletedEvents$.asObservable();
  private _saveConfiguration$ = new Subject<INetworkElementDto>();
  readonly saveConfiguration$ = this._saveConfiguration$.asObservable();
  private _dicardAllChanges$ = new Subject<void>();
  readonly dicardAllChanges$ = this._dicardAllChanges$.asObservable();

  private _oDataFilterForSignalPopup: Map<string, any>;
  private _isPointOfInterestFieldName = 'isPointOfInterest';
  private _dimensionTypeIdFieldName = 'dimensionTypeId';
  private _oDataService = 'AvailableSignalsVersionService';
  private _networkElementIdFieldName = 'networkElementId';
  private _chartDataService = 'SmartMetersHistoricalService';
  private _dataValidationService = 'SmartMetersHistoricalValidationService';

  private _saveEditionSuccessMsg = `${this.T_SCOPE}.messages.save-edition-success`;

  constructor(
    private _dateHelper: DateHelperService,
    private _signalsPopup: MatDialog,
    private _objectHelperService: ObjectHelperService,
    private _dialogService: DialogService,
    private _smartMeterMessagesService: SmartMetersHistoricalMessagesService,
    private _pendingChangesService: PendingChangesManagerService
  ) {}

  ngOnInit(): void {}

  initByNetworkElement(): void {
    this.selectedConfiguration = null;
    this.buildDateLimits();
    this.buildChartSettings(this.selectedNE);
    this.buildFormSettings();
  }

  onChartSelectEvent(historicalEvent: SmartMeterSignalVersionDto): void {
    this.selectedConfiguration = this._objectHelperService.clone(historicalEvent);
  }

  onHasChanges(hasChanges: boolean): void {
    this.hasChanges = hasChanges;
  }

  onHasErrors(hasErrors: boolean): void {
    this.hasErrors = hasErrors;
  }

  onSavedCompleted(saveCompleted: SaveSmartMeterSignalVersionResponseDto) {
    if (saveCompleted?.success) {
      this._dialogService.showTranslatedMessageInSnackBar(
        new WlmDialogSettings({ translateKey: this._saveEditionSuccessMsg, icon: 'success' })
      );
    } else {
      this._smartMeterMessagesService.showValidationErrorsPopup(saveCompleted);
    }

    if (this.saveSubscriber$) {
      this.saveSubscriber$.next(true);
      this.saveSubscriber$.complete();
    }
  }

  itHasChanges(): boolean {
    return this.hasChanges;
  }

  private buildDateLimits(): void {
    this.minDate = this._dateHelper.getDateByYearsAgo(3);
    this.maxDate = this._dateHelper.truncateDate(new Date());
  }

  private buildFormSettings(): void {
    setTimeout(() => {
      this.formSettings = new HistoricalFormSettings({
        minDate: this.minDate,
        maxDate: this.maxDate,
        showPointFields: true,
        editOnlyDates: true,
        isReadOnly: this.isReadOnly,
      });
    });
  }

  private buildChartSettings(selectedNE: INetworkElementDto): void {
    const queryParams = {
      hierarchyElementId: selectedNE?.hierarchyElementId,
    };

    this.chartSettings = new HistoricalChartSettings({
      validationService: this._dataValidationService,
      dataParameters: new HistoricalChartDataParameters({
        dataService: this._chartDataService,
        queryParams,
        startDate: this.minDate,
        endDate: this.maxDate,
      }),
      injector: SmartMetersModule.injector,
    });
  }

  addNewConfiguration() {
    this.setOdataFiltersForSignalPopup();
    const dialogConfig = this.getPopupDefinition();
    dialogConfig.data = this.buildPopupSettings();

    this._signalsPopup
      .open(SmartMeterHistoricalCreationPopupComponent, dialogConfig)
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: SmartMeterSignalSelectionPopupResponse) => {
        if (result) {
          this._updateChartNewEvents$.next(result.configurations);
          //TODO: Add new result to configurationCart
        }
      });
  }

  removeConfiguration() {
    this._updateChartDeletedEvents$.next(this.selectedConfiguration);
    this.resetSelection();
  }

  onFormChange(model: SmartMeterSignalVersionDto): void {
    this.currentFormModel = model;
  }

  onCanSave(data: CanSaveHistoricalForm): void {
    this.isFormValid = data.isValid;
    if (this.isFormValid) {
      this.onEdit(data.hasChanges);
    }
  }

  onCanReset(canReset: boolean): void {
    this.canResetForm = canReset;
  }

  onEdit(hasChanges: boolean): void {
    this._updateChartEditedEvents$.next(
      new HistoricalChartEditedEvent({
        editedHistoricalEvent: {
          ...this.currentFormModel,
          hierarchyElementId: this.selectedNE?.hierarchyElementId,
        } as any,
        hasChanges,
      })
    );
  }

  onSave() {
    this.save().subscribe(() => {});
  }

  save(): Observable<boolean> {
    return new Observable<boolean>((o) => {
      this.saveSubscriber$ = o;

      this._saveConfiguration$.next({ ...this.selectedNE });
    });
  }

  /**
   * In this context, resetting means that the selection (which is set to initialFormModel) is set to null.
   */
  discardChanges(): void {
    this.selectedConfiguration = null;
    this.currentFormModel = null;
    this.isFormValid = false;
    this.canResetForm = false;
    this._dicardAllChanges$.next();
  }

  saveDisabled() {
    return !this.hasChanges || this.hasErrors || this.isReadOnly;
  }

  private getPopupDefinition() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = SignalSelectionPopupDimensions.Width;
    dialogConfig.height = SignalSelectionPopupDimensions.Height;
    return dialogConfig;
  }

  private buildPopupSettings(): SmartMeterSelectionPopupSettings {
    return new SmartMeterSelectionPopupSettings({
      signalsListSettings: new SignalSelectionListSettings({
        oDataService: this._oDataService,
        queryFieldNames: [this._networkElementIdFieldName],
        selectedItem: this.selectedNE,
        oDataFilters: this._oDataFilterForSignalPopup,
      }),
      minDate: this.minDate,
      maxDate: this.maxDate,
      title: '',
      networkElement: this.selectedNE,
      pageId: this.pageId,
    });
  }

  private setOdataFiltersForSignalPopup() {
    this._oDataFilterForSignalPopup = new Map<string, any>();
    this._oDataFilterForSignalPopup.set(this._isPointOfInterestFieldName, true);
    this._oDataFilterForSignalPopup.set(this._dimensionTypeIdFieldName, 1);
  }

  private resetSelection() {
    this.selectedConfiguration = null;
    this.currentFormModel = null;
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private getPendingChanges(): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: !this.saveDisabled(),
      saveFn: () => this.save(),
    };
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
