import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

export class UserFilter implements IFilter {
  userIds: string[];
  userIdFieldName: string;

  constructor(elementIds: string[], elementIdFieldName: string) {
    this.userIds = elementIds;
    this.userIdFieldName = elementIdFieldName;
  }

  getFiltersValues(): Map<string, any> {
    return new Map<string, any>([[this.userIdFieldName, this.userIds]]);
  }

  getSelectedFilters() {
    return new UserFilter(this.userIds, this.userIdFieldName);
  }

  getFilters(): CompositeFilterDescriptor {
    const filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

    const userFilter: CompositeFilterDescriptor = { logic: 'or', filters: [] };

    this.userIds.forEach((id) =>
      userFilter.filters.push({ field: this.userIdFieldName, operator: 'eq', value: id })
    );

    filter.filters.push(userFilter);

    return filter;
  }

  getElementIds() {
    return this.userIds;
  }
}
