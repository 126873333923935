import { Injectable, Injector } from '@angular/core';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';

import { ISystemLog } from '../models/system-log';

@Injectable({
  providedIn: 'root',
})
export class LogsSystemService extends GridODataService<ISystemLog> {
  get apiUrl() {
    return `${this.baseUrl}/api/log/system`;
  }

  constructor(injector: Injector) {
    super(injector, '/api/log/system');
  }

  protected mapResponse(response: PagedResultDto<ISystemLog>): PagedResultDto<ISystemLog> {
    return response;
  }
}
