import { Injectable } from '@angular/core';
import { WaterBalanceTypeOption } from '@common-modules/dependencies/water-balance/water-balance-type-option';
import { WaterBalanceTypesEnum } from '@common-modules/dependencies/water-balance/water-balance-types';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WaterBalanceTypesService {
  constructor() {}

  public getWaterBalanceTypes(): Observable<WaterBalanceTypeOption[]> {
    const wbTypes = [
      new WaterBalanceTypeOption('bottom-up', WaterBalanceTypesEnum.BottomUp),
      new WaterBalanceTypeOption('top-down', WaterBalanceTypesEnum.TopDown),
    ];
    return of(wbTypes);
  }
}
