import { Injectable, Injector } from '@angular/core';
import { IHierarchyElementDto } from '@common-modules/dependencies/he/hierarchy-element.dto';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';

const API_BASE = '/api/merged-zones';

@Injectable({
  providedIn: 'root',
})
export class MergedZonesHeAvailableService extends GridODataService<IHierarchyElementDto> {
  constructor(injector: Injector) {
    super(injector, `${API_BASE}/available-he`);
  }

  protected mapResponse(
    response: PagedResultDto<IHierarchyElementDto>
  ): PagedResultDto<IHierarchyElementDto> {
    return response;
  }
}
