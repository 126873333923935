import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SignalInfoDto } from '@common-modules/dependencies/shared/model/signal-info.dto';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { UtilsHelperService } from '@common-modules/shared/helpers/utils-helper.service';
import { HistoricalFormSettings } from '../../features/shared/historical/historical-form-settings';
import { SmartMeterConfigDto } from '../models/smart-meter-config.dto';
import { SmartMeterSelectionPopupSettings } from '../models/smart-meter-selection-popup-settings';
import { SmartMeterSignalSelectionPopupResponse } from '../models/smart-meter-signal-selection-popup-response';
import { SmartMeterSignalVersionDto } from '../models/smart-meter-signal-version-dto';
import { SmartMeterSaveDto } from '../models/smart-meters-save.dto';

const COMPONENT_SELECTOR = 'wlm-smart-meter-historical-creation-popup';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './smart-meter-historical-creation-popup.component.html',
  styleUrl: './smart-meter-historical-creation-popup.component.scss',
})
export class SmartMeterHistoricalCreationPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  private _isLoading = false;
  public get isLoading() {
    return this._isLoading;
  }
  public set isLoading(value) {
    this._isLoading = value;
  }
  canSave = true;

  popupSettings: SmartMeterSelectionPopupSettings;
  formSettings: HistoricalFormSettings = {};
  currentConfigurations: SmartMeterConfigDto[] = [];
  historicalConfigurations: SmartMeterSignalVersionDto[] = [];
  datesFromModel: SmartMeterSignalVersionDto;
  validTo: Date;
  validFrom: Date;
  initialFormModel: SmartMeterSignalVersionDto;

  configuredSignals: SignalInfoDto[] = [];

  constructor(
    private dialogRef: MatDialogRef<SmartMeterHistoricalCreationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) popupSettings: SmartMeterSelectionPopupSettings,
    private _utilsService: UtilsHelperService
  ) {
    this.popupSettings = popupSettings;
  }

  ngOnInit(): void {
    this.formSettings = new HistoricalFormSettings({
      minDate: this.popupSettings.minDate,
      maxDate: this.popupSettings.maxDate,
      editOnlyDates: false,
      emitAtFormInitialization: true,
      showPointFields: false,
    });

    this.initialFormModel = new SmartMeterSignalVersionDto({
      validFrom: this.popupSettings.minDate,
      validTo: this.popupSettings.maxDate,
      signalId: null,
      pointId: null,
      pointDescription: null,
      id: null,
      hierarchyElementId: null,
      customerClassTypeId: null,
      customerClassTypeName: null,
      title: '',
    });

    this.datesFromModel = { ...this.initialFormModel };
  }

  close(): void {
    this.dialogRef.close();
  }

  saveConfiguration(): void {
    this.buildHistoricalConfigurations();
    const popupResponse = new SmartMeterSignalSelectionPopupResponse({
      configurations: this.historicalConfigurations,
    });
    this.dialogRef.close(popupResponse);
  }

  onHistoricalFormChanges(data: SmartMeterSignalVersionDto): void {
    this.validFrom = data.validFrom;
    this.validTo = data.validTo;
  }

  onSmartMetersCurrentConfigChanges(data: SmartMeterSaveDto): void {
    this.currentConfigurations = [...data.configurations];
  }

  private buildHistoricalConfigurations(): void {
    if (this.isValid) {
      this.historicalConfigurations = this.currentConfigurations.map(
        (currentConfig) =>
          new SmartMeterSignalVersionDto({
            ...currentConfig,
            validFrom: this.validFrom,
            validTo: this.validTo,
            title: '',
            id: globalUtilsHelper.generateGuid(),
          })
      );
    }
  }

  get isValid(): boolean {
    return this.currentConfigurations?.length && !!this.validFrom && !!this.validTo;
  }
}
