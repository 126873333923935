import { Injectable, Injector } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { BaseKeyService } from '../base-key.service';
import { SettingsService } from '../config/settings.service';
import { StringHelperService } from '../helpers/string-helper.service';
import { UtilsHelperService } from '../helpers/utils-helper.service';
import { LocalizationHelperService } from '../localization/localization-helper.service';
import { IAlgorithmDto } from '../model/algorithm/algorithm.dto';
import { DimensionTypesEnum } from '../model/shared/dimension-types';
import { HierarchyElementUnitsQueryParameters } from '../model/uom/hierarchy-element-type-time-units-query.dto';
import { UnitTypeConversionViewDto } from '../model/uom/unit-type-conversion-view.dto';
import { AlgorithmsService } from '../services/algorithms.service';
import { LogService } from '../wlm-log/log.service';

@Injectable({ providedIn: 'root' })
export class UoMService extends BaseKeyService<string, UnitTypeConversionViewDto> {
  private readonly _defaultTimeAggregationId = 1;
  readonly defaultHierarchyElementTypeId = '00000000-0000-0000-0000-000000000000';
  private readonly _percentageDecimals = 2;

  constructor(
    injector: Injector,
    private _algorithmsService: AlgorithmsService,
    private _logService: LogService,
    private _localizationHelperService: LocalizationHelperService,
    private _stringHelperService: StringHelperService,
    private _settingsService: SettingsService,
    private _utilsService: UtilsHelperService
  ) {
    super(injector);
  }

  getAllNoExceptions(): Observable<UnitTypeConversionViewDto[]> {
    const elements = this.getCached<UnitTypeConversionViewDto[]>('unit/conversion/all');
    return elements;
  }

  protected getElements(): Observable<UnitTypeConversionViewDto[]> {
    const elements = this.getAllNoExceptions();

    return elements.pipe(
      map((e) => {
        return e.filter((unit) => unit.isDefaultFromUnit);
      })
    );
  }

  protected getKey(model: UnitTypeConversionViewDto): string {
    const key = `${model.dimensionTypeId}-${model.hierarchyElementTypeId?.toLowerCase()}-${
      model.timeAggregationId
    }`;
    return key;
  }

  getUnitName(unitId: number): Observable<string> {
    return this.getAllNoExceptions().pipe(
      take(1),
      map((units) => {
        let uomName;
        const currentUnitFrom = units.find((unit) => unit.unitTypeFromId === unitId);
        if (currentUnitFrom) {
          uomName = currentUnitFrom.unitTypeFromDescription;
        } else {
          const currentUnitTo = units.find((unit) => unit.unitTypeToId === unitId);
          if (currentUnitTo) {
            uomName = currentUnitTo.unitTypeToDescription;
          } else {
            throw new Error(`The unit ${unitId} was not a valid UoM.`);
          }
        }
        return uomName;
      })
    );
  }

  public getAllDefaultUoM() {
    return this.getElements();
  }

  public getBySignal(dimensionTypeId: number): Observable<UnitTypeConversionViewDto> {
    const uom = this.getByParams(dimensionTypeId, this._defaultTimeAggregationId);
    return uom;
  }

  public getBySignalSync(
    dimensionTypeId: number,
    unitTypeId: number,
    unitTypes: UnitTypeConversionViewDto[],
    inputTimeAggregationId: number,
    inputHierarchyElementTypeId: string
  ): UnitTypeConversionViewDto {
    const timeAggregationId = inputTimeAggregationId ?? this._defaultTimeAggregationId;
    const hierarchyElementTypeId =
      inputHierarchyElementTypeId ?? this.defaultHierarchyElementTypeId;
    const result = unitTypes.find(
      (ut) =>
        (ut.dimensionTypeId === dimensionTypeId &&
          ut.timeAggregationId === timeAggregationId &&
          ut.hierarchyElementTypeId === hierarchyElementTypeId) ||
        (ut.unitTypeToId == unitTypeId && ut.unitTypeFromId == unitTypeId)
    );

    if (!result) {
      throw new Error(`No conversion factor was found.`);
    }
    return result;
  }

  public getConvertedValue(
    conversionFactor: UnitTypeConversionViewDto,
    value: number,
    decimalPositions: number,
    showDescription = true
  ): string {
    const convertedValue = value * conversionFactor.conversionFactor;
    const formattedNumber = this._localizationHelperService.formatNumber(
      convertedValue,
      `.${decimalPositions}-${decimalPositions}`
    );

    return `${formattedNumber}${
      showDescription ? ` ${conversionFactor.unitTypeToDescription}` : ''
    }`;
  }

  public getByParams(
    dimensionTypeId: number,
    timeAggregation: number,
    hierarchyElementTypeId?: string
  ): Observable<UnitTypeConversionViewDto> {
    const key = this.getKeyFromParameters(
      dimensionTypeId,
      timeAggregation,
      hierarchyElementTypeId ?? this.defaultHierarchyElementTypeId
    );

    return this.getByKey(key);
  }

  public getByAlgorithmShortName(
    shortName: string,
    hierarchyElementTypeId?: string
  ): Observable<UnitTypeConversionViewDto> {
    return this._algorithmsService.getAlgorithm(shortName.toUpperCase()).pipe(
      mergeMap((data: IAlgorithmDto) => {
        if (data) {
          return this.getByParams(
            data.dimensionTypeId,
            data.timeAggregationId,
            hierarchyElementTypeId
          );
        }
      })
    );
  }

  public getByHEUnit(
    query: HierarchyElementUnitsQueryParameters
  ): Observable<UnitTypeConversionViewDto> {
    const key = this.getKeyFromParameters(
      query.dimensionTypeId,
      query.timeAggregationId,
      query.hierarchyElementTypeId
    );
    return this.getByKey(key);
  }

  getByFromUnit(
    dimensionTypeId: number,
    unitTypeFromId: number,
    timeAggregation = this._defaultTimeAggregationId,
    hierarchyElementTypeId = this.defaultHierarchyElementTypeId
  ): Observable<UnitTypeConversionViewDto> {
    // First, obtain the target unit configured by the user.
    const userUnit$ = this.getByParams(dimensionTypeId, timeAggregation, hierarchyElementTypeId);

    if (!unitTypeFromId) {
      return userUnit$;
    }

    return userUnit$.pipe(
      switchMap((userUnit) => {
        if (!userUnit) {
          this._logService.error({
            msg: 'Could not find unit from settings.',
            payload: { dimensionTypeId, timeAggregation, hierarchyElementTypeId },
          });
          return of(null) as Observable<UnitTypeConversionViewDto>;
        }

        const unitIdToConvert = userUnit.unitTypeToId;

        if (unitIdToConvert === unitTypeFromId) {
          return of({ ...userUnit, conversionFactor: 1 });
        }

        // Then, find the unit dto that maps from the selected unit to the user configured unit.
        return this.getAllNoExceptions().pipe(
          map((units) => {
            const result = units.find(
              (ut) =>
                ut.dimensionTypeId === dimensionTypeId &&
                ut.unitTypeFromId === unitTypeFromId &&
                ut.timeAggregationId === timeAggregation &&
                ut.hierarchyElementTypeId === hierarchyElementTypeId &&
                ut.unitTypeToId === unitIdToConvert
            );
            return result;
          })
        );
      })
    );
  }

  getFormatedUnit(
    unitDescription: string,
    formatLabelKey: string,
    format: string
  ): Observable<string> {
    if (!unitDescription && !formatLabelKey && !format) {
      return of('');
    }

    if (!format) {
      return of(unitDescription);
    }

    const unitDescription$ = of(unitDescription);
    const formatLabelKeyTs$ = formatLabelKey
      ? this._localizationHelperService.get(formatLabelKey)
      : of('{0}');
    const format$ = of(format);

    return combineLatest([unitDescription$, formatLabelKeyTs$, format$]).pipe(
      map(([unitDescription, formatLabelKeyTs, format]) => {
        if (formatLabelKeyTs) {
          return this._stringHelperService.formatString(format, unitDescription, formatLabelKeyTs);
        }

        return unitDescription;
      })
    );
  }

  uomMultiply(
    value: string,
    factor: string,
    strict = true,
    dimensionTypeId?: DimensionTypesEnum,
    disableLocale: boolean = false,
    decimalsFormat: string = null
  ): string {
    const decimals =
      dimensionTypeId === DimensionTypesEnum.Percentage
        ? this._percentageDecimals
        : this._settingsService.maxDecimalPositions;
    const digitsInfo = decimalsFormat ? decimalsFormat : `.${decimals}-${decimals}`;

    let convertedValue = this._utilsService.uomMultiply(String(value), String(factor), strict);

    if (Number(convertedValue) % 1 !== 0) {
      //if decimal, needs to be formated
      convertedValue = this._localizationHelperService.formatNumber(
        +convertedValue,
        digitsInfo,
        disableLocale
      );
    }

    return convertedValue;
  }

  private getKeyFromParameters(
    dimensionTypeId: number,
    timeAggregation: number,
    hierarchyElementTypeId: string
  ): string {
    return `${dimensionTypeId}-${hierarchyElementTypeId?.toLowerCase()}-${timeAggregation}`;
  }
}
