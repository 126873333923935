<input
  matInput
  class="inner-input"
  [placeholder]="settings?.placeholder"
  [formControl]="innerCtrl"
  #filterMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="filterMenu"
  autocomplete="off"
  [readonly]="true"
/>

<mat-menu #filterMenu="matMenu" class="panel-disable-fixed" (closed)="onMenuClosed($event)">
  <div class="menu-container" (click)="stopPropagation($event)">
    <div
      [ngStyle]="{
        'min-height': settings?.expandedMinHeight ?? 'unset',
        'max-height': settings?.expandedMaxHeight ?? 'unset'
      }"
    >
      <kendo-treeview
        name="treeview"
        id="treeview"
        class="filter-item-treeview"
        [nodes]="resolvedNodes"
        kendoTreeViewExpandable
        expandBy="currentDescription"
        [selectedKeys]="selectedKeys"
        selectBy="currentId"
        kendoTreeViewFlatDataBinding
        idField="currentId"
        parentIdField="ancestorId"
        [kendoTreeViewSelectable]="selection"
        [(expandedKeys)]="expandedKeys"
        (selectionChange)="handleSelection($event)"
        (nodeClick)="handleClick($event)"
        [isSelected]="isItemSelected"
        kendoTreeViewLoadMore
        pageSize="10"
      >
        <ng-template kendoTreeViewNodeTemplate let-dataItem>
          <div>
            {{ dataItem.currentDescription }}
          </div>
        </ng-template>
        <ng-template kendoTreeViewLoadMoreButtonTemplate>
          <button mat-icon-button>
            <mat-icon>more_horiz</mat-icon>
          </button>
        </ng-template>
      </kendo-treeview>
    </div>
  </div>
</mat-menu>
