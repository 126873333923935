import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationConfigurationService extends BaseService {
  get apiUrl() {
    return `${this.baseUrl}/api/settings`;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  getUserNotificationConfiguration(): Observable<Map<string, Map<string, boolean>>> {
    return this.httpCacheClient
      .get(`${this.apiUrl}/notification`, {
        avoid: true,
      })
      .pipe(
        map((config) => {
          const configMap = new Map<string, Map<string, boolean>>();

          Object.entries(config).forEach(([configKey, configValues]) => {
            const map: Map<string, boolean> = new Map(Object.entries(configValues));
            configMap.set(configKey, map);
          });

          return configMap;
        })
      );
  }

  saveUserNotificationConfiguration() {}
}
