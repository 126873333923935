import { Component, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { SetValueAction } from '@common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from '@common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseSelectorDynamicWidgetComponent } from '@common-modules/redux/components/base-selector-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, Subject } from 'rxjs';
import { CalendarStateFields } from '../../models/calendar-state-fields';

const COMPONENT_SELECTOR = 'wlm-calendar-selector-widget';
export const CALENDAR_SELECTOR_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './calendar-selector-widget.component.html',
  styleUrls: ['./calendar-selector-widget.component.scss'],
  providers: [ReduxStateService],
})
export class CalendarSelectorWidgetComponent
  extends BaseSelectorDynamicWidgetComponent
  implements OnInit
{
  get selectedFieldName(): string {
    return CalendarStateFields.selectedCalendar;
  }

  get componentName(): string {
    return 'CalendarSelectorWidgetComponent';
  }

  selectedCalendarId: string;
  removeSelection$ = new ReplaySubject<void>();
  _reloadSelector$ = new Subject<void>();
  reloadSelector$ = this._reloadSelector$.asObservable();

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings, pendingChangesService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onWidgetInit(): void {
    this._state
      .select<string>(
        new GetValueSelector({
          fieldName: CalendarStateFields.cleanCalendarSelector,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value && this.widgetInstanceKey !== value) {
          this.removeSelection$.next();
        }
      });

    this._state
      .select<boolean>(
        new GetValueSelector({
          fieldName: CalendarStateFields.reloadCalendarSelector,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this._reloadSelector$.next();
        }
      });
  }

  onCalendarSelected(calendarSelected: any): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: this.selectedFieldName,
        value: calendarSelected,
      })
    );

    this.cleanOtherSelector();
  }

  cleanOtherSelector() {
    this._state.dispatch(
      new SetValueAction({
        fieldName: CalendarStateFields.cleanCalendarSelector,
        value: null,
      })
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: CalendarStateFields.cleanCalendarSelector,
        value: this.widgetInstanceKey,
      })
    );
  }
}
