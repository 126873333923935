import { PressureSettings } from '@common-modules/dependencies/ne-configuration/pressure-settings';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { SignalSelectionListSettings } from '../../features/ne-configuration/ne-signal-selection-lists/signal-selection-list-settings';

export class PressureSelectionPopupSettings {
  signalsListSettings: SignalSelectionListSettings;
  title: string;
  minDate: Date;
  maxDate: Date;
  networkElement: INetworkElementDto;
  pageId: string;
  pressureSettings: PressureSettings;

  constructor(init: Readonly<PressureSelectionPopupSettings>) {
    Object.assign(this, init);
  }
}
