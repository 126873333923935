// prettier-ignore
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpCacheService } from '@common-modules/cache/http-cache/http-cache.service';
import { IHierarchyElementDto } from '@common-modules/dependencies/he/hierarchy-element.dto';
import { BaseWizardStepComponent } from '@common-modules/dependencies/shared/base-wizard-step.component';
import { ElementTypeFilter } from '@common-modules/dependencies/wlm-filters/i-filters/element-type-filter';
import { HierarchyElementPathFilter } from '@common-modules/dependencies/wlm-filters/i-filters/hierarchy-element-path-filter';
import { HierarchyElementsSelectionFilterConfiguration } from '@common-modules/shared-component/combined-grids/hierarchy-elements-selection-grid/hierarchy-elements-selection-filter-configuration';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsEvent } from '@common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from '@common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';

const COMPONENT_SELECTOR = 'wlm-hierarchy-elements-selection-grid';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-elements-selection-grid.component.html',
  styleUrls: ['./hierarchy-elements-selection-grid.component.scss'],
  providers: [
    {
      provide: BaseWizardStepComponent,
      useExisting: forwardRef(() => HierarchyElementSelectionGridComponent),
    },
  ],
})
export class HierarchyElementSelectionGridComponent
  extends BaseWizardStepComponent
  implements OnInit
{
  @ViewChild(GenericGridComponent) genericGrid: GenericGridComponent;
  @Output() selectedHeElementsChange = new EventEmitter<IHierarchyElementDto[]>();
  @Input() hideComponent = false;

  private _persistencyArea: string;
  public get persistencyArea(): string {
    return this.hierarchyElementFilterConfiguration?.persistencyArea ?? this._persistencyArea;
  }

  @Input() public set persistencyArea(value: string) {
    this._persistencyArea = value;
  }

  private _resetHandler$: Observable<boolean>;
  public get resetHandler$(): Observable<boolean> {
    return this._resetHandler$;
  }
  @Input() public set resetHandler$(v: Observable<boolean>) {
    this._resetHandler$ = v;
    this.subscribeToResetWizard(v);
  }

  private _hierarchyElementFilterConfiguration: HierarchyElementsSelectionFilterConfiguration;
  public get hierarchyElementFilterConfiguration(): HierarchyElementsSelectionFilterConfiguration {
    return this._hierarchyElementFilterConfiguration;
  }
  @Input() public set hierarchyElementFilterConfiguration(
    value: HierarchyElementsSelectionFilterConfiguration
  ) {
    this._hierarchyElementFilterConfiguration = value;
    if (value) {
      this.hideFilterButtons = value.hideFilterButtons;
      this.configReady = true;
    }
  }

  @Input() gridCssClass: string;

  useGridPersistence = true;
  gridSettings: GridSetting;
  gridFiltersForBinding: DataBindingFilters;
  gridFilters = new DataBindingFilters();
  configReady = false;
  removeSelection$ = new ReplaySubject<void>();
  hideFilterButtons = false;

  public get pageCrud(): string {
    return 'HierarchyElementSelectionCrud';
  }

  triggerApply$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  clearAll$ = new Subject<void>();
  gridName = 'HierarchyElementsSelection';
  selectedHEs: IHierarchyElementDto[];
  stepKey = 'he-step';
  validFilters: boolean;
  heTypeFilterName = 'hierarchyElementTypeFilter';
  heFilterName = 'hierarchyElementsFilter';
  autoloadFilter = true;
  gridSettingsSubscription: Subscription;
  gridSettingsReady$ = new ReplaySubject<GridSetting>();

  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;

  constructor(private _gridService: GridSettingsService, private _cacheService: HttpCacheService) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.initializeGrid();
  }

  private initializeGrid() {
    this._gridService.getGridSettingsByName(this.gridName).subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
          this.gridSettingsReady$.next(this.gridSettings);
          this.gridSettingsReady$.complete();
        }
      },
    });
  }

  onApplyFiltersFromPage(): void {
    this.triggerApply$.next();
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    this.gridFilters = filtersParameters;
    this.validFilters = this.validateFilters();

    if (this.validFilters && this.autoloadFilter) {
      this.loadGrid();
      this.autoloadFilter = false;
    }
  }

  validateFilters(): boolean {
    if (!this.gridFilters || !this.gridFilters.filters.has(this.heTypeFilterName)) {
      return false;
    }

    const heTypesValue = (this.gridFilters.filters.get(this.heTypeFilterName) as ElementTypeFilter)
      ?.selectedElements;

    const heValues = (this.gridFilters.filters.get(this.heFilterName) as HierarchyElementPathFilter)
      ?.hierarchyFamilyId;

    return heTypesValue.length > 0 && heValues !== undefined;
  }

  onSelectedItemsChanged(selectedHEs: IHierarchyElementDto[]) {
    this.selectedHEs = selectedHEs;
    this.selectedHeElementsChange.emit(this.selectedHEs);
  }

  onApplyFilters(gridFiltersRef?: any): void {
    this.loadGrid(gridFiltersRef);
  }

  loadGrid(gridFiltersRef?: any) {
    const newGridFilters = new DataBindingFilters();
    if (gridFiltersRef && gridFiltersRef.filters) {
      newGridFilters.filters = gridFiltersRef.filters;
    } else if (this.gridFilters && this.gridFilters.filters) {
      newGridFilters.filters = this.gridFilters.filters;
    }

    if (newGridFilters?.filters?.size > 0) {
      this.gridFiltersForBinding = newGridFilters;
    }
  }

  private subscribeToResetWizard(resetHandler$: Observable<boolean>) {
    if (resetHandler$) {
      resetHandler$.pipe(untilDestroyed(this)).subscribe((hasToReset) => {
        if (hasToReset) {
          this._cacheService
            .clearContainsInUrl(this.persistencyArea) // clear selected rows in cache
            .then((success) => {
              this.removeSelection$.next();
              this.clearAll$.next(); // notify all filters to perform clearAll
              this.genericGrid?.clearFilters();
              this.hierarchyElementFilterConfiguration.heTypeSettings.selectedIds = [];
              this.selectedHEs = [];
              this.selectedHeElementsChange.emit(this.selectedHEs);
              this.applyPersistedValue$.next();
              this.resetCompleteHandler$?.next();
            });
        }
      });
    }
  }

  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  emitPersistedValue() {
    if (this.hasToEmitPersistencyValue) {
      this.hasToEmitPersistencyValue = false;
      this.selectedHeElementsChange.emit(this.selectedHEs);
      this.applyPersistedValue$.next();
    }
  }

  onselectedPersistedItemsChanged() {
    this.emitPersistedValue();
  }

  onCheckAutoload(): void {
    if (this.gridSettingsSubscription && !this.gridSettingsSubscription.closed) {
      this.gridSettingsSubscription.unsubscribe();
    }
    this.gridSettingsSubscription = this.gridSettingsReady$
      .pipe(untilDestroyed(this))
      .subscribe((settings) => {
        if (!settings.disableAutoLoad && this.gridFilters?.filters) {
          this.loadGrid();
        }
      });
  }
}
