import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import { RouteNameHelper } from '@common-modules/dependencies/navigation/route-name-helper';
import { AuthGuard } from '@common-modules/shared/auth/services/auth.guard';
import { LeavePageGuard } from '@common-modules/shared/navigation/leave-page.guard';
import { UomPageComponent } from '@common-modules/uom/uom-page/uom-page.component';
import { AlgorithmsConfigurationPageComponent } from '../ne-configuration/algorithms-configuration-page/algorithms-configuration-page.component';
import { NeConfigDetailsPageComponent } from '../ne-configuration/ne-config-details-page/ne-config-details-page.component';
import { NeConfigPageComponent } from '../ne-configuration/ne-config-page/ne-config-page.component';
import { AlarmsConfigurationPageComponent } from './alarms-configuration-page/alarms-configuration-page.component';
import { CalendarPageComponent } from './calendars/calendar-page/calendar-page.component';
import { SAWizardPageComponent } from './sa-wizard-page/sa-wizard-page.component';
import { TelemetryPageComponent } from './telemetry-page/telemetry-page.component';

const routes: Routes = [
  {
    path: RouteNameHelper.telemetry,
    component: TelemetryPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    // #18328: Uncomment this to test the Auth guard.
    data: {
      navKey: NavKeys.TelemetryPoints,
    },
  },
  {
    path: RouteNameHelper.alarms,
    component: AlarmsConfigurationPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.AlarmsConfiguration,
    },
  },
  {
    path: RouteNameHelper.alarmsCreateTelemetry,
    component: SAWizardPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.AlarmsCreationTelemetry,
    },
  },
  {
    path: RouteNameHelper.alarmsCreateAlgorithm,
    component: SAWizardPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.AlarmsCreationAlgorithms,
    },
  },
  {
    path: RouteNameHelper.neConfigDetail,
    component: NeConfigDetailsPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.NetworkElementDetails,
    },
  },
  {
    path: RouteNameHelper.neConfig,
    component: NeConfigPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.NetworkElementConfig,
    },
  },
  {
    path: RouteNameHelper.algorithms,
    component: AlgorithmsConfigurationPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.Algorithms,
    },
  },
  {
    path: RouteNameHelper.uom,
    component: UomPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.UoM,
    },
  },
  {
    path: RouteNameHelper.calendar,
    component: CalendarPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.Calendar,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfigurationRoutingModule {}
