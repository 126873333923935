import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GenericCardSettings } from '@common-modules/shared-component/generic-card/generic-card-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DragListSettings } from '@common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { SelectDragListSettings } from '@common-modules/shared/core/select-drag-list-virtual/select-drag-list-settings';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { ISignalTelemetryNullableViewDto } from '@common-modules/shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { WtrColors } from '@common-modules/shared/styles/wtr-colors';
import { DIMENSION_ITEMS } from '@common-modules/uom/models/dimension-items';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { FiltrableItemMapperService } from '../../../shared/services/filtrable-item-mapper.service';

const COMPONENT_SELECTOR = 'wlm-data-visualization-points-selector';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-points-selector.component.html',
  styleUrls: ['./data-visualization-points-selector.component.scss'],
})
export class DataVisualizationPointsSelectorComponent implements OnInit {
  @Output() pointSelected = new EventEmitter<any>();

  private _pointIdFieldName = 'pointId';
  private _pointDescriptionFieldName = 'pointDescription';
  private _selectedFieldName = 'signalId';

  private _serviceName = 'TelemetryPointsAllService';
  private _pagesize = 50;
  private _dimensions = DIMENSION_ITEMS;

  settings: DragListSettings;
  cardSettings: GenericCardSettings<ISignalTelemetryNullableViewDto>;
  queryParams: Map<string, any>;
  refreshList$ = new Subject<void>();
  reloadList$ = new Subject<void>();
  filters = new DataBindingFilters();

  constructor(
    private _filtrableItemMapper: FiltrableItemMapperService,
    private _localizationService: LocalizationHelperService
  ) {}

  ngOnInit(): void {
    this.prepareListsSettings();
  }

  onSelectItem(signal: ISignalTelemetryNullableViewDto) {
    const signalFiltrableItem = this._filtrableItemMapper.getSignalFiltrableItemDto(signal);
    this.pointSelected.emit(signalFiltrableItem);
  }

  private prepareListsSettings() {
    this.settings = new SelectDragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._pointDescriptionFieldName, dir: 'asc' }],
      oDataFiltersExtended: this.filters,
      useQueryParams: true,
      displayFieldName: this._pointDescriptionFieldName,
      isReadOnly: false,
      selectedFieldName: this._selectedFieldName,
      filtrableFields: [this._pointIdFieldName, this._pointDescriptionFieldName],
      disableSelection: true,
    });

    this._localizationService
      .get(`${AppModules.DataVisualization}.labels`)
      .pipe(untilDestroyed(this))
      .subscribe((ts) => {
        const getPointSubtitleFn = (model: ISignalTelemetryNullableViewDto) =>
          model.isConfigured ? ts['point-configured'] : ts['point-not-configured'];

        this.cardSettings = new GenericCardSettings<ISignalTelemetryNullableViewDto>({
          title1Fn: (model) => `${model.pointId} - ${model.pointDescription}`,
          subtitleFn: (model) => getPointSubtitleFn(model),
          leftIconNameFn: (model) =>
            this._dimensions.find((f) => f.id === model.dimensionTypeId)?.iconName,
          iconColor: WtrColors.NavBarIconColor,
        });
      });

    this.queryParams = new Map<string, any>();
  }
}
