import { ComponentType } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { MapConfigurationsPopupDimensions } from '@common-modules/shared/constants/dimensions.constants';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { IGisElementDto } from '@common-modules/shared/model/gis/gis-element.dto';
import { ICanLeavePage } from '@common-modules/shared/navigation/can-component-deactivate';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GisService } from '../../shared/gis/gis.service';
import { BaseMapComponent } from '../base-map/base-map.component';
import { MapHelperService } from '../map-helper.service';
import { MapParameters } from '../map-parameters';
import { MapThematicConfigurationPopupComponent } from '../map-thematic-configuration-popup/map-thematic-configuration-popup.component';
import { MapZoomConfigurationPopupComponent } from '../map-zoom-configuration-popup/map-zoom-configuration-popup.component';

const COMPONENT_SELECTOR = 'wlm-map-page';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss'],
})
export class MapPageComponent
  extends BasePageComponent
  implements OnInit, OnDestroy, ICanLeavePage
{
  private _baseMap: BaseMapComponent;
  public get baseMap(): BaseMapComponent {
    return this._baseMap;
  }
  @ViewChild(BaseMapComponent)
  public set baseMap(value: BaseMapComponent) {
    this._baseMap = value;

    if (this.baseMap) {
      this.initMap();
    }
  }

  private readonly _settingArea = 'MapSetting';
  private readonly _settingKey = 'MapFilter';

  T_SCOPE = `${AppModules.Map}.${COMPONENT_SELECTOR}`;
  settingsLoaded: boolean = false;
  mapParameters: MapParameters;
  params: Params;
  gisElement: IGisElementDto;
  initializeMap$ = new Subject<void>();
  displayMapMessage$ = new BehaviorSubject<WlmDialogSettings>(null);

  constructor(
    private _route: ActivatedRoute,
    private _gisService: GisService,
    private _mapHelperService: MapHelperService,
    private _mapDialog: MatDialog
  ) {
    super();
    this.hasRightPanel = false;
  }

  ngOnInit(): void {
    super.ngOnInit();

    const params$ = this._route.queryParams;

    params$.pipe(untilDestroyed(this)).subscribe((params) => {
      this.params = this.getQueryParams(params);

      this.getGisElementByParams();
    });
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }
  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WLMMapCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  openZoomsConfigurator = () => this.openMapConfiguratoionPopUp(MapZoomConfigurationPopupComponent);

  openThematicConfigurator = () =>
    this.openMapConfiguratoionPopUp(MapThematicConfigurationPopupComponent);

  openMapConfiguratoionPopUp(component: ComponentType<any>) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = MapConfigurationsPopupDimensions.Width;
    dialogConfig.height = MapConfigurationsPopupDimensions.Height;

    this._mapDialog
      .open(component, dialogConfig)
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((hasToReloadMap) => {
        if (hasToReloadMap) {
          this.initializeMap$.next();
        }
      });
  }

  canLeavePage(): Observable<boolean> {
    const mapSettings = this.baseMap.mapSettings;

    this._mapHelperService.persistMapSettingsLocally(mapSettings, this.mapParameters.settingKey);

    this._mapHelperService.persistMapSettings(mapSettings, this._settingArea, this._settingKey);

    return of(true);
  }

  private initializeMapParameters() {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    const mapParameters = MapParameters.getparameter({
      visibleLayersIds: persistedMapsettings ? persistedMapsettings.visibleLayersIds : null,
      leakYears: persistedMapsettings ? persistedMapsettings.leakYears : null,
      visibleThematicsIds: persistedMapsettings ? persistedMapsettings.visibleThematicsIds : null,
      center: persistedMapsettings ? persistedMapsettings.center : null,
      zoom: persistedMapsettings ? persistedMapsettings.zoom : null,
      showFilters: true,
      settingArea: this._settingArea,
      settingKey: this._settingKey,
      navigatedElement: this.gisElement,
      loadFromPersistency: persistedMapsettings === null,
    });

    return mapParameters;
  }

  private getGisElementByParams(): void {
    if (!this.params) {
      this.settingsLoaded = true;
      return;
    }

    if (this.params.hierarchyElementIds) {
      this._gisService
        .getByHierarchyElement(this.params.hierarchyElementIds)
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.settingsLoaded = true))
        )
        .subscribe((gisElement) => this.setGisElement(gisElement));
    } else if (this.params.networkElementId) {
      this._gisService
        .getByNetworkElement(this.params.networkElementId)
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.settingsLoaded = true))
        )
        .subscribe((gisElement) => this.setGisElement(gisElement));
    } else if (this.params.customerId) {
      this._gisService
        .getByCustomer(this.params.customerId, { avoid: true })
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.settingsLoaded = true))
        )
        .subscribe((gisElement) => this.setGisElement(gisElement));
    } else if (this.params.leakageId) {
      this._gisService
        .getByLeak(this.params.leakageId)
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.settingsLoaded = true))
        )
        .subscribe((gisElement) => this.setGisElement(gisElement));
    } else {
      this.settingsLoaded = true;
    }
  }

  private initMap(): void {
    this.mapParameters = this.initializeMapParameters();
  }

  private setGisElement(gisElement: IGisElementDto): void {
    if (!gisElement) {
      const dialogSettings = new WlmDialogSettings({
        translateKey: `${AppModules.Map}.common.messages.missing-gis-info`,
        icon: 'warning',
      });
      this.displayMapMessage$.next(dialogSettings);
    }

    this.gisElement = gisElement;
  }
}
