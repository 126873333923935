<wlm-page-wrapper>
  <ng-container page-top-buttons>
    <wlm-grid-buttons
      *ngIf="gridSettings"
      [gridSettings]="gridSettings"
      [grid]="grid"
      [gridName]="settings.grid.gridSettingsName"
      (btnClick)="onClickGridBtns($event)"
    >
      <ng-template
        *ngIf="settings?.create"
        wlmGridButtonsThirdSlot
        let-isContainerCollapsed="isContainerCollapsed"
      >
        <button
          wlmCollapsibleButton
          [tooltip]="'common.create'"
          [showLabel]="isContainerCollapsed"
          (click)="onOpenCreate($event)"
        >
          <wlm-icon icon="add"></wlm-icon>
        </button>
      </ng-template>
      <ng-template
        *ngIf="settings.update"
        wlmGridButtonsThirdSlot
        let-isContainerCollapsed="isContainerCollapsed"
      >
        <button
          wlmCollapsibleButton
          [tooltip]="'common.edit'"
          [showLabel]="isContainerCollapsed"
          [disabled]="
            !selectedItem ||
            (settings.update.disableHook && (settings.update.disableHook(selectedItem) | async))
          "
          (click)="onOpenUpdate($event)"
        >
          <wlm-icon icon="edit"></wlm-icon>
        </button>
      </ng-template>

      <ng-template
        *ngIf="settings.delete"
        wlmGridButtonsThirdSlot
        let-isContainerCollapsed="isContainerCollapsed"
      >
        <button
          wlmCollapsibleButton
          [tooltip]="'common.delete'"
          [showLabel]="isContainerCollapsed"
          [disabled]="
            !selectedItem ||
            (settings.delete.disableHook && (settings.delete.disableHook(selectedItem) | async))
          "
          (click)="onDeleteConfirm($event)"
        >
          <wlm-icon icon="delete"></wlm-icon>
        </button>
      </ng-template>

      <ng-template
        *ngIf="additionalButtonsTemplates"
        wlmGridButtonsThirdSlot
        let-isContainerCollapsed="isContainerCollapsed"
      >
        <div *ngFor="let template of additionalButtonsTemplates" class="slot-wrapper-inner">
          <ng-container
            *ngTemplateOutlet="template; context: { isContainerCollapsed: isContainerCollapsed }"
          ></ng-container>
        </div>
      </ng-template>
    </wlm-grid-buttons>
  </ng-container>

  <ng-container page-content>
    <wlm-local-grid
      *ngIf="gridSettings"
      class="crud-grid"
      [gridSettings]="gridSettings"
      [gridData]="allEntities$ | async"
      (selectedItemChanged)="onSelectedItem($event)"
      (gridReady)="onGridReady()"
    ></wlm-local-grid>
  </ng-container>
</wlm-page-wrapper>
