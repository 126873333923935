import { Component, Input, OnInit } from '@angular/core';
import { DynamicLayoutItemSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-item-settings';
import { DynamicLayoutSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-settings';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { AutoUnsubscribe } from '@common-modules/shared/observables/auto-unsubscribe';
import { CustomerMeterDto } from '../models/customer-meter.dto';
import { CustomerDetailsService } from '../services/customer-details.service';

const COMPONENT_SELECTOR = 'wlm-customer-details-meters';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './customer-details-meters.component.html',
  styleUrls: ['./customer-details-meters.component.scss'],
})
export class CustomerDetailsMetersComponent implements OnInit {
  private _customerId: string;
  get customerId(): string {
    return this._customerId;
  }
  // First possible flow: introduce the customer id.
  @Input() set customerId(value: string) {
    this._customerId = value;

    if (this.customerId) {
      this.requirementsLoading = true;
      const subs = this._customerService.getCustomerMeters(this.customerId).subscribe((meters) => {
        this.meters = meters;
      });
      this._customerIdUnsubscribe.set(subs);
      this.initializeLayout();
    }
  }

  private _meters: CustomerMeterDto[];
  get meters(): CustomerMeterDto[] {
    return this._meters;
  }
  @Input() set meters(value: CustomerMeterDto[]) {
    this.requirementsLoading = true;
    this._meters = value;
    this.initializeLayout();
  }

  requirementsLoading = true;
  layoutLoading = true;
  layoutSettings: DynamicLayoutSettings;

  private _customerIdUnsubscribe = new AutoUnsubscribe();
  private _widgetPage = 'meters';
  private _widgetModule = 'meters';

  constructor(
    private _customerService: CustomerDetailsService,
    private _authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {}

  onLayouLoading(layoutLoading: boolean): void {
    this.layoutLoading = layoutLoading;
  }

  private initializeLayout(): void {
    let items: DynamicLayoutItemSettings[];

    if (!this.customerId) {
      return;
    }

    if (!this.meters) {
      items = [];
    } else {
      items = this.meters.map((meter) => {
        const componentName = 'MeterDetailsWidgetComponent';
        const result = {
          componentName,
          widgetInstanceKey: `${componentName}#${meter.installationId}`,
          title: meter.installationId,
          params: {
            meter,
            customerId: this.customerId,
          },
          scopeInstanceKeys: {},
        } as DynamicLayoutItemSettings;

        return result;
      });
    }

    this.layoutSettings = new DynamicLayoutSettings({
      layoutKey: null,
      layoutArea: null,
      currentUser: this._authenticationService.userCode,
      widgetPage: this._widgetPage,
      widgetModule: this._widgetModule,
      items,
    });

    this.requirementsLoading = false;
  }
}
