import { Injectable } from '@angular/core';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { DateFormats } from '@common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { HistoricalVersionDto } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-version.dto';
import { ISaveHistoricalVersionResponse } from '@common-modules/wlm-charts/core/models/historical-chart-settings/save-historical-version';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export abstract class BaseHistoricalMessagesService {
  T_SCOPE = `${AppModules.Configuration}.base-messages-service`;
  private _configTitleMsg = `${this.T_SCOPE}.error-title`;
  private _configValuesMsg = `${this.T_SCOPE}.validation.values`;
  private _currentDatesMsg = 'common.current-dates';
  private _categoryKey = 'Category';
  private _zoneKey = 'HierarchyElementId';
  private _neNameKey = 'NetworkElementName';
  private _fieldsDictionary: { [key: string]: string } = {
    HierarchyElementId: 'zone',
    Category: 'category',
    NetworkElementName: 'ne-name',
    Boundary: 'boundary',
    SmartMeter: 'smart-meter',
    LargeUser: 'large-user',
    'Lars&Sworps': 'lars-sworps',
  };

  private _newLine = of('\n');

  constructor(
    private _localizationService: LocalizationHelperService,
    private _dateHelper: DateHelperService,
    private _dialogService: DialogService
  ) {}

  abstract additionalErrorFields(error: HistoricalVersionDto): Observable<string>;

  showValidationErrorsPopup(response: ISaveHistoricalVersionResponse): void {
    const ts$ = [this._configTitleMsg, this._configValuesMsg, this._currentDatesMsg].map((msg) =>
      this._localizationService.get(msg)
    );

    let errorList = this.combineErrorLists(
      response.historicalEventErrors,
      response.relatedErrors,
      response.otherEntityErrors
    );

    forkJoin(ts$).subscribe(([titleTs, valuesTs, currentDatesTs]) => {
      let finalMessage = '';
      const errorMessages$ = [];

      if (Object.keys(errorList).length > 0) {
        this.generateMessageForErrors(errorList, currentDatesTs, errorMessages$);
      }

      forkJoin(errorMessages$).subscribe((messages) => {
        finalMessage += messages.join('\n');

        this._dialogService.showMessage(finalMessage, 'error', {
          popup: 'cross-site-errors-popup',
        });
      });
    });
  }

  private generateMessageForErrors(errors: any, currentDatesTs: any, errorMessages$: any[]) {
    var conflictedPoints = Object.keys(errors);

    conflictedPoints.forEach((pointId) => {
      const pointHeaderTs = this.getPopupErrorField('point-info-header', { ['value']: pointId });
      errorMessages$.push(pointHeaderTs);

      if (errors.hasOwnProperty(pointId)) {
        const errorList = errors[pointId];

        errorList.forEach((error) => {
          let errorFields$ = [];
          let category = '';
          let {
            entityErrorInformation,
            isIn,
            propertyId,
            customerClassTypeName,
            networkElementName,
            hierarchyElementId,
            validFrom,
            validTo,
            isLars,
            isSubtraction,
            pressureTypeId,
          } = error;

          if (entityErrorInformation) {
            const { zoneKey, categoryKey, neNameKey } =
              this.processOtherFieldsMessageLines(entityErrorInformation);
            hierarchyElementId = zoneKey;
            category = categoryKey;
            networkElementName = neNameKey;
          } else {
            category = this.calculateLocalCategory(isIn, propertyId, customerClassTypeName, isLars);
          }

          const networkElementNameTs = networkElementName
            ? this.getPopupErrorField('ne-name', { ['value']: networkElementName })
            : of(null);
          const hierarchyElementIdTs = hierarchyElementId
            ? this.getPopupErrorField('zone', { ['value']: hierarchyElementId })
            : of(null);
          const isInTs =
            isIn !== undefined ? this.getPopupErrorField(isIn ? 'is-in' : 'is-out', {}) : of(null);
          const propertyIdTs = propertyId
            ? this.getPopupErrorField('property-id', { ['value']: propertyId })
            : of(null);
          const customerClassTypeNameTs = customerClassTypeName
            ? this.getPopupErrorField('customer-class-type-name', {
                ['value']: customerClassTypeName,
              })
            : of(null);
          const pressureTypeNameTs = pressureTypeId
            ? this.getPopupErrorField(`pressure-type-${pressureTypeId}`, {})
            : of(null);
          const larsSubstractionTs =
            isLars && isSubtraction ? this.getPopupErrorField('lars-substraction', {}) : of(null);
          const larsAdditionTs =
            isLars && isSubtraction === false
              ? this.getPopupErrorField('lars-addition', {})
              : of(null);
          const sworpsSubstractionTs =
            isLars === false && isSubtraction
              ? this.getPopupErrorField('sworps-substraction', {})
              : of(null);
          const sworpsAdditionTs =
            isLars === false && isSubtraction === false
              ? this.getPopupErrorField('sworps-addition', {})
              : of(null);
          const validFromTs = validFrom
            ? this.getPopupErrorField(`valid-from`, {
                ['value']: this.formatDate(validFrom, currentDatesTs),
              })
            : of(null);
          const validToTs = validTo
            ? this.getPopupErrorField(`valid-to`, {
                ['value']: this.formatDate(validTo, currentDatesTs),
              })
            : of(null);
          const categoryTs = category ? this.getPopupErrorField(category, {}) : of(null);

          const additionalTs = this.additionalErrorFields(error as HistoricalVersionDto);

          errorFields$ = errorFields$.concat([
            networkElementNameTs,
            hierarchyElementIdTs,
            validFromTs,
            validToTs,
            categoryTs,
            isInTs,
            propertyIdTs,
            customerClassTypeNameTs,
            pressureTypeNameTs,
            larsSubstractionTs,
            larsAdditionTs,
            sworpsSubstractionTs,
            sworpsAdditionTs,
            additionalTs,
          ]);

          const errorMessage$ = forkJoin(errorFields$).pipe(
            map((items) => items.filter(Boolean).join(', '))
          );

          errorMessages$.push(errorMessage$);
        });

        errorMessages$.push(this._newLine);
      }
    });
  }

  calculateLocalCategory(isIn: any, propertyId: any, customerClassTypeName: any, isLars: any) {
    if (isIn !== undefined) {
      return 'category-boundary';
    }

    if (propertyId !== undefined) {
      return 'category-large-user';
    }

    if (customerClassTypeName !== undefined) {
      return 'category-smart-meter';
    }

    if (isLars !== undefined) {
      return 'category-lars-sworps';
    }

    return '';
  }

  processOtherFieldsMessageLines(entityErrorInformation: any) {
    //here we only extract the zone and the category fields
    const categoryInfo = entityErrorInformation.find((x) => x.key === this._categoryKey);
    const zoneInfo = entityErrorInformation.find((x) => x.key === this._zoneKey);
    const neNameInfo = entityErrorInformation.find((x) => x.key === this._neNameKey);
    let categoryKey = '';
    let zoneKey = '';
    let neNameKey = '';

    if (categoryInfo) {
      categoryKey = `${this.getProperFieldName(categoryInfo.key)}-${this.getProperFieldName(
        categoryInfo.value
      )}`;
    }

    if (zoneInfo) {
      zoneKey = zoneInfo.value;
    }

    if (neNameInfo) {
      neNameKey = neNameInfo.value;
    }

    return { zoneKey, categoryKey, neNameKey };
  }

  private combineErrorLists(
    historicalErrors: { [key: string]: any[] },
    relatedErrors: { [key: string]: any[] },
    otherErrors: { [key: string]: any[] }
  ): { [key: string]: any[] } {
    const combinedErrorList: { [key: string]: any[] } = {};

    for (const key in historicalErrors) {
      combinedErrorList[key] = historicalErrors[key];
    }

    for (const key in relatedErrors) {
      if (combinedErrorList[key]) {
        combinedErrorList[key] = combinedErrorList[key].concat(relatedErrors[key]);
      } else {
        combinedErrorList[key] = relatedErrors[key];
      }
    }

    if (otherErrors) {
      for (const key in otherErrors) {
        if (combinedErrorList[key]) {
          combinedErrorList[key] = combinedErrorList[key].concat(otherErrors[key]);
        } else {
          combinedErrorList[key] = otherErrors[key];
        }
      }
    }

    return combinedErrorList;
  }

  getProperFieldName(fieldName: string) {
    return this._fieldsDictionary[fieldName];
  }

  private getPopupErrorField(fieldKey: string, params: any): Observable<string> {
    return this._localizationService.get(`${this.T_SCOPE}.validation.fields.${fieldKey}`, params);
  }

  private formatDate(date: Date, currentDatesTs): string {
    if (this._dateHelper.isCurrentStartDate(date)) {
      return currentDatesTs.start;
    }

    if (this._dateHelper.isCurrentEndDate(date)) {
      return currentDatesTs.end;
    }

    return this._localizationService.formatDate(
      this._dateHelper.ensureDateObject(date),
      DateFormats.Date
    );
  }
}
