import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlgorithmFilterItemQuery } from '@common-modules/common-filters/components/algorithms/algorithm-filter-item/algorithm-filter-item-query';
import { AlgorithmFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/algorithm-f-adapter';
import { ElementTargetFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/element-target-f-adapter';
import { TimeAggregationFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/time-aggregation-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { FiltersPayloadStatus } from '@common-modules/dependencies/wlm-filters/filters-payload-status.enum';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { TimeAggregationEnum } from '@common-modules/shared/model/algorithm/time-aggregation.enum';
import { ElementTargetsEnum } from '@common-modules/shared/model/shared/element-targets.enum';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { TreeSettingsService } from '@common-modules/shared/services/tree-settings.service';
import { ReplaySubject } from 'rxjs';
import { FilterConditionalOverride } from '../../models/filter-conditional-override';
import { AlgorithmWizardStepSettings } from './algorithm-wizard-step-configuration';

const COMPONENT_SELECTOR = 'wlm-algorithm-selection-grid-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './algorithm-selection-grid-filter.component.html',
  styleUrls: ['./algorithm-selection-grid-filter.component.scss'],
})
export class AlgorithmSelectionGridFilterComponent
  extends BasePageFiltersComponent
  implements OnInit
{
  private _algorithmFilterConfiguration: AlgorithmWizardStepSettings;
  public get algorithmFilterConfiguration(): AlgorithmWizardStepSettings {
    return this._algorithmFilterConfiguration;
  }
  @Input() public set algorithmFilterConfiguration(value: AlgorithmWizardStepSettings) {
    this._algorithmFilterConfiguration = value;
    if (value) {
      this.enableAdditionalFilters = value.enableAdditionalFilters;

      // Update filter item settings with the specified in the config.
      Object.assign(this.algorithmItemSettings, value.algorithmSettings);
      if (this.enableAdditionalFilters) {
        Object.assign(this.targetItemSettings, value.targetSettings);
        Object.assign(this.aggregationItemSettings, value.timeAggregationSettings);
      }

      this.buildFiltersOverrides();
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }
  @Output() filtersChanged = new EventEmitter<DataBindingFilters>();

  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;

  baseFilterSettings = new BaseFilterSettings({
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
    expandedMaxWidth: '800px', // this value is adjusted to the longuest algorithm name + shortname
    disableSelectAll: true,
    disableSearch: true,
  });
  itemFilterSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  targetBaseSettings = new BaseFilterSettings({
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-1-label`,
  });
  targetItemSettings = new BaseFilterItemSettings({
    ...this.itemFilterSettings,
  });

  aggregationBaseSettings = new BaseFilterSettings({
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-2-label`,
  });
  aggregationItemSettings = new BaseFilterItemSettings({
    ...this.itemFilterSettings,
  });

  algorithmBaseSettings = new BaseFilterSettings({
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-3-label`,
    applyFiltersTextKey: `${this.T_SCOPE}.apply-label`,
    clearAllTextKey: `${this.T_SCOPE}.clear-all-label`,
    disableSearch: false,
  });
  algorithmItemSettings = new BaseFilterItemSettings({
    ...this.itemFilterSettings,
    required: false,
  });

  filterText: string;
  treeSettings: ITreeSettings = null;
  dataBindingFilters = new DataBindingFilters();
  persistFilters$ = new ReplaySubject<void>();
  allowedTimeAggregations = [TimeAggregationEnum.Base, TimeAggregationEnum.Daily];
  filtersOverrides: FilterConditionalOverride[];
  algorithmQuery = new AlgorithmFilterItemQuery({});
  enableAdditionalFilters = false;

  constructor(
    private treeSettingsService: TreeSettingsService,
    private arrayHelper: ArrayHelperService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  ngOnInit(): void {
    this.setFiltersPersistencyArea();
    if (this.algorithmFilterConfiguration.algorithmSettings.selectedIds) {
      this.persistFilters$.next();
    }
    this.buildTreeSettings();
  }

  buildKeysToComplete(): void {
    const algorithmIdFieldName = this.algorithmFilterConfiguration.algorithmSettings.fieldName;
    this.keysToComplete = [algorithmIdFieldName];
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const config = this.algorithmFilterConfiguration;
    const adapters: FilterAdapterSettings[] = [
      new AlgorithmFAdapter({
        dataBindingField: config.algorithmSettings.fieldName,
        algorithmIdFieldName: config.algorithmSettings.fieldName,
      }),
    ];

    if (this.enableAdditionalFilters) {
      adapters.push(
        new ElementTargetFAdapter({
          dataBindingField: config.targetSettings.fieldName,
          fieldName: config.targetSettings.fieldName,
          tabParameter: null,
          multiple: true, // Could be refactored
        })
      );

      adapters.push(
        new TimeAggregationFAdapter({
          dataBindingField: config.timeAggregationSettings.fieldName,
          fieldName: config.timeAggregationSettings.fieldName,
          tabParameter: null,
          multiple: true, // Could be refactored
        })
      );
    }
    return adapters;
  };

  buildFiltersOverrides(): void {
    if (!this.enableAdditionalFilters) {
      this.filtersOverrides = [];
      return;
    }

    const config = this.algorithmFilterConfiguration;
    const targetFieldName = config.targetSettings.fieldName;
    const aggregationFieldName = config.timeAggregationSettings.fieldName;
    const algorithmFieldName = config.algorithmSettings.fieldName;

    const results = [
      new FilterConditionalOverride({
        onChangedFieldName: targetFieldName,
        targetFieldName: algorithmFieldName,
        targetIsFromPersistencyFn: this.checkPersistency(
          targetFieldName,
          config.targetSettings.selectedIds
        ),
        overridePayloadFn: this.overrideAlgorithms(targetFieldName, 'targetIds'),
      }),
      new FilterConditionalOverride({
        onChangedFieldName: aggregationFieldName,
        targetFieldName: algorithmFieldName,
        targetIsFromPersistencyFn: this.checkPersistency(
          aggregationFieldName,
          config.timeAggregationSettings.selectedIds
        ),
        overridePayloadFn: this.overrideAlgorithms(aggregationFieldName, 'timeAggregationIds'),
      }),
    ];
    this.filtersOverrides = results;
  }

  private checkPersistency =
    (sourceFieldName: string, selectedIds: number[]) =>
    (payload: FiltersPayload): boolean => {
      const currentIds = payload.getSelectedIds<number>(sourceFieldName);
      const persistedIds = selectedIds ?? [];
      const areSame = this.arrayHelper.areSame<number>(currentIds, persistedIds);
      return areSame;
    };

  private overrideAlgorithms = (sourceFieldName: string, algorithmQueryFieldName: string) => {
    return (
      payload: FiltersPayload,
      eventType: 'apply' | 'filter',
      changes: Map<string, boolean>
    ): FiltersPayload => {
      if (
        eventType === 'apply' ||
        (eventType === 'filter' &&
          payload.status.get(sourceFieldName) === FiltersPayloadStatus.Initial)
      ) {
        // Add values to query.
        const selectedIds = payload.getSelectedIds(sourceFieldName);
        this.setAlgorithmQuery({
          [algorithmQueryFieldName]: selectedIds,
        });

        // Update payload.
        const newPayload = this.resetAlgorithmPayload(payload);
        return newPayload;
      } else if (eventType === 'filter' && changes.get(sourceFieldName)) {
        // Update payload.
        const newPayload = this.resetAlgorithmPayload(payload);
        return newPayload;
      }
      return payload;
    };
  };

  private resetAlgorithmPayload(payload: FiltersPayload): FiltersPayload {
    const { fieldName, selectedIds } = this.algorithmFilterConfiguration.algorithmSettings;
    const defaultTreeFilter = new BasicFilter(fieldName, selectedIds);
    const newPayload = payload.clone();
    newPayload.addField(fieldName, defaultTreeFilter);
    return newPayload;
  }

  private setAlgorithmQuery(data: AlgorithmFilterItemQuery): void {
    this.algorithmQuery = { ...this.algorithmQuery, ...data };
  }

  protected setFiltersPersistencyArea(): void {
    const { persistencyArea } = this.algorithmFilterConfiguration;
    this.algorithmBaseSettings.persistencyArea = persistencyArea;
    this.targetBaseSettings.persistencyArea = persistencyArea;
    this.aggregationBaseSettings.persistencyArea = persistencyArea;
  }

  buildTreeSettings(): void {
    // Settings props to undefined, as this is the only grid that does not define them.
    this.treeSettingsService
      .buildTreeSettings({
        minLenghtToSearch: 1,
        displayableField: undefined,
        focusDelay: undefined,
        showRecursiveToggle: undefined,
        showSelectedNodesChips: undefined,
      })
      .subscribe((settings) => (this.treeSettings = settings));
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }

  onGlobalClearAll(): void {
    const config = this.algorithmFilterConfiguration;
    if (this.enableAdditionalFilters && config.targetSettings && config.timeAggregationSettings) {
      const { payload } = this.currentFilterResults;
      const targetFieldName = config.targetSettings.fieldName;
      const aggregationFieldName = config.timeAggregationSettings.fieldName;

      const targetIds = payload.getSelectedIds<ElementTargetsEnum>(targetFieldName);
      const timeAggregationIds = payload.getSelectedIds<TimeAggregationEnum>(aggregationFieldName);
      this.setAlgorithmQuery({
        targetIds,
        timeAggregationIds,
      });
    }
  }
}
