// prettier-ignore
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpCacheService } from '@common-modules/cache/http-cache/http-cache.service';
import { AlgorithmWizardStepSettings } from '@common-modules/common-filters/filters/algorithm-selection-grid-filter/algorithm-wizard-step-configuration';
import { BaseWizardStepComponent } from '@common-modules/dependencies/shared/base-wizard-step.component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { IGridSettings } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { GridBtnsEvent } from '@common-modules/shared/grid-buttons/models/grid-btns-event';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { IAlgorithmDto } from '@common-modules/shared/model/algorithm/algorithm.dto';
import { ElementTargetsEnum } from '@common-modules/shared/model/shared/element-targets.enum';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { LocalGridComponent } from '@common-modules/wlm-grid/local-grid/local-grid.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';

const COMPONENT_SELECTOR = 'wlm-algorithm-selection-grid';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './algorithm-selection-grid.component.html',
  styleUrls: ['./algorithm-selection-grid.component.scss'],
  providers: [
    {
      provide: BaseWizardStepComponent,
      useExisting: forwardRef(() => AlgorithmSelectionGridComponent),
    },
  ],
})
export class AlgorithmSelectionGridComponent extends BaseWizardStepComponent implements OnInit {
  @ViewChild(LocalGridComponent) localGrid: LocalGridComponent;
  @Output() selectedAlgorithmsChange = new EventEmitter<IAlgorithmDto[]>();
  @Output() selectedTargetChange = new EventEmitter<ElementTargetsEnum>();
  //  algorithmFilterConfiguration: AlgorithmWizardStepSettings;

  private _algorithmFilterConfiguration: AlgorithmWizardStepSettings;
  public get algorithmFilterConfiguration(): AlgorithmWizardStepSettings {
    return this._algorithmFilterConfiguration;
  }
  @Input() public set algorithmFilterConfiguration(v: AlgorithmWizardStepSettings) {
    if (v) {
      this.enableAdditionalFilters = v.enableAdditionalFilters;
    }
    this._algorithmFilterConfiguration = v;
    this.subscribeToResetWizard(this.resetHandler$);
  }

  @Input() resetHandler$: Observable<boolean>;
  enableAdditionalFilters = false;
  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;
  gridSettings: IGridSettings;
  defaultFilters: DataBindingFilters;
  filters: DataBindingFilters;
  gridFiltersForBinding: DataBindingFilters;
  selectedAlgorithms: IAlgorithmDto[];
  types = [1, 2, 4];
  entityTypeFieldName = 'entityTypeId';
  algorithmIdFieldName = 'algorithmShortName';
  clearAll$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  defaultAlgorithms: IAlgorithmDto[];
  filteredAlgorithms: string[];
  data: IAlgorithmDto[];
  algorithmDefinitionKey = `${AppModules.Algorithms}.algorithm-names`;
  gridName = 'AlgorithmSelection';
  stepKey = 'algorithm-step';
  selectedTargetId: ElementTargetsEnum;

  constructor(
    private _gridSettingsService: GridSettingsService,
    private _globalsService: GlobalsService,
    private _objectHelperService: ObjectHelperService,
    private _localization: LocalizationHelperService,
    private _cacheService: HttpCacheService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeGrid();
  }

  initializeGrid() {
    forkJoin({
      gridSettings: this._gridSettingsService.getGridSettingsByName('AlgorithmSelection'),
      algorithms: this._globalsService.getAlgorithms(),
      algorithmsTs: this._localization.get(this.algorithmDefinitionKey),
    }).subscribe(({ gridSettings, algorithms, algorithmsTs }) => {
      this.gridSettings = gridSettings;
      const baseAlgorithms = algorithms.filter(
        (x) => x.enable === true && this.types.includes(x.entityTypeId)
      );
      baseAlgorithms.forEach((alg) => {
        alg.algorithmDescription = algorithmsTs[alg.algorithmShortName];
      });

      this.defaultAlgorithms = this._objectHelperService.sortObjectArray(
        baseAlgorithms,
        'algorithmShortName'
      );
      this.filteredAlgorithms = this.filteredAlgorithms ?? [];
      this.loadGrid();
      if (this.filteredAlgorithms?.length > 0) {
        this.emitPersistedValue();
      }
    });
  }

  loadGrid() {
    this.data = this.defaultAlgorithms.filter((x) =>
      this.filteredAlgorithms.includes(x[this.algorithmIdFieldName])
    );
    this.selectedAlgorithmsChange.emit(this.data);
    this.selectedTargetChange.emit(this.selectedTargetId);
  }

  getDataBindingFilters(dataBindingFilters: DataBindingFilters) {
    this.filters = dataBindingFilters;
    const newFilterValue = dataBindingFilters.filters.get(this.algorithmIdFieldName);
    this.filteredAlgorithms = newFilterValue
      ? this.getValuesFromDatabinding<string>(newFilterValue)
      : [];
    // Obtain selected target, if possible.
    if (this.enableAdditionalFilters) {
      const targetFieldName = this.algorithmFilterConfiguration.targetSettings.fieldName;
      if (targetFieldName) {
        const targetFilter = dataBindingFilters.filters.get(targetFieldName) as BasicFilter;
        // It is always single selection.
        this.selectedTargetId = targetFilter.value as number;
      }
    }
  }

  getValuesFromDatabinding<T>(newFilterValue: IFilter): T[] {
    const mainFilter = newFilterValue.getFilters();
    const itemFilters = (mainFilter.filters[0] as CompositeFilterDescriptor).filters;
    return itemFilters.map((x) => (x as FilterDescriptor).value);
  }

  private subscribeToResetWizard(resetHandler$: Observable<boolean>) {
    if (resetHandler$) {
      resetHandler$.pipe(untilDestroyed(this)).subscribe((hasToReset) => {
        if (hasToReset) {
          this._cacheService
            .clearContainsInUrl(this.algorithmFilterConfiguration.persistencyArea) // clear selected rows in cache
            .then((success) => {
              this.clearAll$.next(); // notify all filters to perform clearAll
              this.localGrid.clearFilters();
              this.filteredAlgorithms = [];
              this.loadGrid(); // reload grid
              this.selectedAlgorithmsChange.emit([]);
              this.selectedTargetChange.emit(null);
              this.resetCompleteHandler$.next();
            });
        }
      });
    }
  }

  onClickGridBtns(event: GridBtnsEvent): void {
    this.onClearAllFilters();
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  emitPersistedValue() {
    if (this.hasToEmitPersistencyValue) {
      this.hasToEmitPersistencyValue = false;
      this.selectedAlgorithmsChange.emit(this.data);
      this.selectedTargetChange.emit(this.selectedTargetId);
      this.applyPersistedValue$.next();
    }
  }
}
