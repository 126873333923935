import { Injectable, Injector } from '@angular/core';
import {
  AlarmChartQueryDto,
  AlgorithmQuery,
} from '@common-modules/dependencies/alarms/alarm-chart-query.dto';
import { AlarmClassTypes } from '@common-modules/dependencies/alarms/alarm-class-types.enum';
import { AlarmDto } from '@common-modules/dependencies/alarms/alarm.dto';
import { CVService } from '@common-modules/dependencies/cv/cv.service';
import { CVChartSerieDto } from '@common-modules/dependencies/cv/models/cv-chart-serie.dto';
import { CVThresholdDto } from '@common-modules/dependencies/cv/models/cv-threshold.dto';
import { ThresholdDto } from '@common-modules/dependencies/cv/models/threshold.dto';
import { MVService } from '@common-modules/dependencies/mv/mv.service';
import { CVShortQueryDto } from '@common-modules/dependencies/shared/model/cv-short-query.dto';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { UtilsHelperService } from '@common-modules/shared/helpers/utils-helper.service';
import { DateFormats } from '@common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { TimeAggregationEnum } from '@common-modules/shared/model/algorithm/time-aggregation.enum';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { MVAlarmsQueryDto } from '@common-modules/shared/model/mv/mv-alarm-query.dto';
import { MVChartPointDto } from '@common-modules/shared/model/mv/mv-chart-point.dto';
import { MVChartSerieDto } from '@common-modules/shared/model/mv/mv-chart-serie.dto';
import { MVThresholdDto } from '@common-modules/shared/model/mv/mv-threshold.dto';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { AlarmsService } from '@common-modules/shared/services/alarms.service';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { LogScope } from '@common-modules/shared/wlm-log/log-scope';
import { LogService } from '@common-modules/shared/wlm-log/log.service';
import { GCartesianChartSerie } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-cartesian-chart-series';
import { GChartColoredPiece } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-colored-piece';
import { GChartLineStyle } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-line-style';
import { GChartSerieDataPoint } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-serie-data-point';
import { GChartSerieMarkArea } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-serie-mark-area';
import { GChartSerieMarkAreaItem } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-serie-mark-area-item';
import { GChartTextStyles } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-text-styles';
import { GChartVisualMap } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-visual-map';
import { GChartVisualMapPiece } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-visual-map-piece';
import { GenericCartesianChartSettings } from '@common-modules/wlm-charts/core/models/generic-chart-settings/generic-cartesian-chart-settings';
import { TrendChartDataParameters } from '@common-modules/wlm-charts/core/models/trend-chart-data-parameters';
import { BaseCartesianChartService } from '@common-modules/wlm-charts/core/services/base-cartesian-chart.service';
import { EchartsSettingsMapperService } from '@common-modules/wlm-charts/core/services/echarts-settings-mapper.service';
import { EChartsOption } from 'echarts';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { ChartSerieTypeEnum } from '@common-modules/wlm-charts/core/models/chart-serie-type.enum';
import { GChartItemStyle } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-item-style';
import { TooltipFilterMethodEnum } from '@common-modules/wlm-charts/core/models/generic-chart-settings/tooltip-filter-method-enum';
import { GenericChartUnselectedSeries } from '@common-modules/wlm-charts/core/models/generic-chart-unselected-series';
import { asEnumerable } from 'linq-es2015';
import { IAlarmsChartInstanceScope } from './alarm-chart-instance-scope';
import { AlarmsChartParams } from './alarm-chart-params';
import { AlarmChartTooltipLabels, AlarmChartTooltipService } from './alarm-chart-tooltip.service';
import { AlarmChartData } from './alarms-chart-data';

@Injectable()
export class AlarmsChartService extends BaseCartesianChartService {
  private _timeAggregationForSignals = TimeAggregationEnum.Base;
  private _thresholdId = 'THRESHOLD';
  private _defaultBackgroundLighten = 0.7;
  private _ackAlarmBackgroundColor = '#7d7d7d';
  private _seriesToHide: GenericChartUnselectedSeries;
  private _thresholdWarningKey = 'hide-thresholds-message';

  // Instance scopes. Data that only applies to a specific AlarmsChartService.getData call.
  private _scopes: { [key: string]: IAlarmsChartInstanceScope } = {};

  private _tooltipLabels: AlarmChartTooltipLabels;
  private _i18nLoaded = false;
  readonly T_SCOPE = `${AppModules.Alarms}.alarms-chart-service`;

  constructor(
    injector: Injector,
    private readonly _dateHelper: DateHelperService,
    private readonly _uomService: UoMService,
    private readonly _logService: LogService,
    private readonly _alarmsService: AlarmsService,
    private readonly _cvService: CVService,
    private readonly _mvService: MVService,
    private readonly _utilsService: UtilsHelperService,
    private readonly _localization: LocalizationHelperService,
    private readonly _arrayHelper: ArrayHelperService,
    private readonly _nativeChartService: EchartsSettingsMapperService,
    private readonly _alarmChartTooltipService: AlarmChartTooltipService,
    private readonly _dialogService: DialogService
  ) {
    super(injector);

    this.genericChartServiceLoaded$.subscribe((loaded) => {
      if (!loaded) {
        return;
      }
    });
  }

  setSerieNamesUnselected(serieNames: GenericChartUnselectedSeries): void {
    this._seriesToHide = serieNames;
  }

  getAlarmsPeriod(alarms: AlarmDto[], alarmClassId: number): DateRange {
    const isAlgorithmSeries = this._alarmsService.alarmClassIsAlgoritm(alarmClassId);
    const minDate = this.calculateMinDate(alarms, isAlgorithmSeries);
    const maxDate = this.calculateMaxDate(alarms, isAlgorithmSeries);

    return new DateRange(
      this._dateHelper.fromApiFormat(minDate),
      this._dateHelper.fromApiFormat(maxDate)
    );
  }

  getData(params: TrendChartDataParameters): Observable<any> {
    const alarmsParams: AlarmsChartParams = params.queryParams;
    const scopeKey = params.scopeKey;
    if (!scopeKey) {
      this._logService.error({ msg: 'You must generate an unique scopeKey.' });
      return of(null);
    }
    this.resetScope(scopeKey);

    return this.getTranslations().pipe(
      switchMap(() => {
        if (!alarmsParams.alarms.length) {
          return of(null);
        }

        const isAlgorithmSeries = this._alarmsService.alarmClassIsAlgoritm(
          alarmsParams.alarmClassId
        );
        const minDate = params.startDate;
        const maxDate = params.endDate;

        // Get main series.

        let alarmSeries$;
        if (alarmsParams?.alarmClassId === AlarmClassTypes.Others) {
          const algorithmsQuery = alarmsParams.alarms
            .filter((x) => x.algorithmShortName)
            .map(
              (x) =>
                new AlgorithmQuery({
                  algorithmShortName: x.algorithmShortName,
                  elementId: alarmsParams.elementId,
                })
            );

          const algorithms = algorithmsQuery.reduce((acc, current) => {
            const index = acc.findIndex(
              (a) =>
                a.algorithmShortName === current.algorithmShortName &&
                a.elementId === current.elementId
            );

            if (index < 0) {
              acc.push(current);
            }

            return acc;
          }, [] as AlgorithmQuery[]);

          const signalIds = alarmsParams.alarms.filter((x) => x.signalId).map((a) => a.signalId);

          const alarmChartQuery = new AlarmChartQueryDto({
            algorithms,
            startDate: minDate,
            endDate: maxDate,
            signalIds,
          });

          alarmSeries$ = this._alarmsService.getChartByAlarms(alarmChartQuery);
        } else if (isAlgorithmSeries) {
          const shortNames = asEnumerable(
            alarmsParams.alarms.filter((x) => x.algorithmShortName).map((x) => x.algorithmShortName)
          )
            .Distinct()
            .ToArray();
          alarmSeries$ = this._cvService.getChart(
            new CVShortQueryDto(minDate, maxDate, [alarmsParams.elementId], shortNames)
          );
        } else {
          alarmSeries$ = this._mvService.getChartByAlarms(
            new MVAlarmsQueryDto({
              startDate: this._dateHelper.toApiFormat(minDate),
              endDate: this._dateHelper.toApiFormat(maxDate),
              alarmsIds: alarmsParams.alarms
                .filter((x) => !x.algorithmShortName)
                .map((a) => a.alarmId),
            })
          );
        }

        this.setScopeData(scopeKey, {
          alarms: alarmsParams.alarms,
          minDate,
          maxDate,
          isAlgorithmSeries,
          alarmClassId: alarmsParams.alarmClassId,
          elementId: alarmsParams.elementId,
        });

        return forkJoin({
          series: alarmSeries$,
          alarmSeverities: this._alarmsService.getAlarmSeverityTypes(),
          scopeKey: of(params.scopeKey),
        });
      })
    );
  }

  public mapDataToGenericSettings(data: AlarmChartData): Observable<GenericCartesianChartSettings> {
    if (!data) {
      return of(null);
    }
    const { series, alarmSeverities, scopeKey } = data;

    const scope = this.getScope(scopeKey);
    const { alarms, minDate, maxDate } = scope;

    // Hash colors for easy access.
    const severityColors = alarmSeverities.reduce((accum, current) => {
      accum[current.severityId] = current.severityColor;
      return accum;
    }, {});
    const mainSeriesNames = (series as any).map((serie) => this.getSerieName(serie));

    this.setScopeData(scopeKey, { mainSeriesNames, severityColors, series });

    if (!series.length) {
      this.setScopeData(scopeKey, {
        mainChartSeriesUnprocessed: [],
        allChartSeriesUnprocessed: [],
        settings: {} as any,
      });
      return of(null);
    }

    // Obtain main series, and calculate colored pieces.

    let series$: Observable<GCartesianChartSerie>[] = [];
    let firstSerieColored = false;
    if (series.length) {
      const cvSeries = series.filter((x) => (x as CVChartSerieDto).algorithmShortName);
      const mvSeries = series.filter((x) => (x as MVChartSerieDto).signalId);

      if (cvSeries.length) {
        // Only one trend is shown for algorithms, so all alarms are painted into it.
        const coloredPieces = this.calculateColoredPieces(alarms, severityColors, maxDate);
        series$ = series$.concat(
          (cvSeries as CVChartSerieDto[]).map((serie) => {
            const result = this.getCVGCartesianChartSerie(
              serie,
              minDate,
              maxDate,
              !firstSerieColored ? coloredPieces : []
            );
            firstSerieColored = true;
            return result;
          })
        );
      }
      if (mvSeries.length) {
        const currentAlarms = this.getAlarmsFromMVApiSeries(series as MVChartSerieDto[], alarms);
        const coloredPieces = this.calculateColoredPieces(currentAlarms, severityColors, maxDate);
        const sortedDates = this.getMVSortedDates(series as MVChartSerieDto[]);
        series$ = series$.concat(
          (series as MVChartSerieDto[]).map((serie) => {
            const result = this.getMVGCartesianChartSerie(
              serie,
              minDate,
              maxDate,
              !firstSerieColored ? coloredPieces : [],
              serie.signalId,
              sortedDates
            );
            firstSerieColored = true;
            return result;
          })
        );
      }
    }

    // We need to know the signalIds of the series that have been loaded in order to load the thresholds.
    // Therefore, the chart is initially loaded without thresholds, and they are then appended on chart init.
    return forkJoin(series$).pipe(
      map((series) => {
        this.assignColors(series);

        const tooltip = this._alarmChartTooltipService.buildTooltip({
          alarms,
          severityColors,
          mainSeriesNames,
          ackAlarmBackgroundColor: this._ackAlarmBackgroundColor,
          getScope: () => this.getScope(scopeKey),
          getSerieName: this.getSerieName,
          tooltipLabels: this._tooltipLabels,
          getHiddenSerieNames: () => this._seriesToHide,
        });
        const settings = new GenericCartesianChartSettings({
          series,
          tooltip,
        });

        // We need to keep:
        // - mainChartSeriesUnprocessed, so we can separate main from main + threshold series.
        // - allChartSeriesUnprocessed. When threshold are loaded, they will be appended and all series will be processed
        //   at once. This is so things like the min/maxes can be recalculated.
        const seriesCloned = this.genericChartService.cloneSeries(series);
        this.setScopeData(scopeKey, {
          mainChartSeriesUnprocessed: seriesCloned,
          allChartSeriesUnprocessed: seriesCloned,
          settings,
        });

        return settings;
      })
    );
  }

  private assignColors(series: GCartesianChartSerie[]): void {
    series.forEach((serie, index) => {
      const color = this.genericChartService.assignColor(index);
      if (serie.lineStyle) {
        serie.lineStyle.color = color;
      } else {
        serie.lineStyle = new GChartItemStyle({ color });
      }
    });
  }

  private getMVSortedDates(series: MVChartSerieDto[]): Date[] {
    const points: MVChartPointDto[] = series.reduce(
      (allPoints, serie) => allPoints.concat(serie.points),
      []
    );
    const dates = points.map((point) => this._dateHelper.fromApiFormat(point.measureTimestamp));
    const sorted = this._dateHelper.sortDates(dates);
    return sorted;
  }

  /**
   * Start date is the 00:00:00 of the day prior to the one in which the first
   * non-acknowledged alarm of that type became 'Active' in the zone.
   */
  private calculateMinDate(alarms: AlarmDto[], isAlgorithmSeries: boolean): string {
    // Dates are in an string API format, so they should be sortable as strings.
    // If dates are localized before, this method will not work correctly.
    const sortedAsc = alarms
      .filter((a) => !a.isAcknowledge)
      .map((a) => a.alarmStartDateTime)
      .sort(this.sortStrDatesAsc);
    if (!sortedAsc.length) {
      this._logService.error({ msg: 'Cannot calculate startDate: all alarms are ACK.' });
      return null;
    }
    let firstDate = this._dateHelper.fromApiFormat(sortedAsc[0]);
    if (!firstDate) {
      this._logService.error({ msg: 'Cannot calculate startDate: possible format error.' });
      return null;
    }
    // Go to the previous day, only if alarms are from algorithms.
    if (isAlgorithmSeries) {
      firstDate = this._dateHelper.addDays(firstDate, -1);
    }
    firstDate = this._dateHelper.truncateDate(firstDate);
    return this._dateHelper.toApiFormat(firstDate);
  }

  /**
   * End Date is the 23:00:00 of the day after the one in which the last
   * non-acknowledged alarm of that type became 'Not Active' in that zone.
   */
  private calculateMaxDate(alarms: AlarmDto[], isAlgorithmSeries: boolean): string {
    // If any of the alarms have a null enddate, the last value painted for the trends
    // will be for the last date processed (yesterday in the case we would be in PRO environment).
    const activeNonAckAlarm = alarms.find((a) => !a.isAcknowledge && !a.alarmEndDateTime);
    if (activeNonAckAlarm) {
      return null;
    }
    // Dates are in an string API format, so they should be sortable as strings.
    // If dates are localized before, this method will not work correctly.
    const sortedAsc = alarms
      .filter((a) => !a.isAcknowledge && a.alarmEndDateTime)
      .map((a) => a.alarmEndDateTime)
      .sort(this.sortStrDatesAsc);
    let lastDate;
    if (!sortedAsc.length) {
      // Manually set the last processed day (yesterday)
      lastDate = new Date();
      lastDate = this._dateHelper.addDays(lastDate, -1);
    } else {
      lastDate = this._dateHelper.fromApiFormat(sortedAsc[sortedAsc.length - 1]);
      if (!lastDate) {
        this._logService.error({ msg: 'Cannot calculate endDate: possible format error.' });
        return null;
      }
      if (isAlgorithmSeries) {
        // Only add one day if the alarms are from algortims.
        lastDate = this._dateHelper.addDays(lastDate, 1);
      }
    }

    // Go to the previous day and set time to 00:00
    lastDate = this._dateHelper.setTime(lastDate, 23, 59);
    return this._dateHelper.toApiFormat(lastDate);
  }

  private mapCVThresholdToSerie = (
    threshold: CVThresholdDto,
    severityColors: { [key: number]: string },
    referenceSerie: CVChartSerieDto
  ): Observable<GCartesianChartSerie> => {
    let result$ = this.mapThresholdToSerie<CVThresholdDto, CVChartSerieDto>(
      threshold,
      severityColors,
      referenceSerie,
      this.getUnitFromCVSerie
    );

    result$ = result$.pipe(
      map((serie) => {
        serie.additionalParams.algorithmShortName = threshold.algorithmShortName;
        return serie;
      })
    );

    return result$;
  };

  private mapMVThresholdToSerie = (
    threshold: MVThresholdDto,
    severityColors: { [key: number]: string },
    referenceSerie: MVChartSerieDto
  ): Observable<GCartesianChartSerie> => {
    let result$ = this.mapThresholdToSerie<MVThresholdDto, MVChartSerieDto>(
      threshold,
      severityColors,
      referenceSerie,
      this.getUnitFromMVSerie
    );

    result$ = result$.pipe(
      map((serie) => {
        serie.additionalParams.signalId = threshold.signalId;
        return serie;
      })
    );

    return result$;
  };

  private mapThresholdToSerie<T1 extends ThresholdDto, T2 extends { dimensionTypeId: number }>(
    threshold: T1,
    severityColors: { [key: number]: string },
    referenceSerie: T2,
    getUnitFromSerie: (serie: T2) => Observable<UnitTypeConversionViewDto>
  ): Observable<GCartesianChartSerie> {
    const dataPoints = threshold.values.map(
      (item) =>
        ({
          pointCategory: this._dateHelper.fromApiFormat(item.date),
          pointValue: item.value,
        } as GChartSerieDataPoint)
    );
    const trendColor = severityColors[threshold.severityId];
    return getUnitFromSerie(referenceSerie).pipe(
      map((unit) => {
        const timestamp = new Date().getTime();

        const isSignal = (referenceSerie as any as MVChartSerieDto).signalId;

        const showInUtc = isSignal
          ? false
          : (referenceSerie as any as CVChartSerieDto).timeAggregationId !==
            TimeAggregationEnum.Base;

        const showAlwaysInTooltip = isSignal
          ? false
          : (referenceSerie as any as CVChartSerieDto).timeAggregationId !==
            TimeAggregationEnum.Base;

        const serie = new GCartesianChartSerie({
          // Set id with a custom prefix so we can identify the thresholds later.
          id: `${this._thresholdId}-${timestamp}`,
          dataPoints,
          type: ChartSerieTypeEnum.Line,
          yAxisIndex: 0,
          xAxisIndex: 0,
          xAxisWLMDimensionTypeId: DimensionTypesEnum.NA,
          yAxisWLMDimensionTypeId: referenceSerie.dimensionTypeId,
          yAxisWLMUnitTypeIdFrom: threshold.unitTypeId,
          yAxisWLMUnitTypeIdTo: unit.unitTypeToId,
          yAxisName: unit.unitTypeToDescription,
          showInUtc,
          showAlwaysInTooltip,
          lineStyle: new GChartLineStyle({
            type: 'dashed',
            color: trendColor,
          }),
          symbol: 'none',
          showAlwaysFilterMethod: this.getShowAlwaysFilterMethodBySerie(referenceSerie as any),

          additionalParams: {
            envelopeDescription: threshold.envelopeDescription,
          },
        });
        return serie;
      })
    );
  }

  private getUnitFromMVSerie = (serie: MVChartSerieDto): Observable<UnitTypeConversionViewDto> => {
    return this._uomService
      .getByParams(serie.dimensionTypeId, this._timeAggregationForSignals)
      .pipe(take(1));
  };

  private getUnitFromCVSerie = (serie: CVChartSerieDto): Observable<UnitTypeConversionViewDto> => {
    return this._uomService
      .getByParams(serie.dimensionTypeId, serie.timeAggregationId, serie.hierarchyElementTypeId)
      .pipe(take(1));
  };

  private getCVGCartesianChartSerie(
    serie: CVChartSerieDto,
    minDate: Date,
    maxDate: Date,
    coloredPieces: GChartColoredPiece[] = []
  ): Observable<GCartesianChartSerie> {
    // Check if the series must be converted or shown in UTC.
    const showInUtc = this._cvService.showInUTC(serie);
    const dataPoints = serie.points.map((point) => {
      const dataPoint: GChartSerieDataPoint = {
        pointCategory: this._dateHelper.fromApiFormat(point.referenceTimestamp),
        pointValue: point.value,
      };
      return dataPoint;
    });

    return this.getUnitFromCVSerie(serie).pipe(
      map((unit) => {
        const cartesianChartSerie = new GCartesianChartSerie({
          dataPoints,
          name: this.getSerieName(serie),
          type: ChartSerieTypeEnum.Line,
          xAxisWLMDimensionTypeId: DimensionTypesEnum.NA,
          yAxisWLMDimensionTypeId: serie.dimensionTypeId,
          yAxisWLMUnitTypeIdFrom: unit.unitTypeFromId,
          yAxisWLMUnitTypeIdTo: unit.unitTypeToId,
          yAxisName: unit.unitTypeToDescription,
          xMin: minDate,
          xMax: maxDate,
          showInUtc,
          additionalParams: {
            isAlgorithm: true,
            algorithmShortName: serie.algorithmShortName,
          },
          showAlwaysFilterMethod: this.getShowAlwaysFilterMethodBySerie(serie),
        });

        if (coloredPieces) {
          cartesianChartSerie.markArea = this.buildColoredBackground(coloredPieces);
          // TODO: decide approach
          // cartesianChartSerie.visualMap = this.buildColoredTrend(coloredPieces);
        }

        return cartesianChartSerie;
      })
    );
  }

  private getMVGCartesianChartSerie(
    serie: MVChartSerieDto,
    minDate: Date,
    maxDate: Date,
    coloredPieces: GChartColoredPiece[],
    signalId: number,
    sortedDates: Date[]
  ): Observable<GCartesianChartSerie> {
    const dataPoints = serie.points.map((point) => {
      const dataPoint: GChartSerieDataPoint = {
        pointCategory: this._dateHelper.fromApiFormat(point.measureTimestamp),
        pointValue: point.value,
      };
      return dataPoint;
    });

    let lastProcessedDay = maxDate;
    if (!lastProcessedDay) {
      lastProcessedDay = sortedDates.length ? sortedDates[sortedDates.length - 1] : new Date();
    }

    return this.getUnitFromMVSerie(serie).pipe(
      map((unit) => {
        const cartesianChartSerie = new GCartesianChartSerie({
          dataPoints,
          name: this.getSerieName(serie),
          type: ChartSerieTypeEnum.Line,
          xAxisWLMDimensionTypeId: DimensionTypesEnum.NA,
          yAxisWLMDimensionTypeId: serie.dimensionTypeId,
          yAxisWLMUnitTypeIdFrom: unit.unitTypeFromId,
          yAxisWLMUnitTypeIdTo: unit.unitTypeToId,
          yAxisName: unit.unitTypeToDescription,
          xMin: minDate,
          xMax: maxDate,
          additionalParams: {
            signalId,
            isAlgorithm: false,
          },
          showInUtc: serie.isFlatten,
          showAlwaysFilterMethod: this.getShowAlwaysFilterMethodBySerie(serie),
        });

        if (coloredPieces) {
          cartesianChartSerie.markArea = this.buildColoredBackground(coloredPieces);
          // TODO: decide approach
          // cartesianChartSerie.visualMap = this.buildColoredTrend(coloredPieces);
        }

        return cartesianChartSerie;
      })
    );
  }

  private getSerieName(serie: any): string {
    if (serie.algorithmShortName) {
      return serie.algorithmShortName;
    } else if (serie.pointDescription) {
      return serie.pointDescription;
    } else {
      // Note that threshold series do not have a name but a generated id
      // that is not visible in tooltip.
      return serie.name;
    }
  }

  private calculateColoredPieces(
    inputAlarms: AlarmDto[],
    severityColors: { [key: number]: string },
    inputMaxDate: Date
  ): GChartColoredPiece[] {
    if (!inputAlarms || !inputAlarms.length) {
      return [];
    }
    const maxDate = this._dateHelper.toApiFormat(inputMaxDate);
    let alarms = [...inputAlarms];

    // Sort from less to most relevant (Ack firt, then by severity ASC).
    alarms = alarms.sort((a1, a2) => {
      if (a1.isAcknowledge) return -1;
      if (a2.isAcknowledge) return 1;
      return a1.alarmSeverity <= a2.alarmSeverity ? -1 : 1;
    });

    let pieces = [];
    alarms.forEach((alarm) => {
      const alarmStart = alarm.alarmStartDateTime;
      const alarmEnd = alarm.alarmEndDateTime ?? maxDate;

      if (alarmStart > alarmEnd) {
        return;
      }

      pieces.forEach((piece, index) => {
        if (!piece) {
          return;
        }
        if (piece.start >= alarmStart && piece.end <= alarmEnd) {
          // The alarm completely surrounds the piece => remove piece.
          delete pieces[index];
        } else if (piece.end <= alarmEnd && piece.end >= alarmStart) {
          // Piece is trimmed by the right.
          piece.end = alarmStart;
        } else if (piece.start <= alarmStart && piece.end >= alarmEnd) {
          // The piece completely surrounds the alarm => two pieces are created.
          pieces.push(
            new GChartColoredPiece({
              start: piece.start,
              end: alarmStart,
              color: piece.color,
              entityId: piece.entityId,
            })
          );
          pieces.push(
            new GChartColoredPiece({
              start: alarmEnd,
              end: piece.end,
              color: piece.color,
              entityId: piece.entityId,
            })
          );
          delete pieces[index];
        } else if (piece.start <= alarmEnd && piece.end >= alarmEnd) {
          // Piece is trimmed by the left.
          piece.start = alarmEnd;
        }
      });

      // Add the current alarm as a piece.
      pieces.push(
        new GChartColoredPiece({
          start: alarmStart,
          end: alarmEnd,
          entityId: alarm.alarmId,
          color: alarm.isAcknowledge
            ? this._ackAlarmBackgroundColor
            : severityColors[alarm.alarmSeverity],
        })
      );
    });

    pieces = pieces.filter(Boolean).filter((piece) => piece.start !== piece.end);
    // Map all pieces dates to Date. If we leave them like strings, the chart will paint them in UTC instead of local.
    pieces.forEach((piece) => {
      piece.start = this._dateHelper.fromApiFormat(piece.start);
      piece.end = this._dateHelper.fromApiFormat(piece.end);
    });
    return pieces;
  }

  private buildColoredBackground(pieces: GChartColoredPiece[]): GChartSerieMarkArea {
    const data: [GChartSerieMarkAreaItem, GChartSerieMarkAreaItem][] = pieces.map((piece) => [
      new GChartSerieMarkAreaItem({
        xAxis: piece.start,
        itemStyle: new GChartTextStyles({
          color: this._utilsService.fadeColor(piece.color, this._defaultBackgroundLighten),
        }),
      }),
      new GChartSerieMarkAreaItem({
        xAxis: piece.end,
      }),
    ]);
    const results = new GChartSerieMarkArea({
      data,
    });
    return results;
  }

  // Serves as a starting point when considering to paint the trends.
  private buildColoredTrend(pieces: GChartColoredPiece[]): GChartVisualMap {
    const data = pieces.map((piece) => {
      return new GChartVisualMapPiece({
        gt: this._dateHelper.fromApiFormat(piece.start),
        lte: this._dateHelper.fromApiFormat(piece.end),
        color: piece.color,
      });
    });
    const result = new GChartVisualMap({
      pieces: data,
    });
    return result;
  }

  loadThresholds(
    selectedNames: string[],
    scopeKey: string,
    callback: (settings: EChartsOption) => void
  ): void {
    // Load thresholds corresponding to the and keep them in memory.
    this.buildThresholdSeries(selectedNames, scopeKey, (thresholds: GCartesianChartSerie[]) => {
      this.updateChartWithThresholds(thresholds, scopeKey).subscribe((settings) => {
        callback(settings);
      });
    });
  }

  /**
   * Load all threshold series and convert them to chart series.
   * Ideally should be used after the chart has loaded, so all required data is available.
   * After this, use updateToggleThresholds to apply the correct thresholds on each trend selection.
   * Returns data by using a callback instead of a piped observable, due to the difficulty of
   * mapping Observable<any[]> to Obseravable<any>[] using pipe operators.
   * @param scopeKey Used to access the correct scope of data (as Angular service is singleton, many charts can be built at same time).
   * @param callback Returns the array of GCartesianChartSerie.
   */
  public buildThresholdSeries(
    selectedNames: string[],
    scopeKey: string,
    callback: (thrs: GCartesianChartSerie[]) => void
  ): void {
    const scope = this.getScope(scopeKey);
    const { alarms, minDate, maxDate, severityColors } = scope;
    const apiSeries = scope.series;
    if (!alarms || !apiSeries.length) {
      // If no api series, we cannot show any thresholds.
      callback([]);
      return;
    }
    const mainChartSeriesUnprocessed = scope.mainChartSeriesUnprocessed ?? [];
    const namesMain = scope.mainSeriesNames ?? [];
    const selectedSeriesUnprocessed = this.getMainSelectedSeries(
      mainChartSeriesUnprocessed,
      selectedNames,
      namesMain
    );

    if (!selectedSeriesUnprocessed.length) {
      // If there are no main series, do not load thresholds.
      callback([]);
      return;
    }
    let currentAlarms;

    const activeSerie = selectedSeriesUnprocessed.length ? selectedSeriesUnprocessed[0] : null;
    let activeSerieIsAlgorithm = false;

    if (activeSerie) {
      activeSerieIsAlgorithm = activeSerie.additionalParams?.isAlgorithm;
      if (activeSerieIsAlgorithm) {
        currentAlarms = this.getAlarmsFromCVChartSeries(selectedSeriesUnprocessed, alarms);
      } else {
        currentAlarms = this.getAlarmsFromMVChartSeries(selectedSeriesUnprocessed, alarms);
      }
    } else {
      this._dialogService.showTranslatedMessageInSnackBar(
        new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.${this._thresholdWarningKey}` })
      );
      callback([]);
      return;
    }

    const alarmTypes = this._arrayHelper.onlyUnique(currentAlarms.map((a) => a.alarmTypeId));

    this.logInfo(`Alarms types: ${alarmTypes.length}`);

    if (selectedSeriesUnprocessed.length > 1 || alarmTypes.length > 1) {
      // To show thresholds, only one main serie must be active, and all alarms must be of the same type.
      this._dialogService.showTranslatedMessageInSnackBar(
        new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.${this._thresholdWarningKey}` })
      );
      callback([]);
      return;
    }

    const activeApiSerie: any = apiSeries[0];
    const activeAlarmType = alarmTypes[0];

    const getThresholds = activeSerieIsAlgorithm
      ? this._cvService.getThresholds
      : this._mvService.getThresholds;

    const mapToSerie = activeSerieIsAlgorithm
      ? this.mapCVThresholdToSerie
      : this.mapMVThresholdToSerie;

    // Get api series.
    const apiSource$ = getThresholds(
      activeAlarmType,
      this._dateHelper.toApiFormat(minDate),
      this._dateHelper.toApiFormat(maxDate)
    );
    // Map to chart.
    this.mapApiToChart(apiSource$, (t) => mapToSerie(t, severityColors, activeApiSerie), callback);
  }

  /**
   * Helper local function. Helps mapping from api series to chart series.
   */
  mapApiToChart(apiSource$, mapToChartFn, callback) {
    const subs1 = apiSource$.subscribe((thresholdApiSeries) => {
      // Convert to chart series.
      if (!thresholdApiSeries.length) {
        this.logInfo('No thresholds were received from the api.');
        subs1.unsubscribe();
        callback([]);
        return;
      }
      const thresholdChartSeries$ = thresholdApiSeries.map((t) => mapToChartFn(t));
      forkJoin(thresholdChartSeries$).subscribe((thresholdChartSeries: GCartesianChartSerie[]) => {
        subs1.unsubscribe();
        // Return the values using a callback.
        callback(thresholdChartSeries);
      });
    });
  }

  /**
   * Receive the thresholds, and show only the ones that correspond to the selected main series.
   * Also converts them to native series.
   */
  public updateChartWithThresholds(
    thresholdsUnprocessed: GCartesianChartSerie[],
    scopeKey: string // Related to the service scope instance.
  ): Observable<EChartsOption> {
    const scope = this.getScope(scopeKey);
    const mainChartSeriesUnprocessed = scope.mainChartSeriesUnprocessed
      ? this.genericChartService.cloneSeries(scope.mainChartSeriesUnprocessed)
      : [];
    const mainSettings: any = scope.settings ?? {};

    const seriesUnprocessed = mainChartSeriesUnprocessed.concat(thresholdsUnprocessed);
    // We need to update the unprocessed series in the scope so that the tooltip can use them.
    this.setScopeData(scopeKey, {
      allChartSeriesUnprocessed: seriesUnprocessed,
    });

    mainSettings.series = seriesUnprocessed;
    return this.genericChartService.getDefaultCartesianChart(mainSettings).pipe(
      map((newSettings) => {
        const newOptions = this._nativeChartService.mapSettings(newSettings);
        return newOptions;
      })
    );
  }

  /**
   * From native series, extract the ones which are selected and are not thresholds.
   */
  private getMainSelectedSeries(
    seriesWithName: any[],
    selectedNames: string[],
    namesMain: string[]
  ): any[] {
    let selectedMain;
    if (selectedNames == null) {
      // If not specified, all main series are selected (default).
      selectedMain = namesMain;
    } else {
      // Keep only the selected that are main series (not thresholds).
      selectedMain = selectedNames.filter((selected) =>
        namesMain.find((main) => selected === main)
      );
    }
    const mainSelectedSeries = seriesWithName
      .filter((serie) => selectedMain.find((main) => main === serie.name))
      .map((serie) => ({ ...serie }));

    return mainSelectedSeries;
  }

  /**
   * When a serie is toggled, recalculate the colored pieces to match the current set of series.
   */
  public updateColoredPieces(
    selectedNames: string[],
    nativeSeries: any[],
    scopeKey: string // Related to the service scope instance.
  ): EChartsOption {
    const scope = this.getScope(scopeKey);
    const { series, isAlgorithmSeries, alarms, severityColors, maxDate } = scope;

    // Relaculation only makes sense if there is more than one trend.

    const mainSeriesNames = scope.mainSeriesNames ?? [];
    let selectedMain: string[];
    if (selectedNames == null) {
      // If not specified, all main series are selected (default).
      selectedMain = mainSeriesNames;
    } else {
      // Keep only the selected that are main series (not thresholds).
      selectedMain = selectedNames.filter((selected) =>
        mainSeriesNames.find((main) => selected === main)
      );
    }

    const selectedSeriesMV = (series as MVChartSerieDto[]).filter(
      (serie) => serie.signalId && selectedNames.find((name) => name === this.getSerieName(serie))
    );

    const selectedSeriesCV = (series as CVChartSerieDto[]).filter(
      (serie) =>
        serie.algorithmShortName && selectedNames.find((name) => name === this.getSerieName(serie))
    );

    // Reset the marked areas of all the other series.
    nativeSeries.forEach((serie) => (serie.markArea = null));

    const currentMVAlarms = this.getAlarmsFromMVApiSeries(selectedSeriesMV, alarms);
    const currentCVAlarms = this.getAlarmsFromCVApiSeries(selectedSeriesCV, alarms);
    const coloredPieces = this.calculateColoredPieces(
      [...currentMVAlarms, ...currentCVAlarms],
      severityColors,
      maxDate
    );
    const markArea = this.buildColoredBackground(coloredPieces);
    const firstSerie = nativeSeries.find((serie) => selectedMain.find((ms) => ms === serie.name));

    // Only assign markArea to the first serie that is not threshold (so transparency is not altered).
    if (firstSerie) {
      firstSerie.markArea = markArea;
    }

    return {
      series: nativeSeries,
    };
  }

  /**
   * Get all alarms corresponding to MV series, by using signal ids.
   */
  private getAlarmsFromMVApiSeries(series: MVChartSerieDto[], alarms: AlarmDto[]): AlarmDto[] {
    return this.getAlarmsFromSignalIds(
      series.map((s) => s.signalId),
      alarms
    );
  }

  private getAlarmsFromCVApiSeries(series: CVChartSerieDto[], alarms: AlarmDto[]): AlarmDto[] {
    return this.getAlarmsFromCVShortName(
      series.map((s) => s.algorithmShortName),
      alarms
    );
  }

  private getAlarmsFromMVChartSeries(
    series: GCartesianChartSerie[],
    alarms: AlarmDto[]
  ): AlarmDto[] {
    return this.getAlarmsFromSignalIds(
      series.map((s) => s.additionalParams?.signalId).filter(Boolean),
      alarms
    );
  }

  private getAlarmsFromCVChartSeries(
    series: GCartesianChartSerie[],
    alarms: AlarmDto[]
  ): AlarmDto[] {
    return this.getAlarmsFromCVShortName(
      series.map((s) => s.additionalParams?.algorithmShortName).filter(Boolean),
      alarms
    );
  }

  /**
   * Get all alarms corresponding to signal ids.
   */
  private getAlarmsFromSignalIds(signalIds: number[], alarms: AlarmDto[]): AlarmDto[] {
    const selected = alarms.filter((alarm) =>
      signalIds.find((signalId) => signalId === alarm.signalId)
    );
    return selected;
  }

  private getAlarmsFromCVShortName(shortNames: string[], alarms: AlarmDto[]): AlarmDto[] {
    const selected = alarms.filter((alarm) =>
      shortNames.find((shortName) => shortName === alarm.algorithmShortName)
    );
    return selected;
  }

  formatDate = (date: Date) => this._localization.formatDate(date, DateFormats.DateTime);

  private isDailySerie(serie: CVChartSerieDto | MVChartSerieDto): boolean {
    // All signal series are base by default.
    return (
      (serie as CVChartSerieDto)?.algorithmShortName &&
      (serie as CVChartSerieDto).timeAggregationId === TimeAggregationEnum.Daily
    );
  }

  private getShowAlwaysFilterMethodBySerie(
    serie: CVChartSerieDto | MVChartSerieDto
  ): TooltipFilterMethodEnum {
    let filterMethod = null;
    if (this.isDailySerie(serie)) {
      filterMethod = TooltipFilterMethodEnum.Daily;
    }
    return filterMethod;
  }

  /**
   * Ensure the translations are loaded.
   */
  private getTranslations(): Observable<void> {
    if (this._i18nLoaded) {
      return of(null);
    }
    return this._localization.get(`${this.T_SCOPE}`).pipe(
      tap((translations) => {
        this._tooltipLabels = {
          thresholdLabel: translations['threshold-serie-label'],
          alarmLabel: translations['alarm-label'],
          thresholdTitle: translations['threshold-title'],
          startDateLabel: translations['start-date'],
          endDateLabel: translations['end-date'],
        };
        this._i18nLoaded = true;
      })
    );
  }

  /**
   * Save data that will be associated to a specific chart instance (or scope).
   */
  private setScopeData(scopeKey: string, data: IAlarmsChartInstanceScope): void {
    if (!this._scopes[scopeKey]) {
      this._scopes[scopeKey] = {};
    }
    Object.assign(this._scopes[scopeKey], data);
  }

  private getScope(scopeKey: string): IAlarmsChartInstanceScope {
    if (!scopeKey) {
      this._logService.error({
        msg: `You must provide an scope key.`,
      });
      return null;
    }
    const result = this._scopes[scopeKey] ?? {};
    return result;
  }

  public resetScope(scopeKey: string): void {
    this._scopes[scopeKey] = {};
  }

  private sortStrDatesAsc = (d1, d2) => (d1 > d2 ? 1 : d1 < d2 ? -1 : 0);

  private logInfo(msg, payload = null): void {
    this._logService.info({ msg, payload, scope: LogScope.Alarms });
  }
}
