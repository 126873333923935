import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StringHelperService } from '@common-modules/shared/helpers/string-helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { closest } from '@progress/kendo-angular-common';
import {
  BaseFilterCellComponent,
  FilterService,
  PopupCloseEvent,
  SinglePopupService,
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { map, startWith } from 'rxjs';

type OptionElement = { text: string; value: any };

@UntilDestroy()
@Component({
  selector: 'wlm-enumerable-column-filter',
  templateUrl: './enumerable-column-filter.component.html',
  styleUrls: ['./enumerable-column-filter.component.scss'],
})
export class EnumerableColumnFilterComponent
  extends BaseFilterCellComponent
  implements OnInit, OnDestroy
{
  @Input() field: string;
  @Input() filterService: FilterService;
  @Input() filter: CompositeFilterDescriptor;
  @Input() elements: Map<any, string>;

  private popupSubscription: any;

  internalElements: OptionElement[];

  selectControl = new FormControl();
  filterString: string = '';
  filteredElements: OptionElement[];
  selectedElements: OptionElement[];

  private readonly _avoidPopupCloseClasses = [
    'enumerable-column-filter',
    'mat-select',
    'mat-select-value',
    'grid-filter-container',
    'mat-mdc-autocomplete-panel',
  ];

  constructor(
    private _element: ElementRef,
    filterService: FilterService,
    private _stringHelperService: StringHelperService,
    popupService: SinglePopupService
  ) {
    super(filterService);

    // Handle the service onClose event and prevent the menu from closing when the datepickers are still active.
    this.popupSubscription = popupService.onClose
      .pipe(untilDestroyed(this))
      .subscribe((e: PopupCloseEvent) => {
        if (
          document.activeElement &&
          closest(document.activeElement, (node: any) => {
            return (
              node === this._element.nativeElement ||
              !!this._avoidPopupCloseClasses.find(
                (classItem) => String(node.className).indexOf(classItem) >= 0
              )
            );
          })
        ) {
          e.preventDefault();
        }
      });
  }

  ngOnInit(): void {
    this.internalElements = Array.from(this.elements?.keys())?.map((m) => ({
      value: m,
      text: this.elements?.get(m)?.toString(),
    }));
    const valuesFiltered = this.filter?.filters?.map((m) =>
      (m as FilterDescriptor)?.value === null ? 'null' : (m as FilterDescriptor)?.value
    );
    this.selectedElements = this.internalElements.filter((f) => valuesFiltered.includes(f.value));

    this.selectControl.valueChanges
      .pipe(
        startWith<string>(''),
        map((value) => (typeof value === 'string' ? value : this.filterString)),
        map((filter) => this.filterElements(filter))
      )
      .subscribe((filteredElements) => {
        this.filteredElements = filteredElements;
      });
  }

  prepareFilters(value: any[]): void {
    const currentValues: any = [];
    if (value?.length === 0) {
      this.applyFilter(this.removeFilter(this.field));
    } else {
      const filter: CompositeFilterDescriptor = {
        logic: value?.length > 1 ? 'or' : 'and',
        filters: [],
      };

      value.forEach((item) => {
        currentValues.push(item);
        filter.filters.push({
          field: this.field,
          operator: 'eq',
          value: item,
        });
      });
      this.applyFilter(this.removeFilter(this.field));
      this.applyFilter(
        value.length > 1 ? filter : this.updateFilter(filter.filters[0] as FilterDescriptor)
      );
    }
  }

  removeChip(event: Event, element: any): void {
    event.stopPropagation();
    this.toggleSelection(element);
  }

  toggleSelection(element: any): void {
    if (!this.isSelected(element)) {
      this.selectedElements.push(element);
    } else {
      const i = this.selectedElements.findIndex((e) => e.value === element.value);
      this.selectedElements.splice(i, 1);
    }

    this.prepareFilters(this.selectedElements.map((e) => e.value));
  }

  optionClicked(element: OptionElement): void {
    this.toggleSelection(element);
  }

  autocompleteLabelFn = (element: OptionElement) => element.text;

  autocompleteValueFn = (element: OptionElement) => element.value;

  isSelected(element: OptionElement) {
    return this.selectedElements.some((e) => e.value === element.value);
  }

  private filterElements(value: string): OptionElement[] {
    this.filterString = value;
    if (value) {
      return this.internalElements.filter((e) =>
        this._stringHelperService.localeIncludes(e.text, value)
      );
    } else {
      return this.internalElements.slice();
    }
  }

  public ngOnDestroy(): void {
    this.popupSubscription.unsubscribe();
  }
}
