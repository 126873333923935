import { Component, Injector, inject } from '@angular/core';
import { GenericCrudSettings } from '@common-modules/generic-crud/generic-crud-settings';
import { LeakTypesFormComponent } from '../leak-types-form/leak-types-form.component';

@Component({
  selector: 'wlm-leak-types-crud',
  templateUrl: './leak-types-crud.component.html',
  styleUrls: ['./leak-types-crud.component.scss'],
})
export class LeakTypesCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'LeakTypesCrudService',
      grid: {
        gridSettingsName: 'LeakTypesCrud',
      },
      create: {
        formComponent: LeakTypesFormComponent,
      },
      update: {
        formComponent: LeakTypesFormComponent,
      },
      delete: {},
    });
  }
}
