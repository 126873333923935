import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { INetworkElementTypeDto } from '@common-modules/dependencies/ne/network-element-type.dto';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { NodeFormDefinition } from '@common-modules/wlm-charts/core/models/schematics/node-form-definition';
import {
  SchematicNode,
  SchematicNodeModified,
} from '@common-modules/wlm-charts/core/models/schematics/schematic';

const COMPONENT_SELECTOR = 'wlm-schematics-create-node-form';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './schematics-create-node-form.component.html',
  styleUrls: ['./schematics-create-node-form.component.scss'],
})
export class SchematicsCreateNodeFormComponent implements OnInit {
  @Input() nodeForm: UntypedFormGroup;
  @Input() nodeFormDefinition: NodeFormDefinition;

  @Output() saveNode = new EventEmitter<SchematicNodeModified>();

  @Output() cancelForm = new EventEmitter<void>();
  neTypes: INetworkElementTypeDto[];

  readonly T_SCOPE = `${AppModules.Schematics}.${COMPONENT_SELECTOR}`;

  constructor(private _globalsService: GlobalsService) {}

  ngOnInit(): void {
    this._globalsService.getNetworkElementTypes().subscribe((neTypes) => {
      this.neTypes = neTypes.sort((a, b) =>
        a.networkElementTypeName.localeCompare(b.networkElementTypeName)
      );
    });
  }

  saveCurrentNode() {
    const node = new SchematicNode();

    node.id = this.nodeForm.controls[this.nodeFormDefinition.gisIdFieldName].value;
    node.name = this.nodeForm.controls[this.nodeFormDefinition.nameFieldName].value;
    node.category =
      this.nodeForm.controls[this.nodeFormDefinition.networkElementFieldName].value.toString();
    node.x = this.nodeForm.controls[this.nodeFormDefinition.latitudeFieldName].value;
    node.y = this.nodeForm.controls[this.nodeFormDefinition.longitudeFieldName].value;

    const categoryName = this.neTypes.find((f) => f.networkElementTypeId === +node.category);

    const nodeModified: SchematicNodeModified = {
      node: node,
      categoryName: categoryName.networkElementTypeName,
    };

    this.saveNode.emit(nodeModified);
  }

  cancel() {
    this.cancelForm.emit();
  }
}
