import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetRegistryService } from '@common-modules/widget-registry/widget-registry';
import { WLMGridModule } from '@common-modules/wlm-grid/wlm-grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommentsModule } from '../comments/comments.module';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { MonitoringRoutingModule } from './monitoring-routing.module';
import { NotificationAttributesComponent } from './notification/notification-components/notification-attributes/notification-attributes.component';
import { NotificationDetailsByIdComponent } from './notification/notification-components/notification-details-by-id/notification-details-by-id.component';
import { NotificationDetailsFormComponent } from './notification/notification-components/notification-details-form/notification-details-form.component';
import { NotificationDetailsComponent } from './notification/notification-components/notification-details/notification-details.component';
import { NotificationGridFilterComponent } from './notification/notification-components/notification-grid-filter/notification-grid-filter.component';
import { NotificationPageComponent } from './notification/notification-page/notification-page.component';

@NgModule({
  declarations: [
    NotificationPageComponent,
    NotificationGridFilterComponent,
    NotificationDetailsComponent,
    NotificationDetailsFormComponent,
    NotificationAttributesComponent,
    NotificationDetailsByIdComponent,
  ],
  imports: [
    CommonModule,
    WlmSharedModule,
    WlmSharedComponentModule,
    WLMGridModule,
    TranslateModule,
    WlmFiltersModule,
    MonitoringRoutingModule,
    CommentsModule,
  ],
  exports: [NotificationDetailsComponent, NotificationDetailsFormComponent],
})
export class MonitoringModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      { name: 'NotificationDetailsByIdComponent', widgetClass: NotificationDetailsByIdComponent },
      { name: 'NotificationDetailsComponent', widgetClass: NotificationDetailsComponent },
      { name: 'NotificationAttributesComponent ', widgetClass: NotificationAttributesComponent },
    ]);
  }
}
