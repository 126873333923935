import { Injectable, Injector } from '@angular/core';
import { AlarmsActiveDto } from '@common-modules/dependencies/alarms/alarms-active.dto';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';

const endpointUrl = '/api/alarm';
const oDataEndPoint = 'activation';
@Injectable({
  providedIn: 'root',
})
export class AlarmActivationsService extends GridODataService<AlarmsActiveDto> {
  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/${oDataEndPoint}`);
  }

  protected mapResponse(
    response: PagedResultDto<AlarmsActiveDto>
  ): PagedResultDto<AlarmsActiveDto> {
    return response;
  }
}
