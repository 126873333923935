import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { VersionInfoDto } from '@common-modules/dependencies/navigation/version-info.dto';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { waterLossLicenseModules } from '@water-loss//water-loss-app/water-loss-license-modules';
import { waterLossAppNavigations } from '@water-loss//water-loss-navigations';
import { environment } from 'src/environments/environment';
import { VersionInfoService } from './version-info.service';

const COMPONENT_SELECTOR = 'wlm-version-info-popup';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './version-info-popup.component.html',
  styleUrls: ['./version-info-popup.component.scss'],
})
export class VersionInfoPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmNavigation}.${COMPONENT_SELECTOR}`;
  productsVersion: { [key: string]: string };
  releaseDate: Date;
  copyrightText: string;
  legalDocumentLink: string;
  showSpinner = false;
  versionInfo: VersionInfoDto;
  canExpire: boolean;
  enabledModulesSummary: string;
  moduleTranslateKeys: { [apiName: string]: string };

  constructor(
    private readonly _dialogRef: MatDialogRef<VersionInfoPopupComponent>,
    private readonly _versionInfoService: VersionInfoService,
    private readonly _objectHelperService: ObjectHelperService,
    private readonly _dateHelperService: DateHelperService
  ) {}

  ngOnInit(): void {
    this.loadVersionInfo();
  }

  private loadVersionInfo() {
    this.showSpinner = true;
    this._versionInfoService
      .getVersion()
      .pipe(untilDestroyed(this))
      .subscribe((versionInfo) => {
        const sorted = this._objectHelperService.sortObjectKeys(versionInfo.productsVersion);
        this.productsVersion = sorted as { [key: string]: string };
        this.releaseDate = this._dateHelperService.fromApiFormat(environment.releaseDate);
        this.copyrightText = environment.copyright;
        this.legalDocumentLink = environment.legalUrl;
        this.versionInfo = versionInfo;
        this.canExpire = this.checkCanExpire();
        this.buildModuleTranslateKeys();

        this.showSpinner = false;
      });
  }

  buildModuleTranslateKeys(): void {
    this.moduleTranslateKeys = this.versionInfo.enabledModules.reduce(
      (accum, currentModuleName) => {
        accum[currentModuleName] = this.getModuleTranslateKey(currentModuleName);
        return accum;
      },
      {}
    );
  }

  getModuleTranslateKey(moduleName: string): string {
    const module = waterLossLicenseModules.find((module) => module.apiName === moduleName);
    if (!module || !module.navKey) {
      console.error(`The module ${moduleName} does not have a frontend translation`);
      return moduleName;
    }
    const moduleInHiddenLinks = waterLossAppNavigations.hiddenLinks.get(module.navKey);
    if (moduleInHiddenLinks) {
      return moduleInHiddenLinks.titleTranslateKey;
    }

    const moduleInLinks = waterLossAppNavigations.links.get(module.navKey);
    if (moduleInLinks) {
      return moduleInLinks.titleTranslateKey;
    }

    return moduleName;
  }

  checkCanExpire = (): boolean =>
    typeof this.versionInfo.expiration == 'string' && !this.versionInfo.expiration.includes('9999');

  close() {
    this._dialogRef.close();
  }
}
