import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { ALCPrioritisationView } from '@common-modules/dependencies/alc/prioritisation-view.dto';
import { HierarchyElementFiltersDataDto } from '@common-modules/dependencies/he/hierarchy-element-filters-data.dto';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WNavigateSettings } from '@common-modules/dependencies/navigation/w-navigate-by';
import { NotificationRelatedComponent } from '@common-modules/shared-component/notification-related/notification-related.component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthorizeService } from '@common-modules/shared/auth/services/authorize.service';
import { WlmElementExtended } from '@common-modules/shared/charts/model/elements/element-extended';
import { EligibilityPopupComponent } from '@common-modules/shared/component/eligibility-popup/eligibility-popup.component';
import { ApplicationAttributes } from '@common-modules/shared/constants/application-constants';
import { EligibilityPopupDimensions } from '@common-modules/shared/constants/eligibility.constants';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { LabelValueListItem } from '@common-modules/shared/core/label-value-list/label-value-list-item';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { GridBtnsEvent } from '@common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from '@common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { NavMenuBuilderHelperService } from '@common-modules/shared/helpers/navmenu-builder-helper.service';
import { ICanLeavePage } from '@common-modules/shared/navigation/can-component-deactivate';
import { NotificationGridSettingService } from '@common-modules/shared/notifications/notification-gridsetting.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { RightPanelAttributesService } from '@common-modules/shared/services/right-panel-attributes-builder.service';
import { ChartType } from '@common-modules/wlm-charts/core/models/chart-type.enum';
import { CustomWorkspaceChartDataParameters } from '@common-modules/wlm-charts/core/models/custom-workspace-chart-data-parameters';
import { CustomWorkspaceChartSettings } from '@common-modules/wlm-charts/core/models/custom-workspace-chart-settings';
import { EntityTypes } from '@common-modules/wlm-charts/core/models/entity-types';
import { PeriodTypesEnum } from '@common-modules/wlm-charts/core/models/period-types.enum';
import { TimeSelectorChartSettings } from '@common-modules/wlm-charts/core/models/time-selector-settings';
import { TimeSelectorChartWidgetComponent } from '@common-modules/wlm-charts/core/time-selector-chart-widget/time-selector-chart-widget.component';
import { BaseGridPageComponent } from '@common-modules/wlm-grid/base-grid/base-grid-page.component';
import { GridPersistedElements } from '@common-modules/wlm-grid/generic-grid/generic-grid-constants';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { TimeControlBarDto } from '@common-modules/wlm-grid/model/time-control-bar.dto';
import { PipeGridComponent } from '@common-modules/wlm-grid/pipe-grid/pipe-grid.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject, combineLatest, forkJoin, of } from 'rxjs';
import { Images } from 'src/assets/icons/previous-icons/images';
import { CustomerGroupComponent } from '../../customer/customer-group/customer-group.component';
import { DataVisualizationNavigationService } from '../../data-visualization-shared/services/data-visualization-navigation.service';
import { MapHelperService } from '../../map/map-helper.service';
import { MapParameters } from '../../map/map-parameters';
import { MapRelatedComponent } from '../../map/map-related/map-related.component';
import { NavItemsConfiguration } from '../../shared/model/navigation/navitem-configuration';
import { NavMenuConfiguration } from '../../shared/model/navigation/navmenu-configuration';
import { PipePieChartComponent } from '../../shared/pipes/pipe-pie-chart/pipe-pie-chart.component';
import { PrioritisationGridFilterConfiguration } from '../prioritisation-components/prioritisation-filter/prioritisation-grid-filter-configuration';
import { PrioritisationGridFilterValidations } from '../prioritisation-grid/prioritisation-grid-filter-validations';

const COMPONENT_SELECTOR = 'wlm-prioritisation-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './prioritisation-page.component.html',
  styleUrls: ['./prioritisation-page.component.scss'],
  providers: [RightPanelAttributesService],
})
export class PrioritisationPageComponent
  extends BaseGridPageComponent
  implements OnInit, OnDestroy, ICanLeavePage
{
  @ViewChild(GenericGridComponent) public prioritisationGrid: GenericGridComponent;

  gridSettings: GridSetting;
  gridFilters: DataBindingFilters;
  private _selectedsPrioritisation: ALCPrioritisationView[];
  private _heLevelLayerMap = new Map<string, number>();

  clearFiltersIcon: string = Images.IconClearFilter;
  hasBeenSelected = false;

  public get selectedsPrioritisation(): ALCPrioritisationView[] {
    return this._selectedsPrioritisation;
  }

  public set selectedsPrioritisation(value: ALCPrioritisationView[]) {
    this._selectedsPrioritisation = value;
    this.updateNavigationParams();
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'PrioritisationPageCrud';
  }

  private _navigations: DropdownNavigationItem[];
  public get navigations(): DropdownNavigationItem[] {
    return this._navigations;
  }

  public set navigations(v: DropdownNavigationItem[]) {
    this._navigations = v;
  }

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  panelOpenState = true;
  selectedHierarchyElements: any;
  selectedHierarchyFamilyId: any;
  selectedPrioritisation: ALCPrioritisationView;
  gridFiltersForBinding: DataBindingFilters;
  currentSelection: string[] = [];
  filterDetailsParameters: TabDetailPanelParameters;
  canEditEligibility = true;
  prioritisationFilterConfiguration: PrioritisationGridFilterConfiguration;
  maximumSelectedZones = 20;
  configReady = false;
  gridName = 'Prioritisation';
  canLoad: boolean;
  clearAll$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  filterFields = ['hierarchyElementsIds'];
  gridSettingsReady$ = new ReplaySubject<GridSetting>();
  defaultHierarchyElementId = '';
  maxZonesToRaiseSelectedTs$: Observable<string>;
  invalidLevelSelectedTs$: Observable<string>;
  maxRaiseZonesMessage: string;
  invalidLevelMessage: string;
  eligibleZones: ALCPrioritisationView[];
  removeSelectionPersisted$ = new ReplaySubject<void>();
  removeSelection$ = new ReplaySubject<void>();
  useLatestCalculationDay = true;
  gridReferenceDate: Date;

  // Filters fieldNames
  hierarchyElementIdFieldName = 'ancestors';
  hierarchyElementFamilyFieldName = 'HierarchyFamilyId';

  private readonly _settingArea = 'MapSetting';
  private readonly _settingKey = `${this.persistencyArea}_MapFilter`;
  private readonly _hierarchyElementTypeIdField = 'hierarchyElementTypeId';
  private readonly _chartWorkspaceName = 'PrioritisationDetailsChart';

  constructor(
    private _editEligibilityDialog: MatDialog,
    private _globalsService: GlobalsService,
    private _gridService: GridSettingsService,
    private _route: ActivatedRoute,
    private _navMenuBuilderHelperService: NavMenuBuilderHelperService,
    private _notificationGridSettingService: NotificationGridSettingService,
    private _authService: AuthorizeService,
    private _mapHelperService: MapHelperService,
    private _rightPanelAttributesService: RightPanelAttributesService,
    private readonly _dataVisualizationNavigationService: DataVisualizationNavigationService
  ) {
    super();

    this._rightPanelAttributesService.init({
      baseTranslateKey: `${this.T_SCOPE}.fields`,
      hierarchyElementTypeIdField: this._hierarchyElementTypeIdField,
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initMessageTooltips();
    this.subscribeLayersMap();

    this._route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.readNavigationParameters(params)
        .pipe(untilDestroyed(this))
        .subscribe((filterData) => {
          if (filterData?.hierarchyFamilyId) {
            this.selectedHierarchyFamilyId = filterData.hierarchyFamilyId;
          }

          this.init();

          const notificationPermission$ = this._authService.canAccess('WLMNotificationsCrud', 'r');
          const localization$ = this.localization.get(`${this.T_SCOPE}.tab-settings`);
          combineLatest([localization$, notificationPermission$]).subscribe(
            ([ts, notificationPermission]) => {
              const panelSettings = new TabDetailPanelSettings();
              panelSettings.addComponent(TimeSelectorChartWidgetComponent, ts.trends);
              panelSettings.addComponent(MapRelatedComponent, ts.map);
              panelSettings.addComponent(CustomerGroupComponent, ts['customers-summary']);
              panelSettings.addComponent(PipeGridComponent, ts['pipes-summary']);
              panelSettings.addComponent(PipePieChartComponent, ts['pipes-charts']);

              if (notificationPermission) {
                panelSettings.addComponent(NotificationRelatedComponent, ts.notifications);
              }

              this.rightPanelService.setTabSettings(panelSettings);
              this.panelOpenState = this.getPersisted(TabDetailParameterName.filterExpanded);
            }
          );
        });
    });
  }

  initMessageTooltips() {
    const maxRaiseZones = ApplicationAttributes.MaxItemsForRaiseActivities;
    this.maxZonesToRaiseSelectedTs$ = this.localization.get(
      `${AppModules.WlmShared}.wlm-nav-dropdown.max-raise-zones`,
      { maxRaiseZones }
    );
    this.invalidLevelSelectedTs$ = this.localization.get(
      `${AppModules.WlmShared}.wlm-nav-dropdown.disabled-not-dma`,
      { maxRaiseZones }
    );
  }

  updateNavigationParams() {
    this.navigations = [];
    this.eligibleZones = this.getEligibleZones();

    forkJoin([this.maxZonesToRaiseSelectedTs$, this.invalidLevelSelectedTs$]).subscribe(
      ([maxRaiseZonesMessage, invalidLevelMessage]) => {
        this.maxRaiseZonesMessage = maxRaiseZonesMessage;
        this.invalidLevelMessage = invalidLevelMessage;

        const navItemsConfiguration = this.getNavItemsConfigurations();
        const navMenuConfiguration = new NavMenuConfiguration({
          selectedElements: this.selectedsPrioritisation,
          items: navItemsConfiguration,
        });
        this._navMenuBuilderHelperService
          .buildMenu(navMenuConfiguration)
          .subscribe((menu) => (this.navigations = menu));
      }
    );
  }

  getEligibleZones(): ALCPrioritisationView[] {
    return this.selectedsPrioritisation?.filter(
      (x) =>
        x.isEligibleByUser ||
        ((x.isEligibleByUser === null || x.isEligibleByUser === undefined) &&
          x.isEligibleBySystem === true)
    );
  }

  private getNavItemsConfigurations(): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.DistributionNetwork,
        validationType: 'max-zones',
        paramType: 'he-family-hetype',
      },
      {
        key: NavKeys.ActivityRaising,
        validationType: 'custom',
        customValidation: this.getNavRaisingDisabled(),
        customtooltip: this.getNavRaisingDisabledTooltip(),
        paramType: 'he-family',
      },
      { key: NavKeys.Activities, validationType: 'max-zones', paramType: 'he-family' },
      { key: NavKeys.Leaks, validationType: 'max-zones', paramType: 'he-family' },
      { key: NavKeys.WaterBalance, validationType: 'max-zones', paramType: 'he-family' },
      {
        key: NavKeys.Map,
        validationType: 'custom',
        customValidation: this.selectedsPrioritisation?.length !== 1,
        customtooltip: this._navMenuBuilderHelperService.getNavDisabledTooltipMessageByMaxZones(),
        paramType: 'he-family',
      },
      {
        key: NavKeys.DataVisualization,
        validationType: 'max-zones',
        paramType: 'he-family',
        customNavMethod: this.onManageTemplatesAndWorkspaces,
      },
    ];
  }

  getNavRaisingDisabledTooltip(): string {
    return this.eligibleZones?.length > this.maximumSelectedZones ? this.maxRaiseZonesMessage : '';
  }

  getNavRaisingDisabled(): boolean {
    return (
      !this.selectedsPrioritisation?.length ||
      this.eligibleZones?.length > this.maximumSelectedZones
    );
  }

  ngOnDestroy() {
    this.persist(TabDetailParameterName.dataBindingFilter, this.gridFiltersForBinding);
    this.persist(TabDetailParameterName.hierarchyElementId, '');
    this.persist(TabDetailParameterName.elementName, null);

    super.ngOnDestroy();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {
    this.loadState();
    this.updateNavigationParams();
    this.initGrid();
  }

  initGrid() {
    if (this.gridName) {
      const persistedSettings = this.getPersisted(
        GridPersistedElements.Settings,
        undefined
      ) as GridSetting;

      if (persistedSettings) {
        this.setGridSettings(persistedSettings);
      } else {
        this._gridService.getGridSettingsByName(this.gridName).subscribe({
          next: (gridSettings) => {
            if (gridSettings) {
              gridSettings.notificationSetting =
                this._notificationGridSettingService.getNotificationSettingsForGrid(this.gridName);

              this.setGridSettings(gridSettings);
            }
          },
        });
      }
    }
  }

  loadState() {
    const filters = [];

    // if not comes from navigation, then should get persisted values (if any)
    if (!this.selectedHierarchyElements) {
      this.selectedHierarchyElements = (
        this.getPersisted(this.hierarchyElementIdFieldName, [
          { value: this.defaultHierarchyElementId, label: '' },
        ]) as BasicFilter
      ).value;
    }

    // idem with the family
    if (!this.selectedHierarchyFamilyId) {
      const persistedFamilyId = this.getPersisted(this.hierarchyElementFamilyFieldName, undefined);
      if (persistedFamilyId) {
        this.selectedHierarchyFamilyId = (persistedFamilyId as BasicFilter)?.value?.shift()?.value;
      }

      // get default family from service if previous attemps fail
      this._globalsService
        .getDefaultHierarchyFamilyId(this.selectedHierarchyFamilyId)
        .subscribe((familyId) => {
          this.selectedHierarchyFamilyId = familyId;

          this.continueLoad(filters);
        });
    } else {
      this.continueLoad(filters);
    }
  }

  onManageTemplatesAndWorkspaces = (navigateSettings: WNavigateSettings): void => {
    const elements = this.selectedsPrioritisation.map(
      (x) =>
        new WlmElementExtended(
          x.hierarchyElementId,
          x.hierarchyElementTypeId,
          x.hierarchyElementName,
          null,
          null,
          EntityTypes.hierarchyElement
        )
    );

    this._dataVisualizationNavigationService.openManageTemplatePopupAndNavigate(
      navigateSettings,
      elements,
      this._chartWorkspaceName
    );
  };

  private continueLoad(filters: any[]) {
    const gridFiltersForBinding = this.getPersisted(
      TabDetailParameterName.dataBindingFilter,
      undefined
    ) as DataBindingFilters;

    let dataBindingCasted = new DataBindingFilters();
    if (gridFiltersForBinding?.exportableFilter) {
      dataBindingCasted.exportableFilter = gridFiltersForBinding?.exportableFilter;
      dataBindingCasted.reloadFromSerialization();
    } else {
      dataBindingCasted = null;
    }

    this.loadPage(dataBindingCasted, filters);
  }

  private loadPage(gridFiltersForBinding: DataBindingFilters, filters: IFilter[]) {
    const configuration = new PrioritisationGridFilterConfiguration(
      this.persistencyArea,
      this.hierarchyElementIdFieldName,
      this.hierarchyElementFamilyFieldName,
      this.selectedHierarchyElements,
      this.selectedHierarchyFamilyId,
      filters
    );

    this.prioritisationFilterConfiguration = configuration;
    this.configReady = true;
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const filterValidation = new PrioritisationGridFilterValidations();

    if (
      filtersParameters &&
      filterValidation.validateFilters(this.filterFields, filtersParameters)
    ) {
      this.gridFilters = filtersParameters;
      this.canLoad = true;
    } else {
      this.canLoad = false;
    }
  }

  loadGrid() {
    const newGridFilters = new DataBindingFilters();
    newGridFilters.filters = this.gridFilters.filters;
    this.gridFiltersForBinding = newGridFilters;
    this.persistFilters$.next();
  }

  getFiltersDetailsParameters(filtersDetailsParameters: TabDetailPanelParameters) {
    this.filterDetailsParameters = filtersDetailsParameters;
  }

  getSelectedPrioritisation(selectedPrioritisation: ALCPrioritisationView) {
    if (selectedPrioritisation != null) {
      this.hasBeenSelected = true;
    }
    this.selectedPrioritisation = selectedPrioritisation;
    this.sendParameters(selectedPrioritisation, this.filterDetailsParameters);
  }

  getSelectedsPrioritisation(selectedsPrioritisation: ALCPrioritisationView[]) {
    this.selectedsPrioritisation = selectedsPrioritisation;
  }

  private sendParameters(selectedElement: ALCPrioritisationView, filter: TabDetailPanelParameters) {
    forkJoin([
      this.localization.get(`${this.T_SCOPE}.tab-settings`),
      this.buildRightPanelPill(selectedElement),
    ]).subscribe(([ts, panelPillText]) => {
      const parameters = filter ?? new TabDetailPanelParameters();

      parameters.addParameter(
        TabDetailParameterName.hierarchyElementId,
        selectedElement?.hierarchyElementId
      );
      parameters.addParameter(TabDetailParameterName.notification, selectedElement);
      parameters.addParameter(
        TabDetailParameterName.elementId,
        selectedElement?.hierarchyElementId
      );

      let timeSelectorChartSetting = null;

      if (selectedElement?.hierarchyElementId) {
        const wlmElement = this.getWlmElement(selectedElement);

        timeSelectorChartSetting = new TimeSelectorChartSettings({
          chartSetting: new CustomWorkspaceChartSettings({
            chartType: ChartType.customizable,
            dataParameters: new CustomWorkspaceChartDataParameters({
              startDate: new Date(),
              endDate: new Date(),
              workspaceName: this._chartWorkspaceName,
              elements: [wlmElement],
            }),
            exportFileName: `${this.titleService.getTitle()} ${
              selectedElement?.hierarchyElementId
            }`,
          }),
          includeDefaultPeriods: true,
          defaultSelectedPeriodType: PeriodTypesEnum.customFromDateRange,
        });
      }

      parameters.addParameter(
        TabDetailParameterName.timeSelectorChartSetting,
        timeSelectorChartSetting
      );

      const mapParameters = this.initializeMapParameters();

      if (this.hasBeenSelected) {
        mapParameters.hierarchyElements = selectedElement?.hierarchyElementId
          ? [selectedElement?.hierarchyElementId]
          : [];
        mapParameters.visibleLayersIds = [
          ...new Set([
            ...mapParameters.visibleLayersIds,
            ...[this._heLevelLayerMap.get(selectedElement?.hierarchyElementTypeId)],
          ]),
        ];
      }

      parameters.addParameter(TabDetailParameterName.mapParameters, mapParameters);

      parameters.addParameter(
        TabDetailParameterName.topAttributes,
        this.buildRightPanelAttributes()
      );
      parameters.addParameter(TabDetailParameterName.topValues, selectedElement);

      parameters.addParameter(
        TabDetailParameterName.titleSidePills,
        panelPillText ? [panelPillText] : []
      );

      this.buildRightPanelHeader(selectedElement)
        .pipe(untilDestroyed(this))
        .subscribe((rightPanelHeader) => {
          // Default
          if (!rightPanelHeader && selectedElement) {
            rightPanelHeader = selectedElement.hierarchyElementId
              ? ts['zone-title'] + selectedElement.hierarchyElementId
              : null;
          }
          parameters.addParameter(TabDetailParameterName.elementName, rightPanelHeader);

          this.includeAdditionalParams(parameters);
          this.rightPanelService.setTabParameters(parameters);
        });
    });
  }

  private initializeMapParameters() {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    const mapParameters = MapParameters.getparameter({
      visibleLayersIds: persistedMapsettings ? persistedMapsettings.visibleLayersIds : null,
      leakYears: persistedMapsettings ? persistedMapsettings.leakYears : null,
      visibleThematicsIds: persistedMapsettings ? persistedMapsettings.visibleThematicsIds : null,
      showFilters: true,
      settingArea: this._settingArea,
      settingKey: this._settingKey,
      loadFromPersistency: persistedMapsettings === null,
    });

    return mapParameters;
  }

  editEligibility(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = EligibilityPopupDimensions.Width;
    dialogConfig.data = {
      prioritisations: this.selectedsPrioritisation,
      user: '',
      referenceDate: this.gridReferenceDate,
    };
    const popup = this._editEligibilityDialog.open(EligibilityPopupComponent, dialogConfig);
    popup
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.removeSelectionPersisted$.next();
          this.removeSelection$.next();
          this.prioritisationGrid?.reloadGrid();
        }
      });
  }

  validateBulkEligibility(): boolean {
    return !this.selectedsPrioritisation || this.selectedsPrioritisation?.length === 0;
  }

  /**
   * Captures all the click events of all the buttons and associate them to the correct callbacks.
   */
  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  /**
   * When all filters are available, perform autoload.
   */
  onCheckAutoload(): void {
    this.gridSettingsReady$.pipe(untilDestroyed(this)).subscribe((settings) => {
      if (!settings.disableAutoLoad) {
        if (this.gridFilters?.filters) {
          this.loadGrid();
        }
      }
    });
  }

  readNavigationParameters(params: Params): Observable<HierarchyElementFiltersDataDto> {
    const queryParams = this.getQueryParams(params);
    const emptyNavigationParameters = queryParams?.hierarchyElementIds === undefined;
    this.useGridPersistence = emptyNavigationParameters;
    if (!emptyNavigationParameters) {
      this.removeSelectionPersisted$.next();
    }

    this.selectedHierarchyElements =
      typeof queryParams?.hierarchyElementIds === 'string'
        ? [queryParams?.hierarchyElementIds]
        : queryParams?.hierarchyElementIds;
    this.selectedHierarchyFamilyId = queryParams?.hierarchyFamilyId;

    if (this.selectedHierarchyElements?.[0] && !this.selectedHierarchyFamilyId) {
      return this._globalsService.getHeFiltersDataById(this.selectedHierarchyElements[0]);
    }

    return of(null);
  }

  canLeavePage(): Observable<boolean> {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    this._mapHelperService.persistMapSettings(
      persistedMapsettings,
      this._settingArea,
      this._settingKey
    );

    return of(true);
  }

  onNotifyUseLatestCalculationDay(value: boolean) {
    this.useLatestCalculationDay = value;
  }

  onTimeControlChange(timeControlBar: TimeControlBarDto) {
    this.gridReferenceDate = timeControlBar.baseGridDate;
  }

  private setGridSettings(gridSettings: GridSetting) {
    this.gridSettings = gridSettings;

    this.gridSettingsReady$.next(this.gridSettings);
    this.gridSettingsReady$.complete();
  }

  private buildRightPanelAttributes(): LabelValueListItem[] {
    const buildAlgorithmAttribute = this._rightPanelAttributesService.buildAlgorithmAttribute;

    return [
      buildAlgorithmAttribute({
        shortName: 'DDI',
        propertyFn: (model: ALCPrioritisationView) => model.ddi,
      }),
      buildAlgorithmAttribute({
        shortName: 'DALCMNF',
        propertyFn: (model: ALCPrioritisationView) => model.alcmnf,
      }),
      buildAlgorithmAttribute({
        shortName: 'DLNU',
        propertyFn: (model: ALCPrioritisationView) => model.dlnu,
      }),
      buildAlgorithmAttribute({
        shortName: 'DBL',
        propertyFn: (model: ALCPrioritisationView) => model.backgroundLeakage,
      }),
      buildAlgorithmAttribute({
        shortName: 'DALCCTIL',
        propertyFn: (model: ALCPrioritisationView) => model.interventionLevelDeviation,
      }),
      buildAlgorithmAttribute({
        shortName: 'DALCEL',
        propertyFn: (model: ALCPrioritisationView) => model.exitLevelDeviation,
      }),
    ];
  }

  private getWlmElement(selectedElement: ALCPrioritisationView): WlmElementExtended {
    const { hierarchyElementId, hierarchyElementTypeId, hierarchyElementName } = selectedElement;

    return new WlmElementExtended(
      hierarchyElementId,
      hierarchyElementTypeId,
      hierarchyElementName,
      null,
      null
    );
  }

  private buildRightPanelPill(selectedElement: ALCPrioritisationView): Observable<string> {
    if (selectedElement?.isEligibleByUser) {
      return this.localization.get(`${this.T_SCOPE}.is-eligible-user`);
    }
    if (selectedElement?.isEligibleBySystem) {
      return this.localization.get(`${this.T_SCOPE}.is-eligible-system`);
    }
    return of(null);
  }

  private subscribeLayersMap() {
    this._mapHelperService.getHeLevelLayerMapping().subscribe((map) => {
      this._heLevelLayerMap = map;
    });
  }

  private buildRightPanelHeader(selectedElement: ALCPrioritisationView): Observable<string> {
    return this._rightPanelAttributesService.getRightPanelHeaderFromSettings(
      this.gridSettings,
      selectedElement
    );
  }
}
