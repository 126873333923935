import { Injectable, Injector } from '@angular/core';
import { VersionInfoDto } from '@common-modules/dependencies/navigation/version-info.dto';
import { SpinnerSplashService } from '@common-modules/wlm-spinner/spinner-splash.service';
import { VersionInfoService } from '@water-loss//features/navigation/version-info-popup/version-info.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class WlmSpinnerSplashService extends SpinnerSplashService {
  constructor(injector: Injector, private _versionInfoService: VersionInfoService) {
    super(injector);
  }

  getAppName(): Observable<string> {
    return of('Water Loss');
  }

  getLogo(): Observable<string> {
    return of('ecostruxure.png');
  }

  getVersion(): Observable<VersionInfoDto> {
    return this._versionInfoService.getVersion();
  }
}
