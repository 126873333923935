import { Injectable, Injector } from '@angular/core';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { Observable } from 'rxjs/internal/Observable';
import { BaseChartService } from '../base-chart/base-chart.service';
import { HistoricalChartDataParameters } from '../models/historical-chart-settings/historical-chart-data-parameters';
import { HistoricalChartResultSettings } from '../models/historical-chart-settings/historical-chart-result-settings';
import { HistoricalEvent } from '../models/historical-chart-settings/historical-event';
import { IHistoricalOperation } from '../models/historical-chart-settings/historical-operation';
import { HistoricalVersionDto } from '../models/historical-chart-settings/historical-version.dto';
import { ISaveHistoricalVersionResponse } from '../models/historical-chart-settings/save-historical-version';

export type Translations = { [key: string]: string };

@Injectable({
  providedIn: 'root',
})
export abstract class BaseHistoricalChartService extends BaseChartService<HistoricalChartResultSettings> {
  protected T_SCOPE = 'wlm-charts.wlm-historical-chart';
  protected minDate: Date;
  protected maxDate: Date;
  protected arrayHelperService: ArrayHelperService;
  protected dateHelperService: DateHelperService;

  constructor(injector: Injector) {
    super(injector);
    this.arrayHelperService = injector.get('ArrayHelperService');
    this.dateHelperService = injector.get('DateHelperService');
  }

  protected abstract getGroup(configuration: HistoricalVersionDto): string;
  protected abstract getTooltip(
    configuration: HistoricalVersionDto,
    ts: Translations,
    unit?: UnitTypeConversionViewDto
  ): string;
  protected abstract getLabel(configuration: HistoricalVersionDto, ts: Translations): string;
  protected abstract getTranslationKey(): string;

  abstract getEvent(configuration: HistoricalVersionDto): Observable<HistoricalEvent>;
  abstract saveHistoricalConfiguration(
    operations: IHistoricalOperation[],
    currentConfigurations: HistoricalVersionDto[],
    originalConfigurations: HistoricalVersionDto[],
    element?: unknown
  ): Observable<ISaveHistoricalVersionResponse>;

  abstract getCurrentFromHistorical(configuration: HistoricalVersionDto);

  abstract configurationsAreEqual<T extends HistoricalVersionDto>(
    configuration1: T,
    configuration2: T
  );

  abstract recalculateDatesGroupBy(configuration: HistoricalVersionDto): string;

  /**
   * We need to require a clone implementation because the current ObjectHelperService.clone has some issues.
   */
  abstract cloneConfiguration(configuration: HistoricalVersionDto): HistoricalVersionDto;

  protected setDatesThreshold(params: HistoricalChartDataParameters) {
    this.minDate = params.startDate;
    this.maxDate = params.endDate;
  }

  getDaysToRecalculate(
    currentConfigurations: HistoricalVersionDto[],
    originalConfigurations: HistoricalVersionDto[]
  ): Date[] {
    return this.getDaysToRecalculateOptimized(currentConfigurations, originalConfigurations);
  }

  private getDaysToRecalculateOptimized(
    currentConfigurations: HistoricalVersionDto[],
    originalConfigurations: HistoricalVersionDto[]
  ): Date[] {
    const currentDatesHash = new Map<string, Set<number>>();
    const originalDatesHash = new Map<string, Set<number>>();

    currentConfigurations.forEach((configuration) => {
      const currentRange = this.dateHelperService.datesBetween(
        new DateRange(configuration.validFrom, configuration.validTo),
        this.minDate,
        this.maxDate
      );
      const currentDates = new Set<number>();
      currentRange.forEach((date) => currentDates.add(+date));
      this.updateDatesHash(currentDatesHash, currentDates, configuration);
    });

    originalConfigurations.forEach((originalConfiguration) => {
      const originalRange = this.dateHelperService.datesBetween(
        new DateRange(originalConfiguration.validFrom, originalConfiguration.validTo),
        this.minDate,
        this.maxDate
      );
      const originalDates = new Set<number>();
      originalRange.forEach((date) => originalDates.add(+date));
      this.updateDatesHash(originalDatesHash, originalDates, originalConfiguration);
    });

    const datesToRecalculateNumber = this.calculateDatesDiff(currentDatesHash, originalDatesHash);
    const datesToRecalculate = datesToRecalculateNumber.map((dateNumber) => new Date(dateNumber));

    const datesSet = new Set(datesToRecalculate.map((f) => f.toISOString()));

    return Array.from(datesSet).map((f) => new Date(f));
  }

  private calculateDatesDiff(currentDatesHash, originalDatesHash): number[] {
    const resultDates = new Set<number>();
    const addToResults = (array: number[]) => array.forEach((date) => resultDates.add(date));

    const allGroups = this.arrayHelperService.onlyUnique(
      Array.from(currentDatesHash.keys()).concat(Array.from(originalDatesHash.keys()))
    );

    allGroups.forEach((group) => {
      const currentGroup = currentDatesHash.get(group);
      const originalGroup = originalDatesHash.get(group);

      if (currentGroup && !originalGroup) {
        addToResults([...currentGroup]);
      } else if (originalGroup && !currentGroup) {
        addToResults([...originalGroup]);
      } else {
        const groupDiff = this.arrayHelperService.arrayDiff<number>(
          [...currentGroup],
          [...originalGroup]
        );

        addToResults(groupDiff);
      }
    });

    return [...resultDates];
  }

  private updateDatesHash(
    hash: Map<string, Set<number>>,
    newSet: Set<number>,
    configuration: HistoricalVersionDto
  ): void {
    const group = this.recalculateDatesGroupBy(configuration);
    if (!hash.has(group)) {
      hash.set(group, new Set());
    }
    hash.set(group, new Set([...hash.get(group), ...newSet]));
  }
}
