import { Component, Input, OnInit } from '@angular/core';
import { HierarchyElementPathFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FiltersAdapterService } from '@common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { TreeSettingsService } from '@common-modules/shared/services/tree-settings.service';
import { ReplaySubject } from 'rxjs';
import { PrioritisationGridFilterConfiguration } from './prioritisation-grid-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-prioritisation-filter';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './prioritisation-filter.component.html',
  styleUrls: ['./prioritisation-filter.component.scss'],
})
export class PrioritisationFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _prioritisationFilterConfiguration: PrioritisationGridFilterConfiguration;
  public get prioritisationFilterConfiguration(): PrioritisationGridFilterConfiguration {
    return this._prioritisationFilterConfiguration;
  }
  @Input() public set prioritisationFilterConfiguration(v: PrioritisationGridFilterConfiguration) {
    this._prioritisationFilterConfiguration = v;
    if (v) {
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  treeSettings: ITreeSettings = null;
  filterFields = ['hierarchyElementsIds'];
  gridSettingsReady$ = new ReplaySubject<GridSetting>();
  mustPersistFilters = false;
  isFirstLoad = true;

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
  };

  bfSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-label`,
    disableSelectAll: true,
  };

  hierarchyFamilyItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
    required: true,
  });

  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
  });

  // Communicates hierarchy-family-filter-item with hierarchy-tree-filter-item.
  hierarchyElementFamilyId: string;

  constructor(
    private treeSettingsService: TreeSettingsService,
    private adaptersService: FiltersAdapterService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  buildKeysToComplete(): void {
    const { hierarchyElementIdFieldName } = this.prioritisationFilterConfiguration;
    this.keysToComplete = [hierarchyElementIdFieldName];
  }

  ngOnInit(): void {
    this.buildTreeSettings();
    this.setFiltersPersistencyArea();
  }

  buildTreeSettings(): void {
    this.treeSettingsService
      .buildTreeSettings()
      .subscribe((settings) => (this.treeSettings = settings));
  }

  /**
   * Saves the family Id so the tree filter can obtain it.
   * Only contains the activity Id filter.
   */
  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName = this.prioritisationFilterConfiguration.hierarchyElementFamilyFieldName;
    const defaultId = this.prioritisationFilterConfiguration.hierarchyElementFamily;
    this.hierarchyElementFamilyId = this.adaptersService.getFamilyId(
      filters,
      familyFieldName,
      defaultId
    );
  }

  onTreeFilterReady() {
    if (this.mustPersistFilters && this.isFirstLoad) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
      this.isFirstLoad = false;
    }
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const { hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.prioritisationFilterConfiguration;
    const adapters = [
      new HierarchyElementPathFAdapter({
        dataBindingField: 'hierarchyElementsIds',
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId: this.hierarchyElementFamilyId,
      }),
    ];
    return adapters;
  };

  setFiltersPersistencyArea(): void {
    this.bfSettings.persistencyArea = this.prioritisationFilterConfiguration.persistencyArea;
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }
}
