import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FilterItemSelectOption } from '@common-modules/common-filters/models/filter-item-select-option';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { UserDto } from '@common-modules/shared/model/roles/user.dto';
import { UsersGridODataService } from '@common-modules/shared/services/users/users.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../../core/base-select-filter-item/base-select-filter-item.component';
import { AdaptedFilterItem } from '../../core/hooks/adapted-filter-item';

const COMPONENT_SELECTOR = 'wlm-user-filter-item';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './user-filter-item.component.html',
  styleUrls: ['./user-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => UserFilterItemComponent),
    },
  ],
})
export class UserFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, AdaptedFilterItem
{
  @Input() currentUser: string;
  @Input() fieldName: string;
  @Input() filterText: string;
  @Input() set selectedId(value: any) {
    this.selectedIds = this.ensureArray(value);
  }
  @Input() set defaultSelectedId(value: any) {
    this.defaultSelectedIds = this.ensureArray(value);
  }

  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);

  mode = 'multiple';
  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;
  defaultSelectedIds: any[] = [];
  onlyActiveUsers = true;

  constructor(
    private _usersGridODataService: UsersGridODataService,
    private _objectHelper: ObjectHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.data$$.next(this.getData$());
    super.onInit();
  }

  getData$(): Observable<FilterItemSelectOption[]> {
    return this._usersGridODataService.getUsers(this.onlyActiveUsers).pipe(
      map((users: UserDto[]) => {
        const current = users.find((x) => x.userCode === this.currentUser && x.isActive);
        if (!current) {
          return [];
        }

        let userOptionItems: FilterItemSelectOption[] = [
          new FilterItemSelectOption(this.currentUser, '@Me'),
        ];
        if (current?.isSuperUser) {
          userOptionItems = userOptionItems.concat(
            users
              .filter((f) => f.isActive === true && f.userCode !== this.currentUser)
              .map((user: UserDto) => {
                return new FilterItemSelectOption(user.userCode, `${user.name} ${user.surname}`);
              })
          );
        }

        return this._objectHelper.sortObjectArray(userOptionItems, 'label');
      })
    );
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  isValid(): boolean {
    return this.isValidSelect;
  }

  getFieldNames(): string[] {
    return [this.fieldName];
  }

  ensureArray(value: any): any[] {
    if (typeof value !== 'undefined' && value !== null) {
      return Array.isArray(value) ? [...value] : [value];
    } else {
      return [];
    }
  }

  getAdapter(): FilterAdapterEnum {
    return FilterAdapterEnum.User;
  }
}
