import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { SettingsService } from '@common-modules/shared/config/settings.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import {
  DATA_VALIDATION_ESTIMATION_FUNCTIONS,
  DataValidationEstimationFunctionType,
  DataValidationSelectionType,
} from '@common-modules/wlm-charts/core/models/data-validation/data-validation-functions';
import { EstimationFunction } from '@common-modules/wlm-charts/core/models/data-validation/estimation-function';
import { EditableChartSelection } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-selection';
import { EditableChartValueRange } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-value-range';
import { BrushMode } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-brush';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataValidationChartService } from '../services/data-validation-chart.service';

const COMPONENT_SELECTOR = 'wlm-data-validation-value-ranges';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-validation-value-ranges.component.html',
  styleUrls: ['./data-validation-value-ranges.component.scss'],
})
export class DataValidationValueRangesComponent implements OnInit {
  private _valueRanges: EditableChartValueRange[];

  get valueRanges(): EditableChartValueRange[] {
    return this._valueRanges;
  }

  @Input() set valueRanges(value: EditableChartValueRange[]) {
    this._valueRanges = value;
    if (value) {
      this.updateRangesInForm();
    }
  }

  private _showCart: boolean;
  get showCart(): boolean {
    return this._showCart;
  }
  @Input() set showCart(value: boolean) {
    this._showCart = value;
    this.loadUomLabel();

    if (!this._showCart) {
      this.valueRanges = [];
    }
  }

  @Output() valueRangesChanged = new EventEmitter<EditableChartValueRange[]>();
  @Output() applyValueSelection = new EventEmitter<EditableChartSelection>();

  T_SCOPE = `${AppModules.DataValidation}.${COMPONENT_SELECTOR}`;

  estimationFunctions: EstimationFunction[];

  isLoading = false;
  rangeForm: UntypedFormGroup;

  public get fixedEstimationSelected() {
    return (
      this.rangeForm?.get(this.estimationFunctionFieldName)?.value ===
      DataValidationEstimationFunctionType.Fixed
    );
  }

  estimationFunctionFieldName = 'estimationFunction';
  fixedValueFieldName = 'fixedValue';
  fixedValueLabel$: Observable<string>;
  rangeIds: string[] = [];
  rangeStartControlPrefix = 'rangeFrom%%';
  rangeEndControlPrefix = 'rangeEnd%%';
  errorList: string[] = [];

  private _valueDebounceTime = 1000;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _dialogService: DialogService,
    private _settings: SettingsService,
    private _translateService: TranslateService,
    private _dataValidationChartService: DataValidationChartService
  ) {
    this.estimationFunctions = DATA_VALIDATION_ESTIMATION_FUNCTIONS.filter(
      (fn) => fn.selectionType !== DataValidationSelectionType.Date
    );

    this.createForm();
  }

  ngOnInit(): void {}

  onRemoveRange(rangeId: string) {
    if (this.errorList.length) {
      this._dialogService.showTranslatedMessageInSnackBar(
        new WlmDialogSettings({
          translateKey: `${this.T_SCOPE}.messages.range-errors-pendings`,
          icon: 'warning',
        })
      );
    } else {
      this.rangeForm.removeControl(`${this.rangeStartControlPrefix}${rangeId}`);
      this.rangeForm.removeControl(`${this.rangeEndControlPrefix}${rangeId}`);
      this.rangeIds = this.rangeIds.filter((f) => f !== rangeId);
      this.valueRanges = this.getRangeValuesFromRangeId();

      this.valueRangesChanged.emit(this.valueRanges);
    }
  }

  onApplyFunction() {
    this.getEditableChartSelectionModel()
      .pipe(untilDestroyed(this))
      .subscribe((selection) => {
        this.applyValueSelection.emit(selection);
        this.valueRanges = [];
      });
  }

  private getEditableChartSelectionModel(): Observable<EditableChartSelection> {
    const functionType = this.rangeForm.get(this.estimationFunctionFieldName).value;
    const estimationFunction = this.estimationFunctions.find((f) => f.type === functionType);
    const fixedValue = this.rangeForm.get(this.fixedValueFieldName).value;

    const ranges = this.getRangeValuesFromRangeId();

    return this.convertValuesFromUoM(ranges, fixedValue).pipe(
      map(([rangesConverted, fixedValueConverted]) => {
        const formatedRanges = [];
        rangesConverted.forEach((item) => {
          formatedRanges.push(
            new EditableChartValueRange({ startValue: item[0], endValue: item[1] })
          );
        });

        const formValues = {
          fixedValue: fixedValueConverted,
        };

        return new EditableChartSelection({
          selectionType: BrushMode.Vertical,
          ranges: ranges,
          rangesConverted: formatedRanges,
          estimationFunction: estimationFunction,
          formValues: formValues,
        });
      })
    );
  }

  private convertValuesFromUoM(selectedRanges: EditableChartValueRange[], fixedValue: number) {
    const ranges = selectedRanges.map((range) => [range.startValue, range.endValue]) as [
      number,
      number
    ][];
    const dimensionTypeId = this._dataValidationChartService.dimensionTypeId;
    const rangesConverted$ = this._dataValidationChartService.convertValuePairArrayFromUoM(
      ranges,
      dimensionTypeId
    );

    const fixedValueConverted$ = fixedValue
      ? this._dataValidationChartService.convertValueFromUoM(fixedValue, dimensionTypeId)
      : of(null);

    return forkJoin([rangesConverted$, fixedValueConverted$]);
  }

  private updateRangesInForm() {
    this.rangeIds = [];
    this.errorList = [];

    if (this.valueRanges?.length) {
      for (const element of this.valueRanges) {
        const id = globalUtilsHelper.generateGuid();
        this.rangeIds.push(id);

        const startValue = +element.startValue.toFixed(
          this._settings.uomMinPositionsToKeepPrecision
        );
        const endValue = +element.endValue.toFixed(this._settings.uomMinPositionsToKeepPrecision);

        const rangeStartControl = this.createRangeControl(startValue);
        const rangeEndControl = this.createRangeControl(endValue);

        rangeStartControl.addValidators(
          this.validateGreaterThan(rangeStartControl, rangeEndControl)
        );
        rangeEndControl.addValidators(this.validateGreaterThan(rangeStartControl, rangeEndControl));

        this.rangeForm.addControl(`${this.rangeStartControlPrefix}${id}`, rangeStartControl);
        this.rangeForm.addControl(`${this.rangeEndControlPrefix}${id}`, rangeEndControl);
      }
    }

    this.checkFunctionSelectorEnabledState();
  }

  private createForm() {
    const formControls: { [key: string]: UntypedFormControl } = {};

    const functionSelectorControl = this.createFunctionSelectorControl();
    formControls[this.estimationFunctionFieldName] = functionSelectorControl;
    formControls[this.fixedValueFieldName] = new UntypedFormControl(null);

    this.rangeForm = this._formBuilder.group(formControls);

    this.checkFunctionSelectorEnabledState();
  }

  private checkFunctionSelectorEnabledState() {
    if (this.rangeIds.length) {
      this.rangeForm.get(this.estimationFunctionFieldName).enable();
    } else {
      this.rangeForm.get(this.estimationFunctionFieldName).setValue(null);
      this.rangeForm.get(this.fixedValueFieldName).setValue(null);
      this.rangeForm.get(this.estimationFunctionFieldName).disable();
    }
  }

  private createFunctionSelectorControl() {
    const newControl = new UntypedFormControl(null, [Validators.required]);

    newControl.valueChanges.pipe(untilDestroyed(this)).subscribe((selectedFunction) => {
      if (selectedFunction === DataValidationEstimationFunctionType.Fixed) {
        this.rangeForm.get(this.fixedValueFieldName).setValidators([Validators.required]);
      } else {
        this.rangeForm.get(this.fixedValueFieldName).clearValidators();
        this.rangeForm.get(this.fixedValueFieldName).setValue(null);
      }

      this.rangeForm.get(this.fixedValueFieldName).updateValueAndValidity();
    });

    return newControl;
  }

  private createRangeControl(value: number): UntypedFormControl {
    const newControl = new UntypedFormControl(value, [Validators.required]);

    newControl.valueChanges
      .pipe(debounceTime(this._valueDebounceTime), untilDestroyed(this), distinctUntilChanged())
      .subscribe((x) => {
        const hasSomeInvalidRange = this.rangeIds.some((id) => !this.validateRange(id));
        this.updateErrorList();
        if (x !== null && !hasSomeInvalidRange) {
          const valueRanges = this.getRangeValuesFromRangeId();
          this.valueRangesChanged.emit(valueRanges);
        }
      });

    return newControl;
  }

  private updateErrorList() {
    const errorListUpdate = [];
    Object.keys(this.rangeForm.controls).forEach((key) => {
      if (key != this.estimationFunctionFieldName && key != this.fixedValueFieldName) {
        let control = this.rangeForm.get(key);

        if (control.invalid) {
          Object.keys(control.errors).forEach((errorKey) => {
            errorListUpdate.push(errorKey);
          });
        }
      }
    });

    this.errorList = [...new Set(errorListUpdate)];
  }

  private validateRange(id: string): boolean {
    const rangeStartControl = this.rangeForm.get(`${this.rangeStartControlPrefix}${id}`);
    const rangeEndControl = this.rangeForm.get(`${this.rangeEndControlPrefix}${id}`);

    rangeStartControl.updateValueAndValidity();
    rangeEndControl.updateValueAndValidity();

    if (rangeStartControl.status === 'INVALID' || rangeEndControl.status === 'INVALID') {
      return false;
    }

    return true;
  }

  private validateGreaterThan(firstControl: UntypedFormControl, secondControl: UntypedFormControl) {
    return () => {
      const firstValue = firstControl.value;
      const secondValue = secondControl.value;
      if (secondValue < firstValue) {
        return { greaterThan: true };
      }
      return null;
    };
  }

  private getRangeValuesFromRangeId() {
    const rangeValues: EditableChartValueRange[] = [];
    this.rangeIds.forEach((id) => {
      const rangeStartControl = this.rangeForm.get(`${this.rangeStartControlPrefix}${id}`);
      const rangeEndControl = this.rangeForm.get(`${this.rangeEndControlPrefix}${id}`);

      rangeValues.push(
        new EditableChartValueRange({
          startValue: rangeStartControl?.value,
          endValue: rangeEndControl?.value,
        })
      );
    });

    return rangeValues;
  }

  private loadUomLabel() {
    const label$ = this._translateService.get(`${this.T_SCOPE}.fields.value`);
    const unit$ = this._dataValidationChartService.getChartUnitLabel();

    this.fixedValueLabel$ = combineLatest([label$, unit$]).pipe(
      untilDestroyed(this),
      map(([label, unit]) => (unit ? `${label} [${unit}]` : label))
    );
  }
}
