import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

export class ActivityStatusFilter implements IFilter {
  status: boolean[];
  statusActivitiesFieldName: string;

  constructor(status: boolean[], statusActivitiesFieldName: string) {
    this.status = status;
    this.statusActivitiesFieldName = statusActivitiesFieldName;
  }
  getSelectedFilters(): boolean[] {
    return this.status;
  }

  private getStatusFilter(filterValue): FilterDescriptor {
    const newFilter: FilterDescriptor = {
      field: this.statusActivitiesFieldName,
      operator: 'eq',
      value: filterValue,
    };
    return newFilter;
  }

  getFiltersValues(): Map<string, any> {
    return new Map<string, any>([[this.statusActivitiesFieldName, this.status]]);
  }

  getFilters(): CompositeFilterDescriptor {
    if (!this.status) {
      return null;
    }

    const mainFilter: CompositeFilterDescriptor = {
      filters: [],
      logic: 'or',
    };

    if (this.status.every((x) => x === false)) {
      mainFilter.filters.push(this.getStatusFilter(true));
      mainFilter.filters.push(this.getStatusFilter(false));
    }

    if (this.status[0]) {
      mainFilter.filters.push(this.getStatusFilter(true));
    }

    if (this.status[1]) {
      mainFilter.filters.push(this.getStatusFilter(false));
    }

    return {
      filters: [mainFilter],
      logic: 'and',
    };
  }
}
