import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { ActivitiesGridFilterValidations } from '@common-modules/dependencies/alc/activities-grid-filter-validations';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { FilterGroupFieldSettings } from '@common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { ApplyExtendedFiltersAction } from '@common-modules/dynamic-layout/state/filters/filters.actions';
import { ResetFiltersSelector } from '@common-modules/dynamic-layout/state/filters/filters.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { ExtendedFilters } from '@common-modules/shared/filters/component-filters/extended-filters';
import { IPendingChangesChecker } from '@common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UoMScopes } from '../uom-scopes';

const COMPONENT_SELECTOR = 'wlm-uom-filter-widget';
export const UOM_FILTER_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './uom-filter-widget.component.html',
  styleUrls: ['./uom-filter-widget.component.scss'],
  providers: [ReduxStateService],
})
export class UomFilterWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit, IPendingChangesChecker
{
  private _filters: DataBindingFilters;
  private _scopeSettings = new StateScopeSettings({
    scope: UoMScopes.Main,
  });

  pageId: string;

  canLoad: boolean;
  filterDetailsParameters: TabDetailPanelParameters;
  autoLoad: boolean = true;
  persistFilters$ = new Subject<void>();
  clearAll$ = new Subject<void>();
  filterSettings: FilterGroupSettings;

  // Filters fieldNames
  hierarchyFamilyFieldName = 'hierarchyFamilyId';

  get componentName(): string {
    return 'UomFilterWidgetComponent';
  }

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);

    this.pageId = widgetSettings.page;

    this.setPersistencyArea(widgetSettings.widgetInstanceKey);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onWidgetInit(): void {
    this.setFilterSettings(null);

    this._state
      .select<number>(new ResetFiltersSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (_) => this.clearAll$.next(),
      });
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const filterValidation = new ActivitiesGridFilterValidations();

    if (
      filtersParameters &&
      filterValidation.validateFilters([this.hierarchyFamilyFieldName], filtersParameters)
    ) {
      this._filters = filtersParameters;
      this.canLoad = true;
    } else {
      this.canLoad = false;
    }
  }

  applyFilters() {
    if (this.canLoad) {
      const extendedFilters = this.buildExtendedFilters();

      this.checkPendingChanges(this.pageId).subscribe((_) => {
        this._state.dispatch(new ApplyExtendedFiltersAction(extendedFilters, this._scopeSettings));
        this.persistFilters$.next();
      });
    }
  }

  setFiltersDetailsParameters(value: TabDetailPanelParameters) {
    this.filterDetailsParameters = value;
    if (this.autoLoad && this.canLoad) {
      this.applyFilters();
      this.autoLoad = false;
    }
  }

  onCheckAutoload(): void {
    this.applyFilters();
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      filter((value) => value !== null)
    );
  }

  private setFilterSettings(params: Params) {
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      hierarchyFamilyId: new FilterGroupFieldSettings({
        fieldName: this.hierarchyFamilyFieldName,
      }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      navigationParams: params,
      persistencyArea: this.persistencyArea,
    });
  }

  private setPersistencyArea(widgetId: string) {
    this.persistencyArea = this.componentName + widgetId;
  }

  private buildExtendedFilters(): ExtendedFilters {
    const model = {};
    const familyFilter: any = this._filters.filters.get(this.hierarchyFamilyFieldName);
    if (familyFilter) {
      model[this.hierarchyFamilyFieldName] = familyFilter.familyId[0];
    }

    const extendedFilters = new ExtendedFilters({
      dataBindingFilters: this._filters,
      model,
    });

    return extendedFilters;
  }
}
