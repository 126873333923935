// prettier-ignore
import { ChangeDetectorRef, Component, ComponentRef, DoCheck, ElementRef, EventEmitter, Inject, Injector, Input, NgZone, OnInit, Optional, Output, Type, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import { BaseDynamicWidgetComponent } from '../../redux/components/base-dynamic-widget.component';
import { ArrayHelperService } from '../../shared/helpers/array-helper.service';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { ObjectHelperService } from '../../shared/helpers/object-helper.service';
import { WlmResizeObserverService } from '../../shared/services/resize-observer.service';
import { LogService } from '../../shared/wlm-log/log.service';
import { WidgetRegistryService } from '../../widget-registry/widget-registry';
import {
  DYNAMIC_LAYOUT_EXTERNAL_SETTINGS,
  DynamicLayoutExternalSettings,
} from '../dynamic-layout-external-settings';

import { DynamicLayoutConfigurableItemSettings } from '../models/dynamic-layout-configurable-item-settings';
import { DynamicLayoutIdentity } from '../models/dynamic-layout-identity';
import { DynamicLayoutItemSettings } from '../models/dynamic-layout-item-settings';
import { DynamicLayoutSettings } from '../models/dynamic-layout-settings';
import { DynamicLayoutSettingsLoadOptions } from '../models/dynamic-layout-settings-load-options';
// prettier-ignore
import { ActivatedRoute } from '@angular/router';
import { SettingsComponentType } from '@water-loss//features/shared/config/settings-component-type';
import {
  detachedLayoutQueryParam,
  IGoldenLayoutComponent,
  NativeGoldenLayoutConfig,
} from '../../golden-layout-host/golden-layout-exports';
import { DelegatePersistLayoutService } from '../delegate-persist-layout/delegate-persist-layout.service';
import { DynamicLayoutLevels } from '../models/dynamic-layout-levels';
import {
  DynamicLayoutStructure,
  DynamicLayoutStructureComponent,
  DynamicLayoutStructureStack,
} from '../models/dynamic-layout-structure';
import { DynamicLayoutWidgetSelection } from '../models/dynamic-layout-widget-selection';
import { DynamicSettings } from '../models/dynamic-settings';
import { DynamicSettingsSave } from '../models/dynamic-settings-save';
import { GoldenLayoutNode } from '../models/golden-layout-node';
import { LayoutLoadOptions } from '../models/layout-load-options';
import { LayoutNodeTypes } from '../models/layout-node-types';
import { WidgetDefinitionSettings } from '../models/widget-definition-settings';
import { DynamicLayoutHeadersService } from '../services/dynamic-layout-headers.service';
import { DynamicLayoutStyleLevelsService } from '../services/dynamic-layout-style-levels.service';
import { GoldenLayoutService } from '../services/golden-layout.service';
import { PersistLayoutService } from '../services/persist-layout.service';
import { DynamicLayoutComponentApi } from './dynamic-layout-component-api';

const COMPONENT_SELECTOR = 'wlm-dynamic-layout';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './dynamic-layout.component.html',
  styleUrls: ['./dynamic-layout.component.scss'],
  providers: [GoldenLayoutService, DynamicLayoutStyleLevelsService, DynamicLayoutHeadersService],
})
export class DynamicLayoutComponent implements OnInit, DoCheck, DynamicLayoutComponentApi {
  readonly versionClass = `dl-version-2`;

  // If a layout is a detached container, it means that, when we detect that
  // the current page has detached layout settings, they will be projected in this layout instance.
  // This is necessary because goldenLayout.isSubWindow is instantiated too late in our flow.
  @Input() isDetachedContainer = true;
  @Input() useDynamicStructure = false;
  @Input() featureInjector: Injector;
  @Input() allowEmptyRoot = false;
  @Input() containerClass = '';
  @Input() injector: Injector;
  @Input() specificLevel: DynamicLayoutLevels;

  /**
   * Alternative way of instatiating a layout, which also offers load options.
   */
  private _loadOptions: LayoutLoadOptions;

  @Input() set settingsToLoad(value: DynamicLayoutSettingsLoadOptions) {
    if (value) {
      this.loadSettingsWithOptions(value.settings, value.loadOptions);
    }
  }

  @Input() set settings(value: DynamicLayoutSettings) {
    this.loadSettingsWithOptions(value);
  }
  get settings(): DynamicLayoutSettings {
    return this._settings;
  }
  private _settings: DynamicLayoutSettings;

  @ViewChild('layoutContainer') set viewLayoutContainerRef(value: ElementRef) {
    if (value) {
      this._layoutContainerRef = value;
      // Listen to resize events in the container to adjust tabs size.
      this.listenAndResize(this._layoutContainerRef);
    }
  }

  @ViewChild('glRoot', { static: false }) set viewGlRoot(value: IGoldenLayoutComponent) {
    if (value) {
      this.glRoot = value;

      if (!this.allowEmptyRoot && !this.settings?.items?.length) {
        this.reset();
        return;
      }
      this._layoutReloaded$.next();
      // Listen to state changes.
      this.updateOnLayoutChange();
    }
  }

  @Output() resized = new EventEmitter<void>();
  @Output() layoutLoading = new EventEmitter<boolean>();
  @Output() layoutEmpty = new EventEmitter<void>();
  @Output() settingsChanged = new EventEmitter<DynamicLayoutSettings>();
  @Output() widgetDestroyed = new EventEmitter<string>();
  @Output() layoutReset = new EventEmitter<void>();
  @Output() currentLayoutIdentity = new EventEmitter<DynamicLayoutIdentity>();
  @Output() activeWidgets = new EventEmitter<DynamicLayoutWidgetSelection[]>();

  glSettings: NativeGoldenLayoutConfig;
  glSettings$: Observable<NativeGoldenLayoutConfig>;
  currentComponents: ComponentRef<BaseDynamicWidgetComponent>[] = [];
  // Specify which saved settings must be recovered.
  pageKey: string;
  private _layoutReloaded$ = new Subject<void>();
  readonly layoutReloaded$ = this._layoutReloaded$.asObservable();

  private _isLoading = true;
  get isLoading() {
    return this._isLoading;
  }
  set isLoading(value) {
    this._isLoading = value;
    this.layoutLoading.emit(this.isLoading);
  }

  private _glRoot: IGoldenLayoutComponent;
  get glRoot(): IGoldenLayoutComponent {
    return this._glRoot;
  }
  set glRoot(value: IGoldenLayoutComponent) {
    this._glRoot = value;
    if (this._glRoot?.initialize) {
      this.glRoot.initialize();
    }
    this._glRootChanged$.next(value);
  }

  private _glRootChanged$ = new BehaviorSubject<IGoldenLayoutComponent>(null);
  private _layoutContainerRef: ElementRef;
  private _resizeSubscription: Subscription;
  private _persistLayoutService: PersistLayoutService;
  private _layoutChangeSubs = new Subscription();
  private _widgetDefinitions = new Map<string, DynamicLayoutItemSettings>();

  private _componentsParamsSubscription = new Map<string, Subscription>();
  private _emitActiveStatusSubs: Subscription;
  private _lastPersistedSettings: DynamicLayoutSettings;

  private readonly _enableTitlesOverride = true;
  private readonly _enableDelegateLog = false;
  private _listenItemUpdatesSubs: Subscription;
  private _popoutsByDefault = false;
  private _expandedTitles = new Map<string, string>();
  private _expandedTitleWidths = new Map<string, number>();

  constructor(
    private readonly _ngZone: NgZone,
    private readonly _resizeObserverService: WlmResizeObserverService,
    private readonly _glService: GoldenLayoutService,
    private readonly _defaultPersistLayoutService: PersistLayoutService,
    private readonly _logService: LogService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _arrayService: ArrayHelperService,
    private readonly _widgetRegistry: WidgetRegistryService,
    private readonly _objectHelperService: ObjectHelperService,
    private readonly _element: ElementRef,
    @Optional()
    @Inject(DYNAMIC_LAYOUT_EXTERNAL_SETTINGS)
    private readonly _externalSettings: DynamicLayoutExternalSettings,
    @Optional()
    private readonly _delegatePersistLayoutService: DelegatePersistLayoutService,
    private readonly _route: ActivatedRoute,
    private readonly _dynamicLayoutHeadersService: DynamicLayoutHeadersService,
    private readonly _dlStyleLevelsService: DynamicLayoutStyleLevelsService,
    private readonly _cd: ChangeDetectorRef
  ) {}

  /**
   * Part of the component external API. Check DynamicLayoutComponentApi interface.
   */
  loadSettingsWithOptions(
    settings: DynamicLayoutSettings,
    loadOptions = new LayoutLoadOptions()
  ): void {
    this._settings = this._objectHelperService.serializedClone(settings);
    this._loadOptions = this._objectHelperService.serializedClone(loadOptions);

    this.emitLayoutIdentity();
    this._componentsParamsSubscription = new Map<string, Subscription>();

    if (settings) {
      this.registerWidgetDefinitions(this._settings.items);
      this.initializeWhenReady(loadOptions.disablePersistencyLoad);
    }
  }

  ngOnInit(): void {
    this.setLayoutLevel();
  }

  ngDoCheck(): void {
    this._dlStyleLevelsService.applyCurrentLevel(this._element.nativeElement);
  }

  private setLayoutLevel(): void {
    if (this.specificLevel) {
      this._dlStyleLevelsService.setSpecificLevel(this.specificLevel);
    } else {
      this._dlStyleLevelsService.findNextLevelClass(this._element.nativeElement);
    }
  }

  private canInitialize(): boolean {
    const result = this.settings;
    return Boolean(result);
  }

  private initializeWhenReady(disablePersistencyLoad = false): void {
    this.isLoading = true;
    if (this.canInitialize()) {
      this.setupPrerequisites();
      // Configure GL, and will set loading to false.
      this.generateGLConfig(disablePersistencyLoad);

      if (this.settings.delegatePersistency?.currentId) {
        this._delegatePersistLayoutService
          ?.listenDelegated(() => this.settings)
          .pipe(untilDestroyed(this), filter(Boolean))
          .subscribe((updatedSettings: DynamicLayoutSettings) => {
            // Assign to private variable instead of setter, because setter starts the flow again.
            this._settings = globalUtilsHelper.serializedClone(updatedSettings);
            this.saveCurrentState(this.settings);
          });
      }
    }
  }

  private setupPrerequisites(): void {
    this.configurePeristLayoutService();
  }

  private updateSettings(settings: NativeGoldenLayoutConfig): void {
    if (!settings || (!this.allowEmptyRoot && !this.settings?.items?.length)) {
      this.glSettings$ = null;
      const emptyLayout: any = {
        root: undefined,
      };
      this.glSettings = emptyLayout;
    } else {
      this.glSettings$ = of(settings);
      this.glSettings = settings;
    }

    this._changeDetectorRef.detectChanges();
  }

  private reset() {
    this.updateSettings(null);
    this.isLoading = false;
  }

  /**
   * We have a predefined persistency service that acts a default, but each layout can define its own persistency service.
   */
  private configurePeristLayoutService(): void {
    this._persistLayoutService = this._defaultPersistLayoutService;
    if (this.settings.persistLayoutService && this.featureInjector) {
      const service = this.featureInjector.get(this.settings.persistLayoutService);
      service.setInjector(this.featureInjector);
      if (service) {
        this._persistLayoutService = service;
      }
    }
  }

  /**
   * Generate the main GL settings, or recover them from storage.
   * If disablePersistencyLoad is specified, the saved state is ignored.
   */
  private generateGLConfig(disablePersistencyLoad = false): void {
    this._ngZone.run(() => {
      this.registerComponents();

      // If refresh is forced, do not try to restore state.
      if (disablePersistencyLoad || this.settings.delegatePersistency?.delegateToId) {
        this.buildNativeAndRefresh(this.settings);
      } else {
        this.restoreSavedState().subscribe({
          next: (hasBeenRestored) => {
            if (!hasBeenRestored) {
              this.buildNativeAndRefresh(this.settings);
            }
          },
          error: () => {
            this.buildNativeAndRefresh(this.settings);
          },
        });
      }
    });
  }

  private registerComponents(): void {
    // If we are instantiating two NECFilters, we must specify the same component twice here.
    // Then, after the components are instantiated by GL, we assign the missing settings.
    const widgetsHash = this.getWidgetTypesById();
    this._glService.registerComponents(widgetsHash);
  }

  /**
   * Set the saved state as the actual state. Return if the state has been restored or not.
   */
  private restoreSavedState(): Observable<boolean> {
    const { layoutKey, layoutArea } = this.settings;

    if (!layoutKey || !layoutArea) {
      return of(false);
    }

    const saveSettings = new DynamicSettings({
      settingKey: layoutKey,
      settingArea: layoutArea,
    });

    return this._persistLayoutService.load(saveSettings).pipe(
      finalize(() => (this.isLoading = false)),
      map((loadedSettings) => {
        if (loadedSettings && this.canLoadPersistency(this.settings, loadedSettings)) {
          this.buildNativeAndRefresh(loadedSettings);
          return true;
        }
        return false;
      })
    );
  }

  private buildNativeAndRefresh(newSettings: DynamicLayoutSettings): void {
    if (this.willRenderDetachedLayout()) {
      return;
    }
    let glConfig = this._glService.buildNativeConfig(newSettings);
    if (glConfig) {
      this._settings = newSettings;
      this.registerWidgetDefinitions(this._settings.items);
      this.emitSettingsChanged(this.settings);
    } else {
      // If building from the supplied settings fail, fallback to building from previous settings.
      glConfig = this._glService.buildNativeConfig(this.settings);
    }

    this.setupPrerequisites();
    this._ngZone.run(() => {
      this.registerComponents();
      this._glService.updateTranslations(glConfig, newSettings.items);

      this.refreshNativeLayout(glConfig);
      this.isLoading = false;
      this._cd.detectChanges();
    });
  }

  /**
   * We must only load persisted layouts when:
   *  - The items in the new settings are the same ones as in the current settings.
   *  - The current settings do not have a defined structure.
   */
  private canLoadPersistency(
    currentSettings: DynamicLayoutSettings,
    newSettings: DynamicLayoutSettings
  ): boolean {
    const sortBy = (item: DynamicLayoutItemSettings) => item.widgetInstanceKey;
    let currentItems = this._arrayService.sortObjects(currentSettings.items, sortBy);
    let newItems = this._arrayService.sortObjects(newSettings.items, sortBy);

    if (currentSettings.ignoreParamsOnPersistencyCheck) {
      currentItems = this._objectHelperService.clone(currentItems, true);
      newItems = this._objectHelperService.clone(newItems, true);
      currentItems.forEach((item) => (item.params = null));
      newItems.forEach((item) => (item.params = null));
    }
    const areSameItems = this._objectHelperService.deepEqual(currentItems, newItems);
    if (areSameItems) {
      const hasEmptyStructure =
        !currentSettings.structure || currentSettings.structure.length === 0;
      return hasEmptyStructure;
    }
    return false;
  }

  private emitSettingsChanged(settings: DynamicLayoutSettings): void {
    const clonedSettings = this._objectHelperService.clone(settings);
    this.settingsChanged.emit(clonedSettings);
    if (!clonedSettings.items.length) {
      this.layoutEmpty.emit();
    }
  }

  private refreshNativeLayout(config: NativeGoldenLayoutConfig): void {
    const glConfig = this._objectHelperService.serializedClone(config);

    this.ensureHeaderSettingsConsistency(glConfig);

    this._glService.ensureStyles(glConfig, this._dlStyleLevelsService.currentLayoutLevel);

    const componentNodes = this.getAllComponentNodes();
    this.refreshComponentTitleFromNodes(componentNodes);

    this.updateSettings(null);
    this.updateSettings(glConfig);
  }

  /**
   * When the right panel is resized, the components inside GL must be notified to resize as well.
   */
  private listenAndResize(value: ElementRef) {
    this.ensureClose(this._resizeSubscription);
    this._glRootChanged$.pipe(untilDestroyed(this)).subscribe(() => {
      this._resizeSubscription = this._resizeObserverService
        .observe({
          el: value.nativeElement,
        })
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          // Update size when the container changes.
          this.resizeRoot();
        });
    });
  }

  private ensureClose(subs: Subscription): void {
    if (subs?.closed) {
      subs.unsubscribe();
    }
  }

  /**
   * When the layout is changed, the active components must be obtained again.
   * Also save the current state.
   */
  private updateOnLayoutChange(): void {
    const layoutChange$ = this._glService.stateChanged$(this.glRoot);

    if (!this._layoutChangeSubs.closed) {
      this._layoutChangeSubs.unsubscribe();
    }

    // We cannot unsubscribe with untilDestroyed here, GL gives an error.
    this._layoutChangeSubs = layoutChange$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.setActiveComponents();
      this.syncDynamicSettings();
      this.extendHeaders();
      this.handleSingleTabOverflow();
    });
  }

  private extendHeaders(): void {
    this._dynamicLayoutHeadersService.extendHeaders(this);
  }

  private handleSingleTabOverflow(): void {
    this._dynamicLayoutHeadersService.handleSingleTabOverflow(
      this._expandedTitles,
      this._expandedTitleWidths
    );
  }

  private preconfigureHeaderExtends(currentNodes): void {
    this._dynamicLayoutHeadersService.preconfigureHeaderExtends(this, currentNodes);
  }

  /**
   * Listen to the stateChanged event, check the layout, and obtain all active components.
   * If the layout is set as two side-to-side tabs, there will be two active components.
   */
  private setActiveComponents(): void {
    const rootNode = this._glService.getGLInstanceRoot(this._glRoot);
    this.currentComponents = this._glService.getActiveComponents(rootNode);
    this.resized.emit(null);

    //this._logService.info({ msg: 'Updated current components', payload: this.currentComponents });

    this.checkActiveStatus();
  }

  checkActiveStatus(): void {
    const allNodes = this.getAllComponentNodes();
    const activeNodes = [];
    const inactiveNodes = [];
    allNodes.forEach((node: any) => {
      if (
        this.currentComponents.find((activeNode: any) => {
          if (activeNode.id) {
            return activeNode.id === node.id;
          }
          if (activeNode.componentType) {
            return activeNode.componentType === node.componentType;
          }
          this._logService.error({
            msg: 'Could not compare nodes',
            payload: { node1: activeNode, node2: node },
          });
          return false;
        })
      ) {
        activeNodes.push(node);
      } else {
        inactiveNodes.push(node);
      }
    });

    this._emitActiveStatusSubs?.unsubscribe();
    this._emitActiveStatusSubs = forkJoin([
      this._glService.resolveInstances(activeNodes),
      this._glService.resolveInstances(inactiveNodes),
    ]).subscribe(([activeInstances, inactiveInstances]) => {
      activeInstances.forEach((instance: BaseDynamicWidgetComponent) =>
        instance.toggleActiveStatus(true)
      );
      inactiveInstances.forEach((instance: BaseDynamicWidgetComponent) =>
        instance.toggleActiveStatus(false)
      );
      this.emitActiveWidgets(activeInstances);
    });
  }

  getComponentInstanceByKey(widgetInstanceKey: string): Observable<BaseDynamicWidgetComponent> {
    const currentNodes = this.getAllComponentNodes();

    return this._glService.resolveInstances(currentNodes).pipe(
      map((components: BaseDynamicWidgetComponent[]) => {
        return components.find(
          (component) => component.getWidgetInstanceKey() === widgetInstanceKey
        );
      })
    );
  }

  resizeRoot(): void {
    try {
      const instance = this._glService.getGLInstance(this.glRoot);
      if (instance?.updateRootSize) {
        instance.updateRootSize();
      }
    } catch {
      this._logService.info({ msg: 'Could not resize layout.' });
    }
    this.resized.emit(null);
  }

  /**
   * When the layout changes, update the structure of the settings so it matches it, without reloading the layout.
   * Then, persist the changes.
   */
  private syncDynamicSettings(): void {
    this.syncDynamicStructure();

    const currentNodes = this.getAllComponentNodes();
    this.preconfigureHeaderExtends(currentNodes);

    this._listenItemUpdatesSubs?.unsubscribe();
    this._listenItemUpdatesSubs = this.listenItemUpdates(currentNodes).subscribe(
      ({ noPendingUpdates }) => {
        if (noPendingUpdates) {
          // Get the definitions from the definitions registry, as this.settings.items may not have all available definitions.
          const currentItems = [];
          currentNodes.forEach((node) => {
            const genericNode = this._glService.getComponentRenderedNode(node);
            const instanceKey = genericNode.instanceId;
            if (this._widgetDefinitions.has(instanceKey)) {
              currentItems.push(this._widgetDefinitions.get(instanceKey));
            } else {
              this._logService.error({
                msg: `The instance id ${instanceKey} does not have a matching configuration.`,
              });
            }
          });

          this.refreshComponentTitleFromNodes(currentNodes);
          this.updateItems(currentItems);
          this.emitSettingsChanged(this.settings);
          this.saveCurrentState(this.settings);
        }
      }
    );
  }
  private syncDynamicStructure(): void {
    const glConfig = this._glService.getGLInstanceConfig(this.glRoot);
    const updatedSettings = this._glService.fromNativeToDynamic(glConfig, this.settings);

    this._settings = globalUtilsHelper.clone(this.settings, true);
    this.settings.structure = updatedSettings.structure;
  }

  private getAllComponentNodes(): GoldenLayoutNode[] {
    if (!this.glRoot) {
      return [];
    }

    const currentNodes = this._glService.getAllComponentNodes(
      this._glService.getGLInstanceRoot(this.glRoot)
    );

    return currentNodes;
  }

  private listenItemUpdates(
    currentNodes: GoldenLayoutNode[]
  ): Observable<{ noPendingUpdates: boolean }> {
    return this._glService.resolveInstances(currentNodes).pipe(
      map((components: BaseDynamicWidgetComponent[]) => {
        const anyPendingUpdate = components.find((component) => component.hasPendingUpdates);

        components
          .filter((component) => !!component.itemSettingsUpdated$)
          .forEach((component) => {
            const previous$ = this._componentsParamsSubscription.get(
              component.getWidgetInstanceKey()
            );
            if (previous$) {
              previous$.unsubscribe();
            }

            const changeParams$ = component.itemSettingsUpdated$.subscribe(
              (configurableSettings) => {
                this.onItemUpdated(configurableSettings, currentNodes);
                component.hasPendingUpdates = false;
              }
            );

            this._componentsParamsSubscription.set(component.getWidgetInstanceKey(), changeParams$);
          });

        return { noPendingUpdates: !anyPendingUpdate };
      })
    );
  }

  private onItemUpdated(
    configurableSettings: DynamicLayoutConfigurableItemSettings,
    currentNodes: GoldenLayoutNode[]
  ): void {
    const componentSetting = this.settings.items.find(
      (x) => x.widgetInstanceKey == configurableSettings.widgetInstanceKey
    );

    if (!componentSetting) {
      return;
    }

    const clone = (data) => globalUtilsHelper.clone(data, true);

    const clonedSetting = clone(componentSetting);
    clonedSetting.title = configurableSettings.title;

    clonedSetting.defaultFilters = clone(configurableSettings.defaultFilters);
    clonedSetting.lockFilters = clone(configurableSettings.lockFilters);

    if (configurableSettings.params) {
      clonedSetting.params = clone(configurableSettings.params);
    }

    const allSettings = this.settings.items.filter(
      (x) => x.widgetInstanceKey !== configurableSettings.widgetInstanceKey
    );

    allSettings.push(clonedSetting);
    this.updateItems(allSettings);
    this.settingsChanged.emit(this.settings);
    this.saveCurrentState(this.settings);

    const updatedNode = this.findNode(configurableSettings.widgetInstanceKey, currentNodes);
    this.refreshComponentTitle(configurableSettings.title, updatedNode);
  }

  private updateItems(items: DynamicLayoutItemSettings[]): void {
    this._settings = globalUtilsHelper.clone(this.settings, true);
    this.settings.items = items;
    this.registerWidgetDefinitions(this._settings.items);
    if (!this.settings.items.length) {
      this.layoutEmpty.emit();
    }
  }

  /**
   * If a title exists insde componentState, it is supposed to be
   * the built title that should replace the original one.
   */
  private refreshComponentTitle(fullTitle: string, node): void {
    if (fullTitle && this._enableTitlesOverride) {
      let titleElement = node?.tab?.titleElement;
      titleElement = titleElement?.length ? titleElement[0] : titleElement;
      titleElement.innerHTML = fullTitle;
    }
  }

  private refreshComponentTitleFromNodes(nodes: GoldenLayoutNode[]): void {
    nodes?.forEach((node) => {
      const genericNode = this._glService.getComponentRenderedNode(node);
      if (genericNode?.state?.widgetInstanceKey) {
        const widgetInstanceKey = genericNode.state.widgetInstanceKey;
        const fullTitle = this._widgetDefinitions.get(widgetInstanceKey)?.title;
        if (fullTitle) {
          this.refreshComponentTitle(fullTitle, node);
        }
      }
    });
  }

  private findNode(widgetId: string, inputCurrentNodes?: GoldenLayoutNode[]): GoldenLayoutNode {
    const currentNodes =
      inputCurrentNodes ??
      this._glService.getAllComponentNodes(this._glService.getGLInstanceRoot(this.glRoot));
    const selectedNode = currentNodes.find((node) => {
      const genericNode = this._glService.getComponentRenderedNode(node);
      return genericNode.instanceId === widgetId;
    });
    return selectedNode;
  }

  /**
   * Save the current state of the layout.
   */
  private saveCurrentState(settings: DynamicLayoutSettings): void {
    if (
      !settings ||
      !settings.layoutKey ||
      !settings.layoutArea ||
      globalUtilsHelper.deepEqual(this._lastPersistedSettings, settings) ||
      this.getSettingsToLoad()?.disablePersistencySave
    ) {
      return;
    }

    if (this.isNestedLayoutLeaf(settings)) {
      if (this._enableDelegateLog && settings.delegatePersistency?.currentId) {
        this.logDelegatedTo(settings);
      }
      // Delegate persistency to another component (defined in the settings)
      this._delegatePersistLayoutService.delegate(
        settings.delegatePersistency.delegateToId,
        settings
      );
    } else {
      if (this._objectHelperService.deepEqual(this._lastPersistedSettings, settings)) {
        return;
      }
      this._lastPersistedSettings = this._objectHelperService.clone(settings, true);

      if (this._enableDelegateLog && settings?.delegatePersistency?.currentId) {
        this.logDelegatePersisted(settings);
      }

      // Use normal persistency
      this._persistLayoutService
        .save(
          new DynamicSettingsSave({
            createComponentIfNoExists: true,
            componentTypeId: SettingsComponentType.Layout,
            settingKey: this.settings.layoutKey,
            settingArea: this.settings.layoutArea,
            settingValue: JSON.stringify(settings),
          })
        )
        .subscribe();
    }
  }

  private isNestedLayoutLeaf(settings: DynamicLayoutSettings): boolean {
    return !!settings.delegatePersistency?.delegateToId;
  }

  /**
   * Return the layout to its original state (rebuild it).
   */
  backToInitialState(): void {
    this.isLoading = true;

    const resetSettings = this.buildResetSettings(this.settings);
    this.saveCurrentState(resetSettings);

    this._ngZone.run(() => {
      this.buildNativeAndRefresh(resetSettings);
    });

    this.isLoading = false;
    this._changeDetectorRef.detectChanges();

    this.layoutReset.emit();
  }

  private buildResetSettings(settings: DynamicLayoutSettings): DynamicLayoutSettings {
    const resetSettings: DynamicLayoutSettings = globalUtilsHelper.serializedClone(settings);
    if (resetSettings.defaultStructure) {
      resetSettings.structure = globalUtilsHelper.serializedClone(resetSettings.defaultStructure);
    } else {
      resetSettings.structure = this._glService.buildDynamicStackedStructure(settings);
    }

    return resetSettings;
  }

  getWidgetTypesById(): Map<string, Type<BaseDynamicWidgetComponent>> {
    const allTypesByName = new Map<string, Type<BaseDynamicWidgetComponent>>();

    const componentNames = this._arrayService.onlyUnique(
      this._settings.items.map((item) => item.componentName)
    );
    componentNames.forEach((componentName) => {
      const widget = this._widgetRegistry.get(componentName);
      allTypesByName.set(componentName, widget);
    });

    const result = new Map<string, Type<BaseDynamicWidgetComponent>>();
    this._settings.items.forEach((item) => {
      const currentType = allTypesByName.get(item.componentName);
      if (!currentType) {
        throw new Error(
          `RDX007: The type ${item.componentName} has not been declared as an available component.`
        );
      }
      result.set(item.widgetInstanceKey, currentType);
    });
    return result;
  }

  addWidgetDefinition(definition: WidgetDefinitionSettings): void {
    if (!this.settings) {
      throw new Error('Attempting to add a widget to an undefined layout.');
    }

    const clonedSettings = this._objectHelperService.serializedClone(this.settings);

    clonedSettings.items.push(definition.widgetSettings);
    this.registerWidgetDefinitions(clonedSettings.items);

    const newWidgetInstanceKey = definition.widgetSettings.widgetInstanceKey;
    clonedSettings.structure = this.addWidgetToStructure(
      clonedSettings.structure,
      newWidgetInstanceKey
    );

    // This is not necessary because layoutChange$ is going to emit and persist.
    // this.saveCurrentState(clonedSettings);
    this.buildNativeAndRefresh(clonedSettings);
  }

  addWidget(widget: DynamicLayoutItemSettings): void {
    const definition: WidgetDefinitionSettings = {
      widgetSettings: widget,
      label: null,
      group: null,
      icon: null,
      isSvgIcon: false,
    };

    this.addWidgetDefinition(definition);
  }

  private addWidgetToStructure(
    initialStructure: DynamicLayoutStructure[],
    newWidgetInstanceKey: string
  ): DynamicLayoutStructure[] {
    let structure;
    if (!initialStructure || initialStructure.length === 0) {
      structure = [this.buildStackNode([])];
    } else {
      structure = this._objectHelperService.serializedClone(initialStructure);
    }

    if (structure.length !== 1) {
      throw new Error('The layout must only have one root.');
    }

    const componentNode = this.buildComponentNode(newWidgetInstanceKey);
    const rootNode = structure[0];
    // A stack must always exists, because we checked it previously.
    const firstStackNode = this.findFirstStackNode(rootNode);
    const content = this._glService.getNodeContent(firstStackNode);
    content.push(componentNode);

    return structure;
  }

  private findFirstStackNode(node: DynamicLayoutStructure): DynamicLayoutStructureStack {
    const content = this._glService.getNodeContent(node);
    if (node.type === LayoutNodeTypes.Stack) {
      return this._glService.amendStack(node);
    } else if (node.type === LayoutNodeTypes.Component || !content) {
      return null;
    }

    for (let i = 0; i < content.length; i++) {
      const childNode = content[i];
      const foundNode = this.findFirstStackNode(childNode);
      if (foundNode !== null && foundNode.type === LayoutNodeTypes.Stack) {
        return this._glService.amendStack(foundNode);
      }
    }
  }

  private buildStackNode(content: DynamicLayoutStructure[]): DynamicLayoutStructure {
    const stackNode: DynamicLayoutStructureStack = {
      type: LayoutNodeTypes.Stack,
      content,
      activeItemIndex: 0,
    };
    return stackNode;
  }

  private buildComponentNode(widgetInstanceKey: string): DynamicLayoutStructure {
    const componentNode: DynamicLayoutStructureComponent = {
      type: LayoutNodeTypes.Component,
      widgetInstanceKey,
    };
    return componentNode;
  }

  resetToDefault(): void {
    this.backToInitialState();
  }

  private registerWidgetDefinitions(items: DynamicLayoutItemSettings[]): void {
    items.forEach((item) => {
      const key = item.widgetInstanceKey;
      // We should not check if the widget is already in the definitions,
      // because the filters may have changed.
      this._widgetDefinitions.set(key, item);
    });
  }

  private emitLayoutIdentity(): void {
    if (!this.settings) {
      return;
    }
    // If the settings are not set, we must emit it as empty so the listeners understand that there is no selection.
    const identity: DynamicLayoutIdentity = {
      layoutId: null,
      title: null,
    };

    if (this.settings?.layoutId) {
      identity.layoutId = this.settings.layoutId;
    }

    if (this.settings?.title) {
      identity.title = this.settings.title;
    }

    this.currentLayoutIdentity.emit(identity);
  }

  private ensureCloseFlagConsistency(config: NativeGoldenLayoutConfig): void {
    // This must always be ensured for everything except components, because it triggers the automatic resize when widgets are closed.
    // However, wether to show the close button of the stacks is configurable via settings.
    // #New_GL_Version: UPDATE: this flag cannot be used in v2 for this purpose, because tabs stop being draggable in some cases.
    this._glService.travelConfigAllTypes(config, (data) => {
      if (data.contentItem.type !== 'component') {
        data.contentItem.isClosable = this._glService.getClosableComponentFlag(true);
        if (typeof data.contentItem.isClosable === 'undefined') {
          delete data.contentItem.isClosable;
        }
      }
    });
  }

  private ensureHeaderSettingsConsistency(config: NativeGoldenLayoutConfig): void {
    this._glService.travelConfigAllTypes(config, (data) => {
      if (data.contentItem.type === 'component' || data.contentItem.type === 'stack') {
        if (!data.contentItem['header']) {
          data.contentItem['header'] = {};
        }
        data.contentItem['header']['popout'] = this._popoutsByDefault;
      }
    });
  }

  willRenderDetachedLayout(): boolean {
    const result =
      this.isDetachedContainer && this._route.snapshot.queryParamMap.has(detachedLayoutQueryParam);
    return result;
  }

  getChartInstance = () => this.settings;
  getSettingsToLoad = () => this._loadOptions;

  get glService() {
    return this._glService;
  }

  get externalSettings() {
    return this._externalSettings;
  }

  private emitActiveWidgets(components: BaseDynamicWidgetComponent[]): void {
    const widgets: DynamicLayoutWidgetSelection[] = [];

    components.forEach((component) => {
      widgets.push({
        componentName: component.componentName,
        instanceKey: component.getWidgetInstanceKey(),
      });
    });

    this.activeWidgets.emit(widgets);
  }

  private logDelegatedTo(settings: DynamicLayoutSettings): void {
    console.log(
      `(${settings.debugName})`,
      settings.delegatePersistency.currentId,
      'is delegated to',
      settings.delegatePersistency.delegateToId,
      settings
    );
  }

  private logDelegatePersisted(settings: DynamicLayoutSettings): void {
    console.log(
      `(${settings.debugName})`,
      settings.delegatePersistency.currentId,
      ' is persisted',
      settings
    );
  }
}
