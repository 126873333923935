import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { DynamicLayoutModule } from '@common-modules/dynamic-layout/dynamic-layout.module';
import { GenericStoreService } from '@common-modules/dynamic-layout/state/generic/generic-store.service';
import { ReduxStateModule } from '@common-modules/redux/redux-state.module';
import { DeletedZonesService } from '@common-modules/shared-component/combined-grids/deleted-zones-grid/deleted-zones-service';
import { HierarchyElementsSelectionService } from '@common-modules/shared-component/combined-grids/hierarchy-elements-selection-grid/hierarchy-elements-selection.service';
import { NetworkElementsLarsAndSworpsSitesService } from '@common-modules/shared-component/combined-grids/ne-selection-grid/ne-lars-sworps-sites.service';
import { NetworkElementsService } from '@common-modules/shared-component/combined-grids/ne-selection-grid/ne-selection.service';
import { CommonSharedComponentModule } from '@common-modules/shared-component/common-shared-component.module';
import { CommonSharedModule } from '@common-modules/shared/common-shared.module';
import { MaterialSharedModule } from '@common-modules/shared/material-shared.module';
import { WidgetRegistryService } from '@common-modules/widget-registry/widget-registry';
import { WLMGridModule } from '@common-modules/wlm-grid/wlm-grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { DataVisualizationAlgorithmsSelectorItemComponent } from './components/data-visualization-algorithms-selector-item/data-visualization-algorithms-selector-item.component';
import { DataVisualizationAlgorithmsSelectorComponent } from './components/data-visualization-algorithms-selector/data-visualization-algorithms-selector.component';
import { DataVisualizationCartGroupComponent } from './components/data-visualization-cart/data-visualization-cart-group/data-visualization-cart-group.component';
import { DataVisualizationCartComponent } from './components/data-visualization-cart/data-visualization-cart.component';
import { DataVisualizationChartConfigPopupComponent } from './components/data-visualization-chart-config-popup/data-visualization-chart-config-popup.component';
import { DataVisualizationChartComponent } from './components/data-visualization-chart/data-visualization-chart.component';
import { DataVisualizationConfigFormComponent } from './components/data-visualization-config-form/data-visualization-config-form.component';
import { DataVisualizationGridComponent } from './components/data-visualization-grid/data-visualization-grid.component';
import { DataVisualizationHeAlgorithmPopupComponent } from './components/data-visualization-he-algorithm-popup/data-visualization-he-algorithm-popup.component';
import { DataVisualizationItemDetailsRowComponent } from './components/data-visualization-item-details-row/data-visualization-item-details-row.component';
import { DataVisualizationItemDetailsComponent } from './components/data-visualization-item-details/data-visualization-item-details.component';
import { DataVisualizationManageWorkspacePopupComponent } from './components/data-visualization-manage-workspace-popup/data-visualization-manage-workspace-popup.component';
import { DataVisualizationNeAlgorithmPopupComponent } from './components/data-visualization-ne-algorithm-popup/data-visualization-ne-algorithm-popup.component';
import { DataVisualizationPageComponent } from './components/data-visualization-page/data-visualization-page.component';
import { DataVisualizationPointsSelectorComponent } from './components/data-visualization-points-selector/data-visualization-points-selector.component';
import { TelemetryPointsAllService } from './components/data-visualization-points-selector/telemetry-points-all.service';
import { DataVisualizationSaveConfigPopupComponent } from './components/data-visualization-save-config-popup/data-visualization-save-config-popup.component';
import { DataVisualizationWorkspaceSelectorComponent } from './components/data-visualization-workspace-selector/data-visualization-workspace-selector.component';
import { WorkspaceService } from './components/data-visualization-workspace-selector/workspace.service';
import { DataVisualizationRoutingModule } from './data-visualization-routing.module';
import { DataVisualizationService } from './services/data-visualization.service';
import { DataVisualizationAlgorithmValuesWidgetComponent } from './widgets/data-visualization-algorithm-values-widget/data-visualization-algorithm-values-widget.component';
import { DataVisualizationAlgorithmsSelectorWidgetComponent } from './widgets/data-visualization-algorithms-selector-widget/data-visualization-algorithms-selector-widget.component';
import { DataVisualizationCartWidgetComponent } from './widgets/data-visualization-cart-widget/data-visualization-cart-widget.component';
import { DataVisualizationChartWidgetComponent } from './widgets/data-visualization-chart-widget/data-visualization-chart-widget.component';
import { DataVisualizationPointValuesWidgetComponent } from './widgets/data-visualization-point-values-widget/data-visualization-point-values-widget.component';
import { DataVisualizationPointsSelectorWidgetComponent } from './widgets/data-visualization-points-selector-widget/data-visualization-points-selector-widget.component';
import { DataVisualizationWorkspaceEditorWidgetComponent } from './widgets/data-visualization-workspace-editor-widget/data-visualization-workspace-editor-widget.component';
import { DataVisualizationWorkspaceSelectorWidgetComponent } from './widgets/data-visualization-workspace-selector-widget/data-visualization-workspace-selector-widget.component';

const fieldAppearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [
    DataVisualizationPageComponent,
    DataVisualizationAlgorithmsSelectorWidgetComponent,
    DataVisualizationAlgorithmsSelectorComponent,
    DataVisualizationAlgorithmsSelectorItemComponent,
    DataVisualizationPointsSelectorComponent,
    DataVisualizationPageComponent,
    DataVisualizationPointsSelectorWidgetComponent,
    DataVisualizationCartWidgetComponent,
    DataVisualizationChartWidgetComponent,
    DataVisualizationPointValuesWidgetComponent,
    DataVisualizationAlgorithmValuesWidgetComponent,
    DataVisualizationHeAlgorithmPopupComponent,
    DataVisualizationNeAlgorithmPopupComponent,
    DataVisualizationCartComponent,
    DataVisualizationCartGroupComponent,
    DataVisualizationChartComponent,
    DataVisualizationGridComponent,
    DataVisualizationChartConfigPopupComponent,
    DataVisualizationConfigFormComponent,
    DataVisualizationSaveConfigPopupComponent,
    DataVisualizationManageWorkspacePopupComponent,
    DataVisualizationWorkspaceSelectorComponent,
    DataVisualizationWorkspaceSelectorWidgetComponent,
    DataVisualizationWorkspaceEditorWidgetComponent,
    DataVisualizationItemDetailsComponent,
    DataVisualizationItemDetailsRowComponent,
  ],
  imports: [
    DataVisualizationRoutingModule,
    CommonModule,
    CommonSharedModule,
    CommonSharedComponentModule,
    DynamicLayoutModule,
    ReduxStateModule.forFeature({
      storeServices: [GenericStoreService],
    }),
    MaterialSharedModule,
    TranslateModule,
    WlmChartsModule,
    WlmFiltersModule,
    WLMGridModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [],
  providers: [
    DataVisualizationService,
    HierarchyElementsSelectionService,
    {
      provide: 'HierarchyElementsSelectionService',
      useExisting: HierarchyElementsSelectionService,
    },
    NetworkElementsService,
    { provide: 'NetworkElementsService', useExisting: NetworkElementsService },
    NetworkElementsLarsAndSworpsSitesService,
    {
      provide: 'NetworkElementsLarsAndSworpsSitesService',
      useExisting: NetworkElementsLarsAndSworpsSitesService,
    },
    TelemetryPointsAllService,
    { provide: 'TelemetryPointsAllService', useExisting: TelemetryPointsAllService },
    WorkspaceService,
    { provide: 'WorkspaceService', useExisting: WorkspaceService },
    DeletedZonesService,
    { provide: 'DeletedZonesService', useExisting: DeletedZonesService },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: fieldAppearance,
    },
  ],
})
export class DataVisualizationModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      {
        name: 'DataVisualizationPointsSelectorWidgetComponent',
        widgetClass: DataVisualizationPointsSelectorWidgetComponent,
      },
      {
        name: 'DataVisualizationAlgorithmsSelectorWidgetComponent',
        widgetClass: DataVisualizationAlgorithmsSelectorWidgetComponent,
      },
      {
        name: 'DataVisualizationCartWidgetComponent',
        widgetClass: DataVisualizationCartWidgetComponent,
      },
      {
        name: 'DataVisualizationChartWidgetComponent',
        widgetClass: DataVisualizationChartWidgetComponent,
      },
      {
        name: 'DataVisualizationPointValuesWidgetComponent',
        widgetClass: DataVisualizationPointValuesWidgetComponent,
      },
      {
        name: 'DataVisualizationAlgorithmValuesWidgetComponent',
        widgetClass: DataVisualizationAlgorithmValuesWidgetComponent,
      },
      {
        name: 'DataVisualizationWorkspaceSelectorWidgetComponent',
        widgetClass: DataVisualizationWorkspaceSelectorWidgetComponent,
      },
      {
        name: 'DataVisualizationWorkspaceEditorWidgetComponent',
        widgetClass: DataVisualizationWorkspaceEditorWidgetComponent,
      },
    ]);
  }
}
