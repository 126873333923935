import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import * as constants from '@common-modules/dependencies/shared/hierarchy-tree-filter-constants';
import { ArrayValuesFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/array-values-f-adapter';
import { DateRangeFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/date-range-f-adapter';
import { DimensionTypeFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/dimension-type-f-adapter';
import { HierarchyElementPathFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FiltersAdapterService } from '@common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { WaterBalanceFiltersConfiguration } from './water-balance-filters-configuration';

const COMPONENT_SELECTOR = 'wlm-water-balance-filters';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './water-balance-filters.component.html',
  styleUrls: ['./water-balance-filters.component.scss'],
})
export class WaterBalanceFiltersComponent extends BasePageFiltersComponent implements OnInit {
  @Output() filter = new EventEmitter<Map<string, BasicFilter>>();
  private _config: WaterBalanceFiltersConfiguration;
  public get config(): WaterBalanceFiltersConfiguration {
    return this._config;
  }
  @Input() public set config(v: WaterBalanceFiltersConfiguration) {
    this._config = v;
    if (v) {
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  // Communicates hierarchy-family-filter-item with hierarchy-tree-filter-item.
  hierarchyElementFamilyId: string;

  mustPersistFilters = false;

  T_SCOPE = `${AppModules.WaterBalance}.${COMPONENT_SELECTOR}`;

  _filtersParameters = new DataBindingFilters();
  _filtersDetailParameters = new TabDetailPanelParameters();

  treeSettings: ITreeSettings = null;
  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
    disableSelectAll: true,
  };
  bfTreeSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-tree-label`,
  };
  bfDateSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-date-label`,
    disableSearch: true,
  };
  bfTypeSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-type-label`,
  };
  bfDimensionSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-dimension-label`,
  };

  hierarchyFamilyItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
    required: true,
  });
  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });
  dateRangeItemSettings = new BaseFilterItemSettings({
    storageLocation: 'session',
    hideInputSummaryLabel: true,
    required: true,
  });
  wbTypeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });
  wbDimensionItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  constructor(
    private localization: LocalizationHelperService,
    private adaptersService: FiltersAdapterService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  ngOnInit(): void {
    this.hierarchyElementFamilyId = this.config.hierarchyElementFamily;
    this.setFiltersPersistencyArea();
    this.buildTreeSettings();
  }

  setFiltersPersistencyArea() {
    this.bfTreeSettings.persistencyArea = this.config?.persistencyArea;
    this.bfDateSettings.persistencyArea = this.config?.persistencyArea;
    this.bfTypeSettings.persistencyArea = this.config?.persistencyArea;
    this.bfDimensionSettings.persistencyArea = this.config?.persistencyArea;
  }

  /**
   * Build the tree settings when the translations are ready.
   */
  buildTreeSettings(): void {
    this.localization.get(['common.search', 'common.search-placeholder']).subscribe((ts) => {
      this.treeSettings = {
        selectionMode: constants.treeSelectionMode.multiple,
        aoiList: [],
        unselectableTypes: [],
        minLenghtToSearch: 2,
        autocompleteSetting: {
          itemSource: [],
          placeHolderText: ts['common.search-placeholder'],
          searchBoxTitle: ts['common.search'],
        },
        displayableField: constants.hierarchyElementPathFields.Descendant,
        focusDelay: 4000,
        showRecursiveToggle: false,
        showSelectedNodesChips: true,
      };
    });
  }

  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName = this.config.hierarchyElementFamilyFieldName;
    const defaultId = this.config.hierarchyElementFamily;
    this.hierarchyElementFamilyId = this.adaptersService.getFamilyId(
      filters,
      familyFieldName,
      defaultId
    );
  }

  onTreeFilterReady() {
    if (this.mustPersistFilters) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
    }
  }

  buildKeysToComplete(): void {
    const {
      hierarchyElementIdFieldName,
      waterBalanceDimensionFieldName,
      startDateFieldName,
      waterBalanceTypeFieldName,
    } = this.config;

    this.keysToComplete = [
      hierarchyElementIdFieldName,
      waterBalanceDimensionFieldName,
      startDateFieldName,
      waterBalanceTypeFieldName,
    ];
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const { hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } = this.config;
    const { startDateFieldName, endDateFieldName, allowsDateNull } = this.config;
    const { waterBalanceTypeFieldName } = this.config;
    const { waterBalanceDimensionFieldName } = this.config;

    const adapters = [
      new DimensionTypeFAdapter({
        dataBindingField: 'dimension',
        fieldName: waterBalanceDimensionFieldName,
        tabParameter: TabDetailParameterName.dimensionType,
        multiple: false,
      }),
      new ArrayValuesFAdapter({
        dataBindingField: 'waterBalanceType',
        fieldName: waterBalanceTypeFieldName,
        tabParameter: TabDetailParameterName.waterBalanceType,
        multiple: false,
      }),
      new DateRangeFAdapter({
        dataBindingField: 'dateRange',
        endDateFieldName,
        startDateFieldName,
        allowsNullDate: allowsDateNull,
        inclusive: true,
      }),
      new HierarchyElementPathFAdapter({
        dataBindingField: 'hierarchyElementsIds',
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId: this.hierarchyElementFamilyId,
      }),
    ];
    return adapters;
  };

  filtersResultChanged(results: FilterAdapterResult) {
    this.filter.emit(results?.payload?.data);
    this.onGlobalFilterResults(results);
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }
}
