import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { CrudConstants } from '@common-modules/dependencies/shared/crud-constants';
import { FilterGroupFieldSettings } from '@common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthorizeService } from '@common-modules/shared/auth/services/authorize.service';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { DragListCardSettings } from '@common-modules/shared/core/drag-list-card/drag-list-card-settings';
import { DragListCustomSettings } from '@common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { DragListSettings } from '@common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { PendingChanges } from '@common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from '@common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ElementNetworkElementsConfigurationDto } from '../../shared/model/ne/ene-configuration.dto';

import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { NeConfigurationEneService } from './ne-configuration-elements-network-elements.service';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-elements-network-elements';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-elements-network-elements.component.html',
  styleUrls: ['./ne-configuration-elements-network-elements.component.scss'],
})
export class NeConfigurationElementsNetworkElementsComponent
  extends BaseWidgetComponent
  implements OnInit, IPendingChangesEmitter
{
  private _selectedNE: INetworkElementDto;

  public get selectedNE(): INetworkElementDto {
    return this._selectedNE;
  }

  @Input() public set selectedNE(value: INetworkElementDto) {
    if (value) {
      this._selectedNE = value;
      this.applyFilters();
      this.loadENEConfiguration(value);
    }
  }

  @Input() pageId: string;
  @Input() widgetId: string;

  get componentName(): string {
    return 'NeConfigurationElementsNetworkElementsComponent';
  }
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  isReadOnly = false;
  settings: DragListSettings;
  settingsCustom: DragListCustomSettings;
  queryParams: Map<string, any>;
  refreshList$ = new Subject<void>();
  reloadList$ = new Subject<void>();
  excludedNE: INetworkElementDto[] = [];
  configuredNE: INetworkElementDto[] = [];
  cardSettings: DragListCardSettings;
  persistFilters$ = new Subject<void>();
  filterSettings: FilterGroupSettings;
  clearAll$ = new Subject<void>();

  canLoad: boolean;
  filterDetailsParameters: TabDetailPanelParameters;
  autoLoad: boolean;

  private _configurationHasChanged = false;
  public get configurationHasChanged() {
    return this._configurationHasChanged;
  }
  public set configurationHasChanged(value) {
    this._configurationHasChanged = value;
    this.setPendingChanges(this.pageId, this.getPendingChanges(value));
  }

  // Filters fieldNames
  private _originalConfiguredNE: INetworkElementDto[] = [];

  private _titleFieldName = 'title';
  private _networElementDescriptionFieldName = 'networkElementDescription';
  private _networkElementNameFieldName = 'networkElementName';
  private _serviceName = 'NeConfigurationEneService';
  private _pagesize = 50;

  private _networkElementTypeIdFieldName = 'networkElementTypeId';
  private _filters: DataBindingFilters;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _eneService: NeConfigurationEneService,
    private readonly _dialogService: DialogService,
    private readonly _spinnerService: SpinnerService,
    private readonly _arrayHelperService: ArrayHelperService,
    private readonly _authorizeService: AuthorizeService,
    private readonly _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkReadOnlyAccess();
    this.prepareListsSettings();
    this.setFilterSettings();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  checkReadOnlyAccess() {
    this._authorizeService
      .canAccess(CrudConstants.NetworkElementCrud, 'u')
      .subscribe((canAccess) => {
        this.isReadOnly = !canAccess;
      });
  }

  prepareListsSettings() {
    this.settings = new DragListSettings({
      dataService: this._serviceName,
      pageSize: this._pagesize,
      orderBy: [{ field: this._networkElementNameFieldName, dir: 'asc' }],
      useQueryParams: true,
      displayFieldName: this._titleFieldName,
      isReadOnly: this.isReadOnly,
      scrollId: this.widgetId,
    });
    this.settingsCustom = new DragListCustomSettings({
      hideFilter: true,
      emptyLegendKey: this.T_SCOPE + '.messages.drag-list-empty-message',
      isReadOnly: this.isReadOnly,
    });
    this.cardSettings = new DragListCardSettings({
      fields: [this._networkElementNameFieldName, this._networElementDescriptionFieldName],
      fieldLabels: {
        networkElementName: this._networkElementNameFieldName,
        networkElementDescription: this._networElementDescriptionFieldName,
      },
      iconName: 'card-handler',
      isSvg: true,
      isReadOnly: this.isReadOnly,
    });
  }

  updateQueryParams() {
    //Get params from filters
    const newParams = new Map<string, any>();
    this._filters.filters.forEach((value, key) => {
      const filter = value.getFiltersValues();
      filter.forEach((filterValue, fieldName) => {
        newParams.set(fieldName, filterValue);
      });
    });

    this.queryParams = newParams;
  }

  compareNE() {
    if (this.listHasChanged(this.configuredNE, this._originalConfiguredNE)) {
      this.configurationHasChanged = true;
      return;
    }

    this.configurationHasChanged = false;
  }

  listHasChanged(current: INetworkElementDto[], original: INetworkElementDto[]) {
    const configurationHasChanged = !this._arrayHelperService.areSame(current, original);
    return configurationHasChanged;
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    this.canLoad = filtersParameters.exportableFilter !== undefined;
    this._filters = filtersParameters;
  }

  applyFilters() {
    if (this.canLoad) {
      this.updateQueryParams();
      this.persistFilters$.next();
    }
  }

  setFiltersDetailsParameters(value: TabDetailPanelParameters) {
    this.filterDetailsParameters = value;
    if (this.autoLoad && this.canLoad) {
      this.applyFilters();
      this.autoLoad = false;
    }
  }

  onCheckAutoload(): void {
    this.applyFilters();
  }

  onDroppedElement($event) {
    const newExcludedNEs = [...this.excludedNE];
    newExcludedNEs.push($event);
    this.excludedNE = [...newExcludedNEs];
  }

  onSave() {
    this.save().subscribe(() => {});
  }

  save(networkElement?: INetworkElementDto): Observable<boolean> {
    this.setLoading(true);
    networkElement = networkElement === undefined ? this.selectedNE : networkElement;

    const newConfiguration = new ElementNetworkElementsConfigurationDto({
      parent: networkElement,
      childrens: this.configuredNE,
    });

    return this._eneService.saveElementNetworkElementsConfiguration(newConfiguration).pipe(
      finalize(() => this.setLoading(false)),
      tap((result) => {
        let dialogSettings = new WlmDialogSettings({ translateKey: '' });
        if (!result) {
          dialogSettings.translateKey = `${this.T_SCOPE}.messages.save-error`;
          dialogSettings.icon = 'error';
          this._dialogService.showTranslatedMessage(dialogSettings);
          return;
        } else {
          this._dialogService.showEntityActionSnackBar('save', 'configuration');
        }
        this.updateOriginalLists();
        this.reloadList$.next();
      }),
      catchError((error) => {
        this.displayErrorMessage(`${this.T_SCOPE}.messages.save-error`);
        return of(null);
      }),
      map((_) => true)
    );
  }

  updateOriginalLists() {
    this._originalConfiguredNE = [...this.configuredNE];
    this.configurationHasChanged = false;
  }

  discard() {
    this.configuredNE = [...this._originalConfiguredNE];
    this.excludedNE = [];
    this.reloadList$.next();
    this.configurationHasChanged = false;
  }

  private displayErrorMessage(messageKey: string) {
    let dialogSettings = new WlmDialogSettings({ translateKey: '' });
    dialogSettings.translateKey = messageKey;
    dialogSettings.icon = 'error';
    this._dialogService.showTranslatedMessage(dialogSettings);
  }

  private loadENEConfiguration(selectedNe: INetworkElementDto) {
    this.setLoading(true);
    this._eneService
      .getElementNetworkElementsByElementId(selectedNe.elementId)
      .pipe(
        untilDestroyed(this),
        finalize(() => this.setLoading(false))
      )
      .subscribe({
        next: (nes) => {
          if (nes) {
            const sortedNesConfiguration = this._arrayHelperService.sortArrayObjectCaseInsensitive(
              nes,
              this._networkElementNameFieldName
            );

            this.configuredNE = [...sortedNesConfiguration];
            this._originalConfiguredNE = [...sortedNesConfiguration];

            this.configurationHasChanged = false;
            this.excludedNE = [...sortedNesConfiguration];
          }
        },
        error: (err) => {
          this.displayErrorMessage(`${this.T_SCOPE}.messages.load-error`);
        },
      });
  }

  private setFilterSettings() {
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      networkElementType: new FilterGroupFieldSettings({
        fieldName: this._networkElementTypeIdFieldName,
      }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      persistencyArea: this.widgetId,
    });
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: hasChanges,
      saveFn: () => this.save(),
    };
  }

  private setLoading(loading: boolean): void {
    this._spinnerService.setLoading(loading, this.componentName);
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
