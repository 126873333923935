import { Injectable, Injector } from '@angular/core';
import { IActivityTypeDto } from '@common-modules/dependencies/alc/activity-type.dto';
import { GenericCrudService } from '@common-modules/generic-crud/generic-crud.service';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable, map } from 'rxjs';
import { IActivityTypeValidationDto } from './activity-type-validation.dto';

@Injectable()
export class ActivityTypesCrudService
  extends BaseService
  implements GenericCrudService<IActivityTypeDto, number>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/activity-types`;
  }

  getAll(): Observable<IActivityTypeDto[]> {
    return this.httpCacheClient.get<IActivityTypeDto[]>(this._entityUrl);
  }

  create(entity: IActivityTypeDto): Observable<IActivityTypeDto> {
    return this.httpCacheClient.post<IActivityTypeDto>(this._entityUrl, entity);
  }

  update(entity: IActivityTypeDto): Observable<IActivityTypeDto> {
    return this.httpCacheClient.put<IActivityTypeDto>(this._entityUrl, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  validate(entity: IActivityTypeDto, isCreate?: boolean): Observable<IActivityTypeValidationDto> {
    return this.httpCacheClient.post<IActivityTypeValidationDto>(
      `${this._entityUrl}/validate?isCreate=${isCreate ? 'true' : 'false'}`,
      entity
    );
  }

  getId(entity: IActivityTypeDto): number {
    return entity.activityTypeId;
  }
}
