import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivitiesGridFilterValidations } from '@common-modules/dependencies/alc/activities-grid-filter-validations';
import { IHierarchyElementDto } from '@common-modules/dependencies/he/hierarchy-element.dto';
import { FilterGroupFieldSettings } from '@common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { IGridSettings } from '@common-modules/shared/constants/grid.constants';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { DynamicGridSettings } from '@common-modules/shared/model/grid/dynamic-grid-settings';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { IDeletedZonesDto } from '@water-loss//features/shared/model/deleted-zones/deleted-zones.dto';
import { ReplaySubject, Subject } from 'rxjs';

const COMPONENT_SELECTOR = 'wlm-deleted-zones-grid';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './deleted-zones-grid.component.html',
  styleUrls: ['./deleted-zones-grid.component.scss'],
})
export class DeletedZonesGridComponent implements OnInit {
  @Output() selectedElementsChange = new EventEmitter<IDeletedZonesDto[]>();

  @Input() persistencyKey: string;
  @Input() filterCssClass: string;

  grid: GenericGridComponent;
  filterSettings: FilterGroupSettings;

  filters: DataBindingFilters;
  gridFiltersMap: Map<string, any>;
  emptyFilters: DataBindingFilters;

  canLoad: boolean;
  disableAutoload = false;
  selectedFamilyId: string;

  clearAll$ = new Subject<void>();
  persistFilters$ = new Subject<void>();

  selectedHE: IHierarchyElementDto;

  hierarchyLevelFieldName = 'hierarchyElementTypeIds';
  hierarchyFamilyFieldName = 'hierarchyFamilyId';

  dynamicGridSettings: DynamicGridSettings;
  gridSettings: IGridSettings;

  reloadGrid$ = new Subject<void>();
  gridSettingsReady$ = new ReplaySubject<IGridSettings>();
  removeSelectionPersisted$ = new ReplaySubject<void>();
  gridSettingsChanged$ = new ReplaySubject<IGridSettings>();

  selectedZones: IDeletedZonesDto[];

  public get persistencyArea(): string {
    if (!this.persistencyKey) {
      return this.componentName;
    }

    return `${this.persistencyKey}-${this.componentName}`;
  }

  public get componentName(): string {
    return 'DeletedZonesGridComponent';
  }

  constructor(private _objectHelperService: ObjectHelperService) {}

  ngOnInit(): void {
    this.initializeGridSettings();
    this.setFilterSettings();
  }

  onFiltersChanged(filtersParameters: DataBindingFilters) {
    const filterValidation = new ActivitiesGridFilterValidations();

    if (
      filtersParameters &&
      filterValidation.validateFilters(['hierarchyFamily', 'hierarchyLevel'], filtersParameters)
    ) {
      this.filters = filtersParameters;
      this.canLoad = true;
    } else {
      this.canLoad = false;
    }
  }

  onApply() {
    if (this.canLoad) {
      this.gridFiltersMap = this.setFiltersMap(this.filters);
      this.loadGrid();
    }
  }

  onAutoload(): void {
    if (!this.disableAutoload && this.filters?.filters) {
      this.disableAutoload = true;
      this.onApply();
    }
  }

  onSelectedItemsChanged(selectedZones: IDeletedZonesDto[]) {
    this.selectedZones = selectedZones;
    this.selectedElementsChange.emit(this.selectedZones);
  }

  getGenericGridInstance(grid: GenericGridComponent) {
    this.grid = grid;
  }

  getGridSettings(gridSettings: IGridSettings) {
    this.gridSettings = gridSettings;
    this.gridSettingsReady$.next(gridSettings);
  }

  private setFiltersMap(dataBindingFilters: DataBindingFilters): Map<string, any> {
    const iFilters = Array.from(dataBindingFilters.filters.values()).map((x) =>
      x.getFiltersValues()
    );
    const filtersToApply = this._objectHelperService.joinMaps(iFilters);
    return this._objectHelperService.clone(filtersToApply);
  }

  private setFilterSettings() {
    this.emptyFilters = new DataBindingFilters();

    const fields: { [field: string]: FilterGroupFieldSettings } = {
      hierarchyFamilyId: new FilterGroupFieldSettings({
        fieldName: this.hierarchyFamilyFieldName,
      }),
      hierarchyLevel: new FilterGroupFieldSettings({
        fieldName: this.hierarchyLevelFieldName,
      }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      persistencyArea: this.persistencyArea,
    });
  }

  private initializeGridSettings() {
    this.dynamicGridSettings = new DynamicGridSettings({
      disableAutoLoad: false,
      gridSettingName: 'DeletedZonesGrid',
      persistencyArea: this.persistencyArea,
      type: 'generic',
      usePersistence: true,
    });
  }

  private loadGrid() {
    this.reloadGrid$.next();
  }
}
