import { Component, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { AppliedFiltersSelector } from '@common-modules/dynamic-layout/state/filters/filters.selectors';
import { BaseSelectorDynamicWidgetComponent } from '@common-modules/redux/components/base-selector-dynamic-widget.component';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataValidationScopes } from '../../data-validation-scopes';
import { DataValidationStateFields } from '../../state/data-validation-state-fields';

const COMPONENT_SELECTOR = 'wlm-data-validation-points-selection-widget';
export const DATA_VALIDATION_POINTS_SELECTION_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-validation-points-selection-widget.component.html',
  styleUrls: ['./data-validation-points-selection-widget.component.scss'],
})
export class DataValidationPointsSelectionWidgetComponent
  extends BaseSelectorDynamicWidgetComponent
  implements OnInit
{
  widgetInstanceKey: string;
  filters: DataBindingFilters;

  // Not all actions/selectors must have the same action settings.
  private _scopeSettings = new StateScopeSettings({
    scope: DataValidationScopes.Main,
  });

  get componentName(): string {
    return 'DataValidationPointsSelectionWidgetComponent';
  }

  get selectedFieldName(): string {
    return DataValidationStateFields.selectedPoint;
  }

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings, pendingChangesService);

    this.widgetInstanceKey = widgetSettings.widgetInstanceKey;
    this.pageId = widgetSettings.page;
  }

  onWidgetInit(): void {
    this.emitWidgetInit();

    this._state
      .select<DataBindingFilters>(new AppliedFiltersSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (dataBindingFilters: DataBindingFilters) => {
          if (!dataBindingFilters) {
            return;
          }

          this.filters = dataBindingFilters;
        },
      });
  }

  onSelectedChange(selectedPoint: any) {
    this.checkPendingChanges(this.pageId).subscribe((_) => this.setSelected(selectedPoint));
  }
}
