import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { DynamicLayoutComponent } from '@common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutSettingsLoadOptions } from '@common-modules/dynamic-layout/models/dynamic-layout-settings-load-options';
import { DynamicSettings } from '@common-modules/dynamic-layout/models/dynamic-settings';
import { LayoutLoadOptions } from '@common-modules/dynamic-layout/models/layout-load-options';
import { LayoutNodeTypes } from '@common-modules/dynamic-layout/models/layout-node-types';
import { DynamicLayoutService } from '@common-modules/dynamic-layout/services/dynamic-layout.service';
import {
  ResetWidgetManagerStateAction,
  SetLayoutKeysAction,
  SetLayoutToLoadAction,
} from '@common-modules/dynamic-layout/state/widget-manager/widget-manager.actions';
import {
  CurrentLayoutIdentitySelector,
  CurrentLayoutSelector,
} from '@common-modules/dynamic-layout/state/widget-manager/widget-manager.selectors';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';

import { BiService } from '../../services/bi.service';

const COMPONENT_SELECTOR = 'wlm-bi-home-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './bi-home-page.component.html',
  styleUrls: ['./bi-home-page.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class BiHomePageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }
  T_SCOPE = `${AppModules.BI}.wlm-bi`;

  pageSettings: DynamicLayoutSettings;
  pageSettingsKeys = {
    layoutKey: 'LayoutHome',
    layoutArea: 'BI',
    widgetPage: 'biHome',
    widgetModule: 'bi',
  };
  sharedScopeInstance = {
    [StateAreas.Filters]: 'shared',
    [StateAreas.WidgetManager]: 'shared',
  };
  currentTemplateLayout: DynamicLayoutSettings;
  currentDashboardTitle: string;
  currentDashboardId: string;

  private _stateWidgetSettings = new StateWidgetSettings({
    module: this.pageSettingsKeys.widgetModule,
    page: this.pageSettingsKeys.widgetPage,
    scopeInstanceKeys: this.sharedScopeInstance,
  });

  private readonly _widgetContainerSettingsKeys = new DynamicSettings({
    settingKey: 'BIHomeWidgetsContainer',
    settingArea: 'BI',
  });

  private readonly _filtersInstanceKey = 'BiFiltersComponent#b14bb17c-38c2-480a-b9d3-cb163679c985';
  private readonly _widgetContainerInstanceKey =
    'BiContainerWidgetComponent#2d45731d-2d8b-46cf-ace1-cfdba83919dc';
  homeDashboard: DynamicLayoutSettings;

  constructor(
    private _authenticationService: AuthenticationService,
    private _localization: LocalizationHelperService,
    private _state: ReduxStateService,
    private _objectHelperService: ObjectHelperService,
    private _dynamicLayoutService: DynamicLayoutService,
    private _biService: BiService,
    private readonly _cd: ChangeDetectorRef
  ) {
    super();
    this._state.configure(this._stateWidgetSettings);
    this.hasRightPanel = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildPage();

    this._state
      .select<DynamicLayoutSettings>(new CurrentLayoutSelector())
      .pipe(untilDestroyed(this))
      .subscribe((layout) => {
        this.currentTemplateLayout = this._objectHelperService.serializedClone(layout);
        this._cd.detectChanges();
      });

    this._state
      .select<DynamicLayoutSettings>(new CurrentLayoutIdentitySelector())
      .pipe(untilDestroyed(this))
      .subscribe((identity) => {
        this.currentDashboardId = identity?.layoutId;
        this.currentDashboardTitle = identity?.title;
        this._cd.detectChanges();
      });

    this._biService.getHomeDashboard(this._widgetContainerSettingsKeys).subscribe((dashboard) => {
      dashboard.layoutKey = this._widgetContainerSettingsKeys.settingKey;
      dashboard.layoutArea = this._widgetContainerSettingsKeys.settingArea;

      const settingsWithOptions = new DynamicLayoutSettingsLoadOptions({
        settings: dashboard,
        loadOptions: new LayoutLoadOptions({
          disablePersistencyLoad: true,
          disablePersistencySave: true,
        }),
      });
      this._state.dispatch(new SetLayoutToLoadAction(null));
      this._state.dispatch(new SetLayoutToLoadAction(settingsWithOptions));
    });
  }

  private buildPage(): void {
    forkJoin([this._localization.get(`${this.T_SCOPE}.widgets`)]).subscribe(([titles]) => {
      this.pageSettings = new DynamicLayoutSettings({
        ...this.pageSettingsKeys,
        currentUser: this._authenticationService.userCode,
        structure: [],
        defaultStructure: [
          {
            type: LayoutNodeTypes.Column,
            content: [
              {
                type: LayoutNodeTypes.Stack,
                height: 15,
                content: [
                  {
                    type: LayoutNodeTypes.Component,
                    widgetInstanceKey: this._filtersInstanceKey,
                  },
                ],
              },
              {
                type: LayoutNodeTypes.Row,
                content: [
                  {
                    type: LayoutNodeTypes.Stack,
                    width: 75,
                    content: [
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: this._widgetContainerInstanceKey,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        items: [
          {
            componentName: 'BiFiltersComponent',
            widgetInstanceKey: this._filtersInstanceKey,
            scopeInstanceKeys: {
              [StateAreas.Filters]: 'shared',
            },
            title: titles['bi-filters-widget'],
          },
          {
            componentName: 'BiContainerWidgetComponent',
            widgetInstanceKey: this._widgetContainerInstanceKey,
            scopeInstanceKeys: {
              [StateAreas.WidgetManager]: 'shared',
            },
            title: titles['bi-container-widget'],
          },
        ],
      });
    });
  }

  onClickResetMainLayout(): void {
    this._state.dispatch(new ResetWidgetManagerStateAction());

    const settings = new DynamicSettings({
      settingKey: this.pageSettingsKeys.layoutKey,
      settingArea: this.pageSettingsKeys.layoutArea,
    });

    this._dynamicLayoutService.resetOne(settings);
    // Trigger first flow to enable widget container
    this._state.dispatch(new SetLayoutKeysAction(null));
    this._state.dispatch(new SetLayoutKeysAction(this._widgetContainerSettingsKeys));
  }

  get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  get persistencyArea(): string {
    return this.pageCrud;
  }

  get pageCrud(): string {
    return 'BiHomePageComponent';
  }

  get navigations(): DropdownNavigationItem[] {
    return [];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  ngOnDestroy(): void {
    this._state.dispatch(new ResetWidgetManagerStateAction());
  }
}
