import { GenericAction } from '@common-modules/redux/models/generic-action';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { ExtendedFilters } from '@common-modules/shared/filters/component-filters/extended-filters';
import { FiltersActionTypes } from './filters.action-types';

export abstract class FiltersAction extends GenericAction {
  area = StateAreas.Filters;
}

export class ApplyFiltersAction extends FiltersAction {
  type = FiltersActionTypes.ApplyFilters;

  constructor(public payload: DataBindingFilters, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class ApplyExtendedFiltersAction extends FiltersAction {
  type = FiltersActionTypes.ApplyExtendedFilters;

  constructor(public payload: ExtendedFilters, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class SetFiltersSettingsAction extends FiltersAction {
  type = FiltersActionTypes.SetSettings;

  constructor(public payload, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class ResetFiltersAction extends FiltersAction {
  type = FiltersActionTypes.ResetFilters;

  constructor(public payload, settings = new StateScopeSettings({})) {
    super(settings);
  }
}
