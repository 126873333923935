import { Directive, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { GenericChartComponent } from '../generic-chart/generic-chart.component';
import { IChartDataParameters } from '../models/chart-data-parameters';
import { ChartSettings } from '../models/chart-settings';
import { GenericChartSettings } from '../models/generic-chart-settings/generic-chart-settings';
import { GenericChartUnselectedSeries } from '../models/generic-chart-unselected-series';
import { GChartClickEvent } from '../models/generic-events/g-chart-click-event';
import { GChartDataZoomEvent } from '../models/generic-events/g-chart-data-zoom-event';
import { GChartInitEvent } from '../models/generic-events/g-chart-init-event';
import { GChartLegendSelectedEvent } from '../models/generic-events/g-chart-legend-selected-event';
import { GenericChartService } from '../services/generic-chart.service';

@Directive()
export abstract class BaseChartComponent {
  T_SCOPE = `${AppModules.WlmCharts}`;

  showDataPointsState = true;

  @ViewChild(GenericChartComponent) genericChart: GenericChartComponent;

  @Input() public abstract chartSettings: ChartSettings;
  @Input() set loading(value) {
    this.loadingHandler$.next(value);
  }
  @Input() public abstract disableInnerLoading;
  @Output() loadingEvent = new EventEmitter<boolean>();
  @Output() chartInitEvent = new EventEmitter<GChartInitEvent>();
  @Output() chartClickEvent = new EventEmitter<GChartClickEvent>();
  @Output() chartDataZoomEvent = new EventEmitter<GChartDataZoomEvent>();
  @Output() chartLegendSelectedEvent = new EventEmitter<GChartLegendSelectedEvent>();
  @Output() genericChartSettingsChange = new EventEmitter<GenericChartSettings>();
  @Output() chartLoaded = new EventEmitter<boolean>();
  @Output() chartRendered = new EventEmitter<boolean>();
  @Output() chartFinished = new EventEmitter<void>();
  @Output() serieNamesUnselected = new EventEmitter<GenericChartUnselectedSeries>();

  private _exportChart$ = new Subject<void>();
  readonly exportChart$ = this._exportChart$.asObservable();
  private _showDataPoints$ = new Subject<boolean>();
  readonly showDataPoints$ = this._showDataPoints$.asObservable();

  public loadingHandler$ = new BehaviorSubject<boolean>(false);
  public genericChartService: GenericChartService;
  public dataLoaded = false;
  public isLoading = false;

  private _genericChartSettings: GenericChartSettings;
  public get genericChartSettings(): GenericChartSettings {
    return this._genericChartSettings;
  }
  public set genericChartSettings(value: GenericChartSettings) {
    this._genericChartSettings = value;
    this.genericChartSettingsChange.emit(this.genericChartSettings);
    this.dataLoaded = !!(this._genericChartSettings as any)?.series?.length;
  }

  constructor(protected _injector: Injector) {
    this.genericChartService = this._injector.get(GenericChartService);
    this.loadingEvent.emit(false);
  }

  protected abstract getSerieData(params: any): Observable<any>;
  public abstract getGenericChartSettings(): Observable<GenericChartSettings>;
  public abstract setDateParams(startDate: Date, endDate: Date);
  public abstract getParams(): IChartDataParameters;
  public abstract setParams(newParams: IChartDataParameters);

  public setDateRange(startDate: Date, endDate: Date) {
    this.chartSettings.dataParameters.startDate = startDate;
    this.chartSettings.dataParameters.endDate = endDate;
  }

  public chartDataZoomChanged(event: any) {
    this.chartDataZoomEvent.emit(event);
  }

  protected setLoading(isLoading: boolean) {
    if (!this.disableInnerLoading) {
      this.isLoading = isLoading;
      this.loadingHandler$.next(isLoading);
    }
    this.loadingEvent.emit(isLoading);
  }

  onChartInit = (event) => this.chartInitEvent.emit(event);
  onChartClick = (event) => this.chartClickEvent.emit(event);
  onChartDataZoom = (event) => this.chartDataZoomEvent.emit(event);
  onChartLegendSelected = (event) => this.chartLegendSelectedEvent.emit(event);
  onChartLoaded = (event) => this.chartLoaded.emit(event);
  onChartRendered = (event) => this.chartRendered.emit(event);
  onChartFinished = (event) => this.chartFinished.emit(event);
  onSerieNamesUnselected = (event) => this.serieNamesUnselected.emit(event);

  exportChart = () => this._exportChart$.next();
  showDataPoints = () => {
    this.showDataPointsState = !this.showDataPointsState;
    this._showDataPoints$.next(this.showDataPointsState);
  };
}
