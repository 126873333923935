import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { Params } from '@angular/router';
import { IHierarchyElementPathDto } from '@common-modules/dependencies/he/hierarchy-element-path.dto';
import * as constants from '@common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { TabDetailParameterName } from '@common-modules/dependencies/navigation/tab-detail-component';
import { HierarchyElementPathFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  BaseFilterSettingsDefault,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { NeHeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { FiltersAdapterService } from '@common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { TreeSettingsService } from '@common-modules/shared/services/tree-settings.service';
import { ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BASE_FILTER_SETTINGS_DEFAULT } from '../../core/injection-tokens';
import { HeTreeFamilyFilterConfiguration } from './he-tree-family-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-hierarchy-element-tree-family-filter';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-element-tree-family-filter.component.html',
  styleUrls: ['./hierarchy-element-tree-family-filter.component.scss'],
})
export class HierarchyElementTreeFamilyFilterComponent
  extends BasePageFiltersComponent
  implements OnInit
{
  private _hetreeFamilyFilterConfiguration: HeTreeFamilyFilterConfiguration;
  public get hetreeFamilyFilterConfiguration(): HeTreeFamilyFilterConfiguration {
    return this._hetreeFamilyFilterConfiguration;
  }
  public set hetreeFamilyFilterConfiguration(v: HeTreeFamilyFilterConfiguration) {
    this._hetreeFamilyFilterConfiguration = v;
    if (v) {
      this.setFiltersPersistencyArea();
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  private _filterGroupSettings: FilterGroupSettings;
  public get settings(): FilterGroupSettings {
    return this._filterGroupSettings;
  }
  @Input() public set settings(v: FilterGroupSettings) {
    this._filterGroupSettings = v;
    this.persistencyArea = v?.persistencyArea;

    if (v) {
      if (v.navigationParams) {
        this.loadNavigationParams(v.navigationParams);
      }

      this.loadState();
    }
  }

  @Input() set fieldLabelKey(value: string) {
    if (value) {
      this.bfSettings.inputLabelKey = value;
    }
  }

  @Input() set disabled(value: boolean) {
    this.bfSettings.disableFilter = value;
  }

  selectedHierarchyFamilyId: string;
  selectedHETypeIds: string[];
  selectedHierarchyElementIds: string[];

  defaultHETypeIds: string[];
  defaultHierarchyElementId = '';

  heFilterSettings: NeHeFISettings;
  treeSettings: ITreeSettings = null;
  filterFields = ['hierarchyElementsIds'];
  gridSettingsReady$ = new ReplaySubject<GridSetting>();
  mustPersistFilters = false;
  isFirstLoad = true;

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
  };

  bfSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-label`,
    disableSelectAll: true,
  };

  hierarchyFamilyItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
    required: true,
  });

  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
  });

  // Communicates hierarchy-family-filter-item with hierarchy-tree-filter-item.
  hierarchyElementFamilyId: string;

  @Output() selectedNodesChange = new EventEmitter<IHierarchyElementPathDto[]>();

  constructor(
    private treeSettingsService: TreeSettingsService,
    private adaptersService: FiltersAdapterService,
    private _globalsService: GlobalsService,
    persistencyService: PersistencyService,
    @Optional()
    @Inject(BASE_FILTER_SETTINGS_DEFAULT)
    private _bfSettingsOverrides: BaseFilterSettingsDefault
  ) {
    super(persistencyService);

    if (this._bfSettingsOverrides) {
      this.baseFilterSettings = {
        ...this.baseFilterSettings,
        ...this._bfSettingsOverrides,
      };
      this.bfSettings = {
        ...this.bfSettings,
        ...this._bfSettingsOverrides,
      };
    }
  }

  buildKeysToComplete(): void {
    const { hierarchyElementIdFieldName } = this.hetreeFamilyFilterConfiguration;
    this.keysToComplete = [hierarchyElementIdFieldName];
  }

  ngOnInit(): void {
    this.buildTreeSettings();
  }

  buildTreeSettings(): void {
    this.treeSettingsService.buildTreeSettings().subscribe({
      next: (settings) => {
        this.treeSettings = settings;
        if (this.settings) {
          this.setAdditionalParams(this.settings.additionalParams);
        }
      },
    });
  }

  /**
   * Saves the family Id so the tree filter can obtain it.
   * Only contains the activity Id filter.
   */
  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName = this.hetreeFamilyFilterConfiguration.hierarchyElementFamilyFieldName;
    const defaultId = this.hetreeFamilyFilterConfiguration.hierarchyElementFamily;
    this.hierarchyElementFamilyId = this.adaptersService.getFamilyId(
      filters,
      familyFieldName,
      defaultId
    );
  }

  onTreeFilterReady() {
    if (this.mustPersistFilters && this.isFirstLoad) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
      this.isFirstLoad = false;
    }
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const { hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.hetreeFamilyFilterConfiguration;
    const adapters = [
      new HierarchyElementPathFAdapter({
        dataBindingField: 'hierarchyElementsIds',
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId: this.hierarchyElementFamilyId,
      }),
    ];
    return adapters;
  };

  setFiltersPersistencyArea(): void {
    this.bfSettings.persistencyArea = this.hetreeFamilyFilterConfiguration.persistencyArea;
    this.persistencyArea = this.bfSettings.persistencyArea;
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }

  loadState() {
    const filters = [];

    // Load persisted familyId
    if (!this.selectedHierarchyFamilyId) {
      const persistedFamilyId = this.settings.avoidPersistency
        ? undefined
        : this.getPersisted(this.settings.fields.hierarchyElementFamilyId.fieldName, undefined);

      if (persistedFamilyId) {
        this.selectedHierarchyFamilyId = (persistedFamilyId as BasicFilter)?.value?.shift()?.value;
      }
    }

    // Load persisted heTypes
    if (!this.selectedHETypeIds) {
      const persistedHeTypes = this.settings.avoidPersistency
        ? undefined
        : this.getPersisted(this.settings.fields.hierarchyElementTypes.fieldName, undefined);

      if (persistedHeTypes) {
        this.selectedHETypeIds = (persistedHeTypes as BasicFilter)?.value?.map((x) => x.value);
      }
    }

    if (!this.selectedHierarchyElementIds) {
      const defaultHeFilter = { value: this.defaultHierarchyElementId, label: '' };
      const hierarchyElementFilter = this.settings.avoidPersistency
        ? defaultHeFilter
        : (this.getPersisted(
            this.settings.fields.hierarchyElementId.fieldName,
            defaultHeFilter
          ) as BasicFilter);

      this.selectedHierarchyElementIds = hierarchyElementFilter.value;
    }

    this._globalsService
      .getDefaultHierarchyFamilyId(this.selectedHierarchyFamilyId)
      .pipe(
        switchMap((familyId) => {
          if (!this.selectedHierarchyFamilyId) {
            this.selectedHierarchyFamilyId = familyId;
          }

          return this._globalsService.getHierarchyElementTypes(this.selectedHierarchyFamilyId).pipe(
            map((hierarchies) => {
              // set default heTypeId (used on clear he filter)
              if (!this.defaultHETypeIds) {
                this.defaultHETypeIds = hierarchies
                  .filter(
                    (he) => he.hierarchyElementTypeName === constants.hierarchyElementTypes.DMA
                  )
                  ?.map((x) => x.hierarchyElementTypeId);
              }

              return this.defaultHETypeIds;
            })
          );
        })
      )
      .subscribe(() => {
        this.heFilterSettings = new NeHeFISettings({
          fieldName: this.settings.fields.hierarchyElementId.fieldName,
          selectedIds: this.selectedHETypeIds ?? this.defaultHETypeIds,
          defaultValue: this.defaultHETypeIds,
          isNEFilter: false,
        });

        const gridFiltersForBinding = this.getPersisted(
          TabDetailParameterName.dataBindingFilter,
          undefined
        ) as DataBindingFilters;

        let dataBindingCasted = new DataBindingFilters();
        if (gridFiltersForBinding?.exportableFilter) {
          dataBindingCasted.exportableFilter = gridFiltersForBinding?.exportableFilter;
          dataBindingCasted.reloadFromSerialization();
        } else {
          dataBindingCasted = null;
        }

        const filterConfiguration = new HeTreeFamilyFilterConfiguration({
          persistencyArea: this.settings.persistencyArea,
          hierarchyElementIdFieldName: this.settings.fields.hierarchyElementId.fieldName,
          hierarchyElementFamilyFieldName: this.settings.fields.hierarchyElementFamilyId.fieldName,
          hierarchyElementId: this.selectedHierarchyElementIds,
          hierarchyElementFamily: this.selectedHierarchyFamilyId,
          filters: [],
        });

        this.hetreeFamilyFilterConfiguration = filterConfiguration;
      });
  }

  private loadNavigationParams(queryParams: Params) {
    if (queryParams?.hierarchyElementIds) {
      this.selectedHierarchyElementIds = Array.isArray(queryParams.hierarchyElementIds)
        ? queryParams.hierarchyElementIds
        : [queryParams.hierarchyElementIds];
    }
    this.selectedHierarchyFamilyId = queryParams.hierarchyFamilyId;
  }

  onSelectedNodesChanged(selectedNodes) {
    this.selectedNodesChange.emit(selectedNodes);
  }

  private setAdditionalParams(additionalParams) {
    this.treeSettings.selectionMode =
      additionalParams?.selectionMode?.value ?? constants.treeSelectionMode.multiple;

    if (additionalParams?.unselectableTypes?.value) {
      this.treeSettings.unselectableTypes = additionalParams.unselectableTypes.value;
    }
  }
}
