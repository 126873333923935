import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { AlarmsSubscriptionService } from './alarms-subscription.service';

const COMPONENT_SELECTOR = 'wlm-alarms-subscription';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarms-subscription.component.html',
  styleUrls: ['./alarms-subscription.component.scss'],
})
export class AlarmsSubscriptionComponent implements OnInit {
  private _profileId: string;
  public get profileId(): string {
    return this._profileId;
  }
  @Input()
  public set profileId(value: string) {
    this._profileId = value;
    if (this._profileId) {
      this.setSubscriptionStatus();
    } else {
      this.isSubscribed = false;
    }
  }

  @Output() subscriptionChange = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();

  T_SCOPE = `${AppModules.Alarms}.${COMPONENT_SELECTOR}`;

  isSubscribed: boolean = false;

  subscribeText = `${this.T_SCOPE}.subscribe-text`;
  unsubscribeText = `${this.T_SCOPE}.unsubscribe-text`;

  constructor(
    private _alarmsSubscriptionService: AlarmsSubscriptionService,
    private _dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  setSubscriptionStatus() {
    this.loading.emit(true);
    this._alarmsSubscriptionService
      .getSubscriptionStatus(this.profileId)
      .pipe(untilDestroyed(this))
      .subscribe((isSubscribed) => {
        this.isSubscribed = isSubscribed;
        this.loading.emit(false);
      });
  }

  setSubscription($event) {
    if (this.profileId) {
      this.prepareSubscriptionCall(this.profileId);
    } else {
      this.isSubscribed = !this.isSubscribed;
      this.subscriptionChange.emit(this.isSubscribed);
    }
  }

  private prepareSubscriptionCall(profileId: string) {
    const subcriptionCall$ = this.isSubscribed
      ? this._alarmsSubscriptionService.unsubscribe(profileId)
      : this._alarmsSubscriptionService.subscribe([profileId]);

    const successMsg = this.isSubscribed
      ? `${this.T_SCOPE}.messages.unsubscribe-success`
      : `${this.T_SCOPE}.messages.subscribe-success`;
    const errorMsg = this.isSubscribed
      ? `${this.T_SCOPE}.messages.unsubscribe-error`
      : `${this.T_SCOPE}.messages.subscribe-error`;

    this.processSubscription(subcriptionCall$, successMsg, errorMsg);
  }

  private processSubscription(
    subcriptionCall$: Observable<string>,
    successMsg: string,
    errorMsg: string
  ) {
    this.loading.emit(true);
    subcriptionCall$.subscribe({
      next: (result) => {
        this.displayMessage(successMsg, 'success');
        this.isSubscribed = !this.isSubscribed;
        this.subscriptionChange.emit(this.isSubscribed);
        this.loading.emit(false);
      },
      error: (err) => {
        this.displayMessage(errorMsg, 'error');
        this.loading.emit(false);
      },
    });
  }

  private displayMessage(messageKey: string, icon: 'success' | 'error') {
    const dialogSettings = new WlmDialogSettings({ translateKey: messageKey });
    dialogSettings.icon = icon;

    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }
}
