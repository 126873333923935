import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfigurableFormModule } from '../configurable-form/configurable-form.module';
import { DynamicLayoutModule } from '../dynamic-layout/dynamic-layout.module';
import { CommonSharedModule } from '../shared/common-shared.module';
import { UtilsHelperService } from '../shared/helpers/utils-helper.service';
import { MaterialSharedModule } from '../shared/material-shared.module';
import { WlmSpinnerModule } from '../wlm-spinner/wlm-spinner.module';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { CustomBlankFieldComponent } from './custom-components/custom-blank-field/custom-blank-field.component';
import { CustomGlobalValueDisplayFieldComponent } from './custom-components/custom-global-value-display-field/custom-global-value-display-field.component';
import { CustomGlobalValueSelectorFieldComponent } from './custom-components/custom-global-value-selector-field/custom-global-value-selector-field.component';
import { CustomInputComponent } from './custom-components/custom-input/custom-input.component';
import { registerTranslateExtension } from './extensions/translate.extension';
import { CustomFieldTypes } from './models/custom-field-types';
import { DynamicFormsModuleSettings } from './models/dynamic-forms-module-settings';
import { FieldValidatorUITypes } from './models/field-validator-ui-types';
import { DynamicFormHelperService } from './services/dynamic-form-helper.service';
import { DynamicFormParserService } from './services/dynamic-form-parser.service';
import { DynamicFormValidatorsService } from './services/dynamic-form-validators.service';
import { DynamicFormService } from './services/dynamic-form.service';
import { FormlyFormParserService } from './services/formly-form-parser.service';

const buildValidators = (types: FieldValidatorUITypes[]) =>
  types.map((type) => ({
    name: type,
    validation: DynamicFormValidatorsService.build(type),
  }));

const fieldAppearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'always',
};

@NgModule({
  declarations: [
    DynamicFormComponent,
    CustomInputComponent,
    CustomBlankFieldComponent,
    CustomGlobalValueSelectorFieldComponent,
    CustomGlobalValueDisplayFieldComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialSharedModule,
    TranslateModule,
    WlmSpinnerModule,
    DynamicLayoutModule,
    CommonSharedModule,
    ConfigurableFormModule,
    FormlyModule.forRoot({
      validators: buildValidators([FieldValidatorUITypes.Min, FieldValidatorUITypes.Max]),
      types: [
        {
          name: CustomFieldTypes.IntegerInput,
          component: CustomInputComponent,
          wrappers: ['form-field'],
        },
        {
          name: CustomFieldTypes.Blank,
          component: CustomBlankFieldComponent,
        },
        {
          name: CustomFieldTypes.GlobalValueSelector,
          component: CustomGlobalValueSelectorFieldComponent,
        },
        {
          name: CustomFieldTypes.GlobalValueDisplay,
          component: CustomGlobalValueDisplayFieldComponent,
          wrappers: ['form-field'],
        },
      ],
      extras: {
        resetFieldOnHide: false,
      },
    }),
    FormlyMaterialModule,
    FormlyMatFormFieldModule,
    FormlyMatDatepickerModule,
  ],
  providers: [
    DynamicFormService,
    DynamicFormHelperService,
    //AttributeFormGroupService,
    {
      provide: DynamicFormParserService,
      useClass: FormlyFormParserService,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: fieldAppearance,
    },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerTranslateExtension,
      deps: [TranslateService, UtilsHelperService],
    },
  ],
  exports: [DynamicFormComponent],
})
export class DynamicFormsModule {
  static forFeature(settings: DynamicFormsModuleSettings): ModuleWithProviders<DynamicFormsModule> {
    let providers = settings.providers ?? [];
    if (settings.factoryFnBuilder) {
      providers.push({
        provide: FORMLY_CONFIG,
        multi: true,
        useFactory: settings.factoryFnBuilder(),
        deps: settings.deps ?? [],
      });
    }
    const moduleDefinition = {
      ngModule: DynamicFormsModule,
      providers,
    };

    return moduleDefinition;
  }
}
