import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FilterItemSelectOption } from '@common-modules/common-filters/models/filter-item-select-option';
import { FilterSelection } from '@common-modules/common-filters/models/filters-selection';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { FiltersPayloadStatus } from '@common-modules/dependencies/wlm-filters/filters-payload-status.enum';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { WlmSharedModule } from '@water-loss//features/shared/wlm-shared.module';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';

@Directive()
export abstract class BaseSelectFilterItemComponent
  extends BaseFilterItemComponent
  implements OnDestroy
{
  // The name/identifier of the field associated to the filter.
  @Input() fieldName: string;
  // The text from the filter's input. The filter must be filtered by this text if possible.
  @Input() filterText: string;
  // Preselected filter elements.
  private _selectedIds: any[];
  get selectedIds(): any[] {
    return this._selectedIds;
  }
  @Input() set selectedIds(value: any[]) {
    this._selectedIds = value;
    this.selectedIdsChanged$.next(value);
  }

  // Select type (single/multiple)
  @Input() mode: string = 'multiple';
  // Emits the selection. An alternative to the filtered$ observable.
  @Output() select = new EventEmitter<FiltersPayload>();
  // Higher Order Observable that emit other observables.
  // The child observable resolve to the data displayed by the filter.
  // Done like this for being able to change the child observable depending on filters.
  abstract data$$: BehaviorSubject<Observable<FilterItemSelectOption[]>>;
  // Key for transtating the title.
  abstract titleKey: string;
  // Key for translations the label that is displayed in the filter input along with the selection of elements.
  abstract inputSummaryKey: string;
  // Check if the select is valid.
  isValidSelect = true;
  // Allows the component to disable the clearAll action.
  disableClear = false;

  private filtered$ = new BehaviorSubject<FiltersPayload>(null);
  protected filteredNoPayload$ = new BehaviorSubject<BasicFilter[]>(null);
  clearAll$: Subject<void> = new Subject();
  selectAll$: Subject<void> = new Subject();
  restoreState$: Subject<void> = new Subject();
  saveInitStatus$: Subject<void> = new Subject();

  protected localization: LocalizationHelperService;
  private selectedIdsChanged$ = new ReplaySubject<any[]>(null);

  private _selectedElements: FilterItemSelectOption[] = [];
  public get selectedElements(): FilterItemSelectOption[] {
    return this._selectedElements;
  }
  public setSelectedElements(selection: FilterSelection) {
    this._selectedElements = selection.selection;
    this.isValidSelect = true;
    // Validate required constraint.
    // if (this.settings.required && (!this.selectedElements || this.selectedElements.length === 0)) {
    //   this.isValidSelect = false;
    // }

    const requiredAndEmptySelect =
      this.settings.required && (!this.selectedElements || this.selectedElements.length === 0);

    this.isValidSelect = !requiredAndEmptySelect || this.settings.allowEmptySelect;
    // If the select is required, the selection should never come empty.
    if (this.isValidSelect) {
      const filter = new BasicFilter(this.fieldName, [...this.selectedElements]);
      if (this.fieldName) {
        const payload = this.buildPayload([filter], selection.status);
        this.filtered$.next(payload);
        this.select.emit(payload);
      } else {
        // If there is no fieldName, it is a special case when the filtered$ and select events must be emitted in the subclass.
        this.filteredNoPayload$.next([filter]);
      }
    }
  }

  public elementList: FilterItemSelectOption[] = [];
  private _previousSubs: Subscription;

  constructor() {
    super();
    this.localization = WlmSharedModule.injector.get(LocalizationHelperService);
  }

  onInit(): void {
    this.initFilter();
  }

  initFilter() {
    this.data$$.pipe(untilDestroyed(this, 'ngOnDestroy')).subscribe((data$) => {
      if (data$) {
        // If previous call was made, cancel it.
        if (this._previousSubs) {
          this._previousSubs.unsubscribe();
        }
        // If a new data$ observable was emitted, subscribe to it instead.
        this._previousSubs = data$.pipe(untilDestroyed(this, 'ngOnDestroy')).subscribe((items) => {
          this.elementList = items;
          // Every time we subscribe to the data$ observable, filter by ids if any are available.
          // It is also possible to specify the selected ids directly inside the data$ observable,
          // without assigning them to the selectedIds input attribute.
          this.selectedIdsChanged$.pipe(untilDestroyed(this, 'ngOnDestroy')).subscribe((ids) => {
            if (ids) {
              this.setSelectedElements(
                new FilterSelection({
                  selection: items.filter((f) => ids.includes(f.value)),
                  status: FiltersPayloadStatus.Initial,
                })
              );
            }
          });
        });
      }
    });
  }

  onSelected(selected: FilterSelection): void {
    this.setSelectedElements(selected);
  }

  setClear(): void {
    if (this.disableClear) {
      return;
    }
    this.clearAll$.next();
  }

  setSelectAll(): void {
    this.selectAll$.next();
  }

  setRestoreState(): void {
    this.restoreState$.next();
  }

  setInitialState(): void {
    this.saveInitStatus$.next();
  }

  getFiltered$(): Observable<FiltersPayload> {
    // If the observable is hidden, emit null instead of the value.
    return this.filtered$.pipe(map((data) => (this.hide ? null : data)));
  }

  getStateFormatted(): Observable<string> {
    if (this.settings?.hideInputSummary) {
      return of('');
    }
    return this.localization.get(this.inputSummaryKey).pipe(
      map((inputSummaryLabel) => {
        if (this.selectedElements) {
          const labels = this.selectedElements.map((el) => el.label);
          const joined = labels.length !== 0 ? labels.join(', ') : null;
          if (joined) {
            return this.settings?.hideInputSummaryLabel ? joined : `${inputSummaryLabel}${joined}`;
          }
          return null;
        }
      })
    );
  }

  ngOnDestroy(): void {}
}
