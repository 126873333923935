import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NoBoundarySettings } from '@common-modules/dependencies/ne-configuration/no-boundary-settings';
import { PressureSettings } from '@common-modules/dependencies/ne-configuration/pressure-settings';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { SignalCategories } from '@common-modules/dependencies/shared/model/signal-categories';
import { SettingsEntityType } from '@common-modules/dynamic-forms/models/settings-entity-type';
import { DynamicLayoutComponent } from '@common-modules/dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutItemSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-item-settings';
import { DynamicLayoutKeys } from '@common-modules/dynamic-layout/models/dynamic-layout-keys';
import { DynamicLayoutSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutService } from '@common-modules/dynamic-layout/services/dynamic-layout.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { DynamicSettingsService } from '@common-modules/shared/config/dynamic-settings.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SMART_METERS_CONFIG_DEFAULT_KEY } from '@water-loss//smart-meters/smart-meters-config-widget/smart-meters-config-widget.component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NEC_BOUNDARY_COMPONENT_INSTANCE } from '../widgets/ne-configuration-boundary-widget/ne-configuration-boundary-widget.component';
import { NEC_LARGE_USERS_COMPONENT_INSTANCE } from '../widgets/ne-configuration-large-user-widget/ne-configuration-large-user-widget.component';
import { NEC_LARS_SWORPS_COMPONENT_INSTANCE } from '../widgets/ne-configuration-lars-sworps-widget/ne-configuration-lars-sworps-widget.component';
import { NEC_PRESSURE_COMPONENT_INSTANCE } from '../widgets/ne-configuration-pressure-widget/ne-configuration-pressure-widget.component';

const COMPONENT_SELECTOR = 'wlm-ne-config-signals-group';

export const NEC_SIGNALS_GROUP_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-config-signals-group.component.html',
  styleUrls: ['./ne-config-signals-group.component.scss'],
})
export class NeConfigSignalsGroupComponent implements OnInit {
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  @Input() dynamicLayoutKeys: DynamicLayoutKeys;

  private _selectedNE: INetworkElementDto;
  get selectedNE(): INetworkElementDto {
    return this._selectedNE;
  }
  @Input() set selectedNE(value: INetworkElementDto) {
    this._selectedNE = value;
    if (!value) {
      this.layoutSettings = null;
    }

    this.getSignalCategories(value).subscribe((categories) => {
      this.currentSignalCategories = categories;

      if (
        !this._objectHelperService.deepEqual(
          this.currentSignalCategories,
          this._previousSignalCategories
        )
      ) {
        this.layoutSettings = null;
        this.hiddenElementsByCategory = {};

        this.setHiddenElementsByCategory();

        setTimeout(() => {
          this.buildSignalsLayout();
          this._previousSignalCategories = this._objectHelperService.clone(
            this.currentSignalCategories
          );
        });
      }
    });
  }

  titleKey = `${AppModules.Configuration}.common.configuration-not-available-title`;
  subtitleKey = `${AppModules.Configuration}.common.configuration-not-available-subtitle`;

  @Output() boundarySettingsChange = new EventEmitter<NoBoundarySettings>();
  @Output() pressureSettingsChange = new EventEmitter<PressureSettings>();

  layoutSettings: DynamicLayoutSettings;
  signalsCustomSettings: any;

  // Identifies which widgets will be loaded. Depends on the selected NE.
  currentSignalCategories: SignalCategories[];
  hiddenElementsByCategory: { [category: string]: string[] } = {};
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  private readonly _boundaryWidgetName = 'NeConfigurationBoundaryWidgetComponent';
  private readonly _larsSworpsWidgetName = 'NeConfigurationLarsSworpsWidgetComponent';
  private readonly _pressureWidgetName = 'NeConfigurationPressureWidgetComponent';
  private readonly _largeUsersWidgetName = 'NeConfigurationLargeUserWidgetComponent';
  private readonly _smartMetersWidgetName = 'SmartMetersConfigWidgetComponent';
  private _previousSignalCategories: SignalCategories[];

  private readonly _pressureCppFieldName = 'CPP';
  private readonly _pressureMppFieldName = 'MPP';

  constructor(
    private _localization: LocalizationHelperService,
    private _authenticationService: AuthenticationService,
    private _dynamicSettingsService: DynamicSettingsService,
    private _dynamicLayoutService: DynamicLayoutService,
    private _objectHelperService: ObjectHelperService,
    private readonly _globalsService: GlobalsService
  ) {}

  ngOnInit(): void {}

  private getSignalCategories(selectedNE: INetworkElementDto): Observable<SignalCategories[]> {
    let entityType: SettingsEntityType;
    let elementTypeId;

    if (!selectedNE) {
      return of([]);
    }

    if (typeof selectedNE.hierarchyElementTypeId !== 'undefined') {
      entityType = SettingsEntityType.HierarchyElement;
      elementTypeId = selectedNE.hierarchyElementTypeId;
    } else {
      entityType = SettingsEntityType.NetworkElement;
      elementTypeId = selectedNE.networkElementTypeId;
    }

    var entitySubtypes = this._globalsService.buildEntitySubtypesFromNetworkElement(selectedNE);

    return this._dynamicSettingsService
      .getSignalsCustomSettings(entityType, String(elementTypeId), entitySubtypes)
      .pipe(
        map((data) => {
          this.signalsCustomSettings = data;

          return data.map((item) => item.categoryKey);
        })
      );
  }

  private isSelectedCategory = (findCategory: SignalCategories): boolean => {
    const found = this.currentSignalCategories.find((category) => category === findCategory);
    return typeof found !== 'undefined';
  };

  /**
   * Builds which components need to be defined from the current categories array.
   * If the categories are the same, the layout will not be rebuilt again.
   * IMPORTANT: all that is done here must be related to the categories or it will not work.
   */
  private buildSignalsLayout(): void {
    this.buildTitles().subscribe((titles) => {
      const widgetItems: DynamicLayoutItemSettings[] = [];
      const { scopeInstanceKeys } = this.dynamicLayoutKeys;

      const boundariesSelected = this.isSelectedCategory(SignalCategories.Boundaries);
      const noBoundariesSelected = this.isSelectedCategory(SignalCategories.NoBoundaries);

      if (boundariesSelected || noBoundariesSelected) {
        widgetItems.push({
          componentName: this._boundaryWidgetName,
          widgetInstanceKey: NEC_BOUNDARY_COMPONENT_INSTANCE,
          scopeInstanceKeys,
          title: titles.get(this._boundaryWidgetName),
        });

        const boundarySettings = new NoBoundarySettings({
          hasBoundaryIn: boundariesSelected,
          hasBoundaryOut: boundariesSelected,
          hasNoBoundary: noBoundariesSelected,
        });
        this.boundarySettingsChange.next(boundarySettings);
      }

      if (this.isSelectedCategory(SignalCategories.PressurePoints)) {
        widgetItems.push({
          componentName: this._pressureWidgetName,
          widgetInstanceKey: NEC_PRESSURE_COMPONENT_INSTANCE,
          scopeInstanceKeys,
          title: titles.get(this._pressureWidgetName),
        });

        const pressureHiddenElements =
          this.hiddenElementsByCategory[SignalCategories.PressurePoints];

        const pressureSettings = new PressureSettings({
          hideCPP: pressureHiddenElements?.some((e) => e === this._pressureCppFieldName),
          hideMPP: pressureHiddenElements?.some((e) => e === this._pressureMppFieldName),
        });

        this.pressureSettingsChange.next(pressureSettings);
      }

      if (this.isSelectedCategory(SignalCategories.LargeUsers)) {
        widgetItems.push({
          componentName: this._largeUsersWidgetName,
          widgetInstanceKey: NEC_LARGE_USERS_COMPONENT_INSTANCE,
          scopeInstanceKeys,
          title: titles.get(this._largeUsersWidgetName),
        });
      }

      if (this.isSelectedCategory(SignalCategories.LarsAndSworps)) {
        widgetItems.push({
          componentName: this._larsSworpsWidgetName,
          widgetInstanceKey: NEC_LARS_SWORPS_COMPONENT_INSTANCE,
          scopeInstanceKeys,
          title: titles.get(this._larsSworpsWidgetName),
        });
      }

      if (this.isSelectedCategory(SignalCategories.SmartMeters)) {
        widgetItems.push({
          componentName: this._smartMetersWidgetName,
          widgetInstanceKey: SMART_METERS_CONFIG_DEFAULT_KEY,
          scopeInstanceKeys,
          title: titles.get(this._smartMetersWidgetName),
        });
      }

      const { settingKey, settingArea, widgetModule, widgetPage } = this.dynamicLayoutKeys;

      this.layoutSettings = new DynamicLayoutSettings({
        layoutKey: settingKey,
        layoutArea: settingArea,
        currentUser: this._authenticationService.userCode,
        widgetPage,
        widgetModule,
        items: widgetItems,
        minItemHeight: 70,
        minItemWidth: 200,
        showCloseIcon: false,
      });
    });
  }

  private buildTitles(): Observable<Map<string, string>> {
    return this._localization.get(`${AppModules.Configuration}.wlm-ne-config-page.widgets`).pipe(
      map((ts) => {
        const titles = new Map([
          [this._boundaryWidgetName, ts['wlm-ne-configuration-boundary']],
          [this._larsSworpsWidgetName, ts['wlm-ne-configuration-lars-sworps']],
          [this._largeUsersWidgetName, ts['wlm-ne-configuration-large-user']],
          [this._pressureWidgetName, ts['wlm-ne-configuration-pressure']],
          [this._smartMetersWidgetName, ts['wlm-smart-meters-config-widget']],
        ]);
        return titles;
      })
    );
  }

  private setHiddenElementsByCategory() {
    if (!this.signalsCustomSettings) {
      return;
    }

    const fields = this.signalsCustomSettings.map((item) => item.fields);
    fields.forEach((field) => {
      Object.values(field).forEach((value: any) => {
        if (!value) {
          return;
        }

        const categoryKey = value.categoryKey;
        const hiddenElements = value.hiddenElements;

        if (categoryKey && hiddenElements) {
          this.hiddenElementsByCategory[categoryKey] = hiddenElements;
        }
      });
    });
  }
}
