import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { IGridSettings } from '@common-modules/shared/constants/grid.constants';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { DynamicGridSettings } from '@common-modules/shared/model/grid/dynamic-grid-settings';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ReplaySubject } from 'rxjs';

const COMPONENT_SELECTOR = 'wlm-campaigns-creation-activities';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './campaigns-creation-activities.component.html',
  styleUrls: ['./campaigns-creation-activities.component.scss'],
})
export class CampaignsCreationActivitiesComponent implements OnInit {
  @Input() activitiesFieldName: string;
  @Input() activitiesForm: UntypedFormGroup;
  @Input() familyId: string;
  @Input() campaignId: string;

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  dynamicGridSettings: DynamicGridSettings;
  gridFiltersForBinding: DataBindingFilters;
  gridSettings: IGridSettings;
  grid: GenericGridComponent;

  gridSettingsReady$ = new ReplaySubject<IGridSettings>();
  removeSelectionPersisted$ = new ReplaySubject<void>();
  gridSettingsChanged$ = new ReplaySubject<IGridSettings>();
  selectedActivities: any[];
  initialSelectedByFilter: DataBindingFilters;

  persistencyArea = 'ALCActivitySelection';

  private readonly _campaignIdFieldName = 'campaignId';
  private readonly _familyIdFieldName = 'hierarchyFamilyId';
  private readonly _activityIdFieldName = 'activityId';

  constructor() {}

  ngOnInit(): void {
    this.initializeGridSettings();
    this.getFormData();
  }

  getGenericGridInstance(grid: GenericGridComponent) {
    this.grid = grid;
  }

  getGridSettings(gridSettings: IGridSettings) {
    this.gridSettings = gridSettings;
    this.gridSettingsReady$.next(gridSettings);
  }

  loadGrid() {
    const familyFilter = new DataBindingFilters();
    const compositeFilter = this.getActivitiesFilter(this._campaignIdFieldName);
    familyFilter.addOrUpdateBasicFilter(this._familyIdFieldName, this.familyId);
    familyFilter.addOrUpdateCompositeFilter(this._campaignIdFieldName, compositeFilter);

    this.gridFiltersForBinding = familyFilter;
  }

  onSelectedActivities(activities) {
    const activityIds = activities?.length ? activities.map((activity) => activity.activityId) : [];

    this.activitiesForm.controls[this.activitiesFieldName].setValue(activityIds);
  }

  private getFormData() {
    const activityIds = this.activitiesForm.get(this.activitiesFieldName).value;
    if (activityIds?.length) {
      this.initialSelectedByFilter = this.getSelectedActivitiesFilter(activityIds);
    }
  }

  private getSelectedActivitiesFilter(activityIds: string[]): DataBindingFilters {
    const dataBinding = new DataBindingFilters();
    const compositeActivityFilterDescriptor = this.getSelectedActivitiesFilterDescriptor(
      activityIds,
      this._activityIdFieldName
    );

    dataBinding.addOrUpdateBasicFilter(this._familyIdFieldName, this.familyId);
    dataBinding.addOrUpdateCompositeFilter(
      this._activityIdFieldName,
      compositeActivityFilterDescriptor
    );

    return dataBinding;
  }

  getSelectedActivitiesFilterDescriptor(
    activities: string[],
    fieldName: string
  ): CompositeFilterDescriptor {
    const mainFilter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [],
    };

    if (activities?.length) {
      const compositeActivityFilter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [],
      };

      const activitiesFilterArray = [];

      activities.forEach((activity) => {
        activitiesFilterArray.push({
          operator: 'eq',
          field: fieldName,
          value: activity,
        });
      });

      compositeActivityFilter.filters = activitiesFilterArray;
      mainFilter.filters = [compositeActivityFilter];
    }

    return mainFilter;
  }

  private initializeGridSettings() {
    this.dynamicGridSettings = new DynamicGridSettings({
      disableAutoLoad: false,
      gridSettingName: 'ActivitiesSelection',
      persistencyArea: this.persistencyArea,
      type: 'generic',
      usePersistence: false,
    });

    this.loadGrid();
  }

  private getActivitiesFilter(fieldName: string): CompositeFilterDescriptor {
    const mainFilter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [],
    };

    const filterIsNull: FilterDescriptor = {
      operator: 'eq',
      field: fieldName,
      value: null,
    };

    if (this.campaignId) {
      const compositeFilter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [],
      };

      const filterIsCurrent: FilterDescriptor = {
        operator: 'eq',
        field: fieldName,
        value: this.campaignId,
      };

      compositeFilter.filters.push(filterIsNull);
      compositeFilter.filters.push(filterIsCurrent);
      mainFilter.filters.push(compositeFilter);

      return mainFilter;
    } else {
      mainFilter.filters.push(filterIsNull);

      return mainFilter;
    }
  }
}
