import { Injectable, Injector } from '@angular/core';
import { StateFullSettings } from '@common-modules/redux/models/state-full-settings';
import { StateSelectorBuilder } from '@common-modules/redux/models/state-selector-builder';
import { BaseStore, ReducerHandlerFn } from '@common-modules/redux/store/base.store';
import { ActionCreator, createAction, props } from '@ngrx/store';
import { DynamicLayoutIdentity } from '../../models/dynamic-layout-identity';
import { DynamicLayoutSettings } from '../../models/dynamic-layout-settings';
import { DynamicSettings } from '../../models/dynamic-settings';
import { WidgetDefinitionSettings } from '../../models/widget-definition-settings';

import { WidgetManagerState } from './widget-manager-state';
import { WidgetManagerActionTypes } from './widget-manager.action-types';
import {
  ResetLayoutAction,
  SelectWidgetDefinitionAction,
  SetCurrentLayoutAction,
  SetCurrentLayoutIdentityAction,
  SetLayoutKeysAction,
  SetLayoutToLoadAction,
} from './widget-manager.actions';
import { WidgetManagerSelectorTypes } from './widget-manager.selector-types';

@Injectable()
export class WidgetManagerStoreService extends BaseStore {
  readonly serviceName = 'WidgetManagerStoreService';

  constructor(injector: Injector) {
    super(injector);
  }

  protected getReducer(): Map<string, ReducerHandlerFn> {
    return new Map<string, ReducerHandlerFn>([
      [
        WidgetManagerActionTypes.SelectWidgetDefinition,
        (state: WidgetManagerState, action: SelectWidgetDefinitionAction) => {
          state.selectedDefinition = action.payload;
        },
      ],
      [
        WidgetManagerActionTypes.SetCurrentLayout,
        (state: WidgetManagerState, action: SetCurrentLayoutAction) => {
          state.currentLayout = action.payload;
        },
      ],
      [
        WidgetManagerActionTypes.SetLayoutToLoad,
        (state: WidgetManagerState, action: SetLayoutToLoadAction) => {
          state.layoutToLoad = action.payload;
        },
      ],
      [
        WidgetManagerActionTypes.SetLayoutKeys,
        (state: WidgetManagerState, action: SetLayoutKeysAction) => {
          state.layoutKeys = action.payload;
        },
      ],
      [
        WidgetManagerActionTypes.SetCurrentLayoutIdentity,
        (state: WidgetManagerState, action: SetCurrentLayoutIdentityAction) => {
          state.currentIdentity = action.payload;
        },
      ],
      [
        WidgetManagerActionTypes.ResetLayout,
        (state: WidgetManagerState, action: ResetLayoutAction) => {
          state.layoutKeysToReset = action.payload;
        },
      ],
      [
        WidgetManagerActionTypes.ResetWidgetManagerState,
        (state: WidgetManagerState, _: ResetLayoutAction) => {
          state.selectedDefinition = null;
          state.currentLayout = null;
          state.layoutToLoad = null;
          state.layoutKeys = null;
          state.currentIdentity = null;
          state.layoutKeysToReset = null;
        },
      ],
    ]);
  }

  protected getSelectors(): Map<string, StateSelectorBuilder> {
    return new Map<string, StateSelectorBuilder>([
      [
        WidgetManagerSelectorTypes.SelectedWidgetDefinition,
        {
          fn: (state: WidgetManagerState) => {
            return state?.selectedDefinition;
          },
        },
      ],
      [
        WidgetManagerSelectorTypes.CurrentLayout,
        {
          fn: (state: WidgetManagerState) => {
            return state?.currentLayout;
          },
        },
      ],
      [
        WidgetManagerSelectorTypes.LayoutToLoad,
        {
          fn: (state: WidgetManagerState) => {
            return state?.layoutToLoad;
          },
        },
      ],
      [
        WidgetManagerSelectorTypes.LayoutKeys,
        {
          fn: (state: WidgetManagerState) => {
            return state?.layoutKeys;
          },
        },
      ],
      [
        WidgetManagerSelectorTypes.CurrentLayoutIdentity,
        {
          fn: (state: WidgetManagerState) => {
            return state?.currentIdentity;
          },
        },
      ],
      [
        WidgetManagerSelectorTypes.ResetLayout,
        {
          fn: (state: WidgetManagerState) => {
            return state?.layoutKeysToReset;
          },
        },
      ],
    ]);
  }

  protected getActionCreators(): ActionCreator<any>[] {
    return [
      createAction(
        WidgetManagerActionTypes.SelectWidgetDefinition,
        props<{ payload: WidgetDefinitionSettings; settings: StateFullSettings }>()
      ),
      createAction(
        WidgetManagerActionTypes.SetCurrentLayout,
        props<{ payload: DynamicLayoutSettings; settings: StateFullSettings }>()
      ),
      createAction(
        WidgetManagerActionTypes.SetLayoutToLoad,
        props<{ payload: DynamicLayoutSettings; settings: StateFullSettings }>()
      ),
      createAction(
        WidgetManagerActionTypes.SetLayoutKeys,
        props<{ payload: DynamicSettings; settings: StateFullSettings }>()
      ),
      createAction(
        WidgetManagerActionTypes.SetCurrentLayoutIdentity,
        props<{ payload: DynamicLayoutIdentity; settings: StateFullSettings }>()
      ),
      createAction(
        WidgetManagerActionTypes.ResetLayout,
        props<{ payload: DynamicSettings; settings: StateFullSettings }>()
      ),
      createAction(
        WidgetManagerActionTypes.ResetWidgetManagerState,
        props<{ null; settings: StateFullSettings }>()
      ),
    ];
  }
}
