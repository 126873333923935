import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { GenericCardSettings } from '@common-modules/shared-component/generic-card/generic-card-settings';
import { IFiltrableItemDto } from '@common-modules/shared/model/filtrable-items/filtrable-item.dto';

const COMPONENT_SELECTOR = 'wlm-data-visualization-cart-group';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-cart-group.component.html',
  styleUrls: ['./data-visualization-cart-group.component.scss'],
})
export class DataVisualizationCartGroupComponent implements OnInit {
  @ContentChild('card', { static: false }) cardTemplateRef: TemplateRef<any>;

  @Input() title: string;

  private _items: IFiltrableItemDto[];
  public get items(): IFiltrableItemDto[] {
    return this._items;
  }
  @Input()
  public set items(value: IFiltrableItemDto[]) {
    this._items = value;
  }

  @Input() cardSettings: GenericCardSettings<IFiltrableItemDto>;

  constructor() {}

  ngOnInit(): void {}
}
