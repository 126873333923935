import { Injectable, Injector } from '@angular/core';
import { GetCacheOpts } from '@common-modules/cache/http-cache/http-cache.client';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElementNetworkElementsConfigurationDto } from '../../shared/model/ne/ene-configuration.dto';

const endpointUrl = '/api/elementnetworkelement';
@Injectable({
  providedIn: 'root',
})
export class NeConfigurationEneService extends GridODataService<INetworkElementDto> {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/selection`);
  }

  protected mapResponse(
    response: PagedResultDto<INetworkElementDto>
  ): PagedResultDto<INetworkElementDto> {
    return response;
  }

  getElementNetworkElementsByElementId(elementId: string): Observable<INetworkElementDto[]> {
    return this.httpCacheClient.get(`${this.baseUrl}${endpointUrl}/${elementId}`, { avoid: true });
  }

  saveElementNetworkElementsConfiguration(
    eneConfiguration: ElementNetworkElementsConfigurationDto
  ): Observable<boolean> {
    return this.httpCacheClient
      .post(`${this.baseUrl}${endpointUrl}/save`, eneConfiguration)
      .pipe(map(() => true));
  }
}
