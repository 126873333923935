import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';

export class NotificationGridSetting {
  isNotificable: boolean;
  identifierFieldName: string[];
  zoneIdColumnName: string;
  navKey: NavKeys;

  constructor(init: NotificationGridSetting) {
    Object.assign(this, init);
  }
}
