import { Component, Input, inject } from '@angular/core';
import { IntegrableForm, IntegrableFormParams } from '@common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { IBillingClassDto } from '../../../shared/model/customer/billing-class.dto';

@Component({
  selector: 'wlm-billing-class-form',
  templateUrl: './billing-class-form.component.html',
  styleUrls: ['./billing-class-form.component.scss'],
})
export class BillingClassFormComponent extends IntegrableForm {
  @Input() initialModel: IBillingClassDto;
  model: IBillingClassDto;
  private readonly _globalsService = inject(GlobalsService);

  onModelChange(model: IBillingClassDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'billingClassId',
          type: 'input',
          className: 'col-3',
          props: {
            label: 'Billing Class Id',
            required: true,
          },
          expressions: {
            'props.disabled': (data) => this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'billingClassName',
          type: 'input',
          className: 'col-6',
          props: {
            label: 'Billing Class Name',
            required: true,
          },
        },
        {
          key: 'customerClassTypeId',
          type: 'select',
          className: 'col-3',
          props: {
            label: 'Customer Class Type',
            valueProp: 'customerClassTypeId',
            labelProp: 'customerClassTypeName',
            required: true,
            options: this._globalsService.getCustomerClassTypes(),
          },
        },
        {
          key: 'minBilling',
          type: 'input',
          className: 'col-6',
          props: {
            type: 'number',
            label: 'Minimum Billing',
            required: true,
          },
        },
        {
          key: 'maxBilling',
          type: 'input',
          className: 'col-6',
          props: {
            type: 'number',
            label: 'Maximum Billing',
            required: true,
          },
        },
      ],
    },
  ];
}
