// prettier-ignore
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FilterItemSummaryRow } from '@common-modules/common-filters/models/filter-item-summary-row';
import { IHierarchyElementPathDto } from '@common-modules/dependencies/he/hierarchy-element-path.dto';
import * as constants from '@common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { FiltersPayloadStatus } from '@common-modules/dependencies/wlm-filters/filters-payload-status.enum';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { HierarchyElementPathFilter } from '@common-modules/dependencies/wlm-filters/i-filters/hierarchy-element-path-filter';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { SelectOption } from '@common-modules/shared/model/shared/select-option';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectableSettings, TreeViewComponent } from '@progress/kendo-angular-treeview';
import { HierarchyTreeFilterHelperService } from '@water-loss//features/shared/filters/hierarchy/hierarchy-tree-filter/hierarchy-tree-filter-helper.service';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { AdaptedFilterItem } from '../../core/hooks/adapted-filter-item';
import { FilterHookAfterMenuOpen } from '../../core/hooks/filter-hook-after-menu-open';

const COMPONENT_SELECTOR = 'wlm-hierarchy-tree-filter-item';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './hierarchy-tree-filter-item.component.html',
  styleUrls: ['./hierarchy-tree-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => HierarchyTreeFilterItemComponent),
    },
  ],
})
export class HierarchyTreeFilterItemComponent
  extends BaseFilterItemComponent
  implements OnInit, FilterHookAfterMenuOpen, AdaptedFilterItem
{
  @ViewChild(TreeViewComponent) public treeControl: TreeViewComponent;

  private _selectedNodes: IHierarchyElementPathDto[];

  public get selectedNodes(): IHierarchyElementPathDto[] {
    return this._selectedNodes;
  }

  @Input() public set selectedNodes(nodes: IHierarchyElementPathDto[]) {
    this.assignSelectedNodes(nodes);
  }

  @Input() sendRootLevel = false;

  treeSettingsReady$ = new ReplaySubject<ITreeSettings>();
  private _treeSettings: ITreeSettings;
  public get treeSettings(): ITreeSettings {
    return this._treeSettings;
  }
  @Input() public set treeSettings(value: ITreeSettings) {
    this._treeSettings = { ...value };
    this._treeSettings.unselectableNodes = this._treeSettings.unselectableNodes ?? [];
    this._treeSettings.unselectableTypes = this._treeSettings.unselectableTypes ?? [];
    this.treeSettingsReady$.next(this._treeSettings);
  }

  @Input() hierarchyFamilyIdFieldName: string;
  @Input() hierarchyElementIdFieldName: string;

  private _initialSelectedKeys: string[];
  public get initialSelectedKeys(): string[] {
    return this._initialSelectedKeys;
  }
  @Input() public set initialSelectedKeys(value: string[]) {
    this._initialSelectedKeys = value;
    if (this.initialSelectedKeys?.length) {
      this.selectedKeys = [];
      this.filterTreeBySearchValues(this.initialSelectedKeys);
    }
  }
  @Input() rebuildTree$: Observable<void>;
  @Input() set restoreStatusOrder(value: number) {
    this.restoreOrder = value;
  }

  @Output() selectedFiltersChange = new EventEmitter<HierarchyElementPathFilter>();
  @Output() treeSettingsChange = new EventEmitter<ITreeSettings>();
  @Output() selectedNodesChange = new EventEmitter<IHierarchyElementPathDto[]>();
  @Output() filterReady = new EventEmitter<void>();

  private _treeNodes: IHierarchyElementPathDto[];
  public get treeNodes(): IHierarchyElementPathDto[] {
    return this._treeNodes;
  }
  public set treeNodes(value: IHierarchyElementPathDto[]) {
    this._treeNodes = value;
    this.preprocessViewData(this._treeNodes);
  }

  filteredTreeNodes: IHierarchyElementPathDto[];
  selectedKeys: any[] = [];
  expandedKeys: any[] = [];
  oldUnselectableTypes: string[];
  isLoading = false;
  treeIsEmpty = false;
  errorMessage: string;
  lastSelectedNode: IHierarchyElementPathDto;
  private initialStatus: IHierarchyElementPathDto[];
  processedNodeIsSelectable: { [descendant: string]: boolean } = {};

  filtered$ = new Subject<FiltersPayload>();
  lastEmittedKeys: string[] = [];
  // Used for displaying the summary.
  summaryItems: { [key: string]: FilterItemSummaryRow[] } = {};
  summaryItemsKeys: string[] = [];
  // Indicates the first family when the component is loaded
  initialFamilyId: string;
  isFirstLoad = true;

  private _hierarchyFamilyId: string;

  get hierarchyFamilyId(): string {
    return this._hierarchyFamilyId;
  }

  @Input() set hierarchyFamilyId(value) {
    if (!this.initialFamilyId && value) {
      this.initialFamilyId = value;
    }

    this._hierarchyFamilyId = value;
    this.initializeTree(this._hierarchyFamilyId);
  }

  private _filterText: string;

  public get filterText(): string {
    return this._filterText;
  }

  @Input() public set filterText(value: string) {
    this._filterText = value?.toUpperCase();

    if (!this.treeNodes) {
      return;
    }

    if (!this.filterText) {
      this.filteredTreeNodes = [...this.treeNodes];
      this.expandedKeys = this.getAllSelectedKeysWithAncestors();
    } else {
      this.filteredTreeNodes = this.treeNodes.filter((node) =>
        node.descendantDescription.toUpperCase().includes(this.filterText)
      );
      const unselectableTypes = this.treeSettings.unselectableTypes ?? [];
      const unselectableNodes = this.treeSettings.unselectableNodes ?? [];
      this.filteredTreeNodes = this.filteredTreeNodes.filter(
        (x) =>
          unselectableTypes.findIndex((y) => y === x.descendantHierarchyElementTypeName) === -1 &&
          unselectableNodes.findIndex(
            (y) => y.toLocaleUpperCase() === x.descendant.toLocaleLowerCase()
          ) === -1
      );
      this.filteredTreeNodes = this.includeParents(this.filteredTreeNodes);
      this.expandedKeys = this.filteredTreeNodes.map((item) => this.getDisplayableField(item));
    }
  }

  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;

  constructor(
    private globalService: GlobalsService,
    private hierarchyTreeFilterHelperService: HierarchyTreeFilterHelperService,
    private localization: LocalizationHelperService,
    private objectHelperService: ObjectHelperService
  ) {
    super();

    // Adds configuration instead of replacing it.
    this.settings = {
      maxTemplateSummaryItems: 20,
    };
  }

  ngOnInit(): void {
    this.treeSettingsChange.emit(this.treeSettings);
    this.oldUnselectableTypes = this.treeSettings.unselectableTypes;
    if (this.rebuildTree$) {
      this.rebuildTree$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.initializeTree(this.hierarchyFamilyId));
    }
  }

  /**
   * Allows us to assign selected nodes while specifying that the triggered filter event has a specific state.
   */
  private assignSelectedNodes(
    nodes: IHierarchyElementPathDto[],
    status = FiltersPayloadStatus.Normal
  ): void {
    if (this.lastSelectedNode) {
      // When a node is selected, their ancestors and children are unselected.
      this._selectedNodes = this.removeAncestorsAndDescendants(
        this.lastSelectedNode,
        nodes,
        this.treeNodes
      );
    } else {
      this._selectedNodes = nodes ? nodes.filter((node) => !this.isRootNode(node)) : [];
    }

    // If no node is selected, set default node(s)
    if (this._selectedNodes.length === 0 && this.treeNodes && this.treeNodes.length !== 0) {
      // check for default nodes in the settings..
      this._selectedNodes = this.treeSettings.defaultSelectedNodes
        ? this.treeNodes.filter((x) =>
            this.treeSettings.defaultSelectedNodes.includes(x.descendant)
          )
        : // ...if not, set the root node by default
          this.treeNodes.filter(this.isRootNode);
    }

    // Sync the selectedKeys with the selectedNodes
    this.selectedKeys =
      this.selectedNodes === undefined ? [] : this.selectedNodes.map((n) => n.descendant);

    this.buildSummaryItems();
    this.notifyChanges(status);
  }

  get selection(): SelectableSettings {
    return { mode: this.treeSettings.selectionMode };
  }

  initializeTree(filteredHierarchyFamilyId: any) {
    if (filteredHierarchyFamilyId === undefined) {
      return;
    }
    this.isLoading = true;
    this.treeIsEmpty = false;
    this.setClear(true);
    this.globalService.getHierarchyElementPaths().subscribe({
      next: (hierarchyElements) => {
        this.treeNodes = hierarchyElements.filter((element) =>
          element.hierarchyFamilyIds.includes(filteredHierarchyFamilyId)
        );
        this.assignSelectedNodes([], FiltersPayloadStatus.Initial); // By default, it will set root nodes.
        this.filteredTreeNodes = this.loadTreeFromAOIList();
        const searchOptions = this.filteredTreeNodes.map((e) => e.descendant).sort();
        this.treeSettings.autocompleteSetting.itemSource = searchOptions.filter(
          (v, i, a) => a.indexOf(v) === i // <-- to get unique values
        );

        if (this.filteredTreeNodes.filter((n) => n.ancestor === undefined).length === 0) {
          this.treeIsEmpty = true;
        }

        if (
          this.isFirstLoad &&
          this.initialFamilyId === this.hierarchyFamilyId &&
          this.initialSelectedKeys?.length > 0
        ) {
          this.filterTreeBySearchValues(this.initialSelectedKeys);
          this.initialSelectedKeys = [];
        }

        this.isLoading = false;
        this.filterReady.emit();
      },
      error: (err) => (this.errorMessage = err),
    });
  }

  private loadTreeFromAOIList(): IHierarchyElementPathDto[] {
    let aoiDefined = this.treeSettings.aoiList;
    if (aoiDefined === null || aoiDefined === undefined || aoiDefined.length === 0) {
      return this.treeNodes;
    }

    this.treeSettings.aoiList.forEach((e) => {
      aoiDefined = this.hierarchyTreeFilterHelperService.mergeStringLists(
        aoiDefined,
        this.getHierarchyNodes(this.treeNodes, e, constants.searchDirection.up)
      );
      aoiDefined = this.hierarchyTreeFilterHelperService.mergeStringLists(
        aoiDefined,
        this.getHierarchyNodes(this.treeNodes, e, constants.searchDirection.down)
      );
    });

    let treeFilteredByAOI: IHierarchyElementPathDto[] = [];
    treeFilteredByAOI = this.treeNodes.filter((e) => aoiDefined.indexOf(e.descendant) > -1);

    return treeFilteredByAOI;
  }

  /**
   * Fix visual errors that appear with the combination of mat-menu and tree-view.
   */
  fhAfterMenuOpen(): void {
    this.isLoading = true;
    setTimeout(() => (this.isLoading = false));
  }

  private getHierarchyNodes(
    tree: IHierarchyElementPathDto[],
    initialNode: string,
    direction: string
  ): string[] {
    if (initialNode === undefined || initialNode === null) {
      return [];
    }
    let result = [initialNode];
    let nextNode: IHierarchyElementPathDto[];
    if (direction === constants.searchDirection.up) {
      nextNode = tree.filter((e) => e.descendant === initialNode);
      return nextNode.length > 0
        ? result.concat(
            this.getHierarchyNodes(
              tree,
              tree.filter((e) => e.descendant === initialNode)[0].ancestor,
              direction
            )
          )
        : result;
    } else {
      nextNode = tree.filter((e) => e.ancestor === initialNode);

      if (nextNode.length === 0) {
        return result;
      }

      nextNode.forEach((node) => {
        const locatedNode = this.getHierarchyNodes(tree, node.descendant, direction);
        result = result.concat(locatedNode);
      });

      return result;
    }
  }

  /**
   * For each selected node, set it as selected and expand all its ancestors.
   * This version works with N nodes.
   */
  private filterTreeBySearchValues(values: string[]): IHierarchyElementPathDto[] {
    if (!this.treeNodes?.length || !this.treeSettings?.displayableField) {
      return;
    }

    const currentSelectedNodes = [];
    let currentExpandedKeys = [];

    values.forEach((value) => {
      const searchResult = this.hierarchyTreeFilterHelperService.search(
        this.treeNodes,
        value,
        this.treeSettings?.displayableField
      );
      const selected: IHierarchyElementPathDto = searchResult
        .filter((x) => this.getDisplayableField(x) === value)
        .pop();
      if (selected) {
        currentSelectedNodes.push(selected);
      }
      currentExpandedKeys = currentExpandedKeys.concat(
        searchResult.map((x) => this.getDisplayableField(x))
      );
    });

    this.setSelectedNodes(currentSelectedNodes, FiltersPayloadStatus.Initial);
    // Apply unique to keys
    this.expandedKeys = currentExpandedKeys.filter((v, i, a) => a.indexOf(v) === i);

    return currentSelectedNodes;
  }

  public familyChange(event: any): void {
    this.initializeTree(event.value);
  }

  public handleSelection(event: any): void {
    const item = event.dataItem as IHierarchyElementPathDto;

    this.lastSelectedNode = item;

    this.selectedKeys =
      this.selectedNodes === undefined ? [] : this.selectedNodes.map((n) => n.descendant);

    this.setSelectedNodes([item]);
  }

  public setSelectedNodes(items: IHierarchyElementPathDto[], status = FiltersPayloadStatus.Normal) {
    let currentSelectedKeysTotal = [];
    items.forEach((item) => {
      let currentSelectedKeys = [];
      if (
        this.treeSettings.unselectableTypes.indexOf(item.descendantHierarchyElementTypeName) > -1 ||
        this.treeSettings?.unselectableNodes?.indexOf(item.descendant) > -1
      ) {
        currentSelectedKeys = this.getSelectedKeyBranch(item, false);
      } else {
        if (this.selectedKeys.indexOf(item.descendant) > -1) {
          currentSelectedKeys = this.getSelectedKeyBranch(item, false);
        } else {
          currentSelectedKeys =
            this.treeSettings.selectionMode === constants.treeSelectionMode.single
              ? [item.descendant]
              : this.getSelectedKeyBranch(item);
        }
      }

      currentSelectedKeysTotal = currentSelectedKeysTotal.concat(currentSelectedKeys);
    });

    this.selectedKeys = currentSelectedKeysTotal;

    this.assignSelectedNodes(this.getSelectedNodes(), status);

    this.filterByProyectedTypes();

    this.notifyChanges(status);
  }

  onSelectedChanged(event: any, item) {
    const nodesWithoutItem = this.selectedNodes.filter((x) => x.descendant != item.key);
    this.selectedNodes = nodesWithoutItem;
  }

  private notifyChanges(status = FiltersPayloadStatus.Normal) {
    // If the only selected node is a root node, emit an empty filter event.
    // The code in "set selectedNode" ensures that selecting root nodes will exclude all others.
    const rootNodeSelected = this.selectedNodes.find(this.isRootNode);
    let selected = rootNodeSelected && !this.sendRootLevel ? [] : this.selectedKeys;

    // When the selectionChange event is fired, we set the selectedKeys again,
    // which fires a second selectionChange event.
    // Only set the nodes when the keys are different from the last filter event.
    if (!this.objectHelperService.deepEqual(this.selectedKeys, this.lastEmittedKeys)) {
      // Very important to sort, as ['a', 'b'] is not equal to ['b', 'a'].
      selected = selected.sort();

      this.lastEmittedKeys = [...selected];
      // This event will still emit the value.
      this.selectedNodesChange.emit(this.selectedNodes);
      const elementsWithNames = this.buildElementsWithNames();
      const filter = new BasicFilter(this.hierarchyElementIdFieldName, selected, {
        elementsWithNames,
      });
      const payload = this.buildPayload([filter], status);
      this.filtered$.next(payload);

      if (this.hierarchyElementIdFieldName) {
        this.emitHierarchyElementPathFilter();
      }
    }
  }

  private buildElementsWithNames(): SelectOption<string>[] {
    const elementsWithNames = this.selectedNodes.map(
      (node) =>
        ({
          value: node.descendant,
          label: node.descendantHierarchyElementName,
        } as SelectOption<string>)
    );
    return elementsWithNames;
  }

  /**
   * Converts the selected nodes in a summary format. Then groups them by title.
   */
  private buildSummaryItems(): void {
    if (this.selectedNodes && this.selectedNodes.length !== 0) {
      let items: { title: string; label: string; key: string }[] = [];
      // We can limit the amount of summary items to show by configuration.
      const nodesToShow = this.settings?.maxTemplateSummaryItems ?? this.selectedNodes.length;
      const summaryNodes = this.selectedNodes.slice(0, nodesToShow);

      items = summaryNodes.map((node) => {
        return {
          label: node.descendantDescription,
          title: this.getAncestors(node, this.treeNodes)
            .map((item) => item.descendantDescription)
            .join(' -> '),
          key: node.descendant,
        };
      });

      const groupedItems: { [key: string]: FilterItemSummaryRow[] } = {};
      items.forEach((item) => {
        const summaryItem = new FilterItemSummaryRow(item.label, null, item.key);
        if (typeof groupedItems[item.title] !== 'undefined') {
          const values = [summaryItem, ...groupedItems[item.title]].sort((a, b) =>
            a.label > b.label ? 1 : -1
          );
          groupedItems[item.title] = values;
        } else {
          groupedItems[item.title] = [summaryItem];
        }
      });
      this.summaryItems = groupedItems;
      this.summaryItemsKeys = Array.from(Object.keys(this.summaryItems)).sort((a, b) =>
        a.length > b.length ? 1 : -1
      );
    }
  }

  /**
   * Instead of checking while rendering each node, we preprocess some content, like flags or styles, so
   * they can be direcly accessed in the view.
   * Both the treeNodes and the settings must be available.
   */
  private preprocessViewData(allNodes: IHierarchyElementPathDto[]): void {
    // Wait for the filter settings to be ready.
    this.treeSettingsReady$.pipe(untilDestroyed(this)).subscribe(() => {
      // Check if all nodes are selectable.
      const nodeIsSelectable = {};
      allNodes.forEach((node) => {
        const isSelectable = this.isNodeSelectable({
          descendantHierarchyElementTypeName: node.descendantHierarchyElementTypeName,
          descendant: node.descendant,
        });
        nodeIsSelectable[node.descendant] = isSelectable;
      });
      // Refresh the view with deep copy.
      this.processedNodeIsSelectable = { ...nodeIsSelectable };
    });
  }

  /**
   * Get all the ancestors nodes of a node.
   */
  private getAncestors(
    node: IHierarchyElementPathDto,
    nodes: IHierarchyElementPathDto[]
  ): IHierarchyElementPathDto[] {
    const ancestors = [];
    const parentNode = nodes.find((item) => item.descendant === node.ancestor);
    if (parentNode) {
      this.getAncestorsRecursive(parentNode, ancestors, nodes);
    }
    return ancestors;
  }

  /**
   * Get all the ancestors nodes of a node. Helper method.
   */
  private getAncestorsRecursive(
    node: IHierarchyElementPathDto,
    ancestors: IHierarchyElementPathDto[],
    nodes: IHierarchyElementPathDto[]
  ): void {
    ancestors.unshift(node);
    const parentNode = nodes.find((item) => item.descendant === node.ancestor);
    if (parentNode) {
      this.getAncestorsRecursive(parentNode, ancestors, nodes);
    }
  }

  manageHierarchicalSelection(item: IHierarchyElementPathDto) {
    const retrieved = this.getSelectedKeyBranch(item);
    const newElements = retrieved.filter((f) => this.selectedKeys.indexOf(f) === -1);
    this.selectedKeys = this.selectedKeys.filter((f) => retrieved.indexOf(f) < 0);
    this.selectedKeys = this.selectedKeys.concat(newElements);
  }

  filterByProyectedTypes() {
    if (!this.treeSettings) {
      return;
    }

    if (this.treeSettings.projectableTypes && this.treeSettings.useSelectRecursive) {
      this.selectedNodes = this.selectedNodes.filter(
        (f) => this.treeSettings.projectableTypes.indexOf(f.descendantHierarchyElementTypeName) > -1
      );
      this.selectedKeys = this.selectedNodes.map((n) => n.descendant);
    }
  }

  private emitHierarchyElementPathFilter() {
    const elementsWithNames = this.buildElementsWithNames();
    const heFilter = new HierarchyElementPathFilter(
      // tslint:disable-next-line: no-string-literal
      this.hierarchyFamilyId,
      this.selectedNodes.map((n) => n.descendant),
      this.hierarchyFamilyIdFieldName,
      this.hierarchyElementIdFieldName,
      elementsWithNames
    );
    this.selectedFiltersChange.emit(heFilter);
  }

  private getSelectedKeyBranch(item: IHierarchyElementPathDto, add: boolean = true): any[] {
    if (this.treeSettings.useSelectRecursive) {
      const nodeDescendants = this.getHierarchyNodes(
        this.filteredTreeNodes,
        item.descendant,
        constants.searchDirection.down
      );
      const selectedNodes = this.filteredTreeNodes.filter(
        (n) => nodeDescendants.indexOf(n.descendant) > -1
      );
      const selectableNodes = selectedNodes
        .filter(
          (n) =>
            this.treeSettings.unselectableTypes.indexOf(n.descendantHierarchyElementTypeName) ===
              -1 || this.treeSettings?.unselectableNodes?.indexOf(n.descendant) === -1
        )
        .map((m) => m.descendant);

      return add
        ? this.hierarchyTreeFilterHelperService.mergeStringLists(this.selectedKeys, selectableNodes)
        : this.selectedKeys.filter((n) => selectableNodes.indexOf(n) < 0);
    }

    return add
      ? this.selectedKeys.concat([item.descendant])
      : this.selectedKeys.filter((e) => e !== item.descendant);
  }

  public getSelectedNodes() {
    if (!this.treeNodes) {
      return [];
    }

    return this.treeNodes.filter((e) => {
      return this.selectedKeys.indexOf(e.descendant) > -1;
    });
  }

  public clearSelection() {
    this.selectedKeys = [];
    this.selectedNodes = [];
  }

  public isItemSelected = (dataItem: any) =>
    this.selectedKeys.indexOf((dataItem as IHierarchyElementPathDto).descendant) > -1;

  public handleClick(event: any): void {
    event.originalEvent.preventDefault();
  }

  public isNodeSelectable({ descendantHierarchyElementTypeName, descendant }: any): boolean {
    // We have previously checked in the "set treeSettings" that unselectableTypes and unselectableNodes are defined.
    return (
      this.treeSettings.unselectableTypes.indexOf(descendantHierarchyElementTypeName) === -1 &&
      this.treeSettings.unselectableNodes.indexOf(descendant) === -1
    );
  }

  removeChip(selectedNodeValue): void {
    const node = this.selectedNodes.filter((item) => item.descendant === selectedNodeValue).pop();

    this.setSelectedNodes([node]);
  }

  ClearAllChips(event: any) {
    this.selectedKeys = [];
    this.selectedNodes = [];
    this.notifyChanges();
  }

  setUseSelectRecursive(e: MatSlideToggleChange) {
    this.treeSettings.useSelectRecursive = e.checked;
  }

  public buildIndex(currentNode: any, base: string = ''): string {
    if (!currentNode) {
      return null;
    }

    const sameLevelItems = this.filteredTreeNodes.filter(
      (node) => node.ancestor === currentNode.ancestor
    );
    const levelIndex = sameLevelItems.indexOf(currentNode);
    const merged = `${levelIndex}${base ? '_' : ''}${base}`;

    if (currentNode.ancestor === null || currentNode.ancestor === undefined) {
      return merged;
    } else {
      const parent = this.filteredTreeNodes.find(
        (nodes) => nodes.descendant === currentNode.ancestor
      );
      return this.buildIndex(parent, merged);
    }
  }

  /**
   * Include all the ancestors of the nodes. Only include each node once.
   */
  private includeParents(nodes: IHierarchyElementPathDto[]): IHierarchyElementPathDto[] {
    let results = [...nodes];
    nodes.forEach((node) => {
      results = results.concat(this.getAncestors(node, this.treeNodes));
    });
    const resultsUnique: IHierarchyElementPathDto[] = [];
    const included: Map<string, boolean> = new Map();
    results.forEach((item) => {
      if (!included.has(item.descendant)) {
        resultsUnique.push(item);
        included.set(item.descendant, true);
      }
    });
    return resultsUnique;
  }

  /**
   * Check if a node is at the top of the tree (company node).
   */
  isRootNode(node: IHierarchyElementPathDto): boolean {
    return node && !node.ancestor;
  }

  /**
   * If a node is selected, remove all its ancestors and all its descendants.
   */
  removeAncestorsAndDescendants(
    current: IHierarchyElementPathDto,
    selectedNodes: IHierarchyElementPathDto[],
    treeNodes: IHierarchyElementPathDto[]
  ): IHierarchyElementPathDto[] {
    if (!current) {
      return selectedNodes;
    }
    if (!selectedNodes) {
      return [];
    }
    const ancestors = this.getAncestors(current, treeNodes);
    const children = this.getChildren(current, treeNodes);
    const mustExclude = [...ancestors, ...children];
    const result = [];
    selectedNodes.forEach((node) => {
      if (!mustExclude.find((excluded) => excluded.descendant === node.descendant)) {
        result.push(node);
      }
    });
    return result;
  }

  /**
   * Find all children of a current node and an array.
   */
  getChildren(
    current: IHierarchyElementPathDto,
    nodes: IHierarchyElementPathDto[]
  ): IHierarchyElementPathDto[] {
    const children = nodes.filter((node) => node.ancestor === current.descendant);
    if (children.length === 0) {
      return [];
    }
    let result = children;
    children.forEach((child) => {
      result = result.concat(this.getChildren(child, nodes));
    });
    return result;
  }

  getDisplayableField(node: IHierarchyElementPathDto): string {
    return this.hierarchyTreeFilterHelperService.getDisplayableField(
      node,
      this.treeSettings.displayableField
    );
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  setClear(isInitial = false): void {
    this.clearSelection();
    this.summaryItems = {};
    this.summaryItemsKeys = [];
    this.expandedKeys = [];
    this.notifyChanges(isInitial ? FiltersPayloadStatus.Initial : FiltersPayloadStatus.Normal);
  }

  setSelectAll(): void {}

  getFiltered$(): Observable<FiltersPayload> {
    return this.filtered$.asObservable();
  }

  getStateFormatted(): Observable<string> {
    if (!this.selectedKeys || this.selectedKeys.length === 0 || this.settings?.hideInputSummary) {
      return of('');
    }

    const selectedLabels = this.selectedKeys.map((descendant) => {
      const found = this.selectedNodes.find((node) => node.descendant === descendant);
      const label = found ? found.descendantDescription : descendant;
      return label;
    });

    const labels = selectedLabels.sort((a, b) => (a > b ? 1 : -1));
    const joined = labels.join(', ');
    return this.localization.get(`${this.T_SCOPE}.input-summary`).pipe(
      map((label) => {
        return this.settings?.hideInputSummaryLabel ? joined : `${label}${joined}`;
      })
    );
  }

  isValid(): boolean {
    return this.selectedKeys && this.selectedKeys.length !== 0;
  }

  setInitialState(): void {
    this.initialStatus = [...this.selectedNodes];
    this.initialFamilyId = this.hierarchyFamilyId.slice();
  }

  setRestoreState(): void {
    this.lastSelectedNode = null;
    this.selectedNodes = [...this.initialStatus];
    this.initialSelectedKeys = [...this.initialStatus.map((x) => x.descendant)];
  }

  getFieldNames(): string[] {
    return [this.hierarchyFamilyIdFieldName, this.hierarchyElementIdFieldName];
  }

  private getAllSelectedKeysWithAncestors(): string[] {
    const nodesWithAncestors = this.includeParents(this.selectedNodes);
    return nodesWithAncestors.map((item) => this.getDisplayableField(item));
  }

  getAdapter(): FilterAdapterEnum {
    return FilterAdapterEnum.HierarchyElementPathFilter;
  }
}
