// prettier-ignore
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpCacheService } from '@common-modules/cache/http-cache/http-cache.service';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { BaseWizardStepComponent } from '@common-modules/dependencies/shared/base-wizard-step.component';
import { NetworkElementWizardStepConfiguration } from '@common-modules/dependencies/shared/ne-wizard-step-configuration';
import { ElementTypeFilter } from '@common-modules/dependencies/wlm-filters/i-filters/element-type-filter';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { GridBtnsEvent } from '@common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from '@common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject } from 'rxjs';

const COMPONENT_SELECTOR = 'wlm-ne-selection-grid';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-selection-grid.component.html',
  styleUrls: ['./ne-selection-grid.component.scss'],
  providers: [
    {
      provide: BaseWizardStepComponent,
      useExisting: forwardRef(() => NeSelectionGridComponent),
    },
  ],
})
export class NeSelectionGridComponent extends BaseWizardStepComponent implements OnInit {
  @ViewChild(GenericGridComponent) genericGrid: GenericGridComponent;

  private _neSelectionConfiguration: NetworkElementWizardStepConfiguration;
  public get neSelectionConfiguration(): NetworkElementWizardStepConfiguration {
    return this._neSelectionConfiguration;
  }
  @Input() public set neSelectionConfiguration(value: NetworkElementWizardStepConfiguration) {
    this._neSelectionConfiguration = value;
    if (value) {
      this.hideFilterButtons = value.hideFilterButtons;
    }
  }

  @Input() hideComponent = false;

  private _resetHandler$: Observable<boolean>;
  public get resetHandler$(): Observable<boolean> {
    return this._resetHandler$;
  }
  @Input() public set resetHandler$(v: Observable<boolean>) {
    this._resetHandler$ = v;
    this.subscribeToResetWizard(v);
  }

  private _persistencyArea: string;
  public get persistencyArea(): string {
    return this.neSelectionConfiguration?.persistencyArea ?? this._persistencyArea;
  }
  @Input() public set persistencyArea(value: string) {
    this._persistencyArea = value;
  }

  @Input() gridName = 'NetworkElementsSelection';
  @Input() gridCssClass: string;

  @Output() selectedNetworkElementsChange = new EventEmitter<INetworkElementDto[]>();

  gridSettings: GridSetting;
  gridFiltersForBinding: DataBindingFilters; // = new DataBindingFilters();
  gridFilters: DataBindingFilters;
  autoloadFilter = true;
  clearAll$ = new Subject<void>();
  gridSettingsReady$ = new ReplaySubject<GridSetting>();
  neTypeFilterIsValid: boolean;
  selectedNEs: INetworkElementDto[];
  stepKey = 'ne-step';
  mustClearFilters = false;
  neTypeFilterName = 'networkElementType';
  removeSelection$ = new Subject<void>();
  hideFilterButtons = false;

  constructor(
    private _gridSettingService: GridSettingsService,
    private _cacheService: HttpCacheService
  ) {
    super();
  }

  ngOnInit(): void {
    this._gridSettingService.getGridSettingsByName(this.gridName).subscribe({
      next: (gridSettings) => {
        this.gridSettings = gridSettings;
        this.gridSettingsReady$.next(this.gridSettings);
        this.gridSettingsReady$.complete();
      },
    });
  }

  loadGrid() {
    const newGridFilters = new DataBindingFilters();
    newGridFilters.filters = this.gridFilters?.filters ?? new Map<string, IFilter>();
    this.gridFiltersForBinding = newGridFilters;
  }

  onSelectedItemsChange(selectedNE: INetworkElementDto[]) {
    this.selectedNEs = selectedNE;
    this.selectedNetworkElementsChange.emit(this.selectedNEs);
  }

  onFiltersChange(filter: DataBindingFilters) {
    this.gridFilters = filter;
  }

  validateFilters() {
    if (!this.gridFilters || !this.gridFilters.filters.has(this.neTypeFilterName)) {
      return false;
    }

    const neTypesValue = (this.gridFilters.filters.get(this.neTypeFilterName) as ElementTypeFilter)
      ?.selectedElements;

    return neTypesValue.length > 0;
  }

  onApply() {
    this.neTypeFilterIsValid = this.validateFilters();
    if (this.neTypeFilterIsValid) {
      this.loadGrid();
    }
  }

  private subscribeToResetWizard(resetHandler$: Observable<boolean>) {
    if (resetHandler$) {
      resetHandler$.pipe(untilDestroyed(this)).subscribe((hasToReset) => {
        if (hasToReset) {
          this._cacheService
            .clearContainsInUrl(this.persistencyArea) // clear selected rows in cache
            .then((success) => {
              this.removeSelection$.next();
              this.clearAll$.next(); // notify all filters to perform clearAll
              this.genericGrid?.clearFilters();
              this.selectedNEs = [];
              this.neTypeFilterIsValid = false;
              this.selectedNetworkElementsChange.emit(this.selectedNEs);
              this.resetCompleteHandler$?.next();
            });
        }
      });
    }
  }

  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
    this.neTypeFilterIsValid = false;
  }

  emitPersistedValue() {
    if (this.hasToEmitPersistencyValue) {
      this.hasToEmitPersistencyValue = false;
      this.selectedNetworkElementsChange.emit(this.selectedNEs);
      this.applyPersistedValue$.next();
    }
  }

  onselectedPersistedItemsChanged() {
    this.emitPersistedValue();
  }

  onCheckAutoload(): void {
    this.gridSettingsReady$.pipe(untilDestroyed(this)).subscribe((settings) => {
      if (!settings.disableAutoLoad) {
        this.neTypeFilterIsValid = this.validateFilters();
        if (this.neTypeFilterIsValid) {
          this.loadGrid();
        }
      }
    });
  }

  onGridSettingsChange(gridSettings: GridSetting): void {
    this.gridSettings = gridSettings;
  }
}
