import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FiltrableGisLayer } from '@common-modules/dependencies/map/filtrable-gis-layer';
import { IGisLayer } from '@common-modules/dependencies/map/gis-layer';
import { GisLayerSelectorItem } from '@common-modules/dependencies/map/gis-layer-selector-item';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, forkJoin } from 'rxjs';
import { MapLayerSourcesService } from '../map-layer-sources.service';
import { MapLayerSetting } from './models/map-layer-setting';

const COMPONENT_SELECTOR = 'wlm-map-zoom-configuration-popup';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-zoom-configuration-popup.component.html',
  styleUrls: ['./map-zoom-configuration-popup.component.scss'],
})
export class MapZoomConfigurationPopupComponent implements OnInit {
  readonly T_SCOPE = `${AppModules.Map}.${COMPONENT_SELECTOR}`;

  private _saveConfig$ = new Subject<boolean>();
  readonly saveConfig$ = this._saveConfig$.asObservable();

  private _reloadOnClose = false;

  hasValidChanges = false;

  gridName = 'GisLayerSelection';
  gridSettings: GridSetting;
  isLoading = false;

  noSelectionTitleKey = `${this.T_SCOPE}.no-selection-title`;
  noSelectionSubtitleKey = `${this.T_SCOPE}.no-selection-subtitle`;

  gisLayerSelectorItems: GisLayerSelectorItem[];
  mapLayerSettings: MapLayerSetting[];
  selectedLayerSetting: MapLayerSetting;

  constructor(
    private _dialogRef: MatDialogRef<MapZoomConfigurationPopupComponent>,
    private _globalsService: GlobalsService,
    private _localizationHelperService: LocalizationHelperService,
    private _dialogService: DialogService,
    private _mapLayerSourcesService: MapLayerSourcesService,
    private _gridSettingService: GridSettingsService
  ) {}

  ngOnInit(): void {
    this.getConfigurationData();
  }

  save(isSuperUser: boolean) {
    this._saveConfig$.next(isSuperUser);
  }

  onIsLoadingChanges(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  onSaveFinished(isSuccess: boolean) {
    this.reload();

    // If any setting have been saved succesfully, we reload the map on close
    this._reloadOnClose ||= isSuccess;
  }

  onHasValidChanges(hasValidChanges: boolean) {
    this.hasValidChanges = hasValidChanges;
  }

  close(): void {
    this._dialogRef.close(this._reloadOnClose);
  }

  getSelectedRow(item: GisLayerSelectorItem) {
    this.selectedLayerSetting = this.mapLayerSettings?.find((l) => l.layerId === item?.layerId);
  }

  private reload() {
    this.hasValidChanges = false;
    this.getConfigurationData();
  }

  private getConfigurationData() {
    this.isLoading = true;

    const gisLayers$ = this._globalsService.getFiltrableGisLayers().pipe(untilDestroyed(this));

    const gridSettings$ = this._gridSettingService
      .getGridSettingsByName(this.gridName)
      .pipe(untilDestroyed(this));

    const categoryLabels$ = this._localizationHelperService.get(`${this.T_SCOPE}.types`);
    const neTypeLabels$ = this._localizationHelperService.get('common.network-element-types');

    const layerSettings$ = this._mapLayerSourcesService
      .getMapLayerSettings()
      .pipe(untilDestroyed(this));

    return forkJoin([
      gisLayers$,
      gridSettings$,
      categoryLabels$,
      neTypeLabels$,
      layerSettings$,
    ]).subscribe({
      next: ([gisLayers, gridSettings, categoryLabels, neTypeLabels, layerSettings]) => {
        this.gisLayerSelectorItems = this.setGisLayerSelectorItems(
          gisLayers,
          categoryLabels,
          neTypeLabels
        );

        this.gridSettings = gridSettings;
        this.mapLayerSettings = layerSettings;

        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.displayErrorMessage(`${this.T_SCOPE}.messages.load-error`);
      },
    });
  }

  private setGisLayerSelectorItems(
    gisLayers: FiltrableGisLayer,
    categoryLabels: any,
    neTypeLabels: any
  ): GisLayerSelectorItem[] {
    const items: GisLayerSelectorItem[] = [];

    const { leakYears, ...gisLayerData } = gisLayers;

    Object.entries(gisLayerData).forEach(([key, layers]) => {
      const category = categoryLabels[key];

      const layersExtended = FiltrableGisLayer.flatGisLayer(layers);
      layersExtended.forEach((l: IGisLayer) => {
        const neType = neTypeLabels[l.networkElementTypeId];
        const label = neType ? `${category} - ${neType}` : category;

        items.push(
          new GisLayerSelectorItem({
            layerId: l.gisLayerId,
            description: l.description,
            categoryLabel: label,
          })
        );
      });
    });

    return items;
  }

  private displayErrorMessage(messageKey: string) {
    let dialogSettings = new WlmDialogSettings({ translateKey: messageKey, icon: 'error' });

    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }
}
