import { Injectable, Injector } from '@angular/core';
import { IHierarchyElementDto } from '@common-modules/dependencies/he/hierarchy-element.dto';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';

@Injectable({
  providedIn: 'root',
})
export class HierarchyElementsSelectionService extends GridODataService<IHierarchyElementDto> {
  constructor(injector: Injector) {
    super(injector, '/api/he/hierarchy-summary');
  }
  protected mapResponse(
    response: PagedResultDto<IHierarchyElementDto>
  ): PagedResultDto<IHierarchyElementDto> {
    return response;
  }
}
