import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicSettingsSave } from '@common-modules/dynamic-layout/models/dynamic-settings-save';
import { RangeColor } from '@common-modules/shared-component/range-color/range-color';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DynamicSettingsService } from '@common-modules/shared/config/dynamic-settings.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { UtilsHelperService } from '@common-modules/shared/helpers/utils-helper.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { asEnumerable } from 'linq-es2015';
import { combineLatest, Observable } from 'rxjs';
import { WlmElementType } from '../../shared/model/wlm/wlm-element-type';
import { MapHelperService } from '../map-helper.service';
import { ThematicKPI } from '../map-thematic-configuration-popup/thematic-kpi';
import { KpiStep } from '../map-thematic/models/map-thematic-kpi-step';

const COMPONENT_SELECTOR = 'wlm-map-thematic-configuration';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-thematic-configuration.component.html',
  styleUrls: ['./map-thematic-configuration.component.scss'],
})
export class MapThematicConfigurationComponent implements OnInit {
  private _uomTs = 'common.unit-labels';

  private _kpi: ThematicKPI;

  public get kpi(): ThematicKPI {
    return this._kpi;
  }
  @Input() public set kpi(v: ThematicKPI) {
    this._kpi = v;
    if (v?.steps) {
      this.reset();
      this.mapToRangeColors(v);
    }
  }

  @Input() elementTypes: WlmElementType[];
  @Input() saveThematicConfiguration$: Observable<any>;
  @Input() discardThematicConfiguration$: Observable<any>;

  @Output() validityChanged = new EventEmitter<boolean>();
  @Output() isLoadingChanged = new EventEmitter<boolean>();
  @Output() saveFinished = new EventEmitter<boolean>();
  @Output() hasPendingChanges = new EventEmitter<boolean>();

  rangesPerLevel: { [key: string]: RangeColor[] };
  changesPerLevel: { [key: string]: RangeColor[] } = {};
  isValidPerLevel: { [key: string]: boolean } = {};
  levelsDescription: { [key: string]: string };
  rangeLabelsPerLevel: { [key: string]: Observable<string> };
  conversionsPerLevel: { [key: string]: UnitTypeConversionViewDto };
  levels: string[];

  isValid: boolean;
  isLoading = false;

  readonly T_SCOPE = `${AppModules.Map}.${COMPONENT_SELECTOR}`;
  private readonly _decimalPrecisionToSave = 10;

  constructor(
    private _uomService: UoMService,
    private _objectHelperService: ObjectHelperService,
    private _dynamicSettingsService: DynamicSettingsService,
    private _mapHelperService: MapHelperService,
    private _dialogService: DialogService,
    private _utilsHelperService: UtilsHelperService
  ) {}

  ngOnInit(): void {
    this.saveThematicConfiguration$?.pipe(untilDestroyed(this))?.subscribe((isSuperUser) => {
      this.save(isSuperUser);
    });
    this.discardThematicConfiguration$?.pipe(untilDestroyed(this))?.subscribe((element) => {
      this.discardChanges();
    });
  }

  loadConversions(
    levels: number[],
    dimensionTypeId: number,
    timeAggregationId: number,
    elementTypes: WlmElementType[]
  ): Observable<UnitTypeConversionViewDto>[] {
    const uoms$ = [];
    levels.forEach((level) => {
      const heTypeId = elementTypes?.find((x) => x.gisLayerId == level)?.hierarchyElementTypeId;
      const uom$ = this._uomService.getByParams(dimensionTypeId, timeAggregationId, heTypeId);
      uoms$.push(uom$);
    });

    return uoms$;
  }

  onRangeValueChanged(ranges: RangeColor[], key) {
    this.changesPerLevel[key] = ranges;

    let hasPendingChanges = false;
    for (let key of Object.keys(this.changesPerLevel)) {
      const changedRanges = this.changesPerLevel[key];
      const originalRanges = this.rangesPerLevel[key];
      if (!this._objectHelperService.deepEqual(changedRanges, originalRanges)) {
        hasPendingChanges = true;
        break;
      }
    }

    this.hasPendingChanges.emit(hasPendingChanges);
  }

  onValidityChanged(isValid: boolean, key) {
    this.isValidPerLevel[key] = isValid;
    const allValid = !asEnumerable(Object.values(this.isValidPerLevel)).Any((x) => !x);
    this.validityChanged.emit(allValid);
  }

  save(isSuperUser: boolean) {
    this.setIsLoading(true);

    const rangesToSave = this.getRangesToSave();
    const settingsToSave = [];
    for (const level of Object.keys(rangesToSave)) {
      const conversionFactor = this.conversionsPerLevel[level]?.conversionFactor;
      const ranges = {
        Steps: rangesToSave[level].map(
          (x) =>
            new KpiStep({
              color: x.color,
              value: +this._utilsHelperService.uomDivide(
                String(x.range),
                String(conversionFactor ?? 1),
                null,
                this._decimalPrecisionToSave
              ),
            })
        ),
      };
      const settingToSave = new DynamicSettingsSave({
        settingArea: 'MapSetting',
        settingKey: `${this.kpi.kpiType}-${this.kpi.kpiProperty}-${level}`,
        settingValue: JSON.stringify(ranges),
        componentTypeId: 6,
        createComponentIfNoExists: true,
        saveAsDefault: isSuperUser,
      });

      settingsToSave.push(settingToSave);
    }

    this._dynamicSettingsService
      .saveDynamicSettingsBulk(settingsToSave)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this.displayMessage(`${this.T_SCOPE}.messages.save-success`, 'success');
          this.setIsLoading(false);
          this.saveFinished.emit(true);
        },
        error: (error) => {
          this.displayMessage(`${this.T_SCOPE}.messages.save-error`, 'error');
          this.setIsLoading(false);
        },
      });
  }

  discardChanges() {
    this.changesPerLevel = {};
    this.rangesPerLevel = this._objectHelperService.clone(this.rangesPerLevel);
    this.hasPendingChanges.emit(false);
  }

  private reset() {
    this.changesPerLevel = {};
    this.rangesPerLevel = {};
    this.isValidPerLevel = {};
    this.hasPendingChanges.emit(false);
  }

  private mapToRangeColors(kpi: ThematicKPI) {
    const levels = kpi.steps.map((x) => x.level);

    combineLatest(
      this.loadConversions(levels, kpi.dimensionTypeId, kpi.timeAggregationId, this.elementTypes)
    ).subscribe((conversions) => {
      const rangeColorByLevel: { [key: string]: RangeColor[] } = {};
      const levelsDescription: { [key: string]: string } = {};
      const conversionsPerLevel: { [key: string]: UnitTypeConversionViewDto } = {};
      const rangeLabelsPerLevel: { [key: string]: Observable<string> } = {};

      kpi.steps.forEach((kpiLevelSteps) => {
        const elementType = this.elementTypes.find((x) => x.gisLayerId == kpiLevelSteps.level);

        const conversionLevel = conversions.find(
          (x) => x?.hierarchyElementTypeId == elementType.hierarchyElementTypeId
        );

        const rangeColors = kpiLevelSteps.steps.map(
          (x) =>
            new RangeColor({
              color: x.color,
              range: +this._utilsHelperService.uomMultiply(
                String(x.value),
                String(conversionLevel?.conversionFactor ?? 1)
              ),
            })
        );

        rangeColorByLevel[kpiLevelSteps.level] = rangeColors;
        rangeLabelsPerLevel[kpiLevelSteps.level] = this._uomService.getFormatedUnit(
          conversionLevel?.unitTypeToDescription,
          `${this._uomTs}.${kpi.labelKey}`,
          kpi.unitTypeFormat
        );
        levelsDescription[kpiLevelSteps.level] = kpiLevelSteps.description;
        conversionsPerLevel[kpiLevelSteps.level] = conversionLevel;
      });

      this.rangesPerLevel = rangeColorByLevel;
      this.rangeLabelsPerLevel = rangeLabelsPerLevel;
      this.levels = Object.keys(rangeColorByLevel);
      this.levelsDescription = levelsDescription;
      this.conversionsPerLevel = conversionsPerLevel;
    });
  }

  private getRangesToSave(): { [key: string]: RangeColor[] } {
    const rangesToSave = {};

    Object.keys(this.changesPerLevel).forEach((key) => {
      const changedRanges = this.changesPerLevel[key];
      const originalRanges = this.rangesPerLevel[key];
      if (this._objectHelperService.deepDiff(changedRanges, originalRanges)) {
        rangesToSave[key] = changedRanges;
      }
    });
    return rangesToSave;
  }

  private setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
    this.isLoadingChanged.emit(isLoading);
  }

  private displayMessage(messageKey: string, icon: 'error' | 'success') {
    let dialogSettings = new WlmDialogSettings({ translateKey: '' });
    dialogSettings.translateKey = messageKey;
    dialogSettings.icon = icon;
    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }
}
