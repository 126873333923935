import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FilterItemSummaryRow } from '@common-modules/common-filters/models/filter-item-summary-row';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { FiltersPayloadStatus } from '@common-modules/dependencies/wlm-filters/filters-payload-status.enum';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AlgorithmFilter } from '@common-modules/dependencies/wlm-filters/i-filters/algorithm-filter';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { IAlgorithmHierarchyViewDto } from '@common-modules/shared/model/algorithm/algorithm-hierarchy-view.dto';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { SelectableSettings, TreeViewComponent } from '@progress/kendo-angular-treeview';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { FilterHookAfterMenuOpen } from '../../core/hooks/filter-hook-after-menu-open';
import { AlgorithmFilterItemQuery } from './algorithm-filter-item-query';
import { AlgoritmNodeType } from './algorithm-node-types';

const COMPONENT_SELECTOR = 'wlm-algorithm-filter-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './algorithm-filter-item.component.html',
  styleUrls: ['./algorithm-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => AlgorithmFilterItemComponent),
    },
  ],
})
export class AlgorithmFilterItemComponent
  extends BaseFilterItemComponent
  implements OnInit, FilterHookAfterMenuOpen
{
  @ViewChild(TreeViewComponent) public treeControl: TreeViewComponent;

  private _treeSettings: ITreeSettings;
  private _filterText: string;

  @Input() public set treeSettings(value: ITreeSettings) {
    this._treeSettings = { ...value };
  }
  @Input() initialSelectedKeys: string[];
  @Input() public set filterText(value: string) {
    this._filterText = value?.toUpperCase();

    if (!this.treeNodes) {
      return;
    }

    if (!this.filterText) {
      this.filteredTreeNodes = [...this.treeNodes];
      if (this.searchHasBeenMade) {
        this.expandedKeys = [];
        this.searchHasBeenMade = false;
      }
    } else {
      this.searchHasBeenMade = true;
      const searchResult = this.treeNodes.filter(
        (node) =>
          (node.type === AlgoritmNodeType.Family &&
            this.algorithmFamilyTranslations
              .get(node.descendant)
              .toUpperCase()
              .includes(this.filterText)) ||
          (node.type === AlgoritmNodeType.Aggregation &&
            this.aggregationTranslations.get(node.name).toUpperCase().includes(this.filterText)) ||
          (node.type === AlgoritmNodeType.Algorithm &&
            (this.algorithmTranslations.get(node.name).toUpperCase().includes(this.filterText) ||
              node.name.toUpperCase().includes(this.filterText)))
      );

      this.filteredTreeNodes = [
        ...new Set([...this.includeParents(searchResult), ...this.includeChildren(searchResult)]),
      ];
      this.expandedKeys = this.filteredTreeNodes.map((item) => item.descendant);
    }
  }

  private _query: AlgorithmFilterItemQuery;
  get query(): AlgorithmFilterItemQuery {
    return this._query;
  }
  @Input() set query(value: AlgorithmFilterItemQuery) {
    this._query = value;
    if (this.allTreeNodes) {
      this.initNodes(this.allTreeNodes, FiltersPayloadStatus.Normal);
    }
  }

  @Output() selectedNodesChange = new EventEmitter<IAlgorithmHierarchyViewDto[]>();
  @Output() selectedFiltersChange = new EventEmitter<AlgorithmFilter>();

  // tslint:disable-next-line: adjacent-overload-signatures
  public get treeSettings(): ITreeSettings {
    return this._treeSettings;
  }

  // tslint:disable-next-line: adjacent-overload-signatures
  public get filterText(): string {
    return this._filterText;
  }

  get selection(): SelectableSettings {
    return { mode: this.treeSettings.selectionMode };
  }

  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  algorithmDefinitionKey = `${AppModules.Algorithms}.algorithm-names`;
  algorithFamilyDefinitionKey = `${AppModules.Algorithms}.algorithm-family-names`;
  aggregationsDefinitionKey = `${AppModules.Algorithms}.aggregation-names`;
  treeNodes: IAlgorithmHierarchyViewDto[];
  allTreeNodes: IAlgorithmHierarchyViewDto[];
  selectedNodes: IAlgorithmHierarchyViewDto[];
  algorithmTranslations = new Map<string, string>();
  algorithmFamilyTranslations = new Map<string, string>();
  aggregationTranslations = new Map<string, string>();
  filtered$ = new Subject<FiltersPayload>();
  expandedKeys: any[] = [];
  filteredTreeNodes: IAlgorithmHierarchyViewDto[];

  initialStatus: IAlgorithmHierarchyViewDto[];
  isLoading = false;
  treeIsEmpty = false;
  selectedKeys: any[] = [];
  lastSelectedNode: IAlgorithmHierarchyViewDto;
  lastEmittedKeys: string[] = [];
  algorithmIdFieldName = 'algorithmShortName';
  undefinedTranslationText = 'Undefined translation';
  // Used for displaying the summary.
  summaryItems: { [key: string]: FilterItemSummaryRow[] } = {};
  summaryItemsKeys: string[] = [];
  searchHasBeenMade = false;

  constructor(
    private globalService: GlobalsService,
    private localization: LocalizationHelperService,
    private objectHelperService: ObjectHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeTree();
  }

  fhAfterMenuOpen(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      this.expandedKeys = this.getExpandedNodes();
      this.searchHasBeenMade = false;
    });
  }

  getExpandedNodes(): string[] {
    if (!this.selectedNodes?.length) {
      return [];
    }

    let parents = [];
    this.selectedNodes?.forEach((node) => {
      parents = parents.concat(this.getAncestors(node, this.treeNodes).map((x) => x.descendant));
    });
    const uniqueItems = new Set(parents);
    return [...uniqueItems];
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  setClear(): void {
    this.clearSelection();
    this.expandedKeys = [];
    this.setSelectedKeys(this.treeSettings?.defaultSelectedNodes);
    this.buildSummaryItems();
    this.notifyChanges();
  }

  setDefaultSelectedNodes() {
    const defaultNodes = this.treeSettings?.defaultSelectedNodes ?? [];
    if (defaultNodes.length) {
      this.initialStatus = [...this.treeNodes.filter((x) => defaultNodes.includes(x.name))];
    }
  }

  setSelectAll(): void {}

  setInitialState(): void {
    this.initialStatus = [...(this.selectedNodes ?? [])];
  }

  setRestoreState(): void {
    this.lastSelectedNode = null;
    this.selectedNodes = [...(this.initialStatus ?? [])];
    this.selectedKeys = [...this.selectedNodes.map((x) => x.name)];
    this.buildSummaryItems();
    this.notifyChanges();
  }

  getFiltered$(): Observable<FiltersPayload> {
    return this.filtered$.asObservable();
  }

  getStateFormatted(): Observable<string> {
    if (!this.selectedKeys || this.selectedKeys.length === 0 || this.settings?.hideInputSummary) {
      return of('');
    }
    const labels = this.selectedNodes.map((x) => x.name)?.sort((a, b) => (a > b ? 1 : -1));
    const joined = labels.join(', ');
    return this.localization.get(`${this.T_SCOPE}.input-summary`).pipe(
      map((label) => {
        return this.settings?.hideInputSummaryLabel ? joined : `${label}${joined}`;
      })
    );
  }

  isValid(): boolean {
    return this.selectedKeys && this.selectedKeys.length !== 0;
  }
  getFieldNames(): string[] {
    return [this.algorithmIdFieldName];
  }

  initializeTree() {
    forkJoin([
      this.localization.get(this.algorithmDefinitionKey),
      this.localization.get(this.algorithFamilyDefinitionKey),
      this.localization.get(this.aggregationsDefinitionKey),
      this.globalService.getAlgorithmHierarchies(),
    ]).subscribe(([tsAlgorithm, tsAlgorithmFamily, tsAggregation, algorithmHierarchies]) => {
      algorithmHierarchies.forEach((item) => {
        switch (item.type) {
          case AlgoritmNodeType.Family:
            {
              const value =
                tsAlgorithmFamily[item.descendant] ??
                `${this.undefinedTranslationText} [${item.descendant}]`;
              this.algorithmFamilyTranslations.set(item.descendant, value);
            }
            break;

          case AlgoritmNodeType.Aggregation:
            {
              const value =
                tsAggregation[item.name] ?? `${this.undefinedTranslationText} [${item.name}]`;
              this.aggregationTranslations.set(item.name, value);
            }
            break;

          case AlgoritmNodeType.Algorithm:
            {
              const value =
                tsAlgorithm[item.name] ?? `${this.undefinedTranslationText} [${item.name}]`;
              this.algorithmTranslations.set(item.name, value);
            }
            break;
        }
      });
      this.allTreeNodes = algorithmHierarchies;
      this.initNodes(this.allTreeNodes, FiltersPayloadStatus.Initial);
    });
  }

  /**
   * Find all children of a current node and an array.
   */
  getChildren(
    current: IAlgorithmHierarchyViewDto,
    nodes: IAlgorithmHierarchyViewDto[]
  ): IAlgorithmHierarchyViewDto[] {
    const children = nodes.filter((node) => node.ancestor === current.descendant);
    if (children.length === 0) {
      return [];
    }
    let result = children;
    children.forEach((child) => {
      result = result.concat(this.getChildren(child, nodes));
    });
    return result;
  }

  public handleClick(event: any): void {
    event.originalEvent.preventDefault();
  }

  public handleSelection(event: any): void {
    const item = event.dataItem as IAlgorithmHierarchyViewDto;

    this.lastSelectedNode = item;

    this.selectedKeys =
      this.selectedNodes === undefined ? [] : this.selectedNodes.map((n) => n.name);

    this.setSelectedNodes([item]);
  }

  public setSelectedNodes(items: IAlgorithmHierarchyViewDto[]) {
    if (items.length > 0 && items[0].type !== AlgoritmNodeType.Algorithm) {
      return;
    }

    let tmpSelectedNodes = [...(this.selectedNodes ?? [])];
    let tmpSelectedKeys = [...(this.selectedKeys ?? [])];
    items.forEach((item) => {
      if (tmpSelectedKeys.includes(item.name)) {
        tmpSelectedKeys = this.selectedKeys.filter((x) => x !== item.name);
        tmpSelectedNodes = tmpSelectedNodes.filter((x) => x.name !== item.name);
      } else {
        tmpSelectedKeys.push(item.name);
        tmpSelectedNodes.push(item);
      }
    });

    if (tmpSelectedKeys.length === 0 && this.treeSettings.defaultSelectedNodes) {
      tmpSelectedKeys = this.getDefaultSelectedKeys();
      tmpSelectedNodes = this.getDefaultSelectedNodes();
    }

    this.selectedKeys = tmpSelectedKeys;
    this.selectedNodes = tmpSelectedNodes;
    this.buildSummaryItems();

    this.notifyChanges();
  }

  getDefaultSelectedKeys() {
    return this.getDefaultSelectedNodes()?.map((x) => x.name) ?? [];
  }

  getDefaultSelectedNodes() {
    return (
      this.treeNodes.filter((x) => this.treeSettings?.defaultSelectedNodes.includes(x.name)) ?? []
    );
  }

  private notifyChanges(status = FiltersPayloadStatus.Normal) {
    // When the selectionChange event is fired, we set the selectedKeys again,
    // which fires a second selectionChange event.
    // Only set the nodes when the keys are different from the last filter event.
    let selected = [...this.selectedKeys];
    if (
      !this.selectedKeys.length ||
      !this.objectHelperService.deepEqual(this.selectedKeys, this.lastEmittedKeys)
    ) {
      // Very important to sort, as ['a', 'b'] is not equal to ['b', 'a'].
      selected = selected?.sort();

      this.lastEmittedKeys = [...selected];
      // This event will still emit the value.
      this.selectedNodesChange.emit(this.selectedNodes);
      const filter = new BasicFilter(this.algorithmIdFieldName, selected);
      const payload = this.buildPayload([filter], status);
      this.filtered$.next(payload);

      if (this.algorithmIdFieldName) {
        this.emitAlgorithmFilter();
      }
    }
  }

  private emitAlgorithmFilter() {
    const algorithmFilter = new AlgorithmFilter(
      this.selectedNodes.map((n) => n.name),
      this.algorithmIdFieldName
    );
    this.selectedFiltersChange.emit(algorithmFilter);
  }

  public isItemSelected = (dataItem: any) =>
    this.selectedKeys.indexOf((dataItem as IAlgorithmHierarchyViewDto).name) > -1;

  public clearSelection() {
    this.selectedKeys = [];
    this.selectedNodes = [];
  }

  /**
   * Converts the selected nodes in a summary format. Then groups them by title.
   */
  private buildSummaryItems(): void {
    this.summaryItems = null;
    this.summaryItemsKeys = [];
    if (this.selectedNodes && this.selectedNodes.length !== 0) {
      let items: { title: string; label: string }[] = [];
      // We can limit the amount of summary items to show by configuration.
      const nodesToShow = this.settings?.maxTemplateSummaryItems ?? this.selectedNodes.length;
      const summaryNodes = this.selectedNodes.slice(0, nodesToShow);

      items = summaryNodes.map((node) => {
        return {
          label: this.algorithmTranslations.get(node.name),
          title: this.getAncestors(node, this.treeNodes)
            .map((item) => this.getParentTranslation(item))
            .join(' - '),
        };
      });

      const groupedItems: { [key: string]: FilterItemSummaryRow[] } = {};
      items.forEach((item) => {
        const summaryItem = new FilterItemSummaryRow(item.label);
        if (typeof groupedItems[item.title] !== 'undefined') {
          const values = [summaryItem, ...groupedItems[item.title]]?.sort((a, b) =>
            a.label > b.label ? 1 : -1
          );
          groupedItems[item.title] = values;
        } else {
          groupedItems[item.title] = [summaryItem];
        }
      });
      this.summaryItems = groupedItems;
      this.summaryItemsKeys = Array.from(Object.keys(this.summaryItems))?.sort((a, b) =>
        a.length > b.length ? 1 : -1
      );
    }
  }

  getParentTranslation(item: IAlgorithmHierarchyViewDto): string {
    return item.type === AlgoritmNodeType.Family
      ? this.algorithmFamilyTranslations.get(item.descendant)
      : this.aggregationTranslations.get(item.name);
  }

  getAdapter(): FilterAdapterEnum {
    return FilterAdapterEnum.Algorithm;
  }

  setSelectedKeys(keys: string[]) {
    const nodes = !keys ? [] : this.treeNodes.filter((x) => keys.includes(x.name));
    this.selectedKeys = nodes.map((x) => x.name);
    this.selectedNodes = nodes;
  }

  /**
   * Include all the ancestors of the nodes. Only include each node once.
   */
  private includeParents(nodes: IAlgorithmHierarchyViewDto[]): IAlgorithmHierarchyViewDto[] {
    let results = [...nodes];
    nodes.forEach((node) => {
      results = results.concat(this.getAncestors(node, this.treeNodes));
    });
    const resultsUnique: IAlgorithmHierarchyViewDto[] = [];
    const included: Map<string, boolean> = new Map();
    results.forEach((item) => {
      if (!included.has(item.descendant)) {
        resultsUnique.push(item);
        included.set(item.descendant, true);
      }
    });
    return resultsUnique;
  }

  /**
   * Get all the ancestors nodes of a node.
   */
  private getAncestors(
    node: IAlgorithmHierarchyViewDto,
    nodes: IAlgorithmHierarchyViewDto[]
  ): IAlgorithmHierarchyViewDto[] {
    const ancestors = [];
    const parentNode = nodes.find((item) => item.descendant === node.ancestor);
    if (parentNode) {
      this.getAncestorsRecursive(parentNode, ancestors, nodes);
    }
    return ancestors;
  }

  /**
   * Get all the ancestors nodes of a node. Helper method.
   */
  private getAncestorsRecursive(
    node: IAlgorithmHierarchyViewDto,
    ancestors: IAlgorithmHierarchyViewDto[],
    nodes: IAlgorithmHierarchyViewDto[]
  ): void {
    ancestors.unshift(node);
    const parentNode = nodes.find((item) => item.descendant === node.ancestor);
    if (parentNode) {
      this.getAncestorsRecursive(parentNode, ancestors, nodes);
    }
  }

  /**
   * Include all the children of the nodes. Only include each node once.
   */
  private includeChildren(nodes: IAlgorithmHierarchyViewDto[]): IAlgorithmHierarchyViewDto[] {
    let results = [...nodes];
    nodes.forEach((node) => {
      results = results.concat(this.getChildren(node, this.treeNodes));
    });
    const resultsUnique: IAlgorithmHierarchyViewDto[] = [];
    const included: Map<string, boolean> = new Map();
    results.forEach((item) => {
      if (!included.has(item.descendant)) {
        resultsUnique.push(item);
        included.set(item.descendant, true);
      }
    });
    return resultsUnique;
  }

  /**
   * Apply the query to an array of nodes, and then eliminate unused Aggregation / Family nodes.
   */
  private filterByQuery(nodes: IAlgorithmHierarchyViewDto[]): IAlgorithmHierarchyViewDto[] {
    if (!this.query || !Object.keys(this.query).length) {
      return nodes;
    }
    let filtered = [...nodes];

    // If it is an empty array, do not filter by anything.
    filtered = this.filterByProperty<number>(
      filtered,
      this.query.targetIds,
      (item) => item.entityTypeId,
      (item) => item.type === AlgoritmNodeType.Family // Always include family nodes.
    );
    filtered = this.filterByProperty<number>(
      filtered,
      this.query.timeAggregationIds,
      (item) => item.timeAggregationId,
      (item) => item.type === AlgoritmNodeType.Family // Always include family nodes.
    );

    // THis must be done twice, because if we do it once, family nodes can be left with no children, and they should be removed too.
    filtered = this.removeNodesWithNoChildren(filtered);
    filtered = this.removeNodesWithNoChildren(filtered);

    return filtered;
  }

  private filterByProperty<T>(
    nodes: IAlgorithmHierarchyViewDto[],
    filterArray: T[],
    getIdFn: (item: IAlgorithmHierarchyViewDto) => T,
    mustIncludeFn: (item: IAlgorithmHierarchyViewDto) => boolean
  ): IAlgorithmHierarchyViewDto[] {
    let results = [...nodes];
    if (filterArray && filterArray.length) {
      results = nodes.filter(
        (item) =>
          mustIncludeFn(item) ||
          (typeof getIdFn(item) !== 'undefined' && filterArray.find((id) => id === getIdFn(item)))
      );
    }
    return results;
  }

  /**
   * If the filtering leaves Family or Aggregation nodes without children, then remove them.
   */
  private removeNodesWithNoChildren(
    nodes: IAlgorithmHierarchyViewDto[]
  ): IAlgorithmHierarchyViewDto[] {
    const excludeIds = [];
    nodes
      .filter((node) => node.type !== AlgoritmNodeType.Algorithm)
      .forEach((node) => {
        const children = this.getChildren(node, nodes);
        if (!children.length) {
          excludeIds.push(node.descendant);
        }
      });
    const result = nodes.filter(
      (node) => !excludeIds.find((excludeId) => node.descendant === excludeId)
    );
    return result;
  }

  private initNodes(
    initialNodes: IAlgorithmHierarchyViewDto[],
    status: FiltersPayloadStatus
  ): void {
    this.treeNodes = this.filterByQuery(initialNodes);
    this.filteredTreeNodes = [...this.treeNodes];

    const defaultNodes = this.treeSettings?.defaultSelectedNodes;

    this.setSelectedKeys(this.initialSelectedKeys ?? defaultNodes ?? []);
    this.buildSummaryItems();
    this.notifyChanges(status);
  }
}
