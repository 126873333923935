import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarModeEnum } from '@common-modules/dependencies/alarms/calendar-mode.enum';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { BaseFilterSettings } from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DayTypesEnum } from '@common-modules/shared/model/shared/day-types.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { SACalendarData } from '../models/sa-calendar-data';
import { SACalendarSelectorSettings } from '../models/sa-calendar-selector-settings';

const COMPONENT_SELECTOR = 'wlm-sa-calendar-selection';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './sa-calendar-selection.component.html',
  styleUrls: ['./sa-calendar-selection.component.scss'],
})
export class SACalendarSelectionComponent implements OnInit {
  @Input() public set settings(model: SACalendarSelectorSettings) {
    if (!model) {
      return;
    }

    this.settingModel = model;
    this.selectedModel = model;

    // Update the filters settings so they match the new settings.
    this.initSeasons();
    this.initDayTypes();
    this.initCalendars();
  }

  private _resetHandler$: Observable<boolean>;
  public get resetHandler$(): Observable<boolean> {
    return this._resetHandler$;
  }
  @Input() public set resetHandler$(value: Observable<boolean>) {
    this._resetHandler$ = value;
    this.subscribeToResetWizard(this.resetHandler$);
  }
  @Input() resetCompleteHandler$: ReplaySubject<void>;

  @Output() selectedCalendarChange = new EventEmitter<SACalendarData>();

  T_SCOPE = `${AppModules.Alarms}.${COMPONENT_SELECTOR}`;

  baseFilterSettings = new BaseFilterSettings({
    formMode: true, // Disable all action buttons.
    disableSearch: true,
  });
  itemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    storageLocation: 'none',
    required: false,
    hideSelectAllCheckbox: false,
  });

  // Seasons filter config.
  seasonsBaseSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.seasons-title`,
  };
  seasonsItemSettings = this.itemSettings;
  seasonsSelectedIds: string[] = [];

  // Day types filter config.
  dayBaseSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.day-title`,
  };
  dayItemSettings = this.itemSettings;
  dayTypesSelectedIds: number[] = [];

  // Calendar filter config.
  calendarBaseSettings = {
    ...this.baseFilterSettings,
    expandedMinWidth: '275px',
    expandedMaxWidth: '375px',
    inputLabelKey: `${this.T_SCOPE}.calendar-title`,
    disableSelectAll: false,
  };

  calendarModeField = 'calendarMode';
  calendarItemSettings = this.itemSettings;

  seasonsClearAll$ = new Subject<void>();
  seasonsApply$ = new Subject<void>();
  daysClearAll$ = new Subject<void>();
  daysApply$ = new Subject<void>();
  calendarsClearAll$ = new Subject<void>();
  calendarsApply$ = new Subject<void>();

  settingModel: SACalendarSelectorSettings;
  selectedModel: SACalendarData;

  seasonsName = 'seasons';
  calendarName = 'calendars';
  // This name  will be mapped to 'calendarIsWeek' and 'calendarIsWeeked'.
  dayTypeName = 'dayTypes';

  isValid = false;
  datesValid = false;
  seasonsValid = false;
  dayTypesValid = false;
  calendarValid = false;
  selectedCalendarsWithParams: { [key: string]: { [key: string]: any } } = {};

  constructor() {}

  ngOnInit(): void {}

  private subscribeToResetWizard(resetHandler$: Observable<boolean>) {
    if (resetHandler$) {
      resetHandler$.pipe(untilDestroyed(this)).subscribe((hasToReset) => {
        if (hasToReset) {
          this.clearAll();
          this.resetCompleteHandler$.next();
        }
      });
    }
  }

  initSeasons(): void {
    if (this.settingModel.seasons) {
      this.seasonsSelectedIds = this.settingModel.seasons;
    }
  }

  initDayTypes(): void {
    this.dayTypesSelectedIds = [];
    if (this.settingModel.calendarIsWeek) {
      this.dayTypesSelectedIds.push(DayTypesEnum.WorkingDay);
    }
    if (this.settingModel.calendarIsWeekend) {
      this.dayTypesSelectedIds.push(DayTypesEnum.Weekend);
    }
  }

  initCalendars(): void {
    if (this.settingModel.calendars) {
      this.selectedCalendarsWithParams = Object.entries(this.settingModel.calendars).reduce(
        (accum, [key, value]) => {
          accum[key] = {
            [this.calendarModeField]: value
              ? CalendarModeEnum.Inclusive
              : CalendarModeEnum.Exclusive,
          };
          return accum;
        },
        {}
      );
    }
  }

  onFilterSeasons(filters: FiltersPayload): void {
    if (!this.selectedModel) {
      return;
    }

    const seasons = filters.data.get(this.seasonsName)?.value?.map((v) => v.value);
    this.selectedModel.seasons = seasons;
    this.selectedCalendarChange.emit(this.selectedModel);
  }

  onFilterDayTypes(filters: FiltersPayload): void {
    if (!this.selectedModel) {
      return;
    }

    const types = filters.data.get(this.dayTypeName)?.value?.map((v) => v.value);

    this.selectedModel.calendarIsWeekend = types
      ? Boolean(types.find((type) => type === DayTypesEnum.Weekend))
      : false;
    this.selectedModel.calendarIsWeek = types
      ? Boolean(types.find((type) => type === DayTypesEnum.WorkingDay))
      : false;

    this.selectedCalendarChange.emit(this.selectedModel);
  }

  onFilterCalendar(filters: FiltersPayload): void {
    if (!this.selectedModel) {
      return;
    }

    const hash: { [key: string]: boolean } = {};
    const selected = filters.data.get(this.calendarName)?.value ?? [];
    selected.forEach((calendar) => {
      const calendarMode = calendar.params ? calendar.params[this.calendarModeField] : null;
      hash[calendar.value] = calendarMode === CalendarModeEnum.Inclusive;
    });

    this.selectedModel[this.calendarName] = hash;

    this.selectedCalendarChange.emit(this.selectedModel);
  }

  onReset(): void {
    this.clearAll();
  }

  clearAll(): void {
    this.daysClearAll$.next();
    this.seasonsClearAll$.next();
    this.calendarsClearAll$.next();
  }

  restore() {
    this.clearAll();
  }
}
