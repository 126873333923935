import { ExcludedPeriodDto } from '@common-modules/dependencies/alarms/excluded-period.dto';
import { EnvelopesConfiguration } from './envelopes-configuration';

export class ProfileConfigurationDto extends EnvelopesConfiguration {
  profileId: string | null;
  algorithmId: string;
  hierarchyElementId: string;
  hierarchyElementTypeId: string | null;
  networkElementId: string;
  signalId: number | null;
  dimensionTypeId: number | null;
  profileName: string;
  calendarIsWeek: boolean;
  calendarIsWeekend: boolean;
  periodIsFixed: boolean;
  periodDays: number | null;
  periodStartDate: Date | null;
  periodEndDate: Date | null;
  timeAggregationId: number;

  name: string;
  description: string;

  parkStartDate: Date | null;
  parkEndDate: Date | null;
  parkReasonTypeId: number | null;
  parkDescription: string;
  calendars: { [key: string]: boolean };
  seasons: string[];
  excludedPeriods: ExcludedPeriodDto[];

  constructor(init?: Partial<ProfileConfigurationDto>) {
    super(init);
    Object.assign(this, init);
  }
}
