import { Injectable, Injector } from '@angular/core';
import { WorkspaceDto } from '@common-modules/shared/model/data-viz/workspace.dto';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService extends GridODataService<WorkspaceDto> {
  workspaceUrl = '/api/dataviz/workspaces';

  constructor(injector: Injector) {
    super(injector, `/api/dataviz/workspaces/available/user`);
  }

  protected mapResponse(response: PagedResultDto<WorkspaceDto>): PagedResultDto<WorkspaceDto> {
    return response;
  }

  getTemplatesByUsers(): Observable<WorkspaceDto[]> {
    return this.httpCacheClient.get<WorkspaceDto[]>(
      `${this.baseUrl}${this.workspaceUrl}/available/user`
    );
  }

  getTemplateByName(name: string): Observable<WorkspaceDto> {
    return this.httpCacheClient.get<WorkspaceDto>(`${this.baseUrl}${this.workspaceUrl}/${name}`);
  }

  createWorkspace(workspace: WorkspaceDto, saveAsSystem: boolean) {
    let urlPath = `${this.baseUrl}${this.workspaceUrl}`;

    if (saveAsSystem) {
      urlPath = `${urlPath}?isSystem=${saveAsSystem}`;
    }

    return this.httpCacheClient.post<WorkspaceDto>(urlPath, workspace);
  }

  updateWorkspace(workspace: WorkspaceDto) {
    return this.httpCacheClient.post<WorkspaceDto>(
      `${this.baseUrl}${this.workspaceUrl}/${workspace.workspaceId}`,
      workspace
    );
  }

  delete(workspaceId: number) {
    let urlPath = `${this.baseUrl}${this.workspaceUrl}`;

    return this.httpCacheClient.delete(urlPath, null, null, null, { workspaceId });
  }
}
