import { BaseHierarchySummaryDto } from '@common-modules/dependencies/he/hierarchy-summary.dto';

export interface ISignalTelemetryNullableViewDto extends BaseHierarchySummaryDto {
  signalId: number;
  signalDescription: string;
  unitTypeId: string;
  sourceSystemDescription: string;
  sourceSystemId?: number;
  pointId: string;
  pointDescription: string;
  isPointOfInterest?: boolean;
  previousIsPointOfInterest?: boolean;
  dimensionTypeId?: number;
  dimensionType?: string;
  isBoundary?: boolean;
  isAlarmRelated?: boolean;
  lastMeasureTimestamp?: Date;
  isConfigured?: boolean;
}

export class SignalTelemetryNullableViewDto implements ISignalTelemetryNullableViewDto {
  signalId: number;
  signalDescription: string;
  unitTypeId: string;
  sourceSystemDescription: string;
  sourceSystemId?: number;
  pointId: string;
  pointDescription: string;
  isPointOfInterest?: boolean;
  previousIsPointOfInterest?: boolean;
  dimensionTypeId?: number;
  dimensionType?: string;
  isBoundary?: boolean;
  isAlarmRelated?: boolean;
  lastMeasureTimestamp?: Date;
  isConfigured?: boolean;
  hierarchyFamilyId: string;
  hierarchyFamilyName: string;
  hierarchyElementId: string;
  hierarchyElementTypeId: string;
  hierarchyElementName: string;
  level0Id: string;
  level0TypeId: string;
  level0Name: string;
  level1Id: string;
  level1TypeId: string;
  level1Name: string;
  level2Id: string;
  level2TypeId: string;
  level2Name: string;
  level3Id: string;
  level3TypeId: string;
  level3Name: string;
  level4Id: string;
  level4TypeId: string;
  level4Name: string;
  level5Id: string;
  level5TypeId: string;
  level5Name: string;
  level6Id: string;
  level6TypeId: string;
  level6Name: string;
  level7Id: string;
  level7TypeId: string;
  level7Name: string;
  level8Id: string;
  level8TypeId: string;
  level8Name: string;
  level9Id: string;
  level9TypeId: string;
  level9Name: string;
  level10Id: string;
  level10TypeId: string;
  level10Name: string;
  level11Id: string;
  level11TypeId: string;
  level11Name: string;

  constructor(init: Partial<SignalTelemetryNullableViewDto>) {
    Object.assign(this, init);
  }
}
