import { EnvelopePersistencyFactor } from '@common-modules/dependencies/alarms/envelope-persistency-factor.enum';

export class EnvPersistencyConfiguration {
  persistencyValue: number;
  persistencyFactor: EnvelopePersistencyFactor;

  constructor(init?: Partial<EnvPersistencyConfiguration>) {
    Object.assign(this, init);
  }
}
