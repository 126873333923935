<wlm-spinner-wrapper [showSpinner]="isLoading" [enableRelative]="true">
  <wlm-popup-wrapper
    [showCloseButton]="true"
    contentContainerClass="custom-popup-hide-overflow"
    (popupClose)="cancel()"
  >
    <ng-container popup-content>
      <wlm-dynamic-layout [settings]="settings"></wlm-dynamic-layout>
    </ng-container>
    <ng-container popup-actions>
      <button
        mat-raised-button
        class="wtr-action-button-primary"
        onclick="this.blur()"
        (click)="loadSelectedWorkspace()"
        [disabled]="!selectedWorkspace || hasError"
      >
        {{ (navigationMode ? 'common.navigate' : 'common.load') | translate }}
      </button>
      <div class="remove-btn-container" *hasPerm="['WLMDataVisualizationCrud', 'd']">
        <button
          *ngIf="!navigationMode && isVisibleDeleteButton()"
          mat-raised-button
          class="wtr-action-button-error"
          onclick="this.blur()"
          (click)="deleteSelectedWorkspace()"
          [disabled]="!selectedWorkspace"
        >
          {{ 'common.remove' | translate }}
        </button>
      </div>
    </ng-container>
  </wlm-popup-wrapper>
</wlm-spinner-wrapper>
