import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';

const COMPONENT_SELECTOR = 'wlm-profile-dialogs-hierarchy';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './profile-dialogs-hierarchy.component.html',
  styleUrls: ['./profile-dialogs-hierarchy.component.scss'],
  host: {
    class: 'water-grid',
  },
})
export class ProfileDialogsHierarchyComponent extends BaseWidgetComponent implements OnInit {
  userCode: string;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    const userDto = parameters.parameters.get(TabDetailParameterName.user);
    this.userCode = userDto?.userCode;
  }

  init(): void {}

  get componentName() {
    return 'ProfileDialogsHierarchyComponent';
  }
}
