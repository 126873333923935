import { Component, Input, inject } from '@angular/core';
import { ColumnEnumsService } from '@common-modules/shared/core/grid/column-enums.service';
import { IntegrableForm, IntegrableFormParams } from '@common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { map } from 'rxjs';
import { ICustomerClassTypeDto } from '../../../shared/model/customer/customer-class-type.dto';

@Component({
  selector: 'wlm-customer-class-type-form',
  templateUrl: './customer-class-type-form.component.html',
  styleUrls: ['./customer-class-type-form.component.scss'],
})
export class CustomerClassTypeFormComponent extends IntegrableForm {
  @Input() initialModel: ICustomerClassTypeDto;
  model: ICustomerClassTypeDto;
  private readonly _columnEnumsService = inject(ColumnEnumsService);

  onModelChange(model: ICustomerClassTypeDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'customerClassCategoryId',
          type: 'select',
          className: 'col-6',
          props: {
            label: 'Customer Class Category',
            valueProp: 'customerClassCategoryId',
            labelProp: 'customerClassCategoryName',
            required: true,
            options: this._columnEnumsService.getCustomerClassCategoriesMapping().pipe(
              map((mapa: Map<any, string>) => {
                return Array.from(mapa, ([key, value]) => ({
                  customerClassCategoryId: key,
                  customerClassCategoryName: value,
                }));
              })
            ),
          },
        },
        {
          key: 'customerClassSuperTypeId',
          type: 'select',
          className: 'col-6',
          props: {
            label: 'Customer Class Super Type',
            valueProp: 'customerClassSuperTypeId',
            labelProp: 'customerClassSuperTypeName',
            required: true,
            options: this._columnEnumsService.getCustomerClassSuperTypesMapping().pipe(
              map((mapa: Map<any, string>) => {
                return Array.from(mapa, ([key, value]) => ({
                  customerClassSuperTypeId: key,
                  customerClassSuperTypeName: value,
                }));
              })
            ),
          },
        },
        {
          key: 'customerClassTypeName',
          type: 'input',
          className: 'col-6',
          props: {
            label: 'Customer Class Type Name',
            required: true,
          },
        },
        {
          key: 'isMeasured',
          type: 'checkbox',
          className: 'col-6 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Measured',
            required: true,
          },
        },
      ],
    },
  ];
}
