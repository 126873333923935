import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicLayoutModule } from '@common-modules/dynamic-layout/dynamic-layout.module';
import { FiltersStoreService } from '@common-modules/dynamic-layout/state/filters/filters-store.service';
import { GenericStoreService } from '@common-modules/dynamic-layout/state/generic/generic-store.service';
import { ReduxStateModule } from '@common-modules/redux/redux-state.module';
import { CommonSharedComponentModule } from '@common-modules/shared-component/common-shared-component.module';
import { FileUploadModule } from '@common-modules/shared/file-upload/file-upload.module';
import { MaterialSharedModule } from '@common-modules/shared/material-shared.module';
import { WidgetRegistryService } from '@common-modules/widget-registry/widget-registry';
import { WLMGridModule } from '@common-modules/wlm-grid/wlm-grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { SchematicSearchComponent } from '@water-loss/features/schematics/schematic-container-components/schematic-search/schematic-search.component';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import { MapModule } from '../map/map.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { SchematicChartComponent } from './schematic-chart/schematic-chart.component';
import { SchematicsCreateNodeFormComponent } from './schematic-container-components/schematics-create-node-form/schematics-create-node-form.component';
import { SchematicImportPopupComponent } from './schematic-import-popup/schematic-import-popup.component';
import { SchematicImportComponent } from './schematic-import/schematic-import.component';
import { SchematicSelectorComponent } from './schematic-selector/schematic-selector.component';
import { SchematicsContainerComponent } from './schematics-container/schematics-container.component';
import { SchematicsPageComponent } from './schematics-page/schematics-page.component';
import { SchematicsRoutingModule } from './schematics-routing.module';
import { SchematicChartService } from './services/schematic-chart.service';
import { SchematicsService } from './services/schematics.service';
import { SchematicsContainerWidgetComponent } from './widgets/schematics-container-widget/schematics-container-widget.component';
import { SchematicsSelectorWidgetComponent } from './widgets/schematics-selector-widget/schematics-selector-widget.component';

@NgModule({
  declarations: [
    SchematicsPageComponent,
    SchematicsSelectorWidgetComponent,
    SchematicsContainerWidgetComponent,
    SchematicsContainerComponent,
    SchematicChartComponent,
    SchematicImportPopupComponent,
    SchematicImportComponent,
    SchematicsCreateNodeFormComponent,
    SchematicSearchComponent,
    SchematicSelectorComponent,
  ],
  imports: [
    CommonModule,
    MaterialSharedModule,
    DynamicLayoutModule,
    WlmSharedModule,
    SchematicsRoutingModule,
    WlmChartsModule,
    WLMGridModule,
    ReduxStateModule.forFeature({
      storeServices: [FiltersStoreService, GenericStoreService],
    }),
    FileUploadModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MapModule,
    CommonSharedComponentModule,
  ],
  providers: [
    SchematicsService,
    { provide: 'SchematicsService', useExisting: SchematicsService },
    SchematicChartService,
    { provide: 'SchematicChartService', useExisting: SchematicChartService },
  ],
})
export class SchematicsModule {
  constructor(widgetRegistry: WidgetRegistryService) {
    widgetRegistry.register([
      {
        name: 'SchematicsSelectorWidgetComponent',
        widgetClass: SchematicsSelectorWidgetComponent,
      },
      {
        name: 'SchematicsContainerWidgetComponent',
        widgetClass: SchematicsContainerWidgetComponent,
      },
    ]);
  }
}
