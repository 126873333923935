import { Inject, Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { NavItem } from '@common-modules/dependencies/navigation/nav-item';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, forkJoin, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppModules } from '../../shared/app-modules.enum';
import { AppPermissionsSettings } from '../../shared/auth/services/app-permissions-settings';
import { AuthorizeService, TPermissions } from '../../shared/auth/services/authorize.service';
import { SettingsService } from '../../shared/config/settings.service';
import { ObjectHelperService } from '../../shared/helpers/object-helper.service';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';
import { AppNavigationsSettings } from './app-navigations-settings';
import { BaseLink } from './base-link';
import { MenuLink } from './menu-link';
import { MenuLinkConf } from './menu-link-conf';
import { ModuleLink } from './module-link';
import { ModuleLinkConf } from './module-link-conf';

import { APP_NAVIGATIONS } from '../../shared/core/injection-tokens/app-navigations.token';
import { APP_PERMISSIONS } from '../../shared/core/injection-tokens/app-permissions.token';
import { CURRENT_ENVIRONMENT } from '../../shared/core/injection-tokens/current-environment.token';
import { NavKeys } from './nav-keys.enum';
import { WNavigateBy } from './w-navigate-by';

export const navigationMustOpenNewTab = (event: MouseEvent) =>
  event.ctrlKey || event.metaKey || event.button !== 0;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WlmNavigationService {
  private moduleLinks: ReplaySubject<Array<BaseLink>> = new ReplaySubject();
  private T_SCOPE = `${AppModules.WlmNavigation}.wlm-navigation-service`;

  private _environment;
  private _appNavigations: AppNavigationsSettings;
  private _appPermissions: AppPermissionsSettings;

  constructor(
    private authorizeService: AuthorizeService,
    private localization: LocalizationHelperService,
    private settingsService: SettingsService,
    private router: Router,
    private _objectHelperService: ObjectHelperService,
    @Inject(CURRENT_ENVIRONMENT) environment,
    @Inject(APP_NAVIGATIONS) appNavigations: AppNavigationsSettings,
    @Inject(APP_PERMISSIONS) appPermissions: AppPermissionsSettings
  ) {
    this._environment = environment;
    this._appNavigations = appNavigations;
    this._appPermissions = appPermissions;

    this.settingsService.ready$.pipe(untilDestroyed(this)).subscribe(() => {
      // Genegate all module links.
      const navItems = this._appNavigations.moduleLinkKeys.map((key) => new NavItem(key, false));
      const homeNavItem = new NavItem(NavKeys.Home, false);
      forkJoin([
        this.generateMenuLinks([homeNavItem]),
        this.generateModuleLinks(navItems),
      ]).subscribe(([menuLinks, moduleLinks]) => {
        let moduleLinksCopy = this._objectHelperService.clone(moduleLinks, true);
        const links: BaseLink[] = [...menuLinks, ...moduleLinksCopy];
        this.moduleLinks.next(links);
      });
    });
  }

  navigate(data: WNavigateBy): void {
    const { urlPath, queryParams, event, openInNewTab, customNavMethod } = data;

    let newTab = false;
    if (typeof openInNewTab !== 'undefined' && openInNewTab !== null) {
      newTab = openInNewTab;
    } else {
      newTab = navigationMustOpenNewTab(event);
    }

    if (customNavMethod) {
      customNavMethod({ openInNewTab: newTab });
      return;
    }

    if (newTab) {
      const urlTree = this.router.createUrlTree([urlPath], {
        queryParams,
      });
      const url = this.router.serializeUrl(urlTree);
      window.open(url);
    } else {
      this.router.navigate([urlPath], {
        queryParams,
      });
    }
  }

  getNavigationModules() {
    return this.moduleLinks.asObservable();
  }

  getNavItemUrl(navItemKey: NavKeys): string {
    const menuLinkConf = this._getMenuLinkConf(navItemKey);
    return menuLinkConf.urlPath;
  }

  /**
   * Builds an array of MenuLinks from an array of NavKeys.
   * IMPORTANT: This method should only receive Navkeys for menuLinks, not moduleLinks.
   * Removes links without permissions and injects translations.
   */
  generateMenuLinks(navItems: NavItem[]): Observable<MenuLink[]> {
    navItems = navItems ?? [];
    const dependencies$ = this._generateLinksDependencies(navItems);

    const modulePermissionsMap$ = this._getModulePermissionsMap(navItems);

    const navItemsInfo$ = forkJoin({
      dep: forkJoin(dependencies$),
      mod: forkJoin(modulePermissionsMap$),
    });

    // Use all the dependencies: check permissions and add translations to build the menu links.
    //return dependencies$.pipe(
    return navItemsInfo$.pipe(
      map((navItemsInfo: any) => {
        const menuLinks: MenuLink[] = [];
        // Build the array of menuLinks.
        navItems.forEach((navItem) => {
          const menuLinkConf = this._getMenuLinkConf(navItem.key);
          if (!menuLinkConf) {
            return;
          }
          // Check wrong use case.
          if ((menuLinkConf as any).menuLinksKeys && !environment.production) {
            throw Error(
              `Trying to use 'generateMenuLinks' with keys that correspond to ModuleLinks. Use only MenuLinks or use 'generateModuleLinks'.`
            );
          }
          if (
            (navItemsInfo.dep[0].perm[navItem.key] || navItemsInfo.dep[0].perm[navItem.titleKey]) &&
            navItemsInfo.mod[navItem.key]
          ) {
            // Parse the query params configured in the navItems, if existing
            menuLinks.push(
              new MenuLink({
                title: navItem.titleKey
                  ? navItemsInfo.dep[0].ts[navItem.titleKey]
                  : navItemsInfo.dep[0].ts[navItem.key],
                urlPath: menuLinkConf.urlPath,
                svgIcon: menuLinkConf.svgIcon,
                disabled: navItem.disable,
                disabledKeyTooltip: navItem.disabledKeyTooltip,
                queryParams: navItem.queryParams,
                isSvg: menuLinkConf.isSvg,
                customNavMethod: navItem.customNavMethod,
                key: navItem.key,
              })
            );
          }
        });
        return menuLinks;
      })
    );
  }

  generateMenuLinksByNavKeys(navKeys: NavKeys[]): Observable<MenuLink[]> {
    const navigationsItems = navKeys.map((navKey) => new NavItem(navKey, false));
    return this.generateMenuLinks(navigationsItems);
  }

  /**
   * Alternate to 'generateMenuLinks', but with moduleLinks. It will also process the child links of every moduleLink.
   * IMPORTANT: This method should only receive ModuleLinks, not MenuLinks.
   * Removes links without permissions and injects translations.
   */
  generateModuleLinks(navItems: NavItem[]): Observable<ModuleLink[]> {
    navItems = navItems ?? [];
    let dependencies$ = this._generateLinksDependencies(navItems);
    // Use all the dependencies: check permissions and add translations to build the links.
    return dependencies$.pipe(
      switchMap((deps: any) => {
        // Contain all module links, but with still no children.
        const moduleLinksNoChildren: { [navKey: string]: ModuleLink } = {};
        // Contain all children observables for each moduleLinks.
        const moduleLinksChildren$: { [navKey: string]: Observable<MenuLink[]> } = {};
        const array = [];

        // Build the array of menuLinks.
        navItems.forEach((navItem) => {
          const moduleLinkConf = this._getModuleLinkConf(navItem.key);
          // Check wrong use case.
          if (
            (moduleLinkConf as any).menuLinksKeys === undefined &&
            !this._environment.production
          ) {
            throw Error(
              `Trying to use 'generateModuleLinks' with keys that correspond to MenuLinks. Use only ModuleLinks or use 'generateMenuLinks'.`
            );
          }
          if (deps.perm[navItem.key]) {
            // Add the allowed moduleLink.
            moduleLinksNoChildren[navItem.key] = new ModuleLink({
              title: deps.ts[navItem.key],
              shortTitle: deps.shortTs[navItem.key],
              svgIcon: moduleLinkConf.svgIcon,
              menuLinks: [],
              isSvg: moduleLinkConf.isSvg,
            });
            // Also check the keys of the children menuLinks.
            moduleLinksChildren$[navItem.key] = this.generateMenuLinks(
              moduleLinkConf.menuLinksKeys.map((key) => new NavItem(key, navItem.disable))
            ).pipe(take(1));
            array.push(moduleLinksChildren$[navItem.key]);
          }
        });

        // Generate all children and append them to the new moduleLinks.
        return forkJoin(moduleLinksChildren$).pipe(
          map((children: { [navKey: string]: MenuLink[] }) => {
            const moduleLinks = [];
            for (let moduleKey in moduleLinksNoChildren) {
              // Get the link.
              const moduleLink: ModuleLink = moduleLinksNoChildren[moduleKey];
              // Append the children.
              moduleLink.menuLinks = children[moduleKey] as MenuLink[];

              if (moduleLink.menuLinks.length !== 0) {
                // Add it to result.
                moduleLinks.push(moduleLink);
              }
            }
            return moduleLinks;
          })
        );
      })
    );
  }

  /**
   * Get the permissions required for accessing a specific NavKey.
   */
  getNavLinkPermissions(navKey: NavKeys): TPermissions {
    const linkHasKey = this._appNavigations.links.has(navKey);
    const hiddenLinksHasKey = this._appNavigations.hiddenLinks.has(navKey);

    if (!linkHasKey && !hiddenLinksHasKey) {
      throw Error(`The key ${navKey} is not a valid NavKeys option.`);
    }

    const permissions = this._appPermissions.routePermissions.get(navKey);

    return permissions;
  }

  /**
   * Gets the module permissions for a given navkey (MenuLink)
   * IMPORTANT: This method should only receive MenuLinks, not ModuleLinks.
   */
  getNavLinkModulePermissions(navKey: NavKeys): TPermissions {
    const parentKey = this._appPermissions.permissionsHierarchy.get(navKey);
    if (!parentKey) {
      return null;
    }

    const parentPermissions = this._appPermissions.routePermissions.get(parentKey);

    return parentPermissions;
  }

  private _addQueryParams(url: string, params: Params): string {
    if (!url || !params) {
      return url;
    }
    const paramArray = [];
    for (let key in params) {
      paramArray.push({ key: key, value: params[key] });
    }
    const paramsStr = paramArray.map((item) => `${item.key}=${item.value}`).join('&');
    if (paramsStr && paramsStr !== '') {
      return `${url}?${paramsStr}`;
    }
    return url;
  }

  /**
   * Generate the dependencies (permissions, translations, etc) needed to create the links.
   */
  private _generateLinksDependencies(navItems: NavItem[]): any {
    const permissions$ = {};
    const translations$ = {};
    const shortTranslations$ = {};

    // We will load all permissions and all translations for the selected keys.
    navItems.forEach((navItem) => {
      const menuLink = this._getMenuLinkConf(navItem.key);

      permissions$[navItem.key] = this.authorizeService.canAccessMultiple(
        this.getNavLinkPermissions(navItem.key)
      );

      translations$[navItem.key] = menuLink?.titleTranslateKey
        ? this.localization.get(menuLink.titleTranslateKey)
        : of('');

      if (menuLink?.shortTitleTranslateKey) {
        shortTranslations$[navItem.key] = this.localization.get(menuLink.shortTitleTranslateKey);
      } else {
        shortTranslations$[navItem.key] = of(undefined);
      }

      if (navItem.titleKey) {
        translations$[navItem.titleKey] = this.localization.get(
          `${this.T_SCOPE}.${navItem.titleKey}`
        );
      }
    });

    // Get everything at once.
    const dependencies$ = forkJoin({
      perm: forkJoin(permissions$),
      ts: forkJoin(translations$),
      shortTs: forkJoin(shortTranslations$),
    });

    return dependencies$;
  }

  /**
   * Get the module permissions map for a given NavItem array.
   * IMPORTANT: This method should only receive MenuLinks, not ModuleLinks.
   */
  private _getModulePermissionsMap(navItems: NavItem[]): any {
    const moduleCanAccess$ = {};
    navItems.forEach((navigation) => {
      const modulePermissions = this.getNavLinkModulePermissions(navigation.key);
      moduleCanAccess$[navigation.key] = this.authorizeService.canAccessMultiple(modulePermissions);
    });

    return moduleCanAccess$;
  }

  /**
   * Get the corresponding link of a NavKey. The links also contain the permissions they need to be rendered.
   */
  private _getLinkConf(key: NavKeys): ModuleLinkConf | MenuLinkConf {
    const { links, hiddenLinks } = this._appNavigations;
    if (links.has(key)) {
      return links.get(key);
    } else if (hiddenLinks.has(key)) {
      return hiddenLinks.get(key);
    }
  }

  private _getMenuLinkConf(key: NavKeys): MenuLinkConf {
    return this._getLinkConf(key) as MenuLinkConf;
  }

  private _getModuleLinkConf(key: NavKeys): ModuleLinkConf {
    return this._getLinkConf(key) as ModuleLinkConf;
  }

  registryIcon(_iconName: string, _iconPath: string) {}

  navigateOrReload(commands: any[], extras?: NavigationExtras): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(commands, extras);
    });
  }
}
