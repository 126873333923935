import { Injectable, Injector } from '@angular/core';
import { ALCCampaignDto } from '@common-modules/dependencies/alc/alc-campaing.dto';
import { CampaignStatusStyle } from '@common-modules/dependencies/alc/campaign-status-style';
import { CampaignStatus } from '@common-modules/dependencies/alc/campaign-status.enum';
import { WaterBalanceParams } from '@common-modules/dependencies/water-balance/water-balance-params';
import { WaterBalanceTypesEnum } from '@common-modules/dependencies/water-balance/water-balance-types';
import { DynamicSettings } from '@common-modules/dynamic-layout/models/dynamic-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DynamicSettingsService } from '@common-modules/shared/config/dynamic-settings.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { CurrencyTypesEnum } from '@common-modules/shared/model/shared/currency-types.enum';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { get } from 'lodash';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ThematicKPI } from '../../map/map-thematic-configuration-popup/thematic-kpi';
import { KpiTranslationKeysMapping } from '../../map/map-thematic/models/kpi-translations-mapping';
import { MapThematicKpi } from '../../map/map-thematic/models/map-thematic-kpi';
import { MapThematicKpiSettngs } from '../../map/map-thematic/models/map-thematic-settings';
import { ALCCampaignBaseLine } from '../../shared/model/alc/alc-campaign-baseline';
import { ALCCampaignStatus } from '../../shared/model/alc/alc-campaign-status';
import { KpiTypeEnum } from '../../shared/model/kpi/kpi-type.enum';
import { KpiVisibilityEnum } from '../../shared/model/kpi/kpi-visibility.enum';
import { KpiTitlePipe } from '../../shared/pipes/kpi-title-pipe';
import { WaterBalanceService } from '../../water-balance/water-balance-page/water-balance.service';

@Injectable({
  providedIn: 'root',
})
export class AlcCampaignsService extends GridODataService<ALCCampaignDto> {
  T_SCOPE = `${AppModules.ALC}.alc-campaigns`;
  private _kpisTsRoute = `${AppModules.BI}.bi-widgets`;
  private _unitTranslationScope = 'common.unit-labels';

  constructor(
    injector: Injector,
    private _dynamicSettingsService: DynamicSettingsService,
    private _objectHelperService: ObjectHelperService,
    private _localizationService: LocalizationHelperService,
    private _uomHelperService: UoMService,
    private _waterbalanceService: WaterBalanceService,
    private _dateHelperService: DateHelperService
  ) {
    super(injector, '/api/alc/campaign');
  }

  protected mapResponse(response: PagedResultDto<ALCCampaignDto>): PagedResultDto<ALCCampaignDto> {
    return response;
  }

  saveCampaign(campaign: ALCCampaignDto) {
    return this.httpCacheClient.post(`${this.baseUrl}${this.api}`, campaign);
  }

  editCampaign(campaign: ALCCampaignDto) {
    return this.httpCacheClient.post(`${this.baseUrl}${this.api}/${campaign.campaignId}`, campaign);
  }

  deleteCampaign(campaigns: ALCCampaignDto[]): Observable<ALCCampaignDto> {
    const campaignIds = campaigns.map((x) => x.campaignId);

    return this.httpCacheClient.delete(`${this.baseUrl}${this.api}`, null, null, null, campaignIds);
  }

  getKpiSettings(): Observable<ThematicKPI[]> {
    const labels$ = this._localizationService.get(this._kpisTsRoute);
    const kpiSettings$ = this._dynamicSettingsService
      .loadDynamicSettings(
        new DynamicSettings({ settingArea: 'MapSetting', settingKey: 'MapKpis' })
      )
      .pipe(
        map((kpiSettings) => {
          let kpis: MapThematicKpi[] = [];

          if (!kpiSettings) {
            return kpis;
          }

          const parsedKpiSettings = this._objectHelperService.lowerCapitalizeKeysDeep(
            kpiSettings
          ) as MapThematicKpiSettngs;

          if (parsedKpiSettings?.categories) {
            Object.values(parsedKpiSettings.categories).forEach((value) => {
              const kpi = new MapThematicKpi(value.categoryKey, value.values);
              const campaignKpis = kpi.values.filter((x) =>
                x.visibility.includes(KpiVisibilityEnum.Campaign)
              );
              if (campaignKpis.length) {
                kpi.values = campaignKpis;
                kpis.push(kpi);
              }
            });
          }

          return kpis;
        })
      );

    return forkJoin([labels$, kpiSettings$]).pipe(
      map(([labels, kpis]) => {
        const thematicKpis = this.setThematicKpis(kpis, labels);
        return thematicKpis;
      })
    );
  }

  getCampaignStatuses(): ALCCampaignStatus[] {
    const status = [];

    const buildClass = (klass: string) => `base-pill ${klass}-color`;

    status.push(
      new ALCCampaignStatus({
        value: CampaignStatus.Open,
        class: buildClass(CampaignStatusStyle.Open),
        labelKey: `${this.T_SCOPE}.status.open`,
      })
    );
    status.push(
      new ALCCampaignStatus({
        value: CampaignStatus.Blocked,
        class: buildClass(CampaignStatusStyle.Blocked),
        labelKey: `${this.T_SCOPE}.status.blocked`,
      })
    );
    status.push(
      new ALCCampaignStatus({
        value: CampaignStatus.Closed,
        class: buildClass(CampaignStatusStyle.Closed),
        labelKey: `${this.T_SCOPE}.status.closed`,
      })
    );
    status.push(
      new ALCCampaignStatus({
        value: CampaignStatus.InDesign,
        class: buildClass(CampaignStatusStyle.InDesign),
        labelKey: `${this.T_SCOPE}.status.in-design`,
      })
    );
    status.push(
      new ALCCampaignStatus({
        value: CampaignStatus.Planned,
        class: buildClass(CampaignStatusStyle.Planned),
        labelKey: `${this.T_SCOPE}.status.planned`,
      })
    );

    return status;
  }

  getKpiUnitDescription(
    kpi: ThematicKPI,
    unitConversion: UnitTypeConversionViewDto
  ): Observable<string> {
    return this._uomHelperService.getFormatedUnit(
      unitConversion.unitTypeToDescription,
      `${this._unitTranslationScope}.${kpi.labelKey}`,
      kpi.unitTypeFormat
    );
  }

  getBaseLineValue(
    selectedKpi: ThematicKPI,
    daysOffset: number,
    familyId: string,
    hierarchyElementId: string
  ): Observable<ALCCampaignBaseLine> {
    const endDate = this._dateHelperService.getDefaultEndDate();
    const startDate = this._dateHelperService.getDateByDaysAgo(daysOffset);

    const waterBalanceType = selectedKpi.kpiType as KpiTypeEnum;

    const wbParams = new WaterBalanceParams({
      currencyTypeId: CurrencyTypesEnum.NA,
      dimensionTypeId: selectedKpi.dimensionTypeId,
      familyId,
      hierarchyElementIds: hierarchyElementId ? [hierarchyElementId] : [],
      startDate,
      endDate,
      waterBalanceType:
        waterBalanceType == KpiTypeEnum.WaterBalanceBottomUp
          ? WaterBalanceTypesEnum.BottomUp
          : WaterBalanceTypesEnum.TopDown,
    });

    return this._waterbalanceService.getWaterBalance(wbParams).pipe(
      map((wbValue) => {
        if (wbValue) {
          const kpiProperty = this._objectHelperService.camelCase(selectedKpi.kpiProperty);
          const kpiValue = wbValue[`${kpiProperty}Percentage`];
          const baseLine = new ALCCampaignBaseLine({
            systemInputValue: wbValue.systemInputVolume,
            kpiValue: kpiValue ?? 0,
          });

          return baseLine;
        }
      })
    );
  }

  private setThematicKpis(kpiSettings: MapThematicKpi[], ts: any): ThematicKPI[] {
    const kpis: ThematicKPI[] = [];

    const prefix = 'bi.bi-widgets.';
    const translationMapping = KpiTranslationKeysMapping;

    kpiSettings.forEach((kpi) => {
      kpi.values.forEach((x) => {
        const translationkey = translationMapping[kpi.categoryKey];
        const categoryLabel = get(ts, translationkey?.replace(prefix, ''));
        const categoryKey = translationkey?.replace(prefix, '');
        const kpiProperty = x.kpiProperty;
        const kpiType = x.kpiType;
        const kpiLabel$ = new KpiTitlePipe(this._localizationService).transform(x);

        const steps = x.steps;
        const timeAggregationId = x.timeAggregationId;
        const dimensionTypeId = x.dimensionTypeId;
        const unitTypeFormat = x.unitTypeFormat;
        const labelKey = x.labelKey;

        const thematicKpi = new ThematicKPI({
          categoryLabel,
          categoryKey,
          kpiProperty,
          kpiType,
          kpiLabel$,
          steps,
          timeAggregationId,
          dimensionTypeId,
          unitTypeFormat,
          labelKey,
        });

        kpis.push(thematicKpi);
      });
    });

    return kpis;
  }
}
