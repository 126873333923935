import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpCacheService } from '@common-modules/cache/http-cache/http-cache.service';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { InitialRedirectService } from '@common-modules/shared/auth/services/initial-redirect.service';
import { SettingsService } from '@common-modules/shared/config/settings.service';
import {
  ConfigurationHomePopupDimensions,
  VersionInfoPopupDimensions,
} from '@common-modules/shared/constants/dimensions.constants';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { CustomThemes } from '@common-modules/shared/theme/custom-themes';
import { ThemeService } from '@common-modules/shared/theme/theme.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { waterLossAppNavigationPaths } from '@water-loss//water-loss-navigations';
import { HomeConfigurationPopupComponent } from '../home-configuration-popup/home-configuration-popup.component';
import { NotificationConfigurationPopupComponent } from '../notification-configuration-page/notification-configuration-popup.component';
import { VersionInfoPopupComponent } from '../version-info-popup/version-info-popup.component';

class LangOption {
  locale: string;
  name: string;
}

const COMPONENT_SELECTOR = 'wlm-top-bar-account-card';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './top-bar-account-card.component.html',
  styleUrls: ['./top-bar-account-card.component.scss'],
})
export class TopBarAccountCardComponent implements OnInit {
  langs: LangOption[] = [];
  currentLocale: string;
  T_SCOPE = `${AppModules.WlmNavigation}.${COMPONENT_SELECTOR}`;
  today = new Date();
  theme: CustomThemes;
  darkThemeSliderChecked: boolean;

  constructor(
    public authService: AuthenticationService,
    private cacheService: HttpCacheService,
    private localization: LocalizationHelperService,
    private settingsService: SettingsService,
    private matDialog: MatDialog,
    private readonly _themeService: ThemeService,
    private readonly _initialRedirectService: InitialRedirectService,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {
    this.configTranslation();
    this._themeService.theme$.pipe(untilDestroyed(this)).subscribe((theme) => {
      this.theme = theme;
      this.darkThemeSliderChecked = theme === CustomThemes.Dark;
    });
  }

  /**
   * Configures translation-related data and build the array of available languages.
   */
  configTranslation(): void {
    this.currentLocale = this.localization.currentLocale;
    this.localization.get(`${this.T_SCOPE}.language-options`).subscribe((ts) => {
      const locales = this.localization.getLocales();
      this.langs = locales.map((locale) => ({ locale, name: ts[locale] }));
    });
  }

  /**
   * Set the selected locale to the active one.
   * @param locale The selected locale.
   */
  setLocale(locale: string) {
    this.localization.useLocale(locale);
  }

  logOut(): void {
    this.authService.logout(() => {
      this._initialRedirectService.saveCurrentRoute();
    });
  }

  cleanCache() {
    localStorage.clear();
    this.cacheService.clear();
  }

  navigatetoUserPage() {
    const url = this.settingsService.oAuthConfig.issuer;
    window.open(url, '_blank');
  }

  openVersionPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = VersionInfoPopupDimensions.Width;

    const popup = this.matDialog.open(VersionInfoPopupComponent, dialogConfig);
  }

  openNotificationConfigurationPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = VersionInfoPopupDimensions.Width;
    dialogConfig.height = VersionInfoPopupDimensions.Height;

    const popup = this.matDialog.open(NotificationConfigurationPopupComponent, dialogConfig);
  }

  openHomePageConfigurationPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = ConfigurationHomePopupDimensions.Width;
    dialogConfig.height = ConfigurationHomePopupDimensions.Height;

    const popup = this.matDialog.open(HomeConfigurationPopupComponent, dialogConfig);
  }

  toggleDarkTheme(): void {
    const nextTheme = this.theme === CustomThemes.Dark ? CustomThemes.Default : CustomThemes.Dark;
    this._themeService.setTheme(nextTheme);
    this.darkThemeSliderChecked = nextTheme === CustomThemes.Dark;
  }

  goToIntegrationPage(): void {
    this._router.navigate([waterLossAppNavigationPaths[NavKeys.Integration]]);
  }
}
