import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HierarchyElementFiltersDataDto } from '@common-modules/dependencies/he/hierarchy-element-filters-data.dto';
import * as constants from '@common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { IHierarchyElementTypes } from '@common-modules/dependencies/he/hierarchy.constants';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { HierarchyFamilyFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/hierarchy-family-fi-settings';
import { HierarchyTreeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/hierarchy-tree-fi-settings';
import { NeHeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { HierarchyElementsSelectionFilterConfiguration } from '@common-modules/shared-component/combined-grids/hierarchy-elements-selection-grid/hierarchy-elements-selection-filter-configuration';
import { NotificationRelatedComponent } from '@common-modules/shared-component/notification-related/notification-related.component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthorizeService } from '@common-modules/shared/auth/services/authorize.service';
import { EligibilityService } from '@common-modules/shared/component/eligibility-popup/eligibility.service';
import { ApplicationAttributes } from '@common-modules/shared/constants/application-constants';
import {
  AlarmsNavPopupDimensions,
  AlgorithmsNavPopupDimensions,
} from '@common-modules/shared/constants/dimensions.constants';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { LabelValueListItem } from '@common-modules/shared/core/label-value-list/label-value-list-item';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsEvent } from '@common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from '@common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { NavMenuBuilderHelperService } from '@common-modules/shared/helpers/navmenu-builder-helper.service';
import { ICanLeavePage } from '@common-modules/shared/navigation/can-component-deactivate';
import { NotificationGridSettingService } from '@common-modules/shared/notifications/notification-gridsetting.service';
import { IPendingChangesChecker } from '@common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { RightPanelAttributesService } from '@common-modules/shared/services/right-panel-attributes-builder.service';
import { PeriodTypesEnum } from '@common-modules/wlm-charts/core/models/period-types.enum';
import { TimeSelectorChartSettings } from '@common-modules/wlm-charts/core/models/time-selector-settings';
import { GridPersistedElements } from '@common-modules/wlm-grid/generic-grid/generic-grid-constants';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { PipeGridComponent } from '@common-modules/wlm-grid/pipe-grid/pipe-grid.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterDescriptor } from '@progress/kendo-data-query';
import {
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  combineLatest,
  forkJoin,
  of,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ActiveAlarmsGridComponent } from '../../alarms/active-alarms-grid/active-alarms-grid.component';
import { AlarmsChartComponent } from '../../alarms/alarms-chart/alarms-chart.component';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WNavigateSettings } from '@common-modules/dependencies/navigation/w-navigate-by';
import { WlmElementExtended } from '@common-modules/shared/charts/model/elements/element-extended';
import { ChartType } from '@common-modules/wlm-charts/core/models/chart-type.enum';
import { CustomWorkspaceChartDataParameters } from '@common-modules/wlm-charts/core/models/custom-workspace-chart-data-parameters';
import { CustomWorkspaceChartSettings } from '@common-modules/wlm-charts/core/models/custom-workspace-chart-settings';
import { EntityTypes } from '@common-modules/wlm-charts/core/models/entity-types';
import { TimeSelectorChartWidgetComponent } from '@common-modules/wlm-charts/core/time-selector-chart-widget/time-selector-chart-widget.component';
import { BaseGridPageComponent } from '@common-modules/wlm-grid/base-grid/base-grid-page.component';
import { DvAlarmsNavigationPopupComponent } from '../../alarms/dv-alarms-navigation-popup/dv-alarms-navigation-popup.component';
import { ActivitiesGridFilterValidations } from '../../alc/activities-grid/activities-grid-filter-validations';
import { GridAlgorithms } from '../../algorithm/constants';
import { DvAlgorithmNavigationPopupComponent } from '../../algorithm/dv-algorithm-navigation-popup/dv-algorithm-navigation-popup.component';
import { CustomerGroupComponent } from '../../customer/customer-group/customer-group.component';
import { DataVisualizationNavigationService } from '../../data-visualization-shared/services/data-visualization-navigation.service';
import { MapHelperService } from '../../map/map-helper.service';
import { MapParameters } from '../../map/map-parameters';
import { MapRelatedComponent } from '../../map/map-related/map-related.component';
import { IDistributionNetworkDto } from '../../shared/model/distribution-network/distribution-network.dto';
import { NavItemsConfiguration } from '../../shared/model/navigation/navitem-configuration';
import { NavMenuConfiguration } from '../../shared/model/navigation/navmenu-configuration';
import { PipePieChartComponent } from '../../shared/pipes/pipe-pie-chart/pipe-pie-chart.component';
import { DmaCommentsComponent } from '../distribution-network-components/dma-comments/dma-comments.component';

const COMPONENT_SELECTOR = 'wlm-distribution-network-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './distribution-network-page.component.html',
  styleUrls: ['./distribution-network-page.component.scss'],
  providers: [RightPanelAttributesService],
})
export class DistributionNetworkPageComponent
  extends BaseGridPageComponent
  implements OnInit, IPendingChangesChecker, OnDestroy, ICanLeavePage
{
  @ViewChild(GenericGridComponent) public distributionNetworkGrid: GenericGridComponent;

  private _hasBeenSelected = false;

  pageId: string = 'DistributionNetworkPage';
  autoLoad: boolean;
  filterConfig: HierarchyElementsSelectionFilterConfiguration;
  heFilterSettings: NeHeFISettings;
  selectedFamilyId: string;
  defaultHETypeIds: string[];
  hierarchyElementIdFieldName = 'ancestors';
  hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  hierarchyElementTypeFieldName = 'hierarchyElementTypeId';
  hierarchyElementId: string[];
  selectedHETypeIds: string[];
  filteredHierarchyElementIds: FilterDescriptor[];
  filterDetailsParameters: TabDetailPanelParameters;
  private _gridFilters: DataBindingFilters;
  private readonly alarmPopupMaxwidth = '81vw';
  public get gridFilters(): DataBindingFilters {
    return this._gridFilters;
  }
  public set gridFilters(value: DataBindingFilters) {
    this._gridFilters = value;
  }
  gridFiltersForBinding: DataBindingFilters;
  gridSettings: GridSetting;

  private _selectedDistribution: IDistributionNetworkDto;
  public get selectedDistribution(): IDistributionNetworkDto {
    return this._selectedDistribution;
  }
  public set selectedDistribution(value: IDistributionNetworkDto) {
    this.checkPendingChanges(this.pageId).subscribe((result) => {
      if (!result) {
        return;
      }
      this._selectedDistribution = value;

      this.notificationSelectionService.setZonesSelected([
        this.selectedDistribution?.hierarchyElementId,
      ]);
      this.SendParameters(this.selectedDistribution, this.filterDetailsParameters);
    });
  }

  private _selectedDistributions: IDistributionNetworkDto[];
  public get selectedDistributions(): IDistributionNetworkDto[] {
    return this._selectedDistributions;
  }
  public set selectedDistributions(value: IDistributionNetworkDto[]) {
    this._selectedDistributions = value;
    this.updateNavigationParams();
  }

  navigations: DropdownNavigationItem[] = [];
  zoneHeaderTitle: string;
  clearAll$ = new Subject<void>();
  triggerApply$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  configReady = false;
  canLoad: boolean;
  gridName = 'DistributionNetwork';
  zones: string[];
  maxRaiseZonesMessage: string;
  invalidLevelMessage: string;
  ineligibleZones$: Observable<string[]>;
  maxZonesToRaiseSelectedTs$: Observable<string>;
  invalidLevelSelectedTs$: Observable<string>;
  removeSelectionPersisted$ = new ReplaySubject<void>();
  gridSettingsReady$ = new ReplaySubject<GridSetting>();

  gridSettingsSubscription: Subscription;

  T_SCOPE = `${AppModules.DistributionNetwork}.${COMPONENT_SELECTOR}`;
  heLevelLayerMap = new Map<string, number>();

  private _hierarchyElementTypeFilterName = 'hierarchyElementTypeFilter';
  private _hierarchyElementsFilterName = 'hierarchyElementsFilter';
  private readonly _settingArea = 'MapSetting';
  private readonly _settingKey = `${this.persistencyArea}_MapFilter`;
  private readonly _hierarchyElementTypeIdField = 'hierarchyElementTypeId';
  private readonly _chartWorkspaceName = 'DistributionNetworkDetailsChart';

  constructor(
    private _gridService: GridSettingsService,
    private _globalsService: GlobalsService,
    private eligibilityService: EligibilityService,
    private route: ActivatedRoute,
    private _navMenuBuilderHelperService: NavMenuBuilderHelperService,
    private _popupDialog: MatDialog,
    private _notificationGridSettingService: NotificationGridSettingService,
    private _authService: AuthorizeService,
    private _mapHelperService: MapHelperService,
    private _rightPanelAttributesService: RightPanelAttributesService,
    private _pendingChangesService: PendingChangesManagerService,
    private readonly _dataVisualizationNavigationService: DataVisualizationNavigationService
  ) {
    super();

    this._rightPanelAttributesService.init({
      baseTranslateKey: `${this.T_SCOPE}.fields`,
      hierarchyElementTypeIdField: this._hierarchyElementTypeIdField,
    });
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }
  public get pageCrud(): string {
    return 'DistributionNetworkPageCrud';
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  ngOnInit(): void {
    super.ngOnInit();
    this.initMessageTooltips();
    this.subscribeLayersMap();

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.readNavigationParameters(params)
        .pipe(untilDestroyed(this))
        .subscribe((filtersData) => {
          if (filtersData?.hierarchyFamilyId && filtersData?.hierarchyElementTypeId) {
            this.selectedFamilyId = filtersData.hierarchyFamilyId;
            this.selectedHETypeIds = [filtersData.hierarchyElementTypeId];
          }

          this.init();

          const alarmsPermission$ = this._authService.canAccess('WLMAlarmsCrud', 'r');
          const notificationPermission$ = this._authService.canAccess('WLMNotificationsCrud', 'r');
          const localization$ = this.localization.get(`${this.T_SCOPE}.tab-settings`);

          combineLatest([alarmsPermission$, localization$, notificationPermission$]).subscribe({
            next: ([alarmsPermission, ts, notificationPermission]) => {
              const panelSettings = new TabDetailPanelSettings();

              if (alarmsPermission) {
                panelSettings.addComponent(ActiveAlarmsGridComponent, ts.alarms);
                panelSettings.addComponent(AlarmsChartComponent, ts.chart);
              }

              panelSettings.addComponent(TimeSelectorChartWidgetComponent, ts.trends);
              panelSettings.addComponent(DmaCommentsComponent, ts.comments);

              if (notificationPermission) {
                panelSettings.addComponent(NotificationRelatedComponent, ts.notifications);
              }

              panelSettings.addComponent(MapRelatedComponent, ts.map);
              panelSettings.addComponent(CustomerGroupComponent, ts['customers-summary']);
              panelSettings.addComponent(PipeGridComponent, ts['pipes-summary']);
              panelSettings.addComponent(PipePieChartComponent, ts['pipes-charts']);

              this.zoneHeaderTitle = ts.zone;

              this.rightPanelService.setTabSettings(panelSettings);
              this.rightPanelService.setCurrentPageId(this.pageId);
            },
          });
        });
    });
  }

  initMessageTooltips() {
    const maxRaiseZones = ApplicationAttributes.MaxItemsForRaiseActivities;
    this.maxZonesToRaiseSelectedTs$ = this.localization.get(
      `${AppModules.WlmShared}.wlm-nav-dropdown.max-raise-zones`,
      { maxRaiseZones }
    );
    this.invalidLevelSelectedTs$ = this.localization.get(
      `${AppModules.WlmShared}.wlm-nav-dropdown.disabled-not-dma`,
      { maxRaiseZones }
    );
  }

  readNavigationParameters(params: Params): Observable<HierarchyElementFiltersDataDto> {
    const queryParams = this.getQueryParams(params);

    if (queryParams.hierarchyElementIds) {
      this.removeSelectionPersisted$.next();
      this.useGridPersistence = false;
      const hierarchyElementIds =
        typeof queryParams.hierarchyElementIds === 'string'
          ? [queryParams.hierarchyElementIds]
          : queryParams.hierarchyElementIds;
      this.hierarchyElementId = hierarchyElementIds;
    }

    if (queryParams.hierarchyFamilyId && queryParams.hierarchyElementTypeId) {
      this.selectedFamilyId = queryParams.hierarchyFamilyId;
      this.selectedHETypeIds = queryParams.hierarchyElementTypeId
        ? [queryParams.hierarchyElementTypeId]
        : queryParams.hierarchyElementTypeId;
    }

    if (
      this.hierarchyElementId?.[0] &&
      !(queryParams?.hierarchyFamilyId && queryParams?.hierarchyElementTypeId)
    ) {
      return this._globalsService.getHeFiltersDataById(this.hierarchyElementId[0]);
    }

    return of(null);
  }

  init(): void {
    this.loadState();
    this.updateNavigationParams();
    this.initGrid();
  }

  initGrid() {
    if (this.gridName) {
      const persistedSettings = this.getPersisted(
        GridPersistedElements.Settings,
        undefined
      ) as GridSetting;

      if (persistedSettings) {
        this.setGridSettings(persistedSettings);
      } else {
        this._gridService.getGridSettingsByName(this.gridName).subscribe({
          next: (gridSettings) => {
            if (gridSettings) {
              gridSettings.notificationSetting =
                this._notificationGridSettingService.getNotificationSettingsForGrid(this.gridName);

              this.setGridSettings(gridSettings);
            }
          },
        });
      }
    }
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((result) => result !== null)
    );
  }

  loadState() {
    // load persisted familyId
    if (!this.selectedFamilyId) {
      const persistedFamilyBasicFilter = this.getPersisted(
        this.hierarchyElementFamilyFieldName,
        undefined
      );

      if (persistedFamilyBasicFilter) {
        this.selectedFamilyId = (persistedFamilyBasicFilter as BasicFilter)?.value?.shift()?.value;
      }
    }

    // load persisted heTypes
    if (!this.selectedHETypeIds) {
      const heTypesPersistedBasicFilter = this.getPersisted(
        this.hierarchyElementTypeFieldName,
        undefined
      );

      if (heTypesPersistedBasicFilter) {
        this.selectedHETypeIds = (heTypesPersistedBasicFilter as BasicFilter)?.value?.map(
          (x) => x.value
        );
      }
    }

    if (!this.hierarchyElementId) {
      this.hierarchyElementId = (
        this.getPersisted(this.hierarchyElementIdFieldName, [
          { value: '', label: '' },
        ]) as BasicFilter
      ).value;
    }

    this._globalsService
      .getDefaultHierarchyFamilyId(this.selectedFamilyId)
      .pipe(
        switchMap((familyId) => {
          if (!this.selectedFamilyId) {
            this.selectedFamilyId = familyId;
          }

          return this._globalsService.getHierarchyElementTypes(this.selectedFamilyId).pipe(
            map((hierarchies) => {
              // set default heTypeId (used on clear he filter)
              if (!this.defaultHETypeIds) {
                this.defaultHETypeIds = hierarchies
                  .filter(
                    (he) => he.hierarchyElementTypeName === constants.hierarchyElementTypes.DMA
                  )
                  ?.map((x) => x.hierarchyElementTypeId);
              }

              return this.defaultHETypeIds;
            })
          );
        })
      )
      .subscribe(() => {
        this.heFilterSettings = new NeHeFISettings({
          fieldName: this.hierarchyElementTypeFieldName,
          selectedIds: this.selectedHETypeIds ?? this.defaultHETypeIds,
          defaultValue: this.defaultHETypeIds,
          isNEFilter: false,
        });

        const gridFiltersForBinding = this.getPersisted(
          TabDetailParameterName.dataBindingFilter,
          undefined
        ) as DataBindingFilters;

        let dataBindingCasted = new DataBindingFilters();
        if (gridFiltersForBinding?.exportableFilter) {
          dataBindingCasted.exportableFilter = gridFiltersForBinding?.exportableFilter;
          dataBindingCasted.reloadFromSerialization();
        } else {
          dataBindingCasted = null;
        }

        this.loadPage(dataBindingCasted);
      });
  }

  private loadPage(gridFiltersForBinding: DataBindingFilters) {
    const configuration = new HierarchyElementsSelectionFilterConfiguration({
      persistencyArea: this.persistencyArea,
      familySettings: new HierarchyFamilyFISettings({
        fieldName: this.hierarchyElementFamilyFieldName,
        selectedId: this.selectedFamilyId,
      }),
      treeSettings: new HierarchyTreeFISettings({
        fieldName: this.hierarchyElementIdFieldName,
        selectedIds: this.hierarchyElementId,
      }),
      heTypeSettings: this.heFilterSettings,
    });

    this.canLoad =
      this.heFilterSettings?.selectedIds?.length > 0 && this.selectedFamilyId !== undefined;
    this.filterConfig = configuration;

    if (gridFiltersForBinding) {
      this.autoLoad = true;
    }

    this.configReady = true;
  }

  updateNavigationParams() {
    this.navigations = [];
    const ineligibleZones$ = this.eligibilityService.getIneligibleZones(true);

    forkJoin([
      ineligibleZones$,
      this.maxZonesToRaiseSelectedTs$,
      this.invalidLevelSelectedTs$,
    ]).subscribe(([zones, maxRaiseZonesMessage, invalidLevelMessage]) => {
      this.zones = zones;
      this.maxRaiseZonesMessage = maxRaiseZonesMessage;
      this.invalidLevelMessage = invalidLevelMessage;

      const navItemsConfiguration = this.getNavItemsConfigurations();
      const navMenuConfiguration = new NavMenuConfiguration({
        selectedElements: this.selectedDistributions,
        items: navItemsConfiguration,
      });
      this._navMenuBuilderHelperService
        .buildMenu(navMenuConfiguration)
        .subscribe((menu) => (this.navigations = menu));
    });
  }

  private getNavItemsConfigurations(): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.Prioritisation,
        validationType: 'max-zones',
        paramType: 'he-family',
      },
      {
        key: NavKeys.ActivityRaising,
        validationType: 'custom',
        customValidation: this.getNavRaisingDisabled(),
        customtooltip: this.getNavRaisingDisabledTooltip(),
        paramType: 'he-family',
      },
      { key: NavKeys.Activities, validationType: 'max-zones', paramType: 'he-family' },
      { key: NavKeys.Leaks, validationType: 'max-zones', paramType: 'he-family' },
      {
        key: NavKeys.DataVisualization,
        validationType: 'max-zones',
        paramType: 'he-family',
        customNavMethod: this.openAlarmsPopup,
        titleKey: 'dataviz-alarms',
      },
      {
        key: NavKeys.DataVisualization,
        validationType: 'max-zones',
        paramType: 'he-family',
        customNavMethod: this.onManageTemplatesAndWorkspaces,
      },
      { key: NavKeys.WaterBalance, validationType: 'max-zones', paramType: 'he-family' },
      {
        key: NavKeys.Map,
        validationType: 'custom',
        customValidation: this.selectedDistributions?.length !== 1,
        customtooltip: this._navMenuBuilderHelperService.getNavDisabledTooltipMessageByMaxZones(),
        paramType: 'he-family',
      },
    ];
  }

  openAlarmsPopup = (navigateSettings: WNavigateSettings): void => {
    const data = {
      hierarchyElements: this.selectedDistributions.map((x) => x.hierarchyElementId),
      hierarchyFamilyId: this.selectedFamilyId,
      navigateSettings,
    };
    this.openPopup(
      AlarmsNavPopupDimensions.Width,
      AlarmsNavPopupDimensions.Height,
      data,
      DvAlarmsNavigationPopupComponent,
      this.alarmPopupMaxwidth
    );
  };

  openAlgorithmsPopup = (): void => {
    const data = {
      hierarchyElements: this.selectedDistributions.map((x) => x.hierarchyElementId),
      algorithms: [GridAlgorithms.DDI, GridAlgorithms.MNF, GridAlgorithms.DL],
    };
    this.openPopup(
      AlgorithmsNavPopupDimensions.Width,
      AlgorithmsNavPopupDimensions.Height,
      data,
      DvAlgorithmNavigationPopupComponent
    );
  };

  onManageTemplatesAndWorkspaces = (navigateSettings: WNavigateSettings): void => {
    const elements = this.selectedDistributions.map(
      (x) =>
        new WlmElementExtended(
          x.hierarchyElementId,
          x.hierarchyElementTypeId,
          x.hierarchyElementName,
          null,
          null,
          EntityTypes.hierarchyElement
        )
    );

    this._dataVisualizationNavigationService.openManageTemplatePopupAndNavigate(
      navigateSettings,
      elements,
      this._chartWorkspaceName
    );
  };

  private openPopup(width: string, height: string, data: any, component: any, maxWidth?: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = width;
    dialogConfig.height = height;
    dialogConfig.data = data;
    dialogConfig.maxWidth = maxWidth;
    this._popupDialog.open(component, dialogConfig);
  }

  private getNavRaisingDisabledTooltip() {
    return this.hasSomeNonDMASelected()
      ? this.invalidLevelMessage
      : this.numberOfEligibleZonesExceeded()
      ? this.maxRaiseZonesMessage
      : '';
  }

  private numberOfEligibleZonesExceeded(): boolean {
    return (
      this.selectedDistributions?.filter((x) => !this.zones.includes(x.hierarchyElementId))
        ?.length > ApplicationAttributes.MaxItemsForRaiseActivities
    );
  }

  private hasSomeNonDMASelected(): boolean {
    return this.selectedDistributions?.some(
      (x) => x.hierarchyElementTypeId !== IHierarchyElementTypes.DMA
    );
  }

  private getNavRaisingDisabled() {
    return (
      !this.selectedDistributions?.length ||
      this.hasSomeNonDMASelected() ||
      this.numberOfEligibleZonesExceeded()
    );
  }

  setFilteredZones(hierarchyElementIds: string[]) {
    if (hierarchyElementIds) {
      this.filteredHierarchyElementIds = hierarchyElementIds.map(
        (x) =>
          ({
            field: 'hierarchyElementId',
            operator: 'eq',
            value: x,
          } as FilterDescriptor)
      );
    }
  }

  /**
   * When the filters are applied from this page, emit the observable helper that will trigger the apply action inside the filters component.
   * This is done to propagate the filters to this component, that will receive them in getDataBindingFilters and getFiltersDetailsParameters.
   * When the "apply" event is triggered, the onApplyFiltersFromFilters will finally load the grid.
   */
  onApplyFiltersFromPage(): void {
    this.triggerApply$.next();
  }

  /**
   * From this callback, the filters are already loaded, so we just need to load the grid to apply them.
   */
  onApplyFilters(gridFiltersRef?: any): void {
    this.loadGrid(gridFiltersRef);
  }

  onValidityChange(valid: boolean): void {
    this.canLoad = valid;
  }

  loadGrid(gridFiltersRef?: any) {
    const newGridFilters = new DataBindingFilters();
    if (gridFiltersRef && gridFiltersRef.filters) {
      newGridFilters.filters = gridFiltersRef.filters;
    } else if (this.gridFilters && this.gridFilters.filters) {
      newGridFilters.filters = this.gridFilters.filters;
    }

    if (newGridFilters?.filters?.size > 0) {
      this.gridFiltersForBinding = newGridFilters;
    }
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const filterValidation = new ActivitiesGridFilterValidations();

    if (
      filtersParameters &&
      filterValidation.validateFilters(
        [this._hierarchyElementTypeFilterName, this._hierarchyElementsFilterName],
        filtersParameters
      )
    ) {
      this.gridFilters = filtersParameters;
      const heTypeFilter = this.gridFilters.exportableFilter[this._hierarchyElementTypeFilterName];
      this.selectedHETypeIds = heTypeFilter
        .getFiltersValues()
        .get(this.hierarchyElementTypeFieldName);

      this.persistFilters$.next();
    }
  }

  getFiltersDetailsParameters(filtersDetailsParameters: TabDetailPanelParameters) {
    this.filterDetailsParameters = filtersDetailsParameters;
    if (this.autoLoad && this.canLoad) {
      this.loadGrid();
      this.autoLoad = false;
    }
  }

  ngOnDestroy() {
    this.persist(TabDetailParameterName.dataBindingFilter, this.gridFiltersForBinding);
    this.notificationSelectionService.setZonesSelected(null);

    super.ngOnDestroy();
  }

  getSelectedDistribution(selectedActivity: IDistributionNetworkDto) {
    if (selectedActivity) {
      this._hasBeenSelected = true;
    }

    this.selectedDistribution = selectedActivity;
  }

  getSelectedDistributions(selectedDistributions: IDistributionNetworkDto[]) {
    this.selectedDistributions = selectedDistributions;
  }

  private SendParameters(
    selectedElement: IDistributionNetworkDto,
    filter: TabDetailPanelParameters
  ) {
    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      const parameters = filter ?? new TabDetailPanelParameters();

      parameters.addParameter(TabDetailParameterName.pageId, this.pageId);
      parameters.addParameter(
        TabDetailParameterName.hierarchyElementId,
        selectedElement?.hierarchyElementId
      );
      parameters.addParameter(TabDetailParameterName.notification, selectedElement);

      const mapParameters = this.initializeMapParameters();

      if (this._hasBeenSelected) {
        mapParameters.hierarchyElements = selectedElement?.hierarchyElementId
          ? [selectedElement?.hierarchyElementId]
          : [];
        mapParameters.visibleLayersIds = [
          ...new Set([
            ...mapParameters.visibleLayersIds,
            ...[this.heLevelLayerMap.get(this.selectedHETypeIds[0])],
          ]),
        ];

        let timeSelectorChartSetting = null;

        if (selectedElement?.hierarchyElementId) {
          const wlmElement = this.getWlmElement(selectedElement);

          timeSelectorChartSetting = new TimeSelectorChartSettings({
            chartSetting: new CustomWorkspaceChartSettings({
              chartType: ChartType.customizable,
              dataParameters: new CustomWorkspaceChartDataParameters({
                startDate: new Date(),
                endDate: new Date(),
                workspaceName: this._chartWorkspaceName,
                elements: [wlmElement],
              }),
              exportFileName: `${this.titleService.getTitle()} ${
                selectedElement?.hierarchyElementId
              }`,
            }),
            includeDefaultPeriods: true,
            defaultSelectedPeriodType: PeriodTypesEnum.customFromDateRange,
          });
        }

        parameters.addParameter(
          TabDetailParameterName.timeSelectorChartSetting,
          timeSelectorChartSetting
        );
      }

      parameters.addParameter(TabDetailParameterName.mapParameters, mapParameters);

      parameters.addParameter(
        TabDetailParameterName.topAttributes,
        this.buildRightPanelAttributes()
      );
      parameters.addParameter(TabDetailParameterName.topValues, selectedElement);

      this.buildRightPanelHeader(selectedElement)
        .pipe(untilDestroyed(this))
        .subscribe((rightPanelHeader) => {
          // Default
          if (!rightPanelHeader && selectedElement) {
            rightPanelHeader = selectedElement.hierarchyElementId
              ? ts['zone-title'] + selectedElement.hierarchyElementId
              : null;
          }
          parameters.addParameter(TabDetailParameterName.elementName, rightPanelHeader);

          this.includeAdditionalParams(parameters);
          this.rightPanelService.setTabParameters(parameters);
        });
    });
  }

  private getWlmElement(selectedElement: IDistributionNetworkDto): WlmElementExtended {
    const { hierarchyElementId, hierarchyElementTypeId, hierarchyElementName } = selectedElement;

    return new WlmElementExtended(
      hierarchyElementId,
      hierarchyElementTypeId,
      hierarchyElementName,
      null,
      null
    );
  }

  private initializeMapParameters() {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    const mapParameters = MapParameters.getparameter({
      visibleLayersIds: persistedMapsettings ? persistedMapsettings.visibleLayersIds : null,
      leakYears: persistedMapsettings ? persistedMapsettings.leakYears : null,
      visibleThematicsIds: persistedMapsettings ? persistedMapsettings.visibleThematicsIds : null,
      showFilters: true,
      settingArea: this._settingArea,
      settingKey: this._settingKey,
      loadFromPersistency: persistedMapsettings === null,
    });

    return mapParameters;
  }

  /**
   * Captures all the click events of all the buttons and associate them to the correct callbacks.
   */
  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.Filter:
        this.onApplyFiltersFromPage();
        break;
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
        break;
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  onCheckAutoload(): void {
    if (this.gridSettingsSubscription && !this.gridSettingsSubscription.closed) {
      this.gridSettingsSubscription.unsubscribe();
    }
    this.gridSettingsSubscription = this.gridSettingsReady$
      .pipe(untilDestroyed(this))
      .subscribe((settings) => {
        if (!settings.disableAutoLoad && this.gridFilters?.filters) {
          this.loadGrid();
        }
      });
  }

  canLeavePage(): Observable<boolean> {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    this._mapHelperService.persistMapSettings(
      persistedMapsettings,
      this._settingArea,
      this._settingKey
    );

    return this.checkPendingChanges(this.pageId);
  }

  private subscribeLayersMap() {
    this._mapHelperService.getHeLevelLayerMapping().subscribe((map) => {
      this.heLevelLayerMap = map;
    });
  }

  private setGridSettings(gridSettings: GridSetting) {
    this.gridSettings = gridSettings;

    this.gridSettingsReady$.next(this.gridSettings);
    this.gridSettingsReady$.complete();
  }

  private buildRightPanelAttributes(): LabelValueListItem[] {
    const buildAlgorithmAttribute = this._rightPanelAttributesService.buildAlgorithmAttribute;

    return [
      buildAlgorithmAttribute({
        shortName: 'DDI',
        propertyFn: (model: IDistributionNetworkDto) => model.ddi,
      }),
      buildAlgorithmAttribute({
        shortName: 'MNF',
        propertyFn: (model: IDistributionNetworkDto) => model.mnf,
      }),
      buildAlgorithmAttribute({
        shortName: 'DL',
        propertyFn: (model: IDistributionNetworkDto) => model.dl,
      }),
    ];
  }

  private buildRightPanelHeader(selectedElement: IDistributionNetworkDto): Observable<string> {
    return this._rightPanelAttributesService.getRightPanelHeaderFromSettings(
      this.gridSettings,
      selectedElement
    );
  }
}
