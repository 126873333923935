import { Component, DestroyRef, ElementRef, Inject, Injector, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BiFilters } from '@common-modules/dependencies/bi/bi-filters';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { TrendChartWidgetParams } from '@common-modules/dynamic-layout/models/widget-definition-settings';
import { DefaultParamsState } from '@common-modules/dynamic-layout/state/default-params/default-params-state';
import { ResetDefaultParamsAction } from '@common-modules/dynamic-layout/state/default-params/default-params.actions';
import { AppliedDefaultParamsSelector } from '@common-modules/dynamic-layout/state/default-params/default-params.selectors';
import { AppliedExtendedFiltersSelector } from '@common-modules/dynamic-layout/state/filters/filters.selectors';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ExtendedFilters } from '@common-modules/shared/filters/component-filters/extended-filters';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { IElementSize } from '@common-modules/shared/model/element-size';
import { SizeCalculatorService } from '@common-modules/shared/services/size-calculator.service';
import { ChartType } from '@common-modules/wlm-charts/core/models/chart-type.enum';
import { PieChartDataParameters } from '@common-modules/wlm-charts/core/models/pie-chart-data-parameters';
import { PieChartSettings } from '@common-modules/wlm-charts/core/models/pie-chart-settings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseBIDynamicWidgetComponent } from '../../../models/base-bi-dynamic-widget';
import { BiService } from '../../../services/bi.service';

const COMPONENT_SELECTOR = 'wlm-pie-chart-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './pie-chart-widget.component.html',
  styleUrls: ['./pie-chart-widget.component.scss'],
})
export class PieChartWidgetComponent extends BaseBIDynamicWidgetComponent implements OnInit {
  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.BI,
  });

  chartDefinition: string;
  chartSettings: PieChartSettings;
  isLoading = false;
  chartHasData = true;

  //From KPI definition
  dataService: string;
  staticQueryParams: any;
  queryParamFieldsMap: { [key: string]: string };

  biPageFilters: BiFilters;
  biDefaultFilters: BiFilters;
  filters: BiFilters;
  private _previousFilters: BiFilters;

  private readonly _fixedSizeElements$ = new ReplaySubject<IElementSize>(1);
  readonly fixedSizeElements$ = this._fixedSizeElements$.asObservable();

  private _containerSize$: Observable<IElementSize>;
  get containerSize$(): Observable<IElementSize> {
    return this._containerSize$;
  }
  set containerSize$(value: Observable<IElementSize>) {
    this._containerSize$ = value;
    this.tryInitResize();
  }

  private _fixedSizes$: Observable<IElementSize>[] = [];

  private readonly _calculatedSize$ = new ReplaySubject<IElementSize>(1);
  readonly calculatedSize$ = this._calculatedSize$.asObservable();
  private _resizeSubscription: Subscription;

  private readonly _sizeCalculatorService = inject(SizeCalculatorService);
  protected readonly _destroyRef = inject(DestroyRef);

  private tryInitResize(): void {
    if (this.containerSize$ && this._fixedSizes$?.length) {
      const size$ = this._sizeCalculatorService.listenCalculateRemainingSize$({
        containerSize$: this.containerSize$,
        fixedSizes$: this._fixedSizes$,
        dimensionToCalculate: 'height',
        debugName: COMPONENT_SELECTOR,
      });

      this._resizeSubscription?.unsubscribe();
      this._resizeSubscription = size$
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe((size) => {
          this._calculatedSize$.next(size);
        });
    }
  }

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _objectHelperService: ObjectHelperService,
    private _biService: BiService,
    private readonly _element: ElementRef
  ) {
    super(injector, widgetSettings);

    this.setTitle(widgetSettings);

    this.setChartParamams(widgetSettings.params);
  }

  onWidgetInit(): void {
    this.containerSize$ = this._sizeCalculatorService.listenElementSize$(
      this._element.nativeElement
    );

    this._state
      .select<ExtendedFilters>(new AppliedExtendedFiltersSelector(this._scopeSettings))
      .pipe(
        untilDestroyed(this),
        map((pageFilters) => pageFilters?.model as BiFilters),
        map((pageBiFilters: BiFilters) => {
          if (!pageBiFilters) {
            return null;
          }

          const { defaultFilters, lockFilters } = this.itemSettings;

          this.biPageFilters = globalUtilsHelper.clone(pageBiFilters, true);

          return this._biService.applyFilterLocks(defaultFilters, pageBiFilters, lockFilters);
        })
      )
      .subscribe({
        next: (biFilters: BiFilters) => {
          if (!biFilters) {
            return;
          }

          this.filters = biFilters;

          if (!this._previousFilters) {
            // Initial case.
            this.setDynamicChartSettings();
          } else {
            const filtersDiff: Partial<BiFilters> = this._objectHelperService.deepDiff(
              this.filters,
              this._previousFilters
            );

            if (typeof filtersDiff.selectedIds !== 'undefined') {
              this.setDynamicChartSettings();
            }
          }

          this._previousFilters = this.filters;
        },
      });

    this._state
      .select<DefaultParamsState>(new AppliedDefaultParamsSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (state) => {
          // TODO: the widget key check should be outside this class
          if (!state || state.widgetInstanceKey !== this.getWidgetInstanceKey()) {
            return;
          }

          this.itemSettings.defaultFilters = state.filters.model;
          this.itemSettings.lockFilters = state.lockFilters;

          this.biDefaultFilters = state.filters.model as BiFilters;
          this.setTitle(state);

          this.filters = this._biService.applyFilterLocks(
            this.biDefaultFilters,
            this.biPageFilters,
            state.lockFilters
          );

          const filtersDiff: Partial<BiFilters> = this._objectHelperService.deepDiff(
            this.filters,
            this._previousFilters
          );

          this._previousFilters = this.filters;

          if (typeof filtersDiff.selectedIds !== 'undefined') {
            this.setDynamicChartSettings();
          }

          // This must be here because only the WB widget knows how to serialize its filters.
          this.updateItemSettings({
            params: null,
            defaultFilters: this.biDefaultFilters,
            lockFilters: state.lockFilters,
            widgetInstanceKey: this.getWidgetInstanceKey(),
            title: state.title,
          });

          // This is to avoid loading the same params again.
          this._state.dispatch(new ResetDefaultParamsAction(this._scopeSettings));
        },
      });
  }

  get componentName(): string {
    return COMPONENT_SELECTOR;
  }

  setDynamicChartSettings() {
    const queryParams = this.getQueryParams();
    const dataParameters = new PieChartDataParameters({
      endDate: null,
      startDate: null,
      queryParams,
      dataService: this.dataService,
    });

    const chartSettings = new PieChartSettings({
      dataParameters,
      chartType: ChartType.pie,
    });

    this.chartSettings = chartSettings;
    this.chartHasData = true;
  }

  setChartParamams(params: TrendChartWidgetParams): void {
    this.dataService = params.dataService;
    this.staticQueryParams = params.staticQueryParams;
    this.queryParamFieldsMap = params.queryParamFieldsMap;
  }

  setElementsIds(elementIds: string[]): void {
    this.filters.selectedIds = elementIds;
    this.setDynamicChartSettings();
  }

  onChartLoaded(isLoading): void {
    this.isLoading = isLoading;
  }

  onChartHasData(hasData: boolean) {
    this.chartHasData = hasData;
  }

  private getQueryParams() {
    const queryParams: { [key: string]: any } = { ...this.staticQueryParams };
    const fieldNames = this._biService.filters.fieldNames;

    const heIdParamName = this.queryParamFieldsMap[fieldNames.hierarchyElementId];
    const heFamilyIdParamName = this.queryParamFieldsMap[fieldNames.hierarchyFamilyId];

    queryParams[heIdParamName] = this.filters.selectedIds;
    queryParams[heFamilyIdParamName] = this.filters.selectedFamily;

    return queryParams;
  }

  onWidgetInfoSizeChanged(size: IElementSize): void {
    this._fixedSizes$ = [of(size)];
    this.tryInitResize();
  }
}
