import { Component, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { FiltersAdapterService } from '@common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { ElementTypeFAdapter } from '../../../dependencies/wlm-filters/adapters/element-type-f-adapter';
import { FamilyFAdapter } from '../../../dependencies/wlm-filters/adapters/family-f-adapter';
import { BaseFilterItemSettings } from '../../../dependencies/wlm-filters/base-filter-item-settings';

import { HeFamilyLevelFilterConfiguration } from './he-family-level-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-he-family-level-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './he-family-level-filter.component.html',
  styleUrls: ['./he-family-level-filter.component.scss'],
})
export class HeFamilyLevelFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _filterConfiguration: HeFamilyLevelFilterConfiguration;
  public get filterConfiguration(): HeFamilyLevelFilterConfiguration {
    return this._filterConfiguration;
  }
  public set filterConfiguration(value: HeFamilyLevelFilterConfiguration) {
    this._filterConfiguration = value;
    if (value) {
      this.setFiltersPersistencyArea();
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  private _filterGroupSettings: FilterGroupSettings;
  public get settings(): FilterGroupSettings {
    return this._filterGroupSettings;
  }
  @Input() public set settings(v: FilterGroupSettings) {
    this._filterGroupSettings = v;

    if (v) {
      if (v.navigationParams) {
        this.loadNavigationParams(v.navigationParams);
      }

      this.loadState();
    }
  }

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
  };
  hefamilyLevelSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-1-label`,
    disableSelectAll: true,
    disableSearch: true,
  };
  hierarchyFamilyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
    required: true,
  });
  hierarchyLevelFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
  });

  selectedFamilyId: any;
  selectedLevel: any;
  hfSelectedId: string;
  filterIsReady = false;

  private _defaultHETypeIds = ['efd92639-f72a-464a-aaba-53e78e0ba129'];

  constructor(
    private _adaptersService: FiltersAdapterService,
    private _globalsService: GlobalsService,
    _persistencyService: PersistencyService
  ) {
    super(_persistencyService);
  }
  ngOnInit(): void {}

  buildKeysToComplete(): void {
    const { hierarchyElementFamilyFieldName, hierarchyElementTypeFieldName } =
      this.filterConfiguration;
    this.keysToComplete = [hierarchyElementFamilyFieldName, hierarchyElementTypeFieldName];
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const {
      hierarchyElementFamilyFieldName,
      heFamilyFilterSettings,
      hierarchyElementTypeFieldName,
      heTypeSettings,
    } = this.filterConfiguration;
    const adapters = [
      new FamilyFAdapter({
        dataBindingField: 'hierarchyFamily',
        heFamilyFieldName: hierarchyElementFamilyFieldName,
        heFamilyFilterSettings,
      }),

      new ElementTypeFAdapter({
        dataBindingField: 'hierarchyLevel',
        elementTypeFieldName: hierarchyElementTypeFieldName,
        neheFilterSettings: heTypeSettings,
      }),
    ];
    return adapters;
  };

  loadState() {
    const filters = [];
    this.persistencyArea = this.settings.persistencyArea;

    if (!this.selectedLevel) {
      const persistedLevel = this.getPersisted(
        this.settings.fields.hierarchyLevel.fieldName,
        undefined
      );

      this.selectedLevel = persistedLevel
        ? (persistedLevel as BasicFilter)?.value?.map((m) => m.value)
        : this._defaultHETypeIds;
    }
    if (!this.selectedFamilyId) {
      const persistedFamilyId = this.getPersisted(
        this.settings.fields.hierarchyFamilyId.fieldName,
        undefined
      );

      if (persistedFamilyId) {
        this.selectedFamilyId = (persistedFamilyId as BasicFilter)?.value?.shift()?.value;
      }
    }
    this._globalsService
      .getDefaultHierarchyFamilyId(this.selectedFamilyId)
      .subscribe((familyId) => {
        if (!this.selectedFamilyId) {
          this.selectedFamilyId = familyId;
        }

        const excludeElementTypes =
          this.settings?.additionalParams?.excludeElementTypes?.value ?? [];

        this.filterConfiguration = new HeFamilyLevelFilterConfiguration(
          this.settings.persistencyArea,
          this.settings.fields.hierarchyFamilyId.fieldName,
          this.settings.fields.hierarchyLevel.fieldName,
          this.selectedFamilyId,
          this.selectedLevel,
          [familyId],
          this._defaultHETypeIds,
          excludeElementTypes,
          filters
        );

        this.hfSelectedId = this.selectedFamilyId;
      });
  }

  onFamilyIdSelect(filters: FiltersPayload): void {
    if (this.filterIsReady) {
      const familyFieldName = this.filterConfiguration.heFamilyFilterSettings.fieldName;
      const defaultId = this.filterConfiguration.heFamilyFilterSettings.selectedId;
      const newFamilyId = this._adaptersService.getFamilyId(filters, familyFieldName, defaultId);
      if (!this.hfSelectedId || this.hfSelectedId !== newFamilyId) {
        this.hfSelectedId = newFamilyId;
      }
    }
  }

  protected setFiltersPersistencyArea(): void {
    this.hefamilyLevelSettings.persistencyArea = this.filterConfiguration.persistencyArea;
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    this.filterIsReady = true;
    return true;
  }

  private loadNavigationParams(queryParams: Params) {
    this.selectedFamilyId = queryParams.hierarchyFamilyId;
    this.selectedLevel = queryParams.hierarchyLevel;
  }
}
