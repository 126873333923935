import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { WtrColors } from '@common-modules/shared/styles/wtr-colors';
import { FlatTreeNode } from '@common-modules/shared/tree/flat-tree-node';
import { DvAlgorithmsSelectorItemSettings } from '../../models/dv-algorithms-selector-item-settings';

const COMPONENT_SELECTOR = 'wlm-data-visualization-algorithms-selector-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-algorithms-selector-item.component.html',
  styleUrls: ['./data-visualization-algorithms-selector-item.component.scss'],
})
export class DataVisualizationAlgorithmsSelectorItemComponent implements OnInit {
  @Input() node: FlatTreeNode<string, DvAlgorithmsSelectorItemSettings>;
  @Input() isExpanded: boolean;

  @Output() algorithmSelected = new EventEmitter<DvAlgorithmsSelectorItemSettings>();
  @Output() groupToggled = new EventEmitter<string>();

  readonly T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;

  iconColor = WtrColors.NavBarIconColor;

  constructor() {}

  ngOnInit(): void {}

  onAlgorithmSelected(item: DvAlgorithmsSelectorItemSettings): void {
    this.algorithmSelected.emit(item);
  }

  onGroupToggle(): void {
    this.groupToggled.emit(this.node.id);
  }
}
