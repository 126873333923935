import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { BiFilters } from '@common-modules/dependencies/bi/bi-filters';
import { HierarchyService } from '@common-modules/dependencies/he/hierarchy.service';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { WorkspaceChartWidgetParams } from '@common-modules/dynamic-layout/models/widget-definition-settings';
import { DefaultParamsState } from '@common-modules/dynamic-layout/state/default-params/default-params-state';
import { ResetDefaultParamsAction } from '@common-modules/dynamic-layout/state/default-params/default-params.actions';
import { AppliedDefaultParamsSelector } from '@common-modules/dynamic-layout/state/default-params/default-params.selectors';
import { AppliedExtendedFiltersSelector } from '@common-modules/dynamic-layout/state/filters/filters.selectors';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { WlmElementExtended } from '@common-modules/shared/charts/model/elements/element-extended';
import { ExtendedFilters } from '@common-modules/shared/filters/component-filters/extended-filters';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { EventViewCategories } from '@common-modules/wlm-charts/core/events-chart/models/event-view-categories';
import { EventChartQueryDto } from '@common-modules/wlm-charts/core/events-chart/models/events-chart-query.dto';
import { ChartType } from '@common-modules/wlm-charts/core/models/chart-type.enum';
import { CustomWorkspaceChartDataParameters } from '@common-modules/wlm-charts/core/models/custom-workspace-chart-data-parameters';
import { CustomWorkspaceChartSettings } from '@common-modules/wlm-charts/core/models/custom-workspace-chart-settings';
import { DynamicChartSettings } from '@common-modules/wlm-charts/core/models/dynamic-chart-settings';
import { DynamicChartComponent } from '@common-modules/wlm-charts/dynamic-chart/dynamic-chart.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, map, of } from 'rxjs';
import { BaseBIDynamicWidgetComponent } from '../../../models/base-bi-dynamic-widget';
import { BiService } from '../../../services/bi.service';

const COMPONENT_SELECTOR = 'wlm-workspace-chart-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './workspace-chart-widget.component.html',
  styleUrls: ['./workspace-chart-widget.component.scss'],
})
export class WorkspaceChartWidgetComponent extends BaseBIDynamicWidgetComponent implements OnInit {
  @ViewChild(DynamicChartComponent) dynamicChartComponent;

  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.BI,
  });

  chartDefinition: string;
  chartSettings: DynamicChartSettings;

  //From KPI definition
  workspaceName: string;
  eventCategories: EventViewCategories[];

  filters: BiFilters;

  biPageFilters: BiFilters;
  biDefaultFilters: BiFilters;

  private _previousFilters: BiFilters;

  // Filters fieldNames
  private _hierarchyElementIdFieldName = 'HierarchyElementId';
  private _startDateFieldName = 'StartDate';
  private _endDateFieldName = 'EndDate';

  private _setDateParams$ = new ReplaySubject<DateRange>();
  readonly setDateParams$ = this._setDateParams$.asObservable();

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private readonly _objectHelperService: ObjectHelperService,
    private readonly _biService: BiService,
    private readonly _heService: HierarchyService
  ) {
    super(injector, widgetSettings);

    this.setChartParamams(widgetSettings.params);
  }

  onWidgetInit(): void {
    this._state
      .select<ExtendedFilters>(new AppliedExtendedFiltersSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (filters) => {
          if (!filters) {
            return;
          }

          this.filters = this.extractBiFilters(filters);

          if (!this._previousFilters) {
            // Initial case.
            this.setDynamicChartSettings();
          } else {
            const filtersDiff: Partial<BiFilters> = this._objectHelperService.deepDiff(
              this.filters,
              this._previousFilters
            );
            if (typeof filtersDiff.selectedIds !== 'undefined') {
              this.setDynamicChartSettings();
            } else if (typeof filtersDiff.selectedDateRange !== 'undefined') {
              this.setDateParams(this.filters.selectedDateRange);
            }
          }

          this._previousFilters = this.filters;
        },
      });

    this._state
      .select<DefaultParamsState>(new AppliedDefaultParamsSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (state) => {
          // TODO: the widget key check should be outside this class
          if (!state || state.widgetInstanceKey !== this.getWidgetInstanceKey()) {
            return;
          }

          this.biDefaultFilters = state.filters.model as BiFilters;

          this.filters = this._biService.applyFilterLocks(
            this.biDefaultFilters,
            this.biPageFilters,
            state.lockFilters
          );
          // TODO: Change to accept two lock flags. Also, extract this logic elsewhere.
          if (state.lockFilters) {
            this.filters = this.biDefaultFilters;
          } else {
            this.filters = this.biPageFilters;
          }

          const filtersDiff: Partial<BiFilters> = this._objectHelperService.deepDiff(
            this.filters,
            this._previousFilters
          );
          if (typeof filtersDiff.selectedIds !== 'undefined') {
            this.setDynamicChartSettings();
          } else if (typeof filtersDiff.selectedDateRange !== 'undefined') {
            this.setDateParams(this.filters.selectedDateRange);
          }

          //Uncomment this when working in WB Builder story
          // this.setChartParams(filters.params);

          // This must be here because only the WB widget knows how to serialize its filters.
          this.updateItemSettings({
            params: state.params,
            defaultFilters: this.biDefaultFilters,
            lockFilters: state.lockFilters,
            widgetInstanceKey: this.getWidgetInstanceKey(),
            title: state.title,
          });

          // This is to avoid loading the same params again.
          this._state.dispatch(new ResetDefaultParamsAction(this._scopeSettings));
        },
      });
  }

  get componentName(): string {
    return COMPONENT_SELECTOR;
  }

  setDynamicChartSettings() {
    this.getWlmElements(this.filters.selectedIds).subscribe((wlmElements) => {
      const dataParameters = new CustomWorkspaceChartDataParameters({
        startDate: this.filters.selectedDateRange.start,
        endDate: this.filters.selectedDateRange.end,
        workspaceName: this.workspaceName,
        elements: wlmElements,
      });

      const chartSettings = new CustomWorkspaceChartSettings({
        dataParameters,
        chartType: ChartType.workspace,
      });

      const eventChartSettings = this.eventCategories?.length
        ? new EventChartQueryDto({
            categories: this.eventCategories,
            endDate: this.filters.selectedDateRange.end,
            startDate: this.filters.selectedDateRange.start,
            elementsIds: this.filters.selectedIds,
          })
        : null;

      const dynamicChartSettings = new DynamicChartSettings({
        chartSettings,
        eventChartSettings: eventChartSettings,
      });

      this.chartSettings = dynamicChartSettings;
    });
  }

  setChartParamams(params: WorkspaceChartWidgetParams): void {
    this.workspaceName = params.templateName;
    this.eventCategories = params.eventChartCategories;
  }

  setDateParams(dateRange: DateRange): void {
    this.filters.selectedDateRange = dateRange;
    this._setDateParams$.next(dateRange);
  }

  setElementsIds(elementIds: string[]): void {
    this.filters.selectedIds = elementIds;
    this.setDynamicChartSettings();
  }

  private extractBiFilters(filters: ExtendedFilters): BiFilters {
    const model = filters.model;
    const biFilters = new BiFilters({
      selectedDateRange: new DateRange(
        model[this._startDateFieldName],
        model[this._endDateFieldName],
        this._startDateFieldName,
        this._endDateFieldName
      ),
      selectedIds: model[this._hierarchyElementIdFieldName],
      selectedElements: model.selectedElements,
    });
    return biFilters;
  }

  private getWlmElements(elementIds: string[]): Observable<WlmElementExtended[]> {
    if (!elementIds?.length) {
      return of([]);
    }

    return this._heService.getByKeys(elementIds).pipe(
      untilDestroyed(this),
      map((hierarchyElements) => {
        return hierarchyElements.map((he) => {
          const { hierarchyElementId, hierarchyElementTypeId, hierarchyElementName } = he;

          return new WlmElementExtended(
            hierarchyElementId,
            hierarchyElementTypeId,
            hierarchyElementName,
            null,
            null
          );
        });
      })
    );
  }
}
