import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@common-modules/shared/base.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoordinatesSystem } from './coordinates-system.enum';
import { LatLongCoordinates } from './lat-long-coordinates';
import { UtmCoordinates } from './utm-coordinates';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CoordinatesSystemService extends BaseService {
  private readonly _latLongCoordinatesRegex = new RegExp(
    /^([-+]?\d+(\.\d+)?),\s*([-+]?\d+(\.\d+)?)$/
  );
  private readonly _eastingNorthingCoordsRegex = new RegExp(/^(\d{6})\s*-\s*(\d{1,7})$/);

  get coordinatesSystem(): string {
    return this.settingsService.gis.coordinatesSystem;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  getCoordinatesFormatted(value: string): string {
    let coordinatesFormatted = '';

    // If the user inputs grid reference coordinates,
    // ignore the configuration and return them as already formatted.
    if (this.isValidBritishGridCoordinate(value)) {
      coordinatesFormatted = value;
    } else {
      switch (this.coordinatesSystem) {
        case CoordinatesSystem.EastingNorthing:
          coordinatesFormatted = this.getEastingNorthingCoordinates(value)?.formattedValue;
          break;

        default:
          coordinatesFormatted = this.getLatLongCoordinates(value)?.formattedValue;
          break;
      }
    }
    return coordinatesFormatted;
  }

  getConvertedCoordinates(value: string): Observable<LatLongCoordinates> {
    if (this.isValidBritishGridCoordinate(value)) {
      const utmCoordinates = this.britishGridReferenceToEastingNorthing(value);
      return this.convertUTMCoordinates(utmCoordinates);
    }

    switch (this.coordinatesSystem) {
      case CoordinatesSystem.EastingNorthing:
        const utmCoordinates = this.getEastingNorthingCoordinates(value);
        return this.convertUTMCoordinates(utmCoordinates);

      default:
        const latLongCoordinates = this.getLatLongCoordinates(value);
        return of(latLongCoordinates);
    }
  }

  convertUTMCoordinates(utmCoordinates: UtmCoordinates): Observable<LatLongCoordinates> {
    if (!utmCoordinates) {
      return of(null);
    }

    const queryParams = {
      easting: utmCoordinates.easting,
      northing: utmCoordinates.northing,
    };

    return this.httpCacheClient.get<LatLongCoordinates>(
      `${this.apiUrl}/gis/coordinate/easting-northing`,
      {
        avoid: true,
      },
      queryParams
    );
  }

  getLatLongToUTMFormatted(latitude: number, longitude): Observable<string> {
    const latLongCoordinates = new LatLongCoordinates(latitude, longitude);

    return this.convertLatLongCoordinates(latLongCoordinates).pipe(
      map((utmCoodinates: UtmCoordinates) => {
        const easting = this.formatEastingNorthingPart(utmCoodinates.easting);
        const northing = this.formatEastingNorthingPart(utmCoodinates.northing);

        return `${easting}${northing}`;
      })
    );
  }

  convertLatLongCoordinates(latLongCoordinates: LatLongCoordinates): Observable<UtmCoordinates> {
    const queryParams = {
      latitude: latLongCoordinates.latitude,
      longitude: latLongCoordinates.longitude,
    };

    return this.httpCacheClient.get<UtmCoordinates>(
      `${this.apiUrl}/gis/coordinate/latitude-longitude`,
      {
        avoid: true,
      },
      queryParams
    );
  }

  // Only validate grid references that are in British National Grid coordinate (EPSG:27700):
  // Example: "NS 86152 57939", "NS 8615 5793", "NS 861 579"
  isValidBritishGridCoordinate(coordinate: string): boolean {
    const coordinateRegex = /^[A-Z]{2}\s(\d+)\s(\d+)$/;
    if (coordinateRegex.test(coordinate)) {
      const matches = coordinate.match(coordinateRegex);
      if (matches.length === 3) {
        const firstNumber = matches[1];
        const secondNumber = matches[2];
        if (
          firstNumber.length === secondNumber.length &&
          (firstNumber.length === 3 || firstNumber.length === 4 || firstNumber.length === 5)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Example:
   * @input "NS 86152 57939";
   * @output { "easting": 586152, "northing": 557939 }
   */
  britishGridReferenceToEastingNorthing(gridRef: string) {
    gridRef = gridRef.replace(/\s/g, '');
    let multiplier: number;
    let length: number;
    switch (gridRef.length) {
      case 8:
        multiplier = 100;
        length = 3;
        break;
      case 10:
        multiplier = 10;
        length = 4;
        break;
      case 12:
        multiplier = 1;
        length = 5;
        break;
      default:
        throw new Error(
          'Unexpected length of grid reference (should be 8, 10 or 12 characters long)'
        );
    }
    const char1 = gridRef[0];
    const char2 = gridRef[1];
    let east = parseInt(gridRef.substring(2, 2 + length)) * multiplier;
    let north = parseInt(gridRef.substring(2 + length, gridRef.length)) * multiplier;
    if (char1 === 'H') {
      north += 1000000;
    } else if (char1 === 'N') {
      north += 500000;
    } else if (char1 === 'O') {
      north += 500000;
      east += 500000;
    } else if (char1 === 'T') {
      east += 500000;
    }
    let char2ord = char2.charCodeAt(0);
    if (char2ord > 73) char2ord--; // Adjust for no I

    const nx = ((char2ord - 65) % 5) * 100000;
    const ny = (4 - Math.floor((char2ord - 65) / 5)) * 100000;
    const easting = east + nx;
    const northing = north + ny;

    return new UtmCoordinates(easting, northing);
  }

  private formatEastingNorthingPart(number: number): number {
    const numberString = number.toString();

    const truncFirstPart = numberString.match(/^\d{1,6}/);

    const firstSixDigits = truncFirstPart ? Number(truncFirstPart[0]) : 0;
    return firstSixDigits;
  }

  private getEastingNorthingCoordinates(value: string): UtmCoordinates {
    const match = this._eastingNorthingCoordsRegex.exec(value);
    const coordinates = match?.[0]?.split('-');

    if (!coordinates || coordinates.length !== 2) {
      return;
    }

    const easting = +coordinates[0];
    const northing = +coordinates[1];

    return new UtmCoordinates(easting, northing);
  }

  private getLatLongCoordinates(value: string): LatLongCoordinates {
    const match = this._latLongCoordinatesRegex.exec(value);
    const coordinates = match?.[0]?.split(',');

    if (!coordinates || coordinates.length !== 2) {
      return;
    }

    const lat = +coordinates[0];
    const lng = +coordinates[1];

    // Latitude ranges from -90 to 90; longitude ranges from -180 to 180
    if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
      return;
    }

    return new LatLongCoordinates(lat, lng);
  }
}
