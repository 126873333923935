import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
// prettier-ignore

const COMPONENT_SELECTOR = 'wlm-point-configuration';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './point-configuration.component.html',
  styleUrls: ['./point-configuration.component.scss'],
})
export class PointConfigurationComponent extends BaseWidgetComponent implements OnInit {
  @ViewChild(GenericGridComponent) public genericGrid: GenericGridComponent;

  gridSettings: GridSetting;
  filters: DataBindingFilters;

  private _signalId: number;
  public get signalId(): number {
    return this._signalId;
  }
  public set signalId(v: number) {
    this._signalId = v;
    this.init();
  }

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private _gridSettingService: GridSettingsService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._gridSettingService.getGridSettingsByName('PointConfiguration').subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.signalId = parameters.parameters.get(TabDetailParameterName.signalId);
  }

  init(): void {
    this.setDataBindingFilter();
  }

  private setDataBindingFilter() {
    if (!this.signalId) return;
    const auxFilter = new DataBindingFilters();
    auxFilter.addOrUpdateBasicFilter(TabDetailParameterName.signalId, this.signalId);
    this.filters = auxFilter;
  }

  get componentName() {
    return 'PointConfigurationComponent';
  }
}
