import { HistoricalVersionDto } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-version.dto';
import { BoundarySignalDto } from './boundary-signal.dto';

export class BoundarySignalVersionDto extends HistoricalVersionDto {
  hierarchyElementId: string;
  hierarchyElementTypeId: string;
  signalId: number;
  pointId: string;
  pointDescription: string;
  isIn: boolean;

  constructor(init: BoundarySignalVersionDto) {
    super(init);
    Object.assign(this, init);
  }

  getCurrentConfig?(): BoundarySignalDto {
    const current: BoundarySignalDto = {
      signalId: this.signalId,
      hierarchyElementId: this.hierarchyElementId,
      hierarchyElementTypeId: this.hierarchyElementTypeId,
      pointId: this.pointId,
      pointDescription: this.pointDescription,
      isIn: this.isIn,
      title: '',
    };
    return current;
  }
}
