import { Component, Inject, Injector } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout.tokens';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';

const COMPONENT_SELECTOR = 'wlm-integration-gis-layers-grid-widget';

export const INTEGRATION_GIS_LAYER_GRID_WIDGET_INSTANCE = COMPONENT_SELECTOR + '#1';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './integration-gis-layers-grid-widget.component.html',
  styleUrls: ['./integration-gis-layers-grid-widget.component.scss'],
  providers: [ReduxStateService],
})
export class IntegrationGisLayersGridWidgetComponent extends BaseDynamicWidgetComponent {
  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  onWidgetInit(): void {}

  get componentName(): string {
    return 'IntegrationGisLayersGridWidgetComponent';
  }
}
