import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { untilDestroyed } from '@ngneat/until-destroy';
import { FieldTypeConfig } from '@ngx-formly/core';
// IMPORTANT: This should always be imported from material, not core.
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'wlm-chips-input-type',
  templateUrl: './chips-input-type.component.html',
  styleUrl: './chips-input-type.component.scss',
  host: {
    class: 'wlm-chips-input',
  },
})
export class ChipsInputTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  items: string[] = [];
  itemControl = new FormControl();
  readonly selectable = true;
  readonly removable = true;
  readonly addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  ngOnInit(): void {
    this.items = this.formControl.value ?? [];
    this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.items = value;
    });
  }

  get empty() {
    return this.formControl.value?.length === 0;
  }

  add(event: MatChipInputEvent): void {
    // Add item only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    const input = event.input;
    const value = event.value;

    // Add item
    if ((value || '').trim()) {
      this.formControl.setValue([...(this.formControl.value ?? []), value.trim()]);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.itemControl.setValue(null);

    this.formControl.markAsDirty();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.formControl.setValue([...(this.formControl.value ?? []), event.option.viewValue]);

    this.itemControl.setValue(null);
  }

  isValidChip(text: string): boolean {
    if (this.props.maxChipLength && text?.length > this.props.maxChipLength) {
      return false;
    }
    return true;
  }

  remove(i: number): void {
    const value = this.formControl.value;

    this.formControl.setValue([...value.slice(0, i), ...value.slice(i + 1, value.length)]);
    this.formControl.markAsDirty();
  }

  onBlur() {
    this.formControl.markAsTouched();
    this.field.focus = false;
  }
}
