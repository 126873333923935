import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { SelectedNetworkElementSelector } from '@common-modules/dynamic-layout/state/ne-config/ne-config.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { applyOnActiveWidget } from '@common-modules/redux/operators/apply-on-active-widget.operator';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { untilDestroyed } from '@ngneat/until-destroy';

export function necSelectNetworkElement(
  that: BaseDynamicWidgetComponent,
  state: ReduxStateService,
  settings: StateScopeSettings,
  callbackFn: (networkElement: INetworkElementDto) => void
): void {
  state
    .select<INetworkElementDto>(new SelectedNetworkElementSelector(settings))
    .pipe(untilDestroyed(that), applyOnActiveWidget(that, callbackFn))
    .subscribe();
}
