import { Component, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { SetValueAction } from '@common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from '@common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { AlgorithmFiltrableItemDto } from '../../../shared/model/filtrable-items/algorithm-filtrable-item.dto';
import { SignalFiltrableItemDto } from '../../../shared/model/filtrable-items/signal-filtrable-item.dto';
import { DataVisualizationCartSelectedWorkspace } from '../../models/data-visualization-cart-selected-workspace';
import { DataVisualizationCartSelection } from '../../models/data-visualization-cart-selection';
import { DataVisualizationCartSettings } from '../../models/data-visualization-cart-settings';
import { DataVisualizationStateFields } from '../../models/data-visualization-state-fields';

const COMPONENT_SELECTOR = 'wlm-data-visualization-cart-widget';
export const DV_CART_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-cart-widget.component.html',
  styleUrls: ['./data-visualization-cart-widget.component.scss'],
  providers: [ReduxStateService],
})
export class DataVisualizationCartWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  private _algorithmsAdded$ = new Subject<AlgorithmFiltrableItemDto[]>();
  readonly algorithmsAdded$ = this._algorithmsAdded$.asObservable();

  private _pointAdded$ = new Subject<SignalFiltrableItemDto>();
  readonly pointAdded$ = this._pointAdded$.asObservable();

  private _workspaceLoaded$ = new ReplaySubject<DataVisualizationCartSelectedWorkspace>();
  readonly workspaceLoaded$ = this._workspaceLoaded$.asObservable();

  private _clearItems$ = new Subject<boolean>();
  readonly clearItems$ = this._clearItems$.asObservable();

  cartSettings: DataVisualizationCartSettings;

  get componentName(): string {
    return 'DataVisualizationCartWidgetComponent';
  }

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);

    this.setCartSettings(widgetSettings.itemSettings?.params);
  }

  onWidgetInit(): void {
    this.listenState(DataVisualizationStateFields.selectedAlgorithms).subscribe(
      (algorithmsSelected: AlgorithmFiltrableItemDto[]) => {
        if (algorithmsSelected?.length) {
          const algorithms = algorithmsSelected.map((a) => globalUtilsHelper.clone(a, true));
          this._algorithmsAdded$.next(algorithms);
        }
      }
    );

    this.listenState(DataVisualizationStateFields.selectedPoint).subscribe(
      (signalSelected: SignalFiltrableItemDto) => {
        if (signalSelected) {
          const signal = globalUtilsHelper.clone(signalSelected, true);
          this._pointAdded$.next(signal);
        }
      }
    );

    this.listenState(DataVisualizationStateFields.selectedCartWorkspace).subscribe(
      (workspace: DataVisualizationCartSelectedWorkspace) => {
        if (workspace) {
          this._workspaceLoaded$.next(workspace);
        }
      }
    );

    this.listenState(DataVisualizationStateFields.clearAll).subscribe((value) => {
      if (value) {
        this.cartSettings = null;
        this._clearItems$.next(true);
      }
    });
  }

  onPlotCart(cartSelection: DataVisualizationCartSelection) {
    this.persistChanges(cartSelection);

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.plotCartSelection,
        value: null,
      })
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.plotCartSelection,
        value: cartSelection,
      })
    );
  }

  persistChanges(cartSelection: DataVisualizationCartSelection) {
    this.updateItemSettings({
      title: this._settings.title,
      params: cartSelection.cartSettings,
      widgetInstanceKey: this.getWidgetInstanceKey(),
    });
  }

  onCartSelectionChange(cartSelection: DataVisualizationCartSelection) {
    this.persistChanges(cartSelection);
    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.cartSelectionUpdate,
        value: cartSelection?.items,
      })
    );
  }

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }

  private setCartSettings(params: any) {
    this.cartSettings = params as DataVisualizationCartSettings;
  }
}
