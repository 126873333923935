import { Injectable } from '@angular/core';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { PeriodTypesEnum } from '@common-modules/wlm-charts/core/models/period-types.enum';
import { TimeSelectorPeriod } from '@common-modules/wlm-charts/core/models/time-selector-period';

@Injectable({
  providedIn: 'root',
})
export class TimeSelectorService {
  constructor(private _dateHelperService: DateHelperService) {}

  getDefaultTimeSelectorPeriod(): TimeSelectorPeriod[] {
    const periods = [];

    periods.push(
      new TimeSelectorPeriod({
        startDate: this._dateHelperService.getDateByDaysAgo(7),
        endDate: this._dateHelperService.getDefaultEndDate(),
        keyLabel: 'week',
        periodType: PeriodTypesEnum.week,
      })
    );

    periods.push(
      new TimeSelectorPeriod({
        startDate: this._dateHelperService.getDateByMonthAgo(1),
        endDate: this._dateHelperService.getDefaultEndDate(),
        keyLabel: 'month',
        periodType: PeriodTypesEnum.month,
      })
    );

    // Uncomment to add months periods

    periods.push(
      new TimeSelectorPeriod({
        startDate: this._dateHelperService.getDateByMonthAgo(3),
        endDate: this._dateHelperService.getDefaultEndDate(),
        keyLabel: 'months',
        numberParam: 3,
        periodType: PeriodTypesEnum.threeMonths,
      })
    );

    periods.push(
      new TimeSelectorPeriod({
        startDate: this._dateHelperService.getDateByMonthAgo(12),
        endDate: this._dateHelperService.getDefaultEndDate(),
        keyLabel: 'year',
        periodType: PeriodTypesEnum.year,
      })
    );

    periods.push(
      new TimeSelectorPeriod({
        startDate: this._dateHelperService.getDateByMonthAgo(24),
        endDate: this._dateHelperService.getDefaultEndDate(),
        keyLabel: 'years',
        numberParam: 2,
        periodType: PeriodTypesEnum.twoYears,
      })
    );

    periods.push(
      new TimeSelectorPeriod({
        startDate: this._dateHelperService.getDateByMonthAgo(36),
        endDate: this._dateHelperService.getDefaultEndDate(),
        keyLabel: 'years',
        numberParam: 3,
        periodType: PeriodTypesEnum.threeYears,
      })
    );

    return periods;
  }
}
