import { Injectable, Injector } from '@angular/core';
import { AlarmSeriesDto } from '@common-modules/dependencies/alarms/alarm-serie.dto';
import { CVService } from '@common-modules/dependencies/cv/cv.service';
import { CVChartSerieDto } from '@common-modules/dependencies/cv/models/cv-chart-serie.dto';
import { MVService } from '@common-modules/dependencies/mv/mv.service';
import { CVShortQueryDto } from '@common-modules/dependencies/shared/model/cv-short-query.dto';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { EstimatedEditedEnum } from '@common-modules/shared/model/algorithm/estimated-edited.enum';
import { TimeAggregationEnum } from '@common-modules/shared/model/algorithm/time-aggregation.enum';
import { MVChartSerieDto } from '@common-modules/shared/model/mv/mv-chart-serie.dto';
import { MVQueryDto } from '@common-modules/shared/model/mv/mv-query.dto';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { ChartSerieTypeEnum } from '@common-modules/wlm-charts/core/models/chart-serie-type.enum';
import { GCartesianChartSerie } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-cartesian-chart-series';
import { GChartSerieDataPoint } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-serie-data-point';
import { GenericCartesianChartSettings } from '@common-modules/wlm-charts/core/models/generic-chart-settings/generic-cartesian-chart-settings';
import { TrendChartDataParameters } from '@common-modules/wlm-charts/core/models/trend-chart-data-parameters';
import { BaseCartesianChartService } from '@common-modules/wlm-charts/core/services/base-cartesian-chart.service';
import { Observable, forkJoin, map, of, take } from 'rxjs';

@Injectable()
export class MVCVChartService extends BaseCartesianChartService {
  private _timeAggregationForSignals = 1;
  private _naUnitTypeId = 50;

  constructor(
    injector: Injector,
    private _cvService: CVService,
    private _mvService: MVService,
    private _uomService: UoMService,
    private _dateHelper: DateHelperService
  ) {
    super(injector);
  }

  protected get url() {
    return `${this.apiUrl}/dv`;
  }

  public getData(dataQueries: TrendChartDataParameters): Observable<any[]> {
    if (dataQueries.queryParams['algorithms']?.length) {
      var cvshortQuery = new CVShortQueryDto(
        dataQueries.startDate,
        dataQueries.endDate,
        dataQueries.queryParams['elementIds'],
        dataQueries.queryParams['algorithms']
      );
      return this._cvService.getChart(cvshortQuery);
    } else if (dataQueries.queryParams['signalIds']?.length) {
      const mvQuery = new MVQueryDto(
        dataQueries.startDate,
        dataQueries.endDate,
        dataQueries.queryParams['signalIds']
      );
      return this._mvService.getChart(mvQuery);
    }

    return of(null);
  }

  public mapDataToGenericSettings(
    data: CVChartSerieDto[] | MVChartSerieDto[]
  ): Observable<GenericCartesianChartSettings> {
    const series$: Observable<GCartesianChartSerie>[] = [];
    data?.forEach((serie) => {
      let serieObs$: Observable<GCartesianChartSerie> = new Observable<GCartesianChartSerie>(null);
      if (serie['algorithmShortName']) {
        serieObs$ = this.getCVGCartesianChartSerie(serie).pipe(take(1));
      } else if (serie['signalId']) {
        // Pipe(first()) is needed to complete the uom subject.Otherwise, the forkJoin never ends.
        serieObs$ = this.getMVGCartesianChartSerie(serie).pipe(take(1));
      }
      series$.push(serieObs$);
    });

    if (!series$.length) {
      return of(null);
    }

    return forkJoin(series$).pipe(
      map(
        (series) =>
          new GenericCartesianChartSettings({
            series,
          })
      )
    );
  }

  public getQueriesDtosForAlarms(alarmSeries: AlarmSeriesDto): (MVQueryDto | CVShortQueryDto)[] {
    const queries = [];

    if (alarmSeries?.signalIds?.length) {
      const mvQuery = new MVQueryDto(
        this._dateHelper.fromApiFormat(alarmSeries.startDate),
        this._dateHelper.fromApiFormat(alarmSeries.endDate),
        alarmSeries.signalIds?.length ? alarmSeries.signalIds : []
      );

      queries.push(mvQuery);
    }

    const cvQueries: CVShortQueryDto[] = [];
    Object.keys(alarmSeries.algorithms).forEach((shortName) => {
      const cvQuery = new CVShortQueryDto(
        this._dateHelper.fromApiFormat(alarmSeries.startDate),
        this._dateHelper.fromApiFormat(alarmSeries.endDate),
        alarmSeries.algorithms[shortName],
        [shortName]
      );
      cvQueries.push(cvQuery);
    });

    if (cvQueries.length) {
      queries.push(...cvQueries);
    }

    return queries;
  }

  private getCVGCartesianChartSerie(serie: CVChartSerieDto): Observable<GCartesianChartSerie> {
    const dataPoints = serie.points.map((point) => {
      const dataPoint: GChartSerieDataPoint = {
        pointCategory: this._dateHelper.fromApiFormat(point.referenceTimestamp),
        pointValue: point.value,
        color: this.getDataPointColorByEstimatedEdited(
          point.estimated
            ? EstimatedEditedEnum.Estimated
            : point.edited
            ? EstimatedEditedEnum.Edited
            : 0
        ),
        hasToShowSymbol: point.estimated || point.edited,
      };
      return dataPoint;
    });
    const showInUtc = this._cvService.showInUTC(serie);
    return this._uomService
      .getByParams(serie.dimensionTypeId, serie.timeAggregationId, serie.hierarchyElementTypeId)
      .pipe(
        map((unit) => {
          const cartesianChartSerie: GCartesianChartSerie = {
            dataPoints,
            name: serie.elementName + '-' + serie.algorithmShortName,
            type: ChartSerieTypeEnum.Line,
            yAxisWLMDimensionTypeId: serie.dimensionTypeId,
            yAxisWLMUnitTypeIdFrom: unit?.unitTypeFromId ?? this._naUnitTypeId,
            yAxisWLMUnitTypeIdTo: unit?.unitTypeToId ?? this._naUnitTypeId,
            yAxisName: unit?.unitTypeToDescription ?? '',
            xAxisWLMDimensionTypeId: DimensionTypesEnum.NA,
            largeData: true,
            showInUtc,
            showAlwaysInTooltip: serie.timeAggregationId == TimeAggregationEnum.Daily,
            showAlwaysFilterMethod: this.getShowAlwaysFilterMethod(serie.timeAggregationId),
          };

          return cartesianChartSerie;
        })
      );
  }

  private getMVGCartesianChartSerie(serie: MVChartSerieDto): Observable<GCartesianChartSerie> {
    const dataPoints = serie.points.map((point) => {
      const dataPoint: GChartSerieDataPoint = {
        pointCategory: this._dateHelper.fromApiFormat(point.measureTimestamp),
        pointValue: point.value,
        color: this.getDataPointColorByEstimatedEdited(point.estimatedEdited),
        hasToShowSymbol: Boolean(point.estimatedEdited),
      };
      return dataPoint;
    });

    return this._uomService
      .getByParams(serie.dimensionTypeId, this._timeAggregationForSignals)
      .pipe(
        map((unit) => {
          const cartesianChartSerie: GCartesianChartSerie = {
            dataPoints,
            name: serie.pointDescription,
            type: ChartSerieTypeEnum.Line,
            yAxisIndex: 0,
            xAxisIndex: 0,
            xAxisWLMDimensionTypeId: DimensionTypesEnum.NA,
            yAxisWLMDimensionTypeId: serie.dimensionTypeId,
            yAxisWLMUnitTypeIdFrom: unit?.unitTypeFromId ?? this._naUnitTypeId,
            yAxisWLMUnitTypeIdTo: unit?.unitTypeToId ?? this._naUnitTypeId,
            yAxisName: unit?.unitTypeToDescription ?? '',
            largeData: true,
            showInUtc: serie.isFlatten,
          };

          return cartesianChartSerie;
        })
      );
  }
}
