import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { TelemetryDimensionTypes } from '@common-modules/shared/constants/dimensions.constants';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { TelemetryGridFilterConfiguration } from '../../wlm-filters/telemetry-filter/telemetry-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-data-validation-filter';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-validation-filter.component.html',
  styleUrls: ['./data-validation-filter.component.scss'],
})
export class DataValidationFilterComponent implements OnInit {
  @Input() widgetId;
  @Input() reset$: Observable<void>;

  @Output() apply = new EventEmitter<DataBindingFilters>();

  filters: DataBindingFilters;

  configReady = false;
  autoLoad = true;
  canLoad = true;

  storageLocation = 'local';
  telemetryFilterConfiguration: TelemetryGridFilterConfiguration;
  clearAll$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  filterFields = ['hierarchyElementsIds'];

  selectedHierarchyElements: any;
  selectedHierarchyFamilyId: any;
  selectedDimensionTypeId: DimensionTypesEnum;
  selectedNetworkElementTypeIds: number[];

  // Filters fieldNames
  hierarchyElementIdFieldName = 'HierarchyElementId';
  hierarchyElementFamilyFieldName = 'HierarchyFamilyId';
  networkElementTypeIdFieldName = 'NetworkElementTypeId';
  dimensionTypeIdFieldName = 'DimensionTypeId';

  get componentName(): string {
    return 'DataValidationFilterComponent';
  }

  constructor(
    private _globalsService: GlobalsService,
    private _persistencyService: PersistencyService
  ) {}

  ngOnInit(): void {
    this.loadState();
    this.bindResetFilters();
  }

  setDataBindingFilters(filtersParameters: DataBindingFilters) {
    if (filtersParameters && this.validateFilters(this.filterFields, filtersParameters)) {
      this.filters = filtersParameters;
      this.canLoad = true;
    } else {
      this.canLoad = false;
    }
  }

  applyFilters() {
    if (this.canLoad) {
      this.apply.emit(this.filters);
      this.persistFilters$.next();
    }
  }

  setFiltersDetailsParameters(value: TabDetailPanelParameters) {
    if (this.autoLoad && this.canLoad) {
      this.applyFilters();
      this.autoLoad = false;
    }
  }

  onCheckAutoload(): void {
    this.applyFilters();
  }

  private loadState() {
    const useLocalStorage = this.storageLocation === 'local';

    // Persisted Dimension
    this.selectedDimensionTypeId = this._persistencyService.getPersisted(
      this.widgetId,
      this.dimensionTypeIdFieldName,
      undefined,
      false,
      useLocalStorage
    )?.value[0]?.value;

    const neTypes = this._persistencyService.getPersisted(
      this.widgetId,
      this.networkElementTypeIdFieldName,
      undefined,
      false,
      useLocalStorage
    )?.value[0]?.value;

    // Persisted NetworkElements
    if (neTypes !== undefined && neTypes !== null) {
      this.selectedNetworkElementTypeIds = [neTypes];
    }

    // Persisted HierarchyElements
    if (!this.selectedHierarchyElements) {
      this.selectedHierarchyElements = (
        this._persistencyService.getPersisted(
          this.widgetId,
          this.hierarchyElementIdFieldName,
          [{ value: '', label: '' }],
          false,
          useLocalStorage
        ) as BasicFilter
      )?.value;
    }

    // Persisted Family
    if (!this.selectedHierarchyFamilyId) {
      const persistedFamilyId = this._persistencyService.getPersisted(
        this.widgetId,
        this.hierarchyElementFamilyFieldName,
        undefined,
        false,
        useLocalStorage
      );
      if (persistedFamilyId) {
        this.selectedHierarchyFamilyId = (persistedFamilyId as BasicFilter)?.value?.shift()?.value;
      }
      this._globalsService
        .getDefaultHierarchyFamilyId(this.selectedHierarchyFamilyId)
        .subscribe((familyId) => {
          this.selectedHierarchyFamilyId ??= familyId;

          this.loadFilter();
        });
    } else {
      this.loadFilter();
    }
  }

  private loadFilter() {
    const configuration = new TelemetryGridFilterConfiguration({
      persistencyArea: this.widgetId,
      hierarchyElementIdFieldName: this.hierarchyElementIdFieldName,
      hierarchyElementFamilyFieldName: this.hierarchyElementFamilyFieldName,
      networkElementTypeIdFieldName: this.networkElementTypeIdFieldName,
      hierarchyElementId: this.selectedHierarchyElements,
      hierarchyElementFamily: this.selectedHierarchyFamilyId,
      neTypeMode: 'multiple',
      dimensionTypeIdFieldName: this.dimensionTypeIdFieldName,
      dimensionTypeId: this.selectedDimensionTypeId,
      networkElementTypeIds: this.selectedNetworkElementTypeIds,
      dimensionTypeIds: TelemetryDimensionTypes,
    });

    this.telemetryFilterConfiguration = configuration;
    this.configReady = true;
  }

  private validateFilters(filterFields: string[], filterParameters: DataBindingFilters): boolean {
    let filtersAreValid = true;
    filterFields.forEach((filterName) => {
      filtersAreValid = filtersAreValid && filterParameters?.filters?.get(filterName) !== undefined;
    });

    return filtersAreValid;
  }

  private bindResetFilters() {
    this.reset$.pipe(untilDestroyed(this)).subscribe(() => this.clearAll$.next());
  }
}
