import { Injectable } from '@angular/core';
import { ActivityCategoryType } from '@common-modules/dependencies/wlm-filters/i-filters/activity-category-type';
import { ActivityCategoryTypesEnum } from '@common-modules/dependencies/wlm-filters/i-filters/activity-category-types';
import { Observable, of } from 'rxjs';

@Injectable()
export class ActivityCategoryTypesService {
  constructor() {}

  public getActivityCategoryTypes(): Observable<ActivityCategoryType[]> {
    const activityCategoryTypes = [
      new ActivityCategoryType('Repair', ActivityCategoryTypesEnum.IsRepair),
      new ActivityCategoryType('Detection', ActivityCategoryTypesEnum.IsDetection),
    ];

    return of(activityCategoryTypes);
  }
}
