import { Injectable } from '@angular/core';
import { DefaultHistoricalValidationService } from '@common-modules/wlm-charts/core/services/default-historical-validations.service';
import { LargeUserSignalFlatDto } from '../../shared/model/signals/large-user-signal-flat.dto';
import { LargeUserSignalVersionDto } from '../../shared/model/signals/large-user-signal-version.dto';

@Injectable()
export class LargeUserHistoricalValidationService extends DefaultHistoricalValidationService<LargeUserSignalFlatDto> {
  validateCurrentConfiguration(
    config1: LargeUserSignalFlatDto,
    config2: LargeUserSignalFlatDto
  ): boolean {
    const isDifferentPointId = config1.pointId !== config2.pointId;
    const isDifferentLargeUserId = config1.largeUserId !== config2.largeUserId;

    if (
      (isDifferentPointId && isDifferentLargeUserId) ||
      (isDifferentPointId && !isDifferentLargeUserId) ||
      config1 === config2
    ) {
      return false;
    }

    return true;
  }

  getCurrentFromHistorical(config: LargeUserSignalVersionDto): LargeUserSignalFlatDto {
    if (!config) {
      return null;
    }
    const current: LargeUserSignalFlatDto = {
      propertyId: config.propertyId,
      hierarchyElementId: config.hierarchyElementId,
      hierarchyElementName: config.hierarchyElementName,
      customerId: config.customerId,
      largeUserId: config.largeUserId,
      organizationName: null,
      signalId: config.signalId,
      pointDescription: config.pointDescription,
      pointId: config.pointId,
    };
    return current;
  }
}
