import { Injectable, Injector } from '@angular/core';
import { GetCacheOpts } from '@common-modules/cache/http-cache/http-cache.client';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseChartService<T> extends BaseService {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector);
  }

  public abstract getData(params: any): Observable<any[]>;
  public abstract mapDataToGenericSettings(data: any): Observable<T>;
}
