import { WlmElementExtended } from '@common-modules/shared/charts/model/elements/element-extended';
import { IChartDataParameters } from './chart-data-parameters';

export class CustomWorkspaceChartDataParameters implements IChartDataParameters {
  startDate: Date;
  endDate: Date;

  workspaceName: string;
  elements: WlmElementExtended[];

  public constructor(init?: Readonly<CustomWorkspaceChartDataParameters>) {
    Object.assign(this, init);
  }
}
