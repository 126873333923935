import { Component, Inject, Injector, OnDestroy } from '@angular/core';
import { AlcAttributesDto } from '@common-modules/dependencies/alc/alc-attributes.dto';
import { DynamicFormHasChanges } from '@common-modules/dependencies/ne-configuration/dynamic-form-changes';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { IApplicationAttributeDto } from '@common-modules/dependencies/shared/model/application-attribute.dto';
import { DynamicFormAdditionalSettings } from '@common-modules/dynamic-forms/models/dynamic-form-additional-settings';
import { DynamicFormSourceSettings } from '@common-modules/dynamic-forms/models/dynamic-form-source-settings';
import { SettingsEntitySubtype } from '@common-modules/dynamic-forms/models/settings-entity-subtype';
import { SettingsEntityType } from '@common-modules/dynamic-forms/models/settings-entity-type';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { DynamicLayoutKeys } from '@common-modules/dynamic-layout/models/dynamic-layout-keys';
import {
  ResetHasChangesAction,
  SetAttributesSourceAction,
  SetFormAdditionalSettingsAction,
  SetFormDefinitionsAction,
  SetFormUIValuesAction,
} from '@common-modules/dynamic-layout/state/ne-config/ne-config.actions';
import { SetAttributesSourceSelector } from '@common-modules/dynamic-layout/state/ne-config/ne-config.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { applyOnActiveWidget } from '@common-modules/redux/operators/apply-on-active-widget.operator';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DynamicSettingsService } from '@common-modules/shared/config/dynamic-settings.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { AlgorithmAttributesIds } from '@common-modules/shared/model/algorithm/algorithm-attributes-ids';
import { AlgorithmAttributesDto } from '@common-modules/shared/model/algorithm/algorithm-attributes.dto';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'dexie';
import { Observable, forkJoin, of } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { AlcAttributesIds } from '../../../shared/model/alc/alc-attributes-ids';
import { ApplicationAttributesIds } from '../../../shared/model/application/application-attributes-ids';
import { HierarchyElementTypes } from '../../../shared/model/hierarchy/hierarchy-element-types';
import { ModeUnbilledMConsumption } from '../../../shared/model/water-balance/mode-unbilled-m-consumption';
import { necSelectNetworkElement } from '../../helpers/select-network-element';
import { AttributeFormGroupSettings } from '../../ne-config-attribute-form-group/attribute-form-group-settings';
import { AttributeFormGroupService } from './attribute-form-group.service';

const COMPONENT_SELECTOR = 'wlm-ne-config-attribute-form-group-widget';
export const NEC_ATTR_FORM_GROUP_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-config-attribute-form-group-widget.component.html',
  styleUrls: ['./ne-config-attribute-form-group-widget.component.scss'],
  providers: [ReduxStateService, AttributeFormGroupService],
})
export class NeConfigAttributeFormGroupWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnDestroy
{
  selectedNE: INetworkElementDto;
  attributeFormGroupSettings: AttributeFormGroupSettings;
  attrSource$: Subscription;
  setLoading: (loading: boolean) => void;
  readonly T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });
  private _formsLayoutKeys: DynamicLayoutKeys;
  private _previousAttributesSource: DynamicFormSourceSettings;
  private _formAdditionalSettings: DynamicFormAdditionalSettings;

  private readonly _mNFPercentageAlgorithmsEnabledId = 420;
  private readonly _categoriesLabelsPath = `${AppModules.Configuration}.wlm-ne-config-attribute-form-group.forms`;
  private readonly _attributesLabelsPath = `${AppModules.Configuration}.attributes`;
  private readonly _activityTranslateKey = `${AppModules.Configuration}.formats.activity-duration`;
  private readonly _activityExpression = 'ACT(\\d+)';
  private readonly _entityNameKey = 'common.entities.configuration';

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private readonly _globalsService: GlobalsService,
    private readonly _attributeFormGroupService: AttributeFormGroupService,
    private readonly _spinnerService: SpinnerService,
    private readonly _dynamicSettingsService: DynamicSettingsService
  ) {
    super(injector, widgetSettings);
    this._formsLayoutKeys = widgetSettings.params.innerLayoutKeys;
    this.setLoading = this._spinnerService.buildSetLoadingFn();

    this._attributeFormGroupService.init({
      state: this._state,
      scopeSettings: this._scopeSettings,
    });
  }

  onWidgetInit(): void {
    necSelectNetworkElement(
      this,
      this._state,
      this._scopeSettings,
      (selectedNE: INetworkElementDto) => this.setNewNetworkElement(selectedNE)
    );

    this._state
      .select<DynamicFormSourceSettings>(new SetAttributesSourceSelector(this._scopeSettings))
      .pipe(
        untilDestroyed(this),
        applyOnActiveWidget(this, (inputSource: DynamicFormSourceSettings) => {
          if (inputSource) {
            const source: DynamicFormSourceSettings =
              globalUtilsHelper.serializedClone(inputSource);
            this.buildFormFromSource(source);
          }
        })
      )
      .subscribe();
  }

  private setNewNetworkElement(selectedNE: INetworkElementDto) {
    this.dispatchResetHasChanges();

    if (selectedNE) {
      this.setLoading(true);
    }

    this.selectedNE = selectedNE;
    this.buildAdditionalSettings();

    if (this.attrSource$) {
      this.attrSource$.unsubscribe();
    }

    if (this.selectedNE) {
      this.attrSource$ = this.buildAttributesSource(selectedNE).subscribe((attributesSource) => {
        this.attrSource$ = null;
        this.buildFormFromSource(attributesSource);
      });
    } else {
      this.buildFormFromSource(null);
    }
  }

  private buildAttributesSource(
    selectedNE: INetworkElementDto
  ): Observable<DynamicFormSourceSettings> {
    let entityType;
    let elementTypeId;
    if (typeof selectedNE.hierarchyElementTypeId !== 'undefined') {
      entityType = SettingsEntityType.HierarchyElement;
      elementTypeId = selectedNE.hierarchyElementTypeId;
    } else {
      entityType = SettingsEntityType.NetworkElement;
      elementTypeId = selectedNE.networkElementTypeId;
    }

    // Avoid building source again if same entity type and id are being requested.

    const entitySubtypes = this._globalsService.buildEntitySubtypesFromNetworkElement(selectedNE);
    const attributesSourceKey = this.buildSourceKey(entityType, elementTypeId, entitySubtypes);

    if (this._previousAttributesSource?.sourceKey === attributesSourceKey) {
      return of(this._previousAttributesSource);
    }

    return this.buildFormState({
      isZone: selectedNE.isZone,
      hierarchyElementTypeId: selectedNE.hierarchyElementTypeId,
      isMerging: selectedNE.isMerging,
    }).pipe(
      map((formState) => {
        const source = new DynamicFormSourceSettings({
          sourceKey: attributesSourceKey,
          entityType,
          elementTypeId,
          entitySubtypes,
          categoriesLabelsPath: this._categoriesLabelsPath,
          attributesLabelsPath: this._attributesLabelsPath,
          formState,
          labelOverwrites: [
            {
              onRegex: this._activityExpression,
              useKey: this._activityTranslateKey,
              labelParam: 'activity',
            },
          ],
        });

        this._previousAttributesSource = source;
        return source;
      })
    );
  }

  private buildSourceKey = (
    entityType: string,
    elementTypeId: string,
    subtypes: SettingsEntitySubtype[]
  ) => {
    const result = `${entityType}.${elementTypeId}.${subtypes.join('.')}`;
    return result;
  };

  /**
   * This method is not called twice for the same entityType and elementTypeId,
   * so input properties should NOT have different values for the same entityType and elementTypeId.
   * IE, networkElementName could not be injected here, but in additionalSettings.
   */
  private buildFormState(data: {
    isZone: boolean;
    hierarchyElementTypeId?: string;
    isMerging: boolean;
  }): Observable<any> {
    return forkJoin([
      this._globalsService.getAlgorithmAttributes().pipe(take(1)),
      this._globalsService.getAlcAttributes().pipe(take(1)),
      this._globalsService.getApplicationAttributesById(
        ApplicationAttributesIds.HierarchyElementPressureType
      ),
    ]).pipe(
      take(1),
      map(([algorithmAttributes, alcAttributes, hierarchyElementPressureTypeDto]) => {
        const getAlgorithmAttributeFn = this.getAlgorithmAttributeValue(algorithmAttributes);

        const globalLNUAttributesSelector = algorithmAttributes.find(
          (aa) => aa.algorithmAttributeId === this._mNFPercentageAlgorithmsEnabledId
        )?.algorithmAttributeValue;
        const hierarchyLevel = data.hierarchyElementTypeId?.toUpperCase();

        const formState = {
          isZone: data.isZone,
          isMerging: data.isMerging,
          hierarchyLevel,
          technicianAssignedHierarchyId: this.getAlcAttributeValue(alcAttributes)(
            AlcAttributesIds.TechnicianAssignedHierarchyId
          ),
          DMAHierarchyType: HierarchyElementTypes.DMA,
          modeUnbilledMConsumption: {
            BillingSystem: ModeUnbilledMConsumption.BillingSystem,
          },
          modeBilledUConsumption: {
            BillingSystem: ModeUnbilledMConsumption.BillingSystem,
          },
          manualParametersHierarchyLevel: getAlgorithmAttributeFn(
            AlgorithmAttributesIds.ManualParametersHierarchyLevel
          ),
          confidenceLevelHierarchyLevel: getAlgorithmAttributeFn(
            AlgorithmAttributesIds.ConfidenceLevelHierarchyLevel
          ),
          energyCostVolumeHierarchyLevel: getAlgorithmAttributeFn(
            AlgorithmAttributesIds.EnergyCostVolumeHierarchyLevel
          ),
          hierarchyElementPressureType: this.getApplicationAttributeValue(
            hierarchyElementPressureTypeDto
          ),
          globalLNUAttributesSelector,
          isConfiguredPrioritisationHierarchyLevel: this.isConfiguredPrioritisationHierarchyLevel(
            hierarchyLevel,
            getAlgorithmAttributeFn
          ),
          isDMA: hierarchyLevel === HierarchyElementTypes.DMA,
        };

        return formState;
      })
    );
  }

  private isConfiguredPrioritisationHierarchyLevel(
    hierarchyLevel,
    getAlgorithmAttributeFn
  ): boolean {
    const prioritisationHierarchyLevel = getAlgorithmAttributeFn(
      AlgorithmAttributesIds.PrioritisationHierarchyLevel
    );

    return hierarchyLevel?.toUpperCase() === prioritisationHierarchyLevel?.toUpperCase();
  }

  private buildFormFromSource(source: DynamicFormSourceSettings): void {
    if (source) {
      source.entityNameKey = this._entityNameKey;
      this._attributeFormGroupService
        .dispatchFormGroup(source, this._formAdditionalSettings)
        .pipe(finalize(() => this.setLoading(false)))
        .subscribe(({ isCached, visibleCategories }) => {
          if (!isCached || !this.attributeFormGroupSettings) {
            this.attributeFormGroupSettings = {
              categories: visibleCategories,
              dynamicLayoutKeys: this._formsLayoutKeys,
            };
          }
        });
    } else {
      this.attributeFormGroupSettings = null;
    }
  }

  private buildAdditionalSettings(): void {
    if (this.selectedNE) {
      this._formAdditionalSettings = {
        entityId: this.selectedNE.elementId,
        entityName: this.selectedNE.networkElementName,
      };
    } else {
      this._formAdditionalSettings = null;
    }
  }

  private getAlgorithmAttributeValue = (algorithmAttributes: AlgorithmAttributesDto[]) => {
    return (id: AlgorithmAttributesIds) => {
      let value = algorithmAttributes.find(
        (aa) => aa.algorithmAttributeId === id
      )?.algorithmAttributeValue;
      if (typeof value === 'string') {
        value = value.toUpperCase();
      }
      return value;
    };
  };

  private getAlcAttributeValue = (alcAttributes: AlcAttributesDto[]) => {
    return (id: AlcAttributesIds) => {
      let value = alcAttributes.find((aa) => aa.attributeId === id)?.attributeValue;
      if (typeof value === 'string') {
        value = value.toUpperCase();
      }
      return value;
    };
  };

  private getApplicationAttributeValue = (appAttributes: IApplicationAttributeDto) => {
    let value = appAttributes?.attributeValue;
    if (typeof value === 'string') {
      value = value.toUpperCase();
    }
    return value;
  };

  private dispatchResetHasChanges(): void {
    this._state.dispatch(
      new ResetHasChangesAction(new DynamicFormHasChanges({ changes: {} }), this._scopeSettings)
    );
  }

  get componentName(): string {
    return 'NeConfigAttributeFormGroupWidgetComponent';
  }

  private resetAllActions(): void {
    const actions = [
      new SetAttributesSourceAction(null, this._scopeSettings),
      new SetFormDefinitionsAction(null, this._scopeSettings),
      new SetFormUIValuesAction(null, this._scopeSettings),
      new SetFormAdditionalSettingsAction(null, this._scopeSettings),
    ];

    for (let action of actions) {
      this._state.dispatch(action);
    }
  }

  ngOnDestroy(): void {
    this.resetAllActions();
  }
}
