import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoginOptions, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { SettingsService } from '../../config/settings.service';
import { GlobalsService } from '../../services/globals.service';
import { LicenceModules } from '../models/licence-modules.enum';
import { User } from '../models/user';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userSubject: BehaviorSubject<User>;
  private refreshTokenTimeout;
  public get userValue(): User {
    return this.userSubject.value;
  }

  public userCode;

  public get email(): string {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['email'];
  }
  public user: Observable<User>;

  private _accessToken: string;
  private _accessToken$ = new ReplaySubject<string>(1);
  readonly accessToken$ = this._accessToken$.asObservable();
  tenantName: string;

  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    private settingsService: SettingsService,
    private _globalsService: GlobalsService,
    private readonly _location: Location,
    private readonly _route: ActivatedRoute
  ) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.user = this.userSubject.asObservable();

    const token = this.oauthService.getAccessToken();
    if (token) {
      this._accessToken$.next(token);
      this._accessToken = token;
      this.getUserCode();
    } else {
      this.oauthService.events
        .pipe(
          untilDestroyed(this),
          filter((event) => {
            const result = Boolean(
              ['discovery_document_loaded', 'token_received'].find((type) => type === event.type)
            );
            return result;
          }),
          map((_) => {
            const token = this.oauthService.getAccessToken();
            return token;
          }),
          filter((token) => token !== null),
          take(1)
        )
        .subscribe((token) => {
          this._accessToken$.next(token);
          this._accessToken = token;

          const tenantId = this.oauthService.getIdentityClaims()['tenantid'];
          if (tenantId) {
            this.getTenantName(tenantId).subscribe((res) => {
              this.tenantName = res;
            });
          }

          this.getUserCode();
        });
    }
  }

  private getTenantName(tenantId: string): Observable<any> {
    return this.http.get(
      `${this.settingsService.apis.default.url}/api/abp/multi-tenancy/tenants/by-id/${tenantId}`
    );
  }

  customLoadDiscoveryDocumentAndTryLogin(): Promise<boolean> {
    if (this.oauthService.hasValidAccessToken()) {
      return new Promise(() => true);
    }

    this.oauthService.configure(this.settingsService.oAuthConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    const queryString = window.location.search;
    const options: LoginOptions = {
      customHashFragment: queryString ? `#${queryString}` : null,
    };
    return this.oauthService.loadDiscoveryDocumentAndLogin(options);
  }

  logout(beforeLogoutFn?: () => any) {
    const isLogged = this.oauthService.hasValidAccessToken();
    if (isLogged) {
      console.log(isLogged);
    }

    if (beforeLogoutFn) {
      beforeLogoutFn();
    }

    this.oauthService
      .revokeTokenAndLogout(false)
      .catch((exception) => {
        console.error(exception);
        this.oauthService.logOut(false);
      })
      .then((_) => {
        this.oauthService.logOut(false);
      });
  }

  getBearerToken(): Observable<string> {
    return this.accessToken$.pipe(
      map((token) => {
        return `Bearer ${token}`;
      })
    );
  }

  mustLogIn(): boolean {
    return !this.oauthService.hasValidAccessToken() && !this.isOauthCallbackRoute();
  }

  getAuthRedirectUri(): string {
    const fragments = this.settingsService.oAuthConfig.redirectUri.split('/');
    return fragments[fragments.length - 1];
  }

  isOauthCallbackRoute(urlPath?: string): boolean {
    const path = urlPath ?? this._location.path();
    const params = this._route.snapshot.queryParamMap;
    const noParamsRoute = path.split('?')[0];
    return (
      path.includes(this.getAuthRedirectUri()) ||
      ((noParamsRoute === '' || noParamsRoute === '/') && params.has('code') && params.has('state'))
    );
  }

  // Only use after being sure that token initialization has been performed.
  get currentToken(): string {
    return this._accessToken;
  }

  private refreshToken() {
    return this.http
      .post<any>(
        `${this.settingsService.apis.default.url}/users/refresh-token`,
        {},
        { withCredentials: true }
      )
      .pipe(
        map((user) => {
          this.userSubject.next(user);
          this.startRefreshTokenTimer();
          return user;
        })
      );
  }

  /**
   * Ensures that the access token is available. If not, performs login again.
   */
  ensureTokenAvailable(): void {
    if (!this.oauthService.hasValidAccessToken()) {
      this.customLoadDiscoveryDocumentAndTryLogin();
    }
  }

  getEnabledModulesFromToken() {
    //TODO: [Pending] inspect token to extract enabled modules by licence and returns it as an array of LicenceModules
    const data = this.oauthService.getAccessToken();
    const jwtToken = JSON.parse(atob(data.split('.')[1]));

    const modules = [LicenceModules.ALC, LicenceModules.BI, LicenceModules.Pressure];
    return modules;
  }

  private getUserCode() {
    this._globalsService.getCurrentUser().subscribe((user) => {
      this.userCode = user?.userCode;
    });
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
