<wlm-signal-historical-form
  *ngIf="settings && formModel"
  [settings]="settings"
  [formModel]="formModel"
  [additionalFieldsTemplate]="additionalFields"
  (formChanges)="onFormChange($event)"
  (canSave)="onCanSave($event)"
  (canReset)="onCanReset($event)"
></wlm-signal-historical-form>

<ng-template #additionalFields>
  <div class="additional-fields-container">
    <mat-form-field class="mat-field customer-class-type-field">
      <mat-label>{{ T_SCOPE + '.pressure-type' | translate }}</mat-label>
      <input type="text" matInput [formControl]="pressureType" autocomplete="off" />
    </mat-form-field>

    <mat-form-field
      class="mat-field customer-class-type-field"
      *ngIf="formModel && formModel.offset !== null && formModel.offset !== undefined"
    >
      <mat-label>{{ T_SCOPE + '.displacement-type' | translate }}</mat-label>
      <input type="number" matInput [formControl]="offset" autocomplete="off" />
    </mat-form-field>
  </div>
</ng-template>
