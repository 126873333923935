import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable, startWith } from 'rxjs';
import { MaterialSharedModule } from '../../shared/material-shared.module';
import { SelectOption } from '../../shared/model/shared/select-option';

@Component({
  selector: 'wlm-chips-selector',
  templateUrl: './chips-selector.component.html',
  styleUrl: './chips-selector.component.scss',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MaterialSharedModule, TranslateModule],
  host: {
    class: 'wlm-chips-input',
  },
})
export class ChipsSelectorComponent implements OnInit {
  private _options: SelectOption<string>[];
  get options(): SelectOption<string>[] {
    return this._options ?? [];
  }
  @Input() set options(value: SelectOption<string>[]) {
    this._options = value;
    this.buildSelectedOptions();
  }

  private _selectedValues: string[];
  get selectedValues(): string[] {
    return this._selectedValues ?? [];
  }
  @Input() set selectedValues(value: string[]) {
    this._selectedValues = value;
    this.buildSelectedOptions();
  }

  private _disabled: boolean;
  get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    setTimeout(() => {
      this.disabled ? this.selectControl.disable() : this.selectControl.enable();
    });
  }

  @Output() valueChanged = new EventEmitter<string[]>();

  selectedOperator: string;
  selectControl = new FormControl();
  filterString: string = '';
  filteredOptions$: Observable<any[]>;
  selectedOptions: SelectOption<string>[];

  ngOnInit(): void {
    this.filteredOptions$ = this.selectControl.valueChanges.pipe(
      startWith<string>(''),
      map((value) => (typeof value === 'string' ? value : this.filterString)),
      map((filter) => this.filterOptionsBySearch(filter)),
      map((options) => this.filterOptionsBySelected(options))
    );
  }

  displayFn = (): string => '';

  removeChip(event: Event, element: SelectOption<string>): void {
    event.stopPropagation();
    this.toggleSelection(element);
  }

  toggleSelection(option: SelectOption<string>): void {
    if (!this.isSelected(option)) {
      this.selectedOptions.push(option);
    } else {
      const i = this.selectedOptions.findIndex((e) => e.value === option.value);
      this.selectedOptions.splice(i, 1);
    }

    this.selectControl.setValue(this.selectedOptions);

    this.valueChanged.emit(this.selectedOptions.map((option) => option.value));
  }

  optionClicked(event: Event, element: SelectOption<string>): void {
    event.stopPropagation();
    this.toggleSelection(element);
  }

  isSelected = (element: SelectOption<string>) =>
    this.selectedOptions.some((e) => e.value === element.value);

  private buildSelectedOptions(): void {
    this.selectedOptions = this.options.filter((f) => this.selectedValues.includes(f.value));
  }

  private filterOptionsBySearch(value: string): SelectOption<string>[] {
    this.filterString = value;
    if (value) {
      const lcValue = value.toLowerCase();
      return this.options.filter((option) => option.value.toLowerCase().includes(lcValue));
    } else {
      return this.options.slice();
    }
  }

  private filterOptionsBySelected = (
    filteredOptions: SelectOption<string>[]
  ): SelectOption<string>[] =>
    filteredOptions.filter(
      (option) =>
        !this.selectedOptions.find((selectedOption) => selectedOption.value === option.value)
    );
}
