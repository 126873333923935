// prettier-ignore
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
// prettier-ignore
import { TabDetailPanelParameters, TabDetailPanelSettings, TabDetailParameterName } from '@common-modules/dependencies/navigation/tab-detail-component';
import { DynamicLayoutBuilderService } from '@common-modules/dynamic-layout/layouts/dynamic-layout-builder-service';
import { SimpleDynamicLayoutSettings } from '@common-modules/dynamic-layout/layouts/simple-dynamic-layout-settings';
import { SimpleDynamicLayoutSettingsItem } from '@common-modules/dynamic-layout/layouts/simple-dynamic-layout-settings-item';
import { DynamicLayoutLevels } from '@common-modules/dynamic-layout/models/dynamic-layout-levels';
import { DynamicLayoutSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-settings';
import { DynamicLayoutSettingsLoadOptions } from '@common-modules/dynamic-layout/models/dynamic-layout-settings-load-options';
import { GoldenLayoutService } from '@common-modules/dynamic-layout/services/golden-layout.service';
import { SetValueAction } from '@common-modules/dynamic-layout/state/generic/generic.actions';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { TabPanelFields } from '@common-modules/shared/component/base-widget.component';
import { LabelValueListItem } from '@common-modules/shared/core/label-value-list/label-value-list-item';
import { StylizedTitleSidePill } from '@common-modules/shared/core/right-panel/title-side-pill';
import { ComponentHelperService } from '@common-modules/shared/helpers/component-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { RightPanelService } from '@common-modules/shared/navigation/right-panel.service';
import { IPendingChangesChecker } from '@common-modules/shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { RPButtonWithDetails } from '../../rp-button-with-details';

const COMPONENT_SELECTOR = 'wlm-gl-detail-panel';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './gl-detail-panel.component.html',
  styleUrls: ['./gl-detail-panel.component.scss'],
  providers: [GoldenLayoutService],
})
export class GlDetailPanelComponent
  implements OnInit, AfterViewInit, OnDestroy, IPendingChangesChecker
{
  parameters: TabDetailPanelParameters;
  // Key used for checking the pending changes.
  pageId: string;
  refreshDebounceTime = 300;
  private _isLoading = true;
  public get isLoading() {
    return this._isLoading;
  }
  public set isLoading(value) {
    this._isLoading = value;
    this._cd.detectChanges();
  }
  private titleEmptyValue = '';
  title$: BehaviorSubject<string> = new BehaviorSubject<string>(this.titleEmptyValue);
  subtitle = null;
  titleSidePills: string[] = [];
  stylizedTitleSidePills: StylizedTitleSidePill[] = [];
  topAttributes: LabelValueListItem[] = [];
  topValues: { [key: string]: any } = null;
  fullElementName = '';
  maxTitleLength = 50;
  additionalContainerClass: string;
  buttonWithDetails: RPButtonWithDetails[] = [];
  layoutSettingsLoadOptions: DynamicLayoutSettingsLoadOptions;
  isSelectedElement = false;
  readonly specificLevel = DynamicLayoutLevels.Main;
  // Flag that helps with race condition between _updateTitle and _localization.
  private titleInitialized = false;
  readonly T_SCOPE = `${AppModules.RightPanel}.${COMPONENT_SELECTOR}`;

  // Persistency keys are different for each page, as each page saves state of its current RP.
  private readonly _persistencyArea = 'DynamicLayout_TabPanel';
  private _persistencyPage: string;
  // Although persistency is different for each page, all pages listen to the same RP state
  private readonly _stateModule = this._persistencyArea;
  private readonly _statePage = this._stateModule;

  private readonly _scopeInstanceKeys = {
    [StateAreas.Generic]: 'shared',
  };

  constructor(
    private readonly _navigationPanelService: RightPanelService,
    private readonly _localization: LocalizationHelperService,
    private readonly _cd: ChangeDetectorRef,
    private readonly _pendingChangesService: PendingChangesManagerService,
    private readonly _dynamicLayoutBuilderService: DynamicLayoutBuilderService,
    private readonly _state: ReduxStateService
  ) {
    this._localization.get(this.T_SCOPE).subscribe((ts) => {
      this.titleEmptyValue = ts['title-empty-value'];
      if (!this.titleInitialized) {
        // Only set the default title if the real title is not already set.
        this.title$.next(this.titleEmptyValue);
      }
    });
    // Listen to page changes.
    this._navigationPanelService
      .currentPageKeyObservable()
      .pipe(untilDestroyed(this))
      .subscribe((pageKey) => (this._persistencyPage = pageKey));

    this.configureStateService();

    this._navigationPanelService
      .currentPageIdObservable()
      .pipe(untilDestroyed(this))
      .subscribe((pageId) => {
        this.pageId = pageId;
        this.dispatchEmpty();
      });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this._navigationPanelService
      .tabSettingObservable()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (panelSettings: TabDetailPanelSettings) => {
          if (panelSettings && panelSettings.components.length) {
            this.buildLayoutFromPanelSettings(panelSettings);
          } else {
            this.reset();
          }
        },
      });

    this._navigationPanelService
      .tabParameterObservable()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (parameters) => {
          this.parameters = parameters;
          // When new parameters are set, all components will receive them.
          const forceRefresh = true;
          this.updateComponentParameter(forceRefresh);
          this.updatePanelFeatures();
        },
      });

    if (this.tabSettings?.components.length) {
      this.buildLayoutFromPanelSettings(this.tabSettings);
    }
  }

  init() {}

  private updatePanelFeatures(): void {
    this.checkSelectedElement();
    this.updateTitle();
    this.updateSubtitle();
    this.updateTitleSidePills();
    this.updateTitleSidePillsWithStyle();
    this.updateTopAttributes();
    this.updateTopValues();
    this.updateContainerClass();
    this.updateButtonWithDetails();
  }

  private checkSelectedElement(): void {
    this.isSelectedElement = !!this.parameters?.parameters.get(TabDetailParameterName.elementName);
  }

  private buildLayoutFromPanelSettings(
    panelSettings: TabDetailPanelSettings,
    disablePersistencyLoad = false
  ): void {
    this.isLoading = true;
    if (!panelSettings || !this._navigationPanelService.hasRightPanel) {
      this.isLoading = false;
      return;
    }

    const simpleSettings: SimpleDynamicLayoutSettings = {
      // Each page has a differently persisted RP layout (the pageKey changes)
      layoutArea: this._persistencyArea,
      layoutKey: this._persistencyPage,
      scopeInstanceKeys: this._scopeInstanceKeys,
      items: panelSettings.components.map((component) => {
        const widgetName = ComponentHelperService.getWidgetName(component);
        const simpleItem: SimpleDynamicLayoutSettingsItem = {
          widgetName,
          title: panelSettings.titlesByKey.get(widgetName),
        };
        return simpleItem;
      }),
    };

    this._dynamicLayoutBuilderService
      .build(simpleSettings)
      .pipe(
        map((settings: DynamicLayoutSettings) => {
          // Although all pages have different persistency keys, they all have the same redux path.
          settings.widgetModule = this._stateModule;
          settings.widgetPage = this._statePage;

          const settingsLoadOptions: DynamicLayoutSettingsLoadOptions = {
            settings,
            loadOptions: {
              disablePersistencyLoad,
            },
          };
          return settingsLoadOptions;
        })
      )
      .subscribe((settingsLoadOptions) => {
        this.layoutSettingsLoadOptions = settingsLoadOptions;
        this.isLoading = false;
      });
  }

  private configureStateService(): void {
    this._state.configure(
      new StateWidgetSettings({
        module: this._stateModule,
        page: this._statePage,
        scopeInstanceKeys: this._scopeInstanceKeys,
      })
    );
  }

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }

  private reset() {
    this.title$.next(this.titleEmptyValue);
    this.parameters?.parameters?.clear();
    this.layoutSettingsLoadOptions = null;
    this._cd.detectChanges();
    this.isLoading = false;
  }

  private updateTitle() {
    const elementNameKey = TabDetailParameterName.elementName;
    this.fullElementName = '';
    let elementName = this.parameters?.parameters.get(elementNameKey) ?? this.titleEmptyValue;

    if (elementName === false) {
      elementName = '';
    } else if (elementName === '') {
      elementName = this.titleEmptyValue;
    }

    if (elementName.length > this.maxTitleLength) {
      const startIndex = elementName.indexOf(':') + 1;
      this.fullElementName = elementName.slice().substring(startIndex, elementName.length);
      elementName = `${elementName.substring(0, this.maxTitleLength)}...`;
    }

    this.title$.next(elementName);
    if (this.titleEmptyValue !== '') {
      this.titleInitialized = true;
    }
  }

  private updateSubtitle(): void {
    const subtitleKey = TabDetailParameterName.subtitle;
    this.subtitle = this.parameters?.parameters.get(subtitleKey) ?? null;
  }

  private updateTitleSidePills(): void {
    const sidePillsKey = TabDetailParameterName.titleSidePills;
    this.titleSidePills = this.parameters?.parameters.get(sidePillsKey) ?? [];
  }

  private updateTitleSidePillsWithStyle(): void {
    const sidePillsKey = TabDetailParameterName.stylizedTitleSidePills;
    this.stylizedTitleSidePills = this.parameters?.parameters.get(sidePillsKey) ?? [];
  }

  private updateTopAttributes(): void {
    const topAttributesKey = TabDetailParameterName.topAttributes;
    this.topAttributes = this.parameters?.parameters.get(topAttributesKey) ?? null;
  }

  private updateTopValues(): void {
    const topValuesKey = TabDetailParameterName.topValues;
    this.topValues = this.parameters?.parameters.get(topValuesKey) ?? null;
  }

  private updateContainerClass(): void {
    const containerClass = TabDetailParameterName.containerClass;
    this.additionalContainerClass = this.parameters?.parameters.get(containerClass) ?? null;
  }

  private updateButtonWithDetails(): void {
    const buttonWithDetails = TabDetailParameterName.buttonWithDetails;
    this.buttonWithDetails = this.parameters?.parameters.get(buttonWithDetails) ?? null;
  }

  onLayoutResized(): void {
    this._navigationPanelService.setResized(null);
  }

  /**
   * Apply parameters to current components.
   * If a component is currently open but it was opened previously, do not apply parameters again,
   * because they must have been applied earlier.
   */
  private updateComponentParameter(forceRefresh = false) {
    this.dispatchEmpty();

    if (this.parameters) {
      this.dispatchParams(this.parameters);
    }
  }

  private dispatchParams(serialized: TabDetailPanelParameters): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: TabPanelFields.ParamsKeys,
        value: serialized,
      })
    );
  }

  private dispatchEmpty(): void {
    this.dispatchParams(null);
  }

  /**
   * Layouts have a tree structure, so we obtain all active components recursively.
   */
  private setActiveComponentsRecursive(node): any[] {
    if (!node) {
      return [];
    }
    const { contentItems, activeContentItem$ } = node;
    if (activeContentItem$) {
      return activeContentItem$ ? [activeContentItem$?.value] : [];
    }
    return contentItems.reduce((result, item) => {
      result = result.concat(this.setActiveComponentsRecursive(item));
      return result;
    }, []);
  }

  private get tabSettings() {
    return this._navigationPanelService.TabSettings;
  }

  /**
   * Return the layout to its original state (rebuild it).
   */
  backToInitialState(): void {
    this.isLoading = true;

    this.checkPendingChanges(this.pageId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          const forceRefresh = true;
          this.buildLayoutFromPanelSettings(this.tabSettings, forceRefresh);
          this.isLoading = false;
        });
      });
  }

  ngOnDestroy(): void {
    this.dispatchEmpty();
  }
}
