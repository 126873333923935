import { NotificationDto } from '@common-modules/shared/model/notifications/notification.dto';
import { CommentDto } from '../../../comments/models/comment.dto';

export class NotificationDetailFormResult {
  notification: NotificationDto;
  comment?: CommentDto;

  constructor(init: NotificationDetailFormResult) {
    Object.assign(this, init);
  }
}
