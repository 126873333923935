import { AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FilterItemSelectOption } from '@common-modules/common-filters/models/filter-item-select-option';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../../core/base-select-filter-item/base-select-filter-item.component';

const COMPONENT_SELECTOR = 'wlm-dimension-types-filter-item';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './dimension-types-filter-item.component.html',
  styleUrls: ['./dimension-types-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => DimensionTypesFilterItemComponent),
    },
  ],
})
export class DimensionTypesFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() fieldName: string;
  @Input() filterText: string;
  @Input() hiddenTypeIds: any[] = [];
  @Input() includeOnlyTypeIds: any[] = [];
  @Input() includeNA = false;
  @Input() set selectedId(value: any) {
    this.selectedIds = this.ensureArray(value);
  }
  @Input() set defaultSelectedId(value: any) {
    this.defaultSelectedIds = this.ensureArray(value);
  }

  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);

  mode = 'single';
  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;
  defaultSelectedIds: any[] = [];

  constructor(
    private globalsService: GlobalsService,
    private localizationService: LocalizationHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.data$$.next(this.getData$());
    super.onInit();
  }

  getData$(): Observable<FilterItemSelectOption[]> {
    const excluded = this.hiddenTypeIds ?? [];
    if (!this.includeNA) {
      excluded.push(0);
    }

    return this.globalsService.getDimensionTypes().pipe(
      switchMap((items) => {
        return this.localizationService.get('common.dimension-types').pipe(
          map((ts) => {
            const itemsFiltered = this.includeOnlyTypeIds?.length
              ? items.filter((x) => this.includeOnlyTypeIds.includes(x.dimensionTypeId))
              : items.filter((x) => !excluded.includes(x.dimensionTypeId));

            return itemsFiltered.map(
              (item) =>
                new FilterItemSelectOption(
                  item.dimensionTypeId,
                  ts[item.dimensionTypeDescription.toLowerCase()]
                )
            );
          })
        );
      })
    );
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  // To be valid, at least one item must be selected.
  isValid(): boolean {
    return this.isValidSelect;
  }

  ensureArray(value: any): any[] {
    if (typeof value !== 'undefined' && value !== null) {
      return Array.isArray(value) ? [...value] : [value];
    } else {
      return [];
    }
  }

  getFieldNames(): string[] {
    return [this.fieldName];
  }

  getAdapter(): FilterAdapterEnum {
    return FilterAdapterEnum.Dimension;
  }
}
