import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { AlarmNameSegment } from '../../shared/model/alarms/alarm-name-part';

const COMPONENT_SELECTOR = 'wlm-alarm-name-generator';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarm-name-generator.component.html',
  styleUrls: ['./alarm-name-generator.component.scss'],
})
export class AlarmNameGeneratorComponent implements OnInit, AfterViewInit {
  @ViewChild('suffixInput') suffixInput: ElementRef;

  private _resetHandler$: Observable<boolean>;
  public get resetHandler$(): Observable<boolean> {
    return this._resetHandler$;
  }
  @Input()
  public set resetHandler$(value: Observable<boolean>) {
    this._resetHandler$ = value;
    if (value) {
      this.subscribeToResetWizard(this.resetHandler$);
    }
  }
  @Input() resetCompleteHandler$: ReplaySubject<void>;

  private _alarmNameSegments: AlarmNameSegment[];
  public get alarmNameSegments(): AlarmNameSegment[] {
    return this._alarmNameSegments;
  }

  @Input() public set alarmNameSegments(value: AlarmNameSegment[]) {
    if (value) {
      this._alarmNameSegments = value;
      this.restore();
    }
  }
  @Input() maxInputLength: number = 10;

  @Output() alarmNameSegmentsChange = new EventEmitter<AlarmNameSegment[]>();

  @Output() subscriptionChange = new EventEmitter<boolean>();

  T_SCOPE = `${AppModules.Alarms}.${COMPONENT_SELECTOR}`;
  alarmSegments: AlarmNameSegment[];
  disableInput = false;
  placeHolderKey = `${this.T_SCOPE}.suffix-placeholder`;
  placeHolderText: string;
  selectedFormatLabelKey = `${this.T_SCOPE}.selected-format`;

  constructor() {}
  ngAfterViewInit(): void {}

  ngOnInit(): void {}

  restore() {
    this.alarmSegments?.splice(0, this.alarmSegments?.length);
    this.alarmSegments = this.alarmNameSegments.slice();
    this.disableInputText(false);
    this.notifyChanges();
  }

  public disableInputText(mustDisable: boolean) {
    this.disableInput = mustDisable;
    this.placeHolderText = mustDisable ? '' : this.placeHolderKey;
    if (this.suffixInput) {
      this.suffixInput.nativeElement.value = '';
    }
  }

  drop(event: CdkDragDrop<AlarmNameSegment[]>) {
    moveItemInArray(this.alarmSegments, event.previousIndex, event.currentIndex);
    this.notifyChanges();
  }

  addKeywordFromInput(event: MatChipInputEvent) {
    if (event.value) {
      this.alarmSegments.push(new AlarmNameSegment({ field: '', fieldNameKey: event.value }));
      this.disableInputText(true);
      this.notifyChanges();
    }
  }

  remove(alarmSegment: AlarmNameSegment): void {
    const index = this.alarmSegments.indexOf(alarmSegment);

    if (alarmSegment.field === '') {
      this.disableInputText(false);
    }

    if (index >= 0) {
      this.alarmSegments.splice(index, 1);
      this.notifyChanges();
    }
  }

  notifyChanges() {
    this.alarmNameSegmentsChange.emit(this.alarmSegments);
  }

  subscribeToResetWizard(resetHandler$: Observable<boolean>) {
    if (resetHandler$) {
      resetHandler$.pipe(untilDestroyed(this)).subscribe((hasToReset) => {
        if (hasToReset) {
          this.restore();
          this.resetCompleteHandler$.next();
        }
      });
    }
  }

  onSubscriptionChange($event) {
    this.subscriptionChange.emit($event);
  }
}
