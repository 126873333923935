import { Component, inject, Input, OnInit } from '@angular/core';
import { NetworkElementAttributeLinkDto } from '@common-modules/dependencies/ne/network-element-link-dto';
import { IntegrableForm, IntegrableFormParams } from '@common-modules/shared/forms/integrable-form';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'wlm-net-neat-link-form',
  templateUrl: './net-neat-link-form.component.html',
  styleUrl: './net-neat-link-form.component.scss',
})
export class NetNeatLinkFormComponent extends IntegrableForm implements OnInit {
  @Input() initialModel: NetworkElementAttributeLinkDto = null;
  model: NetworkElementAttributeLinkDto = {} as NetworkElementAttributeLinkDto;
  networkElementTypeId: number;

  private readonly _globalService = inject(GlobalsService);
  private readonly _arrayHelper = inject(ArrayHelperService);

  ngOnInit(): void {}

  onModelChange(model: NetworkElementAttributeLinkDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
    this.networkElementTypeId = params.filters.networkElementTypeId;

    if (this.networkElementTypeId) {
      this.model.networkElementTypeId = this.networkElementTypeId;
      if (this.formOptions.formState.op === 'create') {
        this.setInitialModel(this.model);
      } else {
        this.initialModel.networkElementTypeId = this.networkElementTypeId;
      }
    }
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'networkElementTypeId',
          type: 'input',
          className: 'col-12',
          props: {
            type: 'number',
            label: 'Network Element Type',
            required: true,
          },
        },
        {
          key: 'networkElementAttributeTypeId',
          type: 'select',
          className: 'col-12',
          props: {
            label: 'Network Element Attribute Type',
            required: true,
            valueProp: 'networkElementAttributeTypeId',
            labelProp: 'networkElementAttributeTypeName',
            options: this.getNetworkElementAttributeTypeOptions$(),
          },
          expressions: {
            'props.disabled': (data) => this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'isGisTooltip',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Gis Tooltip',
          },
        },
        {
          key: 'isKey',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Gis Key',
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.isGisTooltip',
            'model.isKey': (model, formState) => {
              return model.isGisTooltip ? model.isKey : false;
            },
          },
        },
        {
          key: 'gisOrder',
          type: 'input',
          className: 'col-2',
          props: {
            type: 'number',
            label: 'GIS Order',
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.isGisTooltip',
            'props.required': 'model.isGisTooltip',
            'model.gisOrder': (model, formState) => {
              return model.isGisTooltip ? model.gisOrder : null;
            },
          },
        },
        {
          key: 'isConfigurable',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Configurable',
          },
        },
      ],
    },
  ];

  private getNetworkElementAttributeTypeOptions$(): Observable<any> {
    return this._globalService
      .getNetworkElementAttributesType()
      .pipe(
        map((items) =>
          this._arrayHelper.sortObjects(items, (item) => item.networkElementAttributeTypeName)
        )
      );
  }
}
