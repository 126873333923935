import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { WidgetRegistryService } from '@common-modules/widget-registry/widget-registry';
import { TranslateModule } from '@ngx-translate/core';
import { WlmChartsModule } from '../features/charts/wlm-charts.module';
import { WlmSharedComponentModule } from '../features/shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../features/shared/wlm-shared.module';
import { SmartMetersExternalSettings } from './models/smart-meters-external-settings';
import { SmartMetersChangesService } from './services/smart-meters-changes.service';
import { SmartMetersHistoricalMessagesService } from './services/smart-meters-historical-messages.service';
import { SmartMetersHistoricalValidationService } from './services/smart-meters-historical-validation.service';
import { SmartMetersHistoricalService } from './services/smart-meters-historical.service';
import { SmartMetersService } from './services/smart-meters.service';
import { SmartMeterHistoricalCreationPopupComponent } from './smart-meter-historical-creation-popup/smart-meter-historical-creation-popup.component';
import { SmartMeterHistoricalFormComponent } from './smart-meter-historical-form/smart-meter-historical-form.component';
import { SmartMetersChangesComponent } from './smart-meters-changes/smart-meters-changes.component';
import { SmartMetersConfigWidgetComponent } from './smart-meters-config-widget/smart-meters-config-widget.component';
import { SmartMetersConfigComponent } from './smart-meters-config/smart-meters-config.component';
import { SmartMetersCurrentConfigComponent } from './smart-meters-current-config/smart-meters-current-config.component';
import { SmartMetersHistoricalConfigComponent } from './smart-meters-historical-config/smart-meters-historical-config.component';
import { SMART_METERS_EXTERNAL_SETTINGS } from './smart-meters-tokens';

@NgModule({
  declarations: [
    SmartMetersConfigWidgetComponent,
    SmartMetersConfigComponent,
    SmartMetersChangesComponent,
    SmartMetersHistoricalConfigComponent,
    SmartMetersCurrentConfigComponent,
    SmartMeterHistoricalCreationPopupComponent,
    SmartMeterHistoricalFormComponent,
  ],
  imports: [
    CommonModule,
    WlmSharedModule,
    TranslateModule,
    WlmSharedComponentModule,
    WlmChartsModule,
    WlmSharedModule,
  ],
  exports: [
    SmartMetersConfigWidgetComponent,
    SmartMetersChangesComponent,
    SmartMetersHistoricalConfigComponent,
    SmartMetersCurrentConfigComponent,
  ],
  providers: [
    SmartMetersService,
    {
      provide: 'SmartMetersService',
      useExisting: SmartMetersService,
    },
    SmartMetersHistoricalService,
    { provide: 'SmartMetersHistoricalService', useExisting: SmartMetersHistoricalService },
    SmartMetersChangesService,
    SmartMetersHistoricalMessagesService,
    {
      provide: 'SmartMetersHistoricalMessagesService',
      useExisting: SmartMetersHistoricalMessagesService,
    },
    SmartMetersHistoricalValidationService,
    {
      provide: 'SmartMetersHistoricalValidationService',
      useExisting: SmartMetersHistoricalValidationService,
    },
  ],
})
export class SmartMetersModule {
  static injector: Injector;

  constructor(injector: Injector, widgetRegistry: WidgetRegistryService) {
    SmartMetersModule.injector = injector;

    widgetRegistry.register([
      { name: 'SmartMetersConfigWidgetComponent', widgetClass: SmartMetersConfigWidgetComponent },
    ]);
  }

  static forFeature(
    externalSettings: SmartMetersExternalSettings
  ): ModuleWithProviders<SmartMetersModule> {
    return {
      ngModule: SmartMetersModule,
      providers: [
        {
          provide: SMART_METERS_EXTERNAL_SETTINGS,
          useValue: externalSettings,
        },
      ],
    };
  }
}
