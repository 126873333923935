import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DefaultParamsFormSettings } from '@common-modules/dependencies/bi/default-params-form-settings';
import { WaterBalanceTypeOption } from '@common-modules/dependencies/water-balance/water-balance-type-option';
import { WaterBalanceKpisWidgetParams } from '@common-modules/dynamic-layout/models/widget-definition-settings';
import { WBDimensionTypes } from '@common-modules/shared/constants/dimensions.constants';
import { SharedConstantsService } from '@common-modules/shared/constants/shared-constants.service';
import { CurrencyTypesEnum } from '@common-modules/shared/model/shared/currency-types.enum';
import { SelectOption } from '@common-modules/shared/model/shared/select-option';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

type FieldsHash<T> = {
  dimensionTypeId: T;
  waterBalanceType: T;
  currencyTypeId: T;
};

const COMPONENT_SELECTOR = 'wlm-water-balance-params-form';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './water-balance-params-form.component.html',
  styleUrls: ['./water-balance-params-form.component.scss'],
})
export class WaterBalanceParamsFormComponent implements OnInit {
  private _settings: DefaultParamsFormSettings;
  get settings(): DefaultParamsFormSettings {
    return this._settings;
  }
  @Input() set settings(value: DefaultParamsFormSettings) {
    this._settings = value;
    if (this.settings) {
      this.init();
    }
  }
  @Output() paramsChanged = new EventEmitter<WaterBalanceKpisWidgetParams>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() paramsTitle = new EventEmitter<string>();

  form: UntypedFormGroup;
  filteredFieldsHash: Partial<FieldsHash<true>> = {};
  readonlyFieldsHash: Partial<FieldsHash<true>> = {};
  waterBalanceTypes: WaterBalanceTypeOption[];
  dimensionTypes: SelectOption<number>[];
  currencyTypes: SelectOption<number>[];
  readonly fieldAppearance = 'outline';
  private readonly _defaultParams: FieldsHash<any> = {
    dimensionTypeId: null,
    waterBalanceType: null,
    currencyTypeId: CurrencyTypesEnum.NA,
  };

  private readonly _allFormFields: FieldsHash<any> = {
    dimensionTypeId: [null, [Validators.required]],
    waterBalanceType: [null, [Validators.required]],
    currencyTypeId: [null, [Validators.required]],
  };

  private getWaterBalanceTypes = (): Observable<any> => {
    return this._sharedConstantsService.getWaterBalanceTypes().pipe(
      tap((data) => {
        this.waterBalanceTypes = data;
      })
    );
  };

  private getDimensionTypes = (): Observable<any> => {
    let query$ = this._globalsService.getDimensionTypesTranslated('include', WBDimensionTypes);
    if (this.settings.type.readonlyFields?.find((field) => field === 'dimensionTypeId')) {
      // Do not filter by wb types here because it may come something like Percentage.
      query$ = this._globalsService.getDimensionTypesTranslated();
    }
    return query$.pipe(
      tap((data) => {
        this.dimensionTypes = data;
      })
    );
  };

  private getCurrencyTypes = (): Observable<any> => {
    return this._sharedConstantsService.getCurrencyTypes().pipe(
      tap((data) => {
        this.currencyTypes = data;
      })
    );
  };

  private readonly _allDependencies: FieldsHash<() => Observable<any>> = {
    waterBalanceType: this.getWaterBalanceTypes,
    dimensionTypeId: this.getDimensionTypes,
    currencyTypeId: this.getCurrencyTypes,
  };

  constructor(
    private _fb: UntypedFormBuilder,
    private _globalsService: GlobalsService,
    private _sharedConstantsService: SharedConstantsService
  ) {}

  ngOnInit(): void {}

  init(): void {
    this.buildForm();
    this.loadDependencies();
    this.hashFlags();

    this.form.updateValueAndValidity();
  }

  private buildForm(): void {
    const formFields = this.filterFields(this._allFormFields);
    this.form = this._fb.group(formFields);

    const defaultParams = this.filterFields(this._defaultParams);
    const allValues: any = { ...defaultParams, ...(this.settings.params ?? {}) };
    const values = this.filterFields(allValues);

    this.form.setValue(values);

    if (this.settings.type.readonlyFields) {
      this.settings.type.readonlyFields.forEach((field) => {
        this.form.get(field)?.disable();
      });
    }

    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.isValid.emit(this.form.valid);
      },
    });

    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.form.valid)
      )
      .subscribe((model: WaterBalanceKpisWidgetParams) => {
        this.paramsChanged.emit(model);
      });
  }

  private loadDependencies(): void {
    const dependencies = this.filterFields<() => Observable<any>>(this._allDependencies);
    Object.values(dependencies).forEach((dependencyFn: () => Observable<any>) =>
      dependencyFn().subscribe()
    );
  }

  private hashFlags(): void {
    this.filteredFieldsHash = {};
    this.settings.fields.forEach((field) => {
      this.filteredFieldsHash[field] = true;
    });
  }

  /**
   * From all the available fields, get only the ones that are currently configured.
   */
  private filterFields<T>(obj: FieldsHash<T>): Partial<FieldsHash<T>> {
    const filtered: Partial<FieldsHash<T>> = {};
    this.settings.fields.forEach((field) => (filtered[field] = obj[field]));
    return filtered;
  }
}
