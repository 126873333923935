import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import html2canvas from 'html2canvas';
import { from, interval, map, Observable, of, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataUrlManagerService {
  private _expiredLoopRunning = false;
  private _runtimeCacheExpirationMinutes = 5;
  private readonly _destroyRef = inject(DestroyRef);
  private _runtimeCache = new Map<string, Observable<string>>();

  getFromElement(key: string, element: HTMLElement): Observable<string> {
    if (!this._expiredLoopRunning) {
      this.runClearExpiredInterval();
    }

    if (!element) {
      return of(null);
    }

    if (!this._runtimeCache.has(key)) {
      const subject$ = new ReplaySubject<string>(1);
      this._runtimeCache.set(key, subject$.asObservable());
      this.buildBase64(element).subscribe((dataUrl) => {
        subject$.next(dataUrl);
      });
    }

    return this._runtimeCache.get(key);
  }

  private buildBase64(element: HTMLElement): Observable<string> {
    return from(html2canvas(element)).pipe(map((canvas) => canvas.toDataURL('img/png')));
  }

  private clearCache(): void {
    this._runtimeCache.clear();
  }

  private runClearExpiredInterval() {
    this._expiredLoopRunning = true;
    const source$ = interval(this._runtimeCacheExpirationMinutes * 60 * 1000);
    source$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.clearCache();
    });
  }
}
