import { Injectable, Injector } from '@angular/core';
import { AdditionalHttpOpts } from '@common-modules/cache/http-cache/additional-http-options';
import { ProfileResultDto } from '@common-modules/dependencies/alarms/profile-result.dto';
import { GenericCartesianChartSettings } from '@common-modules/wlm-charts/core/models/generic-chart-settings/generic-cartesian-chart-settings';
import { TrendChartDataParameters } from '@common-modules/wlm-charts/core/models/trend-chart-data-parameters';
import { BaseCartesianChartService } from '@common-modules/wlm-charts/core/services/base-cartesian-chart.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ProfilesChartSeriesService } from './profiles-chart-series.service';
import { ProfilesService } from './profiles.service';

@Injectable()
export class ProfilesConfigurationChartService extends BaseCartesianChartService {
  constructor(
    injector: Injector,
    private _profilesChartService: ProfilesChartSeriesService,
    private _profileService: ProfilesService
  ) {
    super(injector);
  }

  protected get url() {
    return `${this.apiUrl}/alarm/profile`;
  }

  public getData(params: TrendChartDataParameters): Observable<any[]> {
    if (params.queryParams.profileResults) {
      // If results are already available, do not perform api call.
      return of(params.queryParams.profileResults);
    }

    const queryParams = {
      profilesIds: params.queryParams.profileIds,
    };

    const addHttpOptions = new AdditionalHttpOpts({
      mapResponseDates: true,
    });

    return this.httpCacheClient
      .get<ProfileResultDto[]>(
        `${this.url}/calculate/ids`,
        this.avoidCache,
        queryParams,
        addHttpOptions
      )
      .pipe(
        switchMap((result) => this._profileService.convertDefaultUnitsMany(result, 'from')),
        map((r) => r as ProfileResultDto[])
      );
  }
  public mapDataToGenericSettings(
    profiles: ProfileResultDto[]
  ): Observable<GenericCartesianChartSettings> {
    return this._profilesChartService.mapProfilesToChartSettings(profiles);
  }
}
