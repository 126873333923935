import { Injectable, Injector } from '@angular/core';
import { GetCacheOpts } from '@common-modules/cache/http-cache/http-cache.client';
import { BaseService } from '@common-modules/shared/base.service';
import { IGisCollectionDto } from '@common-modules/shared/model/gis/gis-collection.dto';
import { IGisElementDto } from '@common-modules/shared/model/gis/gis-element.dto';
import { Observable } from 'rxjs';
import { GisElementKey } from '../../map/map-search/models/gis-element-key';

@Injectable({ providedIn: 'root' })
export class GisService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public getById(id: string, expiration?: GetCacheOpts): Observable<IGisElementDto> {
    expiration = expiration ?? this.expiration;
    return this.httpCacheClient.get<IGisElementDto>(`${this.apiUrl}/gis/id/${id}`, expiration);
  }

  public getByIds(ids: string[], expiration?: GetCacheOpts): Observable<IGisCollectionDto> {
    expiration = expiration ?? this.expiration;
    return this.httpCacheClient.post<IGisCollectionDto>(`${this.apiUrl}/gis/id`, ids, expiration);
  }

  public getByHierarchyElement(he: string, expiration?: GetCacheOpts): Observable<IGisElementDto> {
    expiration = expiration ?? this.expiration;
    return this.httpCacheClient.get<IGisElementDto>(`${this.apiUrl}/gis/he/${he}`, expiration);
  }

  public getByHierarchyElements(
    hes: string[],
    expiration?: GetCacheOpts
  ): Observable<IGisCollectionDto> {
    expiration = expiration ?? this.expiration;
    return this.httpCacheClient.post<IGisCollectionDto>(`${this.apiUrl}/gis/he`, hes, expiration);
  }

  public getByNetworkElements(
    nes: string[],
    expiration?: GetCacheOpts
  ): Observable<IGisCollectionDto> {
    expiration = expiration ?? this.expiration;
    return this.httpCacheClient.post<IGisCollectionDto>(`${this.apiUrl}/gis/ne`, nes, expiration);
  }

  public getByNetworkElement(
    networkElementId: string,
    expiration?: GetCacheOpts
  ): Observable<IGisElementDto> {
    expiration = expiration ?? this.expiration;
    return this.httpCacheClient.get<IGisElementDto>(
      `${this.apiUrl}/gis/ne/${networkElementId}`,
      expiration
    );
  }

  public getByCustomer(customerId: string, expiration?: GetCacheOpts): Observable<IGisElementDto> {
    expiration = expiration ?? this.expiration;
    return this.httpCacheClient.get<IGisElementDto>(
      `${this.apiUrl}/gis/customer/${customerId}`,
      expiration
    );
  }

  public getByLeak(leakageId: string, expiration?: GetCacheOpts): Observable<IGisElementDto> {
    expiration = expiration ?? this.expiration;
    return this.httpCacheClient.get<IGisElementDto>(
      `${this.apiUrl}/gis/leak/${leakageId}`,
      expiration
    );
  }

  public getByActivity(activityId: string, expiration?: GetCacheOpts): Observable<IGisElementDto> {
    expiration = expiration ?? this.expiration;
    return this.httpCacheClient.get<IGisElementDto>(
      `${this.apiUrl}/gis/activity/${activityId}`,
      expiration
    );
  }

  public getByInputSearch(
    searchText: string,
    expiration: GetCacheOpts = { avoid: true }
  ): Observable<GisElementKey[]> {
    return this.httpCacheClient.get<GisElementKey[]>(
      `${this.apiUrl}/gis/search/${searchText}`,
      expiration
    );
  }
}
