import { Injectable } from '@angular/core';
import { AxisLocation } from '@common-modules/shared/charts/model/axes/axis-location';
import { IHorizontalAxis } from '@common-modules/shared/charts/model/axes/horizontal-axis';
import { IVerticalAxis } from '@common-modules/shared/charts/model/axes/vertical-axis';
import { IChartCustomization } from '@common-modules/shared/charts/model/chart/chart-customization';
import { ChartSerie } from '@common-modules/shared/charts/model/chart/chart-serie';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { EstimatedEditedEnum } from '@common-modules/shared/model/algorithm/estimated-edited.enum';
import { ISerieDataPointDto } from '@common-modules/shared/model/chart/serie-data-point.dto';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { asEnumerable } from 'linq-es2015';
import { Observable } from 'rxjs';
import { GCartesianChartSerie } from '../models/generic-chart-settings/g-cartesian-chart-series';
import { GChartAxis } from '../models/generic-chart-settings/g-chart-axis';
import { GChartAxisLabel } from '../models/generic-chart-settings/g-chart-axis-label';
import { GChartAxisTick } from '../models/generic-chart-settings/g-chart-axis-tick';
import { GChartItemStyle } from '../models/generic-chart-settings/g-chart-item-style';
import { GChartLegend } from '../models/generic-chart-settings/g-chart-legend';
import { GChartSerieDataPoint } from '../models/generic-chart-settings/g-chart-serie-data-point';
import { GChartTextStyles } from '../models/generic-chart-settings/g-chart-text-styles';
import { GChartTitle } from '../models/generic-chart-settings/g-chart-title';
import { GChartTooltip } from '../models/generic-chart-settings/g-chart-tooltip';
import { TooltipFilterMethodEnum } from '../models/generic-chart-settings/tooltip-filter-method-enum';
import { BaseCartesianChartService } from '../services/base-cartesian-chart.service';
import { GenericChartService } from '../services/generic-chart.service';

@Injectable({
  providedIn: 'root',
})
export class CustomizableChartMapperService {
  private _fontSize = 9;
  private _uomConversions: UnitTypeConversionViewDto[];

  constructor(
    private _genericChartService: GenericChartService,
    private _dateHelperService: DateHelperService,
    private _uomService: UoMService,
    private _baseCartesianChartService: BaseCartesianChartService
  ) {}

  loadUoM(): Observable<UnitTypeConversionViewDto[]> {
    const uomLoaded$ = this._uomService.getAllDefaultUoM();
    uomLoaded$.subscribe((x) => (this._uomConversions = x));
    return uomLoaded$;
  }

  mapChartTitle(customization: IChartCustomization): GChartTitle | null {
    if (!customization.name) {
      return null;
    }
    return new GChartTitle({
      title: customization.name,
      position: 'center',
      textStyle: new GChartTextStyles({
        fontSize: 16,
        fontFamily: 'sans-serif',
        fontWeight: 600,
      }),
    });
  }

  mapVerticalAxis(verticalAxis: IVerticalAxis): GChartAxis {
    const gChartAxis = new GChartAxis({
      axisLabel: new GChartAxisLabel({
        formatter: this._genericChartService.formatNumber,
        color: verticalAxis.labelColor?.toHex(),
      }),
      max: verticalAxis?.maximum,
      min: verticalAxis?.minimum,
      axisWLMUnitTypeId: verticalAxis.unitTypeToId,
      position: this.getAxisPosition(verticalAxis.location),
      type: 'value',
      boundaryGap: false,
      axisWLMDimensionTypeId: verticalAxis.dimensionTypeId,
      name: verticalAxis.getName(),
    });

    return gChartAxis;
  }

  mapHorizontalAxis(horizontalAxis: IHorizontalAxis): GChartAxis {
    const gChartAxis = new GChartAxis({
      max: horizontalAxis.maximum,
      min: horizontalAxis.minimum,
      nameTextStyle: new GChartTextStyles({ fontSize: this._fontSize }),
      name: horizontalAxis.getName(),
      show: true,
      position: this.getAxisPosition(horizontalAxis.location),
      axisLabel: new GChartAxisLabel(),
      type: 'time',
      boundaryGap: false,
      axisTick: new GChartAxisTick({
        show: true,
        interval: 'auto',
        inside: false,
        lenght: 5,
        alignWithLabel: false,
      }),
      axisWLMDimensionTypeId: DimensionTypesEnum.NA,
    });

    return gChartAxis;
  }

  getSeriesLegend(series: GCartesianChartSerie[]): GChartLegend {
    const tooltip = new GChartLegend({
      data: series?.map((x) => x.name),
      fontSize: '11',
      itemHeight: '11',
      textPadding: [10, 0, 0, 0],
    });

    return tooltip;
  }

  setVerticalAxesOffset(gChartAxis: GChartAxis[]) {
    const axisPositions = asEnumerable(gChartAxis.map((x) => x.position))
      .Distinct()
      .ToArray();
    const offset = 45;

    axisPositions.forEach((position) => {
      const axisbyPosition = gChartAxis.filter((x) => x.position == position);

      let initialOffset = 0;
      axisbyPosition.forEach((axis) => {
        axis.offset = initialOffset;
        initialOffset += offset;
      });
    });
  }

  mapDataZoom(xAxes: GChartAxis[], yAxes: GChartAxis[], showDataZoom: boolean) {
    return this._genericChartService.buildDataZoom(
      xAxes,
      yAxes,
      showDataZoom ? 'slider' : 'inside'
    );
  }

  mapSerie(plotableSerie: ChartSerie, unitTypeIdTo: number): GCartesianChartSerie {
    const { serieDefinition, serieValues } = plotableSerie;

    const conversion = this._uomConversions?.find(
      (x) =>
        x?.unitTypeFromId == serieDefinition.serieCustomization.unitTypeFromId &&
        x?.unitTypeToId == unitTypeIdTo
    );

    const serie = new GCartesianChartSerie({
      type: serieDefinition.serieCustomization.type,
      dataPoints: asEnumerable(
        serieValues.map((x) => this.mapValues(x, conversion?.conversionFactor ?? 1))
      )
        .OrderBy((x) => x.pointCategory)
        .ToArray(),
      name: serieDefinition.serieCustomization.name,
      yAxisIndex: serieDefinition.serieCustomization.verticalAxisId,
      xAxisIndex: serieDefinition.serieCustomization.horizontalAxisId,
      largeData: true,
      showInUtc: serieDefinition.serieCustomization.showInUtc,
      itemStyle: new GChartItemStyle({
        color: serieDefinition.serieCustomization.hexColor,
      }),
      labelLayout: {
        hideOverlap: true,
      },
      showAlwaysInTooltip: serieDefinition.serieCustomization.showAlwaysInTooltip,
      showAlwaysFilterMethod: serieDefinition.serieCustomization.showAlwaysInTooltip
        ? TooltipFilterMethodEnum.Daily
        : null,
    });

    this._genericChartService.processSerieSymbol(serie);

    return serie;
  }

  getTooltip(verticalAxes, series): GChartTooltip {
    return new GChartTooltip({
      formatter: (params) => {
        return this._genericChartService.formatTooltipClosestPoint(
          params,
          verticalAxes,
          series,
          false,
          null,
          null
        );
      },
    });
    // backgroundColor: this._defaultBackground,
  }

  mapValues(dataPoint: ISerieDataPointDto, conversionFactor: number): GChartSerieDataPoint {
    return new GChartSerieDataPoint({
      pointCategory: this._dateHelperService.fromApiFormat(dataPoint.referenceDateTime.toString()),
      pointValue: +this._uomService.uomMultiply(
        String(dataPoint.value ?? null),
        String(conversionFactor ?? 1),
        false,
        null,
        true
      ),
      color: this.getDataPointColorByEstimatedEdited(
        dataPoint.estimated
          ? EstimatedEditedEnum.Estimated
          : dataPoint.edited
          ? EstimatedEditedEnum.Edited
          : dataPoint.adjusted
          ? EstimatedEditedEnum.Adjusted
          : 0,
        dataPoint.validity
      ),
      hasToShowSymbol: dataPoint.estimated || dataPoint.edited,
    });
  }

  private getAxisPosition(location: AxisLocation): 'left' | 'right' | 'bottom' | 'top' {
    switch (location) {
      case AxisLocation.Bottom:
        return 'bottom';
      case AxisLocation.Left:
        return 'left';
      case AxisLocation.Rigth:
        return 'right';
      case AxisLocation.Top:
        return 'top';
    }
  }

  private getDataPointColorByEstimatedEdited(estimatedEdited?: number, validity?: number): string {
    return this._baseCartesianChartService.getDataPointColorByEstimatedEdited(
      estimatedEdited,
      validity
    );
  }
}
