import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { DateRange } from '@common-modules/shared/model/date/date-range';

export class DateRangeFISettings extends BaseFilterItemSettings {
  startFieldName = 'startDate';
  endFieldName = 'endDate';
  dateRange: DateRange;
  allowsDateNull = true;
  disableRestoreOnFixed? = false;

  constructor(init: DateRangeFISettings) {
    super(init);
    Object.assign(this, init);

    if (!this.dateRange) {
      this.dateRange = this.defaultDateRange();
    }

    if (!this.defaultValue) {
      this.defaultValue = this.defaultDateRange();
    }
  }

  public defaultDateRange(): DateRange {
    return new DateRange(new Date(), new Date(), this.startFieldName, this.endFieldName);
  }
}
