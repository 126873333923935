import { Component, inject, Injector } from '@angular/core';
import { NetworkElementAttributeTypeCrudDto } from '@common-modules/dependencies/ne/network-element-attribute-type-crud.dto';
import { GenericCrudSettings } from '@common-modules/generic-crud/generic-crud-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { ValidationHelperService } from '@common-modules/shared/helpers/validation-helper.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { map, Observable, of } from 'rxjs';
import { NeatTypeFormComponent } from '../neat-type-form/neat-type-form.component';

import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { NEATypesCrudService } from '../neat-types-crud-service';

const COMPONENT_SELECTOR = 'wlm-neat-type-crud';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './neat-type-crud.component.html',
  styleUrl: './neat-type-crud.component.scss',
})
export class NeatTypeCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);
  private readonly _neaTypesCrudService = inject(NEATypesCrudService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  private readonly _spinnerService = inject(SpinnerService);
  private readonly _globalsService = inject(GlobalsService);
  private readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'NEATypesCrudService',
      grid: {
        gridSettingsName: 'NetworkElementAttributesTypesCrud',
      },
      create: {
        formComponent: NeatTypeFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      update: {
        formComponent: NeatTypeFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(false),
      },
      delete: {
        disableHook: this.disableHook,
      },
    });
  }

  private disableHook = (model: NetworkElementAttributeTypeCrudDto): Observable<boolean> => {
    const isCustom = this._neaTypesCrudService.isCustomAttributeFalse(model);

    return of(isCustom);
  };

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
    (
      model: NetworkElementAttributeTypeCrudDto
    ): Observable<NetworkElementAttributeTypeCrudDto | null> => {
      return this._neaTypesCrudService.validate(model, isCreate).pipe(
        map((validation) => {
          if (validation.isDuplicatedId) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.network-element-attribute-type-id`
            );
            return null;
          }
          if (validation.isDuplicatedName) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.network-element-attribute-type-name`
            );
            return null;
          }
          return model;
        })
      );
    };
}
