import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { LabelValueListModule } from '@common-modules/shared/core/label-value-list/label-value-list.module';
import { GenericMappersModule } from '@common-modules/shared/services/generic-mappers/generic-mappers.module';
import { WidgetRegistryService } from '@common-modules/widget-registry/widget-registry';
import { WLMGridModule } from '@common-modules/wlm-grid/wlm-grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { AlarmsModule } from '../alarms/alarms.module';
import { WlmChartsModule } from '../charts/wlm-charts.module';
import { WlmSharedComponentModule } from '../shared-component/wlm-shared-component.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { DmaCommentsComponent } from './distribution-network-components/dma-comments/dma-comments.component';
import { DistributionNetworkPageComponent } from './distribution-network-page/distribution-network-page.component';
import { DistributionNetworkService } from './distribution-network-page/distribution-network.service';
import { DistributionNetworkRoutingModule } from './distribution-network-routing.module';

@NgModule({
  declarations: [DistributionNetworkPageComponent, DmaCommentsComponent],
  imports: [
    CommonModule,
    DistributionNetworkRoutingModule,
    WlmSharedModule,
    WLMGridModule,
    WlmChartsModule,
    TranslateModule,
    WlmFiltersModule,
    AlarmsModule,
    WlmSharedComponentModule,
    GenericMappersModule,
    LabelValueListModule,
  ],
  providers: [DistributionNetworkService],
})
export class DistributionNetworkModule {
  static injector: Injector;

  constructor(injector: Injector, widgetRegistry: WidgetRegistryService) {
    DistributionNetworkModule.injector = injector;

    widgetRegistry.register([
      {
        name: 'DmaCommentsComponent',
        widgetClass: DmaCommentsComponent,
      },
    ]);
  }
}
