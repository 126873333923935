import { Component, OnInit } from '@angular/core';
import { SetValueAction } from '@common-modules/dynamic-layout/state/generic/generic.actions';
import { BaseSelectorDynamicWidgetComponent } from '@common-modules/redux/components/base-selector-dynamic-widget.component';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { SignalFiltrableItemDto } from '../../../shared/model/filtrable-items/signal-filtrable-item.dto';
import { DataVisualizationStateFields } from '../../models/data-visualization-state-fields';

const COMPONENT_SELECTOR = 'wlm-data-visualization-points-selector-widget';
export const DV_POINTS_SELECTOR_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-points-selector-widget.component.html',
  styleUrls: ['./data-visualization-points-selector-widget.component.scss'],
  providers: [ReduxStateService],
})
export class DataVisualizationPointsSelectorWidgetComponent
  extends BaseSelectorDynamicWidgetComponent
  implements OnInit
{
  onWidgetInit(): void {}

  get selectedFieldName(): string {
    return DataVisualizationStateFields.selectedPoint;
  }

  get componentName(): string {
    return 'DataVisualizationPointsSelectorWidgetComponent';
  }

  onPointSelected(pointSelected: SignalFiltrableItemDto): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: this.selectedFieldName,
        value: pointSelected,
      })
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: this.selectedFieldName,
        value: null,
      })
    );
  }
}
