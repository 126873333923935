import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { FilterValidation } from '@common-modules/shared/filters/component-filters/filter-validation';

export class PrioritisationGridFilterValidations implements FilterValidation {
  validateFilters(filterFields: string[], filterParameters: DataBindingFilters): boolean {
    let filtersAreValid = true;
    filterFields.forEach((filterName) => {
      filtersAreValid = filtersAreValid && filterParameters?.filters?.get(filterName) !== undefined;
    });

    return filtersAreValid;
  }
}
