import { CustomThemes } from '@common-modules/shared/theme/custom-themes';

export enum MapThemes {
  Light = 'light',
  Dark = 'dark',
}

// Maps app-related themes to map themes.
export const mapThemesMapping = {
  [CustomThemes.Default]: MapThemes.Light,
  [CustomThemes.Dark]: MapThemes.Dark,
};
