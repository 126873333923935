import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { Subject } from 'rxjs';
import { TabDetailPanelParameters } from '../../dependencies/navigation/tab-detail-component';
import { FilterAdapterResult } from '../../dependencies/wlm-filters/filter-adapter-result';
import { FiltersPayloadStatus } from '../../dependencies/wlm-filters/filters-payload-status.enum';

import { DataBindingFilters } from '../filters/component-filters/data-binding-filters';
import { PersistencyService } from '../persistency.service';

@Directive()
export abstract class BasePageFiltersComponent {
  @Output() filtersChanged = new EventEmitter<DataBindingFilters>();
  @Output() filtersDetailParametersChanged = new EventEmitter<TabDetailPanelParameters>();
  @Output() apply = new EventEmitter<void>();
  @Output() autoLoad = new EventEmitter<void>();
  @Output() filtersComplete = new EventEmitter<void>();
  @Output() valid = new EventEmitter<boolean>();

  @Input() clearAll$ = new Subject<void>();
  // Helper observable that will trigger the "apply" event from an external source.
  @Input() apply$ = new Subject<void>();
  // Helper observable that will trigger the "persist" event from an external source.
  @Input() persistFilters$: Subject<void>;

  @Input() persistencyArea: string;

  protected currentFilterResults: FilterAdapterResult;
  protected _filtersParameters = new DataBindingFilters();
  protected _filtersDetailParameters = new TabDetailPanelParameters();
  protected _filterHasError: boolean;
  // Handles the first, automatic load if all filters are available.
  protected firstAutoLoad = false;
  protected mustPersistFilters = false;
  filterText: string;
  adapters: FilterAdapterSettings[];
  adaptersReady = false;
  keysToComplete: string[];

  abstract provideAdapters(): FilterAdapterSettings[];

  protected abstract setFiltersPersistencyArea(): void;

  constructor(protected _persistencyService: PersistencyService) {}

  onFilterText(filterText: string): void {
    this.filterText = filterText;
  }

  errorsChanged(hasError: boolean) {
    this._filterHasError = hasError;
    this.emitEvents(null);
  }

  emitEvents(results: FilterAdapterResult) {
    if (this._filterHasError) {
      this.filtersChanged.emit(undefined);
    } else {
      this.filtersChanged.emit(this._filtersParameters);
      this.filtersDetailParametersChanged.emit(this._filtersDetailParameters);
      if (this.canPerformAutoload(results)) {
        this.autoLoad.next();
      }
    }
  }

  onGlobalFilterResults(results: FilterAdapterResult) {
    this._filtersParameters = results.dbFilters;
    this._filtersDetailParameters = results.tabParams;
    this.currentFilterResults = results;
    this.emitEvents(results);
  }

  onGlobalApplyResults(_: FilterAdapterResult) {
    this.apply.emit();
  }

  onGlobalComplete(): void {
    this.filtersComplete.emit();
    this.mustPersistFilters = true;
  }

  private canPerformAutoload(results: FilterAdapterResult): boolean {
    if (!results.completed) {
      return false;
    }

    if (!this.additionalAutoloadCheck(results)) {
      return false;
    }

    // this.logResults(results);
    const statusArray = Array.from(results.payload.status.entries());
    // If any field has emitted a new change, and it is initial
    const requiresLoad = statusArray.findIndex(([key, status]) => {
      return results.changedFields.get(key) && status === FiltersPayloadStatus.Initial;
    });
    const result = requiresLoad !== -1;
    return result;
  }

  private logResults(results: FilterAdapterResult): void {
    const keys = [];
    const statuses = [];
    const changes = [];
    Array.from(results.payload.data.keys()).forEach((key) => {
      keys.push(key);
      statuses.push(results.payload.status.get(key));
      changes.push(results.changedFields.get(key));
    });
    console.table([keys, statuses, changes]);
  }

  // Allows to specify an additional cause to avoid autoloading.
  protected abstract additionalAutoloadCheck(results: FilterAdapterResult): boolean;

  getPersistedData(key: string, defaultValue?: any, useLocalStorage?: boolean): any {
    this.ensurePersistencyArea();
    return this._persistencyService.getPersistedData(
      this.persistencyArea,
      key,
      defaultValue,
      useLocalStorage
    );
  }

  getPersisted(
    key: string,
    defaultValue?: any,
    forcedDefaultValue?: any,
    useLocalStorage?: boolean
  ) {
    this.ensurePersistencyArea();
    return this._persistencyService.getPersisted(
      this.persistencyArea,
      key,
      defaultValue,
      forcedDefaultValue,
      useLocalStorage
    );
  }

  loadPersistedDate(
    dateFieldName: string,
    defaultDate: Date,
    inclusiveDatePersisted: boolean = false,
    truncateDate: boolean = false
  ): Date {
    this.ensurePersistencyArea();
    return this._persistencyService.loadPersistedDate(
      this.persistencyArea,
      dateFieldName,
      defaultDate,
      inclusiveDatePersisted,
      truncateDate
    );
  }

  private ensurePersistencyArea(): void {
    if (!this.persistencyArea) {
      throw new Error(`Could not restore from persistency - persistency area is not set`);
    }
  }
}
