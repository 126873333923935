import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { BaseFilterSettings } from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { SharedConstantsService } from '@common-modules/shared/constants/shared-constants.service';
import { ValidationHelperService } from '@common-modules/shared/helpers/validation-helper.service';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { DateSelectModeEnum } from '@common-modules/shared/model/shared/date-select-mode.enum';
import { SelectOption } from '@common-modules/shared/model/shared/select-option';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { DateRangeAndMode } from './date-range-and-mode';
import { DateRangeAndModeSettings } from './date-range-and-mode.settings';

const COMPONENT_SELECTOR = 'wlm-date-range-and-mode';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './date-range-and-mode.component.html',
  styleUrls: ['./date-range-and-mode.component.scss'],
})
export class DateRangeAndModeComponent implements OnInit {
  mode = DateSelectModeEnum.Fixed;

  minValueForRolling = 1;

  @Input() public set settings(value: DateRangeAndModeSettings) {
    this._settings = value;
    if (value) {
      this.dateRange = value.dateRangeSettings.dateRange;
      this.mode = value.periodIsFixed ? DateSelectModeEnum.Fixed : DateSelectModeEnum.Rolling;
      if (this.form) {
        this.updateFormFromSettings(value);
      }
    }
  }
  public get settings(): DateRangeAndModeSettings {
    return this._settings;
  }
  private _settings: DateRangeAndModeSettings;

  private _disabled = false;
  public get disabled() {
    return this._disabled;
  }
  @Input() public set disabled(value) {
    this._disabled = value;
    this.baseFilterSettings.disableFilter = value;
  }
  @Input() empty$: Observable<void>;
  @Input() apply$: Subject<void>;
  @Input() fieldAppearance = 'outline';
  @Output() valueChanges = new EventEmitter<DateRangeAndMode>();
  @Output() valid = new EventEmitter<boolean>();

  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  form: UntypedFormGroup;
  modes: SelectOption<number>[] = [];
  modeEnum = DateSelectModeEnum;
  // Save the last dates so we can reassign them if we change to rolling and come back to fixed.
  lastDateFixedFilters: FiltersPayload;
  // Differentiate this subject from the empty$, as this is specifically used to communicate with the filter.
  emptyFilter$ = new Subject<void>();
  dateRangeValid = false;
  dateRange: DateRange;

  baseFilterSettings = new BaseFilterSettings({
    inputLabelKey: `${this.T_SCOPE}.date-range`,
    formMode: true, // Disable all action buttons.
    disableSearch: true,
  });
  filterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    storageLocation: 'session',
  });

  constructor(
    private _constants: SharedConstantsService,
    private _fb: UntypedFormBuilder,
    public customValidators: ValidationHelperService
  ) {}

  ngOnInit(): void {
    this.listenToHooks();
    this.loadData();
    this.buildForm();
  }

  listenToHooks(): void {
    this.empty$.pipe(untilDestroyed(this)).subscribe(() => {
      this.onEmpty();
    });
  }

  loadData(): void {
    this._constants
      .mapToArrayObservable(this._constants.getDateSelectModeMapping())
      .subscribe((options) => {
        this.modes = options;
      });
  }

  buildForm(): void {
    this.form = this._fb.group({
      periodDays: [this.settings?.periodDays, []],
      periodStartDate: [this.settings?.dateRangeSettings.dateRange?.start, []],
      periodEndDate: [this.settings?.dateRangeSettings.dateRange?.end, []],
    });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
      this.emitEvents(data);
    });

    if (this.disabled) {
      this.form.disable();
    }
  }

  emitEvents(data): void {
    const valid = this.isValid(data);

    const periodIsFixed = this.mode === DateSelectModeEnum.Fixed;
    const periodDays = periodIsFixed ? null : +data.periodDays;
    const periodStartDate = periodIsFixed ? data.periodStartDate : null;
    const periodEndDate = periodIsFixed ? data.periodEndDate : null;
    this.valueChanges.emit(
      new DateRangeAndMode({
        periodDays,
        periodStartDate,
        periodEndDate,
        periodIsFixed,
      })
    );

    this.valid.emit(valid);
  }

  isValid(model): boolean {
    if (this.mode === DateSelectModeEnum.Fixed) {
      return this.dateRangeValid;
    } else {
      // It only needs to be defined and not 0, as it is a restricted input.
      return this.form.get('periodDays').value >= this.minValueForRolling;
    }
  }

  onModeChange(): void {
    // When changing mode, the data from the previous mode is discarded.
    this.form.reset();

    if (!this.settings.dateRangeSettings.disableRestoreOnFixed) {
      // When going back to fixed mode, restore the dates that will be shown in the filters.
      if (this.mode === DateSelectModeEnum.Fixed && this.lastDateFixedFilters) {
        this.updateDatesFromFilters(this.lastDateFixedFilters);
      }
      // Apply triggers the changes that will populate the form again.
      this.apply$.next();
    } else {
      // If filters restoration is disable, clearAll on rolling mode to ensure it is empty.
      this.updateDatesForm(null, null);
      this.emptyFilter$.next();
      this.dateRange = this.dateRange.cloneWithValues(null, null);
    }

    this.emitEvents(this.form.getRawValue());
  }

  updateDatesFromFilters(filters: FiltersPayload): void {
    this.lastDateFixedFilters = filters;
    const { startFieldName, endFieldName } = this.settings.dateRangeSettings;
    const startDate = filters.data.get(startFieldName)?.value;
    const endDate = filters.data.get(endFieldName)?.value;
    this.updateDatesForm(startDate, endDate);
  }

  updateFormFromSettings(settings: DateRangeAndModeSettings): void {
    this.updateDatesForm(
      settings.dateRangeSettings.dateRange.start,
      settings.dateRangeSettings.dateRange.end
    );
    this.form.patchValue({ periodDays: settings.periodDays });
  }

  updateDatesForm(startDate: Date, endDate: Date): void {
    const modelUpdate = {};
    if (startDate) {
      modelUpdate['periodStartDate'] = startDate;
    }
    if (endDate) {
      modelUpdate['periodEndDate'] = endDate;
    }
    this.form.patchValue(modelUpdate);
  }

  onDateRangeValid(valid: boolean): void {
    this.dateRangeValid = valid;
  }

  /**
   * When resetting, return to fixed dates and reset that filter.
   * This will trigger a change that will be emitted in the output.
   */
  onEmpty(): void {
    this.mode = DateSelectModeEnum.Fixed;
    this.emptyFilter$.next();
  }

  safeSubscribe(obs$: Observable<any>, fn: (value: any) => void): void {
    if (obs$) {
      obs$.pipe(untilDestroyed(this)).subscribe(fn);
    }
  }
}
