import { Component, Inject, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HierarchyElementAttributes } from '@common-modules/dependencies/he/hierarchy.constants';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { PendingChanges } from '@common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from '@common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { asEnumerable } from 'linq-es2015';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IHierarchyElementAttributeSaveDto } from '../../../shared/model/hierarchy/hierarchy-element-attribute-save.dto';
// prettier-ignore
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { HierarchyElementAttributesService } from './hierarchy-elements.service';

const dmaCommentsHierarchyElementAttributeTypeId = 56;
const COMPONENT_SELECTOR = 'wlm-dma-comments';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './dma-comments.component.html',
  styleUrls: ['./dma-comments.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DmaCommentsComponent
  extends BaseWidgetComponent
  implements OnInit, IPendingChangesEmitter
{
  T_SCOPE = `${AppModules.DistributionNetwork}.${COMPONENT_SELECTOR}`;

  @Input() pageId: string;
  @Input() public hierarchyElementId: string;

  public form: UntypedFormGroup;
  public isSaving = false;
  public isLoading = false;
  public isEditButtonDisabled = false;

  private _originalValue: string = '';
  public get originalValue(): string {
    return this._originalValue;
  }
  public set originalValue(value: string) {
    this._originalValue = value;
    this.onFormValueChanged();
  }

  private _valueHasChanged: boolean = false;
  public get valueHasChanged(): boolean {
    return this._valueHasChanged;
  }
  public set valueHasChanged(value: boolean) {
    if (this._valueHasChanged !== value) {
      this.setPendingChanges(this.pageId, this.getPendingChanges(value));
    }

    this._valueHasChanged = value;
  }

  dmaComments: string;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _globalService: GlobalsService,
    private readonly _fb: UntypedFormBuilder,
    public readonly dialogService: DialogService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _heAttributesService: HierarchyElementAttributesService,
    private readonly _pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings);
    this.createForm();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.pageId = parameters?.parameters.get(TabDetailParameterName.pageId);
    this.hierarchyElementId = parameters?.parameters.get(TabDetailParameterName.hierarchyElementId);
  }

  init(): void {
    if (this.hierarchyElementId !== undefined) {
      this.loadDmaCommentsText(this.hierarchyElementId);
    }
    this.form.controls.dmaCommentsControl.disable();
    this.isEditButtonDisabled = false;
  }

  edit(): void {
    this.form.controls.dmaCommentsControl.enable();
    this.isEditButtonDisabled = true;
  }

  onSave() {
    this.save().subscribe(() => {});
  }

  save(): Observable<boolean> {
    this.isSaving = true;
    this.form.controls.dmaCommentsControl.disable();
    this.isEditButtonDisabled = true;

    const heAttributeSave: IHierarchyElementAttributeSaveDto = {
      hierarchyElementAttributes: [
        {
          hierarchyElementId: this.hierarchyElementId,
          hierarchyElementAttributeTypeId: HierarchyElementAttributes.DmaComments,
          hierarchyElementAttributeValue: this.form.controls.dmaCommentsControl.value,
        },
      ],
      userCode: this._authenticationService.userCode,
    };

    const params = { hierarchyElementId: this.hierarchyElementId };

    return this._heAttributesService.save(heAttributeSave).pipe(
      tap((value) => {
        if (value.hasErrors) {
          this.isSaving = false;
          this.dialogService.showErrorMessage({ message: value.errorMessage } as Error);
        } else {
          this.dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages.success`,
              params: params,
            })
          );
          this.originalValue = this.form.controls.dmaCommentsControl.value;
          this.isEditButtonDisabled = false;
          this.isSaving = false;
        }
      }),
      map(() => true)
    );
  }

  resetChanges(): void {
    this.form.controls.dmaCommentsControl.setValue(this.originalValue);
    this.form.controls.dmaCommentsControl.disable();
    this.isEditButtonDisabled = false;
  }

  loadDmaCommentsText(hierarchyElementId: string) {
    this.isLoading = true;
    this._globalService.getHierarchyElementAttributesById(hierarchyElementId).subscribe({
      next: (value) => {
        this.dmaComments = asEnumerable(value).FirstOrDefault(
          (x) => x.hierarchyElementAttributeTypeId === dmaCommentsHierarchyElementAttributeTypeId
        )?.hierarchyElementAttributeValue;
        this.form.controls.dmaCommentsControl.setValue(this.dmaComments);
        this.originalValue = this.dmaComments;
        this.isLoading = false;
      },
    });
  }

  get componentName() {
    return 'DmaCommentsComponent';
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  private getPendingChanges(hasChanges: boolean): PendingChanges {
    return {
      componentId: this.componentName,
      hasValidChanges: hasChanges,
      saveFn: () => this.save(),
    };
  }

  private createForm() {
    this.form = this._fb.group({
      dmaCommentsControl: [''],
    });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.onFormValueChanged());
  }

  private onFormValueChanged() {
    this.valueHasChanged = this.form.controls.dmaCommentsControl.value !== this.originalValue;
  }

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.componentName);
  }
}
