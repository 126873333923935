import { Component, Inject, Injector, Input, OnInit, ViewChild } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { IGridSettings } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBindingDirective } from '@common-modules/shared/odata/grid-binding.directive';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
// prettier-ignore

import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { Images } from 'src/assets/icons/previous-icons/images';
import { ActivityConfirmationService } from '../../activity-confirmation.service';

const COMPONENT_SELECTOR = 'wlm-activity-confirmation-detail';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './activity-confirmation-grid.component.html',
  styleUrls: ['./activity-confirmation-grid.component.scss'],
})
export class ActivityConfirmationGridComponent extends BaseWidgetComponent implements OnInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  @ViewChild(GridBindingDirective) confirmationBinding: GridBindingDirective;
  @ViewChild(GenericGridComponent) public genericGrid: GenericGridComponent;

  private _workOrderId = '';
  public get workOrderId(): string {
    return this._workOrderId;
  }
  @Input()
  public set workOrderId(v: string) {
    this._workOrderId = v;
  }
  public clearFiltersIcon: string = Images.IconClearFilter;
  public filters: DataBindingFilters;
  public filter: CompositeFilterDescriptor;
  public actualWorkUnit: number;
  public reasonForVariance: string | null;
  gridSettings: IGridSettings;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    public confirmationService: ActivityConfirmationService,
    private _gridService: GridSettingsService
  ) {
    super(injector, widgetSettings);
  }

  init(): void {
    this.setDataBindingFilter();
    this.endLoading();
  }

  private loadGridSettings() {
    this._gridService.getGridSettingsByName('Confirmations').subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.workOrderId = parameters
      ? parameters?.parameters?.get(TabDetailParameterName.workOrderId)
      : this.workOrderId;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadGridSettings();
  }

  private setDataBindingFilter() {
    if (!this.workOrderId) {
      return;
    }
    const auxFilter = new DataBindingFilters();
    auxFilter.addOrUpdateBasicFilter(TabDetailParameterName.workOrderId, this.workOrderId);
    this.filters = auxFilter;
  }
  showGridHeaderTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.nodeName === 'TH' ||
        (element.nodeName === 'SPAN' && element.offsetParent.nodeName === 'TH')) &&
      !element.firstElementChild?.classList?.contains('k-checkbox')
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  get componentName() {
    return 'ActivityConfirmationGridComponent';
  }
}
