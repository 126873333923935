import { Injectable, Injector } from '@angular/core';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GroupMeasurementViewDto } from '@common-modules/shared/model/telemetry/group-measurement-view.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';

@Injectable({
  providedIn: 'root',
})
export class PointValidationGroupService extends GridODataService<GroupMeasurementViewDto> {
  constructor(injector: Injector) {
    super(injector, '/api/telemetry/signal/validation/group');
  }

  protected mapResponse(
    response: PagedResultDto<GroupMeasurementViewDto>
  ): PagedResultDto<GroupMeasurementViewDto> {
    return response;
  }
}
