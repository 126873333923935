import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateRangeAndMode } from '@common-modules/common-filters/components/derivated/date-range-and-mode/date-range-and-mode';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { SATimeFrameSelectorSettings } from '../models/sa-time-frame-selector-settings';

const COMPONENT_SELECTOR = 'wlm-sa-time-frame-selection';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './sa-time-frame-selection.component.html',
  styleUrls: ['./sa-time-frame-selection.component.scss'],
})
export class SATimeFrameSelectionComponent implements OnInit {
  private _resetHandler$: Observable<boolean>;
  public get resetHandler$(): Observable<boolean> {
    return this._resetHandler$;
  }
  @Input() public set resetHandler$(value: Observable<boolean>) {
    this._resetHandler$ = value;
    this.subscribeToResetWizard(this.resetHandler$);
  }
  @Input() settings: SATimeFrameSelectorSettings;
  @Input() resetCompleteHandler$: ReplaySubject<void>;

  @Output() timeFrameChanged = new EventEmitter<DateRangeAndMode>();
  @Output() isValidChanged = new EventEmitter<boolean>();

  dateRangeApply$ = new Subject<void>();
  dateRangeClearAll$ = new Subject<void>();

  T_SCOPE = `${AppModules.Alarms}.${COMPONENT_SELECTOR}`;

  constructor() {}

  ngOnInit(): void {}

  onDatesChange(data: DateRangeAndMode): void {
    this.timeFrameChanged.emit(data);
  }
  onDatesValid(isValid: boolean) {
    this.isValidChanged.emit(isValid);
  }

  subscribeToResetWizard(resetHandler$: Observable<boolean>) {
    if (resetHandler$) {
      resetHandler$.pipe(untilDestroyed(this)).subscribe((hasToReset) => {
        if (hasToReset) {
          this.restore();
          this.resetCompleteHandler$.next();
        }
      });
    }
  }

  restore() {
    this.dateRangeClearAll$.next();
  }
}
