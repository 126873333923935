import { Injectable, Injector } from '@angular/core';
import { AdditionalHttpOpts } from '@common-modules/cache/http-cache/additional-http-options';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { Observable } from 'rxjs';
import { AlarmConfigurationDto } from '../../shared/model/alarms/alarm-configuration.dto';
import { DeleteAlarmsDto } from '../../shared/model/alarms/delete-alarms.dto';
import { ParkingOperationDto } from '../../shared/model/alarms/parking-operation.dto';

const endpointUrl = '/api/alarm';
const oDataEndPoint = 'configuration';
@Injectable({
  providedIn: 'root',
})
export class AlarmsConfigurationService extends GridODataService<AlarmConfigurationDto> {
  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/${oDataEndPoint}`);
  }

  protected mapResponse(
    response: PagedResultDto<AlarmConfigurationDto>
  ): PagedResultDto<AlarmConfigurationDto> {
    return response;
  }

  setAlarmsParking(parkingOperation: ParkingOperationDto): Observable<ParkingOperationDto> {
    const requestDatesNoUtc = ['endDate'];
    const addHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });

    return this.httpCacheClient.post(
      `${this.baseUrl}${endpointUrl}/park`,
      parkingOperation,
      {
        avoid: true,
      },
      addHttpOptions
    );
  }

  deleteAlarms(deleteAlarms: DeleteAlarmsDto): Observable<DeleteAlarmsDto> {
    return this.httpCacheClient.post(`${this.baseUrl}${endpointUrl}/delete`, deleteAlarms, {
      avoid: true,
    });
  }
}
