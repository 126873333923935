import { Component, Input, inject } from '@angular/core';
import { ILeakageTypeFullDto } from '@common-modules/dependencies/alc/leakage-type-full.dto';
import { IntegrableForm, IntegrableFormParams } from '@common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { LeakTypeGroupsCrudService } from '../leak-type-groups-crud.service';

@Component({
  selector: 'wlm-leak-types-form',
  templateUrl: './leak-types-form.component.html',
  styleUrls: ['./leak-types-form.component.scss'],
})
export class LeakTypesFormComponent extends IntegrableForm {
  @Input() initialModel: ILeakageTypeFullDto;
  model: ILeakageTypeFullDto;
  private readonly _leakTypeGroupsService = inject(LeakTypeGroupsCrudService);

  onModelChange(model: ILeakageTypeFullDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'leakageTypeId',
          type: 'input',
          className: 'col-6',
          props: {
            label: 'Leakage Type Id',
            type: 'number',
            required: true,
            disabled: true,
          },
          expressions: {
            hide: (data) => this.formOptions.formState.op === 'create',
          },
        },
        {
          key: 'leakageTypeName',
          type: 'input',
          className: 'col-6',
          props: {
            label: 'Leakage Type Name',
            required: true,
          },
        },
        {
          key: 'leakageTypeGroupId',
          type: 'select',
          className: 'col-4',
          props: {
            label: 'Leakage Group',
            valueProp: 'leakageTypeGroupId',
            labelProp: 'leakageTypeGroupName',
            required: true,
            options: this._leakTypeGroupsService.getAll(),
          },
          expressions: {
            className: (data) => (this.formOptions.formState.op === 'create' ? 'col-6' : 'col-4'),
          },
        },
        {
          key: 'nominalWaterLoss',
          type: 'input',
          className: 'col-4',
          props: {
            type: 'number',
            label: 'Nominal Water Loss',
            required: true,
          },
          expressions: {
            className: (data) => (this.formOptions.formState.op === 'create' ? 'col-6' : 'col-4'),
          },
        },
        {
          key: 'nominalJobTime',
          type: 'input',
          className: 'col-4',
          props: {
            type: 'number',
            label: 'Nominal Job Time',
            required: true,
          },
          expressions: {
            className: (data) => (this.formOptions.formState.op === 'create' ? 'col-6' : 'col-4'),
          },
        },
      ],
    },
  ];
}
