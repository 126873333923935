import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CampaignStatus } from '@common-modules/dependencies/alc/campaign-status.enum';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { ApplicationAttributes } from '@common-modules/shared/constants/application-constants';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { ALCCampaignStatus } from '../../../shared/model/alc/alc-campaign-status';

import { AlcCampaignsService } from '../../campaigns-page/alc-campaigns.service';

const COMPONENT_SELECTOR = 'wlm-campaigns-creation-goals';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './campaigns-creation-goals.component.html',
  styleUrls: ['./campaigns-creation-goals.component.scss'],
})
export class CampaignsCreationGoalsComponent implements OnInit {
  @Input() goalTargetFieldName: string;
  @Input() goalProgressFieldName: string;
  @Input() statusFieldName: string;
  @Input() targetDateFieldName: string;
  @Input() goalTargetValue: number;
  @Input() campaignBudgetFieldName: string;

  @Input() goalForm: UntypedFormGroup;

  private _isAutomatic: boolean;
  get isAutomatic(): boolean {
    return this._isAutomatic;
  }

  @Input() set isAutomatic(value: boolean) {
    this._isAutomatic = value;
    this.checkDisabledControls(value);
  }

  baselineLabel: string = '[%]';
  currencyLabel: string;

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;
  statuses: ALCCampaignStatus[];

  selectedStatusStyleMap: Map<number, string> = new Map<number, string>([
    [CampaignStatus.Blocked, 'red-pill-selected'],
    [CampaignStatus.Closed, 'white-pill-selected'],
    [CampaignStatus.InDesign, 'green-pill-selected'],
    [CampaignStatus.Open, 'w-primary-pill-selected'],
    [CampaignStatus.Planned, 'yellow-pill-selected'],
  ]);

  selectedStyle: string = '';

  constructor(
    private _alcCampaignsService: AlcCampaignsService,
    private _globalsService: GlobalsService
  ) {}

  ngOnInit(): void {
    this.getStatuses();
    this.getCurrencyLabel();
    this.setInitialSelectedStatusStyle();
    this.suscribeToFormChanges();
  }

  public onDateTimeChange(value: any): void {}

  getStatuses() {
    this.statuses = this._alcCampaignsService.getCampaignStatuses();
  }

  private getCurrencyLabel() {
    this._globalsService.getApplicationAttributesById(ApplicationAttributes.Currency).subscribe({
      next: (currency) => {
        this.currencyLabel = currency.attributeValue;
      },
      error: (error) => {},
    });
  }

  private checkDisabledControls(value: boolean) {
    const goalProgressControl = this.goalForm.get(this.goalProgressFieldName);
    if (value) {
      goalProgressControl.disable();
    } else {
      goalProgressControl.enable();
    }
  }

  private suscribeToFormChanges() {
    this.goalForm.controls[this.statusFieldName].valueChanges.subscribe((selectedStatus) => {
      this.updateSelectedStatusStyle(selectedStatus);
    });
  }

  private setInitialSelectedStatusStyle() {
    if (this.goalForm) {
      const status = this.goalForm.controls[this.statusFieldName].value;

      this.updateSelectedStatusStyle(status);
    }
  }

  private updateSelectedStatusStyle(selectedStatus: any) {
    const selectedClass = this.selectedStatusStyleMap.get(selectedStatus);

    this.selectedStyle = selectedClass ?? 'campaign-status-empty';
  }
}
