import { Injectable, Injector } from '@angular/core';
import { ACKAlarmsDto } from '@common-modules/dependencies/alarms/ack-alarms.dto';
import { AlarmSeriesDto } from '@common-modules/dependencies/alarms/alarm-serie.dto';
import { AlarmsActiveDto } from '@common-modules/dependencies/alarms/alarms-active.dto';
import { AlarmsSummaryDto } from '@common-modules/dependencies/alarms/alarms-summary.dto';
import { Observable, Subject } from 'rxjs';

import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';

const endpointUrl = '/api/alarm';
const oDataEndPoint = 'hierarchy';
@Injectable({
  providedIn: 'root',
})
export class ActiveAlarmsGridService extends GridODataService<AlarmsActiveDto> {
  private _refreshAlarmCounter$ = new Subject<boolean>();

  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/${oDataEndPoint}`);
  }

  protected mapResponse(
    response: PagedResultDto<AlarmsActiveDto>
  ): PagedResultDto<AlarmsActiveDto> {
    return response;
  }

  getNonAckAlarmsSummary(
    params:
      | {
          hierarchyElementId: string;
        }
      | { networkElementId: string },
    excludeChildren: boolean
  ): Observable<AlarmsSummaryDto[]> {
    const queryParams = {
      ...params,
      excludeChildren,
    };
    return this.httpCacheClient.get<AlarmsSummaryDto[]>(
      `${this.baseUrl}${endpointUrl}/summary`,
      {
        avoid: true,
      },
      queryParams
    );
  }

  setAckAlarms(ackAlarms: ACKAlarmsDto): Observable<ACKAlarmsDto> {
    return this.httpCacheClient.post(`${this.baseUrl}${endpointUrl}/ack`, ackAlarms, {
      avoid: true,
    });
  }

  getAlarmsSeries(alarmsIds: string[]): Observable<AlarmSeriesDto> {
    const queryParams = {
      alarmsIds,
    };

    return this.httpCacheClient.get<AlarmSeriesDto>(
      `${this.baseUrl}${endpointUrl}/alarms-series`,
      {
        avoid: true,
      },
      queryParams
    );
  }

  refreshAlarmCountersObservable(): Observable<boolean> {
    return this._refreshAlarmCounter$.asObservable();
  }

  setRefreshAlarmCounters(undoSelection: boolean = false) {
    this._refreshAlarmCounter$.next(undoSelection);
  }
}
