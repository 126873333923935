import { CurrencyTypesEnum } from '@common-modules/shared/model/shared/currency-types.enum';

export class WaterBalanceChartConfiguration {
  kpis: number[];
  startDate: Date;
  endDate: Date;
  dimensionTypeId: number;
  familyId: string;
  waterBalanceType: number;
  hierarchyElementIds: string[];
  currenctyTypeId?: CurrencyTypesEnum;

  public constructor(init?: Partial<WaterBalanceChartConfiguration>) {
    Object.assign(this, init);
  }
}
