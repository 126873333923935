import { LNUMode } from './lnu-mode.enum';

export enum LNUModeFieldNames {
  Fixed = 'Fixed',
  Calculated = 'Calculated',
  MNF = 'MNF',
}

export const LNUModeFieldNamesMapping = new Map<LNUMode, LNUModeFieldNames>([
  [LNUMode.ManualAllowancePerCustomer, LNUModeFieldNames.Fixed],
  [LNUMode.PercentageOfBilling, LNUModeFieldNames.Calculated],
  [LNUMode.PercentageOfMNF, LNUModeFieldNames.MNF],
  [LNUMode.SmartMeter, null],
]);
