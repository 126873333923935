import { Component, Inject, Injector } from '@angular/core';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { NoBoundarySettings } from '@common-modules/dependencies/ne-configuration/no-boundary-settings';
import { PressureSettings } from '@common-modules/dependencies/ne-configuration/pressure-settings';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { DynamicLayoutSettings } from '@common-modules/dynamic-layout/models/dynamic-layout-settings';
import {
  SetBoundarySettingsAction,
  SetPressureSettingsAction,
} from '@common-modules/dynamic-layout/state/ne-config/ne-config.actions';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { necSelectNetworkElement } from '../../helpers/select-network-element';

const COMPONENT_SELECTOR = 'wlm-ne-config-signals-group-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-config-signals-group-widget.component.html',
  styleUrls: ['./ne-config-signals-group-widget.component.scss'],
  providers: [ReduxStateService],
})
export class NeConfigSignalsGroupWidgetComponent extends BaseDynamicWidgetComponent {
  currentNE: INetworkElementDto;
  // Not all actions/selectors must have the same action settings.
  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });
  dynamicLayoutKeys: DynamicLayoutSettings;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
    this.dynamicLayoutKeys = widgetSettings.params.innerLayoutKeys;
  }

  onWidgetInit(): void {
    necSelectNetworkElement(
      this,
      this._state,
      this._scopeSettings,
      (selectedNE: INetworkElementDto) => (this.currentNE = selectedNE)
    );
  }

  onBoundarySettingsChange(boundarySettings: NoBoundarySettings): void {
    this._state.dispatch(new SetBoundarySettingsAction(boundarySettings, this._scopeSettings));
  }

  onPressureSettingsChange(pressureSettings: PressureSettings): void {
    this._state.dispatch(new SetPressureSettingsAction(pressureSettings, this._scopeSettings));
  }

  get componentName(): string {
    return 'NeConfigSignalsGroupWidgetComponent';
  }
}
