import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import { AuthGuard } from '@common-modules/shared/auth/services/auth.guard';
import { LeavePageGuard } from '@common-modules/shared/navigation/leave-page.guard';
import { CustomerDetailsPageComponent } from './customer-details-page/customer-details-page.component';
import { CustomerGroupComponent } from './customer-group/customer-group.component';

const routes: Routes = [
  {
    path: 'group',
    component: CustomerGroupComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      navKey: NavKeys.CustomerDetails,
    },
  },
  {
    path: 'detail',
    component: CustomerDetailsPageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canDeactivate: [LeavePageGuard],
    data: {
      navKey: NavKeys.CustomerDetails,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutingModule {}
