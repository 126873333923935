import { Component, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IHierarchyElementTypes } from '@common-modules/dependencies/he/hierarchy.constants';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { LabelValueListItem } from '@common-modules/shared/core/label-value-list/label-value-list-item';
import { BaseCalculateResizableChildComponent } from '@common-modules/shared/core/responsive/base-calculate-resizable-child.component';
import { TimeAggregationEnum } from '@common-modules/shared/model/algorithm/time-aggregation.enum';
import { IElementSize } from '@common-modules/shared/model/element-size';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { MapperFunctions } from '@common-modules/shared/services/generic-mappers/mapper-functions';
import { ToAnyUomMapperParams } from '@common-modules/shared/services/generic-mappers/mapper-params/to-any-uom-mapper-params';
import { ToUomMapperParams } from '@common-modules/shared/services/generic-mappers/mapper-params/to-uom-mapper-params';
import { UnitTypeIds } from '@common-modules/shared/uom/unit-type-ids';
import { Observable } from 'rxjs';
import { CustomerMeterDto } from '../models/customer-meter.dto';

const COMPONENT_SELECTOR = 'wlm-meter-details';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './meter-details.component.html',
  styleUrls: ['./meter-details.component.scss'],
})
export class MeterDetailsComponent extends BaseCalculateResizableChildComponent implements OnInit {
  @Input() meter: CustomerMeterDto;
  @Input() customerId: string;

  readonly T_SCOPE = `${AppModules.Customer}.${COMPONENT_SELECTOR}`;
  readonly _labelsKey = `${this.T_SCOPE}.fields`;
  readonly dimensionToCalculate = 'height';

  private readonly _averageConsumptionField = {
    label: `${this._labelsKey}.averageConsumption`,
    propertyFn: (model: CustomerMeterDto) => model.averageConsumption,
    mappers: [MapperFunctions.ToUoM],
    mappersParams: {
      [MapperFunctions.ToUoM]: {
        dimensionTypeId: DimensionTypesEnum.Flow,
        timeAggregationId: TimeAggregationEnum.Daily,
        hierarchyElementTypeId: IHierarchyElementTypes.Customer,
      } as ToUomMapperParams,
    },
  };
  private readonly _openedCssClass = 'col-12';

  readonly collapsedFields: LabelValueListItem[] = [
    {
      label: `${this._labelsKey}.installationId`,
      propertyFn: (model: CustomerMeterDto) => model.installationId,
      cssClass: 'col-6',
    },
    {
      ...this._averageConsumptionField,
      cssClass: 'col-6',
    },
    {
      label: `${this._labelsKey}.billingFrecuency`,
      propertyFn: (model: CustomerMeterDto) => model.billingFrecuency,
      cssClass: 'col-6',
    },
  ];

  readonly openedFields: LabelValueListItem[] = [
    {
      label: `${this._labelsKey}.installationId`,
      propertyFn: (model: CustomerMeterDto) => model.installationId,
      cssClass: this._openedCssClass,
    },
    {
      label: `${this._labelsKey}.meterStatus`,
      propertyFn: (model: CustomerMeterDto) => model.meterStatus,
      cssClass: this._openedCssClass,
      mappers: [MapperFunctions.ToActive],
    },
    {
      label: `${this._labelsKey}.billingFrecuency`,
      propertyFn: (model: CustomerMeterDto) => model.billingFrecuency,
      cssClass: this._openedCssClass,
    },
    {
      label: `${this._labelsKey}.sapBillingClass`,
      propertyFn: (model: CustomerMeterDto) => model.sapBillingClass,
      cssClass: this._openedCssClass,
    },
    this._averageConsumptionField,
    {
      label: `${this._labelsKey}.totalConsumption`,
      propertyFn: (model: CustomerMeterDto) => model.totalConsumption,
      mappers: [MapperFunctions.ToUoM],
      mappersParams: {
        [MapperFunctions.ToUoM]: {
          dimensionTypeId: DimensionTypesEnum.Volume,
          timeAggregationId: TimeAggregationEnum.Daily,
          hierarchyElementTypeId: IHierarchyElementTypes.Customer,
        } as ToUomMapperParams,
      },
      cssClass: this._openedCssClass,
    },
    {
      label: `${this._labelsKey}.installationDate`,
      propertyFn: (model: CustomerMeterDto) => model.installationDate,
      mappers: [MapperFunctions.ToReadableDate],
      cssClass: this._openedCssClass,
    },
    {
      label: `${this._labelsKey}.moveInDate`,
      propertyFn: (model: CustomerMeterDto) => model.moveInDate,
      mappers: [MapperFunctions.ToReadableDate],
      cssClass: this._openedCssClass,
    },
    {
      label: `${this._labelsKey}.rateCategory`,
      propertyFn: (model: CustomerMeterDto) => model.rateCategory,
      cssClass: this._openedCssClass,
    },
    {
      label: `${this._labelsKey}.sICCode`,
      propertyFn: (model: CustomerMeterDto) => model.sICCode,
      cssClass: this._openedCssClass,
    },
    {
      label: `${this._labelsKey}.meterSerialNumber`,
      propertyFn: (model: CustomerMeterDto) => model.meterSerialNumber,
      cssClass: this._openedCssClass,
    },
    {
      label: `${this._labelsKey}.meterSize`,
      propertyFn: (model: CustomerMeterDto) => model.meterSize,
      mappers: [MapperFunctions.ToAnyUoM],
      mappersParams: {
        [MapperFunctions.ToAnyUoM]: {
          dimensionTypeId: DimensionTypesEnum.Diameter,
          unitTypeFromId: UnitTypeIds.Diameter_Millimeters,
          unitTypeToId: UnitTypeIds.Diameter_Millimeters,
        } as ToAnyUomMapperParams,
      },
      cssClass: this._openedCssClass,
    },
    {
      label: `${this._labelsKey}.meterLocation`,
      propertyFn: (model: CustomerMeterDto) => model.meterLocation,
      cssClass: this._openedCssClass,
    },
  ];

  kpisCalculatedStyle: { [key: string]: any } = {};

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.listenSelfContainerSize();

    this.calculatedSize$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((size) => {
      this.kpisCalculatedStyle = size
        ? {
            'max-height': size.height + 'px',
          }
        : {};
    });
  }

  fixedSizes$(): Observable<IElementSize>[] {
    return [];
  }
}
