import { AfterViewInit, Directive, Input, TemplateRef, ViewChild } from '@angular/core';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { FiltersPayloadStatus } from '@common-modules/dependencies/wlm-filters/filters-payload-status.enum';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Directive()
export abstract class BaseFilterItemComponent implements AfterViewInit {
  @ViewChild('main')
  template: TemplateRef<any>;

  @ViewChild('summary')
  templateSummary: TemplateRef<any>;

  private _settings = new BaseFilterItemSettings({});
  public get settings(): BaseFilterItemSettings {
    return this._settings;
  }
  @Input() public set settings(value: BaseFilterItemSettings) {
    if (value) {
      this._settings = Object.assign(this._settings, value);
    }
  }

  @Input() hide = false;

  restoreOrder = 0;

  constructor() {}

  ngAfterViewInit(): void {
    this.validateTemplate(this.template, 'main');
    this.validateTemplate(this.templateSummary, 'summary');
  }

  /**
   * Helper method. Validate if a template has been configured properly.
   */
  private validateTemplate(template: TemplateRef<any>, refName: string): void {
    if (typeof template === 'undefined' && environment.log) {
      throw new Error(
        `The filter item with key ${this.getFilterKey()} must have a <ng-template #${refName}> element.`
      );
    }
  }

  buildPayload(filters: BasicFilter[], status = FiltersPayloadStatus.Normal): FiltersPayload {
    const filterMap = new Map<string, BasicFilter>();
    const statusMap = new Map<string, FiltersPayloadStatus>();
    const lastChange = new Map<string, number>();
    filters.forEach((filter) => {
      filterMap.set(filter.fieldName, filter);
      statusMap.set(filter.fieldName, status);
      lastChange.set(filter.fieldName, +new Date());
    });
    const payload = new FiltersPayload({
      data: filterMap,
      status: statusMap,
      lastChange,
    });
    return payload;
  }

  /**
   * Get the template that represents the main body of the filter item.
   * This method should only be called in afterViewInit, otherwise the template would be undefined.
   */
  getTemplate(): TemplateRef<any> {
    return this.template;
  }

  /**
   * Get the template that represents the summary part of the filter item.
   * This method should only be called in afterViewInit, otherwise the template would be undefined.
   */
  getTemplateSummary(): TemplateRef<any> {
    return this.templateSummary;
  }

  /**
   * Get the key that identifies the filter item. Should be unique within the same base filter.
   */
  abstract getFilterKey(): string;

  /**
   * Receives the "Clear" event from the parent.
   */
  abstract setClear(): void;

  /**
   * Receives the "Select All" event from the parent.
   */
  abstract setSelectAll(): void;

  /**
   * Receives the "Restore State" event from the parent.
   */
  abstract setRestoreState(): void;

  /**
   * Receives the "Set Initial State" event from the parent.
   */
  abstract setInitialState(): void;

  /**
   * Get the observable that notifies when the filter item is filtered.
   */
  abstract getFiltered$(): Observable<FiltersPayload>;

  /**
   * Format the selected elements and returns a string that can be appended inside the main input of the BaseFilter.
   */
  abstract getStateFormatted(): Observable<string>;

  /**
   * Gets if the current filter is valid.
   */
  abstract isValid(): boolean;

  /**
   * Gets the fieldNames used in the filter
   */
  abstract getFieldNames(): string[];
}
