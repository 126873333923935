import { Component, Injector, inject } from '@angular/core';
import { ISapLeakCodeDto } from '@common-modules/dependencies/alc/sap-leak-code.dto';
import { GenericCrudSettings } from '@common-modules/generic-crud/generic-crud-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { ValidationHelperService } from '@common-modules/shared/helpers/validation-helper.service';
import { Observable, map } from 'rxjs';
import { SapLeakCodeCrudService } from '../sap-leak-code-crud.service';
import { SapLeakCodeFormComponent } from '../sap-leak-code-form/sap-leak-code-form.component';

const COMPONENT_SELECTOR = 'wlm-sap-leak-code-crud';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './sap-leak-code-crud.component.html',
  styleUrls: ['./sap-leak-code-crud.component.scss'],
})
export class SapLeakCodeCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);
  private readonly _service = inject(SapLeakCodeCrudService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  private readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'SapLeakCodeCrudService',
      grid: {
        gridSettingsName: 'SapLeakCodeCrud',
      },
      create: {
        formComponent: SapLeakCodeFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      update: {
        formComponent: SapLeakCodeFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(false),
      },
      delete: {},
    });
  }

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
    (model: ISapLeakCodeDto): Observable<ISapLeakCodeDto | null> => {
      return this._service.validate(model, isCreate).pipe(
        map((validation) => {
          if (validation.isDuplicatedId) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.sap-leak-code`
            );
            return null;
          }
          return model;
        })
      );
    };
}
