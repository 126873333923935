import { GenericAction } from '@common-modules/redux/models/generic-action';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { DefaultParamsState } from './default-params-state';
import { DefaultParamsActionTypes } from './default-params.action-types';

export abstract class DefaultParamsAction extends GenericAction {
  area = StateAreas.DefaultParams;
}

export class ApplyDefaultParamsAction extends DefaultParamsAction {
  type = DefaultParamsActionTypes.ApplyDefaultParams;

  constructor(public payload: DefaultParamsState, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class ResetDefaultParamsAction extends DefaultParamsAction {
  type = DefaultParamsActionTypes.ResetDefaultParams;
  payload = null;

  constructor(settings = new StateScopeSettings({})) {
    super(settings);
  }
}
