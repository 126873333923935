import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { HttpCacheService } from '@common-modules/cache/http-cache/http-cache.service';
import { ALCCampaignDto } from '@common-modules/dependencies/alc/alc-campaing.dto';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { MenuLink } from '@common-modules/dependencies/navigation/menu-link';
import { NavItem } from '@common-modules/dependencies/navigation/nav-item';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WNavigateSettings } from '@common-modules/dependencies/navigation/w-navigate-by';
import { WlmNavigationService } from '@common-modules/dependencies/navigation/wlm-navigation.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthorizeService } from '@common-modules/shared/auth/services/authorize.service';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { ApplicationAttributes } from '@common-modules/shared/constants/application-constants';
import { IGridSettings } from '@common-modules/shared/constants/grid.constants';
import { LabelValueListItem } from '@common-modules/shared/core/label-value-list/label-value-list-item';
import { StylizedTitleSidePill } from '@common-modules/shared/core/right-panel/title-side-pill';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsDisable } from '@common-modules/shared/grid-buttons/models/grid-btns-disable';
import { GridBtnsEvent } from '@common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from '@common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { NavMenuBuilderHelperService } from '@common-modules/shared/helpers/navmenu-builder-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { TimeAggregationEnum } from '@common-modules/shared/model/algorithm/time-aggregation.enum';
import { WLMDialogResult } from '@common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { DynamicGridSettings } from '@common-modules/shared/model/grid/dynamic-grid-settings';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { ICanLeavePage } from '@common-modules/shared/navigation/can-component-deactivate';
import { MapperFunctions } from '@common-modules/shared/services/generic-mappers/mapper-functions';
import { ToUomMapperParams } from '@common-modules/shared/services/generic-mappers/mapper-params/to-uom-mapper-params';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { DynamicGridComponent } from '@common-modules/wlm-grid/dynamic-grid/dynamic-grid.component';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { asEnumerable } from 'linq-es2015';
import { Observable, ReplaySubject, Subject, Subscription, combineLatest, of } from 'rxjs';
import { ALCCampaignStatus } from '../../shared/model/alc/alc-campaign-status';
import { NavItemsConfiguration } from '../../shared/model/navigation/navitem-configuration';
import { NavMenuConfiguration } from '../../shared/model/navigation/navmenu-configuration';
import { CampaignsCreationPopupComponent } from '../campaigns-components/campaigns-creation-popup/campaigns-creation-popup.component';
import { RelatedActivitiesWidgetComponent } from '../campaigns-components/related-activities-widget/related-activities-widget.component';
import { AlcCampaignsService } from './alc-campaigns.service';
const COMPONENT_SELECTOR = 'wlm-campaigns-page';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './campaigns-page.component.html',
  styleUrls: ['./campaigns-page.component.scss'],
})
export class CampaignsPageComponent
  extends BasePageComponent
  implements OnInit, OnDestroy, ICanLeavePage
{
  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  dynamicGridLoaded: Subject<boolean> = new Subject<boolean>();
  removeSelectionPersisted$ = new ReplaySubject<void>();
  gridSettingsChanged$ = new ReplaySubject<IGridSettings>();
  removeSelection$ = new ReplaySubject<void>();
  gridSettingsReady$ = new ReplaySubject<IGridSettings>();
  clearAll$ = new Subject<void>();
  triggerApply$ = new Subject<void>();
  gridBtnsDisable$: Subject<GridBtnsDisable>;
  gridSettingsSubscription$: Subscription;
  autoLoad: boolean;

  selectedCampaign: ALCCampaignDto = null;

  dynamicGridSettings: DynamicGridSettings;
  gridFiltersForBinding: DataBindingFilters;
  filters: DataBindingFilters;
  gridSettings: IGridSettings;
  grid: GenericGridComponent;
  filterDetailsParameters: TabDetailPanelParameters;

  private _dynamicGrid: DynamicGridComponent;
  private _checkSelectionForUpdates = false;

  public get dynamicGrid(): DynamicGridComponent {
    return this._dynamicGrid;
  }
  @ViewChild(DynamicGridComponent) set dynamicGrid(value: DynamicGridComponent) {
    this._dynamicGrid = value;
    if (value) {
      this.dynamicGridLoaded.next(true);
    }
  }

  private _selectedCampaigns: ALCCampaignDto[];
  public get selectedCampaigns(): ALCCampaignDto[] {
    return this._selectedCampaigns;
  }
  public set selectedCampaigns(value: ALCCampaignDto[]) {
    this._selectedCampaigns = value;
    this.updateSelectedCampaigns();
    this.updateNavigationParams();
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }
  public get persistencyArea(): string {
    return this.pageCrud;
  }
  public get pageCrud(): string {
    return 'WLMCampaignCrud';
  }
  navigations: DropdownNavigationItem[] = [];

  get popupButtonTooltip(): string {
    return this.selectedCampaigns?.length
      ? `${this.T_SCOPE}.tooltip.edit-campaign`
      : `${this.T_SCOPE}.tooltip.add-campaign`;
  }

  hierarchyFamilyFieldName = 'hierarchyFamilyId';
  disableAutoload = false;

  private _currencySymbol = '';

  private _rightPanelAttributes: LabelValueListItem[];

  activityMenuLink: MenuLink;

  campaignStatuses: ALCCampaignStatus[];

  constructor(
    private _dialogService: DialogService,
    private _navigationService: WlmNavigationService,
    private _dateHelperService: DateHelperService,
    private _authService: AuthorizeService,
    private _campaignService: AlcCampaignsService,
    private _spinnerService: SpinnerService,
    private _globalService: GlobalsService,
    private _cacheService: HttpCacheService,
    private _navMenuBuilderHelperService: NavMenuBuilderHelperService,
    private _localization: LocalizationHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initializeGridSettings();
    this.loadRightPanelComponents();
    this.loadCampaignStates();
    this.setCurrencySymbol();
    this.updateNavigationParams();
    this._navigationService
      .generateMenuLinks([new NavItem(NavKeys.Activities, null)])
      .subscribe((navKeys) => {
        if (navKeys.length) {
          this.activityMenuLink = navKeys[0];
        }
      });
  }

  private loadCampaignStates() {
    this.campaignStatuses = this._campaignService.getCampaignStatuses();
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  canLeavePage(): Observable<boolean> {
    return of(true);
  }

  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.Filter:
        this.onApplyFiltersFromPage();
        break;
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
        break;
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  onApplyFiltersFromPage(): void {
    this.triggerApply$.next();
  }

  getSelectedCampaign(selectedCampaign: ALCCampaignDto) {
    this.selectedCampaign = selectedCampaign;
    this.sendParameters(selectedCampaign, this.filterDetailsParameters);
  }

  getGridSettings(gridSettings: IGridSettings) {
    this.gridSettings = gridSettings;
    this.gridSettingsReady$.next(gridSettings);
  }

  onGridSettingsChanged(settings: IGridSettings) {
    this.gridSettingsChanged$.next(settings);
  }

  getGenericGridInstance(grid: GenericGridComponent) {
    this.grid = grid;
  }

  loadGrid() {
    this.gridFiltersForBinding = new DataBindingFilters();
  }

  openAddPopup(): void {
    this.preparePopup();
  }

  openEditPopup(): void {
    this.preparePopup(this.selectedCampaign);
  }

  deleteCampaigns(): void {
    const isPlural = this.selectedCampaigns.length > 1;
    const warningMessage = isPlural ? 'remove-campaigns-warning' : 'remove-campaign-warning';

    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.${warningMessage}`,
    });

    this._spinnerService.setLoading(true, this.pageCrud);

    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this._campaignService.deleteCampaign(this.selectedCampaigns).subscribe({
            next: (campaign) => {
              this._cacheService.clearContainsInUrl('alc/campaign').then((success) => {
                const dialogSettings = this._dialogService.getDialogSettingsConfiguration(
                  'messages.campaign-delete-success',
                  'success',
                  this.T_SCOPE
                );
                this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
                this.removeSelectionPersisted$.next();
                this.grid.reloadGrid();

                this._spinnerService.setLoading(false, this.pageCrud);
              });
            },
            error: () => {
              const dialogSettings = this._dialogService.getDialogSettingsConfiguration(
                'messages.campaign-delete-error',
                'error',
                this.T_SCOPE
              );
              this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
              this._spinnerService.setLoading(false, this.pageCrud);
            },
          });
        }
      });
  }

  loadRightPanelComponents() {
    const activitiesPermission$ = this._authService.canAccess('ActivitiesCrud', 'r');
    const localization$ = this.localization.get(`${this.T_SCOPE}.tab-settings`);
    combineLatest([activitiesPermission$, localization$]).subscribe(
      ([hasActivitiesPermission, ts]) => {
        const panelSettings = new TabDetailPanelSettings();
        if (hasActivitiesPermission) {
          panelSettings.addComponent(RelatedActivitiesWidgetComponent, ts.activities);
        }

        this.rightPanelService.setTabSettings(panelSettings);
      }
    );
  }

  //In case the related activities changes, we need to update the selectedCampaigns list in order to navigate to the right activities
  private updateSelectedCampaigns() {
    if (this._checkSelectionForUpdates) {
      const updatedRows = (this.grid.grid.data as GridDataResult).data;

      updatedRows.forEach((campaign) => {
        const index = this._selectedCampaigns.findIndex(
          (c) => c.campaignId === campaign.campaignId
        );

        if (index > -1) {
          this._selectedCampaigns[index] = campaign;
        }
      });

      this.grid.persistSelectedRows(this._selectedCampaigns);

      this._checkSelectionForUpdates = false;
    }
  }

  private updateNavigationParams() {
    this.navigations = [];

    const navItemsConfiguration = this.getNavItemsConfigurations();
    const navMenuConfiguration = new NavMenuConfiguration({
      selectedElements: this.selectedCampaigns,
      items: navItemsConfiguration,
    });
    this._navMenuBuilderHelperService
      .buildMenu(navMenuConfiguration)
      .subscribe((menu) => (this.navigations = menu));
  }

  private getNavItemsConfigurations(): NavItemsConfiguration[] {
    const componentContext = this;

    function navigateToActivities(navigateSettings: WNavigateSettings): void {
      const campaignsWithActivities = asEnumerable<ALCCampaignDto>(
        componentContext.selectedCampaigns.filter((x) => x.activities.length)
      );

      const activityIds = campaignsWithActivities.SelectMany((x) => x.activities).ToArray();
      const startDate = componentContext._dateHelperService
        .getMinDate(
          campaignsWithActivities
            .Select((x) =>
              x.activityMinCreationDate ? new Date(x.activityMinCreationDate) : new Date()
            )
            .ToArray()
        )
        .toLocaleDateString();
      const endDate = componentContext._dateHelperService
        .getMaxDate(
          campaignsWithActivities
            .Select((x) =>
              x.activityMaxCreationDate ? new Date(x.activityMaxCreationDate) : new Date()
            )
            .ToArray()
        )
        .toLocaleDateString();

      const hierarchyFamilyId = campaignsWithActivities
        .Select((x) => x.hierarchyFamilyId)
        .Distinct()
        .FirstOrDefault();

      const queryParams = {
        activityIds,
        startDate,
        endDate,
        hierarchyFamilyId,
      };

      const urlPath = componentContext.activityMenuLink.urlPath;
      componentContext._navigationService.navigate({
        ...navigateSettings,
        urlPath,
        queryParams,
      });
    }

    return [
      {
        key: NavKeys.Activities,
        validationType: 'custom',
        customValidation: this.selectedCampaigns?.length === 0,
        paramType: 'none',
        customNavMethod: navigateToActivities,
      },
    ];
  }

  private translateField(key: string): string {
    const finalKey = [this.T_SCOPE, 'tab-settings', key].join('.');
    return finalKey;
  }

  private sendParameters(selectedElement: ALCCampaignDto, filter: TabDetailPanelParameters) {
    const parameters = filter ?? new TabDetailPanelParameters();

    parameters.addParameter(TabDetailParameterName.campaign, selectedElement);
    parameters.addParameter(TabDetailParameterName.elementName, selectedElement?.campaignName);

    const campaignStatus = this.campaignStatuses.find(
      (x) => x.value == selectedElement?.campaignStatus
    );

    if (campaignStatus) {
      parameters.addParameter(TabDetailParameterName.stylizedTitleSidePills, [
        new StylizedTitleSidePill({
          hasToTranslate: true,
          class: campaignStatus.class,
          label: campaignStatus.labelKey,
        }),
      ]);
    } else {
      parameters.addParameter(TabDetailParameterName.stylizedTitleSidePills, []);
    }

    this.updateRightPanelAttributes();
    parameters.addParameter(TabDetailParameterName.topAttributes, this._rightPanelAttributes);
    parameters.addParameter(TabDetailParameterName.topValues, selectedElement);

    this.rightPanelService.setTabParameters(parameters);
  }

  private updateRightPanelAttributes() {
    this._rightPanelAttributes = [
      {
        label: this.translateField('campaignDescription'),
        propertyFn: (model: ALCCampaignDto) => model.campaignDescription,
      },
      {
        label: `${this.translateField('goalProgress')}`,
        propertyFn: (model: ALCCampaignDto) => model.goalProgress,
        mappers: [MapperFunctions.ToUoM],
        mappersParams: {
          [MapperFunctions.ToUoM]: {
            dimensionTypeId: DimensionTypesEnum.Percentage,
            timeAggregationId: TimeAggregationEnum.Daily,
            hierarchyElementTypeId: this.selectedCampaign?.hierarchyElementTypeId,
          } as ToUomMapperParams,
        },
      },
      {
        label: `${this.translateField('estimatedCost')}`,
        propertyFn: (model: ALCCampaignDto) =>
          model?.actualCost !== undefined && model?.actualCost !== null
            ? `${this._localization.formatNumber(model?.actualCost)} ${this._currencySymbol}`
            : '',
      },
    ];
  }

  private initializeGridSettings() {
    this.dynamicGridSettings = new DynamicGridSettings({
      disableAutoLoad: false,
      gridSettingName: 'ALCCampaigns',
      persistencyArea: this.persistencyArea,
      type: 'generic',
      usePersistence: true,
    });

    this.loadGrid();
  }

  private preparePopup(data: ALCCampaignDto = null): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '700px';
    dialogConfig.width = '650px';

    dialogConfig.data = {
      selectedCampaign: data,
    };

    const createPopup = this._dialogService.openComponent(
      CampaignsCreationPopupComponent,
      dialogConfig
    );

    createPopup.afterClosed().subscribe((result) => {
      if (result) {
        this._checkSelectionForUpdates = true;
        this.grid.reloadGrid();
      }
    });
  }

  private setCurrencySymbol() {
    this._globalService

      .getApplicationAttributesById(ApplicationAttributes.Currency)
      .subscribe((attribute) => {
        this._currencySymbol = attribute.attributeValue;
      });
  }
}
