import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AlgorithmsService } from '@common-modules/shared/services/algorithms.service';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { AppModules } from '../../shared/app-modules.enum';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';
import { ElementAttributeTypeEnum } from '../../shared/model/shared/element-attribute-type.enum';
import { ElementAttributeDto } from '../../shared/model/shared/element-attribute.dto';
import { GlobalsService } from '../../shared/services/globals.service';

import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

const COMPONENT_SELECTOR = 'wlm-maxmin-column-component';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './maxmin-column.component.html',
  styleUrls: ['./maxmin-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => MaxminColumnComponent),
    },
  ],
})
export class MaxminColumnComponent extends WlmColumnComponent implements OnInit {
  @Input() hierarchyElementTypeIdField: string;
  @Input() hierarchyElementTypeId: string;
  @Input() hierarchyElementIdField: string;
  @Input() networkElementIdField: string;
  @Input() algorithmShortName?: string;
  @Input() timeAggregationId?: number;
  @Input() dimensionTypeId?: number;
  @Input() unitFormat?: string;
  @Input() decimalPositions?: number;
  @Input() translationPrefix?: string;
  @Input() maxFieldName?: string;
  @Input() minFieldName?: string;
  @Input() elementAttributeType?: ElementAttributeTypeEnum;
  @Input() maxAttributeTypeId?: number;
  @Input() minAttributeTypeId?: number;

  public T_SCOPE = `${AppModules.WlmGrid}.${COMPONENT_SELECTOR}`;

  hierarchyElementId: string;
  messageMissingHEType = 'Missing hierarchyElementTypeId';
  messageErrorUomService = 'Error accessing uom service';
  currentConversionFactor?: number;
  columnUnitLabel: string;

  minTitleTooltip: string;
  maxTitleTooltip: string;
  pointTitleTooltip: string;

  attributes: ElementAttributeDto[];
  currentField: string;

  public get hasMaxMinDefined(): boolean {
    return this.maxFieldName != undefined && this.minFieldName != undefined;
  }

  private get useAttributeValues(): boolean {
    return (
      this.elementAttributeType != undefined &&
      this.maxAttributeTypeId != undefined &&
      this.minAttributeTypeId != undefined
    );
  }

  private titleUnitsDefined = false;

  constructor(
    private algorithmsService: AlgorithmsService,
    private globalService: GlobalsService,
    private localization: LocalizationHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setTooltipBaseText();

    if (this.algorithmShortName !== undefined) {
      this.algorithmsService.getAlgorithm(this.algorithmShortName).subscribe((algorithm) => {
        this.timeAggregationId = algorithm.timeAggregationId;
        this.dimensionTypeId = algorithm.dimensionTypeId;
      });
    }

    if (!this.hasMaxMinDefined && this.useAttributeValues) {
      this.getAttributeValues();
    }

    this.titleUnitsDefined = false;
    this.currentField = this.field;
    this.filterable = false;
  }

  private getAttributeValues() {
    if (this.elementAttributeType == ElementAttributeTypeEnum.HierarchyElements) {
      this.getAttributesFromHierarchyElements();
    }

    if (this.elementAttributeType == ElementAttributeTypeEnum.NetworkElements) {
      this.getAttributesFromNetworkElements();
    }
  }

  private getAttributesFromNetworkElements() {
    this.globalService
      .getNetworkElementAttributesByType([this.minAttributeTypeId, this.maxAttributeTypeId])
      .subscribe((attributes) => {
        this.attributes = attributes.items.map(
          (m) =>
            new ElementAttributeDto({
              attributeTypeId: m.networkElementAttributeTypeId,
              attributeValue: m.networkElementAttributeValue,
              elementId: m.networkElementId,
            })
        );
      });
  }

  private getAttributesFromHierarchyElements() {
    this.globalService
      .getHierarchyElementAttributesByIdFiltered(null, [
        this.minAttributeTypeId,
        this.maxAttributeTypeId,
      ])
      .subscribe((attributes) => {
        this.attributes = attributes.map(
          (m) =>
            new ElementAttributeDto({
              attributeTypeId: m.hierarchyElementAttributeTypeId,
              attributeValue: m.hierarchyElementAttributeValue,
              elementId: m.hierarchyElementId,
            })
        );
      });
  }

  unitDescriptionChange(unitDescription: string) {
    if (!this.titleUnitsDefined) {
      this.title = `${this.title} [${unitDescription}]`;
      this.titleUnitsDefined = true;
      this.headerTextChanged.emit();
    }
  }

  private setTooltipBaseText() {
    this.localization.get(`${this.T_SCOPE}.${this.translationPrefix}`).subscribe((ts) => {
      this.minTitleTooltip = ts['min-tooltip'];
      this.maxTitleTooltip = ts['max-tooltip'];
      this.pointTitleTooltip = ts['point-tooltip'];
    });
  }
}
