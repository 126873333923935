import { Injectable, Injector } from '@angular/core';
import { ISapCodeGroupDto } from '@common-modules/dependencies/alc/sap-code-group.dto';
import { GenericCrudService } from '@common-modules/generic-crud/generic-crud.service';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable, map } from 'rxjs';
import { ISapCodeGroupValidationDto } from './sap-code-group-validation.dto';

@Injectable()
export class SapCodeGroupsCrudService
  extends BaseService
  implements GenericCrudService<ISapCodeGroupDto, number>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/sap-code-groups`;
  }

  getAll(): Observable<ISapCodeGroupDto[]> {
    return this.httpCacheClient.get<ISapCodeGroupDto[]>(this._entityUrl);
  }

  create(entity: ISapCodeGroupDto): Observable<ISapCodeGroupDto> {
    return this.httpCacheClient.post<ISapCodeGroupDto>(this._entityUrl, entity);
  }

  update(entity: ISapCodeGroupDto): Observable<ISapCodeGroupDto> {
    return this.httpCacheClient.put<ISapCodeGroupDto>(this._entityUrl, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  validate(entity: ISapCodeGroupDto, isCreate?: boolean): Observable<ISapCodeGroupValidationDto> {
    return this.httpCacheClient.post<ISapCodeGroupValidationDto>(
      `${this._entityUrl}/validate?isCreate=${isCreate ? 'true' : 'false'}`,
      entity
    );
  }

  getId(entity: ISapCodeGroupDto): number {
    return entity.codeGroupId;
  }
}
