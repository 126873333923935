import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IHierarchyElementTypeDto } from '@common-modules/dependencies/he/hierarchy-element-type.dto';
import { IHierarchyElementTypes } from '@common-modules/dependencies/he/hierarchy.constants';
import { GenericCardSettings } from '@common-modules/shared-component/generic-card/generic-card-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DragListCustomSettings } from '@common-modules/shared/core/drag-list-custom/drag-list-custom-settings';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { Subject } from 'rxjs';

const COMPONENT_SELECTOR = 'wlm-family-relations-selector';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './family-relations-selector.component.html',
  styleUrls: ['./family-relations-selector.component.scss'],
})
export class FamilyRelationsSelectorComponent implements OnInit, OnDestroy {
  private _availableItems: IHierarchyElementTypeDto[];
  get availableItems(): IHierarchyElementTypeDto[] {
    return this._availableItems ?? [];
  }
  @Input() set availableItems(value: IHierarchyElementTypeDto[]) {
    this._availableItems = value;
    if (value) {
      this.currentAvailableItems = globalUtilsHelper.clone(value);
      this.buildTopItems(this.availableItems);
      this.buildBottomItems(this.availableItems);
      this.currentAvailableItems = this.removeFixedItems(this.currentAvailableItems);
      this.syncListItems();
    }
  }

  private _selectedItemIds: string[];
  get selectedItemIds(): string[] {
    return this._selectedItemIds;
  }
  @Input() set selectedItemIds(value: string[]) {
    this._selectedItemIds = value;
    if (value) {
      this.syncListItems();
    }
  }

  @Input() isReadOnly: boolean;
  @Output() valueChanges = new EventEmitter<IHierarchyElementTypeDto[]>();

  private readonly _topItemsIds = [IHierarchyElementTypes.Group];
  private readonly _bottomItemsIds = [IHierarchyElementTypes.DZ, IHierarchyElementTypes.DMA];
  private readonly _fixedItemsIds = this._topItemsIds.concat(this._bottomItemsIds);

  public T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  currentAvailableItems: IHierarchyElementTypeDto[] = [];
  private _selectedItems: IHierarchyElementTypeDto[] = [];
  public get selectedItems(): IHierarchyElementTypeDto[] {
    return this._selectedItems;
  }
  public set selectedItems(value: IHierarchyElementTypeDto[]) {
    this._selectedItems = value;
  }
  topItemsData: IHierarchyElementTypeDto[] = [];
  bottomItemsData: IHierarchyElementTypeDto[] = [];

  sourceListSettings: DragListCustomSettings;
  targetListSettings: DragListCustomSettings;
  fixedListSettings: DragListCustomSettings;
  cardSettings: GenericCardSettings<IHierarchyElementTypeDto>;
  reloadList$ = new Subject<void>();
  refreshList$ = new Subject<void>();

  ngOnInit(): void {
    this.prepareListsSettings();
  }

  ngOnDestroy(): void {
    console.log('destroyed');
  }

  prepareListsSettings() {
    this.sourceListSettings = new DragListCustomSettings({
      hideFilter: true,
      isReadOnly: this.isReadOnly,
    });

    this.targetListSettings = new DragListCustomSettings({
      hideFilter: true,
      isReadOnly: this.isReadOnly,
    });

    this.fixedListSettings = new DragListCustomSettings({
      hideFilter: true,
      isReadOnly: true,
      shrinkContainerHeight: true,
      allowDropCallback: () => false,
    });

    this.cardSettings = new GenericCardSettings<IHierarchyElementTypeDto>({
      title1Fn: (model) => model.hierarchyElementTypeName,
      useTitleCentered: true,
      resizeToContent: true,
    });
  }

  onSourceDroppedElement(het: IHierarchyElementTypeDto) {
    this.emitSelected();
  }

  onTargetDroppedElement(het: IHierarchyElementTypeDto) {
    this.currentAvailableItems = this.currentAvailableItems.filter(
      (item) => item.hierarchyElementTypeId !== het.hierarchyElementTypeId
    );
    this.emitSelected();
  }

  onReorderedElements(elements: IHierarchyElementTypeDto[]) {
    this.selectedItems = elements;
    this.emitSelected();
  }

  private emitSelected(): void {
    const totalSelected = [...this.topItemsData, ...this.selectedItems, ...this.bottomItemsData];
    //const totalSelectedIds = totalSelected.map((item) => item.hierarchyElementTypeId);
    this.valueChanges.emit(totalSelected);
  }

  syncListItems(): void {
    // Extract from available items the currently selected dtos.
    this.selectedItems = this.buildSelectedItems();

    // Allow only to select items that are not currently selected.
    this.currentAvailableItems = this.currentAvailableItems.filter(
      (availableItem) =>
        !this.selectedItems.find(
          (selectedItem) =>
            selectedItem.hierarchyElementTypeId === availableItem.hierarchyElementTypeId
        )
    );
  }

  private buildSelectedItems(): IHierarchyElementTypeDto[] {
    const draggableItems = this.removeFixedItems(this.availableItems);
    return this.selectedItemIds
      .map((selectedId) =>
        draggableItems.find((item) => selectedId === item.hierarchyElementTypeId)
      )
      .filter(Boolean);
  }

  private removeFixedItems(hets: IHierarchyElementTypeDto[]): IHierarchyElementTypeDto[] {
    const result = hets.filter(
      (het) => !this._fixedItemsIds.find((id) => id === het.hierarchyElementTypeId)
    );
    return result;
  }

  private buildTopItems(hets: IHierarchyElementTypeDto[]): void {
    this.topItemsData = [];
    this._topItemsIds.forEach((topItemId) => {
      const selected = hets.find((het) => het.hierarchyElementTypeId === topItemId);
      if (selected) {
        this.topItemsData.push(selected);
      }
    });
  }

  private buildBottomItems(hets: IHierarchyElementTypeDto[]): void {
    this.bottomItemsData = [];
    this._bottomItemsIds.forEach((bottomItemId) => {
      const selected = hets.find((het) => het.hierarchyElementTypeId === bottomItemId);
      if (selected) {
        this.bottomItemsData.push(selected);
      }
    });
  }
}
