import { Component, Input, OnInit } from '@angular/core';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { LocalGridComponent } from '@common-modules/wlm-grid/local-grid/local-grid.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExcelExportEvent } from '@progress/kendo-angular-grid';
import { Subscription, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppModules } from '../../app-modules.enum';
import { IGridSettings } from '../../constants/grid.constants';
import { ExcelHelperService } from '../../exports/service/excel-helper.service';
import { GridColumnHelperService } from '../../helpers/grid-column-helper.service';
import { GridHelperService } from '../../helpers/grid-helper.service';
import { ObjectHelperService } from '../../helpers/object-helper.service';
import { IAlgorithmDto } from '../../model/algorithm/algorithm.dto';
import { GridColumnSetting } from '../../model/grid/grid-column-setting';
import { SimpleColumn } from '../../model/shared/simple-column';
import { UnitTypeConversionViewDto } from '../../model/uom/unit-type-conversion-view.dto';
import { GlobalsService } from '../../services/globals.service';
import { UoMService } from '../../uom/uom.service';

const COMPONENT_SELECTOR = 'wlm-export-excel-button';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './export-excel-button.component.html',
  styleUrls: ['./export-excel-button.component.scss'],
})
export class ExportExcelButtonComponent implements OnInit {
  private _grid: GenericGridComponent;
  private _headerProcessed: boolean;
  private _exportableGridSettings: IGridSettings;
  private _sheet: any;
  units: UnitTypeConversionViewDto[];
  algorithms: IAlgorithmDto[];
  columnEnums: Map<string, Map<any, string>>;
  readonly headerIndex = 0;
  rowsData: any[];
  processedRows: SimpleColumn[][];

  defaultBooleanMapMethodName = 'getBooleanColumnDefaultMapping';

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;
  excelSubscription: Subscription;

  @Input() grid: GenericGridComponent;
  @Input() localGrid: LocalGridComponent;
  @Input() showLabel: boolean;

  private _visibleColumns: GridColumnSetting[];
  public get visibleColumns(): GridColumnSetting[] {
    return this._visibleColumns;
  }
  @Input() public set visibleColumns(v: GridColumnSetting[]) {
    this._visibleColumns = v ? v.filter((x) => x.visible) : [];
  }

  @Input() disabled = false;

  constructor(
    private gridColumnHelper: GridColumnHelperService,
    private gridHelperService: GridHelperService,
    private globalService: GlobalsService,
    private uomService: UoMService,
    private objectHelperService: ObjectHelperService,
    private readonly _excelService: ExcelHelperService
  ) {}

  ngOnInit(): void {}

  initializeExcel() {
    const grid = this.grid ? this.grid : this.localGrid;
    this.excelSubscription = grid.grid?.excelExport?.subscribe((x) => this.onExcelExport(x));
  }

  onExcelExport(e: ExcelExportEvent): void {
    this._sheet = e.workbook.sheets[0];

    this._headerProcessed = false;
    for (let row = 0; row < this.processedRows.length; row++) {
      if (!this._headerProcessed) {
        this.updateHeaders(this.processedRows[row]);
        this._headerProcessed = true;
      }

      this.updateRow(this.processedRows[row], row + 1);
    }

    // This should not be necessary due to same ref, but just in case we clone the sheet.
    e.workbook.sheets[0] = this._sheet;
    // Sanitize the whole workbook, in case more than one page is exported.
    this._excelService.sanitizeWorkbook(e.workbook);

    this.excelSubscription.unsubscribe();
  }

  updateRow(simpleColumns: SimpleColumn[], rowIndex: number) {
    this._sheet.rows[rowIndex].cells.forEach((row, index) => {
      row.value = simpleColumns[index].value;
    });
  }

  updateHeaders(simpleColumns: SimpleColumn[]) {
    this._sheet.rows[this.headerIndex].cells.forEach((header, index) => {
      header.value = simpleColumns[index].columnTitle;
    });
  }

  exportToExcel() {
    this.initializeExcel();

    const excludedTypes = this.gridHelperService.getColumnTypesNonExportable();
    const grid = this.grid ?? this.localGrid;
    // the gridsetting must be clone to avoid modify the original gridsetting object
    this._exportableGridSettings = this.objectHelperService.clone(grid.gridSettings);

    // This removes the non-exportable columns
    this._exportableGridSettings.gridColumnSettings =
      this._exportableGridSettings.gridColumnSettings.filter(
        (f) => !excludedTypes.includes(f.type) && f.visible
      );

    const columnEnums$ = this.gridColumnHelper.getExportableEnums(
      this._exportableGridSettings.gridColumnSettings
    );
    const units$ = this.uomService.getAllDefaultUoM();
    const algorithms$ = this.globalService.getAlgorithms();
    const rows$ = this.grid ? this.grid.gridBinding.allData() : this.localGrid.allData();

    forkJoin([units$.pipe(take(1)), algorithms$.pipe(take(1)), columnEnums$.pipe(take(1)), rows$])
      .pipe(untilDestroyed(this))
      .subscribe(([units, algorithms, columnEnums, rows]) => {
        const hidden = this._exportableGridSettings.gridColumnSettings
          .filter((f) => !f.visible)
          .map((m) => m.field);
        this.rowsData = [];

        // this removes the hidden columns
        rows?.data?.forEach((row) => {
          const filtered = Object.keys(row)
            .filter((key) => !hidden.includes(key))
            .reduce((obj, key) => {
              obj[key] = row[key];
              return obj;
            }, {});

          this.rowsData.push(filtered);
        });

        const columnsProcesed$ = this.gridColumnHelper.processRowDataValuesBulk(
          this.rowsData,
          this._exportableGridSettings.gridColumnSettings,
          units,
          algorithms,
          columnEnums
        );

        forkJoin(columnsProcesed$).subscribe((columns: SimpleColumn[][]) => {
          this.processedRows = columns;
          const grid = this.grid ?? this.localGrid;
          grid.grid.saveAsExcel();
        });
      });
  }
}
