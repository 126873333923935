import { Injectable, Injector } from '@angular/core';
import { ApplyMvEstimationResponse } from '@water-loss//features/data-validation/models/apply-mv-estimation-response';
import { Observable } from 'rxjs';
import { BaseService } from '../../shared/base.service';

@Injectable({ providedIn: 'root' })
export class MvEstimationsService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  get url() {
    return `${this.apiUrl}/mv/estimations`;
  }

  setPreviousValue(
    signalId: number,
    request: { [key: string]: any }
  ): Observable<ApplyMvEstimationResponse> {
    return this.httpCacheClient.post<ApplyMvEstimationResponse>(
      `${this.url}/${signalId}/previous-date`,
      request
    );
  }

  copySignal(
    signalId: number,
    request: { [key: string]: any }
  ): Observable<ApplyMvEstimationResponse> {
    return this.httpCacheClient.post<ApplyMvEstimationResponse>(
      `${this.url}/${signalId}/copy`,
      request
    );
  }
}
