import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { BiDefaultParamsPopupComponent } from '@common-modules/dynamic-layout/components/bi-default-params-popup/bi-default-params-popup.component';
import { BiDefaultParamsPopupParams } from '@common-modules/dynamic-layout/components/models/bi-default-params-popup-params';
import { WidgetDefinitionSettings } from '@common-modules/dynamic-layout/models/widget-definition-settings';
import { DynamicParamsService } from '@common-modules/dynamic-layout/services/dynamic-params.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { UtilsHelperService } from '@common-modules/shared/helpers/utils-helper.service';
import { FlatTreeNode } from '@common-modules/shared/tree/flat-tree-node';
import { Observable } from 'rxjs';

import { BiDefaultParamsPopupResult } from '../../models/bi-default-params-popup-result';

const COMPONENT_SELECTOR = 'wlm-widget-selector-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './widget-selector-item.component.html',
  styleUrls: ['./widget-selector-item.component.scss'],
})
export class WidgetSelectorItemComponent implements OnInit {
  @Input() node: FlatTreeNode<string, WidgetDefinitionSettings>;
  @Input() isExpanded: boolean;

  @Output() definitionSelected = new EventEmitter<WidgetDefinitionSettings>();
  @Output() groupToggled = new EventEmitter<string>();

  readonly T_SCOPE = `${AppModules.BI}.${COMPONENT_SELECTOR}`;

  constructor(
    private _utilsService: UtilsHelperService,
    private _objectHelperService: ObjectHelperService,
    private _dialogService: DialogService,
    private _dynamicParamsService: DynamicParamsService
  ) {}

  ngOnInit(): void {}

  onDefinitionSelected(initialDefinition: WidgetDefinitionSettings): void {
    const widgetInstanceKey = this._utilsService.generateGuid();
    const definition = this._objectHelperService.serializedClone(initialDefinition);
    definition.widgetSettings.widgetInstanceKey = widgetInstanceKey;
    if (definition.widgetSettings.paramsType) {
      this.openConfigPopup(definition).subscribe((success) => {
        if (success) {
          this.definitionSelected.emit(definition);
        }
      });
    } else {
      this.definitionSelected.emit(definition);
    }
  }

  onGroupToggle(): void {
    this.groupToggled.emit(this.node.id);
  }

  openConfigPopup(definition: WidgetDefinitionSettings): Observable<BiDefaultParamsPopupResult> {
    const dialogConfig = new MatDialogConfig();
    const { widgetInstanceKey, title, baseTitle, params, defaultFilters, paramsType } =
      definition.widgetSettings;
    const data: BiDefaultParamsPopupParams = {
      widgetInstanceKey,
      title,
      baseTitle,
      mode: 'create',
      params,
      defaultFilters,
      paramsType,
    };
    dialogConfig.data = data;

    // dialogConfig.height = this._dynamicParamsService.getParamsPopupHeightByType(paramsType);
    const additionalSettings = {
      autoHeight: true,
    };

    const popupRef = this._dialogService.openComponent(
      BiDefaultParamsPopupComponent,
      dialogConfig,
      additionalSettings
    );
    return popupRef.afterClosed();
  }
}
