import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HistoricalChartEditedEvent } from '@common-modules/dependencies/ne-configuration/historical-chart-edited-event';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { OperationType } from '@common-modules/shared/model/shared/operation-type.enum';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseChartComponent } from '../base-chart/base-chart.component';
import { GenericChartComponent } from '../generic-chart/generic-chart.component';
import { IChartDataParameters } from '../models/chart-data-parameters';
import { GCartesianCustomChartSerie } from '../models/generic-chart-settings/g-cartesian-custom-chart-series';
import { GChartCustomItemStyle } from '../models/generic-chart-settings/g-chart-custom-item-style';
import { GenericCartesianCustomChartSettings } from '../models/generic-chart-settings/generic-cartesian-custom-chart-settings';
import { ItemStylesByKeyModes } from '../models/generic-chart-settings/item-styles-by-key-modes';
import { GChartClickEvent } from '../models/generic-events/g-chart-click-event';
import { HistoricalChartDataParameters } from '../models/historical-chart-settings/historical-chart-data-parameters';
import { HistoricalChartDimensions } from '../models/historical-chart-settings/historical-chart-dimensions';
import { HistoricalChartResultSettings } from '../models/historical-chart-settings/historical-chart-result-settings';
import { HistoricalChartSettings } from '../models/historical-chart-settings/historical-chart-settings';
import { IHistoricalOperation } from '../models/historical-chart-settings/historical-operation';
import { HistoricalVersionDto } from '../models/historical-chart-settings/historical-version.dto';
import { ISaveHistoricalVersionResponse } from '../models/historical-chart-settings/save-historical-version';
import { BaseHistoricalChartService } from '../services/base-historical-chart.service';
import { BaseHistoricalValidationService } from '../services/base-historical-validations.service';
import { HistoricalChartSettingsService } from '../services/historical-chart-settings.service';

const COMPONENT_SELECTOR = 'wlm-historical-chart';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './historical-chart.component.html',
  styleUrls: ['./historical-chart.component.scss'],
})
export class HistoricalChartComponent extends BaseChartComponent implements OnInit {
  @ViewChild(GenericChartComponent) genericChart: GenericChartComponent;

  private _chartSettings: HistoricalChartSettings;
  genericChartSettingsSubscription: any;
  public get chartSettings(): HistoricalChartSettings {
    return this._chartSettings;
  }
  @Input() public set chartSettings(v: HistoricalChartSettings) {
    this._chartSettings = v;

    this.resetChart();
    this.instanceDataService();
    this.instanceValidationService();
    this.loadChart();
  }

  @Input() updateChartNewEvents$: Observable<HistoricalVersionDto[]>;
  @Input() updateChartEditedEvents$: Observable<HistoricalChartEditedEvent>;
  @Input() updateChartDeletedEvents$: Observable<HistoricalVersionDto>;

  @Input() discardAllConfiguration$: Observable<void>;
  @Input() saveHistoricalConfiguration$: Observable<any>;
  @Output() saveCompleted = new EventEmitter<ISaveHistoricalVersionResponse>();
  @Output() hasChanges = new EventEmitter<boolean>();
  @Output() hasErrors = new EventEmitter<boolean>();
  @Output() selectEvent = new EventEmitter<HistoricalVersionDto>();

  disableInnerLoading: any;
  dataLoaded: boolean;
  customSettings: GenericCartesianCustomChartSettings;
  readonly T_SCOPE = `${AppModules.WlmCharts}.${COMPONENT_SELECTOR}`;
  readonly chartHeigth = 400;

  get componentName(): string {
    return 'HistoricalChartComponent';
  }

  private _historicalConfigurations: HistoricalVersionDto[];
  private _originalConfigurations: HistoricalVersionDto[];
  private _frontendErrorConfigurations: HistoricalVersionDto[] = [];
  private _dataService: BaseHistoricalChartService;
  private _validationService: BaseHistoricalValidationService;
  private _selectedEvent: HistoricalVersionDto;
  private _editedEventIds = new Set<string>();

  constructor(
    _injector: Injector,
    private _historicalChartSettingsService: HistoricalChartSettingsService,
    private _objectHelperService: ObjectHelperService,
    private _dialogService: DialogService,
    private _arrayHelperService: ArrayHelperService,
    private _spinnerService: SpinnerService,
    private _dateHelperService: DateHelperService
  ) {
    super(_injector);
  }

  ngOnInit(): void {
    this.bindUpdateNewEvents();
    this.bindUpdateEditedEvents();
    this.bindUpdateDeletedEvents();
    this.bindSaveConfigurationEvent();
    this.bindDiscardAllEvent();
  }

  private bindDiscardAllEvent() {
    this.discardAllConfiguration$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.reloadChart();
    });
  }

  private bindSaveConfigurationEvent() {
    this.saveHistoricalConfiguration$.pipe(untilDestroyed(this)).subscribe((element) => {
      const operations = this.getOperationsToSave();
      const trackedDates = this._dataService.getDaysToRecalculate(
        this._historicalConfigurations,
        this._originalConfigurations
      );

      this.showDaysToRecalculatePopup(trackedDates).subscribe((isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this._spinnerService.setLoading(true, this.componentName);
        this._dataService
          .saveHistoricalConfiguration(
            operations,
            this._historicalConfigurations,
            this._originalConfigurations,
            element
          )
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (response) => {
              var historicalErrors = Object.values(response.historicalEventErrors);
              if (historicalErrors.length) {
                this.setApiErrorStyles(historicalErrors);
                this.setSelectedEvent(null);
              } else {
                this.reloadChart();
              }
              this._spinnerService.setLoading(false, this.componentName);
              this.saveCompleted.next(response);
            },
            error: (error) => {
              this._spinnerService.setLoading(false, this.componentName);
              this._dialogService.showErrorMessage(error);

              this.saveCompleted.next(null);
            },
          });
      });
    });
  }

  private bindUpdateNewEvents(): void {
    this.updateChartNewEvents$.pipe(untilDestroyed(this)).subscribe((newHistoricalEvents) => {
      const events$ = newHistoricalEvents.map((historicalEvent) => {
        return this._dataService.getEvent(historicalEvent);
      });
      forkJoin(events$).subscribe((events) => {
        this._historicalConfigurations.push(...newHistoricalEvents);
        this.checkHasChanges();

        const processedSeries = this._historicalChartSettingsService.getProcessed(events);
        const serie = this.getCurrentSerie();
        serie.data = serie.data.concat(processedSeries);

        newHistoricalEvents.forEach((newEvent) => {
          this._editedEventIds.add(newEvent.id);
        });

        this.buildEventStyles().subscribe((stylesByKey) => {
          this.setSeriesStyles(serie, stylesByKey);
        });
      });
    });
  }

  private bindUpdateEditedEvents(): void {
    this.updateChartEditedEvents$
      .pipe(untilDestroyed(this))
      .subscribe(({ editedHistoricalEvent, hasChanges }) => {
        this._dataService.getEvent(editedHistoricalEvent).subscribe((event) => {
          const processedSeries = this._historicalChartSettingsService.getProcessed([event]);

          const editedEvent = processedSeries[0];

          const serie = this.getCurrentSerie();
          const newData = serie.data.map((item) => {
            if (this.getId(item) == this.getId(editedEvent)) {
              return editedEvent;
            }
            return item;
          });

          let editedConfigIndex = this._historicalConfigurations.findIndex(
            (x) => x.id == editedHistoricalEvent.id
          );

          this._historicalConfigurations[editedConfigIndex] = editedHistoricalEvent;
          this.checkHasChanges();

          serie.data = newData;

          // Check if event has changes to add to edited collection

          if (this.checkEventHasChanges(editedHistoricalEvent)) {
            this._editedEventIds.add(editedHistoricalEvent.id);
          } else {
            this._editedEventIds.delete(editedHistoricalEvent.id);
          }

          this.buildEventStyles().subscribe((stylesByKey) => {
            this.setSeriesStyles(serie, stylesByKey);
          });
        });
      });
  }

  private bindUpdateDeletedEvents() {
    this.updateChartDeletedEvents$.subscribe((eventToDelete) => {
      this.showRemoveCurrentConfigPopup(eventToDelete).subscribe((isConfirmed) => {
        if (!isConfirmed) {
          this.setSelectedEvent(eventToDelete);
          return;
        }
        this._historicalConfigurations = this._historicalConfigurations.filter(
          (conf) => conf.id !== eventToDelete.id
        );
        this.checkHasChanges();

        const serie = this.getCurrentSerie();
        const dataWithoutSerie = serie.data.filter((item) => this.getId(item) !== eventToDelete.id);

        serie.data = dataWithoutSerie;
        this.genericChart.updateSeries(this.customSettings.series);

        this.buildEventStyles().subscribe((stylesByKey) => {
          this.setSeriesStyles(serie, stylesByKey);
        });
      });
    });
  }

  /**
   * Removes all previous styles and only sets backend error states.
   */
  private setApiErrorStyles(historicalEventErrors: HistoricalVersionDto[]): void {
    const conflictStyles = this.markEventAsError(historicalEventErrors);
    const jointStyles = this.joinStyles(conflictStyles);
    this.setSeriesStyles(this.customSettings.series[0], jointStyles);
  }

  private checkHasChanges(): boolean {
    const getKey = (item: HistoricalVersionDto) => item.id;
    const originalSorted = this._arrayHelperService.sortObjects(
      this._originalConfigurations,
      getKey
    );
    const currentSorted = this._arrayHelperService.sortObjects(
      this._historicalConfigurations,
      getKey
    );
    const hasChanges = !this._objectHelperService.deepEqual(
      // Done because an instance or HistoricalVersionDto class and an plain object with all its properties are not equal.
      this.normalizeEvents(originalSorted),
      this.normalizeEvents(currentSorted)
    );
    this.hasChanges.emit(hasChanges);
    return hasChanges;
  }

  private checkEventHasChanges(event: HistoricalVersionDto): boolean {
    const originalEvent = this._originalConfigurations.find((x) => x.id == event.id);

    const hasChanges = !this._objectHelperService.deepEqual(
      // Done because an instance or HistoricalVersionDto class and an plain object with all its properties are not equal.
      this.normalizeEvents([originalEvent]),
      this.normalizeEvents([event])
    );

    return hasChanges;
  }

  private setSeriesStyles(
    serie: GCartesianCustomChartSerie,
    stylesByKey: Map<string, Map<string, GChartCustomItemStyle>>,
    updateAll = true
  ) {
    serie.itemStyleByKey = stylesByKey;
    this.genericChart.updateSeries(this.customSettings.series, updateAll);
  }

  private buildFrontendErrorStyles(): Observable<GChartCustomItemStyle[]> {
    let result$;
    if (!this._validationService) {
      result$ = of({});
    }
    result$ = this._validationService
      .getConflictsHistoricalConfiguration(this._historicalConfigurations)
      .pipe(
        map((frontendErrorConfigurations) => {
          this._frontendErrorConfigurations = frontendErrorConfigurations;
          this.checkHasErrors();
          const stylesByKey = this.markEventAsError(frontendErrorConfigurations);
          return stylesByKey;
        })
      );
    return result$;
  }

  private checkHasErrors(): void {
    const hasError = this._frontendErrorConfigurations.length !== 0;
    this.hasErrors.emit(hasError);
  }

  private buildEventStyles(): Observable<Map<string, Map<string, GChartCustomItemStyle>>> {
    return forkJoin([
      this.buildFrontendErrorStyles(),
      this.buildEditedStyles(),
      this.buildSelectedStyles(),
    ]).pipe(
      map(([frontendErrorStyles, editedStyles, selectedStyles]) => {
        const allStyles = [].concat(frontendErrorStyles, editedStyles, selectedStyles);
        const jointStyles = this.joinStyles(allStyles);
        return jointStyles;
      })
    );
  }

  private setSelectedStyle(
    serie: GCartesianCustomChartSerie,
    selectedEvent: HistoricalVersionDto
  ): void {
    this.buildSelectedStyles().subscribe((selectedStyles) => {
      if (selectedStyles.length !== 1) {
        return;
      }

      if (!serie.itemStyleByKey) {
        serie.itemStyleByKey = new Map<string, Map<string, GChartCustomItemStyle>>();
      }

      Array.from(serie?.itemStyleByKey?.values() ?? []).forEach((itemStylesByMode) => {
        if (itemStylesByMode.has(ItemStylesByKeyModes.Selected)) {
          itemStylesByMode.delete(ItemStylesByKeyModes.Selected);
        }
      });

      // Currently only one element can be selected at the same time.
      const selectedElement = selectedStyles[0];

      if (!serie.itemStyleByKey.has(selectedEvent.id)) {
        serie.itemStyleByKey.set(selectedEvent.id, new Map<string, GChartCustomItemStyle>());
      }

      const selectedItemStyles = serie.itemStyleByKey.get(selectedEvent.id);
      selectedItemStyles.set(ItemStylesByKeyModes.Selected, selectedElement);

      // Refresh the styles with new selected element
      this.setSeriesStyles(serie, serie.itemStyleByKey, false);
    });
  }

  private joinStyles(
    allStyles: GChartCustomItemStyle[]
  ): Map<string, Map<string, GChartCustomItemStyle>> {
    const jointStyles = new Map<string, Map<string, GChartCustomItemStyle>>();
    allStyles.forEach((style) => {
      if (!jointStyles.has(style.itemId)) {
        jointStyles.set(style.itemId, new Map());
      }
      const itemStyles = jointStyles.get(style.itemId);
      itemStyles.set(style.mode, style);
    });

    return jointStyles;
  }

  private buildEditedStyles(): Observable<GChartCustomItemStyle[]> {
    const editedIds = Array.from(this._editedEventIds.values());

    const editedStyles = this._historicalChartSettingsService.buildCustomItemStyles(
      editedIds,
      ItemStylesByKeyModes.Edited
    );
    return of(editedStyles);
  }

  private buildSelectedStyles(): Observable<GChartCustomItemStyle[]> {
    let selectedStyles = [];
    if (this._selectedEvent) {
      selectedStyles = this._historicalChartSettingsService.buildCustomItemStyles(
        [this._selectedEvent.id],
        ItemStylesByKeyModes.Selected
      );
    }
    return of(selectedStyles);
  }

  private markEventAsError(conflictEvents: HistoricalVersionDto[]): GChartCustomItemStyle[] {
    return this._historicalChartSettingsService.buildCustomItemStyles(
      conflictEvents.map((event) => event.id),
      ItemStylesByKeyModes.Error
    );
  }

  // The result of this method will be different Dtos for each implementation.
  protected getSerieData(params: HistoricalChartDataParameters): Observable<any> {
    return this._dataService.getData(params);
  }

  private resetChart() {
    this.genericChartSettingsSubscription?.unsubscribe();

    this.resetData();
    this.customSettings = null;
    this.dataLoaded = false;
  }

  private loadChart(dataParameters?: HistoricalChartDataParameters) {
    this.setLoading(true);

    this.genericChartSettingsSubscription = this.getGenericChartSettings(dataParameters).subscribe({
      next: (settings) => {
        this.hasChanges.emit(false);
        this.customSettings = settings;
        this.dataLoaded = true;
        this.setLoading(false);
      },
      error: (error) => {
        this.hasChanges.emit(false);
        this.dataLoaded = true;
        this.setLoading(false);
        this.customSettings = null;
        throw error;
      },
    });
  }

  getGenericChartSettings(
    dataParameters?: HistoricalChartDataParameters
  ): Observable<GenericCartesianCustomChartSettings> {
    return this.getSerieData(dataParameters ?? this.chartSettings.dataParameters).pipe(
      switchMap((data: HistoricalVersionDto[]) => {
        this._historicalConfigurations = data;
        this._originalConfigurations = this._objectHelperService.clone(data);
        return this._dataService.mapDataToGenericSettings(data);
      }),
      switchMap((settings: HistoricalChartResultSettings) => {
        return this._historicalChartSettingsService.getHistoricalChart(
          settings,
          this.chartSettings.dataParameters
        );
      })
    );
  }

  onElementClick(event: GChartClickEvent): void {
    const selectedEventId = event.data[HistoricalChartDimensions.Id];
    const selectedEvent: any = this._historicalConfigurations.find(
      (event) => event.id === selectedEventId
    );

    this.setSelectedEvent(selectedEvent);
    this.setSelectedStyle(this.getCurrentSerie(), selectedEvent);
  }

  private setSelectedEvent(event: HistoricalVersionDto): void {
    this._selectedEvent = event;
    this.selectEvent.emit(event);
  }

  private getCurrentSerie = () => {
    return this.customSettings.series[0];
  };

  private getId = (item: any[]) => item[HistoricalChartDimensions.Id];

  private instanceDataService() {
    this._dataService = this.getInjector().get(this.chartSettings?.dataParameters?.dataService);
  }

  private instanceValidationService() {
    if (this.chartSettings?.validationService) {
      this._validationService = this.getInjector().get(this.chartSettings?.validationService);
    }
  }

  private getInjector(): Injector {
    return this.chartSettings?.injector ?? this._injector;
  }

  setDateParams(startDate: Date, endDate: Date) {
    throw new Error('Method not implemented.');
  }

  getParams(): IChartDataParameters {
    throw new Error('Method not implemented.');
  }

  setParams(newParams: IChartDataParameters) {
    throw new Error('Method not implemented.');
  }

  private getOperationsToSave(): IHistoricalOperation[] {
    const operations: IHistoricalOperation[] = [];
    //TODO check difference between original and edited configuration

    this._originalConfigurations
      .filter(
        (originalConfig) =>
          !this._historicalConfigurations.some((newConf) => newConf.id == originalConfig.id)
      )
      ?.forEach((deletedConf) => {
        const isCurrent = this.isCurrentConfig(deletedConf);
        operations.push(
          this.getOperation(OperationType.Delete, deletedConf, isCurrent, deletedConf)
        );
      });

    this._historicalConfigurations
      .filter(
        (originalConfig) =>
          !this._originalConfigurations.some((newConf) => newConf.id == originalConfig.id)
      )
      ?.forEach((addedConf) => {
        operations.push(this.getOperation(OperationType.Create, addedConf, false, null));
      });

    this._historicalConfigurations.forEach((newConf) => {
      const originalConf = this._originalConfigurations.find(
        (ori) =>
          ori.id == newConf.id && !this._objectHelperService.deepEqual({ ...ori }, { ...newConf })
      );

      if (originalConf) {
        let wasOriginalAndChanged = false;
        if (!this.isCurrentConfig(newConf) && this.isCurrentConfig(originalConf)) {
          wasOriginalAndChanged = true;
        }

        operations.push(
          this.getOperation(OperationType.Update, newConf, wasOriginalAndChanged, originalConf)
        );
      }
    });

    return operations;
  }

  private getOperation(
    type: OperationType,
    configuration: HistoricalVersionDto,
    originalWasCurrent: boolean,
    originalConfiguration: HistoricalVersionDto
  ): IHistoricalOperation {
    return {
      type,
      configuration: this._dataService.cloneConfiguration(configuration),
      originalWasCurrent,
      originalConfiguration: this._dataService.cloneConfiguration(originalConfiguration),
    };
  }

  private isCurrentConfig(config: HistoricalVersionDto) {
    return config.validTo.getFullYear() == this._dateHelperService.currentRangeEndYear;
  }

  private reloadChart() {
    this.resetData();
    this.loadChart();
    this.hasChanges.emit(false);
    this.hasErrors.emit(false);
  }

  private resetData(): void {
    this._editedEventIds = new Set<string>();
    this.setSelectedEvent(null);
  }

  private normalizeEvents(array: HistoricalVersionDto[]) {
    return array.map((item) => ({ ...item }));
  }

  private showRemoveCurrentConfigPopup(eventToDelete: HistoricalVersionDto): Observable<boolean> {
    if (!this.isCurrentConfig(eventToDelete)) {
      return of(true); // Can be removed
    }
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.remove-current-config`,
      icon: 'warning',
    });

    return this._dialogService.showTranslatedDialogMessage(dialogSettings).pipe(
      map((result) => {
        const isConfirmed = result.result;
        return isConfirmed;
      })
    );
  }

  private showDaysToRecalculatePopup(trackedDates: Date[]): Observable<boolean> {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.${
        trackedDates.length === 1 ? 'day-to-recalculate' : 'days-to-recalculate'
      }`,
      params: {
        amount: trackedDates.length,
      },
      icon: 'warning',
    });

    return this._dialogService.showTranslatedDialogMessage(dialogSettings).pipe(
      map((result) => {
        const isConfirmed = result.result;
        return isConfirmed;
      })
    );
  }
}
