import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IGridSettings } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { LocalGridComponent } from '@common-modules/wlm-grid/local-grid/local-grid.component';

import { SAProfileSummary } from '../models/sa-profile-summary';

const COMPONENT_SELECTOR = 'wlm-sa-summary';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './sa-summary.component.html',
  styleUrls: ['./sa-summary.component.scss'],
})
export class SASummaryComponent implements OnInit {
  @ViewChild(LocalGridComponent) localGrid: LocalGridComponent;

  @Input() profileSummary: SAProfileSummary[];
  @Input() persistencyArea: string = 'summary';

  gridSettings: IGridSettings;
  gridName = 'SaSummary';

  constructor(private _gridService: GridSettingsService) {}

  ngOnInit(): void {
    this.getGridSettings();
  }

  getGridSettings() {
    this._gridService.getGridSettingsByName(this.gridName).subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  private resetSummary() {
    // this.summaryValuesChanged.emit([]);
    // this.localGrid.clearFilters();
    // this.resetCompleteHandler$.next();
  }
}
