import { WidgetDefinitionSettings } from '@common-modules/dynamic-layout/models/widget-definition-settings';
import { FlatTreeNode } from '@common-modules/shared/tree/flat-tree-node';

export class WidgetSelectorSettings {
  definitions: WidgetDefinitionSettings[];
  groups: Map<string, FlatTreeNode<string, WidgetDefinitionSettings>>;

  constructor(init: WidgetSelectorSettings) {
    Object.assign(this, init);
  }
}
