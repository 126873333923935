import { ChartConfiguration } from '@water-loss//features/shared/charts/model/chart-configuration';
import { Type } from 'class-transformer';
import { IChartConfiguration } from '../../charts/model/chart/chart-configuration';
import { IFiltrableItemDto } from '../filtrable-items/filtrable-item.dto';

export class WorkspaceDefinitionDto {
  @Type(() => ChartConfiguration)
  chartConfigurations: IChartConfiguration[];
  cartFiltrableItems: IFiltrableItemDto[];
  entityTypes: number[];
}
