// prettier-ignore
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MapHelperService } from '../map-helper.service';
import { KpiTranslationKeysMapping } from './models/kpi-translations-mapping';
import { MapThematicKpi } from './models/map-thematic-kpi';
import { KpiSettingValue } from './models/map-thematic-kpi-value';

const COMPONENT_SELECTOR = 'wlm-map-thematic';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './map-thematic.component.html',
  styleUrls: ['./map-thematic.component.scss'],
})
export class MapThematicComponent implements OnInit {
  private readonly _heTypeFieldname = 'heType';
  private readonly _kpiFieldname = 'kpi';

  private _visibleThematicsIds: string[];
  public get visibleThematicsIds(): string[] {
    return this._visibleThematicsIds;
  }
  @Input()
  public set visibleThematicsIds(value: string[]) {
    this._visibleThematicsIds = value;

    if (this.form && !(value?.length > 0)) {
      this.form.reset();
      this.form.disable();
    }
  }

  @Input() displayed: boolean;

  private _thematics: MapThematicKpi[];

  public get thematics(): MapThematicKpi[] {
    return this._thematics;
  }
  @Input() public set thematics(value: MapThematicKpi[]) {
    this._thematics = value;
    if (this.form) {
      this.setFormControlVariables();
    }
  }

  @Output() visibleThematicsIdsChange = new EventEmitter<string[]>();
  @Output() onLoad = new EventEmitter<void>();
  @Output() kpiInfoChange = new EventEmitter<MapThematicKpi>();

  T_SCOPE = `${AppModules.Map}.${COMPONENT_SELECTOR}`;

  form: UntypedFormGroup;
  heTypeControl: AbstractControl;
  kpiControl: AbstractControl;
  hierarchyElements: { id: number; description: string }[];
  translationMapping = KpiTranslationKeysMapping;
  selectedCategoryKey: string;

  constructor(
    private _globalService: GlobalsService,
    private _mapHelperService: MapHelperService,
    private _formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this._globalService
      .getFiltrableGisHeLayers()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (layers) => {
          const thematicsHe = layers?.hierarchyElements.filter((he) =>
            this._mapHelperService.isThematicZone(he.gisLayerId)
          );

          this.hierarchyElements = thematicsHe.map((he) => {
            return { id: he.gisLayerId, description: he.description };
          });

          this.createForm();

          this.onLoad.emit();
        },
      });
  }

  onChangeEnableThematics($event) {
    if ($event.checked) {
      this.form.enable();
      return;
    }

    this.form.reset();
    this.form.disable();

    if (this.visibleThematicsIds?.length > 0) {
      this.applyThematic([]);
    }
  }

  radioChange(categoryKey: string) {
    this.selectedCategoryKey = categoryKey;
  }

  private createForm() {
    this.form = this._formBuilder.group({
      heType: [null, [Validators.required]],
      kpi: [null, [Validators.required]],
    });

    this.setFormControlVariables();

    if (!this.form.valid) {
      this.form.disable();
    }

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.onFormValueChanged());
  }

  private setFormControlVariables() {
    this.heTypeControl = this.form.controls[this._heTypeFieldname];
    this.kpiControl = this.form.controls[this._kpiFieldname];

    const values = this.getFormInitialValues();
    this.heTypeControl.setValue(+values.heType);
    this.kpiControl.setValue(values.kpi);

    this.notifyKpiInfoChange();
  }

  private notifyKpiInfoChange() {
    const selectedThematic =
      this.selectedCategoryKey && this.form.value.kpi
        ? new MapThematicKpi(this.selectedCategoryKey, [this.form.value.kpi])
        : null;

    this.kpiInfoChange.emit(selectedThematic);
  }

  private onFormValueChanged() {
    if (!this.form.valid) {
      return;
    }

    const thematicId = `${this.form.value.heType}-${this.form.value.kpi.kpiType}-${this.form.value.kpi.kpiProperty}`;
    this.applyThematic([thematicId]);
  }

  private applyThematic(thematicIds: string[]) {
    if (!thematicIds) {
      return;
    }

    this.visibleThematicsIds = thematicIds;
    this.visibleThematicsIdsChange.emit(this.visibleThematicsIds);

    this.notifyKpiInfoChange();
  }

  private getFormInitialValues(): { heType: number; kpi: KpiSettingValue } {
    const values = this.visibleThematicsIds?.[0]?.split('-');
    if (!values || values.length !== 3) {
      return { heType: null, kpi: null };
    }

    const heType = values.shift();

    const kpiValues = this.thematics.reduce((accumulator, array) => {
      return [...accumulator, ...array.values];
    }, [] as KpiSettingValue[]);

    const kpi = kpiValues.filter((v) => v.kpiType === values[0] && v.kpiProperty === values[1]);

    this.selectedCategoryKey = this.thematics
      .filter((category) =>
        category.values.some((v) => v.kpiType === values[0] && v.kpiProperty === values[1])
      )
      .map((m) => m.categoryKey)[0];

    return { heType: +heType, kpi: kpi?.[0] ? kpi[0] : null };
  }
}
