import { NgModule } from '@angular/core';
import { CommonFiltersModule } from '@common-modules/common-filters/common-filters.module';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { TelemetryFilterComponent } from './telemetry-filter/telemetry-filter.component';

const exported = [CommonFiltersModule];

@NgModule({
  declarations: [TelemetryFilterComponent],
  imports: [CommonFiltersModule, WlmSharedModule],
  exports: [...exported, TelemetryFilterComponent],
})
export class WlmFiltersModule {}
