import { Injectable, Injector } from '@angular/core';
import { AdditionalHttpOpts } from '@common-modules/cache/http-cache/additional-http-options';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { handleValidationError } from '@common-modules/shared/rxjs/operators/handle-validation-error.operator';
import {
  Schematic,
  SchematicSave,
  SchematicSaveDto,
} from '@common-modules/wlm-charts/core/models/schematics/schematic';
import { forkJoin, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class SchematicsService extends GridODataService<Schematic> {
  readonly saveProgress$: Observable<number>;

  constructor(injector: Injector, private _dialogsService: DialogService) {
    super(injector, '/api/schematic');
  }

  protected mapResponse(response: PagedResultDto<Schematic>): PagedResultDto<Schematic> {
    return response;
  }

  getOne(schematicName: string): Observable<Schematic> {
    return this.httpCacheClient.get(`${this.entityUrl}/${schematicName}`);
  }

  save(model: SchematicSave): Observable<any> {
    return forkJoin([
      globalUtilsHelper.fileToBase64(model.nodes),
      globalUtilsHelper.fileToBase64(model.links),
    ]).pipe(
      switchMap(([nodesStr, linksStr]) => {
        const data: SchematicSaveDto = {
          ...model,
          nodes: nodesStr,
          links: linksStr,
        };
        return this.httpCacheClient.post(this.entityUrl, data);
      }),
      handleValidationError(this._dialogsService)
    );
  }

  update(model: Schematic): Observable<Schematic> {
    return this.httpCacheClient.post(`${this.entityUrl}/update`, model);
  }

  delete(schematicName: string): Observable<any> {
    return this.httpCacheClient.delete(
      this.entityUrl,
      { avoid: true },
      {},
      new AdditionalHttpOpts({}),
      {
        name: schematicName,
      }
    );
  }

  private get entityUrl() {
    return `${this.apiUrl}/schematic`;
  }
}
