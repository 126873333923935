import { Component, Input, OnInit } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { AuthorizeService } from '@common-modules/shared/auth/services/authorize.service';
import { BaseComponent } from '@common-modules/shared/component/base.component';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { GroupDto } from '@common-modules/shared/model/roles/group.dto';
import { ProfileCrudDto } from '@common-modules/shared/model/roles/profileCrud.dto';
import { ProfileDialogsPathsDto } from '@common-modules/shared/model/roles/profileDialogsPaths.dto';
import { RolesService } from '@common-modules/shared/roles/roles.service';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

const COMPONENT_SELECTOR = 'wlm-profile-dialogs-hierarchy-grid';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './profile-dialogs-hierarchy-grid.component.html',
  styleUrls: ['./profile-dialogs-hierarchy-grid.component.scss'],
  host: {
    class: 'water-grid',
  },
})
export class ProfileDialogsHierarchyGridComponent extends BaseComponent implements OnInit {
  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;
  T_SCOPE_DIALOGS = `${AppModules.Dialogs}.crud-names`;

  profileDialogsPaths: ProfileDialogsPathsDto[];
  public isLoading = false;
  editingMode: boolean;

  componentCrud = 'WLMAdministrationCrud';

  private _groupId: GroupDto;
  public get group(): GroupDto {
    return this._groupId;
  }
  @Input() public set group(v: GroupDto) {
    this._groupId = v;
    if (this.group) {
      this.loadPermissionToEdit();
      this.loadProfileDialogsPathsGridByGroup();
    } else {
      this.profileDialogsPaths = null;
    }
  }
  @Input() fullSize = false;

  private _userCode: string;
  public get userCode(): string {
    return this._userCode;
  }
  @Input() public set userCode(userCode: string) {
    this._userCode = userCode;
    if (this.userCode) {
      this.editingMode = false;
      this.loadProfileDialogsPathsGridByUserCode();
    }
  }

  constructor(
    private readonly _rolesService: RolesService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _dialogService: DialogService,
    private readonly _authorizationService: AuthorizeService,
    private readonly _localizationService: LocalizationHelperService
  ) {
    super();
  }
  ngOnInit(): void {}

  private loadPermissionToEdit() {
    this._authorizationService.canAccess(this.componentCrud, 'u').subscribe({
      next: (canAccess) => {
        this.editingMode = canAccess;
      },
    });
  }

  private loadProfileDialogsPathsGridByUserCode() {
    this.isLoading = true;
    this._rolesService.getProfileDialogsPathsByUserCode(this.userCode).subscribe({
      next: (pr) => {
        this.setProfilesDialogsWithTitle(pr.permissions);
      },
    });
  }

  private loadProfileDialogsPathsGridByGroup() {
    this.isLoading = true;
    this._rolesService.getProfileDialogsPathsByGroupId(this.group.groupId).subscribe({
      next: (pr) => {
        this.setProfilesDialogsWithTitle(pr);
      },
    });
  }

  private setProfilesDialogsWithTitle(pr: ProfileDialogsPathsDto[]) {
    const obs$: Observable<ProfileDialogsPathsDto>[] = pr.map((profile) => {
      return this.translateCrudName(profile).pipe(
        map((title) => {
          profile.title = title;
          return profile;
        })
      );
    });

    forkJoin(obs$).subscribe((profiles) => {
      this.profileDialogsPaths = profiles;
      this.isLoading = false;
    });
  }

  private translateCrudName(crud: ProfileDialogsPathsDto): Observable<string> {
    const name = `${this.T_SCOPE_DIALOGS}.${crud.dialogName}`;
    return this._localizationService.get(name).pipe(
      map((translation) => {
        const crudTitle =
          // if translation is equal to name, means that the translation is not defined
          translation === name
            ? crud.dialogName
                ?.split(/(?=[A-Z])/)
                ?.filter((x) => x !== 'Crud')
                ?.join(' ')
            : translation;
        return crudTitle;
      })
    );
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    const userDto = parameters.parameters.get(TabDetailParameterName.user);
    this.userCode = userDto?.userCode;
  }

  init(): void {}

  crudChanged(
    event: MatCheckboxChange,
    profileDialogPath: ProfileDialogsPathsDto,
    fieldName: string
  ) {
    const checkBoxComponent = event.source as MatCheckbox;
    this.saveProfileCrud(
      checkBoxComponent,
      event.checked,
      new ProfileCrudDto(
        profileDialogPath.profileId,
        profileDialogPath.dialogId,
        fieldName,
        event.checked,
        this._authenticationService.userCode,
        profileDialogPath.dialogName,
        this.group.groupName
      )
    );
  }

  private saveProfileCrud(
    checkBoxComponent: MatCheckbox,
    checkedState: boolean,
    profileCrud: ProfileCrudDto
  ) {
    this.isLoading = true;
    checkBoxComponent.indeterminate = true;

    this._rolesService.setProfileCrud(profileCrud).subscribe({
      next: (response) => {
        this.saveFinished(checkBoxComponent);

        if (response.errors.length) {
          this._dialogService.showTranslatedMessage(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages.update-permission-error`,
              icon: 'error',
            })
          );
        } else {
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages.update-permission-success`,
            })
          );
        }

        this.loadProfileDialogsPathsGridByGroup();
      },
      error: () => {
        this.saveFinished(checkBoxComponent);
        checkBoxComponent.checked = !checkedState;
      },
    });
  }

  saveFinished(checkBoxComponent: MatCheckbox) {
    checkBoxComponent.indeterminate = false;
    this.isLoading = false;
  }

  get componentName() {
    return 'ProfileDialogsHierarchyGridComponent';
  }
}
