import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AlarmsService } from '@common-modules/shared/services/alarms.service';
import { ColumnBase, DataItem } from '@progress/kendo-angular-grid';
import { IAlarmSeverityTypeDto } from '../../dependencies/alarms/alarm-severity-type.dto';

import { WlmColumnComponent } from '../wlm-column/wlm-column.component';

const COMPONENT_SELECTOR = 'wlm-alarm-counter-column';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarm-counter-column.component.html',
  styleUrls: ['./alarm-counter-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => AlarmCounterColumnComponent),
    },
  ],
})
export class AlarmCounterColumnComponent extends WlmColumnComponent implements OnInit {
  @Input() alarmCriticalCounterField: string;
  @Input() alarmHighCounterField: string;
  @Input() alarmMediumCounterField: string;
  @Input() alarmLowCounterField: string;

  alarmSeverities: IAlarmSeverityTypeDto[];

  constructor(private _alarmsService: AlarmsService) {
    super();
  }

  ngOnInit(): void {
    this.getAlarmSeverityTypes();
  }

  getAlarmCounter(dataItem: DataItem, fieldName: string) {
    const counter = +dataItem[fieldName];
    return counter ? counter : 0;
  }

  getAlarmSeverityTypes() {
    this._alarmsService.getAlarmSeverityTypes().subscribe({
      next: (severities) => {
        this.alarmSeverities = severities;
      },
    });
  }
}
