import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TabDetailPanelParameters } from '../../dependencies/navigation/tab-detail-component';
import { DynamicLayoutComponent } from '../../dynamic-layout/dynamic-layout/dynamic-layout.component';
import { DynamicLayoutSettings } from '../../dynamic-layout/models/dynamic-layout-settings';
import { LayoutNodeTypes } from '../../dynamic-layout/models/layout-node-types';
import { DynamicLayoutService } from '../../dynamic-layout/services/dynamic-layout.service';
import { ResetFiltersAction } from '../../dynamic-layout/state/filters/filters.actions';
import { StateAreas } from '../../redux/models/state-areas';
import { StateScopeSettings } from '../../redux/models/state-scope-settings';
import { StateWidgetSettings } from '../../redux/models/state-widget-settings';
import { ReduxStateService } from '../../redux/redux-state.service';
import { AppModules } from '../../shared/app-modules.enum';
import { AuthenticationService } from '../../shared/auth/services/authentication.service';
import { BasePageComponent } from '../../shared/component/base-page.component';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';
import { IPendingChangesChecker } from '../../shared/pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from '../../shared/pending-changes/services/pending-changes-manager.service';

import { ResetUoMStateAction } from '../state/uom.action';
import { UOM_CONFIGURATION_COMPONENT_INSTANCE } from '../widgets/uom-configuration-widget/uom-configuration-widget.component';
import { UOM_FILTER_COMPONENT_INSTANCE } from '../widgets/uom-filter-widget/uom-filter-widget.component';
import { UoMScopes } from '../widgets/uom-scopes';
import { UOM_SELECTION_COMPONENT_INSTANCE } from '../widgets/uom-selection-widget/uom-selection-widget.component';

const COMPONENT_SELECTOR = 'wlm-uom-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './uom-page.component.html',
  styleUrls: ['./uom-page.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class UomPageComponent
  extends BasePageComponent
  implements OnInit, IPendingChangesChecker, OnDestroy
{
  @ViewChild(DynamicLayoutComponent) set layoutComponent(component: DynamicLayoutComponent) {
    if (component) {
      this._dynamicLayoutService.registerLayout([component]);
    }
  }

  // Not all actions/selectors must have the same action settings.
  private _scopeSettings = new StateScopeSettings({
    scope: UoMScopes.Main,
  });

  private readonly _pageSettingsKeys = {
    layoutKey: 'LayoutMain',
    layoutArea: 'UoM',
    widgetPage: 'uomPage',
    widgetModule: 'uomModule',
  };

  private _settings$ = new ReplaySubject<DynamicLayoutSettings>(1);

  private readonly _filtersInstance1 = 'filtersInstance1';
  private readonly _uomConfigInstance1 = 'uomConfigInstance1';

  private readonly _filterWidgetName = 'UomFilterWidgetComponent';
  private readonly _selectionWidgetName = 'UomSelectionWidgetComponent';
  private readonly _configurationWidgetName = 'UomConfigurationWidgetComponent';

  private readonly _stateWidgetSettings = new StateWidgetSettings({
    module: this._pageSettingsKeys.widgetModule,
    page: this._pageSettingsKeys.widgetPage,
    scopeInstanceKeys: {
      [StateAreas.UoM]: this._uomConfigInstance1,
      [StateAreas.Filters]: this._filtersInstance1,
    },
  });

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  pageId: string;
  settings: DynamicLayoutSettings;

  constructor(
    private _authenticationService: AuthenticationService,
    private _localization: LocalizationHelperService,
    private _state: ReduxStateService,
    private _dynamicLayoutService: DynamicLayoutService,
    private _pendingChangesService: PendingChangesManagerService
  ) {
    super();

    this._state.configure(this._stateWidgetSettings);
    this.hasRightPanel = false;

    this.pageId = this._stateWidgetSettings.page;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.buildTitles().subscribe((titles) => {
      this.settings = new DynamicLayoutSettings({
        layoutKey: this._pageSettingsKeys.layoutKey,
        layoutArea: this._pageSettingsKeys.layoutArea,
        widgetPage: this._pageSettingsKeys.widgetPage,
        widgetModule: this._pageSettingsKeys.widgetModule,
        currentUser: this._authenticationService.userCode,
        structure: [],
        defaultStructure: [
          {
            type: LayoutNodeTypes.Column,
            content: [
              {
                type: LayoutNodeTypes.Stack,
                height: 15,
                content: [
                  {
                    type: LayoutNodeTypes.Component,
                    widgetInstanceKey: UOM_FILTER_COMPONENT_INSTANCE,
                  },
                ],
              },
              {
                type: LayoutNodeTypes.Row,
                content: [
                  {
                    type: LayoutNodeTypes.Stack,
                    height: 85,
                    width: 25,
                    content: [
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: UOM_SELECTION_COMPONENT_INSTANCE,
                      },
                    ],
                  },
                  {
                    type: LayoutNodeTypes.Stack,
                    height: 85,
                    width: 75,
                    content: [
                      {
                        type: LayoutNodeTypes.Component,
                        widgetInstanceKey: UOM_CONFIGURATION_COMPONENT_INSTANCE,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        items: [
          {
            componentName: this._filterWidgetName,
            widgetInstanceKey: UOM_FILTER_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.Filters]: this._filtersInstance1,
            },
            title: titles.get(this._filterWidgetName),
          },
          {
            componentName: this._selectionWidgetName,
            widgetInstanceKey: UOM_SELECTION_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.UoM]: this._uomConfigInstance1,
            },
            title: titles.get(this._selectionWidgetName),
          },
          {
            componentName: this._configurationWidgetName,
            widgetInstanceKey: UOM_CONFIGURATION_COMPONENT_INSTANCE,
            scopeInstanceKeys: {
              [StateAreas.Filters]: this._filtersInstance1,
              [StateAreas.UoM]: this._uomConfigInstance1,
            },
            title: titles.get(this._configurationWidgetName),
          },
        ],
        minItemHeight: 70,
        minItemWidth: 200,
        showCloseIcon: false,
      });
      this._settings$.next(this.settings);

      this.onLayoutInit();
    });
  }

  onLayoutInit(): void {}

  onResetFilters(): void {
    this.checkPendingChanges(this.pageId).subscribe((_) =>
      this._state.dispatch(new ResetFiltersAction(null, this._scopeSettings))
    );
  }

  onResetLayout(): void {
    this.checkPendingChanges(this.pageId).subscribe(() => {
      this._dynamicLayoutService.resetAll();
    });
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WLMUnitsOfMeasurementCrud';
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  checkPendingChanges(key: string): Observable<boolean> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }

  canLeavePage(): Observable<boolean> {
    return this.checkPendingChanges(this.pageId);
  }

  private buildTitles(): Observable<Map<string, string>> {
    return this._localization.get(`${this.T_SCOPE}.widgets`).pipe(
      map((ts) => {
        const titles = new Map([
          [this._filterWidgetName, ts['wlm-uom-filter']],
          [this._selectionWidgetName, ts['wlm-uom-selection']],
          [this._configurationWidgetName, ts['wlm-uom-configuration']],
        ]);
        return titles;
      })
    );
  }

  ngOnDestroy() {
    this._state.dispatch(new ResetUoMStateAction(this._scopeSettings));

    super.ngOnDestroy();
  }
}
