import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@common-modules/shared/local-storage.service';
import { AuthenticationService } from './authentication.service';
@Injectable({ providedIn: 'root' })
export class InitialRedirectService {
  private readonly _restoreRouteKey = 'wlm-login-restore-route';

  constructor(
    private readonly _router: Router,
    private readonly _localStorageService: LocalStorageService,
    private readonly _location: Location,
    private _authentication: AuthenticationService
  ) {}

  saveCurrentRoute(): void {
    const path = this._location.path();
    if (this.allowedSavePath(path)) {
      this._localStorageService.addOrUpdate(this._restoreRouteKey, path);
    }
  }

  performInitialRedirect(): void {
    let urlPath = this._location.path();

    if (this._authentication.isOauthCallbackRoute(urlPath)) {
      urlPath = '/';
    }

    const routeToRestore = this.popRouteToRestore();
    if (routeToRestore) {
      urlPath = routeToRestore;
    }

    let pathNoParams = urlPath?.split('?')[0];
    pathNoParams = pathNoParams?.length ? pathNoParams : 'home';
    const urlTree = this._router.parseUrl(urlPath);
    this._router.navigate([pathNoParams], {
      queryParams: urlTree.queryParams,
    });
  }

  private allowedSavePath(path: string): boolean {
    const authRedirectUri = this._authentication.getAuthRedirectUri();
    return (
      path !== '' &&
      path !== '/' &&
      !this._authentication.isOauthCallbackRoute(path) &&
      !path.includes(authRedirectUri)
    );
  }

  private popRouteToRestore(): string {
    const route = this._localStorageService.get(this._restoreRouteKey, null);
    this._localStorageService.remove(this._restoreRouteKey);
    return route;
  }
}
