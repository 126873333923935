import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { IExportPdfComponent } from '@common-modules/shared/exports/models/export-pdf-component';
import { IElementSize } from '@common-modules/shared/model/element-size';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseChartComponent } from '../base-chart/base-chart.component';
import { GenericChartSettings } from '../models/generic-chart-settings/generic-chart-settings';
import { GenericPieChartSettings } from '../models/generic-chart-settings/generic-pie-chart-settings';
import { PieChartDataParameters } from '../models/pie-chart-data-parameters';
import { PieChartSettings } from '../models/pie-chart-settings';
import { BasePieChartService } from '../services/base-pie-chart.service';

const COMPONENT_SELECTOR = 'wlm-pie-chart';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent extends BaseChartComponent implements OnInit, IExportPdfComponent {
  @Input() disableInnerLoading = true;
  @Input() size: IElementSize;
  @Input() disableFixedSize = true;
  @Output() hasData = new EventEmitter<boolean>();

  private _chartSettings: PieChartSettings;
  public get chartSettings(): PieChartSettings {
    return this._chartSettings;
  }
  @Input() public set chartSettings(v: PieChartSettings) {
    this._chartSettings = v;

    if (this.chartSettings) {
      this.initializeChart();
      this.loadChart();
    }
  }
  private _dataService: BasePieChartService;
  genericChartSettingsSubscription: Subscription;

  constructor(_injector: Injector) {
    super(_injector);
  }

  ngOnInit(): void {}

  protected getSerieData(params: any): Observable<any> {
    return this._dataService.getData(params);
  }

  public getGenericChartSettings(
    dataParameters?: PieChartDataParameters
  ): Observable<GenericChartSettings> {
    return this.getSerieData(dataParameters ?? this.chartSettings.dataParameters).pipe(
      switchMap((data) => {
        return this._dataService.mapDataToGenericSettings(data);
      }),
      switchMap((settings) => {
        const hasData = settings?.series[0]?.dataPoints?.length > 0;
        this.hasData.emit(hasData);
        return this.genericChartService.getPieChart(settings as GenericPieChartSettings);
      })
    );
  }

  public setDateParams(startDate: Date, endDate: Date, dataParameters?: PieChartDataParameters) {
    this.setDateRange(startDate, endDate);
    this.resetChart();
    this.loadChart(dataParameters ?? this.chartSettings.dataParameters);
  }

  public getParams(): PieChartDataParameters {
    return this.chartSettings.dataParameters;
  }
  public setParams(newParams: PieChartDataParameters) {
    this.chartSettings.dataParameters = newParams;
  }

  exportToPdf(): void {
    this.genericChart.exportToPdf();
  }

  isEmpty(): boolean {
    return !this.dataLoaded;
  }

  getObjectToExport() {
    return this.genericChart.getObjectToExport();
  }

  getObjectToExportObservable() {
    return this.genericChart.getObjectToExportObservable();
  }

  private initializeChart() {
    this.resetChart();
    this.instanceDataService();
  }

  private resetChart() {
    this.genericChartSettingsSubscription?.unsubscribe();
  }

  private instanceDataService() {
    this._dataService = this._injector.get(this.chartSettings?.dataParameters?.dataService);
  }

  private loadChart(dataParameters?: PieChartDataParameters) {
    this.setLoading(true);
    this.genericChartSettingsSubscription = this.getGenericChartSettings(dataParameters).subscribe({
      next: (settings) => {
        this.genericChartSettings = settings;
        this.setLoading(false);
      },
    });
  }
}
