import { Component, Input, OnInit } from '@angular/core';
import { DateRangeFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/date-range-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { DateRangeSingleFilterConfiguration } from './date-range-single-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-date-range-single-filter';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './date-range-single-filter.component.html',
  styleUrls: ['./date-range-single-filter.component.scss'],
})
export class DateRangeSingleFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _filterConfiguration: DateRangeSingleFilterConfiguration;
  public get filterConfiguration(): DateRangeSingleFilterConfiguration {
    return this._filterConfiguration;
  }
  public set filterConfiguration(value: DateRangeSingleFilterConfiguration) {
    this._filterConfiguration = value;
    if (value) {
      this.setFiltersPersistencyArea();
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  private _filterGroupSettings: FilterGroupSettings;
  public get settings(): FilterGroupSettings {
    return this._filterGroupSettings;
  }
  @Input() public set settings(v: FilterGroupSettings) {
    this._filterGroupSettings = v;

    if (v) {
      this.persistencyArea = v.persistencyArea;
      this.setFilterConfiguration();
    }
  }
  @Input() maxRangeSelected: number;
  @Input() containerClass: string;

  T_SCOPE = `${AppModules.WlmShared}.${COMPONENT_SELECTOR}`;

  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableSelectAll: true,
    disableApplyFilters: false,
  };

  dateRangeGroupSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-label`,
    disableSelectAll: true,
    disableSearch: true,
  };

  dateRangeItemSettings = new BaseFilterItemSettings({
    storageLocation: 'session',
    hideInputSummaryLabel: true,
  });

  constructor(
    private _dateHelperService: DateHelperService,
    _persistencyService: PersistencyService
  ) {
    super(_persistencyService);
  }

  ngOnInit(): void {}

  protected setFiltersPersistencyArea(): void {
    this.dateRangeGroupSettings.persistencyArea = this.filterConfiguration.persistencyArea;
  }

  protected additionalAutoloadCheck(results: FilterAdapterResult): boolean {
    return true;
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const { startDateFieldName, endDateFieldName, dateRange } = this.filterConfiguration;

    const adapters = [
      new DateRangeFAdapter({
        dataBindingField: 'dateRange',
        startDateFieldName,
        endDateFieldName,
        inclusive: dateRange?.inclusive,
        allowsNullDate: dateRange?.allowsNullDate,
      }),
    ];
    return adapters;
  };

  buildKeysToComplete(): void {
    const { startDateFieldName, endDateFieldName } = this.filterConfiguration;
    this.keysToComplete = [startDateFieldName, endDateFieldName];
  }

  private setFilterConfiguration() {
    const defaultDateRange = this._dateHelperService.createDefaultDateRange();

    const startDate = this._dateHelperService.getDefaultStartDate(
      this.settings.additionalParams.offsetStartDate.value
    );
    const endDate = this._dateHelperService.getDefaultEndDate();

    this.filterConfiguration = new DateRangeSingleFilterConfiguration(
      this.settings.persistencyArea,
      this.settings.fields.startDate.fieldName,
      this.settings.fields.endDate.fieldName,
      new DateRange(
        this._dateHelperService.ensureDateObject(startDate),
        this._dateHelperService.ensureDateObject(endDate),
        this.settings.fields.startDate.fieldName,
        this.settings.fields.endDate.fieldName,
        true
      ),
      defaultDateRange
    );
  }
}
