import { Component, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { SetValueAction } from '@common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from '@common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseSelectorDynamicWidgetComponent } from '@common-modules/redux/components/base-selector-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { WorkspaceDto } from '@common-modules/shared/model/data-viz/workspace.dto';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { ChartWorkspaceTypeEnum } from '@common-modules/wlm-charts/core/models/chart-workspace-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { DataVisualizationStateFields } from '../../models/data-visualization-state-fields';

const COMPONENT_SELECTOR = 'wlm-data-visualization-workspace-selector-widget';

export const DV_WORKSPACE_SELECTOR_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-workspace-selector-widget.component.html',
  styleUrls: ['./data-visualization-workspace-selector-widget.component.scss'],
  providers: [ReduxStateService],
})
export class DataVisualizationWorkspaceSelectorWidgetComponent
  extends BaseSelectorDynamicWidgetComponent
  implements OnInit
{
  chartConfigType: ChartWorkspaceTypeEnum;
  selectedWorkspaceName: string;
  currentUser: string;
  removeSelection$ = new ReplaySubject<void>();

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings, pendingChangesService);

    this.chartConfigType = widgetSettings.params.type;
    this.selectedWorkspaceName = widgetSettings.params.selectedWorkspaceName;
    this.currentUser = widgetSettings.params.currentUser;
  }

  onWidgetInit(): void {
    // As we have several instances of this widget we have to pass a clear selection
    // to the child component in order to clear the selection in the others selectors
    this._state
      .select<string>(
        new GetValueSelector({
          fieldName: DataVisualizationStateFields.cleanWorkspaceSelector,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value && this.widgetInstanceKey !== value) {
          this.removeSelection$.next();
        }
      });
  }

  get selectedFieldName(): string {
    return DataVisualizationStateFields.selectedWorkspace;
  }

  get componentName(): string {
    return 'DataVisualizationWorkspaceSelectorWidgetComponent';
  }

  onWorkspaceSelected(selectedWorkspace: WorkspaceDto): void {
    if (!selectedWorkspace) {
      return;
    }

    this._state.dispatch(
      new SetValueAction({
        fieldName: this.selectedFieldName,
        value: null,
      })
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: this.selectedFieldName,
        value: selectedWorkspace,
      })
    );

    this._state.dispatch(
      new SetValueAction({
        fieldName: DataVisualizationStateFields.cleanWorkspaceSelector,
        value: this.widgetInstanceKey,
      })
    );
  }
}
