import { Component, forwardRef, Input } from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';

import { WlmColumnComponent } from '../../wlm-column/wlm-column.component';

@Component({
  selector: 'wlm-boolean-column',
  templateUrl: './boolean-column.component.html',
  styleUrls: ['./boolean-column.component.scss'],
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => BooleanColumnComponent),
    },
  ],
})
export class BooleanColumnComponent extends WlmColumnComponent {
  @Input() useImages: boolean;

  getValue(data): string {
    const value = data[this.field] as string;
    return this.elements?.get(value === undefined || value === null ? 'null' : value)?.toString();
  }
}
