import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import { AuthGuard } from '@common-modules/shared/auth/services/auth.guard';
import { WaterBalancePageComponent } from './water-balance-page/water-balance-page.component';

const routes: Routes = [
  {
    path: '**',
    component: WaterBalancePageComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    // #18328: Uncomment this to test the Auth guard.
    data: {
      navKey: NavKeys.WaterBalance,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WaterBalanceRoutingModule {}
