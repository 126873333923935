import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { BaseWidgetComponent } from '@common-modules/shared/component/base-widget.component';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { NotificationDto } from '@common-modules/shared/model/notifications/notification.dto';

const COMPONENT_SELECTOR = 'wlm-notification-attributes';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './notification-attributes.component.html',
  styleUrls: ['./notification-attributes.component.scss'],
})
export class NotificationAttributesComponent extends BaseWidgetComponent implements OnInit {
  get componentName(): string {
    return 'NotificationAttributesComponent';
  }

  currentNotification: NotificationDto;
  gridSettings: GridSetting;
  gridName = 'ColumnValues';
  pageSize = 0;

  constructor(
    readonly injector: Injector,
    @Inject(WidgetSettingsToken) readonly widgetSettings: StateWidgetSettings,
    private readonly _gridSettingService: GridSettingsService
  ) {
    super(injector, widgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._gridSettingService.getGridSettingsByName(this.gridName).subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          gridSettings.pageSize = this.pageSize;
          gridSettings.hideHeader = true;
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {
    this.currentNotification = parameters.parameters.get(TabDetailParameterName.notification);
    this.pageSize = this.currentNotification?.attributes?.length;
    if (this.gridSettings) {
      const newGridSettings = this.gridSettings;
      newGridSettings.pageSize = this.pageSize;
      this.gridSettings = newGridSettings;
    }
  }

  init(): void {}
}
