import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarDto } from '@common-modules/dependencies/shared/model/calendar.dto';
import { GenericCardSettings } from '@common-modules/shared-component/generic-card/generic-card-settings';
import { DragListSettings } from '@common-modules/shared/core/drag-list-virtual/drag-list-settings';
import { SelectDragListSettings } from '@common-modules/shared/core/select-drag-list-virtual/select-drag-list-settings';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { CalendarService } from '../../service/calendar.service';

const COMPONENT_SELECTOR = 'wlm-calendar-selector';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './calendar-selector.component.html',
  styleUrls: ['./calendar-selector.component.scss'],
})
export class CalendarSelectorComponent implements OnInit {
  @Output() calendarSelected = new EventEmitter<any>();

  @Input() removeSelection$: Observable<void>;
  @Input() reloadSelector$: Observable<void>;
  @Input() selectedCalendarId: string;

  private readonly _calendarFieldName = 'calendarName';
  private readonly _selectedFieldName = 'calendarId';

  private readonly _pagesize = 20;

  settings: DragListSettings;
  cardSettings: GenericCardSettings<CalendarDto>;
  queryParams: Map<string, any> = new Map<string, any>();

  existingCalendars: CalendarDto[];
  selectedCalendar: CalendarDto;
  filters = new DataBindingFilters();
  refreshList$ = new Subject<void>();
  reloadList$ = new Subject<void>();
  isLoading = false;

  constructor(private _calendarService: CalendarService) {}

  ngOnInit(): void {
    this.loadCalendars();
    this.reloadSelector$.pipe(untilDestroyed(this)).subscribe(() => {
      this.loadCalendars();
    });
  }

  onSelectItem(selectedCalendar: CalendarDto) {
    this.calendarSelected.emit(selectedCalendar);
  }

  private loadCalendars() {
    this.isLoading = true;
    this._calendarService
      .getCalendars()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (calendars) => {
          this.existingCalendars = calendars;

          this.selectedCalendar = this.existingCalendars.find(
            (x) => x.calendarId.toLocaleLowerCase() == this.selectedCalendarId?.toLocaleLowerCase()
          );

          this.reloadList$.next();

          this.prepareListsSettings();
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  private prepareListsSettings() {
    this.settings = new SelectDragListSettings({
      isLocal: true,
      pageSize: this._pagesize,
      orderBy: [{ field: this._calendarFieldName, dir: 'asc' }],
      useQueryParams: false,
      oDataFiltersExtended: this.filters,
      displayFieldName: this._calendarFieldName,
      isReadOnly: false,
      selectedFieldName: this._selectedFieldName,
      filtrableFields: [this._calendarFieldName],
      disableSelection: false,
    });

    this.cardSettings = new GenericCardSettings<CalendarDto>({
      title1Fn: (model) => `${model.calendarName}`,
      subtitleFn: (model) => model.calendarDescription,
    });

    this.queryParams = new Map<string, any>();
  }
}
