import { Component, DestroyRef, Inject, Injector, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout.tokens';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { map } from 'rxjs';
import { IntegrationGisLayersMapComponent } from '../../integration-gis-layers-map/integration-gis-layers-map.component';
import { IntegrationGisLayersService } from '../../integration-gis-layers.service';

const COMPONENT_SELECTOR = 'wlm-integration-gis-layers-map-widget';

export const INTEGRATION_GIS_LAYER_MAP_WIDGET_INSTANCE = COMPONENT_SELECTOR + '#1';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './integration-gis-layers-map-widget.component.html',
  styleUrls: ['./integration-gis-layers-map-widget.component.scss'],
  providers: [ReduxStateService],
})
export class IntegrationGisLayersMapWidgetComponent extends BaseDynamicWidgetComponent {
  @ViewChild(IntegrationGisLayersMapComponent) child: IntegrationGisLayersMapComponent;

  private readonly _integrationGisLayersService = inject(IntegrationGisLayersService);
  private readonly _destroyRef = inject(DestroyRef);
  geojsonFeatures: GeoJSON.FeatureCollection;
  selectedElementTypeId: number[];

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
  }

  onWidgetInit(): void {
    this._integrationGisLayersService
      .getGeojsonFeatures()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((features) => {
        this.geojsonFeatures = features;
      });

    this._integrationGisLayersService
      .getElementTypeId()
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        map((elementTypes) =>
          elementTypes
            ? [elementTypes.networkElementSubTypeId ?? elementTypes.networkElementTypeId]
            : []
        )
      )
      .subscribe((elementTypeIds) => {
        this.selectedElementTypeId = elementTypeIds;
      });

    this._integrationGisLayersService.resetAll$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.child?.clear();
      });
  }

  get componentName(): string {
    return 'IntegrationGisLayersMapWidgetComponent';
  }
}
