import { Component, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { DynamicLayoutService } from '@common-modules/dynamic-layout/services/dynamic-layout.service';
import { SetValueAction } from '@common-modules/dynamic-layout/state/generic/generic.actions';
import { GetValueSelector } from '@common-modules/dynamic-layout/state/generic/generic.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { SchematicBasic } from '@common-modules/wlm-charts/core/models/schematics/schematic';
import { SchematicStateFields } from '@common-modules/wlm-charts/core/models/schematics/schematic-state-fields';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

const COMPONENT_SELECTOR = 'wlm-schematics-container-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './schematics-container-widget.component.html',
  styleUrls: ['./schematics-container-widget.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class SchematicsContainerWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit
{
  pageId: string;
  schematic: SchematicBasic;
  editMode: boolean = false;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);

    this.pageId = widgetSettings.page;
  }

  onWidgetInit(): void {
    this.listenState(SchematicStateFields.selectedSchematic).subscribe(
      (schematic: SchematicBasic) => {
        this.schematic = schematic;
      }
    );

    this.listenState(SchematicStateFields.editMode).subscribe((editMode) => {
      this.editMode = editMode;
    });
  }

  onToggleEditor(value): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: SchematicStateFields.editMode,
        value,
      })
    );
  }

  onChartFinished(event): void {
    this.setGlobalLoading(false);
  }

  onExternalLoading(isLoading: boolean): void {
    this.setGlobalLoading(isLoading);
  }

  private setGlobalLoading(isLoading: boolean): void {
    this._state.dispatch(
      new SetValueAction({
        fieldName: SchematicStateFields.isGlobalLoading,
        value: isLoading,
      })
    );
  }

  private listenState(fieldName: string): Observable<any> {
    return this._state
      .select(
        new GetValueSelector({
          fieldName,
        })
      )
      .pipe(untilDestroyed(this));
  }

  get componentName(): string {
    return 'SchematicsContainerWidgetComponent';
  }
}
