import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { LocalGridComponent } from '@common-modules/wlm-grid/local-grid/local-grid.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { GridSetting } from '../../constants/grid.constants';
import { DataBindingFilters } from '../../filters/component-filters/data-binding-filters';
import { ObservablesService } from '../../services/observables.service';
import {
  WlmResizeObserverConfig,
  WlmResizeObserverData,
  WlmResizeObserverService,
} from '../../services/resize-observer.service';
import { GridButtonsFirstSlotDirective } from '../directives/grid-buttons-first-slot.directive';
import { GridButtonsSecondSlotDirective } from '../directives/grid-buttons-second-slot.directive';
import { GridButtonsThirdSlotDirective } from '../directives/grid-buttons-third-slot.directive';
import { GridBtnsDisable } from '../models/grid-btns-disable';
import { GridBtnsEvent } from '../models/grid-btns-event';

const COMPONENT_SELECTOR = 'wlm-grid-buttons';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './grid-buttons.component.html',
  styleUrls: ['./grid-buttons.component.scss'],
})
export class GridButtonsComponent implements OnInit {
  firstSlotContent: TemplateRef<GridButtonsFirstSlotDirective>[];
  @ContentChildren(GridButtonsFirstSlotDirective, { read: TemplateRef }) set queryFirstSlotContent(
    value: QueryList<TemplateRef<GridButtonsFirstSlotDirective>>
  ) {
    this.firstSlotContent = value?.toArray();
  }

  secondSlotContent: TemplateRef<GridButtonsSecondSlotDirective>[];
  @ContentChildren(GridButtonsSecondSlotDirective, { read: TemplateRef })
  set querySecondSlotContent(value: QueryList<TemplateRef<GridButtonsSecondSlotDirective>>) {
    this.secondSlotContent = value?.toArray();
  }

  thirdSlotContent: TemplateRef<GridButtonsThirdSlotDirective>[];
  @ContentChildren(GridButtonsThirdSlotDirective, { read: TemplateRef }) set queryThirdSlotContent(
    value: QueryList<TemplateRef<GridButtonsThirdSlotDirective>>
  ) {
    this.thirdSlotContent = value?.toArray();
  }

  @ViewChild('slot1') templateSlot1: TemplateRef<any>;
  @ViewChild('slot2') templateSlot2: TemplateRef<any>;
  @ViewChild('slot3') templateSlot3: TemplateRef<any>;

  @ViewChild('gridButtonsInternalContainer') set setGridButtonsInternalContainer(
    gridButtonsInternalContainer: ElementRef<any>
  ) {
    if (gridButtonsInternalContainer) {
      this._buttonsRequiredWidthSubs?.unsubscribe();
      this._buttonsRequiredWidthSubs = this._resizeObserver
        .observe(
          new WlmResizeObserverConfig({
            el: gridButtonsInternalContainer.nativeElement,
            calculateWidth: true,
          })
        )
        .subscribe((data: WlmResizeObserverData) => {
          if (data.width) {
            this.buttonsRequiredWidth$.next(data.width);
            this.isButtonsRequiredWidthCalculated = true;
            this.displayHost();
          }
        });
    }
  }

  private _container: Element;
  get container(): Element {
    return this._container;
  }

  @Input() grid: GenericGridComponent;
  @Input() localGrid: LocalGridComponent;
  @Input() gridSettings: GridSetting;
  @Input() disable$: Subject<GridBtnsDisable>;

  @Input() pageId: string;
  @Input() gridFilters: DataBindingFilters;
  @Input() gridName: string;
  @Input() canLoad: boolean;
  @Input() responsive = true;

  @Output() btnClick = new EventEmitter<GridBtnsEvent>();
  @Output() btnCallback = new EventEmitter<GridBtnsEvent>();
  @Output() gridSettingsChange = new EventEmitter<GridSetting>();

  showHamburguer = false;
  buttonsRequiredWidth$ = new BehaviorSubject<number>(null);
  isButtonsRequiredWidthCalculated = false;
  gridButtonsMode: 'horizontal' | 'vertical' = 'horizontal';
  private readonly _responsiveKey = 'page-header-responsive';
  private _buttonsRequiredWidthSubs: Subscription;
  // Add a bit of margin to the widths subtraction to have smoother transitions.
  private readonly _hamburguerAverageMargin = 16; // px

  constructor(
    private readonly _observablesService: ObservablesService,
    private readonly _resizeObserver: WlmResizeObserverService,
    private _element: ElementRef<any>
  ) {}

  ngOnInit(): void {
    this.hideHostUntilCalculated();
    this.listenShowHamburguer();
  }

  private listenShowHamburguer(): void {
    combineLatest([
      this.buttonsRequiredWidth$,
      this._observablesService.listen<number>(this._responsiveKey),
    ]).subscribe(([buttonsRequiredWidth, availableWidth]) => {
      if (availableWidth && buttonsRequiredWidth) {
        this.showHamburguer = availableWidth - this._hamburguerAverageMargin < buttonsRequiredWidth;
      }
    });
  }

  onbtnClick($event) {
    this.btnClick.emit($event);
  }

  onbtnCallback($event) {
    this.btnCallback.emit($event);
  }

  ongridSettingsChange($event) {
    this.gridSettingsChange.emit($event);
  }

  private hideHostUntilCalculated(): void {
    this._element.nativeElement.classList.add('hide-until-calculated');
  }

  private displayHost(): void {
    this._element.nativeElement.classList.remove('hide-until-calculated');
  }
}
