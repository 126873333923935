import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { IInterfacesLoggingSelector } from '@common-modules/dependencies/logs/models/interfaces-logging-selector';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { IGridSettings } from '@common-modules/shared/constants/grid.constants';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsDisable } from '@common-modules/shared/grid-buttons/models/grid-btns-disable';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { DynamicGridSettings } from '@common-modules/shared/model/grid/dynamic-grid-settings';
import { LogsInterfacesService } from '@common-modules/shared/services/logs-interfaces.service';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SendInterfacesCsvRequestPopupComponent } from '../../../interfaces/send-interfaces-csv-request-popup/send-interfaces-csv-request-popup.component';
import { SendInterfacesRequestPopupComponent } from '../../../interfaces/send-interfaces-request-popup/send-interfaces-request-popup.component';
import { LOGS_MODULE_SETTINGS, LogsModuleSettings } from '../../logs-module-settings';

const COMPONENT_SELECTOR = 'wlm-logs-interfaces-container';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './logs-interfaces-container.component.html',
  styleUrls: ['./logs-interfaces-container.component.scss'],
})
export class LogsInterfacesContainerComponent implements OnInit {
  readonly COMMON_SCOPE = `${AppModules.Logs}.common`;

  private _selectedInterface: IInterfacesLoggingSelector;
  get selectedInterface(): IInterfacesLoggingSelector {
    return this._selectedInterface;
  }
  @Input() set selectedInterface(value: IInterfacesLoggingSelector) {
    this._selectedInterface = value;
    this.additionalFilters = new Map<string, any>();

    if (this.selectedInterface) {
      const { interfaceTypeName, additionalTypeNames } = this.selectedInterface;
      this.additionalFilters.set('interfaceTypeName', interfaceTypeName);
      if (additionalTypeNames) {
        this.additionalFilters.set('additionalTypeNames', additionalTypeNames);
      }
      setTimeout(() => {
        this.reloadGrid$.next();
        this.removeSelection$.next();
        this.removeSelectionPersisted$.next();

        this.grid?.clearFilters();
      });
    }
  }
  @Output() refreshSelector = new EventEmitter<void>();

  get componentName(): string {
    return 'LogsInterfacesContainerComponent';
  }

  grid: GenericGridComponent;
  dynamicGridSettings: DynamicGridSettings;
  additionalFilters: Map<string, any>;
  emptyFilters: DataBindingFilters = new DataBindingFilters();
  gridSettings: IGridSettings;
  gridName = 'LogsInterfaces';

  reloadGrid$ = new Subject<void>();
  removeSelection$ = new Subject<void>();
  removeSelectionPersisted$ = new Subject<void>();
  gridSettingsChanged$ = new ReplaySubject<IGridSettings>();
  gridBtnsDisable$: Subject<GridBtnsDisable>;
  gridSettingsReady$ = new ReplaySubject<IGridSettings>();

  private readonly _integrationNavigationKey = 'initialStep';

  constructor(
    private readonly _spinnerService: SpinnerService,
    private readonly _logsInterfacesService: LogsInterfacesService,
    private readonly _dialogService: DialogService,
    private readonly _matDialog: MatDialog,
    private readonly _router: Router,
    @Inject(LOGS_MODULE_SETTINGS) private readonly _moduleSettings: LogsModuleSettings
  ) {}

  ngOnInit(): void {
    this.initGridSettings();
  }

  getGenericGridInstance(grid: GenericGridComponent) {
    this.grid = grid;
  }

  getGridSettings(gridSettings: IGridSettings) {
    this.gridSettings = gridSettings;
    this.gridSettingsReady$.next(gridSettings);
  }

  onGridSettingsChanged(settings: IGridSettings) {
    this.gridSettingsChanged$.next(settings);
  }

  downloadDisabled() {
    if (!this.grid?.selectedRows.length) {
      return true;
    }

    // Archived logs only can be downloaded 1 by 1
    return (
      !this.grid.selectedRows.some((row) => row.fileName) ||
      (this.grid.selectedRows.length > 1 && this.grid.selectedRows.some((row) => row.isArchived))
    );
  }

  onClickDownload() {
    if (!this.grid?.selectedRows.length) {
      return;
    }

    this.setLoading(true);

    const fileNames = this.getFileNamesFromSelectedRows();
    if (!fileNames?.length) {
      this.setLoading(false);
      return;
    }

    this._logsInterfacesService
      .download(this.selectedInterface.interfaceTypeName, fileNames)
      .pipe(
        finalize(() => this.setLoading(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (result) => {
          const blob = new Blob([result], { type: result.type });
          const url = window.URL.createObjectURL(blob);

          const downloadName = this.getDownloadName(fileNames);

          globalUtilsHelper.downloadFile(url, downloadName);
        },
        error: (error) => this._dialogService.showErrorMessage(error),
      });
  }

  openRequestPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '830px';
    dialogConfig.height = '690px';

    dialogConfig.data = this.selectedInterface;
    const popup = this._matDialog.open(SendInterfacesRequestPopupComponent, dialogConfig);

    popup.afterClosed().subscribe((res) => {
      if (res) {
        this.reloadGrid();
        this.refreshSelector.emit();
      }
    });
  }

  onOpenCsvRequestPopup(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px';
    dialogConfig.height = '200px';

    dialogConfig.data = this.selectedInterface;

    const popup = this._matDialog.open(SendInterfacesCsvRequestPopupComponent, dialogConfig);

    popup.afterClosed().subscribe((res) => {
      if (res) {
        this.reloadGrid();
      }
    });
  }

  navigateToIntegration(): void {
    this._router.navigate(['/integration'], {
      queryParams: {
        [this._integrationNavigationKey]: this._moduleSettings.integrationGisLayerStepId,
      },
    } as NavigationExtras);
  }

  get isGisInterfaceSelected(): boolean {
    return this.selectedInterface?.interfaceTypeName === 'gis';
  }

  private initGridSettings() {
    this.dynamicGridSettings = new DynamicGridSettings({
      gridSettingName: this.gridName,
      persistencyArea: 'LogsInterfaces',
      usePersistence: false,
      type: 'generic',
      disableAutoLoad: false,
    });
  }

  private getFileNamesFromSelectedRows(): string[] {
    let fileNames = this.grid?.selectedRows
      .map((row) => row.compressedName)
      .filter((name) => !!name);
    if (fileNames?.length) {
      return fileNames as string[];
    }

    return this.grid?.selectedRows.map((row) => row.fileName).filter((name) => !!name) as string[];
  }

  private getDownloadName(fileNames: string[]): string {
    if (fileNames.length > 1) {
      return this.selectedInterface?.interfaceTypeName;
    }

    const parts = fileNames[0].split('\\');
    if (parts.length > 1) {
      return parts[parts.length - 1];
    }

    return parts[0];
  }

  private setLoading(loading: boolean) {
    this._spinnerService.setLoading(loading, this.componentName);
  }

  private reloadGrid() {
    this.grid?.reloadGrid();
  }
}
