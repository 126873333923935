import { ElementRef, Injectable } from '@angular/core';
import { HierarchyColumnPrefix } from '@common-modules/dependencies/he/hierarchy.constants';
import { Dimensions } from '@common-modules/shared/constants/dimensions.constants';
import { asEnumerable } from 'linq-es2015';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAlarmHistoryDto } from '../../dependencies/alarms/alarm-history.dto';
import { GridHeaderButtonsComponent } from '../../wlm-grid/grid-header-buttons/grid-header-buttons.component';
import { IGridSettings } from '../constants/grid.constants';
import { GridColumnSetting } from '../model/grid/grid-column-setting';
import { AlarmsService } from '../services/alarms.service';
import { DynamicRenderizerComponentService } from '../services/dynamic-renderizer-component.service';
import { GlobalsService } from '../services/globals.service';

@Injectable({
  providedIn: 'root',
})
export class GridHelperService {
  private _zoneColumnsByFamily = new Map<string, Map<string, number>>();

  heTypeIdNamePosition = 2;

  constructor(
    private globalsService: GlobalsService,
    private alarmsService: AlarmsService,
    private _dynamicRenderizerService: DynamicRenderizerComponentService
  ) {
    this.populateZoneColumnsByFamily().subscribe(
      (zoneColumnFields) => (this._zoneColumnsByFamily = zoneColumnFields)
    );
  }

  populateZoneColumnsByFamily(): Observable<Map<string, Map<string, number>>> {
    return this.globalsService.getHierarchyRelationLevels().pipe(
      map((levels) => {
        const zoneColumnsFamily = new Map<string, Map<string, number>>();
        const families = levels
          .map((level) => level.hierarchyFamilyId)
          .filter((v, i, a) => a.indexOf(v) === i);
        families.forEach((family) => {
          const currentFamilyLevels = levels.filter((f) => f.hierarchyFamilyId === family);
          zoneColumnsFamily.set(family, new Map<string, number>());
          let index = 0;
          currentFamilyLevels.forEach((level) => {
            zoneColumnsFamily.get(family).set(level.hierarchyElementTypeId, index);
            index++;
          });
        });

        return zoneColumnsFamily;
      })
    );
  }

  getHierarchyColumns(): Observable<GridColumnSetting[]> {
    return this.globalsService.getHierarchyRelationLevels().pipe(
      map((levels) => {
        const families: string[] = levels
          .map((m) => m.hierarchyFamilyId)
          .filter((v, i, a) => a.indexOf(v) === i);

        const zoneColumnsMap = new Map<string, GridColumnSetting[]>();

        families.forEach((family) => {
          const currentFamilyLevels = levels.filter((f) => f.hierarchyFamilyId === family);
          let index = 0;
          currentFamilyLevels.forEach((l) => {
            const zoneColumns = zoneColumnsMap.get(l.hierarchyElementTypeId);
            if (!zoneColumns) {
              const newIdLevel: GridColumnSetting = {
                field: `level${index}Id`,
                title: l.hierarchyElementTypeName,
                type: 'hierarchy',
                width: Dimensions.MedColumnWidth,
                visible: false,
                // Be ware of changing the position of l.hierarchyElementTypeIdField. Modify also this.heTypeIdNamePosition
                uniqueName: `${HierarchyColumnPrefix.Level};${index};${l.hierarchyElementTypeId};${family}`,
              };
              const newNameLevel: GridColumnSetting = {
                field: `level${index}Name`,
                title: ` ${l.hierarchyElementTypeName} Name`,
                type: 'hierarchy',
                width: Dimensions.MedColumnWidth,
                visible: false,
                // Be ware of changing the position of l.hierarchyElementTypeIdField. Modify also this.heTypeIdNamePosition
                uniqueName: `${HierarchyColumnPrefix.LevelName};${index};${l.hierarchyElementTypeId};${family}`,
              };

              zoneColumnsMap.set(l.hierarchyElementTypeId, [newIdLevel, newNameLevel]);
            } else {
              for (const zoneColumn of zoneColumns) {
                zoneColumn.uniqueName = `${zoneColumn.uniqueName};${family}`;
              }
            }
            index++;
          });
        });

        const heColumns = asEnumerable(zoneColumnsMap)
          .SelectMany((x) => x[1])
          .ToArray();

        return heColumns;
      })
    );
  }

  getVisibleColumnsByFamily(familyId: string, columns: GridColumnSetting[]): GridColumnSetting[] {
    if (familyId === undefined || columns === undefined) {
      return columns;
    }

    const modifiedColumns: GridColumnSetting[] = [];

    for (const column of columns) {
      const uniqueName = column?.uniqueName;

      if (!uniqueName) {
        modifiedColumns.push(column);
        continue;
      }

      if (uniqueName.startsWith(HierarchyColumnPrefix.Level)) {
        const uniqueNameFields = uniqueName.split(';');
        const columnFieldIndex = this._zoneColumnsByFamily
          .get(familyId)
          .get(uniqueNameFields[this.heTypeIdNamePosition]);

        const isVisible = uniqueName.includes(familyId) ? column.visible : false;
        let fieldName;

        if (columnFieldIndex !== undefined && columnFieldIndex !== null) {
          if (uniqueName.startsWith(HierarchyColumnPrefix.LevelName)) {
            fieldName = `level${columnFieldIndex}Name`;
          } else {
            fieldName = `level${columnFieldIndex}Id`;
          }
        }
        modifiedColumns.push({ ...column, visible: isVisible, field: fieldName ?? column.field });
      } else {
        modifiedColumns.push(column);
      }
    }

    return modifiedColumns;
  }

  public getSeverityColorFromSeverity(dataItem: any): Observable<string> {
    const alarm = dataItem as IAlarmHistoryDto;
    const color = 'transparent';
    if (alarm) {
      return this.alarmsService.getAlarmSeverityTypes().pipe(
        map((severities) => {
          return (
            asEnumerable(severities).FirstOrDefault((x) => x.severityId === dataItem)
              .severityColor ?? color
          );
        })
      );
    } else {
      return of(color);
    }
  }

  public getFontWeightFromAck(dataItem: any): Observable<string> {
    const alarm = dataItem as IAlarmHistoryDto;
    let fontWeight = 'normal';
    if (alarm) {
      fontWeight = alarm.acknowledgedDateTime === undefined ? 'bold' : 'normal';
    }
    return of(fontWeight);
  }

  getBackgroundColor(methodName: string, dataItem: any): Observable<string> {
    return this[methodName](dataItem);
  }

  getFontWeight(methodName: string, dataItem: any): Observable<string> {
    return this[methodName](dataItem);
  }

  getBackgroundColorMapping(methodName: string): Observable<Map<any, string>> {
    return this[methodName]();
  }

  public getSeverityColorsMap(): Observable<Map<any, string>> {
    const colorMapping = new Map<any, string>();

    return this.alarmsService.getAlarmSeverityTypes().pipe(
      map((severities) => {
        severities.forEach((sev) => {
          colorMapping.set(sev.severityId, sev.severityColor);
        });
        return colorMapping;
      })
    );
  }

  attachGridHeaderButtons(gridContainerElement: ElementRef): void {
    if (gridContainerElement) {
      const setButtonsFlag = 'has-header-buttons';
      const headerCellSelector = `.k-grid-draggable-header .k-cell-inner:not([${setButtonsFlag}='true']`;
      const element = gridContainerElement.nativeElement;
      const headerCells: HTMLElement[] = Array.from(element.querySelectorAll(headerCellSelector));

      if (headerCells.length > 1) {
        headerCells.forEach((headerCell) => {
          if (this.isDraggableColumn(headerCell)) {
            const headerButtonsElement =
              this._dynamicRenderizerService.injectComponentWithoutDestroyIt(
                GridHeaderButtonsComponent,
                'span',
                (component, componentRef) => {}
              );
            headerCell.appendChild(headerButtonsElement);
            headerCell.setAttribute(setButtonsFlag, 'true');
          }
        });
      }
    }
  }

  getColumnTypesNonExportable(): string[] {
    return ['alarm-counter', 'duration', 'internal'];
  }

  getColumnTypesNonNotificable(): string[] {
    return ['alarm-counter', 'duration', 'maxmin'];
  }

  /**
   * Only add drag button when the column has a title (all except select all).
   */
  isDraggableColumn(headerElement: HTMLElement): boolean {
    const element = headerElement?.firstChild?.firstChild as HTMLElement;
    const isSelectAll = element?.classList?.contains('select-all-column-id');
    return !isSelectAll;
  }

  preProcessRowDataValues(
    gridSettings: IGridSettings,
    removeNonExportable = false,
    removeNonNotificable = false
  ) {
    let excludedTypes = [];
    if (removeNonExportable) {
      excludedTypes = [...excludedTypes, ...this.getColumnTypesNonExportable()];
    }

    if (removeNonNotificable) {
      excludedTypes = [...excludedTypes, ...this.getColumnTypesNonNotificable()];
    }

    // This removes the non-exportable columns
    gridSettings.gridColumnSettings = gridSettings.gridColumnSettings.filter(
      (f) => !excludedTypes.includes(f.type)
    );
  }
}
