import { Injectable, Injector } from '@angular/core';
import { NetworkElementAttributeTypeCrudDto } from '@common-modules/dependencies/ne/network-element-attribute-type-crud.dto';
import { NetworkElementAttributeLinkDto } from '@common-modules/dependencies/ne/network-element-link-dto';
import { Observable, map } from 'rxjs';

import { GenericCrudService } from '@common-modules/generic-crud/generic-crud.service';
import { BaseService } from '@common-modules/shared/base.service';
import { INetworkElementTypeAttributeValidationDto } from './network-element-type-attribute-validation.dto';

@Injectable()
export class NEATypesCrudService
  extends BaseService
  implements GenericCrudService<NetworkElementAttributeTypeCrudDto, number>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/network-element-attribute-types`;
  }

  getAll(): Observable<NetworkElementAttributeTypeCrudDto[]> {
    return this.httpCacheClient.get<NetworkElementAttributeTypeCrudDto[]>(
      this._entityUrl,
      this.avoidCache
    );
  }

  create(
    entity: NetworkElementAttributeTypeCrudDto
  ): Observable<NetworkElementAttributeTypeCrudDto> {
    return this.httpCacheClient.post<NetworkElementAttributeTypeCrudDto>(this._entityUrl, entity);
  }

  update(
    entity: NetworkElementAttributeTypeCrudDto
  ): Observable<NetworkElementAttributeTypeCrudDto> {
    return this.httpCacheClient.put<NetworkElementAttributeTypeCrudDto>(this._entityUrl, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  getId(entity: NetworkElementAttributeTypeCrudDto): number {
    return entity.networkElementAttributeTypeId;
  }

  getAllCategories(): Observable<string[]> {
    return this.httpCacheClient.get<string[]>(`${this._entityUrl}/categories`);
  }

  updateLink(entity: NetworkElementAttributeLinkDto): Observable<boolean> {
    return this.httpCacheClient.post<boolean>(
      `${this._entityUrl}/link/${entity.networkElementTypeId}`,
      entity.networkElementAttributeTypeId
    );
  }

  isCustomAttributeFalse(entity: NetworkElementAttributeTypeCrudDto): boolean {
    return entity.isCustom === false;
  }

  validate(
    entity: NetworkElementAttributeTypeCrudDto,
    isCreate?: boolean
  ): Observable<INetworkElementTypeAttributeValidationDto> {
    return this.httpCacheClient.post<INetworkElementTypeAttributeValidationDto>(
      `${this._entityUrl}/validate?isCreate=${isCreate ? 'true' : 'false'}`,
      entity
    );
  }
}
