import { HierarchyElementPressureConfigDto } from './hierarchy-element-pressure-config.dto';
import { SignalHistoricalVersionDto } from './signal-historical-version-dto';

export class HierarchyElementPressureSignalVersionDto extends SignalHistoricalVersionDto {
  title: string;
  pressureTypeId: number;
  offset: number;
  offsetCoordinates: string;
  hierarchyElementTypeId: string;

  constructor(init: HierarchyElementPressureSignalVersionDto) {
    super(init);
    Object.assign(this, init);
  }

  getCurrentConfig?(): HierarchyElementPressureConfigDto {
    const current = {
      ...super.getCurrentConfig(),
      pressureTypeId: this.pressureTypeId,
      offset: this.offset,
      offsetCoordinates: this.offsetCoordinates,
    };

    return current;
  }
}
