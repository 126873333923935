import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { GenericCardSettings } from '@common-modules/shared-component/generic-card/generic-card-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { IFiltrableItemDto } from '@common-modules/shared/model/filtrable-items/filtrable-item.dto';
import { WtrColors } from '@common-modules/shared/styles/wtr-colors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { AlgorithmFiltrableItemDto } from '../../../shared/model/filtrable-items/algorithm-filtrable-item.dto';
import { AlgorithmFiltrableItemService } from '../../../shared/model/filtrable-items/services/algorithm-filtrable-item.service';
import { SignalFiltrableItemService } from '../../../shared/model/filtrable-items/services/signal-filtrable-item.service';
import { SignalFiltrableItemDto } from '../../../shared/model/filtrable-items/signal-filtrable-item.dto';
import { AlgorithmFiltrableType } from '../../../shared/model/filtrable-items/types/algorithm-filtrable-type';
import { SignalFiltrableType } from '../../../shared/model/filtrable-items/types/signal-filtrable-type';
import { DataVisualizationCartSelectedWorkspace } from '../../models/data-visualization-cart-selected-workspace';
import { DataVisualizationCartSelection } from '../../models/data-visualization-cart-selection';
import { DataVisualizationCartSettings } from '../../models/data-visualization-cart-settings';

const COMPONENT_SELECTOR = 'wlm-data-visualization-cart';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-cart.component.html',
  styleUrls: ['./data-visualization-cart.component.scss'],
})
export class DataVisualizationCartComponent implements OnInit {
  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;

  private _cartSettings: DataVisualizationCartSettings;
  public get cartSettings(): DataVisualizationCartSettings {
    return this._cartSettings;
  }
  @Input()
  public set cartSettings(value: DataVisualizationCartSettings) {
    this._cartSettings = value;

    if (value) {
      this.loadCartState(value);
    }
  }

  @Input() algorithmsAdded$: Observable<AlgorithmFiltrableItemDto[]>;
  @Input() pointAdded$: Observable<SignalFiltrableItemDto>;
  @Input() workspaceLoaded$: Observable<DataVisualizationCartSelectedWorkspace>;
  @Input() clearItems$: Observable<boolean>;

  @Output() plotCartSelection = new EventEmitter<DataVisualizationCartSelection>();
  @Output() cartSelectionChange = new EventEmitter<DataVisualizationCartSelection>();

  private readonly _offsetStartDate = 1;

  private _algorithms: AlgorithmFiltrableItemDto[] = [];
  public get algorithms(): AlgorithmFiltrableItemDto[] {
    return this._algorithms;
  }
  public set algorithms(value: AlgorithmFiltrableItemDto[]) {
    if (
      this._arrayHelperService.areSameByProperty(
        this._algorithms,
        value,
        (model: AlgorithmFiltrableItemDto) => model.filtrableType.key
      )
    ) {
      return;
    }

    this._algorithms = value;
  }

  algorithmCardSettings: GenericCardSettings<AlgorithmFiltrableItemDto>;

  points: SignalFiltrableItemDto[] = [];
  pointCardSettings: GenericCardSettings<SignalFiltrableItemDto>;

  itemsSelection: { [id: string]: boolean } = {};

  initialDateRange: DateRange;
  selectedDateRange: DateRange;
  hasDateRangeErrors: boolean;
  resetEndDate$ = new ReplaySubject<void>();
  resetStartDate$ = new ReplaySubject<void>();

  algorithmsPanelOpenState = false;
  signalsPanelOpenState = false;

  iconColor = WtrColors.NavBarIconColor;

  get isButtonDisabled() {
    return (
      this.hasDateRangeErrors ||
      !(this.algorithms?.length || this.points?.length) ||
      !Object.values(this.itemsSelection).some((v) => v)
    );
  }

  constructor(
    private _arrayHelperService: ArrayHelperService,
    private _objectHelperService: ObjectHelperService,
    private _dateHelperService: DateHelperService,
    private _dialogService: DialogService,
    private _localizationService: LocalizationHelperService,
    private _algorithmFiltrableItemService: AlgorithmFiltrableItemService,
    private _signalFiltrableItemService: SignalFiltrableItemService
  ) {
    this.initDefaultDateRange();
  }

  ngOnInit(): void {
    this.prepareCardSettings();
    this.bindAlgorithmsAdded();
    this.bindPointAdded();
    this.bindWorkspaceLoaded();
    this.bindClearItems();
  }

  onDateRangeChanged(dateRange: DateRange) {
    this.selectedDateRange = dateRange;
  }

  onDateRangeError(hasErrors: boolean) {
    this.hasDateRangeErrors = hasErrors;
  }

  onClickPlot() {
    const cartSelection = this.getCartSelection();

    this.plotCartSelection.emit(cartSelection);
  }

  isItemChecked(item: IFiltrableItemDto) {
    return this.itemsSelection[item.filtrableType.key];
  }

  onCheckItem(event: MatCheckboxChange, item: IFiltrableItemDto) {
    this.itemsSelection[item.filtrableType.key] = event.checked;
  }

  onRemoveAlgorithm(item: AlgorithmFiltrableItemDto) {
    this.onRemoveItem(this.algorithms, item);
  }

  onRemovePoint(item: SignalFiltrableItemDto) {
    this.onRemoveItem(this.points, item);
  }

  getIcon(item: AlgorithmFiltrableItemDto | SignalFiltrableItemDto) {
    return item?.filtrableType?.icon;
  }

  private loadCartState(cartSettings: DataVisualizationCartSettings) {
    const { dateRange, algorithms, points, itemsSelection } = cartSettings;

    if (dateRange) {
      const { start, end } = dateRange;

      this.selectedDateRange = this.initialDateRange = new DateRange(
        this._dateHelperService.ensureDateObject(start),
        this._dateHelperService.ensureDateObject(end)
      );
    }

    if (itemsSelection) {
      this.itemsSelection = globalUtilsHelper.clone(itemsSelection, true);
    }

    if (algorithms?.length) {
      this.algorithms = algorithms.map((a) => new AlgorithmFiltrableItemDto(a));
    }

    if (points?.length) {
      this.points = points.map((p) => new SignalFiltrableItemDto(p));
    }

    this.emitCartSelection();
  }

  private prepareCardSettings() {
    const getAlgorithmSubtitleFn = (model: AlgorithmFiltrableType) =>
      `${model.element.typeName} - ${model.element.name}`;

    const getPointTitleFn = (model: SignalFiltrableType) =>
      `${model.pointId} - ${model.pointDescription}`;

    this._localizationService
      .get(`${AppModules.DataVisualization}.labels`)
      .pipe(untilDestroyed(this))
      .subscribe((ts) => {
        const getPointSubtitleFn = (model: SignalFiltrableType) =>
          model.isConfigured ? ts['point-configured'] : ts['point-not-configured'];

        this.algorithmCardSettings = new GenericCardSettings<AlgorithmFiltrableItemDto>({
          title1Fn: (model) => model.filtrableType.algorithmShortName,
          subtitleFn: (model) => getAlgorithmSubtitleFn(model.filtrableType),
          iconColor: this.iconColor,
        });

        this.pointCardSettings = new GenericCardSettings<SignalFiltrableItemDto>({
          title1Fn: (model) => getPointTitleFn(model.filtrableType),
          subtitleFn: (model) => getPointSubtitleFn(model.filtrableType),
          iconColor: this.iconColor,
        });
      });
  }

  private bindAlgorithmsAdded() {
    this.algorithmsAdded$
      .pipe(untilDestroyed(this))
      .subscribe((items: AlgorithmFiltrableItemDto[]) => {
        if (!items?.length) {
          return;
        }

        const propFn = (model: AlgorithmFiltrableItemDto) => model.filtrableType.key;

        this.checkForDuplicated(
          this.algorithms,
          items,
          `${this.T_SCOPE}.messages.duplicated-algorithms-add`
        );

        this.algorithms = this._objectHelperService.getCombinedArraysByProperty(
          this.algorithms ?? [],
          items,
          propFn
        );
        this.setSelection(this.algorithms);
        this.emitCartSelection();
      });
  }

  private bindPointAdded() {
    this.pointAdded$.pipe(untilDestroyed(this)).subscribe((item: SignalFiltrableItemDto) => {
      if (!item) {
        return;
      }

      const duplicated = this.checkForDuplicated(
        this.points,
        [item],
        `${this.T_SCOPE}.messages.duplicated-point-add`
      );

      if (!duplicated) {
        this.points.push(item);
        this.setSelection(this.points);
        this.emitCartSelection();
      }
    });
  }

  private bindWorkspaceLoaded() {
    this.workspaceLoaded$
      .pipe(untilDestroyed(this))
      .subscribe((workspaceToLoad: DataVisualizationCartSelectedWorkspace) => {
        if (!workspaceToLoad) {
          return;
        }

        const { start, end } = workspaceToLoad.dateRange;
        this.selectedDateRange = this.initialDateRange = new DateRange(
          this._dateHelperService.ensureDateObject(start),
          this._dateHelperService.ensureDateObject(end)
        );

        // Clear chart
        this.clearCart();
        if (workspaceToLoad.points?.length) {
          this.points.push(...workspaceToLoad.points);
          this.setSelection(this.points);
        }

        if (workspaceToLoad.algorithms?.length) {
          this.algorithms.push(...workspaceToLoad.algorithms);
          this.setSelection(this.algorithms);
        }

        this.emitCartSelection();
      });
  }

  private clearCart() {
    this.points = [];
    this.algorithms = [];
    this.itemsSelection = {};
  }

  private bindClearItems() {
    this.clearItems$.pipe(untilDestroyed(this)).subscribe((clear) => {
      if (!clear) {
        return;
      }

      this.clearCart();
      this.initDefaultDateRange();
      this.emitCartSelection();
    });
  }

  private checkForDuplicated(
    current: IFiltrableItemDto[],
    added: IFiltrableItemDto[],
    messageKey: string
  ): boolean {
    const propFn = (model: IFiltrableItemDto) => model.filtrableType.key;

    const duplicated =
      this._arrayHelperService.getDuplicatedItemsByProperty(current, added, propFn)?.length > 0;
    if (duplicated) {
      this.showWarningMessage(messageKey);
    }

    return duplicated;
  }

  private getCartSettings(): DataVisualizationCartSettings {
    return new DataVisualizationCartSettings({
      dateRange: globalUtilsHelper.clone(this.selectedDateRange),
      algorithms: this.algorithms.map((a) => globalUtilsHelper.clone(a)),
      points: this.points.map((p) => globalUtilsHelper.clone(p)),
      itemsSelection: globalUtilsHelper.clone(this.itemsSelection),
    });
  }

  private onRemoveItem(items: IFiltrableItemDto[], item: IFiltrableItemDto) {
    const index = items.findIndex((i) => i === item);
    if (index > -1) {
      items.splice(index, 1);
      delete this.itemsSelection[item.filtrableType.key];
    }

    this.emitCartSelection();
  }

  private setSelection(items: IFiltrableItemDto[]) {
    items?.forEach((i) => {
      const key = i.filtrableType.key;
      if (this.itemsSelection[key] === undefined) {
        this.itemsSelection[key] = true;
      }
    });
  }

  private emitCartSelection() {
    const cartSelection = this.getCartSelection();

    this.cartSelectionChange.emit(cartSelection);
  }

  private getCartSelection(): DataVisualizationCartSelection {
    const { start, end } = this.selectedDateRange;

    const cartSettings = this.getCartSettings();

    const algorithms = this.algorithms.map((a) => new AlgorithmFiltrableItemDto(a));
    const points = this.points.map((p) => new SignalFiltrableItemDto(p));

    algorithms.forEach((a) => this._algorithmFiltrableItemService.setDateRange(a, start, end));
    points.forEach((p) => this._signalFiltrableItemService.setDateRange(p, start, end));

    const selectedItems: IFiltrableItemDto[] = [...algorithms, ...points];
    const visibleItems = selectedItems.filter((a) => this.itemsSelection[a.filtrableType.key]);

    return new DataVisualizationCartSelection(visibleItems, selectedItems, cartSettings);
  }

  private showWarningMessage(messageKey: string) {
    const dialogSettings = new WlmDialogSettings({
      translateKey: messageKey,
      icon: 'warning',
    });
    this._dialogService.showTranslatedMessage(dialogSettings);
  }

  private initDefaultDateRange() {
    this.initialDateRange = this.selectedDateRange = this._dateHelperService.createDefaultDateRange(
      this._offsetStartDate
    );
  }
}
