import { Component, Inject, Injector } from '@angular/core';
import { NECScopes } from '@common-modules/dependencies/ne-configuration/nec-scopes';
import { DynamicFormSettings } from '@common-modules/dynamic-forms/models/dynamic-form-settings';
import { DynamicFormUIValues } from '@common-modules/dynamic-forms/models/dynamic-form-ui-values';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import {
  AlgorithmsFormDefinitionsSelector,
  AlgorithmsFormUIValuesSelector,
} from '@common-modules/dynamic-layout/state/algorithms-config/algorithms-config.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

const COMPONENT_SELECTOR = 'wlm-algorithms-config-attribute-form-widget';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './algorithms-config-attribute-form-widget.component.html',
  styleUrls: ['./algorithms-config-attribute-form-widget.component.scss'],
  providers: [ReduxStateService],
})
export class AlgorithmsConfigAttributeFormWidgetComponent extends BaseDynamicWidgetComponent {
  formSettings: DynamicFormSettings;
  values: DynamicFormUIValues;
  categoryKey: string;

  private _scopeSettings = new StateScopeSettings({
    scope: NECScopes.Main,
  });

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    private _objectHelperService: ObjectHelperService
  ) {
    super(injector, widgetSettings);
    this.categoryKey = widgetSettings.params.categoryKey;
  }

  onWidgetInit(): void {
    this._state
      .select(new AlgorithmsFormDefinitionsSelector(this._scopeSettings))
      .pipe(
        untilDestroyed(this),
        filter((data) => data !== undefined),
        map((formDefinitions: { [categoryKey: string]: DynamicFormSettings }) => {
          return formDefinitions[this.categoryKey];
        }),
        distinctUntilChanged(this._objectHelperService.deepEqual)
      )
      .subscribe((definition: DynamicFormSettings) => {
        this.formSettings = definition;
      });

    this._state
      .select(new AlgorithmsFormUIValuesSelector(this._scopeSettings))
      .pipe(
        untilDestroyed(this),
        filter((data) => data !== undefined)
      )
      .subscribe((values: DynamicFormUIValues) => {
        this.values = values;
      });
  }

  get componentName(): string {
    return 'AlgorithmsConfigAttributeFormWidgetComponent';
  }
}
