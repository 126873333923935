<div class="ls-top-group custom-button-toggle">
  <div class="toggle-button-container">
    <mat-button-toggle-group [value]="toggleViewMode" (change)="onChange($event)">
      <mat-button-toggle [value]="currentMode">{{
        T_SCOPE + '.current-selector' | translate
      }}</mat-button-toggle>
      <mat-button-toggle [value]="historicalMode">{{
        T_SCOPE + '.historical-selector' | translate
      }}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<div *ngIf="toggleViewMode === currentMode && currentTemplate" class="detail-panel-styles">
  <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
</div>
<div *ngIf="toggleViewMode === historicalMode && historicalTemplate" class="detail-panel-styles">
  <ng-container
    *ngTemplateOutlet="historicalTemplate; context: { isReadOnly: isReadOnly }"
  ></ng-container>
</div>
