import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FilterItemSelectOption } from '@common-modules/common-filters/models/filter-item-select-option';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { ActivityCategoryTypesEnum } from '@common-modules/dependencies/wlm-filters/i-filters/activity-category-types';
import { ActivityCategoryTypesFilter } from '@common-modules/dependencies/wlm-filters/i-filters/activity-category-types-filter';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasicFilter } from '@common-modules/shared/filters/component-filters/basic-filter';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';
import { ActivityCategoryTypesService } from '@water-loss//features/alc/activities-components/activity-type-filter/activity-category-types.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../../core/base-select-filter-item/base-select-filter-item.component';

const COMPONENT_SELECTOR = 'wlm-activity-types-filter-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './activity-types-filter-item.component.html',
  styleUrls: ['./activity-types-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => ActivityTypesFilterItemComponent),
    },
  ],
})
export class ActivityTypesFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, OnDestroy
{
  @Input() repairFieldName: string;
  @Input() detectionFieldName: string;
  @Input() filterText: string;
  @Input() filters: IFilter[];
  @Input() defaultSelectedIds: any[];

  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);
  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;

  constructor(private _activityCategoryTypesService: ActivityCategoryTypesService) {
    super();
  }

  ngOnInit(): void {
    this.data$$.next(this.getData$());

    this.setDefaultValue();

    super.onInit();
  }

  getData$(): Observable<FilterItemSelectOption[]> {
    return this._activityCategoryTypesService
      .getActivityCategoryTypes()
      .pipe(map((data) => data.map((item) => new FilterItemSelectOption(item.id, item.name))));
  }

  setDefaultValue() {
    if (this.filters?.length) {
      const activityTypeFilter = this.filters.filter(
        (x) => Object.getPrototypeOf(x) === ActivityCategoryTypesFilter.prototype
      );
      if (activityTypeFilter.length) {
        const filter = activityTypeFilter[0] as ActivityCategoryTypesFilter;
        this.selectedIds = [];
        if (filter.includeRepair) {
          this.selectedIds.push(ActivityCategoryTypesEnum.IsRepair);
        }
        if (filter.includeDetection) {
          this.selectedIds.push(ActivityCategoryTypesEnum.IsDetection);
        }
      }
    }
  }

  /**
   * Override the BaseSelect implementation to process the elements in a different way.
   */
  getFiltered$(): Observable<FiltersPayload> {
    return this.filteredNoPayload$.pipe(
      map((filters: BasicFilter[]) => {
        if (filters && filters[0] && filters[0].value && filters[0].value.length > 0) {
          const selected = filters[0].value;
          const repairItem = selected.find(
            (item) => item.value === ActivityCategoryTypesEnum.IsRepair
          );
          const detectionItem = selected.find(
            (item) => item.value === ActivityCategoryTypesEnum.IsDetection
          );
          const formattedFilters = [
            new BasicFilter(this.repairFieldName, repairItem !== undefined),
            new BasicFilter(this.detectionFieldName, detectionItem !== undefined),
          ];

          const payload = this.buildPayload(formattedFilters);
          this.select.emit(payload);
          return payload;
        }
      })
    );
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  isValid(): boolean {
    return true;
  }

  getFieldNames(): string[] {
    return [this.repairFieldName, this.detectionFieldName];
  }

  getAdapter(): FilterAdapterEnum {
    return FilterAdapterEnum.ActivityCategory;
  }
}
