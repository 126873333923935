import { HttpClient, HttpContext, HttpStatusCode } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AdditionalHttpOpts } from '@common-modules/cache/http-cache/additional-http-options';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { CT_HTTP_CACHE } from '@common-modules/shared/interceptors/interceptor-context-tokens';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { Observable } from 'rxjs';
import { delay, filter, map, retryWhen, take } from 'rxjs/operators';
import { CurrentCalculatedValuesViewDto } from '../shared/model/leakage-reporting/current-cv-view.dto';
import { CVRecalculationJobStateResponseDto } from '../shared/model/leakage-reporting/flag-system-response.dto';
import { FlaggedItemsDto } from '../shared/model/leakage-reporting/flagged-items.dto';
import { FlaggingReasonDto } from '../shared/model/leakage-reporting/flagging-reason.dto';
import { SaveEditedValuesDto } from './leakage-reporting-components/save-edited-values.dto';

const endpointUrl = '/api/leakagereporting';

@Injectable()
export class LeakageReportingService extends GridODataService<CurrentCalculatedValuesViewDto> {
  constructor(
    injector: Injector,
    private _httpClient: HttpClient,
    private _dateHelperService: DateHelperService
  ) {
    super(injector, `${endpointUrl}`);
  }

  get url() {
    return `${this.apiUrl}/leakagereporting`;
  }

  protected mapResponse(
    response: PagedResultDto<CurrentCalculatedValuesViewDto>
  ): PagedResultDto<CurrentCalculatedValuesViewDto> {
    return response;
  }

  getFlaggingReasons(): Observable<FlaggingReasonDto[]> {
    return this.httpCacheClient.get(`${this.url}/reasons`);
  }

  saveFlagSystemItems(flaggedItemsDto: FlaggedItemsDto): Observable<string> {
    const requestDatesNoUtc = ['flaggedItems[].date'];
    const addHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });

    return this.httpCacheClient.post(`${this.url}/save`, flaggedItemsDto, null, addHttpOptions);
  }

  getAlgorithmsRecalculationJobState(jobId: string) {
    const context = new HttpContext();
    context.set(CT_HTTP_CACHE, false);

    const result$ = this._httpClient.get<CVRecalculationJobStateResponseDto>(
      `${this.url}/background/save/${jobId}`,
      {
        observe: 'response',
        context,
      }
    );

    let errorCounter = 0;

    const retry$ = result$.pipe(
      map((response) => {
        if (response.status === HttpStatusCode.NoContent) {
          // If no content, raise an error so retryWhen catches it.
          throw { status: response.status };
        }
        return response;
      }),
      // RetryWhen executes when any error is raised (404, 500...).
      retryWhen((error$) =>
        error$.pipe(
          // Only delay and take when our specific error is thrown.
          filter((error: { status: number }) => {
            const hasToRetry =
              error.status === HttpStatusCode.NoContent ||
              (error.status === HttpStatusCode.ServiceUnavailable && errorCounter < 3);

            if (error.status === HttpStatusCode.ServiceUnavailable) {
              errorCounter++;
            }

            if (!hasToRetry) {
              throw error;
            }

            return hasToRetry;
          }),
          delay(25000),
          take(50)
        )
      )
    );

    const mapped$ = retry$.pipe(
      map((x) => {
        const response = x.body;
        return response;
      })
    );
    return mapped$;
  }

  getCurrentCVs(
    startDate: Date,
    endDate: Date,
    ancestors: string[],
    hierarchyElementTypeId: string,
    hierarchyFamilyId: string
  ): Observable<CurrentCalculatedValuesViewDto[]> {
    const queryParams = {
      startDate: this._dateHelperService.toApiFormatNoUTC(startDate),
      endDate: this._dateHelperService.toApiFormatNoUTC(endDate),
      ancestors,
      hierarchyElementTypeId,
      hierarchyFamilyId,
    };
    return this.httpCacheClient
      .get<CurrentCalculatedValuesViewDto[]>(`${this.url}/all`, { avoid: false }, queryParams)
      .pipe(
        map((cvDtos) => {
          return cvDtos.map((cvDto) => {
            const mapped = {
              ...cvDto,
              date: this._dateHelperService.fromApiFormat(cvDto.date as any, false),
            } as CurrentCalculatedValuesViewDto;
            return mapped;
          });
        })
      );
  }

  saveEditedValues(valuesToSave: SaveEditedValuesDto[]): Observable<string> {
    const requestDatesNoUtc = ['[].date'];
    const addHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });

    return this.httpCacheClient.post(
      `${this.url}/save/calculated-values`,
      valuesToSave,
      null,
      addHttpOptions
    );
  }
}
