import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SelectableSettings, TreeViewModule } from '@progress/kendo-angular-treeview';
import { isObservable, Observable } from 'rxjs';
import { MaterialSharedModule } from '../../shared/material-shared.module';
import { TreeFlatElementDto } from '../../shared/model/shared/tree-flat-element.dto';

export class TreeDropdownSettings {
  selectionMode: 'multiple' | 'single' = 'single';
  expandedMinHeight: string;
  expandedMaxHeight: string;
  placeholder: string;

  constructor() {}
}

type TreeNodesType = TreeFlatElementDto<string>[] | Observable<TreeFlatElementDto<string>[]>;

@Component({
  selector: 'wlm-tree-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialSharedModule,
    TranslateModule,
    TreeViewModule,
  ],
  templateUrl: './tree-dropdown.component.html',
  styleUrl: './tree-dropdown.component.scss',
})
export class TreeDropdownComponent {
  @Input() settings: TreeDropdownSettings;

  private _treeNodes: TreeNodesType;
  get treeNodes(): TreeNodesType {
    return this._treeNodes;
  }
  @Input() set treeNodes(value: TreeNodesType) {
    this._treeNodes = value;
    if (this.treeNodes) {
      if (isObservable(this.treeNodes)) {
        this.treeNodes.subscribe((nodes) => {
          this.resolvedNodes = nodes;
          this.displayInControl();
        });
      } else {
        this.resolvedNodes = this.treeNodes;
        this.displayInControl();
      }
    }
  }

  private _selectedKeys: string[];
  get selectedKeys(): string[] {
    return this._selectedKeys ?? [];
  }
  @Input() set selectedKeys(value: string[]) {
    this._selectedKeys = value;
    this.displayInControl();
    this.updateExpandedKeys();
  }

  private _disabled: boolean;
  get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.disabled ? this.innerCtrl.disable() : this.innerCtrl.enable();
  }

  @Output() selectedKeysChange = new EventEmitter<string[] | string>();

  resolvedNodes: TreeFlatElementDto<string>[] = [];
  isLoading = false;
  expandedKeys: string[] = [];
  readonly innerCtrl = new FormControl();

  handleSelection(event): void {
    const selectedNode: TreeFlatElementDto<string> = event.dataItem;
    if (this.selectedKeys.find((key) => key === selectedNode.currentId)) {
      this.selectedKeys = this.selectedKeys.filter((key) => key !== selectedNode.currentId);
    } else {
      if (this.isSingleSelect()) {
        this.selectedKeys = [selectedNode.currentId];
      } else {
        this.selectedKeys = [...this.selectedKeys, selectedNode.currentId];
      }
    }
    this.updateExpandedKeys();

    this.displayInControl();

    if (this.isSingleSelect()) {
      this.selectedKeysChange.emit(this.selectedKeys.length ? this.selectedKeys[0] : null);
    } else {
      this.selectedKeysChange.emit(this.selectedKeys);
    }
  }

  isItemSelected = (dataItem: TreeFlatElementDto<string>) =>
    this.selectedKeys?.indexOf(dataItem.currentId) > -1;

  handleClick = (event) => event.originalEvent.preventDefault();

  get selection(): SelectableSettings {
    return { mode: this.settings?.selectionMode };
  }

  stopPropagation(event): void {
    event.stopPropagation();
  }

  onMenuClosed(event): void {
    this.expandedKeys = [];
  }

  private updateExpandedKeys(): void {}

  private isSingleSelect = () => this.settings?.selectionMode === 'single';

  private displayInControl(): void {
    if (!this.selectedKeys) {
      this.innerCtrl.setValue('');
    } else {
      const labels = this.selectedKeys.map(
        (key) => this.resolvedNodes.find((node) => node.currentId === key)?.currentDescription
      );

      this.innerCtrl.setValue(labels.join(', '));
    }
  }
}
