import { Injectable, Injector } from '@angular/core';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { AlarmRelatedElementDto } from '../../../shared/model/alarms/alarm-related-element.dto';

const endpointUrl = '/api/alarm';
const oDataEndPoint = 'related-elements';

@Injectable({
  providedIn: 'root',
})
export class AlarmRelatedElementsService extends GridODataService<AlarmRelatedElementDto> {
  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/${oDataEndPoint}`);
  }

  protected mapResponse(
    response: PagedResultDto<AlarmRelatedElementDto>
  ): PagedResultDto<AlarmRelatedElementDto> {
    return response;
  }
}
