import { Injectable, Injector } from '@angular/core';
import { GetCacheOpts } from '@common-modules/cache/http-cache/http-cache.client';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { Observable, map } from 'rxjs';
import { LargeUserAssignedDto } from '../../shared/model/signals/large-user-assigned.dto';
import { LargeUserAvailableDto } from '../../shared/model/signals/large-user-available.dto';

const endpointUrl = '/api/large-user';
@Injectable({
  providedIn: 'root',
})
export class NeConfigurationLargeUserService extends GridODataService<LargeUserAvailableDto> {
  expiration: GetCacheOpts = { avoid: true };

  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/available`);
  }

  protected mapResponse(
    response: PagedResultDto<LargeUserAvailableDto>
  ): PagedResultDto<LargeUserAvailableDto> {
    return response;
  }

  getLargeUsersAssignedByZone(elementId: string): Observable<LargeUserAssignedDto[]> {
    return this.httpCacheClient.get(`${this.baseUrl}${endpointUrl}/${elementId}`, {
      avoid: true,
    });
  }

  saveLargeUserConfiguration(
    largeUsersConfigurations: LargeUserAssignedDto[]
  ): Observable<boolean> {
    return this.httpCacheClient.post(
      `${this.baseUrl}${endpointUrl}/save`,
      largeUsersConfigurations
    );
  }

  deleteLargeUserConfiguration(largeUserAssigned: LargeUserAssignedDto): Observable<boolean> {
    const { hierarchyElementId, largeUserId, propertyId } = largeUserAssigned;
    const params = { hierarchyElementId, largeUserId, propertyId };

    return this.httpCacheClient.delete(
      `${this.baseUrl}${endpointUrl}/delete`,
      {
        avoid: true,
      },
      params
    );
  }

  getLargeUserByPropertyId(propertyId: string): Observable<LargeUserAvailableDto[]> {
    return this.httpCacheClient
      .get(`${this.baseUrl}${endpointUrl}/search/${propertyId}`, {
        avoid: true,
      })
      .pipe(map((data: any) => data));
  }
}
