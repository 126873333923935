import { Directive, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { Observable } from 'rxjs';
import { BaseChartComponent } from '../../base-chart/base-chart.component';
import { GenericChartComponent } from '../../generic-chart/generic-chart.component';
import { BaseHistoricalValidationService } from '../../services/base-historical-validations.service';
import { ChartSettings } from '../chart-settings';
import { GenericChartSettings } from './generic-chart-settings';

@Directive()
export abstract class BaseCrudGenericChartComponent<
  TChartDataParameters extends {
    dataService: string;
  },
  TInputDataType, // Schematic, IEventDto[]
  TInputItemType, // Schematic, IEventDto,
  TChartDataService
> extends BaseChartComponent {
  @ViewChild(GenericChartComponent) genericChart: GenericChartComponent;

  private _chartSettings: ChartSettings;
  genericChartSettingsSubscription: any;
  public get chartSettings(): ChartSettings {
    return this._chartSettings;
  }
  @Input() public set chartSettings(v: ChartSettings) {
    this._chartSettings = v;
    if (this.chartSettings) {
      this.instanceDataService();
      this.instanceValidationService();
      this.loadChart();
      this.onSetChartSettings();
    }
  }

  @Input() updateChartNewData$: Observable<TInputDataType>;
  @Input() updateChartEditedData$: Observable<any>;
  @Input() updateChartDeletedData$: Observable<TInputDataType>;

  @Input() discardData$: Observable<void>;
  @Input() saveData$: Observable<TInputDataType>;
  @Output() saveCompleted = new EventEmitter<any>();
  @Output() hasChanges = new EventEmitter<boolean>();
  @Output() hasErrors = new EventEmitter<boolean>();
  @Output() selectItem = new EventEmitter<TInputItemType>();

  disableInnerLoading: any;
  dataLoaded: boolean;

  protected _currentData: TInputDataType;
  protected _originalData: TInputDataType;
  protected _dataService: TChartDataService;
  private _validationService: BaseHistoricalValidationService;

  constructor(_injector: Injector, protected _objectHelperService: ObjectHelperService) {
    super(_injector);
  }

  abstract onSetChartSettings(): void;

  private instanceDataService() {
    if (!this._dataService) {
      this._dataService = this._injector.get(
        (this.chartSettings?.dataParameters as any)?.dataService
      );
    }
  }

  private instanceValidationService() {
    if ((this.chartSettings as any).validationService) {
      this._validationService = this._injector.get((this.chartSettings as any)?.validationService);
    }
  }

  private reloadChart() {
    this.resetData();
    this.loadChart();
    this.hasChanges.emit(false);
    this.hasErrors.emit(false);
  }

  private resetData(): void {}

  private loadChart(dataParameters?: TChartDataParameters) {
    this.setLoading(true);

    this.genericChartSettingsSubscription = this.getGenericChartSettings(dataParameters).subscribe({
      next: (settings) => {
        this.hasChanges.emit(false);
        this.genericChartSettings = settings;
        this.dataLoaded = true;
        this.setLoading(false);
      },
      error: (error) => {
        this.hasChanges.emit(false);
        this.dataLoaded = true;
        this.setLoading(false);
        this.genericChartSettings = null;
        throw error;
      },
    });
  }

  abstract getGenericChartSettings(
    dataParameters?: TChartDataParameters
  ): Observable<GenericChartSettings>;
}
