import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHierarchyElementDto } from '@common-modules/dependencies/he/hierarchy-element.dto';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { Observable, ReplaySubject } from 'rxjs';

import { SATargetSelectorSettings } from '../models/sa-target-selector-settings';

const COMPONENT_SELECTOR = 'wlm-sa-target-selection';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './sa-target-selection.component.html',
  styleUrls: ['./sa-target-selection.component.scss'],
})
export class SaTargetSelectionComponent implements OnInit {
  private _heTypeSelected: boolean;
  public get heTypeSelected(): boolean {
    return this._heTypeSelected;
  }
  @Input() public set heTypeSelected(value: boolean) {
    this._heTypeSelected = value;
  }
  @Input() targetSettings: SATargetSelectorSettings;
  @Input() resetHandler$: Observable<boolean>;
  @Input() resetCompleteHandler$: ReplaySubject<void>;

  @Output() selectedTargetChange = new EventEmitter<
    INetworkElementDto[] | IHierarchyElementDto[]
  >();

  @Input() persistencyAreaHE: string;
  @Input() persistencyAreaNE: string;

  isHEType = true;
  constructor() {}

  ngOnInit(): void {}

  onTargetChanged(targetSelected: INetworkElementDto[] | IHierarchyElementDto[]) {
    this.selectedTargetChange.emit(targetSelected);
  }
}
