import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IHierarchyElementDto } from '@common-modules/dependencies/he/hierarchy-element.dto';
import * as constants from '@common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { HierarchyFamilyFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/hierarchy-family-fi-settings';
import { HierarchyTreeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/hierarchy-tree-fi-settings';
import { NeHeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { HierarchyElementsSelectionFilterConfiguration } from '@common-modules/shared-component/combined-grids/hierarchy-elements-selection-grid/hierarchy-elements-selection-filter-configuration';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { LocalStorageService } from '@common-modules/shared/local-storage.service';
import { IAlgorithmDto } from '@common-modules/shared/model/algorithm/algorithm.dto';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { IDeletedZonesDto } from '../../../shared/model/deleted-zones/deleted-zones.dto';

const COMPONENT_SELECTOR = 'wlm-data-visualization-he-algorithm-popup';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-he-algorithm-popup.component.html',
  styleUrls: ['./data-visualization-he-algorithm-popup.component.scss'],
})
export class DataVisualizationHeAlgorithmPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;

  private readonly _hierarchyElementIdFieldName = 'hierarchyElementId';
  private readonly _hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  private readonly _hierarchyElementTypeFieldName = 'hierarchyElementTypeId';

  toggleViewMode: 'active' | 'deleted' = 'active';
  activeZonesMode = 'active';
  deletedZonesMode = 'deleted';

  filterCssClass = 'remove-filters-background';

  algorithm: IAlgorithmDto;

  defaultFamily: string;
  defaultHETypeIds: string[];

  configReady = false;
  heSelectionFilterConfig: HierarchyElementsSelectionFilterConfiguration;
  selectedElements: IHierarchyElementDto[];
  selectedDeletedElements: IDeletedZonesDto[];

  persistedHeFamilyId: string;
  persistedHeTree: string[];
  persistedHeTypeIds: string[];

  get componentName(): string {
    return 'DataVisualizationHeAlgorithmPopupComponent';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) { algorithm }: any,
    private _dialogRef: MatDialogRef<DataVisualizationHeAlgorithmPopupComponent>,
    private _globalsService: GlobalsService,
    private _localStorageService: LocalStorageService
  ) {
    this.algorithm = algorithm;
  }

  ngOnInit(): void {
    this.loadPersistedState();

    this._globalsService.getDefaultHierarchyFamilyId().subscribe({
      next: (family) => {
        this.defaultFamily = family;

        this._globalsService.getHierarchyElementTypes(this.defaultFamily).subscribe({
          next: (hierarchies) => {
            if (!this.defaultHETypeIds) {
              this.defaultHETypeIds = hierarchies
                .filter((he) => he.hierarchyElementTypeName === constants.hierarchyElementTypes.DMA)
                ?.map((x) => x.hierarchyElementTypeId);
            }

            this.heSelectionFilterConfig = this.getHierarchyElementFilterConfiguration(
              this.defaultFamily
            );

            this.configReady = true;
          },
        });
      },
    });
  }

  onHierarchyElementsChanged(selectedHes: IHierarchyElementDto[]) {
    this.selectedElements = selectedHes;
  }

  onDeletedZonesChanged(selectedDeletedZones: IDeletedZonesDto[]) {
    this.selectedDeletedElements = selectedDeletedZones;
  }

  cancel(): void {
    this._dialogRef.close();
  }

  add(): void {
    const elements = [...(this.selectedElements ?? []), ...(this.selectedDeletedElements ?? [])];

    this._dialogRef.close({
      algorithm: this.algorithm,
      elements: elements,
    });
  }

  onChangeView(event: MatButtonToggleChange) {
    this.toggleViewMode = event.value;
  }

  private loadPersistedState() {
    // Persisted HeFamilyId
    const heFamilyId = this.getPersisted(
      `${this.componentName}-${this._hierarchyElementFamilyFieldName}`,
      null,
      false
    );

    this.persistedHeFamilyId = heFamilyId?.value ? heFamilyId?.value[0]?.value : null;

    // Persisted HeTree
    const heTree = this.getPersisted(
      `${this.componentName}-${this._hierarchyElementIdFieldName}`,
      null,
      false
    );

    this.persistedHeTree = heTree?.value ? heTree?.value?.map((x) => x) : null;

    // Persisted HeTypeIds
    const heTypeIds = this.getPersisted(
      `${this.componentName}-${this._hierarchyElementTypeFieldName}`,
      null,
      false
    );
    this.persistedHeTypeIds = heTypeIds?.value ? heTypeIds.value?.map((x) => x.value) : null;
  }

  private getHierarchyElementFilterConfiguration(
    defaultFamilyId: string
  ): HierarchyElementsSelectionFilterConfiguration {
    return new HierarchyElementsSelectionFilterConfiguration({
      persistencyArea: this.componentName,
      familySettings: new HierarchyFamilyFISettings({
        fieldName: this._hierarchyElementFamilyFieldName,
        selectedId: this.persistedHeFamilyId ?? defaultFamilyId,
        storageLocation: 'session',
      }),
      treeSettings: new HierarchyTreeFISettings({
        fieldName: this._hierarchyElementIdFieldName,
        selectedIds: this.persistedHeTree ?? [],
        storageLocation: 'session',
      }),
      heTypeSettings: new NeHeFISettings({
        fieldName: this._hierarchyElementTypeFieldName,
        selectedIds: this.persistedHeTypeIds ?? this.defaultHETypeIds,
        defaultValue: this.persistedHeTypeIds ?? this.defaultHETypeIds,
        isNEFilter: false,
        storageLocation: 'session',
      }),
      hideFilterButtons: true,
      cssClass: this.filterCssClass,
    });
  }

  private getPersisted(key: string, defaultValue?: any, useLocalStorage?: boolean): any {
    return this._localStorageService.getTyped(key, defaultValue, useLocalStorage);
  }
}
