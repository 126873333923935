import { Injectable, Injector } from '@angular/core';
import { CalendarDto } from '@common-modules/dependencies/shared/model/calendar.dto';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable } from 'rxjs';
import { CalendarExceptionDtoBase } from '../models/calendar-exception-base.dto';
import { CreateCalendarExceptionDto } from '../models/create-calendar-exception.dto';
import { DeleteCalendarExceptionDto } from '../models/delete-calendar-exception.dto';

@Injectable()
export class CalendarService extends BaseService {
  get apiUrl() {
    return `${this.baseUrl}/api/calendar`;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  getCalendars(): Observable<CalendarDto[]> {
    return this.httpCacheClient.get(`${this.apiUrl}/all`, {
      avoid: true,
    });
  }

  createCalendar(calendar: CalendarDto) {
    return this.httpCacheClient.post(`${this.apiUrl}`, calendar);
  }

  editCalendar(calendar: CalendarDto) {
    return this.httpCacheClient.put(`${this.apiUrl}`, calendar);
  }

  deleteCalendar(calendarId: string) {
    return this.httpCacheClient.delete(`${this.apiUrl}/${calendarId}`);
  }

  createCalendarException(calendarException: CreateCalendarExceptionDto) {
    return this.httpCacheClient.post(`${this.apiUrl}/exception`, calendarException);
  }

  deleteCalendarException(calendarException: DeleteCalendarExceptionDto) {
    return this.httpCacheClient.delete(
      `${this.apiUrl}/exception`,
      null,
      null,
      null,
      calendarException
    );
  }

  getCalendarExceptions(calendarId: string): Observable<CalendarExceptionDtoBase[]> {
    const params = {
      calendarId,
    };
    return this.httpCacheClient.get(
      `${this.apiUrl}/exception`,
      {
        avoid: true,
      },
      params
    );
  }
}
