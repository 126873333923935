import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PointReplacementDto,
  PointReplacementResponseDto,
} from '@common-modules/dependencies/signals/models/point-replacement.dto';
import { SignalsService } from '@common-modules/dependencies/signals/signals.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { ISignalTelemetryNullableViewDto } from '@common-modules/shared/model/telemetry/signal-telemetry-nullable-view.dto';
import { RepaceSignalValidator } from './replace-signal-validator';
const COMPONENT_SELECTOR = 'wlm-replace-signal-popup';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './replace-signal-popup.component.html',
  styleUrls: ['./replace-signal-popup.component.scss'],
})
export class ReplaceSignalPopupComponent implements OnInit {
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  form: UntypedFormGroup;
  public get formTitle(): string {
    return `${this.T_SCOPE}.title`;
  }
  selectedPoint: ISignalTelemetryNullableViewDto;
  isSaving = false;
  currentUser: string;

  pointIdReplacementResponse: PointReplacementResponseDto;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ReplaceSignalPopupComponent>,
    @Inject(MAT_DIALOG_DATA) { signal, user }: any,
    private _signalsService: SignalsService,
    private authenticationService: AuthenticationService
  ) {
    this.selectedPoint = signal;
    this.currentUser = user;
    this.createForm();
  }

  ngOnInit(): void {}
  private createForm() {
    this.form = this.fb.group(
      {
        pointIdDestination: ['', [Validators.required]],
        pointIdSource: [{ value: this.selectedPoint.pointId, disabled: true }],
        pointIdResponse: [{ value: this.pointIdReplacementResponse }],
      },
      {
        validators: RepaceSignalValidator.pointIdValidation(),
      }
    );
  }

  close() {
    this.dialogRef.close(this.pointIdReplacementResponse);
  }

  save() {
    if (this.form.valid) {
      this.isSaving = true;
      this.pointIdReplacementResponse = null;
      const userName = this.authenticationService.userCode;
      const pointReplaceQuery = new PointReplacementDto(
        this.form.get('pointIdSource')?.value,
        this.form.get('pointIdDestination')?.value,
        userName
      );

      this._signalsService.replaceSignalConfiguration(pointReplaceQuery).subscribe({
        next: (pointReplacementResponse) => {
          this.isSaving = false;
          this.pointIdReplacementResponse = pointReplacementResponse;
          if (pointReplacementResponse.hasErrors) {
            this.form.get('pointIdResponse').setValue(pointReplacementResponse);
            this.form.updateValueAndValidity();
          } else {
            this.close();
          }
        },
        error: (error) => {
          this.isSaving = false;
        },
      });
    }
  }

  removePointIdResponse() {
    this.form.get('pointIdResponse').setValue(null);
  }
}
