import { Injectable, Injector } from '@angular/core';
import { MVService } from '@common-modules/dependencies/mv/mv.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { UtilsHelperService } from '@common-modules/shared/helpers/utils-helper.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { MVChartPointDto } from '@common-modules/shared/model/mv/mv-chart-point.dto';
import { MVChartSerieDto } from '@common-modules/shared/model/mv/mv-chart-serie.dto';
import { IMVDto } from '@common-modules/shared/model/mv/mv-dto';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { ChartSerieTypeEnum } from '@common-modules/wlm-charts/core/models/chart-serie-type.enum';
import { DataValidationEstimationFunctionType } from '@common-modules/wlm-charts/core/models/data-validation/data-validation-functions';
import { EditableChartDataParameters } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-data-parameters';
import { EditableChartDateRange } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-date-range';
import { IEditableChartPointSettings } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-point.settings';
import { EditableChartSelection } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-selection';
import { EditableChartValueRange } from '@common-modules/wlm-charts/core/models/editable-chart/editable-chart-value-range';
import { GCartesianChartSerie } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-cartesian-chart-series';
import { BrushMode } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-brush';
import { GChartSerieDataPoint } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-serie-data-point';
import { GenericCartesianChartSettings } from '@common-modules/wlm-charts/core/models/generic-chart-settings/generic-cartesian-chart-settings';
import { BaseEditableChartService } from '@common-modules/wlm-charts/core/services/base-editable-chart.service';
import { asEnumerable } from 'linq-es2015';
import { Observable, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DataValidationEstimationService } from './data-validation-estimation.service';

@Injectable()
export class DataValidationChartService extends BaseEditableChartService {
  private _timeAggregationForSignals = 1;
  private _naUnitTypeId = 50;
  private _startDate: Date;
  private _endDate: Date;

  public dimensionTypeId: number;

  constructor(
    injector: Injector,
    private _mvService: MVService,
    private _uomService: UoMService,
    private _dialogService: DialogService,
    private _dateHelper: DateHelperService,
    private _utilsService: UtilsHelperService,
    private _objectHelperService: ObjectHelperService,
    private _dvEstimationService: DataValidationEstimationService
  ) {
    super(injector);
  }

  protected get url() {
    return `${this.apiUrl}/dv`;
  }

  public getData(dataQueries: EditableChartDataParameters): Observable<any> {
    if (!dataQueries?.queryParams?.['signalIds']) {
      return of(null);
    }

    const { startDate, endDate } = dataQueries.queryParams;
    this._startDate = startDate;
    this._endDate = this._dateHelper.addSeconds(endDate, -1);

    return this._mvService.getChart(dataQueries.queryParams).pipe(map(this.transformData));
  }

  public getDataByRangeAndFunction(
    data: MVChartPointDto[],
    chartSelection: EditableChartSelection,
    signalId?: number
  ) {
    const { estimationFunction, rangesConverted, selectionType } = chartSelection;

    let extraProperties = {
      ...chartSelection.formValues,
      signalId,
      hasToCreate: true,
    };

    let dataPoints$: Observable<MVChartPointDto[]>[] = [];

    const localMvs = data.map((p) => this.getIMVDtoFromMVChartPointDto(signalId, p));

    rangesConverted.forEach((range) => {
      let mvs: IMVDto[] = [];

      switch (selectionType) {
        case BrushMode.Horizontal:
          mvs = this.getDataByHorizontalRange(signalId, data, range);
          const rangeDates = { startDate: range.startDate, endDate: range.endDate };
          extraProperties = { ...extraProperties, ...rangeDates };
          break;

        case BrushMode.Vertical:
        default:
          mvs = this.getDataByVerticalRange(signalId, data, range);

          if (mvs?.length) {
            const mvDates = mvs.map((x) => new Date(x.measureTimestamp));
            const startDate = this._dateHelper.convertUTCStoredDateToLocal(
              new Date(Math.min.apply(null, mvDates))
            );
            const endDate = this._dateHelper.convertUTCStoredDateToLocal(
              new Date(Math.max.apply(null, mvDates))
            );

            const mvRangeDates = { startDate, endDate };
            extraProperties = { ...extraProperties, ...mvRangeDates, hasToCreate: false };
          }

          break;
      }

      const points$ = this._dvEstimationService
        .applyFn(estimationFunction?.functionName, mvs, localMvs, extraProperties)
        .pipe(
          take(1),
          map((response) => {
            if (!response.isCompleted) {
              this.showWarningMessage(this.getWarningMessageFromFunction(estimationFunction.type));
            }

            return response.measuredValues.map((mv) => this.getMVChartPointDtoFromIMVDto(mv));
          })
        );

      dataPoints$.push(points$);
    });

    return forkJoin(dataPoints$).pipe(
      take(1),
      map((dataPoints) => {
        const result = dataPoints.reduce((acc, value) => acc.concat(value), []);

        return asEnumerable(result)
          .OrderBy((p) => p.measureTimestamp)
          .ToArray();
      })
    );
  }

  public saveData(
    currentData: MVChartPointDto[],
    originalData: MVChartPointDto[],
    signalId: number
  ): Observable<boolean> {
    const editedData = this.getEditedData(signalId, currentData, originalData) as IMVDto[];

    return this._mvService.update(signalId, editedData);
  }

  public mapDataToGenericSettings(
    data: MVChartSerieDto
  ): Observable<GenericCartesianChartSettings> {
    if (!data?.signalId) {
      return of(null);
    }

    return this.getMVGCartesianChartSerie(data).pipe(
      take(1),
      map(
        (serie) =>
          new GenericCartesianChartSettings({
            series: [serie],
          })
      )
    );
  }

  public setSettings(settings: GenericCartesianChartSettings): void {
    this.dimensionTypeId = settings?.series?.[0]?.yAxisWLMDimensionTypeId ?? 0;
  }

  public updateChartSerieByPoints(
    serie: GCartesianChartSerie,
    points: MVChartPointDto[]
  ): Observable<GChartSerieDataPoint[]> {
    const updatedPointIds = points.map((p) => p.id);
    const dataPoints = serie.dataPoints.filter((chartPoint) => {
      return !updatedPointIds.some((id) => id === chartPoint.extraProperties.id);
    });

    const dataPoints$ = points.map((point) =>
      this.convertValueToUoM(point.value, point.dimensionTypeId).pipe(
        take(1),
        map((value) => {
          const newChartPoint = this.getChartSerieDataPoint(point, {
            size: this.editedChartPointSize,
          } as IEditableChartPointSettings);

          // Chart value need to be converted to UoM
          newChartPoint.pointValue = value;
          return newChartPoint;
        })
      )
    );

    if (!dataPoints$?.length) {
      of(dataPoints);
    }

    return forkJoin(dataPoints$).pipe(
      take(1),
      map((convertedDataPoints) => {
        return asEnumerable(dataPoints.concat(convertedDataPoints))
          .OrderBy((p) => p.pointCategory)
          .ToArray();
      })
    );
  }

  public getChartUnitLabel() {
    return this._uomService.getByParams(this.dimensionTypeId, this._timeAggregationForSignals).pipe(
      take(1),
      map((unitTypeConversion: UnitTypeConversionViewDto) => {
        if (!unitTypeConversion?.unitTypeToDescription) {
          return '';
        }

        return unitTypeConversion.unitTypeToDescription;
      })
    );
  }

  public convertValueToUoM(pointValue: number, dimensionTypeId: number): Observable<number> {
    return this._uomService
      .getByParams(dimensionTypeId ?? this.dimensionTypeId, this._timeAggregationForSignals)
      .pipe(
        take(1),
        map((unitTypeConversion: UnitTypeConversionViewDto) => {
          if (!unitTypeConversion?.conversionFactor) {
            return pointValue;
          }

          const result = this._utilsService.uomMultiply(
            String(pointValue),
            String(unitTypeConversion.conversionFactor)
          );

          return +result;
        })
      );
  }

  public convertValuePairArrayFromUoM(
    range: [number, number][],
    dimensionTypeId: number
  ): Observable<[number, number][]> {
    return this._uomService.getByParams(dimensionTypeId, this._timeAggregationForSignals).pipe(
      take(1),
      map((unitTypeConversion: UnitTypeConversionViewDto) => {
        if (!unitTypeConversion?.conversionFactor) {
          return range;
        }

        const rangeConverted = [];
        range.forEach((pair) => {
          const value1 = this.convertSingleValueInternal(pair[0], unitTypeConversion);
          const value2 = this.convertSingleValueInternal(pair[1], unitTypeConversion);
          rangeConverted.push([value1, value2]);
        });

        return rangeConverted;
      })
    );
  }

  public convertValueFromUoM(pointValue: number, dimensionTypeId: number): Observable<number> {
    return this._uomService.getByParams(dimensionTypeId, this._timeAggregationForSignals).pipe(
      take(1),
      map((unitTypeConversion: UnitTypeConversionViewDto) => {
        if (!unitTypeConversion?.conversionFactor) {
          return pointValue;
        }

        return this.convertSingleValueInternal(pointValue, unitTypeConversion);
      })
    );
  }

  private convertSingleValueInternal(value: number, unitTypeConversion: UnitTypeConversionViewDto) {
    return +this._utilsService.uomDivide(
      String(value),
      String(unitTypeConversion.conversionFactor)
    );
  }

  private getMVGCartesianChartSerie(serie: MVChartSerieDto): Observable<GCartesianChartSerie> {
    const dataPoints = serie.points.map((point) => this.getChartSerieDataPoint(point));

    return this._uomService
      .getByParams(serie.dimensionTypeId, this._timeAggregationForSignals)
      .pipe(
        map((unit) => {
          const cartesianChartSerie: GCartesianChartSerie = {
            dataPoints,
            name: serie.pointDescription,
            type: ChartSerieTypeEnum.Line,
            yAxisIndex: 0,
            xAxisIndex: 0,
            xAxisWLMDimensionTypeId: DimensionTypesEnum.NA,
            yAxisWLMDimensionTypeId: serie.dimensionTypeId,
            yAxisWLMUnitTypeIdFrom: unit?.unitTypeFromId ?? this._naUnitTypeId,
            yAxisWLMUnitTypeIdTo: unit?.unitTypeToId ?? this._naUnitTypeId,
            yAxisName: unit?.unitTypeToDescription ?? '',
            largeData: true,
            showInUtc: serie.isFlatten,
            xMin: this._startDate,
            xMax: this._endDate,
          };

          return cartesianChartSerie;
        })
      );
  }

  private transformData(data: any) {
    let serie = data?.[0] as MVChartSerieDto;

    serie?.points?.forEach((p) => (p.dimensionTypeId = serie.dimensionTypeId));

    return serie;
  }

  private getChartSerieDataPoint(
    point: MVChartPointDto,
    pointSettings?: IEditableChartPointSettings
  ): GChartSerieDataPoint {
    const dataPoint: GChartSerieDataPoint = {
      pointCategory: this._dateHelper.fromApiFormat(point.measureTimestamp),
      pointValue: point.value,
      color:
        pointSettings?.color ??
        this.getDataPointColorByEstimatedEdited(point.estimatedEdited, point.validity),
      hasToShowSymbol: Boolean(point.estimatedEdited) || Boolean(point.validity),
      alwaysShowSymbol: Boolean(point.estimatedEdited) || Boolean(point.validity),
      symbolSize:
        Boolean(point.estimatedEdited) || Boolean(point.validity) ? pointSettings?.size : undefined,
      extraProperties: point,
    };

    return dataPoint;
  }

  private getEditedData(
    signalId: number,
    currentData: MVChartPointDto[],
    originalData: MVChartPointDto[]
  ) {
    const editedData: IMVDto[] = [];

    currentData.forEach((current) => {
      const original = originalData.find((o) => o.id === current.id);
      if (!this._objectHelperService.deepEqual(original, current)) {
        editedData.push(this.getIMVDtoFromMVChartPointDto(signalId, current));
      }
    });

    return editedData;
  }

  private getIMVDtoFromMVChartPointDto(signalId: number, chartPoint: MVChartPointDto) {
    return {
      id: chartPoint.id,
      signalId: signalId,
      measureTimestamp: chartPoint.measureTimestamp,
      value: chartPoint.value,
      fieldQuality: chartPoint.fieldQuality,
      validity: chartPoint.validity,
      estimatedEdited: chartPoint.estimatedEdited,
      arrivalDateTime: chartPoint.arrivalDateTime,
      dimensionTypeId: chartPoint.dimensionTypeId,
    } as IMVDto;
  }

  private getDataByVerticalRange(
    signalId: number,
    chartPoints: MVChartPointDto[],
    range: EditableChartValueRange
  ): IMVDto[] {
    const measuredValues = chartPoints
      .filter((p) => p.value >= range.startValue && p.value <= range.endValue)
      .map((p) => this.getIMVDtoFromMVChartPointDto(signalId, p));

    return measuredValues;
  }

  private getDataByHorizontalRange(
    signalId: number,
    chartPoints: MVChartPointDto[],
    range: EditableChartDateRange
  ): IMVDto[] {
    const measuredValues = chartPoints
      .filter(
        (p) =>
          this._dateHelper.fromApiFormat(p.measureTimestamp).getTime() >=
            range.startDate.getTime() &&
          this._dateHelper.fromApiFormat(p.measureTimestamp).getTime() <= range.endDate.getTime()
      )
      .map((p) => this.getIMVDtoFromMVChartPointDto(signalId, p));

    return measuredValues;
  }

  private getMVChartPointDtoFromIMVDto(mv: IMVDto) {
    return {
      id: mv.id,
      measureTimestamp: mv.measureTimestamp,
      value: mv.value,
      fieldQuality: mv.fieldQuality,
      validity: mv.validity,
      estimatedEdited: mv.estimatedEdited,
      arrivalDateTime: mv.arrivalDateTime,
      dimensionTypeId: mv.dimensionTypeId,
    } as MVChartPointDto;
  }

  private getWarningMessageFromFunction(functionType: DataValidationEstimationFunctionType) {
    switch (functionType) {
      case DataValidationEstimationFunctionType.Fixed:
        return `${AppModules.DataValidation}.messages.fixed-function-invalid-period`;

      default:
        return `${AppModules.DataValidation}.messages.function-empty-result`;
    }
  }

  private showWarningMessage(messageKey: string) {
    const dialogSettings = new WlmDialogSettings({
      translateKey: messageKey,
      icon: 'warning',
    });
    this._dialogService.showTranslatedMessage(dialogSettings);
  }
}
