import { Component, DestroyRef, Inject, Injector, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IInterfacesLoggingSelector } from '@common-modules/dependencies/logs/models/interfaces-logging-selector';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { DynamicLayoutService } from '@common-modules/dynamic-layout/services/dynamic-layout.service';
import { BaseSelectorDynamicWidgetComponent } from '@common-modules/redux/components/base-selector-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { filter } from 'rxjs';
import { LogsInterfacesSelectorComponent } from '../../logs-interfaces-selector/logs-interfaces-selector.component';
import { LogsInterfacesStateFields } from '../../models/logs-interfaces-state-fields';
import {
  RefreshLogIterfaceSelectorAction,
  RefreshLogIterfaceSelectorSelector,
} from '../refresh-log-interfaces-selector.event';

const COMPONENT_SELECTOR = 'wlm-logs-interfaces-selector-widget';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './logs-interfaces-selector-widget.component.html',
  styleUrls: ['./logs-interfaces-selector-widget.component.scss'],
  providers: [ReduxStateService, DynamicLayoutService],
})
export class LogsInterfacesSelectorWidgetComponent
  extends BaseSelectorDynamicWidgetComponent
  implements OnInit
{
  get selectedFieldName(): string {
    return LogsInterfacesStateFields.selectedInterface;
  }

  get componentName(): string {
    return 'LogsInterfacesSelectorWidgetComponent';
  }

  private readonly _destroyRef = inject(DestroyRef);

  @ViewChild(LogsInterfacesSelectorComponent) selectorComponent: LogsInterfacesSelectorComponent;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings,
    pendingChangesService: PendingChangesManagerService
  ) {
    super(injector, widgetSettings, pendingChangesService);
  }

  onWidgetInit(): void {
    this.emitWidgetInit();

    this._state
      .select(new RefreshLogIterfaceSelectorSelector())
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((value) => !!value)
      )
      .subscribe(() => {
        if (this.selectorComponent) {
          this.selectorComponent.loadSelectorItems();
        }
      });
  }

  onSelectedChange(selectedInterface: IInterfacesLoggingSelector) {
    this.setSelected(selectedInterface);
  }

  ngOnDestroy() {
    this.setSelected(null);
    this._state.dispatch(new RefreshLogIterfaceSelectorAction(null));
  }
}
