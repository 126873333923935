import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { getLocaleFirstDayOfWeek } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { LocalizationHelperService } from '../localization/localization-helper.service';

/**
 * This adapter is necessary to solve the case when the user manually writes a date in a date picker via input.
 * This process is different in some locales, as en-GF uses mm/dd/yyy and es-ES uses dd/mm/yy
 */

@Injectable()
export class CustomNgxDatePickerAdapter extends NgxMatDateAdapter<Date> {
  constructor(@Inject(LOCALE_ID) locale: string, private _localization: LocalizationHelperService) {
    super();
    this.setLocale(this._localization.currentLocale);
  }

  getHour(date: Date): number {
    return date.getHours();
  }
  getMinute(date: Date): number {
    return date.getMinutes();
  }
  getSecond(date: Date): number {
    return date.getSeconds();
  }
  setHour(date: Date, value: number): void {
    date.setHours(value);
  }
  setMinute(date: Date, value: number): void {
    date.setMinutes(value);
  }
  setSecond(date: Date, value: number): void {
    date.setSeconds(value);
  }
  getYear(date: Date): number {
    return date.getFullYear();
  }
  getMonth(date: Date): number {
    return date.getMonth();
  }
  getDate(date: Date): number {
    return date.getDate();
  }
  getDayOfWeek(date: Date): number {
    return date.getDay();
  }
  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const date = new Date();
    const monthNames = [];

    for (let i = 0; i < 12; i++) {
      date.setMonth(i);
      const month = date.toLocaleString(this._localization.currentLocale, { month: style });
      monthNames.push(month);
    }

    return monthNames;
  }

  getDateNames(): string[] {
    const date = new Date();
    const days = [];
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

    for (let i = 1; i <= lastDayOfMonth; i++) {
      days.push(`${i}`);
    }

    return days;
  }
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const weekdays = [];
    for (let i = 1; i <= 7; i++) {
      const date = new Date(2022, 0, i);
      const options = { weekday: style };
      weekdays.push(date.toLocaleDateString(this._localization.currentLocale, options));
    }
    return weekdays;
  }
  getYearName(date: Date): string {
    return date.getFullYear().toString();
  }
  getFirstDayOfWeek(): number {
    return getLocaleFirstDayOfWeek(this._localization.currentLocale);
  }
  getNumDaysInMonth(date: Date): number {
    const year = date.getFullYear();
    const month = date.getMonth();

    return new Date(year, month + 1, 0).getDate();
  }
  clone(date: Date): Date {
    date.setSeconds(0, 0);
    return new Date(date.getTime());
  }
  createDate(year: number, month: number, date: number): Date {
    const newDate = new Date(year, month, date);
    if (
      newDate.getFullYear() === year &&
      newDate.getMonth() === month &&
      newDate.getDate() === date
    ) {
      return newDate;
    }
    return null;
  }
  today(): Date {
    return new Date();
  }
  addCalendarYears(date: Date, years: number): Date {
    const newDate = new Date(date.valueOf());
    newDate.setFullYear(newDate.getFullYear() + years);
    return newDate;
  }
  addCalendarMonths(date: Date, months: number): Date {
    const newDate = new Date(date.valueOf());
    const newMonth = newDate.getMonth() + months;
    newDate.setMonth(newMonth);
    return newDate;
  }
  addCalendarDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  toIso8601(date: Date): string {
    return date.toISOString();
  }
  isDateInstance(obj: any): boolean {
    return obj instanceof Date;
  }
  isValid(date: Date): boolean {
    return !isNaN(date.getTime());
  }
  invalid(): Date {
    return new Date(NaN);
  }

  parse(value: string | number): Date | null {
    const result = this._localization.parseInputDate(value as string, true);

    if ((result as any as String) === '') {
      return result;
    }

    result?.setSeconds(0, 0);
    return result;
  }

  format(date: Date, displayFormat: Object): string {
    const result = this._localization.formatInputDate(date, true);
    return result;
  }
}
