import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { CanSaveHistoricalForm } from '../../features/shared/historical/can-save-historical-form';
import { HistoricalFormSettings } from '../../features/shared/historical/historical-form-settings';
import { SmartMeterSignalVersionDto } from '../models/smart-meter-signal-version-dto';

const COMPONENT_SELECTOR = 'wlm-smart-meter-historical-form';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './smart-meter-historical-form.component.html',
  styleUrls: ['./smart-meter-historical-form.component.scss'],
})
export class SmartMeterHistoricalFormComponent {
  private _formModel: SmartMeterSignalVersionDto;
  get formModel(): SmartMeterSignalVersionDto {
    return this._formModel;
  }
  @Input() set formModel(value: SmartMeterSignalVersionDto) {
    this._formModel = value;
    if (value) {
      this.customerClassTypeCtrl.setValue(value.customerClassTypeName);
    }
  }
  @Input() settings: HistoricalFormSettings;

  @Output() formChanges = new EventEmitter<SmartMeterSignalVersionDto>();
  @Output() canSave = new EventEmitter<CanSaveHistoricalForm>();
  @Output() canReset = new EventEmitter<boolean>();

  readonly customerClassTypeCtrl = new FormControl({
    value: null,
    disabled: true,
  });

  readonly T_SCOPE = `${AppModules.SmartMeters}.${COMPONENT_SELECTOR}`;

  onFormChange(data: SmartMeterSignalVersionDto): void {
    this.formChanges.emit(data);
  }

  onCanSave(data: CanSaveHistoricalForm): void {
    this.canSave.emit(data);
  }

  onCanReset(canReset: boolean): void {
    this.canReset.emit(canReset);
  }
}
