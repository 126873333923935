import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { IdentityUserCmd } from '@common-modules/shared/model/roles/identity-users-cmd.dto';
import { UsersIdentityMappingDto } from '@common-modules/shared/model/roles/users-identity-mapping.dto';
import { UsersIdentityService } from '@common-modules/shared/roles/identity.service';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';

const COMPONENT_SELECTOR = 'wlm-identity-users-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './identity-users-popup.component.html',
  styleUrls: ['./identity-users-popup.component.scss'],
})
export class IdentityUsersPopupComponent implements OnInit {
  @ViewChild(GenericGridComponent) public identityUsersGrid: GenericGridComponent;
  isSaving = false;
  userGridSettingsName = 'IdentityUsersPopup';
  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;

  private _associatedUserCode: string;
  public get associatedUserCode(): string {
    return this._associatedUserCode;
  }
  public set associatedUserCode(v: string) {
    this._associatedUserCode = v;
    this.loadUsers();
  }

  gridFiltersForBinding: DataBindingFilters;
  additionalFilters: Map<string, string>;
  userGridSetting: GridSetting;
  selectedUsers: UsersIdentityMappingDto[];

  constructor(
    _gridSettingsService: GridSettingsService,
    private _authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<IdentityUsersPopupComponent>,
    @Inject(MAT_DIALOG_DATA) { userCode }: UsersIdentityMappingDto,
    private _usersIdentityService: UsersIdentityService,
    private _dialogService: DialogService
  ) {
    _gridSettingsService.getGridSettingsByName(this.userGridSettingsName).subscribe({
      next: (gridSettings) => {
        this.userGridSetting = gridSettings;
      },
    });
    this.associatedUserCode = userCode;
  }

  ngOnInit(): void {
    const additionalFilters = new Map<string, string>();
    additionalFilters.set('userCode', this.associatedUserCode);
    additionalFilters.set('assigned', 'false');
    this.additionalFilters = additionalFilters;
  }

  loadUsers() {
    // this._userService.filterByGroup(this.groupId);
    const dataBinding = new DataBindingFilters();
    this.gridFiltersForBinding = dataBinding;
  }

  saveIdentityUsers() {
    this.isSaving = true;
    this.identityUsersGrid.grid.loading = true;

    const identityUsersCmd = new IdentityUserCmd(
      this.selectedUsers.map((x) => x.userId),
      this.associatedUserCode,
      this._authenticationService.userCode
    );

    this._usersIdentityService.insertIdentityUsers(identityUsersCmd).subscribe({
      next: (response) => {
        if (response.errors.length === 0) {
          if (this.selectedUsers.length > 1) {
            this._dialogService.showTranslatedMessageInSnackBar(
              new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.assign-users-success` })
            );
          } else {
            this._dialogService.showTranslatedMessageInSnackBar(
              new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.assign-user-success` })
            );
          }

          this.dialogRef.close(true);
        } else {
          this._dialogService.showTranslatedMessage(
            new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.assign-fail`, icon: 'error' })
          );
        }
        this.identityUsersGrid.grid.loading = false;
        this.isSaving = false;
      },
      error: (error) => {
        this.isSaving = false;
      },
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
