import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlarmClassTypes } from '../../dependencies/alarms/alarm-class-types.enum';
import { EnvelopeMode } from '../../dependencies/alarms/envelope-modes.enum';

import { LNUMode } from '@water-loss//features/shared/model/ne/lnu-mode.enum';
import { EnvelopePersistencyFactor } from '../../dependencies/alarms/envelope-persistency-factor.enum';
import { EnvelopeRelativeMode } from '../../dependencies/alarms/envelope-relative-modes.enum';
import { CampaignStatusStyle } from '../../dependencies/alc/campaign-status-style';
import { CampaignStatus } from '../../dependencies/alc/campaign-status.enum';
import { TransmissionCategoryKeys } from '../../dependencies/ne/transmission-category-keys';
import { WaterBalanceTypesEnum } from '../../dependencies/water-balance/water-balance-types';
import { FieldDataType } from '../../dynamic-forms/models/field-data-type';
import { AppModules } from '../app-modules.enum';
import { LocalizationHelperService } from '../localization/localization-helper.service';
import { TimeAggregationEnum } from '../model/algorithm/time-aggregation.enum';
import { ImageElement } from '../model/grid/image-element';
import { CurrencyTypesEnum } from '../model/shared/currency-types.enum';
import { DateSelectModeEnum } from '../model/shared/date-select-mode.enum';
import { DayTypesEnum } from '../model/shared/day-types.enum';
import { ElementTargetsEnum } from '../model/shared/element-targets.enum';
import { SeasonsEnum } from '../model/shared/seasons.enum';
import { SelectOption } from '../model/shared/select-option';

/**
 * Service that encapsulates constants that need translations.
 * If this service grows too much, it could be refactored into separated constant services.
 */

// Helper enum. Must not be translated.
enum ConstantsTypes {
  GENERIC = 'generic',
  ACTIVITY = 'activity',
  ALARM = 'alarm',
  ELIGIBILITY = 'eligibility',
  PRIORITISATION = 'prioritisation',
  DN = 'distribution-network',
  LEAKS = 'leaks',
  TELEMETRYPOINTS = 'telemetry-points',
  USERS = 'users',
  NOTIFICATION = 'notification',
  LEAKAGEREPORTING = 'leakage-reporting',
  PRESSUREMANAGEMENT = 'pressure-management',
  WATERBALANCE = 'water-balance',
  CAMPAIGNS = 'alc-campaigns',
  INTEGRATION = 'integration',
}

@Injectable()
export class SharedConstantsService {
  private readonly T_SCOPE = `${AppModules.WlmShared}.constants`;

  constructor(private localization: LocalizationHelperService) {}

  /* GENERIC */

  getBooleanColumnDefaultMapping = (): Observable<Map<any, string>> =>
    this.localizeSwitch(`${ConstantsTypes.GENERIC}.boolean-column-default-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
        // ['null', ts.null],
      ]);
    });

  getGenericActiveInactiveMapping = (): Observable<Map<any, string>> =>
    this.localizeSwitch(`${ConstantsTypes.GENERIC}.active-inactive-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });

  getSelectorColumnDefaultMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(
      `${ConstantsTypes.GENERIC}.selector-column-default-mapping`,
      (ts) => {
        return new Map<any, string>([
          ['contains', ts.contains],
          ['isnull', ts.isnull],
          ['isnotnull', ts.isnotnull],
        ]);
      }
    );
  }

  getFilterOperatorsMapping(): Observable<Map<string, string>> {
    return this.localizeSwitch(`${ConstantsTypes.GENERIC}.filter-operators-mapping`, (ts) => {
      return new Map<any, string>([
        ['eq', ts.eq],
        ['neq', ts.neq],
        ['gte', ts.gte],
        ['gt', ts.gt],
        ['lte', ts.lte],
        ['lt', ts.lt],
        ['isnull', ts.isnull],
        ['isnotnull', ts.isnotnull],
      ]);
    });
  }

  getFilterOperatorsDateMapping(): Observable<Map<string, string>> {
    return this.localizeSwitch(`${ConstantsTypes.GENERIC}.filter-operators-date-mapping`, (ts) => {
      return new Map<any, string>([
        ['eq', ts.eq],
        ['neq', ts.neq],
        ['gte', ts.gte],
        ['gt', ts.gt],
        ['lte', ts.lte],
        ['lt', ts.lt],
        ['isnull', ts.isnull],
        ['isnotnull', ts.isnotnull],
      ]);
    });
  }

  getFilterOperatorsTextMapping(): Observable<Map<string, string>> {
    return this.localizeSwitch(`${ConstantsTypes.GENERIC}.filter-operators-text-mapping`, (ts) => {
      return new Map<any, string>([
        ['eq', ts.eq],
        ['neq', ts.neq],
        ['contains', ts.contains],
        ['doesnotcontain', ts.doesnotcontain],
        ['startswith', ts.startswith],
        ['endswith', ts.endswith],
        ['isempty', ts.isempty],
        ['isnotempty', ts.isnotempty],
        ['isnull', ts.isnull],
        ['isnotnull', ts.isnotnull],
      ]);
    });
  }

  getDayTypesMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.GENERIC}.day-types-mapping`, (ts) => {
      return new Map<number, string>([
        [DayTypesEnum.WorkingDay, ts['working-day']],
        [DayTypesEnum.Weekend, ts.weekend],
      ]);
    });
  }

  getSeasonsMapping(): Observable<Map<string, string>> {
    return this.localizeSwitch(`${ConstantsTypes.GENERIC}.seasons-mapping`, (ts) => {
      return new Map<string, string>([
        [SeasonsEnum.Winter, ts.winter],
        [SeasonsEnum.Spring, ts.spring],
        [SeasonsEnum.Summer, ts.summer],
        [SeasonsEnum.Autumn, ts.autumn],
      ]);
    });
  }

  getDateSelectModeMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.GENERIC}.date-select-mode-mapping`, (ts) => {
      return new Map<number, string>([
        [DateSelectModeEnum.Fixed, ts.fixed],
        [DateSelectModeEnum.Rolling, ts.rolling],
      ]);
    });
  }

  getElementTargetsMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.GENERIC}.element-targets-mapping`, (ts) => {
      return new Map<number, string>([
        [ElementTargetsEnum.HierarchyElements, ts['hierarchy-elements']],
        [ElementTargetsEnum.NetworkElements, ts['network-elements']],
      ]);
    });
  }

  getElementTargetsArray(): Observable<SelectOption<number>[]> {
    return this.mapToArrayObservable(this.getElementTargetsMapping());
  }

  getTimeAggregationMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.GENERIC}.time-aggregation-mapping`, (ts) => {
      return new Map<number, string>([
        [TimeAggregationEnum.Base, ts.base],
        [TimeAggregationEnum.Daily, ts.daily],
        [TimeAggregationEnum.Weekly, ts.weekly],
        [TimeAggregationEnum.Monthly, ts.monthly],
        [TimeAggregationEnum.Yearly, ts.yearly],
      ]);
    });
  }

  getLNUModesMapping = (): Observable<Map<any, string>> => {
    return this.localizeSwitch(`${ConstantsTypes.GENERIC}.lnu-mode-mapping`, (ts) => {
      let map = new Map<any, string>([
        [LNUMode.ManualAllowancePerCustomer, ts['manual-allowance']],
        [LNUMode.PercentageOfBilling, ts['percentage-billing']],
        [LNUMode.SmartMeter, ts['smart-meter']],
        [LNUMode.PercentageOfMNF, ts['percentage-mnf']],
      ]);
      map = this.addStringifiedValues(map);
      return map;
    });
  };

  getTimeAggregationArray(): Observable<SelectOption<number>[]> {
    return this.mapToArrayObservable(this.getTimeAggregationMapping());
  }

  getCurrencyTypeMapping(): Observable<Map<number, string>> {
    return this.localization.get('common.currency-types').pipe(
      map((ts) => {
        return new Map<number, string>([
          [CurrencyTypesEnum.NA, ts.na],
          [CurrencyTypesEnum.Cost, ts.cost],
          [CurrencyTypesEnum.Price, ts.price],
        ]);
      })
    );
  }

  getCurrencyTypes(): Observable<SelectOption<number>[]> {
    return this.mapToArrayObservable(this.getCurrencyTypeMapping());
  }

  getIsSuccessMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.GENERIC}.is-success-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });
  }

  /* ACTIVITY */

  getSapStatusMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ACTIVITY}.sap-status-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.open],
        [false, ts.closed],
      ]);
    });
  }

  getActivityCloseType(): Observable<Map<string, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ACTIVITY}.close-types-mapping`, (ts) => {
      return new Map<any, string>([
        ['ConfirmedLeak', ts['confirmed-leak']],
        ['DryHole', ts['dry-hole']],
        ['Duplicated', ts.duplicated],
      ]);
    });
  }

  getDryHoleTypes(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ACTIVITY}.dry-hole-types-mapping`, (ts) => {
      return new Map<any, string>([
        [1, ts['leak-repaired-over-3m-away']],
        [2, ts['confirmed-dry-hole']],
        [3, ts['reported-leak-present-within-3m']],
        [4, ts['reported-gang-error']],
      ]);
    });
  }

  getIsProactiveMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ACTIVITY}.is-proactive-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });
  }

  getIsRepairActivityMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ACTIVITY}.is-repair-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });
  }

  getIsDetectionActivityMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ACTIVITY}.is-detection-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });
  }

  /* ALARM */

  getSeveritiesMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ALARM}.severities-mapping`, (ts) => {
      return new Map<number, string>([
        [1, ts.low],
        [2, ts.medium],
        [3, ts.high],
        [4, ts.critical],
      ]);
    });
  }

  getSeveritiesArray(): Observable<SelectOption<number>[]> {
    return this.mapToArrayObservable(this.getSeveritiesMapping());
  }

  getAlarmClassTypesMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ALARM}.class-types-mapping`, (ts) => {
      return new Map<number | null, string>([
        [AlarmClassTypes.MNF, ts.mnf],
        [AlarmClassTypes.DDI, ts.ddi],
        [AlarmClassTypes.DL, ts.dl],
        [AlarmClassTypes.Flow, ts.flow],
        [AlarmClassTypes.Pressure, ts.pressure],
        [AlarmClassTypes.Level, ts.level],
        [AlarmClassTypes.Others, ts.others],
      ]);
    });
  }

  getAlarmCategoriesMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ALARM}.alarm-categories-mapping`, (ts) => {
      return new Map<number, string>([
        [4, ts.complex],
        [2, ts['algorithm-simple']],
        [1, ts['signal-simple']],
        [3, ts.profile],
      ]);
    });
  }

  getProfileTypesMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ALARM}.alarm-profile-types-mapping`, (ts) => {
      return new Map<number, string>([
        [2, ts.algorithm],
        [1, ts.telemetry],
        [0, ts.undefined],
      ]);
    });
  }

  getEnvelopeModesMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ALARM}.envelope-modes-mapping`, (ts) => {
      return new Map<number, string>([
        [EnvelopeMode.Adaptative, ts.adaptative],
        [EnvelopeMode.FlatAbsolute, ts['flat-absolute']],
        [EnvelopeMode.FlatRelative, ts['flat-relative']],
      ]);
    });
  }

  getEnvelopeModesArray(): Observable<SelectOption<number>[]> {
    return this.mapToArrayObservable(this.getEnvelopeModesMapping());
  }

  getEnvelopePersistencyFactorMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(
      `${ConstantsTypes.ALARM}.envelope-persistency-factor-mapping`,
      (ts) => {
        return new Map<number, string>([
          [EnvelopePersistencyFactor.Minutes, ts.minutes],
          [EnvelopePersistencyFactor.Hours, ts.hours],
          [EnvelopePersistencyFactor.Days, ts.days],
        ]);
      }
    );
  }

  getEnvelopePersistencyFactorArray(): Observable<SelectOption<number>[]> {
    return this.mapToArrayObservable(this.getEnvelopePersistencyFactorMapping());
  }

  getEnvelopeRelativeModeMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ALARM}.envelope-relative-mode-mapping`, (ts) => {
      return new Map<number, string>([
        [EnvelopeRelativeMode.Absolute_Maximum, ts['absolute-maximum']],
        [EnvelopeRelativeMode.Absolute_Minimum, ts['absolute-minimum']],
        [EnvelopeRelativeMode.Average, ts.average],
        [EnvelopeRelativeMode.Maximum, ts.maximum],
        [EnvelopeRelativeMode.Minimum, ts.minimum],
      ]);
    });
  }

  getEnvelopeRelativeModeArray(): Observable<SelectOption<number>[]> {
    return this.mapToArrayObservable(this.getEnvelopeRelativeModeMapping());
  }

  /* ELIGIBILITY */

  getEligibleUserMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ELIGIBILITY}.eligible-user-mapping`, (ts) => {
      return new Map<any, string>([
        ['null', ts['n-a']],
        [true, ts.eligible],
        [false, ts.ineligible],
      ]);
    });
  }

  getEligibilityMessages(): Observable<Map<string, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ELIGIBILITY}.eligibility-messages`, (ts) => {
      return new Map<string, string>([
        ['PlannedMessage', ts['planned-message']],
        ['NAAlertMessage', ts['na-alert-message']],
        ['StillPlannedMessage', ts['still-planned-message']],
      ]);
    });
  }

  /* PRIORITISATION */

  getReasonForDropMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.PRIORITISATION}.reason-for-drop-mapping`, (ts) => {
      return new Map<number, string>([
        [1, ts['meter-in-fault']],
        [2, ts['reactive-leak-repaired']],
        [3, ts['proactive-leak-repaired']],
        [4, ts['lcc-identified']],
        [5, ts['breach-resolved']],
        [6, ts['configuration-corrected']],
        [7, ts['meter-repaired']],
        [8, ts.ppm],
        [9, ts['seasonal-fluctuation']],
        [10, ts.unknown],
        [11, ts['to-be-confirmed']],
      ]);
    });
  }

  getFaultyMeterMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.PRIORITISATION}.faulty-meter-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });
  }

  /* DN */

  getTsLeakageAlarmStatusMapping(): Observable<Map<any, ImageElement>> {
    return this.localizeSwitch(`${ConstantsTypes.DN}.leakage-alarm-status-mapping`, (ts) => {
      const imageMap = new Map<any, ImageElement>([
        [1, new ImageElement('warning_amber', '#ffcc00', ts.warning)],
        [2, new ImageElement('warning_amber', 'red', ts.alarm)],
        [0, new ImageElement('', 'none', ts['no-alarm'], true)],
      ]);

      return imageMap;
    });
  }

  getPressureMonitoringAlarmStatusMapping(): Observable<Map<any, ImageElement>> {
    return this.localizeSwitch(
      `${ConstantsTypes.PRESSUREMANAGEMENT}.alarm-status-mapping`,
      (ts) => {
        const imageMap = new Map<any, ImageElement>([
          //TODO: Change values if the alarm columns definitions change
          [true, new ImageElement('warning_amber', 'red', ts.alarm)],
          [false, new ImageElement('', 'none', ts['no-alarm'], true)],
        ]);

        return imageMap;
      }
    );
  }

  getTsOtherActiveAlarmsMapping(): Observable<Map<any, ImageElement>> {
    return this.localizeSwitch(`${ConstantsTypes.DN}.leakage-alarm-status-mapping`, (ts) => {
      const imageMap = new Map<any, ImageElement>([
        [true, new ImageElement('warning_amber', 'red', ts.alarm)],
        [false, new ImageElement('', 'none', ts['no-alarm'])],
      ]);

      return imageMap;
    });
  }

  getTsDLCEMapping(): Observable<Map<any, ImageElement>> {
    return this.localizeSwitch(`${ConstantsTypes.DN}.dlce-mapping`, (ts) => {
      const imageMap = new Map<any, ImageElement>([
        [1, new ImageElement('', 'none', ts['no-alarm'], true)],
        [2, new ImageElement('warning_amber', '#ffcc00', ts.warning)],
        [0, new ImageElement('clear', 'red', ts.alarm)],
      ]);

      return imageMap;
    });
  }

  getTsZoneMergingStatusMapping(): Observable<Map<any, ImageElement>> {
    return this.localizeSwitch(`${ConstantsTypes.DN}.zone-merging-status-mapping`, (ts) => {
      const imageMap = new Map<any, ImageElement>([
        [0, new ImageElement('', 'none', ts.normal)],
        [2, new ImageElement('merged-zone-child', 'none', ts.merged, null, true)],
        [1, new ImageElement('merged-zone-parent', 'none', ts.merging, null, true)],
      ]);

      return imageMap;
    });
  }

  getIsOperableMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.DN}.is-operable-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });
  }

  getFaultyMeterStatusMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.DN}.faulty-meter-status-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });
  }

  getLargeUsersFaultsMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.DN}.large-user-faults-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });
  }

  /* LEAKS */

  getIsDryHoleMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.LEAKS}.is-dry-hole-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });
  }

  /* TELEMETRY POINTS */

  getIsPointOfInterestMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(
      `${ConstantsTypes.TELEMETRYPOINTS}.is-point-of-interest-mapping`,
      (ts) => {
        return new Map<any, string>([
          [true, ts.true],
          [false, ts.false],
        ]);
      }
    );
  }

  getPointCategoryMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.TELEMETRYPOINTS}.category-mapping`, (ts) => {
      return new Map<any, string>([
        ['Boundary', ts.boundary],
        ['Inlet/Outlet', ts['inlet-outlet']],
        ['Large User Consumption', ts['large-user-consumption']],
        ['Lars&Sworps', ts['lars-sworps']],
        ['Level', ts.level],
        ['Pressure', ts.pressure],
        ['Smart Meters', ts['smart-meters']],
      ]);
    });
  }

  getTransmissionCategoriesMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(
      `${ConstantsTypes.TELEMETRYPOINTS}.transmission-category-keys-mapping`,
      (ts) => {
        return new Map<any, string>([
          [TransmissionCategoryKeys.Boundaries, ts.boundaries],
          [TransmissionCategoryKeys.NoBoundaries, ts['no-boundaries']],
        ]);
      }
    );
  }

  /* USERS */

  getIsActiveMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.USERS}.is-active-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });
  }

  getIsSuperUserMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.USERS}.is-super-user-mapping`, (ts) => {
      return new Map<any, string>([
        [true, ts.true],
        [false, ts.false],
      ]);
    });
  }

  /* NOTIFICATIONS */

  getNotificationTypesMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.NOTIFICATION}.types`, (ts) => {
      return new Map<any, string>([
        [1, ts.manual],
        [2, ts.automatic],
      ]);
    });
  }

  getNotificationStatusMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.NOTIFICATION}.status`, (ts) => {
      return new Map<any, string>([
        [1, ts.open],
        [2, ts.hold],
        [3, ts.close],
      ]);
    });
  }

  getParkingReasonTypesMapping(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.ALARM}.park-reasons`, (ts) => {
      return new Map<any, string>([
        ['1', ts.manual],
        ['2', ts['missing-data']],
        ['3', ts['invalid-data']],
        ['4', ts['merged']],
      ]);
    });
  }

  getLeakageReportingFlaggingReasons(): Observable<Map<any, string>> {
    return this.localizeSwitch(`${ConstantsTypes.LEAKAGEREPORTING}.flagging-reasons`, (ts) => {
      return new Map<any, string>([
        [1, ts['1']],
        [2, ts['2']],
        [3, ts['3']],
      ]);
    });
  }

  /** WATER BALANCE */

  getWaterBalanceTypesMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.WATERBALANCE}.water-balance-types`, (ts) => {
      return new Map<number, string>([
        [WaterBalanceTypesEnum.TopDown, ts['top-down']],
        [WaterBalanceTypesEnum.BottomUp, ts['bottom-up']],
      ]);
    });
  }

  getWaterBalanceTypes(): Observable<SelectOption<number>[]> {
    return this.mapToArrayObservable(this.getWaterBalanceTypesMapping());
  }

  getWaterBalanceTypesShortMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.WATERBALANCE}.water-balance-types-short`, (ts) => {
      return new Map<number, string>([
        [WaterBalanceTypesEnum.TopDown, ts['top-down']],
        [WaterBalanceTypesEnum.BottomUp, ts['bottom-up']],
      ]);
    });
  }

  getWaterBalanceTypesShort(): Observable<SelectOption<number>[]> {
    return this.mapToArrayObservable(this.getWaterBalanceTypesShortMapping());
  }

  /** ALC CAMPAIGNS */
  getCampaignStatusMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.CAMPAIGNS}.status-mapping`, (ts) => {
      return new Map<number, string>([
        [1, ts['in-design']],
        [2, ts.planned],
        [3, ts.open],
        [4, ts.closed],
        [5, ts.blocked],
      ]);
    });
  }

  getCampaignStatusStyleMapping(): Map<any, string> {
    return new Map<any, string>([
      [CampaignStatus.Blocked, CampaignStatusStyle.Blocked],
      [CampaignStatus.Closed, CampaignStatusStyle.Closed],
      [CampaignStatus.InDesign, CampaignStatusStyle.InDesign],
      [CampaignStatus.Open, CampaignStatusStyle.Open],
      [CampaignStatus.Planned, CampaignStatusStyle.Planned],
    ]);
  }

  /** MEASUREDVALUES */
  getMVValidityMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(`${ConstantsTypes.TELEMETRYPOINTS}.validity-mapping`, (ts) => {
      return new Map<number, string>([
        [0, ts.valid],
        [1, ts.invalid],
        [2, ts.missing],
        [255, ts.unknown],
      ]);
    });
  }
  getMVEstimationFunctionsMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(
      `${ConstantsTypes.TELEMETRYPOINTS}.estimation-function-mapping`,
      (ts) => {
        return new Map<number, string>([
          [1, ts['user-defined']],
          [2, ts['most-recent']],
          [3, ts['previous-day']],
          [4, ts['previous-week']],
          [5, ts['average-past-values']],
          [9, ts['unable-to-estimate']],
        ]);
      }
    );
  }
  getMVValidationFunctionsMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(
      `${ConstantsTypes.TELEMETRYPOINTS}.validation-function-mapping`,
      (ts) => {
        return new Map<number, string>([
          [1, ts['missing']],
          [2, ts['field-quality']],
          [3, ts['spike']],
          [4, ts['flow-meter-faults']],
          [5, ts['outlier']],
          [9, ts['not-able-to-validate']],
          [10, ts['validation-disabled']],
        ]);
      }
    );
  }

  getEstimatedEditedMapping(): Observable<Map<number, string>> {
    return this.localizeSwitch(
      `${ConstantsTypes.TELEMETRYPOINTS}.estimated-edited-mapping`,
      (ts) => {
        return new Map<number, string>([
          [0, ts.none],
          [1, ts.estimated],
          [2, ts.edited],
          [4, ts.adjusted],
        ]);
      }
    );
  }

  getGlobalLNUModeMapping = (): Observable<Map<string, string>> => {
    return this.localizeSwitch(
      `${ConstantsTypes.GENERIC}.global-lnu-mapping`,
      (ts) =>
        new Map<any, string>([
          ['1', ts.active],
          ['2', ts.inactive],
          [1, ts.active],
          [2, ts.inactive],
        ])
    );
  };

  /* Class Types */
  getCustomerClassCategoriesMapping(): Observable<Map<any, string>> {
    return of(
      new Map<any, string>([
        ['bad43ad3-c791-4cd4-99f7-560613e20619', 'BaseCategory'],
        ['1c0038b3-932b-44e3-bfd8-6dc7be15cb7d', 'Acom'],
        ['3c765ffa-c6ab-4613-aab2-966f31b8ae61', 'Sic'],
      ])
    );
  }

  getCustomerClassSuperTypesMapping(): Observable<Map<any, string>> {
    return of(
      new Map<any, string>([
        [1, 'Other'],
        [2, 'Domestic'],
        [3, 'Comercial'],
        [4, 'Industrial'],
      ])
    );
  }

  getDataTypesMapping(): Observable<Map<any, string>> {
    const dataTypesArray = Object.keys(FieldDataType)
      .filter((key) => !isNaN(Number(FieldDataType[key])))
      .map((key) => ({ label: key, value: FieldDataType[key] }));

    const dataTypesMap = new Map(dataTypesArray.map((item) => [item.value, item.label]));

    return of(dataTypesMap);
  }

  /**
   * Convert a map to an array ob objects, specifying the value and label properties.
   */
  public mapToArray(map: Map<any, any>, valueKey = 'value', labelKey = 'label'): any[] {
    return Array.from(map.keys()).map((key) => ({ [valueKey]: key, [labelKey]: map.get(key) }));
  }

  private addStringifiedValues(map: Map<any, string>): Map<any, string> {
    for (let key of Array.from(map.keys())) {
      map.set(String(key), map.get(key));
    }
    return map;
  }

  /**
   * Same as mapToArray, but receives an observable that resolves a map instead of the map.
   */
  public mapToArrayObservable(
    obs: Observable<Map<any, any>>,
    valueKey = 'value',
    labelKey = 'label'
  ): Observable<any[]> {
    return obs.pipe(map((dataMap) => this.mapToArray(dataMap, valueKey, labelKey)));
  }

  /**
   * Helper method. Receives a key to translations inside the column enums translations,
   * and injects the results into the callback.
   */
  private localizeSwitch(translateKey: string, callback): Observable<any> {
    return this.localization.localizeSwitch(`${this.T_SCOPE}.${translateKey}`, callback);
  }
}
