import { MenuLink } from '@common-modules/dependencies/navigation/menu-link';
import { MapTooltipProperty } from './map-tooltip-property';

export class MapTooltip {
  title: string;
  properties?: MapTooltipProperty[];
  navigations?: MenuLink[];

  constructor(init: MapTooltip) {
    Object.assign(this, init);
  }

  public static getProperties(properties: { [key: string]: string }): MapTooltipProperty[] {
    const result: MapTooltipProperty[] = [];

    if (properties) {
      Object.entries(properties).forEach(([key, value]) => {
        const property = new MapTooltipProperty({ key, label: '', labelKey: '', value });
        result.push(property);
      });
    }

    return result;
  }
}
