import { IFilter } from '@common-modules/shared/filters/component-filters/filter';

export class HeTreeFamilyFilterConfiguration {
  persistencyArea: string;
  hierarchyElementIdFieldName: string;
  hierarchyElementId: string[];
  hierarchyElementFamilyFieldName: string;
  hierarchyElementFamily: string;
  filters: IFilter[];

  constructor(init?: Partial<HeTreeFamilyFilterConfiguration>) {
    Object.assign(this, init);
  }
}
