import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RightPanelService } from '@common-modules/shared/navigation/right-panel.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { DropdownNavigationItem } from '../../dependencies/navigation/dropdown-navigation-item';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from '../../dependencies/navigation/tab-detail-component';
import { AppModules } from '../../shared/app-modules.enum';
import { AuthenticationService } from '../../shared/auth/services/authentication.service';
import { BasePageComponent } from '../../shared/component/base-page.component';
import { GridSetting } from '../../shared/constants/grid.constants';
import { GridSettingsService } from '../../shared/core/grid/grid-settings.service';
import { DialogService } from '../../shared/dialogs/dialogs.service';
import { DataBindingFilters } from '../../shared/filters/component-filters/data-binding-filters';
import { GridBtnsDisable } from '../../shared/grid-buttons/models/grid-btns-disable';
import { WLMDialogResult } from '../../shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from '../../shared/model/dialog/wlm-dialog-setting';
import { GroupCmdDto } from '../../shared/model/roles/group-cmd.dto';
import { GroupDto } from '../../shared/model/roles/group.dto';
import { RolesService } from '../../shared/roles/roles.service';
import { GenericGridComponent } from '../../wlm-grid/generic-grid/generic-grid.component';
// prettier-ignore
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GroupEditionPopupComponent } from '../groups-components/group-edition-popup/group-edition-popup.component';
import { GroupsUsersComponent } from '../groups-components/groups-users/groups-users.component';
import { ProfileDialogsHierarchyGridComponent } from '../groups-components/profile-dialogs-hierarchy-grid/profile-dialogs-hierarchy-grid.component';

const COMPONENT_SELECTOR = 'wlm-groups-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './groups-page.component.html',
  styleUrls: ['./groups-page.component.scss'],
})
export class GroupsPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild(GenericGridComponent) public groupsGrid: GenericGridComponent;
  @ViewChild(ProfileDialogsHierarchyGridComponent)
  public crudsGrid: ProfileDialogsHierarchyGridComponent;

  isSaving = false;
  gridSettings: GridSetting;
  gridFiltersForBinding: DataBindingFilters;
  selectedGroup: GroupDto;
  gridName = 'Groups';

  // Handles the disable status of all the buttons. (Check DN Page for details).
  gridBtnsDisable$ = new ReplaySubject<GridBtnsDisable>(30);

  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;

  private readonly _groupEditionPopupSize = {
    width: '520px',
    height: '300px',
  };

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get navigations(): DropdownNavigationItem[] {
    return [];
  }

  constructor(
    private _gridService: GridSettingsService,
    private _rightPanelService: RightPanelService,
    private _editGroupDialog: MatDialog,
    private _dialogService: DialogService,
    private _rolesService: RolesService,
    private _authService: AuthenticationService
  ) {
    super();
    _gridService.getGridSettingsByName(this.gridName).subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadGrid();
    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      const panelSettings = new TabDetailPanelSettings();
      panelSettings.addComponent(GroupsUsersComponent, ts['group-users']);

      this._rightPanelService.setTabSettings(panelSettings);
    });
  }

  private loadGrid() {
    this.gridFiltersForBinding = new DataBindingFilters();
    this.groupsGrid?.reloadGrid();
  }

  public get persistencyArea(): string {
    return this.pageCrud;
  }
  public get pageCrud(): string {
    return 'GroupPageCrud';
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}
  init(): void {}

  getSelectedGroup(item: GroupDto) {
    this.selectedGroup = item;
    this.sendParameters();
  }

  sendParameters() {
    this.localization.get(`${this.T_SCOPE}.group-title`).subscribe((message) => {
      const parameters = new TabDetailPanelParameters();
      parameters.addParameter(
        TabDetailParameterName.elementName,
        this.selectedGroup ? `${message}: ${this.selectedGroup.groupName}` : null
      );
      parameters.addParameter(TabDetailParameterName.Group, this.selectedGroup);
      this._rightPanelService.setTabParameters(parameters);
    });
  }

  editSelectedGroup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = this._groupEditionPopupSize.width;
    dialogConfig.height = this._groupEditionPopupSize.height;
    dialogConfig.data = {
      groupId: this.selectedGroup.groupId,
      groupName: this.selectedGroup.groupName,
      groupDescription: this.selectedGroup.groupDescription,
    };
    const popup = this._editGroupDialog.open(GroupEditionPopupComponent, dialogConfig);

    popup
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.loadGrid();
        }
      });
  }

  addGroup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = this._groupEditionPopupSize.width;
    dialogConfig.height = this._groupEditionPopupSize.height;
    dialogConfig.data = {
      groupId: null,
      groupName: null,
      groupDescription: null,
    };
    const popup = this._editGroupDialog.open(GroupEditionPopupComponent, dialogConfig);

    popup.afterClosed().subscribe((data) => {
      if (data) {
        this.loadGrid();
      }
    });
  }

  deleteGroup() {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.remove-warning`,
    });
    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.deleteGroupAction();
        }
      });
  }

  private deleteGroupAction() {
    this.groupsGrid.grid.loading = true;
    this.crudsGrid.isLoading = true;

    const groupToDelete = new GroupCmdDto(
      this.selectedGroup.groupId,
      this.selectedGroup.groupName,
      this.selectedGroup.groupDescription,
      this._authService.userCode
    );
    this._rolesService.deleteGroup(groupToDelete).subscribe({
      next: (response) => {
        this.groupsGrid.grid.loading = false;
        this.crudsGrid.isLoading = false;

        if (response.errors.length === 0) {
          // this.selectedGroup = null;
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages.remove-success`,
              params: { groupToDelete: groupToDelete.groupName },
            })
          );
          this.loadGrid();
        }
      },
    });
  }
}
