import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { FilterItemSelectOption } from '@common-modules/common-filters/models/filter-item-select-option';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { SharedConstantsService } from '@common-modules/shared/constants/shared-constants.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../../core/base-select-filter-item/base-select-filter-item.component';

const COMPONENT_SELECTOR = 'wlm-seasons-filter-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './seasons-filter-item.component.html',
  styleUrls: ['./seasons-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => SeasonsFilterItemComponent),
    },
  ],
})
export class SeasonsFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, OnDestroy
{
  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);
  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;
  defaultSelectedIds = [];

  constructor(
    private globalsService: GlobalsService,
    private sharedConstants: SharedConstantsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.data$$.next(this.getData$());
    super.onInit();
  }

  getData$(): Observable<FilterItemSelectOption[]> {
    return forkJoin([
      this.globalsService.getSeasons(),
      this.sharedConstants.getSeasonsMapping(),
    ]).pipe(
      map(([seasons, i18n]) => {
        const results = seasons.map((s) => {
          return new FilterItemSelectOption(s.seasonId, i18n.get(s.seasonName.toLowerCase()));
        });
        return results;
      })
    );
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  isValid(): boolean {
    return this.selectedElements && this.selectedElements.length !== 0;
  }

  getFieldNames(): string[] {
    return [this.fieldName];
  }
}
