import { inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SpinnerService } from '../../wlm-spinner/spinner.service';

@Injectable()
export class GridExportHelperService {
  private _notifyExport$ = new Subject<boolean>();
  readonly notifyExport$ = this._notifyExport$.asObservable();
  private _spinnerService = inject(SpinnerService);
  private setLoading: (loading: boolean) => void;
  private readonly _timeout = 500;

  constructor() {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  wrap(exportFn: () => void): void {
    this.setLoading(true);
    setTimeout(() => {
      this._notifyExport$.next(true);
      setTimeout(() => {
        exportFn();
        this._notifyExport$.next(false);
        this.setLoading(false);
      }, this._timeout);
    });
  }
}
