import { HierarchyFamilyFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/hierarchy-family-fi-settings';
import { NeHeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';

export class HeFamilyLevelFilterConfiguration {
  persistencyArea: string;
  hierarchyElementTypeFieldName: string;
  hierarchyElementFamilyFieldName: string;
  selectedFamilyId: string;
  selectedElementTypes: string[];
  defaultFamilyId: string[];
  defaultElementTypes: string[];
  heDisplayableLevels?: string[];
  heFamilyFilterSettings: HierarchyFamilyFISettings;
  heTypeSettings: NeHeFISettings;
  excludeElementTypes?: string[];
  filters: IFilter[];

  constructor(
    persistencyArea: string,
    hierarchyElementFamilyFieldName: string,
    hierarchyElementTypeFieldName: string,
    selectedFamilyId?: string,
    selectedElementTypes?: string[],
    defaultFamilyId?: string[],
    defaultElementTypes?: string[],
    excludeElementTypes?: string[],
    filters?: IFilter[]
  ) {
    this.persistencyArea = persistencyArea;
    this.hierarchyElementFamilyFieldName = hierarchyElementFamilyFieldName;
    this.hierarchyElementTypeFieldName = hierarchyElementTypeFieldName;
    this.selectedFamilyId = selectedFamilyId;
    this.selectedElementTypes = selectedElementTypes;
    this.defaultFamilyId = defaultFamilyId;
    this.defaultElementTypes = defaultElementTypes;
    this.excludeElementTypes = excludeElementTypes;
    this.heFamilyFilterSettings = new HierarchyFamilyFISettings({
      fieldName: hierarchyElementFamilyFieldName,
      selectedId: selectedFamilyId,
    });
    this.heTypeSettings = new NeHeFISettings({
      fieldName: hierarchyElementTypeFieldName,
      selectedIds: selectedElementTypes,
      isNEFilter: false,
    });
    this.filters = filters;
  }
}
