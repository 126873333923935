import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  DataStateChangeEvent,
  GridComponent,
  GridDataResult,
  PagerInputComponent,
} from '@progress/kendo-angular-grid';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';
import { DataResult, process } from '@progress/kendo-data-query';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { GridSetting } from '../../shared/constants/grid.constants';
import { GridHelperService } from '../../shared/helpers/grid-helper.service';

import { BaseGridComponent } from '../base-grid/base-grid.component';
import { GridPersistedElements } from '../generic-grid/generic-grid-constants';
import { GridEditionService } from '../services/grid-edition.service';
import { GridExportHelperService } from '../services/grid-export-helper.service';

const COMPONENT_SELECTOR = 'wlm-local-grid';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './local-grid.component.html',
  styleUrls: ['./local-grid.component.scss'],
  providers: [
    {
      // Provide the current component element as Popup container.
      provide: POPUP_CONTAINER,
      useExisting: ElementRef,
    },
    // Create a new Popup Service that uses the provided container.
    PopupService,
    GridExportHelperService,
  ],
})
export class LocalGridComponent extends BaseGridComponent implements OnInit {
  @ViewChild(GridComponent) localGrid: GridComponent;
  @ViewChild('gridContainer') set onGridContainerElement(gridContainerElement: ElementRef) {
    this._gridHelperService.attachGridHeaderButtons(gridContainerElement);
  }
  private _gridSettings: GridSetting;

  private _resetSelectionHandler$: BehaviorSubject<boolean>;
  public get resetSelectionHandler$(): BehaviorSubject<boolean> {
    return this._resetSelectionHandler$;
  }
  @Input() public set resetSelectionHandler$(v: BehaviorSubject<boolean>) {
    this._resetSelectionHandler$ = v;

    // Handle multiple subscriptions if the input is fired multiple times and the component is not deleted.
    if (this._resetSelectionHandlerSubs && this._resetSelectionHandlerSubs.closed) {
      this._resetSelectionHandlerSubs.unsubscribe();
    }
    this._resetSelectionHandlerSubs = this.resetSelectionHandler$
      ?.pipe(untilDestroyed(this))
      .subscribe((reset) => {
        if (reset) {
          this.onSelectedItemsChanged([]);
        }
      });
  }

  private _resetSelectionHandlerSubs: Subscription;

  private _removeSelection$: Observable<void>;
  public get removeSelection$(): Observable<void> {
    return this._removeSelection$;
  }
  @Input() public set removeSelection$(value: Observable<void>) {
    this._removeSelection$ = value;
    this.removeSelection$.pipe(untilDestroyed(this)).subscribe(() => {
      this.clearSelectAllSelection();
    });
  }

  private _removeSelectionPersisted$: Observable<void>;
  public get removeSelectionPersisted$(): Observable<void> {
    return this._removeSelectionPersisted$;
  }
  @Input() public set removeSelectionPersisted$(value: Observable<void>) {
    this._removeSelectionPersisted$ = value;
    this.removeSelectionPersisted$.pipe(untilDestroyed(this)).subscribe(() => {
      this.clearSelectionPersisted();
    });
  }

  public get gridSettings(): GridSetting {
    return this._gridSettings;
  }
  @Input() public set gridSettings(v: GridSetting) {
    if (v) {
      this._gridSettings = v;

      this.initColumns();
      this.persistGridSettings(v);
    }
  }

  private _gridData: any[];
  public get gridData(): any[] {
    return this._gridData;
  }
  @Input() public set gridData(v: any[]) {
    this._gridData = v;
    if (v) {
      // this.initializeState();
      this.getGridDataResult(v);
    }

    this.clearRowSelected();
  }

  private _undoEditedValues$: BehaviorSubject<boolean>;
  public get undoEditedValues$(): BehaviorSubject<boolean> {
    return this._undoEditedValues$;
  }
  @Input() public set undoEditedValues$(value: BehaviorSubject<boolean>) {
    this._undoEditedValues$ = value;
    this.undoEditedValues$.pipe(untilDestroyed(this)).subscribe(() => {
      this.undoEditedValues();
    });
  }

  private _clearPendingEdition$: BehaviorSubject<boolean>;
  public get clearPendingEdition$(): BehaviorSubject<boolean> {
    return this._clearPendingEdition$;
  }
  @Input() public set clearPendingEdition$(value: BehaviorSubject<boolean>) {
    this._clearPendingEdition$ = value;
    this.clearPendingEdition$.pipe(untilDestroyed(this)).subscribe(() => {
      this.editionService.removeAllChanges();
    });
  }

  @ViewChild(PagerInputComponent) set queryPagerInputComponent(value: PagerInputComponent) {
    if (value) {
      value.numericInput.format = {
        useGrouping: false,
      };
    }
  }

  public allData = (): Observable<GridDataResult> => {
    const data = process(this.gridData ?? [], {
      sort: this.state.sort,
      filter: this.state.filter,
    }).data;

    const result: GridDataResult = {
      data,
      total: data.length,
    };

    return of(result);
  };

  private _gridDataResult: GridDataResult;
  public get gridDataResult(): GridDataResult {
    return this._gridDataResult;
  }
  public set gridDataResult(v: GridDataResult) {
    this._gridDataResult = v;
  }

  editionService: GridEditionService;
  defaultDigitsInfo: string;
  currentLocale: string;

  constructor(
    injector: Injector,
    gridEditionService: GridEditionService,
    private _gridHelperService: GridHelperService
  ) {
    super(injector, gridEditionService);
    this.editionService = gridEditionService;
  }

  ngOnInit(): void {
    const decimalPositions = this._settingsService.maxDecimalPositions;
    this.defaultDigitsInfo = `.${decimalPositions}-${decimalPositions}`;
    this.currentLocale = this._dateFormatsService.currentLocale;
  }

  initColumns() {
    this.setGridSorting();
    this.setGridFilters();
    // this.setInitialPagesize();
    this.setGridPagesize();
    this.columns = [...this.gridSettings?.gridColumnSettings];
    this.fitColumns();
    this.initializeState();
  }

  reloadGrid() {
    this.clearRowSelected();
    this.state.take = this.gridSettings.pageSize;
    this.getGridDataResult(this.gridData);
  }

  resetSelection() {
    this.selectedItems = [];
    this.selectedRows = [];
    this.currentSelection = [];
    this.persistSelectedRows();
    this.setSelectedRows();
  }

  dataStateChange(state: DataStateChangeEvent) {
    if (state) {
      this.state = state;
      this.grid.pageSize = state.take;
    }
    // this.checkPagesizeChange(state?.take);

    if (this.gridData?.length > 0) {
      let newDataPage = this.objectHelperService.clone(process(this.gridData, this.state));
      newDataPage = this.checkEditedValues(newDataPage);
      this.gridDataResult = newDataPage;
    }

    if (this.gridSettings.showSelectAllColumn) {
      this.calculateLocalSelectAllState();
    }
  }

  private checkEditedValues(data: DataResult): DataResult {
    if (data.total > 0 && this.editionService) {
      data.data.forEach((row) => {
        const rowKey = row[this.gridSettings.selectByFieldName];
        const rowEditions = this.editionService.getRowEditedValues(rowKey);
        if (rowEditions) {
          rowEditions.forEach((value, key) => {
            row[key] = value.newValue;
          });
        }
      });
    }

    return data;
  }

  private undoEditedValues() {
    this.restoreOriginalValues();

    this.editionService.removeAllChanges();

    if (this.gridData?.length > 0) {
      this.gridDataResult = process(this.gridData, this.state);
    }

    this.editedValuesChange.emit(this.editionService.getCurrentChanges());
  }

  private restoreOriginalValues() {
    const currentChanges = this.editionService.getCurrentChanges();
    currentChanges.forEach((cellChanges, rowId) => {
      cellChanges.forEach((change, field) => {
        const row = this.gridData.find((f) => f[this.gridSettings.selectByFieldName] == rowId);

        if (row) {
          this.gridData.find((f) => f[this.gridSettings.selectByFieldName] == rowId)[field] =
            change.originalValue;
        }
      });
    });
  }

  setTotalCount(count: number): void {
    this.fitColumns();
    this.totalCount = count;
    this.setSelectedRows();
  }

  onSelectAllChange(checkedState) {
    if (!this.gridSettings) {
      return;
    }

    const checked = checkedState?.target?.checked;
    this.isProcessingSelectAll = true;
    const currentData = this.gridData?.length > 0 ? this.getCurrentFilteredData() : [];
    const currentDataKeys = currentData.map((m) => m[this.gridSettings.selectByFieldName]);
    const selecterRowsKeys = this.selectedRows.map((m) => m[this.gridSettings.selectByFieldName]);

    if (checked) {
      this.selectedRows = this.objectHelperService.getCombinedArrays(
        // filter currentData to avoid posible duplicates
        currentData.filter(
          (f) => !selecterRowsKeys.includes(f[this.gridSettings.selectByFieldName])
        ),
        this.selectedRows
      );
    } else {
      this.selectedRows = this.selectedRows.filter(
        (f) => !currentDataKeys.includes(f[this.gridSettings.selectByFieldName])
      );
    }

    this.selectedItemsChange.emit(this.selectedRows);
    const itemsFiltered = this.selectedRows.map(
      (item) => item[this.gridSettings?.selectByFieldName]
    );
    this.selectAllValue = checked;

    this.persistSelectedRows();

    this.ngZone.run(() => {
      this.isProcessingSelectAll = false;
      this.currentSelection = itemsFiltered;
      this.calculateLocalSelectAllState();
    });
  }

  private calculateLocalSelectAllState() {
    const currentData = this.gridData?.length > 0 ? this.getCurrentFilteredData() : [];
    const hasSelectedRows = this.selectedRows.length > 0;
    if (currentData.length && hasSelectedRows) {
      const selectedRowsKeys = this.selectedRows.map((m) => m[this.gridSettings.selectByFieldName]);

      const allCurrentDataIsSelected = currentData.every((v) =>
        selectedRowsKeys.includes(v[this.gridSettings.selectByFieldName])
      );

      this.selectAllIsIndeterminate = !allCurrentDataIsSelected;
      this.selectAllValue = allCurrentDataIsSelected;
    }
  }

  private getCurrentFilteredData() {
    const filterState = {
      filter: this.state.filter,
      group: this.state.group,
      skip: 0,
      sort: this.state.sort,
      take: this.gridData.length,
    };

    const result = process(this.gridData, filterState);
    return result.data;
  }

  private getGridDataResult(gridData: any[]) {
    if (this.gridSettings) {
      this.gridDataResult = {
        data: gridData?.slice(this.state.skip, this.state.skip + this.state.take),
        total: gridData?.length,
      };
      this.setTotalCount(gridData?.length);
      this.dataStateChange(null);
    }
  }

  clearFilters(): void {
    this.state.filter = null;
    this.state.sort = this.gridSettings?.sort;

    if (this.gridSettings) {
      this.persistGridSettings(this.gridSettings);
    }

    this.removeStorage(GridPersistedElements.Filter);
    this.removeStorage(GridPersistedElements.Sort);

    this.reloadGrid();
    this.calculateLocalSelectAllState();
  }

  canClearFilters() {
    return this.gridData?.length;
  }

  pageSizeChange(event: any) {}
}
