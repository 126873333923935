import { TabDetailParameterName } from '@common-modules/dependencies/navigation/tab-detail-component';
import { HierarchyFamilyFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/hierarchy-family-fi-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { HierarchyFamilyFilter } from '@common-modules/dependencies/wlm-filters/i-filters/hierarchy-family-filter';
import { IFilter } from '@common-modules/shared/filters/component-filters/filter';

export class FamilyFAdapter extends FilterAdapterSettings {
  id = FilterAdapterEnum.Family;

  private heFamilyFieldName: string;
  private heFamilyFilterSettings: HierarchyFamilyFISettings;
  private tabDetailParameterName = TabDetailParameterName.hierarchyFamilyId;

  constructor(init: {
    dataBindingField: string;
    heFamilyFieldName: string;
    heFamilyFilterSettings: HierarchyFamilyFISettings;
    tabDetailParameterName?: TabDetailParameterName;
  }) {
    super(init.dataBindingField);
    Object.assign(this, init);
  }

  adapt(filters: FiltersPayload): IFilter {
    const heFamilyFilter = filters.data.get(this.heFamilyFieldName);
    if (heFamilyFilter) {
      // Convert from BasicFilter to ElementTypeFilter.
      const selectedId = heFamilyFilter.value ? heFamilyFilter.value.map((item) => item.value) : [];
      const result = new HierarchyFamilyFilter(heFamilyFilter.fieldName, selectedId);
      return result;
    }
    return null;
  }

  buildResults(heFamilyFilter: HierarchyFamilyFilter): FilterAdapterResult {
    if (heFamilyFilter) {
      if (!heFamilyFilter.familyId) {
        this.filtersResult.filters.delete(this.dataBindingField);
      } else {
        this.filtersResult.filters.set(this.dataBindingField, heFamilyFilter);
      }

      this.heFamilyFilterSettings.selectedId = heFamilyFilter.familyId;
      this.panelParams.addParameter(this.tabDetailParameterName, this.heFamilyFilterSettings);
    }
    return new FilterAdapterResult({
      dbFilters: this.filtersResult,
      tabParams: this.panelParams,
    });
  }
}
