import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpCacheClient } from '@common-modules/cache/http-cache/http-cache.client';
import { HttpCacheInterceptor } from '@common-modules/cache/http-cache/http-cache.interceptor';
import { HttpCacheManager } from '@common-modules/cache/http-cache/http-cache.manager';
import { HierarchyService } from '@common-modules/dependencies/he/hierarchy.service';
import { WlmNavigationService } from '@common-modules/dependencies/navigation/wlm-navigation.service';
import { NotificationPopupService } from '@common-modules/dependencies/shared/notification-popup.service';
import { DynamicLayoutModule } from '@common-modules/dynamic-layout/dynamic-layout.module';
import { ReduxStateModule } from '@common-modules/redux/redux-state.module';
import { RightPanelModule } from '@common-modules/right-panel/right-panel.module';
import { NotificationRelatedService } from '@common-modules/shared-component/notification-related/notification-related.service';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { AuthorizeService } from '@common-modules/shared/auth/services/authorize.service';
import { TokenInterceptor } from '@common-modules/shared/auth/token.interceptor';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { ColorHelperService } from '@common-modules/shared/helpers/color-helper.service';
import { GridHelperService } from '@common-modules/shared/helpers/grid-helper.service';
import { NavMenuBuilderHelperService } from '@common-modules/shared/helpers/navmenu-builder-helper.service';
import { DatesInterceptor } from '@common-modules/shared/interceptors/dates.interceptor';
import { KendoMessageService } from '@common-modules/shared/localization/kendo-message.service';
import { RightPanelService } from '@common-modules/shared/navigation/right-panel.service';
import { NotificationSelectionService } from '@common-modules/shared/notifications/notification-selection.service';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { UsersIdentityService } from '@common-modules/shared/roles/identity.service';
import { RolesService } from '@common-modules/shared/roles/roles.service';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { WlmSecurityModule } from '@common-modules/wlm-security/wlm-security.module';
import { SpinnerInterceptor } from '@common-modules/wlm-spinner/spinner.interceptor';
import { WlmSpinnerModule } from '@common-modules/wlm-spinner/wlm-spinner.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from '@progress/kendo-angular-l10n';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';
import { AlarmsModule } from './features/alarms/alarms.module';
import { ActivityRaiseService } from './features/alc/activity-raise.service';
import { ALCModule } from './features/alc/alc.module';
import { AlgorithmsModule } from './features/algorithm/algorithm.module';
import { BiModule } from './features/bi/bi.module';
import { BiGridService } from './features/bi/services/bi-grid.service';
import { AlarmActivationsService } from './features/configuration/alarm-configuration-components/alarm-activations-grid/alarm-activations.service';
import { AlarmRelatedElementsService } from './features/configuration/alarm-configuration-components/alarm-related-elements/alarm-related-elements.service';
import { AlarmsConfigurationService } from './features/configuration/alarms-configuration-page/alarms-configuration.service';
import { PointConfigurationService } from './features/configuration/point-configuration.service';
import { PointValidationGroupService } from './features/configuration/point-validation-group.service';
import { TelemetryPointsService } from './features/configuration/telemetry-points.service';
import { CustomerModule } from './features/customer/customer.module';
import { CustomerReadingsGridService } from './features/customer/services/customer-readings-grid.service';
import { DataValidationModule } from './features/data-validation/data-validation.module';
import { DataVisualizationModule } from './features/data-visualization/data-visualization.module';
import { DistributionNetworkModule } from './features/dn/distribution-network.module';
import { HierarchyModule } from './features/hierarchy/hierarchy.module';
import { HierarchySelectionService } from './features/hierarchy/services/hierarchy-selection.service';
import { HomeModule } from './features/home/home.module';
import { LeakageReportingModule } from './features/leakage-reporting/leakage-reporting.module';
import { LogsModule } from './features/logs/logs.module';
import { MapLayerSourcesService } from './features/map/map-layer-sources.service';
import { MonitoringModule } from './features/monitoring/monitoring.module';
import { NotificationService } from './features/monitoring/notification/services/notification.service';
import { WlmNavigationModule } from './features/navigation/wlm-navigation.module';
import { NeConfigurationModule } from './features/ne-configuration/ne-configuration.module';
import { PressureManagementModule } from './features/pressure-management/pressure-management.module';
import { PressureMonitoringService } from './features/pressure-management/pressure-monitoring-page/pressure-monitoring.service';
import { SchematicsModule } from './features/schematics/schematics.module';
import { HierarchyTreeFilterHelperService } from './features/shared/filters/hierarchy/hierarchy-tree-filter/hierarchy-tree-filter-helper.service';
import { WlmSpinnerSplashService } from './features/shared/services/wlm-spinner-splash.service';
import { WlmSharedModule } from './features/shared/wlm-shared.module';
import { ThemeTestModule } from './features/theme-test/theme-test.module';
import { TransmissionNetworkModule } from './features/tn/transmission-network.module';
import { WaterBalanceService } from './features/water-balance/water-balance-page/water-balance.service';
import { WaterBalanceModule } from './features/water-balance/water-balance.module';
import { WlmFiltersModule } from './features/wlm-filters/wlm-filters.module';

import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { APP_NAVIGATIONS } from '@common-modules/shared/core/injection-tokens/app-navigations.token';
import { APP_PERMISSIONS } from '@common-modules/shared/core/injection-tokens/app-permissions.token';
import { CURRENT_ENVIRONMENT } from '@common-modules/shared/core/injection-tokens/current-environment.token';
import {
  materialFormFieldDefaults,
  materialGlobalRippleConfig,
} from '@common-modules/shared/core/material-defaults';
import { ExternalNavigationService } from '@common-modules/shared/navigation/external-navigation.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { WlmChartsModule } from './features/charts/wlm-charts.module';
import { ConfigurationModule } from './features/configuration/configuration.module';
import { IntegrationModule } from './features/integration/integration.module';
import { IntegrationWizardSteps } from './features/integration/pages/integration-page/integration-wizard-steps';
import { LOGS_MODULE_SETTINGS } from './features/logs/logs-module-settings';
import { MergedZonesModule } from './features/merged-zones/merged-zones.module';
import { WaterLossAppComponent } from './water-loss-app/water-loss-app.component';
import { waterLossAppNavigations } from './water-loss-navigations';
import { waterLossAppPermissions } from './water-loss-permissions';
import { WaterLossRoutingModule } from './water-loss-routing.module';

// Golden Layoyt requires to have JQuery as global in the window object.
window['$'] = $;

const modules = [
  HttpClientModule,
  //OAuthModule.forRoot(),
  BrowserModule,
  BrowserAnimationsModule,
  WaterLossRoutingModule,
  WlmNavigationModule,
  WlmSharedModule,
  ALCModule,
  HomeModule,
  WlmSecurityModule,
  DragDropModule,
  RightPanelModule,
  CustomerModule,
  WlmFiltersModule,
  WaterBalanceModule,
  DataValidationModule,
  DataVisualizationModule,
  AlarmsModule,
  AlgorithmsModule,
  // StoreModule.forRoot({}),
  // EffectsModule.forRoot(),
  // !environment.production ? StoreDevtoolsModule.instrument() : [],
  ReduxStateModule,
  DynamicLayoutModule,
  ScrollingModule,
  // TranslateModule.forRoot(translateModuleConfigurator),
  FormlyModule,
  FormlyMaterialModule,
  FormlyMatDatepickerModule,
  WlmSpinnerModule.forFeature({
    service: WlmSpinnerSplashService,
  }),
  IntegrationModule,
  NgxSkeletonLoaderModule.forRoot(),

  // Add previously lazy-loaded modules
  LogsModule,
  BiModule,
  ConfigurationModule,
  MonitoringModule,
  NeConfigurationModule,

  //We should fix those modules
  ThemeTestModule,
  TransmissionNetworkModule,
  PressureManagementModule,
  LeakageReportingModule,
  HierarchyModule,
  DistributionNetworkModule,
  SchematicsModule,
  WlmChartsModule,
  MergedZonesModule,
];

// Translation json configuration moved to translate-module.configurator.ts
@NgModule({
  imports: modules,
  declarations: [WaterLossAppComponent],
  bootstrap: [WaterLossAppComponent],
  exports: [WaterLossAppComponent, CustomerModule, TranslateModule].concat(modules as any),
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DatesInterceptor,
      multi: true,
    },
    HttpCacheManager,
    {
      provide: APP_INITIALIZER,
      useFactory: (cacheManager: HttpCacheManager) => () => cacheManager.start(1),
      deps: [HttpCacheManager],
      multi: true,
    },
    HttpCacheClient,
    { provide: MessageService, useClass: KendoMessageService }, // Kendo i18n for all modules.
    { provide: 'ActivityRaiseService', useExisting: ActivityRaiseService },
    { provide: 'RolesService', useExisting: RolesService },
    { provide: 'PointConfigurationService', useExisting: PointConfigurationService },
    { provide: 'PointValidationGroupService', useExisting: PointValidationGroupService },
    { provide: 'TelemetryPointsService', useExisting: TelemetryPointsService },
    { provide: 'MapLayerSourcesService', useExisting: MapLayerSourcesService },
    { provide: 'WlmNavigationService', useExisting: WlmNavigationService },
    { provide: 'AuthenticationService', useExisting: AuthenticationService },
    { provide: 'AuthorizeService', useExisting: AuthorizeService },
    { provide: 'HierarchyTreeFilterHelperService', useExisting: HierarchyTreeFilterHelperService },
    { provide: 'HierarchyService', useExisting: HierarchyService },
    { provide: 'ArrayHelperService', useExisting: ArrayHelperService },
    { provide: 'ColorHelperService', useExisting: ColorHelperService },
    { provide: 'GridHelperService', useExisting: GridHelperService },
    { provide: 'UsersIdentityService', useExisting: UsersIdentityService },
    { provide: 'GridSettingsService', useExisting: GridSettingsService },
    { provide: 'WaterBalanceService', useExisting: WaterBalanceService },
    { provide: 'NavmenuBuilderHelperService', useExisting: NavMenuBuilderHelperService },
    { provide: 'AlarmsConfigurationService', useExisting: AlarmsConfigurationService },
    { provide: 'AlarmActivationsService', useExisting: AlarmActivationsService },
    { provide: 'AlarmRelatedElementsService', useExisting: AlarmRelatedElementsService },
    { provide: 'NotificationService', useExisting: NotificationService },
    { provide: 'NotificationPopupService', useExisting: NotificationPopupService },
    { provide: 'NotificationRelatedService', useExisting: NotificationRelatedService },
    { provide: 'PressureMonitoringService', useExisting: PressureMonitoringService },
    { provide: 'HierarchySelectionService', useExisting: HierarchySelectionService },
    { provide: 'BiGridService', useExisting: BiGridService },
    { provide: 'CustomerReadingsGridService', useExisting: CustomerReadingsGridService },
    RightPanelService,
    ExternalNavigationService,
    NotificationSelectionService,
    UoMService,
    PendingChangesManagerService,
    AuthenticationService,
    {
      provide: CURRENT_ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: APP_PERMISSIONS,
      useValue: waterLossAppPermissions,
    },
    {
      provide: APP_NAVIGATIONS,
      useValue: waterLossAppNavigations,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: materialFormFieldDefaults,
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: materialGlobalRippleConfig,
    },
    {
      provide: LOGS_MODULE_SETTINGS,
      useValue: {
        integrationGisLayerStepId: IntegrationWizardSteps.GisLayers,
      },
    },
  ],
})
export class WaterLossModule {
  static injector: Injector;

  constructor(injector: Injector) {
    WaterLossModule.injector = injector;
  }
}
