import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { WaterBalanceParams } from '@common-modules/dependencies/water-balance/water-balance-params';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { IElementSize } from '@common-modules/shared/model/element-size';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { WaterBalanceKPIEnum } from '../../shared/model/water-balance/water-balance-kpi-types';
import { WaterBalanceDto } from '../../shared/model/water-balance/water-balance.dto';
import { WaterBalanceService } from '../water-balance-page/water-balance.service';

const COMPONENT_SELECTOR = 'wlm-water-balance-widget';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './water-balance-widget.component.html',
  styleUrls: ['./water-balance-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WaterBalanceWidgetComponent implements OnInit {
  @Output() widgetReady = new EventEmitter<void>();
  @Input() maxSelectAmount = 3; // Select only n elements at the same time.
  @Input() public set params(value: WaterBalanceParams) {
    this.isLoading = true;
    this.data = null;
    this._params = value;
    this.resetSelection();
    this._dataSubs$.unsubscribe();
    this._dataSubs$ = this.waterBalanceService
      .getWaterBalance(this.params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.widgetReady.emit();
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.data = data;
      });
  }

  @Input() set size(value: IElementSize) {
    this.heightPx = value?.height ? value.height + 'px' : 'unset';
    this.widthPx = value?.width ? value.width + 'px' : 'unset';
  }

  @Output() sectionSelect = new EventEmitter<WaterBalanceKPIEnum[]>();

  data: WaterBalanceDto;
  selectedArray: WaterBalanceKPIEnum[] = [];
  selectedHash = {};
  kpiValues = WaterBalanceKPIEnum;
  isLoading = false;
  heightPx: string;
  widthPx: string;
  private _params: WaterBalanceParams;
  private _dataSubs$ = new Subscription();

  T_SCOPE = `${AppModules.WaterBalance}.${COMPONENT_SELECTOR}`;

  constructor(private waterBalanceService: WaterBalanceService) {}

  ngOnInit(): void {}

  onSelect(attr: WaterBalanceKPIEnum): void {
    if (!this.selectedHash[attr]) {
      // Set the new element as the first.
      this.selectedHash[attr] = true;
      this.selectedArray.unshift(attr);
      // If max amount of elements is reached, unselect the last element.
      if (this.selectedArray.length > this.maxSelectAmount) {
        const lastAttr = this.selectedArray.pop();
        this.selectedHash[lastAttr] = false;
      }
    } else {
      // Unselect the element.
      this.selectedHash[attr] = false;
      this.selectedArray = this.selectedArray.filter((item) => item !== attr);
    }
    this.selectedHash = { ...this.selectedHash };
    this.sectionSelect.emit(this.selectedArray.slice().reverse());
  }

  public get params(): WaterBalanceParams {
    return this._params;
  }

  resetSelection(): void {
    this.selectedHash = {};
    this.selectedArray = [];
    this.sectionSelect.emit(this.selectedArray);
  }
}
