import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { AppliedExtendedFiltersSelector } from '@common-modules/dynamic-layout/state/filters/filters.selectors';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { ExtendedFilters } from '@common-modules/shared/filters/component-filters/extended-filters';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ResetUoMStateAction } from '../../state/uom.action';
import { SelectedDimensionSelector } from '../../state/uom.selectors';
import { UoMScopes } from '../uom-scopes';

const COMPONENT_SELECTOR = 'wlm-uom-configuration-widget';
export const UOM_CONFIGURATION_COMPONENT_INSTANCE = `${COMPONENT_SELECTOR}#1`;

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './uom-configuration-widget.component.html',
  styleUrls: ['./uom-configuration-widget.component.scss'],
  providers: [ReduxStateService],
})
export class UomConfigurationWidgetComponent
  extends BaseDynamicWidgetComponent
  implements OnInit, OnDestroy
{
  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  // Not all actions/selectors must have the same action settings.
  private _scopeSettings = new StateScopeSettings({
    scope: UoMScopes.Main,
  });

  phTitleKey = `${this.T_SCOPE}.placeholder-title`;
  phSubtitleKey = `${this.T_SCOPE}.placeholder-subtitle`;

  pageId: string;

  selectedFamily: string;
  selectedDimension: number;

  get componentName(): string {
    return 'UomConfigurationWidgetComponent';
  }

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
    this.pageId = widgetSettings.page;
  }

  onWidgetInit(): void {
    this._state
      .select<ExtendedFilters>(new AppliedExtendedFiltersSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (filters) => {
          if (!filters) {
            return;
          }

          const selectedFamily = filters.model.hierarchyFamilyId;
          if (selectedFamily) {
            this.selectedFamily = selectedFamily;
          }
        },
      });

    this._state
      .select<number>(new SelectedDimensionSelector(this._scopeSettings))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (dimension) => {
          if (!dimension) {
            return;
          }

          this.selectedDimension = dimension;
        },
      });
  }

  ngOnDestroy() {
    this._state.dispatch(new ResetUoMStateAction(this._scopeSettings));
  }
}
