import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { SchematicImportComponent } from '../schematic-import/schematic-import.component';

const COMPONENT_SELECTOR = 'wlm-schematic-import-popup';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './schematic-import-popup.component.html',
  styleUrls: ['./schematic-import-popup.component.scss'],
})
export class SchematicImportPopupComponent implements OnInit {
  @ViewChild(SchematicImportComponent) formComponent: SchematicImportComponent;

  readonly T_SCOPE = `${AppModules.Schematics}.${COMPONENT_SELECTOR}`;
  readonly titleKey = `${this.T_SCOPE}.title`;
  isLoading = true;
  isValid = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) inputData,
    private _dialogRef: MatDialogRef<any>,
    private _dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
  }

  onImport(): void {
    this.formComponent.save();
  }

  onSaved(): void {
    this._dialogService.showEntityActionSnackBar('create', 'schematic.singular');
    this.close(true);
  }

  onIsValid(isValid: boolean): void {
    this.isValid = isValid;
  }

  onExit(event): void {
    this.close(false);
  }

  private close(success: boolean): void {
    this._dialogRef.close(success);
  }
}
