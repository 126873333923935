import { FiltersPayloadStatus } from '@common-modules/dependencies/wlm-filters/filters-payload-status.enum';
import { FilterItemSelectOption } from './filter-item-select-option';

export class FilterSelection {
  selection: FilterItemSelectOption[];
  status: FiltersPayloadStatus;

  constructor(init: Partial<FilterSelection>) {
    this.selection = init.selection ?? [];
    this.status = init.status ?? FiltersPayloadStatus.Normal;
  }
}
