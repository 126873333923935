import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { SignalCategories } from '@common-modules/dependencies/shared/model/signal-categories';
import { DynamicSettings } from '@common-modules/dynamic-layout/models/dynamic-settings';
import { DynamicSettingsDelete } from '@common-modules/dynamic-layout/models/dynamic-settings-delete';
import { DynamicSettingsSave } from '@common-modules/dynamic-layout/models/dynamic-settings-save';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SettingsDataSource } from '../../dynamic-forms/models/settings-data-source.enum';
import { SettingsEntityType } from '../../dynamic-forms/models/settings-entity-type';

import { FormValuesPayload } from '../../dynamic-forms/models/form-values-data-source';
import { SettingsEntitySubtype } from '../../dynamic-forms/models/settings-entity-subtype';
import { BaseService } from '../base.service';
import { ObjectHelperService } from '../helpers/object-helper.service';
import { SelectOption } from '../model/shared/select-option';
import { DynamicSelectOptions } from './dynamic-select-options';

@Injectable({ providedIn: 'root' })
export class DynamicSettingsService extends BaseService {
  constructor(injector: Injector, private readonly _objectHelperService: ObjectHelperService) {
    super(injector);
  }

  getFormsCustomSettings(
    entityType: SettingsEntityType,
    elementTypeId: string,
    entitySubtypes?: SettingsEntitySubtype[]
  ): Observable<any> {
    return this.filterSettingsBySignals(false)(entityType, elementTypeId, entitySubtypes);
  }

  getSignalsCustomSettings(
    entityType: SettingsEntityType,
    elementTypeId: string,
    entitySubtypes?: SettingsEntitySubtype[]
  ): Observable<any> {
    return this.filterSettingsBySignals(true)(entityType, elementTypeId, entitySubtypes);
  }

  getCustomSettings(
    entityType: SettingsEntityType,
    elementTypeId: string,
    entitySubtypes?: SettingsEntitySubtype[]
  ): Observable<any> {
    const queryParams = {
      elementTypeId,
    };

    if (entitySubtypes) {
      queryParams['entitySubtypes'] = entitySubtypes;
    }
    return this.httpCacheClient.get(
      `${this.apiUrl}/configuration/entity/${entityType}`,
      {
        avoid: true,
      },
      queryParams
    );
  }

  getCustomSettingsByDataSource(dataSource: SettingsDataSource): Observable<any> {
    return this.httpCacheClient.get(`${this.apiUrl}/configuration/source/${dataSource}`, {
      avoid: false,
    });
  }

  getUserMapKpiSettings(): Observable<any> {
    return this.httpCacheClient.get(`${this.apiUrl}/settings/mapkpi`).pipe(
      map((settings: any) => {
        // const settingsValue = this.getSettingsValue(settings);
        return settings;
      }),
      catchError((error: HttpErrorResponse) => {
        return error.status === 404 ? of(false) : throwError(error);
      })
    );
  }

  getUserMapLayerSettings(): Observable<any> {
    return this.httpCacheClient.get(`${this.apiUrl}/settings/maplayers`).pipe(
      map((settings: any) => settings),
      catchError((error: HttpErrorResponse) =>
        error.status === 404 ? of(false) : throwError(error)
      )
    );
  }

  private _selectorsCache = new Map<string, ReplaySubject<SelectOption<string>[]>>();

  private buildSelectorKey = (selectorId: string, params: { [paramName: string]: any }) => {
    if (!params || Object.keys(params).length === 0) {
      return selectorId;
    }

    const paramsKey = this._objectHelperService.sortObjectKeys(params);
    return `${selectorId}.${paramsKey}`;
  };

  getSelectorsOptions(
    selectorId: string,
    params: { [paramName: string]: any } = {},
    avoidCache = false
  ): Observable<SelectOption<string>[]> {
    const fullKey = this.buildSelectorKey(selectorId, params);
    if (!avoidCache) {
      if (this._selectorsCache.has(fullKey)) {
        const subject$ = this._selectorsCache.get(fullKey);
        return subject$.asObservable();
      } else {
        // Initialize with subjects so subsequent calls actually wait for the first call's response.
        this._selectorsCache.set(fullKey, new ReplaySubject(1));
      }
    }

    return this.httpCacheClient
      .get(`${this.apiUrl}/configuration/selector/${selectorId}`, this.avoidCache, params)
      .pipe(
        map((data: DynamicSelectOptions) => {
          if (data.selectors.length !== 1) {
            throw new Error(`Only one result is expected, but got ${data.selectors.length}`);
          }

          return Object.entries(data.selectors[0].selectorValues).map(([value, label]) => {
            // Preprocess possible null values which come as strings.
            if (value === null) {
              value = 'null';
            }
            return new SelectOption<string>({ value, label });
          });
        }),
        tap((options) => {
          // Notify all subscribers.
          if (!avoidCache) {
            const subject$ = this._selectorsCache.get(fullKey);
            subject$.next(options);
            subject$.complete();
          }
        })
      );
  }

  getValues(entityType: SettingsEntityType, elementId: any): Observable<FormValuesPayload> {
    const params = typeof elementId !== 'undefined' ? { elementId } : {};
    return this.httpCacheClient.get(
      `${this.apiUrl}/configuration/entity/${entityType}/value`,
      this.avoidCache,
      params
    );
  }

  private filterSettingsBySignals =
    (include: boolean) =>
    (
      entityType: SettingsEntityType,
      elementTypeId: string,
      entitySubtypes: SettingsEntitySubtype[]
    ) => {
      return this.getCustomSettings(entityType, elementTypeId, entitySubtypes).pipe(
        map((data: { categories: { categoryKey: string }[] }) => {
          const signalCategories = Object.values(SignalCategories);
          const filtered = data.categories.filter((category) => {
            const foundIndex = signalCategories.findIndex(
              (signalCategory) => category.categoryKey === signalCategory
            );
            if (include && foundIndex > -1) {
              return true;
            }
            if (!include && foundIndex === -1) {
              return true;
            }
            return false;
          });
          return filtered;
        })
      );
    };

  saveDynamicSettings(saveData: DynamicSettingsSave): Observable<void> {
    return this.httpCacheClient.post(`${this.apiUrl}/settings`, saveData);
  }

  saveDynamicSettingsBulk(saveData: DynamicSettingsSave[]): Observable<void> {
    return this.httpCacheClient.post(`${this.apiUrl}/settings/bulk`, saveData);
  }

  loadDynamicSettings(loadData: DynamicSettings): Observable<any> {
    return this.httpCacheClient
      .get(`${this.apiUrl}/settings/${loadData.settingArea}/${loadData.settingKey}`)
      .pipe(
        map((settings: any) => {
          return settings;
        }),
        catchError((error: HttpErrorResponse) => {
          return error.status === 404 ? of(false) : throwError(error);
        })
      );
  }

  deleteDynamicSettings(deleteData: DynamicSettingsDelete): Observable<any> {
    // Stringify data because, if we have instance of classes inside deleteData, it could not serialize correctly.
    const data = JSON.parse(JSON.stringify(deleteData));
    return this.httpCacheClient.delete(
      `${this.apiUrl}/settings`,
      this.avoidCache,
      null,
      null,
      data
    );
  }

  private getSettingsValue(settings: any) {
    if (settings?.settingValue) {
      const settingValue = settings.settingValue;
      if (typeof settingValue === 'string') {
        return JSON.parse(settingValue);
      }
      return settingValue;
    }
    return settings;
  }
}
