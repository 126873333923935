import { HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, finalize } from 'rxjs';
import { MergedZonesDto } from '../../models/merged-zones.dto';
import { MergedZonesService } from '../../services/merged-zones.service';

export class MergedZonesCreatePopupData {
  isUpdate?: boolean;
  model?: MergedZonesDto;
}

const COMPONENT_SELECTOR = 'wlm-merged-zones-create-popup';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './merged-zones-create-popup.component.html',
  styleUrls: ['./merged-zones-create-popup.component.scss'],
})
export class MergedZonesCreatePopupComponent implements OnInit {
  private _conflictError$ = new Subject<void>();
  readonly conflictError$ = this._conflictError$.asObservable();
  private _conflictNameError$ = new Subject<void>();
  readonly conflictNameError$ = this._conflictNameError$.asObservable();

  private readonly _conflictErrorBoth = 'both';
  private readonly _conflictErrorName = 'name';
  private readonly _conflictErrorId = 'id';

  isValid: boolean = false;
  control = new FormControl();
  initialModel: MergedZonesDto;
  model: MergedZonesDto;
  isUpdate = false;

  readonly T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  readonly baseTitleKey = `${this.T_SCOPE}.title`;
  titleKey: string;
  setLoading: (loading: boolean) => void;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: MergedZonesCreatePopupData,
    private readonly _dialogRef: MatDialogRef<MergedZonesCreatePopupComponent>,
    private readonly _mergedZonesService: MergedZonesService,
    private readonly _dialogService: DialogService,
    private readonly _spinnerService: SpinnerService
  ) {
    this.isUpdate = data?.isUpdate;
    this.setDefaultValues(data?.model);
    this.titleKey = `${this.baseTitleKey}.${this.isUpdate ? 'update' : 'create'}`;
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((model) => {
      this.model = model;
    });
    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.isValid = this.control.valid;
    });
  }

  private setDefaultValues(model?: MergedZonesDto): void {
    if (model) {
      this.control.setValue(model);
    }
  }

  onSave(): void {
    if (this.isValid) {
      this.setLoading(true);

      let apiCall$;
      if (this.isUpdate) {
        apiCall$ = this._mergedZonesService.update(this.model);
      } else {
        apiCall$ = this._mergedZonesService.create(this.model);
      }

      apiCall$.pipe(finalize(() => this.setLoading(false))).subscribe({
        next: (mergedZone) => {
          this._dialogService.showEntityActionSnackBar(
            this.isUpdate ? 'update' : 'create',
            'merged-zone.singular'
          );
          this.onClose(mergedZone);
        },
        error: (httpError) => {
          if (httpError?.status === HttpStatusCode.Conflict) {
            if (httpError.error.type == this._conflictErrorBoth) {
              this._conflictError$.next();
              this._conflictNameError$.next();

              return;
            }
            if (httpError.error.type == this._conflictErrorId) {
              return this._conflictError$.next();
            }

            if (httpError.error.type == this._conflictErrorName) {
              return this._conflictNameError$.next();
            }
          }

          this._dialogService.showErrorMessage(httpError);
        },
      });
    }
  }

  onClose(data?): void {
    this._dialogRef.close(data);
  }
}
