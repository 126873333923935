import { Component, Inject, Injector, OnInit } from '@angular/core';
import { WidgetSettingsToken } from '@common-modules/dynamic-layout/dynamic-layout-external-settings';
import { BaseDynamicWidgetComponent } from '@common-modules/redux/components/base-dynamic-widget.component';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { CustomerMeterDto } from '../../models/customer-meter.dto';

const COMPONENT_SELECTOR = 'wlm-meter-details-widget';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './meter-details-widget.component.html',
  styleUrls: ['./meter-details-widget.component.scss'],
})
export class MeterDetailsWidgetComponent extends BaseDynamicWidgetComponent implements OnInit {
  meter: CustomerMeterDto;
  customerId: string;

  constructor(
    injector: Injector,
    @Inject(WidgetSettingsToken) widgetSettings: StateWidgetSettings
  ) {
    super(injector, widgetSettings);
    this.meter = widgetSettings.params?.meter;
    this.customerId = widgetSettings.params?.customerId;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onWidgetInit(): void {}

  get componentName(): string {
    return 'MeterDetailsWidgetComponent';
  }
}
