import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UsersGridODataService } from '@common-modules/shared/services/users/users.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IHierarchyElementTypes } from '../../dependencies/he/hierarchy.constants';
import { HierarchyService } from '../../dependencies/he/hierarchy.service';
import { DropdownNavigationItem } from '../../dependencies/navigation/dropdown-navigation-item';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from '../../dependencies/navigation/tab-detail-component';
import { AppModules } from '../../shared/app-modules.enum';
import { BasePageComponent } from '../../shared/component/base-page.component';
import { GridSetting } from '../../shared/constants/grid.constants';
import { GridSettingsService } from '../../shared/core/grid/grid-settings.service';
import { DialogService } from '../../shared/dialogs/dialogs.service';
import { DataBindingFilters } from '../../shared/filters/component-filters/data-binding-filters';
import { DateHelperService } from '../../shared/helpers/date-helper.service';
import { WLMDialogResult } from '../../shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from '../../shared/model/dialog/wlm-dialog-setting';
import { UserDto } from '../../shared/model/roles/user.dto';
import { UsersActivityChartConfiguration } from '../../shared/model/roles/users-activity-chart-configuration';
import { RightPanelService } from '../../shared/navigation/right-panel.service';
import { GenericGridComponent } from '../../wlm-grid/generic-grid/generic-grid.component';
import { ProfileDialogsHierarchyComponent } from '../groups-components/profile-dialogs-hierarchy/profile-dialogs-hierarchy.component';
import { CreateUserComponent } from '../users-components/create-user/create-user.component';
import { IdentityUsersAssignedGridComponent } from '../users-components/identity-users-assigned-grid/identity-users-assigned-grid.component';
import { UserGroupGridComponent } from '../users-components/user-group-grid/user-group-grid.component';
import { UsersActivityPopupComponent } from '../users-components/users-activity-popup/users-activity-popup.component';

const COMPONENT_SELECTOR = 'wlm-users-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
})
export class UsersPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild(GenericGridComponent) public genericGrid: GenericGridComponent;
  gridSettings: GridSetting;
  gridFiltersForBinding: DataBindingFilters;
  selectedUser: UserDto;
  groupLevelHE: string[];
  gridName = 'Users';
  canLoad: boolean;
  offsetStartDate = 1; // 1 month

  T_SCOPE = `${AppModules.WlmSecurity}.${COMPONENT_SELECTOR}`;

  constructor(
    private _gridService: GridSettingsService,
    private _rightPanelService: RightPanelService,
    private _usersGridService: UsersGridODataService,
    private _dialogService: DialogService,
    private _userActivityPopup: MatDialog,
    private _createUserPopup: MatDialog,
    private _heService: HierarchyService,
    private _dateHelperService: DateHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getGroupLevelHE();
    this.gridFiltersForBinding = new DataBindingFilters();
    this.initTabPanel();
    this._gridService.getGridSettingsByName(this.gridName).subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
        }
      },
    });
  }

  onSelectedUser(item: UserDto) {
    this.selectedUser = item;
    this.notifyTabPanel();
  }

  /**
   * Shows a warning if needed before toggling super user.
   */
  warnToggleSuperUser() {
    if (!this.selectedUser.isSuperUser) {
      const userName = `${this.selectedUser.name} ${this.selectedUser.surname}`;
      const dialogSettings = new WlmDialogSettings({
        translateKey: `${this.T_SCOPE}.super-user-warning`,
        params: { userName },
      });
      this._dialogService
        .showTranslatedDialogMessage(dialogSettings)
        .subscribe((dialogRef: WLMDialogResult) => {
          if (dialogRef.result) {
            this.toggleSuperUser();
          }
        });
    } else {
      this.toggleSuperUser();
    }
  }

  /**
   * Attempts to toggle super user privileges to the selected user.
   */
  private toggleSuperUser() {
    this._usersGridService
      .toggleSuperUser(this.selectedUser.userCode)
      .subscribe((user: UserDto) => {
        if (this.selectedUser.isSuperUser !== user.isSuperUser) {
          this.selectedUser = new UserDto({
            groupIds: this.selectedUser.groupIds,
            isActive: this.selectedUser.isActive,
            lastAccess: this.selectedUser.lastAccess,
            name: this.selectedUser.name,
            surname: this.selectedUser.surname,
            userCode: this.selectedUser.userCode,
            isSuperUser: user.isSuperUser,
          });
          this.reloadGrid();
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.super-regular-user-change` })
          );
        }
      });
  }

  reactivateUser() {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.reactivate-warning`,
    });
    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.reactivateUserAction();
        }
      });
  }

  private reactivateUserAction() {
    this._usersGridService.reactivateUser(this.selectedUser).subscribe({
      next: (response) => {
        this.reloadGrid();
        this._dialogService.showTranslatedMessageInSnackBar(
          new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.messages.reactivate-success` })
        );
      },
    });
  }

  private reloadGrid() {
    this.genericGrid?.reloadGrid();
  }

  /**
   * Configures the TabDetailPanel with the corresponding components.
   */
  initTabPanel() {
    this.localization.get(`${this.T_SCOPE}.tab-settings`).subscribe((ts) => {
      const panelSettings = new TabDetailPanelSettings();
      panelSettings.addComponent(IdentityUsersAssignedGridComponent, ts['assigned-identity-users']);
      panelSettings.addComponent(UserGroupGridComponent, ts.groups);
      panelSettings.addComponent(ProfileDialogsHierarchyComponent, ts.permissions);
      this._rightPanelService.setTabSettings(panelSettings);
    });
  }

  /**
   * Notifies the TabDetailPanel with the selected user.
   */
  notifyTabPanel() {
    this.localization.get(`${this.T_SCOPE}.user-title`).subscribe((message) => {
      const parameters = new TabDetailPanelParameters();
      parameters.addParameter(TabDetailParameterName.user, this.selectedUser);
      parameters.addParameter(
        TabDetailParameterName.elementName,
        this.selectedUser ? `${message}: ${this.selectedUser.userCode}` : null
      );
      this._rightPanelService.setTabParameters(parameters);
    });
  }

  showActivityChart(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '765px';
    dialogConfig.height = '640px';
    const startDate = this._dateHelperService.getDefaultStartDate(this.offsetStartDate);
    const endDate = this._dateHelperService.getDefaultEndDate();

    const chartParameters = new UsersActivityChartConfiguration({
      startDate: startDate,
      endDate: endDate,
      elementIds: this.groupLevelHE,
    });

    dialogConfig.data = chartParameters;
    this._userActivityPopup.open(UsersActivityPopupComponent, dialogConfig);
  }

  showCreateUserPopup(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px';
    // dialogConfig.height = '400px';

    this._createUserPopup
      .open(CreateUserComponent, dialogConfig)
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          if (result) {
            this.reloadGrid();
          }
        },
      });
  }

  deleteSelectedUser() {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.remove-warning`,
    });
    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.deleteSelectedUserAction();
        }
      });
  }

  private deleteSelectedUserAction() {
    this._usersGridService.deleteUser(this.selectedUser).subscribe({
      next: (response) => {
        this.reloadGrid();
        this._dialogService.showTranslatedMessageInSnackBar(
          new WlmDialogSettings({ translateKey: `${this.T_SCOPE}.messages.remove-success` })
        );
      },
    });
  }

  getGroupLevelHE() {
    this._heService
      .getFilteredBy((x) => x.hierarchyElementTypeId === IHierarchyElementTypes.Group)
      .subscribe((hetype) => {
        this.groupLevelHE = hetype.map((x) => x.hierarchyElementId);
      });
  }

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  public get navigations(): DropdownNavigationItem[] {
    throw new Error('Method not implemented.');
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'UserPageCrud';
  }
}
