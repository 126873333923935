<div class="historical-container">
  <div class="buttons-panel">
    <div *ngIf="!isReadOnly" class="buttons-container">
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.add-configuration' | translate | lowercase"
        (click)="addNewConfiguration()"
        onclick="this.blur()"
        color="primary"
        [disabled]="isReadOnly"
      >
        <mat-icon class="material-icon">add</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        [matTooltip]="T_SCOPE + '.remove-configuration' | translate | lowercase"
        (click)="removeConfiguration()"
        configuration
        onclick="this.blur()"
        [disabled]="!selectedConfiguration || isReadOnly"
      >
        <mat-icon class="material-icon" svgIcon="delete"></mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="T_SCOPE + '.save-configuration' | translate | lowercase"
        (click)="onSave()"
        onclick="this.blur()"
        color="primary"
        [disabled]="saveDisabled()"
      >
        <wlm-icon [icon]="'save'"></wlm-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="'common.discard-button-tooltip' | translate | lowercase"
        (click)="discardChanges()"
        onclick="this.blur()"
        color="primary"
        [disabled]="!hasChanges || isReadOnly"
      >
        <wlm-icon [icon]="'discard-changes'"></wlm-icon>
      </button>
    </div>
  </div>
  <div class="historical-form-container">
    <div
      class="content-stretch empty-legend-container"
      *ngIf="formSettings && !selectedConfiguration"
    >
      <div class="empty-legend" style="flex: 1 0 auto">
        {{ T_SCOPE + '.empty-message' | translate }}
      </div>
    </div>

    <wlm-ne-configuration-pressure-historical-form
      *ngIf="formSettings && selectedConfiguration"
      [settings]="formSettings"
      [formModel]="selectedConfiguration"
      (formChanges)="onFormChange($event)"
      (canSave)="onCanSave($event)"
      (canReset)="onCanReset($event)"
    ></wlm-ne-configuration-pressure-historical-form>
  </div>
  <div class="">
    <wlm-historical-chart
      [chartSettings]="chartSettings"
      [updateChartNewEvents$]="updateChartNewEvents$"
      [updateChartEditedEvents$]="updateChartEditedEvents$"
      [updateChartDeletedEvents$]="updateChartDeletedEvents$"
      [discardAllConfiguration$]="dicardAllChanges$"
      [saveHistoricalConfiguration$]="saveConfiguration$"
      (selectEvent)="onChartSelectEvent($event)"
      (hasChanges)="onHasChanges($event)"
      (hasErrors)="onHasErrors($event)"
      (saveCompleted)="onSavedCompleted($event)"
    ></wlm-historical-chart>
  </div>
</div>
