import { DynamicFormHasChanges } from '@common-modules/dependencies/ne-configuration/dynamic-form-changes';
import { DynamicFormGroupCategories } from '@common-modules/dependencies/ne-configuration/dynamic-form-group-categories';
import { NoBoundarySettings } from '@common-modules/dependencies/ne-configuration/no-boundary-settings';
import { PressureSettings } from '@common-modules/dependencies/ne-configuration/pressure-settings';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { DynamicFormAdditionalSettings } from '@common-modules/dynamic-forms/models/dynamic-form-additional-settings';
import { DynamicFormSourceSettings } from '@common-modules/dynamic-forms/models/dynamic-form-source-settings';
import { DynamicFormUIValues } from '@common-modules/dynamic-forms/models/dynamic-form-ui-values';
import { GenericAction } from '@common-modules/redux/models/generic-action';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { StateFullSettings } from '@common-modules/redux/models/state-full-settings';
import { StateScopeSettings } from '@common-modules/redux/models/state-scope-settings';
import { createAction, props } from '@ngrx/store';
import { NEConfigActionTypes } from './ne-config.action-types';

export abstract class NEConfigAction extends GenericAction {
  area = StateAreas.NEConfig;
}

export class SelectNetworkElementConfigAction extends NEConfigAction {
  type = NEConfigActionTypes.SelectNetworkElement;

  constructor(public payload: INetworkElementDto, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class SetAttributesSourceAction extends NEConfigAction {
  type = NEConfigActionTypes.SetAttributesSource;

  constructor(public payload: DynamicFormSourceSettings, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class SetBoundarySettingsAction extends NEConfigAction {
  type = NEConfigActionTypes.SetBoundarySettings;

  constructor(public payload: NoBoundarySettings, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class SetFormDefinitionsAction extends NEConfigAction {
  type = NEConfigActionTypes.SetFormDefinitions;

  constructor(public payload: DynamicFormGroupCategories, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class SetFormUIValuesAction extends NEConfigAction {
  type = NEConfigActionTypes.SetFormUIValues;

  constructor(public payload: DynamicFormUIValues, settings = new StateScopeSettings({})) {
    super(settings);
  }
}
export class ResetHasChangesAction extends NEConfigAction {
  type = NEConfigActionTypes.ResetHasChanges;

  constructor(public payload: DynamicFormHasChanges, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class SetPressureSettingsAction extends NEConfigAction {
  type = NEConfigActionTypes.SetPressureSettings;

  constructor(public payload: PressureSettings, settings = new StateScopeSettings({})) {
    super(settings);
  }
}

export class SetFormAdditionalSettingsAction extends NEConfigAction {
  type = NEConfigActionTypes.SetFormAdditionalSettings;

  constructor(
    public payload: DynamicFormAdditionalSettings,
    settings = new StateScopeSettings({})
  ) {
    super(settings);
  }
}

export const NE_CONFIG_ACTION_CREATORS = [
  createAction(
    NEConfigActionTypes.SelectNetworkElement,
    props<{ payload: INetworkElementDto; settings: StateFullSettings }>()
  ),
  createAction(
    NEConfigActionTypes.SetAttributesSource,
    props<{ payload: DynamicFormSourceSettings; settings: StateFullSettings }>()
  ),
  createAction(
    NEConfigActionTypes.SetBoundarySettings,
    props<{ payload: NoBoundarySettings; settings: StateFullSettings }>()
  ),
  createAction(
    NEConfigActionTypes.SetFormDefinitions,
    props<{ payload: DynamicFormGroupCategories; settings: StateFullSettings }>()
  ),
  createAction(
    NEConfigActionTypes.SetFormUIValues,
    props<{ payload: DynamicFormUIValues; settings: StateFullSettings }>()
  ),
  createAction(
    NEConfigActionTypes.ResetHasChanges,
    props<{ payload: DynamicFormHasChanges; settings: StateFullSettings }>()
  ),
  createAction(
    NEConfigActionTypes.SetPressureSettings,
    props<{ payload: PressureSettings; settings: StateFullSettings }>()
  ),
  createAction(
    NEConfigActionTypes.SetFormAdditionalSettings,
    props<{ payload: DynamicFormAdditionalSettings; settings: StateFullSettings }>()
  ),
];
