import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ALCCampaignDto } from '@common-modules/dependencies/alc/alc-campaing.dto';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { MenuLink } from '@common-modules/dependencies/navigation/menu-link';
import { NavItem } from '@common-modules/dependencies/navigation/nav-item';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import { WlmNavigationService } from '@common-modules/dependencies/navigation/wlm-navigation.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { IGridSettings } from '@common-modules/shared/constants/grid.constants';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { NavMenuBuilderHelperService } from '@common-modules/shared/helpers/navmenu-builder-helper.service';
import { DynamicGridSettings } from '@common-modules/shared/model/grid/dynamic-grid-settings';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { ReplaySubject } from 'rxjs';
import { ALCActivityDto } from '../../../shared/model/alc/alc-activity.dto';
import { NavItemsConfiguration } from '../../../shared/model/navigation/navitem-configuration';
import { NavMenuConfiguration } from '../../../shared/model/navigation/navmenu-configuration';

const COMPONENT_SELECTOR = 'wlm-related-activities';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './related-activities.component.html',
  styleUrls: ['./related-activities.component.scss'],
})
export class RelatedActivitiesComponent implements OnInit {
  private _campaign: ALCCampaignDto;
  get campaign(): ALCCampaignDto {
    return this._campaign;
  }
  @Input() set campaign(value: ALCCampaignDto) {
    this._campaign = value;
    this.removeSelectionPersisted$.next();
    this.removeSelection$.next();

    if (value) {
      const filter = new DataBindingFilters();
      filter.addOrUpdateBasicFilter('hierarchyFamilyId', this.campaign.hierarchyFamilyId);
      filter.addOrUpdateBasicFilter('campaignId', this.campaign.campaignId);

      this.gridFiltersForBinding = filter;
    } else {
      this.gridFiltersForBinding = null;
    }
  }

  T_SCOPE = `${AppModules.ALC}.${COMPONENT_SELECTOR}`;

  private _activities: string[];
  get activities(): string[] {
    return this._activities;
  }

  settings: DynamicGridSettings = {
    gridSettingName: 'CampaignActivities',
    persistencyArea: 'CampaignRelatedActivities',
    usePersistence: false,
    type: 'generic',
    disableAutoLoad: false,
  };

  gridFiltersForBinding = new DataBindingFilters();
  grid: GenericGridComponent;
  gridSettings: IGridSettings;
  removeSelectionPersisted$ = new ReplaySubject<void>();
  removeSelection$ = new ReplaySubject<void>();
  private _selectedActivities: ALCActivityDto[] = [];
  public get selectedActivities(): ALCActivityDto[] {
    return this._selectedActivities;
  }
  public set selectedActivities(value: ALCActivityDto[]) {
    this._selectedActivities = value;
    this.updateNavigationParams();
  }
  activityMenuLink: MenuLink;
  navigations: DropdownNavigationItem[] = [];

  constructor(
    private _router: Router,
    private _navigationService: WlmNavigationService,
    private _arrayHelperService: ArrayHelperService,
    private _dateHelperService: DateHelperService,
    private _navMenuBuilderHelperService: NavMenuBuilderHelperService
  ) {}

  ngOnInit(): void {
    this.updateNavigationParams();
    this._navigationService
      .generateMenuLinks([new NavItem(NavKeys.Activities, null)])
      .subscribe((navKeys) => {
        if (navKeys.length) {
          this.activityMenuLink = navKeys[0];
        }
      });
  }

  onGridLoaded(grid: GenericGridComponent): void {
    this.grid = grid;
  }

  getGridSettings(gridSettings: IGridSettings) {
    this.gridSettings = gridSettings;
  }

  navigate = (): void => {
    if (!this.selectedActivities.length) {
      return;
    }

    const startDate = this._arrayHelperService.sortArrayObjectCaseInsensitive(
      this.selectedActivities,
      'startDate'
    )[0]?.startDate as string;

    const endDate = this._arrayHelperService.sortArrayObjectCaseInsensitive(
      this.selectedActivities,
      'startDate',
      false
    )[0]?.startDate as string;

    const activityIds = this.selectedActivities.map((m) => m.activityId);

    const queryParams = {
      activityIds: activityIds,
      startDate: this._dateHelperService.toApiFormatNoUTC(new Date(startDate)),
      endDate: this._dateHelperService.toApiFormatNoUTC(new Date(endDate)),
      hierarchyFamilyId: this.campaign.hierarchyFamilyId,
    };

    this._router.navigate([this.activityMenuLink.urlPath], {
      queryParams: queryParams,
    });
  };

  private updateNavigationParams() {
    this.navigations = [];

    const navItemsConfiguration = this.getNavItemsConfigurations();
    const navMenuConfiguration = new NavMenuConfiguration({
      selectedElements: this.selectedActivities,
      items: navItemsConfiguration,
    });
    this._navMenuBuilderHelperService
      .buildMenu(navMenuConfiguration)
      .subscribe((menu) => (this.navigations = menu));
  }

  private getNavItemsConfigurations(): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.Activities,
        validationType: 'custom',
        customValidation: this.selectedActivities?.length === 0,
        paramType: 'none',
        customNavMethod: this.navigate,
      },
    ];
  }
}
