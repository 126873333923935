import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Params } from '@angular/router';
import { networkElementTypesNEConfigurationSet } from '@common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { TabDetailParameterName } from '@common-modules/dependencies/navigation/tab-detail-component';
import { ElementTypeFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/element-type-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { NeHeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { NeTypeFilterConfiguration } from './ne-type-filter-configuration';
import { INeHeFilterSettings } from './nehe-filter-settings';

const COMPONENT_SELECTOR = 'wlm-ne-type-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-type-filter.component.html',
  styleUrls: ['./ne-type-filter.component.scss'],
})
export class NeTypeFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _filterConfiguration: NeTypeFilterConfiguration;
  public get filterConfiguration(): NeTypeFilterConfiguration {
    return this._filterConfiguration;
  }
  public set filterConfiguration(value: NeTypeFilterConfiguration) {
    this._filterConfiguration = value;
    if (value) {
      this.setFiltersPersistencyArea();
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  private _filterGroupSettings: FilterGroupSettings;
  public get settings(): FilterGroupSettings {
    return this._filterGroupSettings;
  }
  @Input() public set settings(v: FilterGroupSettings) {
    this._filterGroupSettings = v;

    if (v) {
      if (v.navigationParams) {
        this.loadNavigationParams(v.navigationParams);
      }
      this.persistencyArea = v.persistencyArea;
      this.loadPersistence();
      this.setFilterConfiguration();
    }
  }

  @Output() clearAll = new EventEmitter<void>();

  selectedNETypeIds: any[] = [];
  filterListElements = networkElementTypesNEConfigurationSet;

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  loaded: boolean;
  familyIdSelected: string;

  neTypeFilterId = FilterAdapterEnum.NetworkElementTypeFilter;
  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableSelectAll: true,
    disableApplyFilters: false,
  };

  networkElementsGroupSettings = new BaseFilterSettings({
    ...this.baseFilterSettings,
    isRequired: true,
    inputLabelKey: `${this.T_SCOPE}.filters-group-ne-label`,
  });

  networkFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    id: this.neTypeFilterId,
  });

  constructor(persistencyService: PersistencyService) {
    super(persistencyService);
  }

  ngOnInit(): void {}

  provideAdapters = (): FilterAdapterSettings[] => {
    const neSettings = this.filterConfiguration.neSettings;

    const neAdapter = new ElementTypeFAdapter({
      dataBindingField: neSettings.elementTypeFieldName,
      elementTypeFieldName: neSettings.elementTypeFieldName,
      neheFilterSettings: new NeHeFISettings({
        isNEFilter: true,
        selectedIds: neSettings.selectedElementIds,
        fieldName: neSettings.elementTypeFieldName,
      }),
      tabDetailParameterName: TabDetailParameterName.networkElementTypeFilterSetting,
    });

    neAdapter.id = this.neTypeFilterId;

    return [neAdapter];
  };

  buildKeysToComplete(): void {
    this.keysToComplete = [this.filterConfiguration.neSettings.elementTypeFieldName];
  }

  processFilterChanges(results: FilterAdapterResult) {
    this.onGlobalFilterResults(results);
  }

  onClearAll(): void {
    this.clearAll.next();
  }

  protected setFiltersPersistencyArea(): void {
    this.networkElementsGroupSettings.persistencyArea = this.filterConfiguration.persistencyArea;
  }

  protected additionalAutoloadCheck(results: FilterAdapterResult): boolean {
    return true;
  }

  private loadNavigationParams(queryParams: Params) {
    this.selectedNETypeIds = queryParams.neTypeFilterId;
  }

  private setFilterConfiguration() {
    const neSettings = new INeHeFilterSettings(
      true,
      this.selectedNETypeIds,
      this.settings.fields.networkElementType.fieldName
    );

    const config = new NeTypeFilterConfiguration({
      persistencyArea: this.settings.persistencyArea,
      defaultNETypeIds: [],
      neSettings,
    });

    this.filterConfiguration = config;
  }

  private loadPersistence() {
    const persistedElement = this.getPersisted(
      this.settings.fields.networkElementType.fieldName,
      [],
      null,
      true
    );

    this.selectedNETypeIds = persistedElement?.value?.length
      ? [persistedElement.value[0]?.value]
      : [];
  }
}
