import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  networkElementTypesDVWizardSet,
  networkElementTypesMapping,
} from '@common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { NetworkElementWizardStepConfiguration } from '@common-modules/dependencies/shared/ne-wizard-step-configuration';
import { HierarchyElementPathFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { NeHeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FiltersAdapterService } from '@common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { TreeSettingsService } from '@common-modules/shared/services/tree-settings.service';
import { ElementTypeFAdapter } from '../../../dependencies/wlm-filters/adapters/element-type-f-adapter';
import { ITreeSettings } from '../../../dependencies/wlm-filters/hierarchy-tree-filter-settings';

const COMPONENT_SELECTOR = 'wlm-ne-selection-grid-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-selection-grid-filter.component.html',
  styleUrls: ['./ne-selection-grid-filter.component.scss'],
})
export class NeSelectionGridFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _neWizardStepConfiguration: NetworkElementWizardStepConfiguration;
  public get filterConfiguration(): NetworkElementWizardStepConfiguration {
    return this._neWizardStepConfiguration;
  }
  @Input() public set filterConfiguration(v: NetworkElementWizardStepConfiguration) {
    this._neWizardStepConfiguration = v;
    if (v) {
      this.neFilterItemSettings.storageLocation = v.storageLocation;
      this.hierarchyFamilyItemSettings.storageLocation = v.storageLocation;
      this.hierarchyTreeItemSettings.storageLocation = v.storageLocation;
      this.filterListElements = v.filterListElements ?? networkElementTypesDVWizardSet;

      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }

  @Output() isValid = new EventEmitter<boolean>();

  T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;
  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableApplyFilters: false,
    disableSelectAll: true,
  };
  bf1Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-1-label`,
    isRequired: true,
    disableErrorStyle: true,
    disableClear: true, // <--this settings is necessary for the reset to work properly
  };
  bf2Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-2-label`,
  };
  neFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
    allowEmptySelect: true,
  });
  hierarchyFamilyItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
    required: true,
  });
  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
  });

  treeSettings: ITreeSettings = null;
  hierarchyElementFamilyId: string;
  filterListElements: networkElementTypesMapping[];
  selectedNETypesTouched = false;
  mode = 'single';
  requiredFilters = ['networkElementTypeId', 'hierarchyElementId'];
  neSelectionFilterFormGroup: UntypedFormGroup;

  private _selectedNETypes: number[];
  public get selectedNETypes(): number[] {
    return this._selectedNETypes;
  }
  public set selectedNETypes(value: number[]) {
    this._selectedNETypes = value;
    this.isValid.emit(this.neTypesFilterIsValid);
  }

  public get neTypesFilterIsValid(): boolean {
    return this._selectedNETypes?.length > 0;
  }

  constructor(
    private _treeSettingsService: TreeSettingsService,
    private _adapterService: FiltersAdapterService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  ngOnInit(): void {
    this.buildTreeSettings();
    this.initializeSelectedNEType();
    this.setFiltersPersistencyArea();
  }

  initializeSelectedNEType() {
    this.selectedNETypes = this.filterConfiguration.initialNetworkElementTypeId;
  }

  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName = this.filterConfiguration.hierarchyElementFamilyFieldName;
    const defaultId = this.filterConfiguration.initialFamilyId;
    this.hierarchyElementFamilyId = this._adapterService.getFamilyId(
      filters,
      familyFieldName,
      defaultId
    );
  }

  onTreeFilterReady() {}

  buildTreeSettings(): void {
    this._treeSettingsService
      .buildTreeSettings()
      .subscribe((settings) => (this.treeSettings = settings));
  }

  oninitSaveInitialValues() {
    this.selectedNETypesTouched = true;
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const { networkElementTypeIdFieldName } = this.filterConfiguration;

    const { hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.filterConfiguration;
    const adapters = [
      new HierarchyElementPathFAdapter({
        dataBindingField: 'hierarchyElementsIds',
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId: this.hierarchyElementFamilyId,
      }),
      new ElementTypeFAdapter({
        dataBindingField: 'networkElementType',
        elementTypeFieldName: networkElementTypeIdFieldName,
        neheFilterSettings: new NeHeFISettings({
          isNEFilter: true,
          fieldName: networkElementTypeIdFieldName,
          selectedIds: this.selectedNETypes,
        }),
      }),
    ];
    return adapters;
  };

  setFiltersPersistencyArea() {
    this.bf1Settings.persistencyArea = this.filterConfiguration?.persistencyArea;
    this.bf2Settings.persistencyArea = this.filterConfiguration?.persistencyArea;
  }

  buildKeysToComplete() {
    const { hierarchyElementIdFieldName } = this.filterConfiguration;
    const { networkElementTypeIdFieldName } = this.filterConfiguration;

    this.keysToComplete = [hierarchyElementIdFieldName, networkElementTypeIdFieldName];
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }
}
