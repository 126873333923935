import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'wlm-tree-dropdown-type',
  templateUrl: './tree-dropdown-type.component.html',
  styleUrl: './tree-dropdown-type.component.scss',
})
export class TreeDropdownTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  private readonly _destroyRef = inject(DestroyRef);
  selectedKeys: string[] = [];

  ngOnInit(): void {
    this.setKeys(this.formControl.getRawValue());
    this.formControl.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((value) => {
      this.setKeys(value);
    });
  }

  onValueChanged(selectedKeys: string[]): void {
    this.formControl.setValue(selectedKeys);
    this.formControl.markAsDirty();
  }

  private setKeys(keys): void {
    if (!keys) {
      this.selectedKeys = [];
    }
    if (typeof keys === 'string') {
      this.selectedKeys = [keys];
    } else {
      this.selectedKeys = keys;
    }
  }
}
