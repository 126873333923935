import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { SchematicNode } from '@common-modules/wlm-charts/core/models/schematics/schematic';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

const COMPONENT_SELECTOR = 'wlm-schematic-search';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './schematic-search.component.html',
  styleUrls: ['./schematic-search.component.scss'],
})
export class SchematicSearchComponent implements OnInit {
  @Input() nodes: SchematicNode[] = [];
  @Output() optionSelected = new EventEmitter<SchematicNode>();

  T_SCOPE = `${AppModules.Schematics}.${COMPONENT_SELECTOR}`;

  searchControl = new UntypedFormControl('');
  filteredOptions: Observable<SchematicNode[]>;
  showResultsNotFound = false;

  constructor() {}

  ngOnInit() {
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : [];
      })
    );
  }

  onSelectOption(event: MatAutocompleteSelectedEvent) {
    this.optionSelected.emit(event.option.value);
  }

  displayFn(node: SchematicNode): string {
    return node && node.name ? node.name : '';
  }

  private _filter(value: string): SchematicNode[] {
    if (!this.nodes || value === undefined || value?.length === 0) {
      return [];
    }

    const filterValue = value.toLowerCase();

    const resultsFiltered = this.nodes?.filter(
      (f) => f.name.toLowerCase().includes(filterValue) || f.id.toLowerCase().includes(filterValue)
    );

    this.showResultsNotFound = filterValue.length && resultsFiltered.length === 0;

    return resultsFiltered;
  }
}
