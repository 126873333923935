import { Injectable, Injector } from '@angular/core';
import { ILeakageTypeFullDto } from '@common-modules/dependencies/alc/leakage-type-full.dto';
import { GenericCrudService } from '@common-modules/generic-crud/generic-crud.service';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable, map } from 'rxjs';

@Injectable()
export class LeakTypesCrudService
  extends BaseService
  implements GenericCrudService<ILeakageTypeFullDto, number>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/leak-types`;
  }

  getAll(): Observable<ILeakageTypeFullDto[]> {
    return this.httpCacheClient.get<ILeakageTypeFullDto[]>(this._entityUrl);
  }

  create(entity: ILeakageTypeFullDto): Observable<ILeakageTypeFullDto> {
    return this.httpCacheClient.post<ILeakageTypeFullDto>(this._entityUrl, entity);
  }

  update(entity: ILeakageTypeFullDto): Observable<ILeakageTypeFullDto> {
    return this.httpCacheClient.put<ILeakageTypeFullDto>(this._entityUrl, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  getId(entity: ILeakageTypeFullDto): number {
    return entity.leakageTypeId;
  }
}
