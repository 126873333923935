import { Component, Input, inject } from '@angular/core';
import { SignalConfigurationCategory } from '@common-modules/dependencies/signals/models/signal-configuration-category';
import { SignalConfigurationSubcategory } from '@common-modules/dependencies/signals/models/signal-configuration-subcategory';
import { SignalConfigurationDto } from '@common-modules/dependencies/signals/models/signal-configuration.dto';
import { SignalsService } from '@common-modules/dependencies/signals/signals.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { asEnumerable } from 'linq-es2015';
import { Subscription, finalize } from 'rxjs';

const COMPONENT_SELECTOR = 'wlm-data-visualization-item-details';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './data-visualization-item-details.component.html',
  styleUrls: ['./data-visualization-item-details.component.scss'],
})
export class DataVisualizationItemDetailsComponent {
  private _signalId: number;
  get signalId(): number {
    return this._signalId;
  }
  @Input() set signalId(value: number) {
    this._signalId = value;
    this.loadDetails();
  }

  readonly skeletonTheme = {
    height: '16px',
    margin: '0px',
  };

  details: SignalConfigurationDto[];
  isLoading = true;
  dataLoaded = false;
  detailsHash: { [category: string]: { [subcategory: string]: SignalConfigurationDto[] } } = {};
  private _loadDetailsSubs: Subscription;
  readonly T_SCOPE = `${AppModules.DataVisualization}.${COMPONENT_SELECTOR}`;
  private readonly _signalsService = inject(SignalsService);

  private _visibleCategories: { [category: string]: boolean } = {
    [SignalConfigurationCategory.Boundary]: true,
    [SignalConfigurationCategory.Pressure]: true,
    [SignalConfigurationCategory.LargeUserConsumption]: true,
    [SignalConfigurationCategory.SmartMeters]: true,

    [SignalConfigurationCategory.InletOutlet]: true,
    [SignalConfigurationCategory.Level]: true,
    [SignalConfigurationCategory.LarsSworps]: true,
  };
  readonly categoriesOrder: SignalConfigurationCategory[] = [
    SignalConfigurationCategory.Boundary,
    SignalConfigurationCategory.Pressure,
    SignalConfigurationCategory.LargeUserConsumption,
    SignalConfigurationCategory.SmartMeters,

    SignalConfigurationCategory.InletOutlet,
    SignalConfigurationCategory.Level,
    SignalConfigurationCategory.LarsSworps,
  ];
  readonly subcategoriesOrder: { [category: string]: SignalConfigurationSubcategory[] } = {
    [SignalConfigurationCategory.Boundary]: [
      SignalConfigurationSubcategory.BoundaryIn,
      SignalConfigurationSubcategory.BoundaryOut,
    ],
    [SignalConfigurationCategory.Pressure]: [
      SignalConfigurationSubcategory.AZP,
      SignalConfigurationSubcategory.CPP,
      SignalConfigurationSubcategory.MPP,
    ],
    [SignalConfigurationCategory.InletOutlet]: [
      SignalConfigurationSubcategory.Inlet,
      SignalConfigurationSubcategory.Outlet,
      SignalConfigurationSubcategory.NoBoundary,
    ],
    [SignalConfigurationCategory.LarsSworps]: [
      SignalConfigurationSubcategory.LarsAddition,
      SignalConfigurationSubcategory.LarsSubtraction,
      SignalConfigurationSubcategory.SworpsAddition,
      SignalConfigurationSubcategory.SworpsSubtraction,
    ],
  };

  getKeys = (items: { [subcategory: string]: SignalConfigurationDto[] }): string[] =>
    Object.keys(items);

  private loadDetails(): void {
    if (!this.signalId) {
      this.details = [];
      this.dataLoaded = false;
      return;
    }

    this.isLoading = true;
    this._loadDetailsSubs?.unsubscribe();
    this._loadDetailsSubs = this._signalsService
      .getSignalsConfigurationDetails(this.signalId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((details) => {
        this.details = details;
        this.processDetails(details);
        this.dataLoaded = true;
      });
  }

  private processDetails(details: SignalConfigurationDto[]): void {
    const visibleDetails = details.filter((detail) => this._visibleCategories[detail.category]);
    const groupByCategories = asEnumerable(visibleDetails)
      .GroupBy((detail) => detail.category)
      .ToDictionary<SignalConfigurationCategory, SignalConfigurationDto[]>((group) => group.key);

    this.detailsHash = {};
    for (let category of groupByCategories.keys()) {
      const categoryGroup = groupByCategories.get(category);
      const subcategoryGroup = asEnumerable(categoryGroup)
        .GroupBy((item) => item.subcategory ?? SignalConfigurationSubcategory.Default)
        .ToDictionary<SignalConfigurationSubcategory, SignalConfigurationDto[]>(
          (group) => group.key
        );

      this.detailsHash[category] = globalUtilsHelper.mapToPlainObject(subcategoryGroup);
    }
  }
}
