import { Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { INetworkElementTypeDto } from '@common-modules/dependencies/ne/network-element-type.dto';
import { SharedConstantsService } from '@common-modules/shared/constants/shared-constants.service';
import { IntegrableForm, IntegrableFormParams } from '@common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { SelectOption } from '@common-modules/shared/model/shared/select-option';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { map, Observable, startWith } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'wlm-ne-type-form',
  templateUrl: './ne-type-form.component.html',
  styleUrls: ['./ne-type-form.component.scss'],
})
export class NeTypeFormComponent extends IntegrableForm {
  @Input() initialModel: INetworkElementTypeDto = null;
  model: INetworkElementTypeDto;

  private readonly _destroyRef = inject(DestroyRef);
  private readonly _sharedConstants = inject(SharedConstantsService);

  ngOnInit(): void {
    this._sharedConstants.getTransmissionCategoriesMapping().subscribe();
  }

  onModelChange(model: INetworkElementTypeDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'networkElementTypeId',
          type: 'input',
          className: 'col-6',
          props: {
            type: 'number',
            label: 'Network Element Type Id',
            required: true,
          },
          expressions: {
            'props.disabled': (data) => this.formOptions.formState.op === 'update',
          },
        },
        {
          key: 'networkElementTypeName',
          type: 'input',
          className: 'col-6',
          props: {
            type: 'text',
            label: 'Network Element Type Name',
            required: true,
          },
        },
        {
          key: 'isZone',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Zone',
            change: (field) => {
              if (field.model.isZone === true) {
                field.form.get('iconPath').setValue('POLYGON');
              } else {
                field.form.get('iconPath').setValue('');
              }
            },
          },
        },
        {
          key: 'isNetworkElement',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Network Element',
            required: true,
          },
        },
        {
          key: 'isGisElement',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is GIS Element',
            required: true,
          },
        },
        {
          key: 'isTransmission',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Transmission',
            required: true,
          },
          hooks: {
            onInit: (field) => {
              const categoriesCtrl = this.getCategoriesCtrl(field);
              const initialCategories = categoriesCtrl.getRawValue();

              if (
                initialCategories === null ||
                typeof initialCategories === 'undefined' ||
                !initialCategories.length
              ) {
                const initialValue = field.formControl.getRawValue();
                this.updateCategoriesOnTransmissionChange(initialValue, field);
              }

              field.formControl.valueChanges
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((value) => {
                  this.updateCategoriesOnTransmissionChange(value, field);
                });
            },
          },
        },
        {
          key: 'isConfigurable',
          type: 'checkbox',
          className: 'col-4 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Configurable',
            required: true,
          },
        },
        {
          key: 'categories',
          type: 'chips-selector',
          className: 'col-12',
          props: {
            label: 'Categories',
            required: true,
            height: '80px',
            options: this.getTransmissionCategories$(),
          },
        },
      ],
    },
    {
      wrappers: ['section'],
      props: { label: 'Map Attributes' },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'gisLayerId',
          type: 'input',
          className: 'col-5',
          props: {
            type: 'number',
            label: 'GIS Layer Id',
            disabled: true,
          },
          hooks: {
            onInit: (field) =>
              this.syncWithField(field, 'networkElementTypeId', (field) => field.parent.parent),
          },
        },
        {
          key: 'description',
          type: 'input',
          className: 'col-5',
          props: {
            label: 'GIS Layer Description',
            disabled: true,
          },
          hooks: {
            onInit: (field) =>
              this.syncWithField(field, 'networkElementTypeName', (field) => field.parent.parent),
          },
        },
        {
          key: 'gisOrder',
          type: 'input',
          className: 'col-2',
          props: {
            type: 'number',
            label: 'GIS Order',
          },
        },
        {
          key: 'iconPath',
          type: 'svg-editor',
          className: 'col-12',
          props: {
            label: 'Icon',
            previewLabel: 'Icon Preview',
            previewPlaceholder: 'Introduce valid SVG content',
          },
          validators: {
            validation: [{ name: 'notSvg' }],
          },
          hooks: {
            onInit: (field) => {
              field.formControl.valueChanges.subscribe((value) => {
                if (value === 'POLYGON') {
                  field.props.disabled = true;
                  field.props.additionalAllowedValues = value;
                } else {
                  field.props.disabled = false;
                }
              });
            },
          },
        },
      ],
    },
  ];

  private syncWithField(
    field: FormlyFieldConfig,
    sourceFieldName: string,
    getSourceFieldParent?: (field: FormlyFieldConfig) => FormlyFieldConfig
  ): void {
    const sourceForm = getSourceFieldParent ? getSourceFieldParent(field) : field.parent;
    const sourceFormControl = sourceForm.get(sourceFieldName).formControl;

    sourceFormControl.valueChanges
      .pipe(untilDestroyed(this), startWith(sourceFormControl.value))
      .subscribe((value) => {
        field.formControl.setValue(value);
      });
  }

  private updateCategoriesOnTransmissionChange(isTransmission: boolean, field): void {
    const categoriesCtrl = this.getCategoriesCtrl(field);

    if (isTransmission) {
      categoriesCtrl.enable();
      this.getTransmissionCategories$().subscribe((categories) => {
        categoriesCtrl.setValue(categories.map((category) => category.value));
      });
    } else {
      categoriesCtrl.setValue([]);
      categoriesCtrl.disable();
    }
  }

  private getCategoriesCtrl = (field): FormControl => field.form.controls['categories'];

  private getTransmissionCategories$(): Observable<SelectOption<string>[]> {
    return this._sharedConstants.getTransmissionCategoriesMapping().pipe(
      map((mapping) =>
        Array.from(mapping.entries()).map(
          (entry) =>
            new SelectOption<string>({
              value: entry[0],
              label: entry[1],
            })
        )
      )
    );
  }
}
