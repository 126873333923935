import { Component, Input, inject } from '@angular/core';
import { IntegrableForm, IntegrableFormParams } from '@common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { IBillingClassDto } from '../../../shared/model/customer/billing-class.dto';

@Component({
  selector: 'wlm-activity-types-form',
  templateUrl: './activity-types-form.component.html',
  styleUrls: ['./activity-types-form.component.scss'],
})
export class ActivityTypesFormComponent extends IntegrableForm {
  @Input() initialModel: IBillingClassDto;
  model: IBillingClassDto;

  private readonly _globalsService = inject(GlobalsService);

  onModelChange(model: IBillingClassDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'activityTypeId',
          type: 'input',
          className: 'col-3',
          props: {
            type: 'number',
            label: 'Activity Type Id',
            required: true,
          },
        },
        {
          key: 'activityTypeName',
          type: 'input',
          className: 'col-3',
          props: {
            label: 'Activity Type Name',
            required: true,
          },
        },
        {
          key: 'shortCode',
          type: 'input',
          className: 'col-3',
          props: {
            label: 'Short Code',
            required: true,
          },
        },
        {
          key: 'defaultDurationHours',
          type: 'input',
          className: 'col-3',
          props: {
            type: 'number',
            label: 'Default Duration Hours',
          },
        },
        {
          key: 'defaultReasonForElegibility',
          type: 'select',
          className: 'col-3',
          props: {
            label: 'Default Reason For Elegibility',
            valueProp: 'reasonForEligibleChangeId',
            labelProp: 'reasonForEligibleChangeDescription',
            options: this._globalsService.getAllReasons(),
          },
        },
        {
          key: 'isRelatedToDma',
          type: 'checkbox',
          className: 'col-3 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Related to DMA?',
            required: true,
          },
        },
        {
          key: 'isAutoCalculated',
          type: 'checkbox',
          className: 'col-3 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Auto Calculated?',
          },
        },
        {
          key: 'isMainActivity',
          type: 'checkbox',
          className: 'col-3 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Main Activity?',
          },
        },
        {
          key: 'isDefaultDetectionActivity',
          type: 'checkbox',
          className: 'col-3 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Default Detection Activity?',
          },
        },
        {
          key: 'isDetectionActivity',
          type: 'checkbox',
          className: 'col-3 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Detection Activity?',
          },
        },
        {
          key: 'isRepairActivity',
          type: 'checkbox',
          className: 'col-3 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Repair Activity?',
          },
        },
        {
          key: 'isActivityRaising',
          type: 'checkbox',
          className: 'col-3 formly-field-no-border',
          defaultValue: false,
          props: {
            label: 'Is Activity Raising?',
          },
        },
      ],
    },
  ];
}
