import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { HierarchyElementFiltersDataDto } from '@common-modules/dependencies/he/hierarchy-element-filters-data.dto';
import { IHierarchyElementTypes } from '@common-modules/dependencies/he/hierarchy.constants';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { NavKeys } from '@common-modules/dependencies/navigation/nav-keys.enum';
import {
  TabDetailPanelParameters,
  TabDetailPanelSettings,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { WNavigateSettings } from '@common-modules/dependencies/navigation/w-navigate-by';
import { FilterGroupFieldSettings } from '@common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { HierarchyElementPathFilter } from '@common-modules/dependencies/wlm-filters/i-filters/hierarchy-element-path-filter';
import { NeSignalSelectionPopupConfiguration } from '@common-modules/shared-component/ne-signal-selection/ne-signal-selection-popup-configuration';
import { NeSignalSelectionPopupResult } from '@common-modules/shared-component/ne-signal-selection/ne-signal-selection-popup-result';
import { NeSignalSelectionPopupComponent } from '@common-modules/shared-component/ne-signal-selection/ne-signal-selection-popup/ne-signal-selection-popup.component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { WlmElementExtended } from '@common-modules/shared/charts/model/elements/element-extended';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { SignalSelectionPopupDimensions } from '@common-modules/shared/constants/dimensions.constants';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { GridSettingsService } from '@common-modules/shared/core/grid/grid-settings.service';
import { LabelValueListItem } from '@common-modules/shared/core/label-value-list/label-value-list-item';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { GridBtnsEvent } from '@common-modules/shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from '@common-modules/shared/grid-buttons/models/grid-btns-options.enum';
import { NavMenuBuilderHelperService } from '@common-modules/shared/helpers/navmenu-builder-helper.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { TimeAggregationEnum } from '@common-modules/shared/model/algorithm/time-aggregation.enum';
import { WLMDialogResult } from '@common-modules/shared/model/dialog/wlm-dialog-result';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { DimensionTypesEnum } from '@common-modules/shared/model/shared/dimension-types';
import { MapperFunctions } from '@common-modules/shared/services/generic-mappers/mapper-functions';
import { ToUomMapperParams } from '@common-modules/shared/services/generic-mappers/mapper-params/to-uom-mapper-params';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { EntityTypes } from '@common-modules/wlm-charts/core/models/entity-types';
import { GridPersistedElements } from '@common-modules/wlm-grid/generic-grid/generic-grid-constants';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { Observable, ReplaySubject, Subject, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrioritisationGridFilterValidations } from '../../alc/prioritisation-grid/prioritisation-grid-filter-validations';
import { DataVisualizationNavigationService } from '../../data-visualization-shared/services/data-visualization-navigation.service';
import { MapHelperService } from '../../map/map-helper.service';
import { MapParameters } from '../../map/map-parameters';
import { MapRelatedComponent } from '../../map/map-related/map-related.component';
import { NavigationElement } from '../../map/navigation-element';
import { NavigationElementType } from '../../map/navigation-element-type';
import { CustomerPremisesViewDto } from '../../shared/model/customer/customer-premises-view.dto';
import { NavItemsConfiguration } from '../../shared/model/navigation/navitem-configuration';
import { NavMenuConfiguration } from '../../shared/model/navigation/navmenu-configuration';
import { LargeUserAssignedDto } from '../../shared/model/signals/large-user-assigned.dto';
import { LargeUserCreationResponseDto } from '../../shared/model/signals/large-user-creation-response.dto';
import { CustomerDetailSingleRpWidgetComponent } from '../customer-detail-single-rp-widget/customer-detail-single-rp-widget.component';
import { CustomerDetailsService } from '../services/customer-details.service';

const COMPONENT_SELECTOR = 'wlm-customer-details-page';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './customer-details-page.component.html',
  styleUrls: ['./customer-details-page.component.scss'],
})
export class CustomerDetailsPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  private _genericGrid;
  public get genericGrid() {
    return this._genericGrid;
  }
  @ViewChild(GenericGridComponent) public set genericGrid(value) {
    this._genericGrid = value;
    if (value) {
      this.setGridSettingsReadySuscription();
    }
  }

  T_SCOPE = `${AppModules.Customer}.${COMPONENT_SELECTOR}`;

  public get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }
  public get persistencyArea(): string {
    return this.pageCrud;
  }

  public get pageCrud(): string {
    return 'WLMCustomerDetailsCrud';
  }

  private _navigations: DropdownNavigationItem[];
  public get navigations(): DropdownNavigationItem[] {
    return this._navigations;
  }

  public set navigations(v: DropdownNavigationItem[]) {
    this._navigations = v;
  }

  private _selectedCustomers: CustomerPremisesViewDto[];
  public get selectedCustomers(): CustomerPremisesViewDto[] {
    return this._selectedCustomers;
  }
  public set selectedCustomers(value: CustomerPremisesViewDto[]) {
    this._selectedCustomers = value;
    this.updateNavigationParams();
  }

  gridSettings: GridSetting;
  gridFilters: DataBindingFilters;
  params: Params;

  // Filters fields names
  hierarchyElementIdFieldName = 'hierarchyElementId';
  hierarchyElementFamilyFieldName = 'hierarchyFamilyId';
  hierarchyElementTypeFieldName = 'hierarchyElementTypeId';
  filterSettings: FilterGroupSettings;

  hierarchyElementIdsFilterField = 'hierarchyElementsIds';

  filterFields = [this.hierarchyElementIdsFilterField];

  gridName = 'CustomerDetails';
  canLoad: boolean;
  autoLoad: boolean;
  selectedCustomer: CustomerPremisesViewDto;
  gridFiltersForBinding: DataBindingFilters;
  filterDetailsParameters: TabDetailPanelParameters;
  clearAll$ = new Subject<void>();
  persistFilters$ = new ReplaySubject<void>();
  gridSettingsReady$ = new ReplaySubject<GridSetting>();
  hasBeenSelected = false;
  removeSelectionPersisted$ = new ReplaySubject<void>();
  removeSelection$ = new ReplaySubject<void>();

  applyConditionalSorting = false;
  conditionalSorting: SortDescriptor[] = [{ field: 'premisId', dir: 'asc' }];

  gridSettingsSubscription$: Subscription;

  private _isPointOfInterestFieldName = 'isPointOfInterest';
  private _dimensionTypeIdFieldName = 'dimensionTypeId';
  private _availableSignalsServiceName = 'LargeUserAvailableSignalsService';

  private _oDataFilterForSignalPopup: Map<string, any>;
  private readonly _rightPanelAttributes: LabelValueListItem[] = [
    {
      label: this.translateField('hierarchyElementId'),
      propertyFn: (model: CustomerPremisesViewDto) => model.hierarchyElementId,
    },
    {
      label: this.translateField('propertyBillingClass'),
      propertyFn: (model: CustomerPremisesViewDto) =>
        this._globalsService
          .getBillingClasses()
          .pipe(
            map(
              (billingClasses) =>
                billingClasses.find((bc) => bc.billingClassId === model.billingClass)
                  ?.billingClassName
            )
          ),
    },
    {
      label: this.translateField('propertyAverageConsumption'),
      propertyFn: (model: CustomerPremisesViewDto) => model.averageConsumption,
      mappers: [MapperFunctions.ToUoM],
      mappersParams: {
        [MapperFunctions.ToUoM]: {
          dimensionTypeId: DimensionTypesEnum.Flow,
          timeAggregationId: TimeAggregationEnum.Daily,
          hierarchyElementTypeId: IHierarchyElementTypes.Customer,
        } as ToUomMapperParams,
      },
    },
  ];

  private readonly _settingArea = 'MapSetting';
  private readonly _settingKey = `${this.persistencyArea}_MapFilter`;
  private readonly _commonCustomerLayers = [1, 13, 1000];

  constructor(
    private _gridService: GridSettingsService,
    private _customerService: CustomerDetailsService,
    private _spinnerService: SpinnerService,
    private _signalsPopup: MatDialog,
    private _dialogService: DialogService,
    private _localization: LocalizationHelperService,
    private _navMenuBuilderHelperService: NavMenuBuilderHelperService,
    private _route: ActivatedRoute,
    private _globalsService: GlobalsService,
    private _mapHelperService: MapHelperService,
    private readonly _dataVisualizationNavigationService: DataVisualizationNavigationService
  ) {
    super();
  }

  ngOnInit(): void {
    this._route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      super.ngOnInit();

      const queryParams = params ? this.getQueryParams(params) : null;
      this.readNavigationParameters(queryParams)
        .pipe(untilDestroyed(this))
        .subscribe((filterData) => {
          let params = { ...queryParams };

          if (filterData?.hierarchyFamilyId) {
            params[this.hierarchyElementFamilyFieldName] = filterData.hierarchyFamilyId;
          }

          this.params = params as Params;

          this.setFilterSettings(params);
          this.updateNavigationParams();
          this.initGridSettings();
          this.setOdataFiltersForSignalPopup();
          this.setRightPanel();
          this.loadGridByParams();
        });
    });
  }

  getPropertyIdFilter(propertyId: string): FilterDescriptor[] {
    const filter = { field: 'premisId', operator: 'eq', value: propertyId };
    return [filter];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  getSelectedCustomer(selectedCustomer: any) {
    this.hasBeenSelected = selectedCustomer != null;
    this.selectedCustomer = selectedCustomer;
    this.sendParameters(selectedCustomer, this.filterDetailsParameters);
  }

  updateNavigationParams() {
    this.navigations = [];

    const navItemsConfiguration = this.getNavItemsConfigurations();
    const navMenuConfiguration = new NavMenuConfiguration({
      selectedElements: this.selectedCustomers,
      items: navItemsConfiguration,
    });
    this._navMenuBuilderHelperService
      .buildMenu(navMenuConfiguration)
      .subscribe((menu) => (this.navigations = menu));
  }

  readNavigationParameters(params: Params): Observable<HierarchyElementFiltersDataDto> {
    const hierarchyElementId =
      typeof params?.hierarchyElementIds === 'string'
        ? params?.hierarchyElementIds
        : params?.hierarchyElementIds?.[0];

    const hierarchyFamilyId = params?.hierarchyFamilyId;

    if (hierarchyElementId && !hierarchyFamilyId) {
      return this._globalsService.getHeFiltersDataById(hierarchyElementId);
    }

    return of(null);
  }

  /**
   * Captures all the click events of all the buttons and associate them to the correct callbacks.
   */
  onClickGridBtns(event: GridBtnsEvent): void {
    switch (event.btn) {
      case GridBtnsOptions.ClearFilters:
        this.onClearAllFilters();
    }
  }

  onClearAllFilters(): void {
    this.clearAll$.next();
  }

  getDataBindingFilters(filtersParameters: DataBindingFilters) {
    const filterValidation = new PrioritisationGridFilterValidations();

    if (
      filtersParameters &&
      filterValidation.validateFilters(this.filterFields, filtersParameters)
    ) {
      this.gridFilters = filtersParameters;
      this.canLoad = true;
      this.persistFilters$.next();
    } else {
      this.canLoad = false;
    }
  }

  getFiltersDetailsParameters(filtersDetailsParameters: TabDetailPanelParameters) {
    this.filterDetailsParameters = filtersDetailsParameters;
    if (this.autoLoad && this.canLoad) {
      this.loadGrid();
      this.autoLoad = false;
    }
  }

  onApplyFilters(gridFiltersRef?: any): void {
    this.loadGrid(gridFiltersRef);
  }

  onValidityChange(valid: boolean): void {
    this.canLoad = valid;
  }

  onCheckAutoload(): void {
    if (this.gridSettingsSubscription$ && !this.gridSettingsSubscription$.closed) {
      this.gridSettingsSubscription$.unsubscribe();
    }
    this.gridSettingsSubscription$ = this.gridSettingsReady$
      .pipe(untilDestroyed(this))
      .subscribe((settings) => {
        if (!settings.disableAutoLoad && this.gridFilters?.filters) {
          this.loadGrid();
        }
      });
  }

  showSetCustomerAsLargeUserConfirmWindow() {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.confirm-create-large-user`,
    });

    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.setCustomerAsLargeUser();
        }
      });
  }

  showSetCustomerAsRegularConfirmWindow() {
    const dialogSettings = new WlmDialogSettings({
      translateKey: `${this.T_SCOPE}.messages.confirm-delete-large-user`,
    });

    this._dialogService
      .showTranslatedDialogMessage(dialogSettings)
      .subscribe((dialogRef: WLMDialogResult) => {
        if (dialogRef.result) {
          this.setCustomerAsRegular();
        }
      });
  }

  canLeavePage(): Observable<boolean> {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    this._mapHelperService.persistMapSettings(
      persistedMapsettings,
      this._settingArea,
      this._settingKey
    );

    return of(true);
  }

  onManageTemplatesAndWorkspaces = (navigateSettings: WNavigateSettings): void => {
    const elements = this.selectedCustomers.map(
      (x) =>
        new WlmElementExtended(
          x.hierarchyElementId,
          x.hierarchyElementTypeId,
          x.hierarchyElementName,
          null,
          null,
          EntityTypes.hierarchyElement
        )
    );

    this._dataVisualizationNavigationService.openManageTemplatePopupAndNavigate(
      navigateSettings,
      elements
    );
  };

  private reloadGrid() {
    this.genericGrid?.reloadGrid();
  }

  private setCustomerAsLargeUser() {
    this._spinnerService.setLoading(true, this.pageCrud);
    this._customerService
      .saveLargeUser(this.selectedCustomer.customerId, this.selectedCustomer.hierarchyElementId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (luCreationResponse) => {
          this._spinnerService.setLoading(false, this.pageCrud);
          this.configureNewLargeUserSignals(luCreationResponse);
          this.showSuccessCreateLuDialog();
        },
        error: (error) => {
          this._spinnerService.setLoading(false, this.pageCrud);
          this.showErrorCreateLuDialog();
        },
      });
  }

  private configureNewLargeUserSignals(luRes: LargeUserCreationResponseDto) {
    this.openSignalSelectionPopup(luRes).subscribe({
      next: (res) => {
        if (res) {
          this._customerService.saveLargeUserConfiguration(res).subscribe({
            next: (res) => {
              this.showSuccessLuConfigurationDialog();
            },
            error: (error) => {
              this.showErrorLuConfigurationDialog();
            },
          });
        }
        this.reloadGrid();
      },
    });
  }

  private setCustomerAsRegular() {
    this._spinnerService.setLoading(true, this.pageCrud);
    this._customerService
      .deleteLargeUser(this.selectedCustomer.customerId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          this._spinnerService.setLoading(false, this.pageCrud);
          this.showSuccessDeleteLuDialog();
          this.reloadGrid();
        },
        error: (error) => {
          this._spinnerService.setLoading(false, this.pageCrud);
          this.showErrorDeleteLuDialog();
        },
      });
  }

  private openSignalSelectionPopup(
    luResponse: LargeUserCreationResponseDto
  ): Observable<LargeUserAssignedDto[]> {
    const dialogConfig = this.getPopupDefinition();
    dialogConfig.data = this.getPopupConfigurationCustomer(
      this.selectedCustomer,
      luResponse.existingConfiguration
    );

    return this._signalsPopup
      .open(NeSignalSelectionPopupComponent, dialogConfig)
      .afterClosed()
      .pipe(untilDestroyed(this))
      .pipe(
        map((value) => {
          const result = value as NeSignalSelectionPopupResult;
          if (result.hasToSave && result.newConfiguration?.length) {
            const popupResult = new LargeUserAssignedDto({
              propertyId: this.selectedCustomer.premisId,
              hierarchyElementId: this.selectedCustomer.hierarchyElementId,
              hierarchyElementName: this.selectedCustomer.hierarchyElementName,
              customerId: this.selectedCustomer.customerId,
              largeUserId: luResponse.largeUserId,
              organizationName: this.selectedCustomer.organizationName,
              signals: result.newConfiguration,
            });

            return [popupResult, ...luResponse.existingConfiguration];
          } else {
            return null;
          }
        })
      );
  }

  private getPopupConfigurationCustomer(
    customer: CustomerPremisesViewDto,
    existingConfiguration: LargeUserAssignedDto[]
  ): any {
    const excludeSignals = existingConfiguration?.length
      ? existingConfiguration
          .map((configuration) => configuration.signals)
          .reduce((combined, current) => combined.concat(current), [])
      : [];

    return new NeSignalSelectionPopupConfiguration({
      currentConfiguration: [],
      excludedConfiguration: excludeSignals,
      oDataService: this._availableSignalsServiceName,
      queryFieldNames: [],
      selectedItem: null,
      title: customer.premisId,
      oDataFilters: this._oDataFilterForSignalPopup,
      showSaveButton: true,
    });
  }

  private getPopupDefinition() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = SignalSelectionPopupDimensions.Width;
    dialogConfig.height = SignalSelectionPopupDimensions.Height;
    return dialogConfig;
  }

  private loadGrid(gridFiltersRef?: any) {
    const newGridFilters = new DataBindingFilters();
    if (gridFiltersRef && gridFiltersRef.filters) {
      newGridFilters.filters = gridFiltersRef.filters;
    } else if (this.gridFilters && this.gridFilters.filters) {
      newGridFilters.filters = this.gridFilters.filters;
    }

    if (newGridFilters?.filters?.size > 0) {
      //We apply a conditional sort due to performance issues with the default sorting
      this.applyConditionalSorting = !this.isFilteredByHierarchy(newGridFilters);
      this.gridFiltersForBinding = newGridFilters;
    }
  }

  private isFilteredByHierarchy(gridFilters: DataBindingFilters): boolean {
    const hePathFilter = gridFilters.filters.get(
      this.hierarchyElementIdsFilterField
    ) as HierarchyElementPathFilter;

    return !!hePathFilter?.elementIds?.length;
  }

  private setFilterSettings(params: Params) {
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      hierarchyElementId: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementIdFieldName,
      }),
      hierarchyElementFamilyId: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementFamilyFieldName,
      }),
      hierarchyElementTypes: new FilterGroupFieldSettings({
        fieldName: this.hierarchyElementTypeFieldName,
      }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      navigationParams: params,
      persistencyArea: this.persistencyArea,
      avoidPersistency: params?.propertyId ? true : false,
    });
  }

  private initGridSettings() {
    if (this.gridName) {
      const persistedSettings = this.getPersisted(
        GridPersistedElements.Settings,
        undefined
      ) as GridSetting;

      if (persistedSettings) {
        this.setGridSettings(persistedSettings);
      } else {
        this._gridService.getGridSettingsByName(this.gridName).subscribe({
          next: (gridSettings) => {
            if (gridSettings) {
              this.setGridSettings(gridSettings);
            }
          },
        });
      }
    }
  }

  private setGridSettings(gridSettings: GridSetting) {
    this.gridSettings = gridSettings;

    this.gridSettingsReady$.next(this.gridSettings);
    this.gridSettingsReady$.complete();
  }

  private setOdataFiltersForSignalPopup() {
    this._oDataFilterForSignalPopup = new Map<string, any>();
    this._oDataFilterForSignalPopup.set(this._isPointOfInterestFieldName, true);
    this._oDataFilterForSignalPopup.set(this._dimensionTypeIdFieldName, 1);
  }

  private sendParameters(
    selectedElement: CustomerPremisesViewDto,
    filter: TabDetailPanelParameters
  ) {
    this._localization.get(`${this.T_SCOPE}.right-panel.pills`).subscribe((ts) => {
      const parameters = filter ?? new TabDetailPanelParameters();
      const propertyIdLabel = ts['property-id'];
      parameters.addParameter(TabDetailParameterName.customer, selectedElement);

      const title = selectedElement ? `${propertyIdLabel}: ${selectedElement?.premisId}` : '';

      parameters.addParameter(TabDetailParameterName.elementName, title);

      parameters.addParameter(
        TabDetailParameterName.titleSidePills,
        selectedElement?.isLargeUser ? [ts['large-user']] : []
      );
      // This will not change independently of the selectedElement.
      parameters.addParameter(TabDetailParameterName.topAttributes, this._rightPanelAttributes);
      parameters.addParameter(TabDetailParameterName.topValues, selectedElement);
      parameters.addParameter(TabDetailParameterName.containerClass, 'customer-details-container');

      const mapParameters = this.initializeMapParameters();
      if (this.hasBeenSelected) {
        mapParameters.navigationParam = selectedElement?.customerId
          ? new NavigationElement({
              elementId: selectedElement?.customerId,
              type: NavigationElementType.Customers,
            })
          : null;
        mapParameters.visibleLayersIds = [
          ...new Set([...mapParameters.visibleLayersIds, ...this._commonCustomerLayers]),
        ];
      }
      parameters.addParameter(TabDetailParameterName.mapParameters, mapParameters);

      this.rightPanelService.setTabParameters(parameters);
    });
  }

  private initializeMapParameters() {
    const persistedMapsettings = this.getPersistedData(this._settingKey, null, true, true);

    const mapParameters = MapParameters.getparameter({
      visibleLayersIds: persistedMapsettings ? persistedMapsettings?.visibleLayersIds : null,
      leakYears: persistedMapsettings ? persistedMapsettings?.leakYears : null,
      visibleThematicsIds: persistedMapsettings ? persistedMapsettings?.visibleThematicsIds : null,
      showFilters: true,
      settingArea: this._settingArea,
      settingKey: this._settingKey,
      loadFromPersistency: persistedMapsettings === null,
    });

    return mapParameters;
  }

  private showErrorDeleteLuDialog() {
    const dialogSettings = this.setDialogSettingsConfiguration('messages.delete-error', 'error');
    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }

  private showSuccessDeleteLuDialog() {
    const dialogSettings = this.setDialogSettingsConfiguration(
      'messages.delete-success',
      'success'
    );
    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }

  private showErrorCreateLuDialog() {
    const dialogSettings = this.setDialogSettingsConfiguration('messages.save-error', 'error');
    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }

  private showSuccessCreateLuDialog() {
    const dialogSettings = this.setDialogSettingsConfiguration('messages.save-success', 'success');
    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }

  private showErrorLuConfigurationDialog() {
    const dialogSettings = this.setDialogSettingsConfiguration(
      'messages.save-lu-configuration-error',
      'error'
    );
    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }

  private showSuccessLuConfigurationDialog() {
    const dialogSettings = this.setDialogSettingsConfiguration(
      'messages.save-lu-configuration-success',
      'success'
    );
    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }

  private setRightPanel(): void {
    this._localization.get(`${this.T_SCOPE}.widgets`).subscribe((ts) => {
      const panelSettings = new TabDetailPanelSettings();
      panelSettings.addComponent(
        CustomerDetailSingleRpWidgetComponent,
        ts['customer-details-single']
      );
      panelSettings.addComponent(MapRelatedComponent, ts.map);
      this.rightPanelService.setTabSettings(panelSettings);
    });
  }

  private setDialogSettingsConfiguration(
    messageKey: string,
    messageType: 'success' | 'error'
  ): WlmDialogSettings {
    const errorMessageKey = `${this.T_SCOPE}.${messageKey}`;
    const dialogSettings = new WlmDialogSettings({
      translateKey: errorMessageKey,
      icon: messageType,
    });
    return dialogSettings;
  }

  private translateField(key: string): string {
    const finalKey = [this.T_SCOPE, 'fields', key].join('.');
    return finalKey;
  }

  private getNavItemsConfigurations(): NavItemsConfiguration[] {
    return [
      {
        key: NavKeys.Map,
        validationType: 'custom',
        customValidation: this.selectedCustomers?.length !== 1,
        customtooltip: this._navMenuBuilderHelperService.getNavDisabledTooltipMessageByMaxZones(),
        paramType: 'custom',
        customParamsKey: ['customerId'],
      },
      {
        key: NavKeys.DataVisualization,
        validationType: 'max-zones',
        paramType: 'he-family',
        customNavMethod: this.onManageTemplatesAndWorkspaces,
      },
    ];
  }

  private setGridSettingsReadySuscription() {
    this.gridSettingsReady$.subscribe(() => {
      this.loadGridByParams();
    });
  }

  private loadGridByParams() {
    if (!this.genericGrid) {
      return;
    }

    if (this.params?.propertyId || this.params?.hierarchyElementIds) {
      this.genericGrid.clearFilters();
      if (this.params?.propertyId) {
        this.genericGrid.applyColumnFilter(this.getPropertyIdFilter(this.params?.propertyId));
      }
    }
  }
}
