import { Component, Injector, inject } from '@angular/core';
import { GenericCrudSettings } from '@common-modules/generic-crud/generic-crud-settings';
import { AppAttributesFormComponent } from '../app-attributes-form/app-attributes-form.component';

@Component({
  selector: 'wlm-app-attributes-crud',
  templateUrl: './app-attributes-crud.component.html',
  styleUrls: ['./app-attributes-crud.component.scss'],
})
export class AppAttributesCrudComponent {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'AppAttributesCrudService',
      grid: {
        gridSettingsName: 'ApplicationAttributesCrud',
      },
      update: {
        formComponent: AppAttributesFormComponent,
      },
    });
  }
}
