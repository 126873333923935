import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonFiltersModule } from '@common-modules/common-filters/common-filters.module';
import { CommonSharedComponentModule } from '@common-modules/shared-component/common-shared-component.module';
import { WLMGridModule } from '@common-modules/wlm-grid/wlm-grid.module';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationFormComponent } from '../shared/notification/notification-form/notification-form.component';
import { NotificationPopupComponent } from '../shared/notification/notification-popup/notification-popup.component';
import { WlmSharedModule } from '../shared/wlm-shared.module';
import { WlmFiltersModule } from '../wlm-filters/wlm-filters.module';
import { AlgorithmSelectionGridComponent } from './algorithm-selection-grid/algorithm-selection-grid.component';
import { NeConfigurationHistoricalSwitcherComponent } from './ne-configuration-historical-switcher/ne-configuration-historical-switcher.component';
import { TelemetryPointsSelectionComponent } from './telemetry-points-selection/telemetry-points-selection.component';

@NgModule({
  declarations: [
    AlgorithmSelectionGridComponent,
    NotificationPopupComponent,
    NotificationFormComponent,
    TelemetryPointsSelectionComponent,
    NeConfigurationHistoricalSwitcherComponent,
  ],
  exports: [
    AlgorithmSelectionGridComponent,
    NotificationPopupComponent,
    NotificationFormComponent,
    CommonSharedComponentModule,
    CommonFiltersModule,
    NeConfigurationHistoricalSwitcherComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonToggleModule,
    WLMGridModule,
    CommonSharedComponentModule,
    WlmSharedModule,
    WlmFiltersModule,
    CommonFiltersModule,
  ],
})
export class WlmSharedComponentModule {
  static injector: Injector;

  constructor(injector: Injector) {
    WlmSharedComponentModule.injector = injector;
  }
}
