import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BaseGridComponent } from '@common-modules/wlm-grid/base-grid/base-grid.component';
import { GenericGridComponent } from '@common-modules/wlm-grid/generic-grid/generic-grid.component';
import { LocalGridComponent } from '@common-modules/wlm-grid/local-grid/local-grid.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject, Subscription, map } from 'rxjs';
import { AppModules } from '../../app-modules.enum';
import { GridSetting } from '../../constants/grid.constants';
import { EditGridSettingsPopupComponent } from '../../core/edit-grid-settings-popup/edit-grid-settings-popup.component';
import { GridSettingsService } from '../../core/grid/grid-settings.service';
import { ManageColumnResult } from '../../core/manage-column/manage-column-result';
import { ManageColumnComponent } from '../../core/manage-column/manage-column.component';
import { DialogService } from '../../dialogs/dialogs.service';
import { DataBindingFilters } from '../../filters/component-filters/data-binding-filters';
import { WlmDialogSettings } from '../../model/dialog/wlm-dialog-setting';
import { IPendingChangesChecker } from '../../pending-changes/models/pending-changes-checker';
import { PendingChangesManagerService } from '../../pending-changes/services/pending-changes-manager.service';
import { GridButtonsThirdSlotDirective } from '../directives/grid-buttons-third-slot.directive';
import { GridBtnsDisable } from '../models/grid-btns-disable';
import { GridBtnsEvent } from '../models/grid-btns-event';
import { GridBtnsOptions } from '../models/grid-btns-options.enum';
import { GridBtnsSettings } from '../models/grid-btns-settings';

const COMPONENT_SELECTOR = 'wlm-grid-buttons-internal';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './grid-buttons-internal.component.html',
  styleUrls: ['./grid-buttons-internal.component.scss'],
})
export class GridButtonsInternalComponent implements IPendingChangesChecker {
  @Input() showHamburguer: boolean = false;

  @Input() firstSlotContent: TemplateRef<GridButtonsThirdSlotDirective>[];
  @Input() secondSlotContent: TemplateRef<GridButtonsThirdSlotDirective>[];
  @Input() thirdSlotContent: TemplateRef<GridButtonsThirdSlotDirective>[];

  private _grid: GenericGridComponent;
  public get grid(): GenericGridComponent {
    return this._grid;
  }
  @Input() public set grid(v: GenericGridComponent) {
    this._grid = v;
    if (v) {
      this._gridReady$.next(v);
    }
  }

  localGridComponent: LocalGridComponent;
  @Input() public set localGrid(v: LocalGridComponent) {
    this.localGridComponent = v;
    if (v) {
      this._gridLocalReady$.next(v);
    }
  }

  @Input() public set gridSettings(value: GridSetting) {
    this._gridSettings = value;
    if (value) {
      this.settings = { ...this.gridSettings.buttons };
      this.settings.show?.forEach((item) => {
        this.show[item] = true;
      });
    }
  }

  @Input() public set disable$(value: Subject<GridBtnsDisable>) {
    this._disable$ = value;
    this.listenDisableChanges();
  }

  @Input() pageId: string;
  @Input() gridFilters: DataBindingFilters;
  @Input() gridName: string;
  @Input() canLoad: boolean;

  @Output() btnClick = new EventEmitter<GridBtnsEvent>();
  @Output() btnCallback = new EventEmitter<GridBtnsEvent>();
  @Output() gridSettingsChange = new EventEmitter<GridSetting>();

  settings: GridBtnsSettings;
  options = GridBtnsOptions;
  disableStatus: { [key: string]: boolean } = {};
  show: { [key: string]: boolean } = {};
  private _gridSettings: GridSetting;
  private _disable$: Subject<GridBtnsDisable>;
  private _disableSubs: Subscription;
  private _gridReady$ = new ReplaySubject();
  private _gridLocalReady$ = new ReplaySubject();

  constructor(
    private _matDialog: MatDialog,
    private _gridService: GridSettingsService,
    private _dialogService: DialogService,
    private _pendingChangesService: PendingChangesManagerService
  ) {}

  checkPendingChanges(key: string): Observable<unknown> {
    return this._pendingChangesService.checkPendingChanges(key).pipe(
      untilDestroyed(this),
      map((_) => true)
    );
  }

  /**
   * Listen disable events from outside the component.
   */
  listenDisableChanges(): void {
    if (this.disable$) {
      this._disableSubs?.unsubscribe();
      this._disableSubs = this.disable$.pipe(untilDestroyed(this)).subscribe((data) => {
        if (data && this.disableStatus[data.btn] !== data.disabled) {
          this.disableStatus[data.btn] = data.disabled;
          this.disableStatus = { ...this.disableStatus };
        }
      });

      this.grid?.gridFiltersChanged
        .pipe(untilDestroyed(this))
        .subscribe((filters: DataBindingFilters) => {
          this.disable$.next(new GridBtnsDisable(GridBtnsOptions.ClearGridFilters, !filters));
        });
    }
  }

  onClickFilter(): void {
    this._gridReady$.pipe(untilDestroyed(this)).subscribe((currentGrid: GenericGridComponent) => {
      // Set the new gridFilters obtained from the filter component, so the grid can reload them.
      const newGridFilters = new DataBindingFilters();
      if (this.gridFilters?.filters) {
        newGridFilters.filters = this.gridFilters?.filters;
      }
      currentGrid.gridFiltersForBinding = newGridFilters;
    });

    this._gridLocalReady$
      .pipe(untilDestroyed(this))
      .subscribe((currentGrid: LocalGridComponent) => {
        currentGrid.reloadGrid();
      });
    this.btnClick.emit(new GridBtnsEvent(GridBtnsOptions.Filter));
  }

  onClickClearFilters(): void {
    this.btnClick.emit(new GridBtnsEvent(GridBtnsOptions.ClearFilters));
  }

  onClickClearGridFilters(): void {
    this._gridReady$.pipe(untilDestroyed(this)).subscribe((currentGrid: GenericGridComponent) => {
      currentGrid.clearFilters();
    });
    this._gridLocalReady$
      .pipe(untilDestroyed(this))
      .subscribe((currentGrid: LocalGridComponent) => {
        currentGrid.clearFilters();
      });
    this.btnClick.emit(new GridBtnsEvent(GridBtnsOptions.ClearGridFilters));
  }

  onClickManageColumns(): void {
    this.checkPendingChanges(this.pageId).subscribe(() => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '830px';
      dialogConfig.height = '690px';

      const currentGrid = this.grid ?? this.localGridComponent;

      dialogConfig.data = { gridSettings: currentGrid.gridSettings, gridName: this.gridName };
      const popup = this._matDialog.open(ManageColumnComponent, dialogConfig);

      popup.componentInstance.loadGridSettings.pipe(untilDestroyed(this)).subscribe({
        next: (loadByDefault: boolean) => {
          this.loadGridSettings(loadByDefault, popup);
        },
      });

      popup
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((data: ManageColumnResult) => {
          if (data) {
            this.loadGridSettings(data.isDefaultGrid, popup);
          }
        });
    });
  }

  private loadGridSettings(
    isDefaultGrid: boolean,
    popup: MatDialogRef<ManageColumnComponent, any>
  ) {
    // When settings are updated, must delete cache
    this.currentGrid.removePersistedGridSettings();

    this._gridService.getGridSettingsByName(this.gridName, isDefaultGrid).subscribe({
      next: (gridSettings) => {
        if (gridSettings) {
          this.gridSettings = gridSettings;
          this.gridSettingsChange.emit(this.gridSettings); // Two way binding.

          this.currentGrid.reloadGrid();

          if (popup?.componentInstance) {
            popup.componentInstance.gridSettings = gridSettings;
          }
          const messageKey = isDefaultGrid ? 'system-template-loaded' : 'user-template-loaded';
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${AppModules.WlmShared}.wlm-manage-column.messages.${messageKey}`,
            })
          );
        }
      },
    });
  }

  get tooltipFilter(): string {
    return this.getTooltipOrDefault(GridBtnsOptions.Filter, 'common.load-data');
  }

  get tooltipClearFilters(): string {
    return this.getTooltipOrDefault(GridBtnsOptions.ClearFilters, 'common.clear-filter');
  }

  get tooltipClearGridFilters(): string {
    return this.getTooltipOrDefault(GridBtnsOptions.ClearGridFilters, 'common.clear-grid-filter');
  }

  get tooltipManageColumn(): string {
    return this.getTooltipOrDefault(GridBtnsOptions.ManageColumns, 'common.manage-columns');
  }

  get tooltipEditGridSettings(): string {
    return this.getTooltipOrDefault(GridBtnsOptions.EditGridSettings, 'common.edit-grid-settings');
  }

  getTooltipOrDefault(btn: GridBtnsOptions, defaultKey: string): string {
    if (this.settings?.tooltipKeys && this.settings.tooltipKeys[btn]) {
      return this.settings.tooltipKeys[btn];
    }
    return defaultKey;
  }

  public get gridSettings(): GridSetting {
    return this._gridSettings;
  }

  public get disable$(): Subject<GridBtnsDisable> {
    return this._disable$;
  }
  editGridSettings() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '830px';
    dialogConfig.height = '690px';

    dialogConfig.data = { gridSettings: this.currentGrid.gridSettings, gridName: this.gridName };
    const popup = this._matDialog.open(EditGridSettingsPopupComponent, dialogConfig);
  }

  get currentGrid(): BaseGridComponent {
    return this.grid ?? this.localGridComponent;
  }
}
