import { Injectable, Injector } from '@angular/core';
import { INetworkElementSubTypeDto } from '@common-modules/dependencies/ne/network-element-subtype.dto';
import { GenericCrudService } from '@common-modules/generic-crud/generic-crud.service';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable, map } from 'rxjs';
import { INetworkElementSubTypeValidationDto } from './network-element-subtype-validation.dto';

@Injectable()
export class NESubTypesCrudService
  extends BaseService
  implements GenericCrudService<INetworkElementSubTypeDto, number>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/network-element-subtypes`;
  }

  getAll(): Observable<INetworkElementSubTypeDto[]> {
    return this.httpCacheClient.get<INetworkElementSubTypeDto[]>(this._entityUrl);
  }

  create(entity: INetworkElementSubTypeDto): Observable<INetworkElementSubTypeDto> {
    return this.httpCacheClient.post<INetworkElementSubTypeDto>(this._entityUrl, entity);
  }

  update(entity: INetworkElementSubTypeDto): Observable<INetworkElementSubTypeDto> {
    return this.httpCacheClient.put<INetworkElementSubTypeDto>(this._entityUrl, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  getId(entity: INetworkElementSubTypeDto): number {
    return entity.networkElementSubTypeId;
  }

  validate(
    entity: INetworkElementSubTypeDto,
    isCreate?: boolean
  ): Observable<INetworkElementSubTypeValidationDto> {
    return this.httpCacheClient.post<INetworkElementSubTypeValidationDto>(
      `${this._entityUrl}/validate?isCreate=${isCreate ? 'true' : 'false'}`,
      entity
    );
  }
}
