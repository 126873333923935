import { Component, Input, OnInit } from '@angular/core';
import { IHierarchyElementTypeDto } from '@common-modules/dependencies/he/hierarchy-element-type.dto';
import {
  TabDetailPanelParameters,
  TabDetailParameterName,
} from '@common-modules/dependencies/navigation/tab-detail-component';
import { ElementTypeFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/element-type-f-adapter';
import { HierarchyElementPathFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { IsZoneFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/is-zone-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { NeHeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { ArrayHelperService } from '@common-modules/shared/helpers/array-helper.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { ElementTargetsEnum } from '@common-modules/shared/model/shared/element-targets.enum';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { TreeSettingsService } from '@common-modules/shared/services/tree-settings.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { NEConfigFilterConfiguration } from './ne-config-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-ne-config-filter';
@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-config-filter.component.html',
  styleUrls: ['./ne-config-filter.component.scss'],
})
export class NeConfigFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _neConfigFilterConfiguration: NEConfigFilterConfiguration;
  public get neConfigFilterConfiguration(): NEConfigFilterConfiguration {
    return this._neConfigFilterConfiguration;
  }
  @Input() public set neConfigFilterConfiguration(value: NEConfigFilterConfiguration) {
    this._neConfigFilterConfiguration = value;
    if (value) {
      this.buildKeysToComplete();
      this.setFiltersPersistencyArea();
      this.familyIdSelected = value.initialFamilyId;
      this.adaptersReady = true;
    }
  }

  @Input() reset$: Observable<void>;

  private _hierarchyElementTypesLevelList: IHierarchyElementTypeDto[];
  private _disabledhierarchyElementTypes: string[] = [];

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  loaded: boolean;
  familyIdSelected: string;

  neTypeFilterId = FilterAdapterEnum.NetworkElementTypeFilter;
  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableSelectAll: true,
    disableApplyFilters: false,
  };

  targetGroupSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-target-label`,
  };

  hierarchyElementGroupSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-he-label`,
  };

  networkElementsGroupSettings = new BaseFilterSettings({
    ...this.baseFilterSettings,
    isRequired: true,
    inputLabelKey: `${this.T_SCOPE}.filters-group-ne-label`,
  });

  treeGroupSettings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-tree-label`,
  };

  targetItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  familyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
  });

  hierarchyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  networkFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    id: this.neTypeFilterId,
  });

  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  get componentName(): string {
    return 'NeConfigFilterComponent';
  }

  isHEFilterSelected = false;
  wasHEFilterSelectedPreviously = true;
  oldHELevelSelection: string[];
  oldFamilyIdSelected: string;

  clearHEFilter$ = new Subject<void>();
  rebuildTreeFilter$ = new Subject<void>();
  treeSettings: ITreeSettings = null;
  alternativeAdapter = FilterAdapterEnum.isZoneSelected;

  constructor(
    private _treeSettingsService: TreeSettingsService,
    private _arrayHelperService: ArrayHelperService,
    private _objectHelper: ObjectHelperService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  protected setFiltersPersistencyArea() {
    this.targetGroupSettings.persistencyArea = this.neConfigFilterConfiguration.persistencyArea;
    this.hierarchyElementGroupSettings.persistencyArea =
      this.neConfigFilterConfiguration.persistencyArea;
    this.networkElementsGroupSettings.persistencyArea =
      this.neConfigFilterConfiguration.persistencyArea;
    this.treeGroupSettings.persistencyArea = this.neConfigFilterConfiguration.persistencyArea;
  }

  protected additionalAutoloadCheck(results: FilterAdapterResult): boolean {
    return true;
  }

  ngOnInit(): void {
    this.isHEFilterSelected = true;
    this.buildTreeSettings();
    this.bindResetFilters();
    this.loaded = true;
  }

  buildTreeSettings(): void {
    this._treeSettingsService
      .buildTreeSettings({
        unselectableTypes: this._disabledhierarchyElementTypes,
      })
      .subscribe((settings) => (this.treeSettings = settings));
  }

  init(): void {}

  provideAdapters = (): FilterAdapterSettings[] => {
    const { heSettings, neSettings, hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.neConfigFilterConfiguration;

    const neheSelectionAdapter = new IsZoneFAdapter({
      dataBindingField: this.neConfigFilterConfiguration.targetSettings.fieldName,
      neheSelectionFieldName: this.neConfigFilterConfiguration.targetSettings.fieldName,
    });

    const neAdapter = new ElementTypeFAdapter({
      dataBindingField: this.neConfigFilterConfiguration.neSettings.elementTypeFieldName,
      elementTypeFieldName: neSettings.elementTypeFieldName,
      neheFilterSettings: new NeHeFISettings({
        isNEFilter: true,
        selectedIds: neSettings.selectedElementIds,
        fieldName: neSettings.elementTypeFieldName,
      }),
      tabDetailParameterName: TabDetailParameterName.networkElementTypeFilterSetting,
    });

    neAdapter.id = this.neTypeFilterId;

    const heAdapter = new ElementTypeFAdapter({
      dataBindingField: this.neConfigFilterConfiguration.heSettings.elementTypeFieldName,
      elementTypeFieldName: heSettings.elementTypeFieldName,
      neheFilterSettings: new NeHeFISettings({
        isNEFilter: false,
        selectedIds: heSettings.selectedElementIds,
        fieldName: heSettings.elementTypeFieldName,
      }),
    });

    return [
      new HierarchyElementPathFAdapter({
        dataBindingField: hierarchyElementIdFieldName,
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId:
          this.neConfigFilterConfiguration.initialFamilyId ??
          this.neConfigFilterConfiguration.defaultFamilyId,
      }),
      neAdapter,
      heAdapter,
      neheSelectionAdapter,
    ];
  };

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  /**
   * Only used to save the selected family id.
   * Will allways contain an array of one element, as it is single select.
   */
  onTargetSelect(filters: FiltersPayload): void {
    const selected = filters.getSelectedIds(
      this.neConfigFilterConfiguration.targetSettings.fieldName
    )[0];
    if (selected) {
      this.isHEFilterSelected = selected === ElementTargetsEnum.HierarchyElements;

      if (this.isHEFilterSelected !== this.wasHEFilterSelectedPreviously) {
        this.clearHEFilter$.next();
        this.wasHEFilterSelectedPreviously = this.isHEFilterSelected;
        this.rebuildTree(this.neConfigFilterConfiguration.defaultHETypeIds);
      }
    }
  }

  onTargetClearAll() {
    this.clearHEFilter$.next();
    this.rebuildTree(this.neConfigFilterConfiguration.defaultHETypeIds);
  }

  onFamilyIdSelect(filters: FiltersPayload): void {
    this.familyIdSelected = filters.getSelectedIds(
      this.neConfigFilterConfiguration.hierarchyElementFamilyFieldName
    )[0] as string;
  }

  onhierarchyLevelsListChanged(hierarchyLevelList: IHierarchyElementTypeDto[]) {
    this._hierarchyElementTypesLevelList = hierarchyLevelList;
  }

  onApplyHEFilter(filters: FiltersPayload) {
    const selectedElements = filters.getSelectedIds(
      this.neConfigFilterConfiguration.heSettings.elementTypeFieldName
    ) as string[];

    this.oldHELevelSelection =
      this.oldHELevelSelection ?? this.neConfigFilterConfiguration.defaultHETypeIds;

    if (!this._arrayHelperService.areSame(selectedElements, this.oldHELevelSelection)) {
      this.oldHELevelSelection = [...selectedElements];
      this.rebuildTree(selectedElements);
    }
  }

  private rebuildTree(selectedElementTypes: string[]): void {
    this.calculateDisabledNodes(selectedElementTypes);
    this.buildTreeSettings();
    this.rebuildTreeFilter$.next();
  }

  calculateDisabledNodes(selectedElements: any[]) {
    if (selectedElements?.length > 0) {
      const heIdList = this._hierarchyElementTypesLevelList.map((m) => m.hierarchyElementTypeId);
      let minLevel = heIdList.indexOf(selectedElements[0]);
      selectedElements.forEach((level) => {
        const currentLevel = heIdList.indexOf(level);
        if (currentLevel < minLevel) {
          minLevel = currentLevel;
        }
      });

      const index = minLevel;
      this._disabledhierarchyElementTypes = this._hierarchyElementTypesLevelList
        .map((x) => x.hierarchyElementTypeName)
        .slice(index + 1);
    }
  }

  buildKeysToComplete(): void {
    const { hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.neConfigFilterConfiguration;
    const targetFieldName = this.neConfigFilterConfiguration.targetSettings.fieldName;

    this.keysToComplete = [
      targetFieldName,
      hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName,
    ];
  }

  // This is necessary to persist the tree values
  onTreeFilterReady() {
    if (this.mustPersistFilters) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
    }
  }

  processFilterChanges(results: FilterAdapterResult) {
    const processedResult = this._objectHelper.clone(results, false);
    const filters = processedResult?.dbFilters?.filters; // Do not clone, using same ref.
    const {
      targetSettings,
      neSettings,
      heSettings,
      hierarchyElementFamilyFieldName,
      hierarchyElementIdFieldName,
    } = this.neConfigFilterConfiguration;

    // Ammend possible familyId disalignment #23821
    if (filters?.has(hierarchyElementIdFieldName)) {
      const heFilter = filters?.get(hierarchyElementIdFieldName);
      heFilter[hierarchyElementFamilyFieldName] = this.familyIdSelected;
      filters?.set(hierarchyElementIdFieldName, heFilter);
    }

    if (filters?.has(targetSettings.fieldName)) {
      const isZone = filters.get(targetSettings.fieldName).getSelectedFilters();
      const hasNEFilter = filters?.has(neSettings.elementTypeFieldName);
      const hasHEFilter = filters?.has(heSettings.elementTypeFieldName);

      if (isZone && hasNEFilter) {
        filters.delete(neSettings.elementTypeFieldName);
      }

      if (!isZone && hasHEFilter) {
        filters.delete(heSettings.elementTypeFieldName);
      }
    }

    this.onGlobalFilterResults(processedResult);
  }

  private bindResetFilters() {
    this.reset$.pipe(untilDestroyed(this)).subscribe(() => this.clearAll$.next());
  }
}
