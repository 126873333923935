import { Injectable } from '@angular/core';
import { HistoricalVersionDto } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-version.dto';
import { Observable, of } from 'rxjs';
import { BaseHistoricalMessagesService } from '../../shared/historical/base-historical-messages.service';

@Injectable()
export class LargeUserHistoricalMessagesService extends BaseHistoricalMessagesService {
  additionalErrorFields(error: HistoricalVersionDto): Observable<string> {
    return of('');
  }
}
