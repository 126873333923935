import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { SignalSelectionListSettings } from '../../features/ne-configuration/ne-signal-selection-lists/signal-selection-list-settings';

export class SmartMeterSelectionPopupSettings {
  signalsListSettings: SignalSelectionListSettings;
  title: string;
  minDate: Date;
  maxDate: Date;
  networkElement: INetworkElementDto;
  pageId: string;

  constructor(init: Readonly<SmartMeterSelectionPopupSettings>) {
    Object.assign(this, init);
  }
}
