import { Component, Input, OnInit } from '@angular/core';
import { IHierarchyElementTypeDto } from '@common-modules/dependencies/he/hierarchy-element-type.dto';
import { networkElementTypesDVWizardSet } from '@common-modules/dependencies/he/hierarchy-tree-filter-item-constants';
import { ElementTypeFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/element-type-f-adapter';
import { HierarchyElementPathFAdapter } from '@common-modules/dependencies/wlm-filters/adapters/hierarchy-element-path-f-adapter';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import {
  BaseFilterSettings,
  baseFilterSettingsDefaults,
} from '@common-modules/dependencies/wlm-filters/base-filter-settings';
import { NeHeFISettings } from '@common-modules/dependencies/wlm-filters/fi-settings/nehe-fi-settings';
import { FilterAdapterResult } from '@common-modules/dependencies/wlm-filters/filter-adapter-result';
import { FilterAdapterSettings } from '@common-modules/dependencies/wlm-filters/filter-adapter-settings';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { FiltersAdapterService } from '@common-modules/dependencies/wlm-filters/filters-adapter.service';
import { FiltersPayload } from '@common-modules/dependencies/wlm-filters/filters-payload';
import { ITreeSettings } from '@common-modules/dependencies/wlm-filters/hierarchy-tree-filter-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageFiltersComponent } from '@common-modules/shared/component/base-page-filters.component';
import { PersistencyService } from '@common-modules/shared/persistency.service';
import { TreeSettingsService } from '@common-modules/shared/services/tree-settings.service';
import { Subject } from 'rxjs';
import { AlarmsConfigurationFilterConfiguration } from './alarms-configuration-filter-configuration';

const COMPONENT_SELECTOR = 'wlm-alarms-configuration-filter';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarms-configuration-filter.component.html',
  styleUrls: ['./alarms-configuration-filter.component.scss'],
})
export class AlarmsConfigurationFilterComponent extends BasePageFiltersComponent implements OnInit {
  private _alarmsConfigurationFilterConfig: AlarmsConfigurationFilterConfiguration;
  public get alarmsConfigurationFilterConfig(): AlarmsConfigurationFilterConfiguration {
    return this._alarmsConfigurationFilterConfig;
  }
  @Input() public set alarmsConfigurationFilterConfig(
    value: AlarmsConfigurationFilterConfiguration
  ) {
    this._alarmsConfigurationFilterConfig = value;
    if (value) {
      this.buildKeysToComplete();
      this.adaptersReady = true;
    }
  }
  @Input() hfFieldName = 'hierarchyFamilyId';
  @Input() disableAutoload = false;

  T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;

  rebuildTreeFilter$ = new Subject<void>();

  treeSettings: ITreeSettings = null;
  baseFilterSettings: BaseFilterSettings = {
    ...baseFilterSettingsDefaults,
    disableSelectAll: true,
    disableApplyFilters: false,
  };
  bf1Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-1-label`,
  };
  bf2Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-2-label`,
  };
  bf3Settings: BaseFilterSettings = {
    ...this.baseFilterSettings,
    inputLabelKey: `${this.T_SCOPE}.filters-group-3-label`,
  };

  familyIdSelected: string;
  treeFamilyId: string;
  mustPersistFilters = false;
  loaded = false;
  firstAutoLoad = false;
  useInitialTreeValues = true;
  treeFilterReadyToDisplay = false;
  filterListElements = networkElementTypesDVWizardSet;
  mode = 'multiple';
  filterHasChanged = false;
  previousFamilyId: string;
  selectedHETypeIds: string[];
  neTypeFilterId = FilterAdapterEnum.NetworkElementTypeFilter;

  hierarchyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: false,
    hideSelectAllCheckbox: true,
  });

  networkFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: false,
    id: this.neTypeFilterId,
  });

  familyFilterItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    hideInputSummary: true,
  });

  hierarchyTreeItemSettings = new BaseFilterItemSettings({
    hideInputSummaryLabel: true,
    required: true,
  });

  private _hierarchyElementTypesLevelList: IHierarchyElementTypeDto[];
  private _disabledhierarchyElementTypes: string[];

  constructor(
    private _adapterService: FiltersAdapterService,
    private _treeSettingsService: TreeSettingsService,
    persistencyService: PersistencyService
  ) {
    super(persistencyService);
  }

  ngOnInit(): void {
    this.setFiltersPersistencyArea();
    this.initHierarchyFamily();
    this.setInitialHETypeIds();
    this.initializeTree(this.familyIdSelected);
    this.loaded = true;
  }

  buildKeysToComplete(): void {
    const { hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.alarmsConfigurationFilterConfig;
    this.keysToComplete = [hierarchyElementFamilyFieldName, hierarchyElementIdFieldName];
  }

  provideAdapters = (): FilterAdapterSettings[] => {
    const { heSettings, neSettings, hierarchyElementFamilyFieldName, hierarchyElementIdFieldName } =
      this.alarmsConfigurationFilterConfig;

    const neAdapter = new ElementTypeFAdapter({
      dataBindingField: 'networkElementType',
      elementTypeFieldName: neSettings.elementTypeFieldName,
      neheFilterSettings: new NeHeFISettings({
        isNEFilter: true,
        selectedIds: neSettings.selectedElementIds,
        fieldName: neSettings.elementTypeFieldName,
      }),
    });

    neAdapter.id = this.neTypeFilterId;

    const heAdapter = new ElementTypeFAdapter({
      dataBindingField: 'hierarchyElementType',
      elementTypeFieldName: heSettings.elementTypeFieldName,
      neheFilterSettings: new NeHeFISettings({
        isNEFilter: false,
        selectedIds: heSettings.selectedElementIds,
        fieldName: heSettings.elementTypeFieldName,
      }),
    });

    const adapters = [
      new HierarchyElementPathFAdapter({
        dataBindingField: hierarchyElementIdFieldName,
        hierarchyElementFamilyFieldName,
        hierarchyElementIdFieldName,
        familyId:
          this.alarmsConfigurationFilterConfig.initialFamilyId ??
          this.alarmsConfigurationFilterConfig.defaultFamilyId,
      }),
      neAdapter,
      heAdapter,
    ];
    return adapters;
  };

  private setInitialHETypeIds() {
    this.selectedHETypeIds =
      this.alarmsConfigurationFilterConfig?.heSettings?.selectedElementIds ??
      this.alarmsConfigurationFilterConfig.defaultHETypeIds;
  }

  initializeTree(familyId: string) {
    this.treeFamilyId = familyId;
    this.calculateDisabledNodes(this.selectedHETypeIds);
    this.buildTreeSettings();
    this.rebuildTreeFilter$.next();
  }

  setFiltersPersistencyArea() {
    this.bf1Settings.persistencyArea = this.alarmsConfigurationFilterConfig.persistencyArea;
    this.bf2Settings.persistencyArea = this.alarmsConfigurationFilterConfig.persistencyArea;
    this.bf3Settings.persistencyArea = this.alarmsConfigurationFilterConfig.persistencyArea;
  }

  initHierarchyFamily(): void {
    this.familyIdSelected =
      this.alarmsConfigurationFilterConfig.initialFamilyId ??
      this.alarmsConfigurationFilterConfig.defaultFamilyId;
    this.previousFamilyId = this.familyIdSelected;
  }

  calculateDisabledNodes(selectedElements: any[]) {
    this._disabledhierarchyElementTypes = [];
    if (selectedElements?.length > 0 && this._hierarchyElementTypesLevelList) {
      const index = this._hierarchyElementTypesLevelList
        .map((x) => x.hierarchyElementTypeId)
        .indexOf(selectedElements[0]);
      this._disabledhierarchyElementTypes = this._hierarchyElementTypesLevelList
        .map((x) => x.hierarchyElementTypeName)
        .slice(index + 1);
    }
  }

  /**
   * Only used to save the selected family id.
   * Will allways contain an array of one element, as it is single select.
   */
  onFamilyIdSelect(filters: FiltersPayload): void {
    const familyFieldName = this.alarmsConfigurationFilterConfig.hierarchyElementFamilyFieldName;
    const defaultId = this.alarmsConfigurationFilterConfig.defaultFamilyId;
    this.familyIdSelected = this._adapterService.getFamilyId(filters, familyFieldName, defaultId);
  }

  buildTreeSettings(): void {
    this._treeSettingsService
      .buildTreeSettings({
        unselectableTypes: this._disabledhierarchyElementTypes,
      })
      .subscribe((settings) => (this.treeSettings = settings));
  }

  // This is necessary to persist the tree values
  onTreeFilterReady() {
    if (this.mustPersistFilters) {
      this.persistFilters$.next();
      this.mustPersistFilters = false;
    }
  }

  additionalAutoloadCheck(_: FilterAdapterResult): boolean {
    return true;
  }
}
