import { DateRange } from '@common-modules/shared/model/date/date-range';

export class DateRangeSingleFilterConfiguration {
  persistencyArea: string;
  startDateFieldName: string;
  endDateFieldName: string;
  dateRange: DateRange;
  defaultDateRange?: DateRange;

  constructor(
    persistencyArea: string,
    startDateFieldName: string,
    endDateFieldName: string,
    dateRange?: DateRange,
    defaultDateRange?: DateRange
  ) {
    this.persistencyArea = persistencyArea;
    this.startDateFieldName = startDateFieldName;
    this.endDateFieldName = endDateFieldName;
    this.dateRange = dateRange;
    this.defaultDateRange = defaultDateRange;
  }
}
