<wlm-svg-editor
  [label]="props.label"
  [placeholder]="placeholder"
  [value]="value"
  [previewLabel]="props.previewLabel"
  [previewPlaceholder]="props.previewPlaceholder"
  [disabledValidation]="props.disabledValidation"
  [additionalAllowedValues]="props.additionalAllowedValues"
  [disabled]="props.disabled"
  (valueChange)="onValueChanged($event)"
  (validityChange)="onValidityChanged($event)"
></wlm-svg-editor>
