import { Component, DestroyRef, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogConfig } from '@angular/material/dialog';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { BaseSelectorDynamicPage } from '@common-modules/dynamic-layout/layouts/base/base-selector-dynamic-page';
import { SelectorDynamicLayoutSettings } from '@common-modules/dynamic-layout/layouts/selector-dynamic-layout/selector-dynamic-layout-settings';
import { SelectorDynamicLayoutComponent } from '@common-modules/dynamic-layout/layouts/selector-dynamic-layout/selector-dynamic-layout.component';
import { StateAreas } from '@common-modules/redux/models/state-areas';
import { StateWidgetSettings } from '@common-modules/redux/models/state-widget-settings';
import { ReduxStateService } from '@common-modules/redux/redux-state.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { Observable, of } from 'rxjs';
import { EditInterfacesPopupComponent } from '../../../interfaces/edit-interfaces-popup/edit-interfaces-popup.component';
import { RefreshLogIterfaceSelectorAction } from '../widgets/refresh-log-interfaces-selector.event';

const COMPONENT_SELECTOR = 'wlm-logs-interfaces-page';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './logs-interfaces-page.component.html',
  styleUrls: ['./logs-interfaces-page.component.scss'],
})
export class LogsInterfacesPageComponent extends BaseSelectorDynamicPage implements OnInit {
  @ViewChild(SelectorDynamicLayoutComponent) layoutComponent: SelectorDynamicLayoutComponent;

  readonly T_SCOPE = `${AppModules.Logs}.${COMPONENT_SELECTOR}`;
  private readonly _dialogService = inject(DialogService);
  private readonly _state = inject(ReduxStateService);
  private readonly _destroyRef = inject(DestroyRef);

  private readonly _layoutArea = 'LogsInterfaces';
  private readonly _layoutKey = 'LayoutMain';

  private readonly _stateWidgetSettings = new StateWidgetSettings({
    module: this._layoutArea,
    page: this._layoutKey,
    scopeInstanceKeys: {
      [StateAreas.Generic]: 'shared',
    },
  });

  constructor() {
    super();
    this.hasRightPanel = false;
    this._state.configure(this._stateWidgetSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onResetLayout(): void {
    this.layoutComponent.onResetLayout();
  }

  getSettings(): Observable<SelectorDynamicLayoutSettings> {
    const settings: SelectorDynamicLayoutSettings = {
      layoutKey: this._layoutKey,
      layoutArea: this._layoutArea,

      showResetIcon: true,
      labelReset: 'common.reset-layout',

      items: {
        selectorWidget: {
          widgetName: 'LogsInterfacesSelectorWidgetComponent',
          titleKey: `${this.T_SCOPE}.widgets.titles.selector`,
        },
        receptorWidget: {
          widgetName: 'LogsInterfacesContainerWidgetComponent',
          titleKey: `${this.T_SCOPE}.widgets.titles.container`,
        },
      },
    };
    return of(settings);
  }

  openEditInterfacesPopup(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60%';
    dialogConfig.height = '80%';
    const dialogRef = this._dialogService.openComponent(EditInterfacesPopupComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((success) => {
        if (success) {
          this._state.dispatch(new RefreshLogIterfaceSelectorAction());
        }
      });
  }

  get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  get persistencyArea(): string {
    return this.pageCrud;
  }

  get pageCrud(): string {
    return 'WLMInterfacesCrud';
  }

  get navigations(): DropdownNavigationItem[] {
    return [];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}
}
